import { FileItem } from '../../components/FileListComponent';
import { HttpClient } from '../httpClient';
import { mergePdfs, mergeTourPdfs } from './mergePdfs';

export async function downloadPDF(
  blob: any,
  files: FileItem[],
  httpClient: HttpClient,
  fileName?: string,
) {
  const mergedPdfFile = await mergePdfs(blob, files, httpClient);

  // Trigger page to download the pdfDocument
  const url = window.URL.createObjectURL(new Blob([mergedPdfFile]));
  const link = document.createElement('a');
  link.href = url;
  if (fileName && fileName !== '') link.setAttribute('download', fileName);
  else link.setAttribute('download', `transportagreement.pdf`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  if (link != null && link.parentNode != null) {
    link.parentNode.removeChild(link);
  }
}

export async function downloadTourPDF(
  blob: any,
  files: FileItem[],
  httpClient: HttpClient,
  fileName?: string,
) {
  const mergedPdfFile = await mergeTourPdfs(blob, files, httpClient);

  // Trigger page to download the pdfDocument
  const url = window.URL.createObjectURL(new Blob([mergedPdfFile]));
  const link = document.createElement('a');
  link.href = url;
  if (fileName && fileName !== '') link.setAttribute('download', fileName);
  else link.setAttribute('download', `tourAgreement.pdf`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  if (link != null && link.parentNode != null) {
    link.parentNode.removeChild(link);
  }
}
