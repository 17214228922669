import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '../lib/constants';
import { useFormContext, Controller, Path } from 'react-hook-form';
import { SporadicRouteFormInput } from './RouteFormRHF';

interface SelectCurrencyProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
  classes?: ClassNameMap<'field'>;
}

export function SelectCurrencyRHF(props: SelectCurrencyProps) {
  const {
    name,
    readOnly = false,
    controllerName,
    classes = { field: '' },
  } = props;
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<SporadicRouteFormInput>();

  const addHandler = (keypressed: string) => {
    for (const [key, value] of Object.entries(CURRENCIES)) {
      if (value.startsWith(keypressed.toUpperCase(), 0)) {
        return key;
      }
    }
  };
  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <FormControl
          id={name}
          fullWidth
          variant="outlined"
          className={classes?.field}
          size="small"
        >
          <InputLabel shrink id="ftl-currency">
            {t('attributes.currency')}
          </InputLabel>
          <Select
            id="name"
            value={field && field.value ? field.value : ' '}
            //labelId="ftl-currency"
            label={t('attributes.currency')}
            readOnly={readOnly}
            inputProps={{ readOnly: readOnly }}
            IconComponent={(props) => (
              <ArrowDropDown
                {...props}
                style={{
                  display: readOnly ? 'none' : 'inline-block',
                }}
              />
            )}
            onKeyPress={(ev) => {
              if (!readOnly && typeof ev.key === 'string') {
                const retKey = addHandler(ev.key);
                setValue(controllerName, retKey);
              }
            }}
            onChange={(ev) => {
              if (!readOnly && ev && typeof ev.target.value === 'string') {
                setValue(controllerName, ev.target.value);
              }
            }}
          >
            {Object.entries(CURRENCIES).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
