import { debounce, TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useEffect, useState } from 'react';
import {
  useGetLocationForSelectLazyQuery,
  useGetLocationsForLegLazyQuery,
} from '../../generated/graphql';

interface SelectLocationProps {
  onCellChange: (location?: { id: number; name: string } | null) => void;
  initialValue: number | undefined;
}

interface SelectLocationInterface {
  id: number;
  name: string;
  address: string;
  card: {
    name: string;
  };
}

export function SelectLocationCell(
  props: SelectLocationProps & TextFieldProps,
) {
  const { onCellChange, initialValue } = props;
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<SelectLocationInterface[]>([]);

  const [value, setValue] = useState<SelectLocationInterface | null>(null);
  const [getLocation, { data: locationData, loading }] =
    useGetLocationForSelectLazyQuery();

  useEffect(() => {
    if (initialValue != null && typeof initialValue == 'number') {
      getLocation({ variables: { locationId: initialValue } });
    }
  }, [initialValue]);

  const [getLocationsforLeg, { data: optionsData, loading: loadingOptions }] =
    useGetLocationsForLegLazyQuery();

  useEffect(() => {
    if (search.length > 2) {
      getLocationsforLeg({ variables: { locationsSearch: search } });
    }
  }, [search]);

  useEffect(() => {
    if (locationData) {
      setOptions([locationData.location]);
    }
  }, [locationData]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.locations]);
      } else {
        setOptions(optionsData.locations);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  return (
    <Autocomplete
      value={value}
      multiple={false}
      sx={{
        height: 28,
      }}
      fullWidth={true}
      autoHighlight
      onChange={(event, val) => {
        setValue(val);
        if (val?.id) {
          onCellChange(val);
        }
      }}
      onInputChange={debouncedChangeHandler}
      getOptionLabel={(option) =>
        `${option.card.name} - ${option.address}(${option?.name})`
      }
      openOnFocus
      options={options}
      loading={loading || loadingOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          variant="outlined"
          hiddenLabel
          InputProps={{ ...params.InputProps }}
          autoFocus
        />
      )}
    />
  );
}
