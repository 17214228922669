import {
  Button,
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert, debounce } from '@mui/material';
import { Box } from '@mui/system';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  CostAllocationItemType,
  Maybe,
  useGetDepartmentsQuery,
} from '../../generated/graphql';
import { newCostAllocationItem } from '../../lib/formHelpers/costAllocation';
import {
  calculateFromPercentage,
  calculatePercentage,
} from '../../lib/percentage';
import { HideableFormFields } from './HideableFormFields';

export const validationSchemaRouteCostAllocation = () =>
  yup.object({
    items: yup
      .array(
        yup
          .object({
            departmentId: yup.string(),
            type: yup.string().oneOf(Object.values(CostAllocationItemType)),
            includedKpi: yup.bool(),
            includedPrice: yup.bool(),
            cost: yup.number().nullable().required(),
            percentage: yup.number(),
            comment: yup.string(),
          })
          .omit(['percentage']),
      )
      .test('items', 'Values need to be same as agreed price', (value, ctx) => {
        const context = ctx as unknown as {
          options?: { context?: { agreedPrice: number | string } };
        };

        const agreedPrice = context?.options?.context?.agreedPrice;

        const price: number = typeof agreedPrice === 'number' ? agreedPrice : 0;

        const totalCost: number =
          value
            ?.filter((x) => x.includedPrice)
            .reduce(
              (sum: number, x: { cost?: Maybe<number> }) => sum + (x.cost ?? 0),
              0,
            ) || 0;

        const diff =
          price - totalCost > 0
            ? `${Math.abs(price - totalCost)} missing`
            : `${Math.abs(price - totalCost)} too much`;
        if (price !== totalCost) {
          return ctx.createError({
            path: ctx.path,
            message: `Cost allocations with cost allocations should sum up to ${price}. Diff: ${diff}`,
          });
        }

        return true;
      }),
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formRoot: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      '& > *': {
        marginRight: theme.spacing(1),
      },
      '& .select-small': {
        width: 100,
      },
      '& .select': {
        width: 150,
      },
    },
  }),
);

export interface CostAllocationItemInterface {
  id?: number;
  departmentId: string;
  type: CostAllocationItemType;
  includedKpi: boolean;
  includedPrice: boolean;
  bringCost: boolean;
  additionalCost: boolean;
  additionalDiscount: boolean;
  percentage?: number;
  cost: number;
  comment: string;
}

interface CostAllocationFieldsProps {
  fieldsPath?: string;
  readOnly?: boolean;
  show: boolean;
  onHideClicked: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function CostAllocationFields(props: CostAllocationFieldsProps) {
  const {
    fieldsPath = '',
    readOnly = false,
    show,
    onHideClicked,
    setOpen,
  } = props;
  const { getFieldProps, errors, setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: departmentsData, loading } = useGetDepartmentsQuery();

  const items = getFieldProps(`${fieldsPath}.items`)
    .value as CostAllocationItemInterface[];

  const agreedPrice = getFieldProps('agreedPrice').value;
  const path = fieldsPath === '' ? '' : `${fieldsPath}.`;

  const updateCost = (agreedPrice: number) => {
    (items || []).forEach((item, index) => {
      if (item.includedPrice) {
        setFieldValue(
          `${path}items.${index}.cost`,
          calculateFromPercentage(agreedPrice, item.percentage ?? 0),
        );
      }
    });
  };

  const updateCostDebounce = useCallback(debounce(updateCost, 500), [items]);

  useEffect(() => {
    updateCostDebounce(agreedPrice);
  }, [agreedPrice, items]);

  const costAllocationErrors = (
    errors as { costAllocation: { items: [] } } | undefined
  )?.costAllocation ?? { items: [] };
  const values = { items };

  if (loading || !departmentsData) {
    return <div>loading...</div>;
  }

  return (
    <HideableFormFields
      title={t('resource.costAllocation.plural')}
      show={show}
      onHideClicked={onHideClicked}
      errors={[costAllocationErrors?.items]}
      setOpen={setOpen}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldArray name={`${path}items`}>
              {(arrayHelpers) => (
                <Grid container spacing={4}>
                  {typeof costAllocationErrors?.items === 'string' ? (
                    <Grid item xs={12}>
                      <Alert severity="error">
                        {costAllocationErrors?.items}
                      </Alert>
                    </Grid>
                  ) : null}
                  {!readOnly && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            arrayHelpers.push(
                              newCostAllocationItem(
                                [{ id: values.items[0]?.departmentId ?? 'se' }],
                                values.items.reduce(
                                  (sum, x) => sum + (x.percentage ?? 0),
                                  0,
                                ),
                              ),
                            );
                          }}
                        >
                          {t('button.add', {
                            item: t('resource.costAllocation.lowercased'),
                          })}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <List>
                      {values.items.map((item, index) => (
                        <ListItem key={index} divider>
                          <Box className={classes.formRoot}>
                            <Field name={`${path}items.${index}.departmentId`}>
                              {({ field }: FieldProps) => (
                                <FormControl
                                  {...field}
                                  variant="outlined"
                                  className="select-small"
                                >
                                  <InputLabel id="department-label" shrink>
                                    {t('attributes.department')}
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    labelId="department-label"
                                    label={t('attributes.department')}
                                    id="department"
                                    readOnly={readOnly}
                                  >
                                    {departmentsData?.departments.map((dep) => (
                                      <MenuItem key={dep.id} value={dep.id}>
                                        {dep.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            </Field>
                            <Field name={`${path}items.${index}.type`}>
                              {({ field }: FieldProps) => (
                                <FormControl
                                  {...field}
                                  variant="outlined"
                                  className="select"
                                >
                                  <InputLabel id="type-label">
                                    {t('attributes.type')}
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    labelId="type-label"
                                    label={t('attributes.type')}
                                    id="type"
                                    readOnly={readOnly}
                                  >
                                    {Object.values(CostAllocationItemType).map(
                                      (val) => (
                                        <MenuItem key={val} value={val}>
                                          {val}
                                        </MenuItem>
                                      ),
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            </Field>
                            <Box display="flex" flexDirection="column">
                              <Field
                                name={`${path}items.${index}.includedKpi`}
                                readOnly={readOnly}
                              >
                                {({ field }: FieldProps) => (
                                  <FormControlLabel
                                    {...field}
                                    checked={field.value}
                                    control={
                                      <Checkbox
                                        color="primary"
                                        disabled={readOnly}
                                        readOnly={readOnly}
                                      />
                                    }
                                    label={t('attributes.includedKpi')}
                                  />
                                )}
                              </Field>
                              <Field
                                name={`${path}items.${index}.includedPrice`}
                              >
                                {({ field, form }: FieldProps) => (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={field.value}
                                        readOnly={readOnly}
                                        disabled={readOnly}
                                        onChange={(
                                          _event: React.ChangeEvent<unknown>,
                                          checked: boolean,
                                        ) => {
                                          if (!checked) {
                                            form.setFieldValue(
                                              `${path}items.${index}.percentage`,
                                              0,
                                            );
                                          }
                                          form.setFieldValue(
                                            `${path}items.${index}.includedPrice`,
                                            !!checked,
                                          );
                                        }}
                                        color="primary"
                                      />
                                    }
                                    label={t('attributes.includedPrice')}
                                  />
                                )}
                              </Field>
                            </Box>
                            <Field name={`${path}items.${index}.percentage`}>
                              {({ field, form, meta }: FieldProps) => (
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  error={meta.touched && Boolean(meta.error)}
                                  helperText={meta.touched && meta.error}
                                  onChange={(
                                    event: React.ChangeEvent<
                                      HTMLTextAreaElement | HTMLInputElement
                                    >,
                                  ) => {
                                    const newValue = parseInt(
                                      event.target.value,
                                      10,
                                    );
                                    form.setFieldValue(
                                      `${path}items.${index}.cost`,
                                      calculateFromPercentage(
                                        agreedPrice,
                                        newValue,
                                      ),
                                    );
                                    form.setFieldValue(
                                      `${path}items.${index}.percentage`,
                                      newValue,
                                    );
                                  }}
                                  inputProps={{
                                    readOnly,
                                  }}
                                  onWheel={(e) =>
                                    (e.target as HTMLInputElement).blur()
                                  }
                                  disabled={!item.includedPrice}
                                  id="percentage"
                                  label={t('attributes.percentage')}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </Field>
                            <Field name={`${path}items.${index}.cost`}>
                              {({ field, form, meta }: FieldProps) => (
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  error={
                                    (meta.touched && Boolean(meta.error)) ||
                                    (item.includedPrice &&
                                      typeof costAllocationErrors.items ===
                                        'string')
                                  }
                                  helperText={meta.touched && meta.error}
                                  onChange={(
                                    event: React.ChangeEvent<
                                      HTMLTextAreaElement | HTMLInputElement
                                    >,
                                  ) => {
                                    const newValue = parseInt(
                                      event.target.value,
                                      10,
                                    );
                                    form.setFieldValue(
                                      `${path}items.${index}.cost`,
                                      newValue,
                                    );
                                    form.setFieldValue(
                                      `${path}items.${index}.percentage`,
                                      calculatePercentage(
                                        agreedPrice,
                                        newValue,
                                      ),
                                    );
                                  }}
                                  inputProps={{
                                    readOnly,
                                  }}
                                  onWheel={(e) =>
                                    (e.target as HTMLInputElement).blur()
                                  }
                                  id="cost"
                                  label={t('attributes.cost')}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </Field>
                            <Field name={`${path}items.${index}.comment`}>
                              {({ field, meta }: FieldProps) => (
                                <TextField
                                  {...field}
                                  variant="outlined"
                                  id="comment"
                                  inputProps={{
                                    readOnly,
                                  }}
                                  error={meta.touched && Boolean(meta.error)}
                                  helperText={meta.touched && meta.error}
                                  label={t('attributes.comment')}
                                  multiline
                                  rows={3}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            </Field>
                            {!readOnly && (
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                disabled={
                                  values.items.length < 2 &&
                                  agreedPrice !== 0 &&
                                  agreedPrice !== ''
                                }
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              )}
            </FieldArray>
          </Grid>
        </Grid>
      </Box>
    </HideableFormFields>
  );
}
