import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Box, Modal, Typography } from '@mui/material';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  DeviationFault,
  LocationDeviationInput,
  Reason,
} from '../../generated/graphql';
import * as yup from 'yup';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { SelectLocation } from '../form/SelectLocation';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import { RemainingGoodsObj } from '../../views/RemainingGoodsInTerminal';
import { CommonTextField } from '../form/CommonTextField';
import { CheckboxField } from '../form/CheckboxField';
import { CheckboxIconField } from '../form/CheckboxIconField';
import DangerIcon from '@mui/icons-material/Report';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '68vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface RemainingGoodsModalProps {
  handleClose: () => void;
  open: boolean;
  initialValue: RemainingGoodsObj;
  terminalName: string;
  handleSubmit: (data: LocationDeviationInput[]) => void;
}

export const validationSchemaForCustDeviation = (t: TFunction) =>
  yup.object().shape({
    obj: yup.array(
      yup.object().shape({
        faultLocationId: yup
          .number()
          .min(
            1,
            t('validation.isRequired', {
              name: t('attributes.location'),
            }),
          )
          .required(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          ),
        toLocationId: yup
          .number()
          .required(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          )
          .positive(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          )
          .min(
            1,
            t('validation.isRequired', {
              name: t('attributes.terminal'),
            }),
          ),
        deviationApplicableDate: yup.date().required(
          t('validation.isRequired', {
            name: t('attributes.date'),
          }),
        ),
        fault: yup
          .string()
          .required('required')
          .oneOf(Object.keys(DeviationFault)),
        customerDeviationListItemsInput: yup
          .array(
            yup.object().shape({
              locationItemNote: yup.string().optional(),
              reason: yup.string().required().oneOf(Object.keys(Reason)),
              otherReason: yup.string().when('reason', {
                is: 'Other',
                then: yup.string().required(
                  t('validation.isRequired', {
                    name: t('attributes.reason'),
                  }),
                ),
              }),
              locationId: yup.number().when('isNewCustomer', {
                is: false,
                then: yup
                  .number()
                  .min(
                    1,
                    t('validation.isRequired', {
                      name: t('attributes.location'),
                    }),
                  )
                  .required(
                    t('validation.isRequired', {
                      name: t('attributes.location'),
                    }),
                  ),
              }),
              newCustomer: yup.string().when('isNewCustomer', {
                is: true,
                then: yup.string().required(
                  t('validation.isRequired', {
                    name: t('attributes.location'),
                  }),
                ),
              }),
              packages: yup
                .number()
                .nullable(true)
                .integer(
                  t('validation.noDecimals', {
                    name: t('attributes.packages'),
                  }),
                )
                .positive(
                  t('validation.fieldPositive', {
                    name: t('attributes.packages'),
                  }),
                ),
              weight: yup
                .number()
                .nullable(true)
                .positive(
                  t('validation.fieldPositive', {
                    name: t('attributes.weight'),
                  }),
                ),
              pallets: yup
                .number()
                .integer(
                  t('validation.noDecimals', {
                    name: t('attributes.pallets'),
                    len: '0',
                  }),
                )
                .nullable()
                .min(
                  0,
                  t('validation.minNumber', {
                    name: t('attributes.pallets'),
                    len: '0',
                  }),
                ),
              palletSpace: yup
                .number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .min(
                  0,
                  t('validation.minNumber', {
                    name: t('attributes.palletSpace'),
                    len: '0',
                  }),
                )
                .typeError('Required')
                .required('Required'),
            }),
          )
          .min(1, 'Atleast One Item is required')
          .required('Atleast One Item is required'),
      }),
    ),
  });

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    '& > *': {
      width: '150px',
    },
  },
  halfRow: {
    display: 'flex',
    width: '100%',
    marginBottom: 26,
  },
  distribute: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  maximizeNote: {
    justifyContent: 'flex-start',
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      flex: 2,
    },
  },
  root: {
    margin: 0,
    padding: 0,
    '& > .MuiGrid-item': {
      padding: 0,
    },
  },
  noPadding: {
    padding: '0 !important',
  },
  noPaddingListRow: {
    paddingLeft: '0 !important',
    width: '100%',
    paddingBottom: 4,
  },
  deleteButton: {
    padding: '0 !important',
  },
  listItem: {
    '&.MuiListItem': {
      padding: 0,
    },
    '&.MuiListItem-gutters': {
      padding: 0,
    },
    '.MuiListItem-root': {
      padding: 0,
    },
  },
  numberFields: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
  selecLocationField: {
    width: '99%',
    fontSize: '10px',
    margin: 0,
    padding: 0,
  },
  field: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
}));

export function RemainingGoodsModal(props: RemainingGoodsModalProps) {
  const { handleClose, open, initialValue, terminalName, handleSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const afterValidation = (values: RemainingGoodsObj) => {
    const validatedInput = values.obj as LocationDeviationInput[];
    handleSubmit(validatedInput);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{t('attributes.remainingGoods')}</Typography>
          <Typography style={{ fontSize: 16 }}>{`${terminalName}`}</Typography>
        </Box>
        <Formik
          initialValues={initialValue}
          onSubmit={afterValidation}
          validate={(value) => {
            try {
              validateYupSchema(
                value,
                validationSchemaForCustDeviation(t),
                true,
                value,
              );
            } catch (err) {
              const errors = yupToFormErrors<{ error: string }>(err);
              return errors;
            }
            return {};
          }}
        >
          {(props) => {
            const { initialValues } = props;
            return (
              <Form>
                <>
                  <Box p={1}>
                    <FieldArray
                      name="obj"
                      render={(arrayProps) => (
                        <>
                          <Box style={{ marginLeft: 25 }}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                arrayProps.push({
                                  id: undefined,
                                  faultLocationId:
                                    initialValues.obj[0].faultLocationId,
                                  deviationApplicableDate:
                                    initialValues.obj[0]
                                      .deviationApplicableDate,
                                  toLocationId: 0,
                                  consequence: true,
                                  fault: DeviationFault.FaultFromTerminal,
                                  note: '',
                                  customerDeviationListItemsInput: [
                                    {
                                      locationId: 0,
                                      packages: null,
                                      pallets: null,
                                      palletSpace: null,
                                      weight: null,
                                      locationItemNote: '',
                                      reason: undefined,
                                      otherReason: '',
                                      newCustomer: '',
                                      isNewCustomer: false,
                                      isDangerous: false,
                                    },
                                  ],
                                });
                              }}
                            >
                              {t('button.add', {
                                item: t('location'),
                              })}
                            </Button>
                          </Box>
                          <Grid item xs={12}>
                            <List>
                              {props.values.obj.map(
                                (_newLocation, locIndex) => (
                                  <ListItem>
                                    <Box
                                      p={1}
                                      style={{
                                        marginTop: 0,
                                        border: '0.5px solid #AAA',
                                      }}
                                    >
                                      <Grid container xs={12}>
                                        <Grid item xs={6}>
                                          {/* To Terminal */}
                                          <SelectLocation
                                            name={`obj.${locIndex}.toLocationId`}
                                            fullWidth
                                            size="small"
                                            label={'toTerminal'}
                                          />
                                        </Grid>
                                        <Grid item xs={5}></Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          container
                                          direction="row"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <>
                                            {locIndex === 0 ? null : (
                                              <IconButton
                                                className={classes.deleteButton}
                                                onClick={() => {
                                                  arrayProps.remove(locIndex);
                                                }}
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            )}
                                          </>
                                        </Grid>
                                      </Grid>
                                      <FieldArray
                                        name={`obj.${locIndex}.customerDeviationListItemsInput`}
                                        render={(arrayHelpers) => (
                                          <Grid
                                            item
                                            xs={12}
                                            spacing={2}
                                            container
                                            className={classes.root}
                                          >
                                            {/* Lables */}
                                            <Grid
                                              xs={12}
                                              item
                                              container
                                              className={classes.noPadding}
                                              style={{
                                                backgroundColor: '#e3e3e3',
                                                marginTop: 5,
                                              }}
                                            >
                                              <Grid item xs={5}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'Customer'}
                                                </Typography>
                                              </Grid>

                                              <Grid item xs={1}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'Packages'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={1}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'Pallets'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={1}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'Pallet space'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={1}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'Weight'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={1}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'Note'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={2}>
                                                <Button
                                                  variant="contained"
                                                  color="primary"
                                                  size="small"
                                                  onClick={() => {
                                                    arrayHelpers.push({
                                                      locationId: 0,
                                                      packages: null,
                                                      pallets: null,
                                                      palletSpace: null,
                                                      weight: null,
                                                      locationItemNote: '',
                                                      reason: undefined,
                                                      otherReason: '',
                                                      newCustomer: '',
                                                      isDangerous: false,
                                                    });
                                                  }}
                                                >
                                                  {t('button.add', {
                                                    item: t('customer'),
                                                  })}
                                                </Button>
                                              </Grid>
                                            </Grid>
                                            {/* list items */}
                                            <Grid item xs={12}>
                                              <List>
                                                {_newLocation.customerDeviationListItemsInput?.map(
                                                  (_customer, index) => {
                                                    return (
                                                      <ListItem
                                                        divider
                                                        key={index}
                                                        className={
                                                          classes.noPaddingListRow
                                                        }
                                                      >
                                                        <Grid
                                                          xs={12}
                                                          item
                                                          container
                                                          key={index}
                                                          style={{
                                                            width: '100%',
                                                          }}
                                                        >
                                                          <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="flex-start"
                                                            style={{
                                                              width: '100%',
                                                              marginBottom: 5,
                                                            }}
                                                          >
                                                            <Grid item xs={4}>
                                                              {props.values.obj[
                                                                locIndex
                                                              ]
                                                                .customerDeviationListItemsInput[
                                                                index
                                                              ]
                                                                .isNewCustomer ? (
                                                                <CommonTextField
                                                                  name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.newCustomer`}
                                                                  size="small"
                                                                />
                                                              ) : (
                                                                <SelectLocation
                                                                  key={`obj.${locIndex}.customerDeviationListItemsInput_${index}`}
                                                                  name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.locationId`}
                                                                  className={
                                                                    classes.selecLocationField
                                                                  }
                                                                  size="small"
                                                                  smallFontSize
                                                                  noLabel
                                                                  smallPadding
                                                                  shortAddress={
                                                                    true
                                                                  }
                                                                />
                                                              )}
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                              <CheckboxField
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.isNewCustomer`}
                                                                label={t(
                                                                  'attributes.newCustomer',
                                                                )}
                                                                customerName={`obj.${locIndex}.customerDeviationListItemsInput.${index}`}
                                                                size="small"
                                                              />
                                                            </Grid>

                                                            <Grid item xs={1}>
                                                              <FastTextFieldPackage
                                                                noLabel
                                                                size="small"
                                                                key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.packages`}
                                                                smallFontSize
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.packages`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                                smallPadding
                                                              />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                              <FastTextFieldPallets
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.pallets`}
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.pallets`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                                smallPadding
                                                              />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                              <FastTextFieldPalletSpace
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                smallPadding
                                                                key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.palletSpace`}
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.palletSpace`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                              />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                              <FastTextFieldWeight
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                smallPadding
                                                                key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.weight`}
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.weight`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                              />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                              <FastTextFieldNote
                                                                noLabel
                                                                size="small"
                                                                multiline
                                                                smallFontSize
                                                                key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.locationItemNote`}
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.locationItemNote`}
                                                                className={
                                                                  classes.field
                                                                }
                                                                smallPadding
                                                              />
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={1}
                                                              container
                                                              direction="row"
                                                              alignItems="center"
                                                              justifyContent="center"
                                                            >
                                                              <>
                                                                {index ===
                                                                0 ? null : (
                                                                  <IconButton
                                                                    className={
                                                                      classes.deleteButton
                                                                    }
                                                                    onClick={() => {
                                                                      arrayHelpers.remove(
                                                                        index,
                                                                      );
                                                                    }}
                                                                  >
                                                                    <DeleteIcon />
                                                                  </IconButton>
                                                                )}
                                                              </>
                                                            </Grid>
                                                          </Box>
                                                          <Grid container>
                                                            {/* for reason */}

                                                            <Grid
                                                              item
                                                              xs={1}
                                                              direction="row"
                                                            >
                                                              <Typography
                                                                style={{
                                                                  fontSize: 12,
                                                                }}
                                                                color="textPrimary"
                                                              >{`Reason -  `}</Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                              <SelectEnumFieldSmall
                                                                name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.reason`}
                                                                key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.reason`}
                                                                enumObject={
                                                                  Reason
                                                                }
                                                                translationPath={
                                                                  'enums.remainingGoodsReasonClass'
                                                                }
                                                                reasonPath={`obj.${locIndex}.customerDeviationListItemsInput.${index}`}
                                                              />
                                                            </Grid>

                                                            {props.values.obj[
                                                              locIndex
                                                            ]
                                                              .customerDeviationListItemsInput[
                                                              index
                                                            ].reason &&
                                                            props.values.obj[
                                                              locIndex
                                                            ]
                                                              .customerDeviationListItemsInput[
                                                              index
                                                            ].reason ===
                                                              Reason.Other ? (
                                                              <Grid item xs={5}>
                                                                <CommonTextField
                                                                  name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.otherReason`}
                                                                  smallPadding
                                                                  fullWidth
                                                                />
                                                              </Grid>
                                                            ) : null}
                                                            <Grid item xs={1}>
                                                              <Tooltip
                                                                title={`${t(
                                                                  'attributes.tooltipDangerousGoodsIcon',
                                                                )}`}
                                                              >
                                                                <div>
                                                                  <CheckboxIconField
                                                                    name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.isDangerous`}
                                                                    icon={
                                                                      <DangerIcon
                                                                        style={{
                                                                          color:
                                                                            'grey',
                                                                        }}
                                                                      />
                                                                    }
                                                                    checkedIcon={
                                                                      <DangerIcon
                                                                        style={{
                                                                          color:
                                                                            'red',
                                                                        }}
                                                                      />
                                                                    }
                                                                    size="small"
                                                                  />
                                                                </div>
                                                              </Tooltip>
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      </ListItem>
                                                    );
                                                  },
                                                )}
                                              </List>
                                            </Grid>
                                          </Grid>
                                        )}
                                      />
                                    </Box>
                                  </ListItem>
                                ),
                              )}
                            </List>
                          </Grid>
                        </>
                      )}
                    />
                  </Box>

                  <Box className={clsx(classes.row, classes.center)}>
                    <Button type="submit" variant="contained" color="primary">
                      {initialValue.obj[0].id && initialValue.obj[0].id !== 0
                        ? t('actions.update')
                        : t('actions.save')}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      {t('actions.cancel')}
                    </Button>
                  </Box>
                </>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
}
