import { Box, Grid, Input, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

interface UpdateCardFormProps {
  onSubmit: (values: { name: string; id: number }) => void;
  name: string;
  type: string;
}

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.name'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
  });

export function UpdateCardForm(props: UpdateCardFormProps) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { type } = props;

  return (
    <Formik
      initialValues={{
        name: props.name,
        id: parseInt(id, 10),
      }}
      validationSchema={validationSchema(t)}
      onSubmit={props.onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={5} justifyContent="center">
            <Grid item sm={8}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label={t('attributes.name')}
                value={props.values.name}
                onChange={props.handleChange}
                error={props.touched.name && Boolean(props.errors.name)}
                helperText={props.touched.name && props.errors.name}
              />
            </Grid>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={5}
            >
              <Input
                type="submit"
                value={t('button.edit', {
                  item: t(`resource.${type}.lowercased`),
                })}
                color="primary"
              />
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
