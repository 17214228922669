import {
  Button,
  Grid,
  CircularProgress,
  Chip,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetTagsDocument,
  useCreateContactTagMutation,
  useDeleteContactTagMutation,
  useGetTagsQuery,
} from '../../generated/graphql';
import { DialogModal } from '../DialogModal';
import { AddContactTagForm } from '../forms/AddContactTagForm';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '80vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
  overflowX: 'hidden',
};

interface AddTagModalProps {
  handleClose: () => void;
  open: boolean;
}

interface TagType {
  id: number;
  title: string;
}

export function ContactTagModal(props: AddTagModalProps) {
  const { handleClose, open } = props;
  const { t } = useTranslation();

  const [isAddFormVisible, setAddFormVisible] = useState<boolean>(false);
  const [isDeleteBoxVisible, setDeleteBoxVisible] = useState<boolean>(false);
  const [tagToDelete, setTagToDelete] = useState<TagType>({ id: 0, title: '' });
  const [tagData, setTagData] = useState<TagType[]>([]);
  const { data, loading } = useGetTagsQuery();

  const [addNewTagApi] = useCreateContactTagMutation({
    refetchQueries: [
      {
        query: GetTagsDocument,
      },
    ],
  });

  const [deleteTagApi] = useDeleteContactTagMutation({
    refetchQueries: [
      {
        query: GetTagsDocument,
      },
    ],
  });

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.cardContactTags &&
      data.cardContactTags.length > 0
    ) {
      setTagData(_.orderBy(data.cardContactTags, 'id', 'asc'));
    }
  }, [data, loading]);

  const handleDeleteTag = (tagId: number, tagTitle: string) => {
    setTagToDelete({
      id: tagId,
      title: tagTitle,
    });
    setDeleteBoxVisible(true);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{'Contact Tags'}</Typography>
          <IconButton style={{ marginLeft: 8 }} onClick={handleClose}>
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
        </Box>
        {isAddFormVisible == false && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAddFormVisible(true);
            }}
          >
            {t('button.add', {
              item: t('attributes.tag'),
            })}
          </Button>
        )}
        <>
          <DialogModal
            open={isDeleteBoxVisible}
            setOpen={setDeleteBoxVisible}
            closeAction={() => setDeleteBoxVisible(false)}
            contentText={String.raw`${t('validation.deleteConfirmation')}${
              tagToDelete.title
            }?`}
            doAction={() => {
              if (tagToDelete.id !== 0) {
                deleteTagApi({
                  variables: {
                    tagId: tagToDelete.id,
                  },
                });
                setTagToDelete({ id: 0, title: '' });
              }
            }}
          />
          <Grid item container>
            {isAddFormVisible && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                mb={2}
              >
                <AddContactTagForm
                  onSubmit={(title) => {
                    //add
                    addNewTagApi({
                      variables: {
                        input: {
                          title,
                        },
                      },
                    });
                    setAddFormVisible(false);
                  }}
                  onCancel={() => {
                    setAddFormVisible(false);
                  }}
                />
              </Box>
            )}
          </Grid>
          {loading && (
            <div style={{ width: '90%', margin: 8 }}>
              <CircularProgress style={{ margin: 8, alignSelf: 'center' }} />
            </div>
          )}
          {!loading &&
          tagData &&
          tagData.length > 0 &&
          isAddFormVisible == false ? (
            <>
              <Divider style={{ marginTop: 3, marginBottom: 3 }} />
              <Grid
                container
                spacing={2}
                direction="row"
                style={{ marginTop: 8 }}
              >
                {tagData.map((tag, index) => {
                  return (
                    <Chip
                      label={tag.title}
                      variant="outlined"
                      color="primary"
                      key={index}
                      style={{ margin: 5 }}
                      onDelete={() => {
                        handleDeleteTag(tag.id, tag.title);
                      }}
                      //deleteIcon={<DeleteIcon />}
                    />
                  );
                })}
              </Grid>
            </>
          ) : (
            !loading &&
            isAddFormVisible == false && (
              <div style={{ width: '90%', margin: 8 }}>{`No tag found!`}</div>
            )
          )}
        </>
      </Box>
    </Modal>
  );
}
