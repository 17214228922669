import {
  debounce,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {
  useGetCardTypeLocationsLazyQuery,
  useGetLocationForSelectLazyQuery,
} from '../../generated/graphql';
import clsx from 'clsx';

interface SelectTerminalLocationProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  withLink?: boolean;
  cardType: string;
}

interface SelectTerminalLocationInterface {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  country: string;
  city: string;
  card: {
    name: string;
    type: string;
    id: number;
  };
}

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
    },
  },
});

function getLocationInfo(location: SelectTerminalLocationInterface | null) {
  if (location?.card?.name === 'BLANK') {
    return '';
  } else {
    return `${location?.card?.name} - ${location?.country}`;
  }
}

export function SelectCardLocations(
  props: SelectTerminalLocationProps & TextFieldProps,
) {
  const {
    name,
    readOnly = false,
    fullWidth = false,
    size = 'medium',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    withLink = false,
    cardType = 'Terminal',
  } = props;
  const { url } = useRouteMatch();
  const [terminal] = useState(url ? url.startsWith('/terminal/') : false);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<SelectTerminalLocationInterface[]>([]);

  const { getFieldProps } = useFormikContext();
  const [value, setValue] = useState<SelectTerminalLocationInterface | null>(
    null,
  );
  const [getLocation, { data: locationData, loading }] =
    useGetLocationForSelectLazyQuery();

  const initialValue = getFieldProps(name).value;
  const classes = useStyles();

  useEffect(() => {
    if (initialValue != null) {
      getLocation({ variables: { locationId: initialValue } });
    }
  }, [initialValue]);

  const [getTerminalLocation, { data: optionsData, loading: loadingOptions }] =
    useGetCardTypeLocationsLazyQuery();

  useEffect(() => {
    if (search.length > 2) {
      getTerminalLocation({
        variables: { cardType: cardType, search: search },
      });
    }
  }, [search]);

  useEffect(() => {
    if (locationData) {
      setOptions([locationData.location]);
      setValue(locationData.location);
    }
  }, [locationData]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.getCardTypeLocations]);
      } else {
        setOptions(optionsData.getCardTypeLocations);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  if (readOnly) {
    const textComponent = (
      <TextField
        {...props}
        variant="outlined"
        className={props.className}
        label={
          noLabel
            ? undefined
            : cardType === 'Customer'
            ? 'Customer'
            : 'Terminal'
        }
        value={getLocationInfo(value)}
        size={size}
        inputProps={{
          style: {
            fontSize: smallFontSize ? '12px' : undefined,
            padding: smallPadding ? '4px' : undefined,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
    if (withLink)
      return (
        <Link
          to={
            terminal
              ? `/terminal/${value?.card.type.toLocaleLowerCase()}s/${
                  value?.card.id
                }`
              : `/traffic/${value?.card.type.toLocaleLowerCase()}s/${
                  value?.card.id
                }`
          }
          target="_blank"
          rel="noopener noreferrer"
          className={clsx(props.className, classes.link)}
        >
          {textComponent}
        </Link>
      );
    return textComponent;
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <Autocomplete
          {...field}
          value={value}
          multiple={false}
          fullWidth={fullWidth}
          autoHighlight
          size={size}
          onChange={(_event, val) => form.setFieldValue(name, val?.id)}
          onInputChange={debouncedChangeHandler}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => getLocationInfo(option)}
          options={options}
          loading={loading || loadingOptions}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size={size}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              variant="outlined"
              label={
                noLabel
                  ? undefined
                  : cardType === 'Customer'
                  ? 'Customer'
                  : 'Terminal'
              }
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: smallFontSize ? '12px' : undefined,
                  padding: smallPadding ? '0' : undefined,
                },
              }}
            />
          )}
        />
      )}
    </Field>
  );
}
