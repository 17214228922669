import { ApolloClient } from '@apollo/client';
import {
  GridColDef,
  GridColumns,
  GridDensity,
  GridFilterModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  useGetMeQuery,
  useUpdateUserConfigurationMutation,
} from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { getRandomNumber } from '../lib/date';
import { LoadingAndError } from '../utils/LoadingAndError';

interface AttachConfiguration {
  (
    columns: GridColDef[],
    columnsConfiguration?: ColumnsConfiguration,
  ): GridColumns;
}

interface GetColumnsConfiguration {
  (tableName: string): {
    [column: string]: Omit<GridColDef, 'field'>;
  };
}

interface GetFilterModel {
  (tableName: string): GridFilterModel;
}

interface GetSortModel {
  (tableName: string): GridSortModel;
}

interface GetCustomConfiguration {
  (tableName: string, key: string): unknown | undefined;
}

interface GetGridDensity {
  (tableName: string): GridDensity;
}

interface UpdateColumnConfiguration {
  (
    tableName: string,
    column: string,
    configuration: Omit<GridColDef, 'field'>,
  ): void;
}

interface UpdateBulkVisibilityConfiguration {
  (tableName: string, columns: string[], newVisibility: boolean): void;
}

interface UpdateFilterConfiguration {
  (tableName: string, filterModel: GridFilterModel): void;
}

interface UpdateSortConfiguration {
  (tableName: string, sortModel: GridSortModel): void;
}

interface UpdateCustomConfiguration {
  (tableName: string, key: string, value: any): void;
}

interface UpdateGridDensity {
  (tableName: string, density: GridDensity): void;
}

interface GetTFTTime {
  (isStartTime: boolean): string;
}
interface GetTFTSameDay {
  (): boolean;
}

interface GetMasterDate {
  (): string;
}

interface GetReportToTerminalView {
  (): {
    startDate: string;
    terminalId: number;
    viewObj: {
      routeView: boolean;
      gridView: boolean;
      valueBoxesView: boolean;
      infoView: boolean;
    };
  };
}
interface SetMasterDate {
  (stringFullDate: string, isTouchTime: boolean, isSameDay?: boolean): void;
}
interface SetTrafficTFTTime {
  (tftTime: string): void;
}
interface SetReportToTerminalView {
  (
    startDate: string,
    terminalId: number,
    viewObj: {
      routeView: boolean;
      gridView: boolean;
      valueBoxesView: boolean;
      infoView: boolean;
    },
  ): void;
}

interface SetFilterForFixed {
  (fromCountry: string, toCountry: string): void;
}

interface ClearConfiguration {
  (): void;
}

interface Department {
  id: string; //'no' | 'se' | 'dk'
  override: boolean;
}

interface UserConfigurationContextValues {
  attachConfiguration?: AttachConfiguration;
  updateColumnConfiguration?: UpdateColumnConfiguration;
  updateColumnVisibilityConfiguration?: UpdateColumnConfiguration;
  updateBulkVisibilityConfiguration?: UpdateBulkVisibilityConfiguration;
  updateFilterConfiguration?: UpdateFilterConfiguration;
  updateSortConfiguration?: UpdateSortConfiguration;
  updateCustomConfiguration?: UpdateCustomConfiguration;
  updateGridDensity?: UpdateGridDensity;
  clearConfiguration?: ClearConfiguration;
  getColumnsConfiguration?: GetColumnsConfiguration;
  getFilterModel?: GetFilterModel;
  getSortModel?: GetSortModel;
  getCustomConfiguration?: GetCustomConfiguration;
  getGridDensity?: GetGridDensity;
  department?: Department;
  hasTrafficAccess?: boolean;
  hasTerminalAccess?: boolean;
  hasCustomsAccess?: boolean;
  isAdmin?: boolean;
  myUserId?: string;
  getTFTTime?: GetTFTTime;
  getTFTReadOnlyTime?: GetTFTTime;
  getTFTSameDay?: GetTFTSameDay;
  getMasterDate?: GetMasterDate;
  setMasterDate?: SetMasterDate;
  setMasterDateForTFTReadOnly?: SetTrafficTFTTime;
  setFilterForFixed?: SetFilterForFixed;
  setReportToTerminalView?: SetReportToTerminalView;
  getReportToTerminalView?: GetReportToTerminalView;
}

const UserConfigurationContext = createContext<UserConfigurationContextValues>({
  attachConfiguration: undefined,
  updateColumnConfiguration: undefined,
  updateColumnVisibilityConfiguration: undefined,
  updateBulkVisibilityConfiguration: undefined,
  updateFilterConfiguration: undefined,
  updateCustomConfiguration: undefined,
  updateGridDensity: undefined,
  clearConfiguration: undefined,
  getColumnsConfiguration: undefined,
  getFilterModel: undefined,
  getSortModel: undefined,
  getCustomConfiguration: undefined,
  getGridDensity: undefined,
  department: undefined,
  hasTerminalAccess: undefined,
  hasCustomsAccess: undefined,
  hasTrafficAccess: undefined,
  isAdmin: undefined,
  myUserId: undefined,
  getTFTTime: undefined,
  getTFTReadOnlyTime: undefined,
  getTFTSameDay: undefined,
  getMasterDate: undefined,
  setMasterDate: undefined,
  setMasterDateForTFTReadOnly: undefined,
  setFilterForFixed: undefined,
  setReportToTerminalView: undefined,
  getReportToTerminalView: undefined,
});

interface ColumnsConfiguration {
  [column: string]: Omit<GridColDef, 'field'>;
}

interface TableConfiguration {
  id?: number;
  filterModel: GridFilterModel;
  sortModel: GridSortModel;
  columns: ColumnsConfiguration;
  custom: {
    [key: string]: string | number | boolean;
  };
  density: GridDensity;
}

interface Configuration {
  [tableName: string]: TableConfiguration;
}

interface UserConfigurationProviderProps {
  children: ReactNode;
  client?: ApolloClient<unknown>;
}

export function UserConfigurationProvider({
  children,
}: UserConfigurationProviderProps) {
  const { data, loading, error } = useGetMeQuery();
  const [updateUserConfiguration] = useUpdateUserConfigurationMutation();

  const [configurationState, setConfigurationState] = useState<{
    id: number;
    configuration: Configuration;
  }>({ id: 0, configuration: {} });

  const setConfiguration = (newConfiguration: Configuration) => {
    const newConfigurationState = {
      id: configurationState.id + 1,
      configuration: newConfiguration ?? {},
    };
    setConfigurationState(newConfigurationState);
  };

  //new for column visibility check
  const setVisibilityConfiguration = (newConfiguration: Configuration) => {
    const newConfigurationState = {
      id: getRandomNumber(configurationState.id),
      configuration: newConfiguration ?? {},
    };
    setConfigurationState(newConfigurationState);
  };

  const department = {
    override: false,
    id: 'se',
  };
  const [isConfigurationSet, setIsConfigurationSet] = useState(false);

  useEffect(() => {
    if (data != null && !isConfigurationSet) {
      setConfiguration(data?.me.configuration);
      setIsConfigurationSet(true);
    }
  }, [data]);

  useEffect(() => {
    if (data != null && isConfigurationSet) {
      updateUserConfiguration({
        variables: {
          input: {
            configuration: configurationState.configuration,
          },
        },
        optimisticResponse: {
          updateConfiguration: {
            ...data?.me,
            __typename: 'PublicUser',
            configuration: configurationState.configuration,
          },
        },
      });
    }
  }, [configurationState.id]);

  const attachConfiguration = (
    columns: GridColDef[],
    columnsConfiguration?: ColumnsConfiguration,
  ) => {
    if (columnsConfiguration == null) {
      return columns;
    }

    return columns.map((column) => {
      const columnConfiguration = columnsConfiguration[column.field];

      if (columnConfiguration == null) {
        return column;
      }

      return { ...column, ...columnConfiguration };
    });
  };

  const getFilterModel = useCallback(
    (tableName: string): GridFilterModel => {
      return configurationState.configuration[tableName]?.filterModel;
    },
    [configurationState.id],
  );

  const getCustomConfiguration = useCallback(
    (tableName: string, key: string) => {
      const tableConfiguration = configurationState.configuration[tableName];
      if (tableConfiguration == null) {
        return undefined;
      }
      const customConfiguration = tableConfiguration.custom;

      if (customConfiguration == null) {
        return undefined;
      }

      return customConfiguration[key];
    },
    [configurationState.id],
  );

  const getGridDensity = useCallback(
    (tableName: string): GridDensity => {
      return configurationState.configuration[tableName]?.density;
    },
    [configurationState.id],
  );

  const getSortModel = useCallback(
    (tableName: string): GridSortModel => {
      return configurationState.configuration[tableName]?.sortModel;
    },
    [configurationState.id],
  );

  const getColumnsConfiguration = useCallback(
    (tableName: string): ColumnsConfiguration => {
      return configurationState.configuration[tableName]?.columns;
    },
    [configurationState.id],
  );

  const updateColumnConfiguration = useCallback(
    (
      tableName: string,
      column: string,
      newColumnConfiguration: Omit<GridColDef, 'field'>,
    ) => {
      const userConfiguration = configurationState.configuration ?? {};
      const tableConfiguration = userConfiguration[tableName] ?? {
        columns: {},
      };
      const tableColumnsConfiguration = tableConfiguration.columns ?? {};

      const currentColumnConfiguration =
        tableColumnsConfiguration[column] ?? {};

      const columnConfiguration = {
        ...currentColumnConfiguration,
        ...newColumnConfiguration,
      };

      const newUserConfiguration = {
        ...userConfiguration,
        [tableName]: {
          ...tableConfiguration,
          columns: {
            ...tableConfiguration.columns,
            [column]: columnConfiguration,
          },
        },
      };

      setConfiguration(newUserConfiguration);
    },
    [configurationState.id],
  );

  //For column visibility
  const updateColumnVisibilityConfiguration = useCallback(
    (
      tableName: string,
      column: string,
      newColumnConfiguration: Omit<GridColDef, 'field'>,
    ) => {
      const userConfiguration = configurationState.configuration ?? {};

      const tableConfiguration = userConfiguration[tableName] ?? {
        columns: {},
      };

      const tableColumnsConfiguration = tableConfiguration.columns ?? {};

      const currentColumnConfiguration =
        tableColumnsConfiguration[column] ?? {};

      const columnConfiguration = {
        ...currentColumnConfiguration,
        ...newColumnConfiguration,
      };

      const newUserConfiguration = {
        ...userConfiguration,
        [tableName]: {
          ...tableConfiguration,
          columns: {
            ...tableConfiguration.columns,
            [column]: columnConfiguration,
          },
        },
      };
      setVisibilityConfiguration(newUserConfiguration);
    },
    [configurationState.id],
  );

  const updateBulkVisibilityConfiguration = useCallback(
    (tableName: string, columns: string[], isVisible: boolean) => {
      const userConfiguration = configurationState.configuration ?? {};
      const tableConfiguration = userConfiguration[tableName] ?? {
        columns: {},
      };
      const tableColumnsConfiguration = tableConfiguration.columns ?? {};
      let newTableColumnsConfiguration: ColumnsConfiguration =
        tableConfiguration.columns ?? {};

      if (columns && columns.length > 0) {
        columns.map((col) => {
          const currentColumnConfiguration =
            tableColumnsConfiguration[col] ?? {};

          const columnConfig = {
            ...currentColumnConfiguration,
            hide: !isVisible,
          };

          newTableColumnsConfiguration = {
            ...newTableColumnsConfiguration,
            [col]: columnConfig,
          };
        });
      }

      const newUserConfiguration = {
        ...userConfiguration,
        [tableName]: {
          ...tableConfiguration,
          columns: newTableColumnsConfiguration,
        },
      };
      setVisibilityConfiguration(newUserConfiguration);
    },
    [configurationState.id],
  );

  const updateConfiguration = useCallback(
    <T extends keyof Configuration[string]>(
      tableName: string,
      key: keyof Configuration[string],
      value: Configuration[string][T],
    ) => {
      const userConfiguration = configurationState.configuration ?? {};
      const tableConfiguration = userConfiguration[tableName] ?? {};

      const newUserConfiguration = {
        ...userConfiguration,
        [tableName]: {
          ...tableConfiguration,
          [key]: value,
        },
      };
      setConfiguration(newUserConfiguration);
    },
    [configurationState.id],
  );

  const updateFilterConfiguration = (
    tableName: string,
    filterModel: GridFilterModel,
  ) => {
    updateConfiguration(tableName, 'filterModel', filterModel);
  };

  const updateSortConfiguration = (
    tableName: string,
    sortModel: GridSortModel,
  ) => {
    updateConfiguration(tableName, 'sortModel', sortModel);
  };

  const updateGridDensity = (tableName: string, density: GridDensity) => {
    updateConfiguration(tableName, 'density', density);
  };

  const getTFTTime = (isStartTime: boolean): string => {
    const value = getCustomConfiguration(
      TABLE_NAMES.TruckFillAndTime,
      'tftTime',
    );
    if (value && typeof value === 'string') {
      return isStartTime ? value.split('_')[0] : value.split('_')[1];
    } else {
      return isStartTime ? '00:00' : '23:59';
    }
  };

  const getTFTReadOnlyTime = (isStartTime: boolean): string => {
    const value = getCustomConfiguration(TABLE_NAMES.TFTReadOnly, 'tftTime');
    if (value && typeof value === 'string') {
      return isStartTime ? value.split('_')[0] : value.split('_')[1];
    } else {
      return isStartTime ? '00:00' : '23:59';
    }
  };

  const getTFTSameDay = (): boolean => {
    const value = getCustomConfiguration(
      TABLE_NAMES.TruckFillAndTime,
      'isSameDay',
    );
    if (value && typeof value === 'boolean') {
      return value;
    }
    return false;
  };

  const setFilterForFixed = (fromCountry: string, toCountry: string) => {
    updateCustomConfiguration(
      TABLE_NAMES.FixedRoutes,
      'countryFilter',
      `${fromCountry}#${toCountry}`,
    );
  };

  const setReportToTerminalView = (
    startDate: string,
    terminalId: number,
    viewObj: {
      routeView: boolean;
      gridView: boolean;
      valueBoxesView: boolean;
      infoView: boolean;
    },
  ) => {
    const newCustomConfiguration = {
      ...(configurationState.configuration[TABLE_NAMES.TFTReadOnly]?.custom ??
        {}),
      ['date']: startDate,
      ['routeView']: viewObj.routeView,
      ['gridView']: viewObj.gridView,
      ['valueBoxesView']: viewObj.valueBoxesView,
      ['infoView']: viewObj.infoView,
      ['terminalId']: terminalId,
    };
    updateConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'custom',
      newCustomConfiguration,
    );
  };

  const setMasterDate = (
    stringFullDate: string,
    isTouchTime: boolean,
    isSameDay?: boolean,
  ) => {
    //stringFullDate -> yyyy-MM-dd HH:mm#yyyy-MM-dd HH:mm

    const startDate = `${stringFullDate.split('#')[0].split(' ')[0]}`;
    const startTime = `${stringFullDate.split('#')[0].split(' ')[1]}`;
    const endDate = `${stringFullDate.split('#')[1].split(' ')[0]}`;
    const endTime = `${stringFullDate.split('#')[1].split(' ')[1]}`;

    if (isTouchTime && isSameDay != undefined) {
      updateTftDateAndDayConfiguration(
        'master_date',
        `${startDate}#${endDate}`,
        'tftTime',
        `${startTime}_${endTime}`,
        isSameDay,
      );
    } else if (isTouchTime) {
      updateTftDateConfiguration(
        'master_date',
        `${startDate}#${endDate}`,
        'tftTime',
        `${startTime}_${endTime}`,
      );
    } else {
      updateCustomConfiguration(
        TABLE_NAMES.TruckFillAndTime,
        'master_date',
        `${startDate}#${endDate}`,
      );
    }
  };

  const setMasterDateForTFTReadOnly = (stringFullDate: string) => {
    //stringFullDate -> yyyy-MM-dd HH:mm#yyyy-MM-dd HH:mm
    const startTime = `${stringFullDate.split('#')[0].split(' ')[1]}`;
    const endTime = `${stringFullDate.split('#')[1].split(' ')[1]}`;
    updateTFTReadOnlyDateConfiguration('tftTime', `${startTime}_${endTime}`);
  };

  const getMasterDate = (): string => {
    const value = getCustomConfiguration(
      TABLE_NAMES.TruckFillAndTime,
      'master_date',
    );
    if (value && typeof value === 'string') {
      return value;
    } else {
      return '-';
    }
  };

  const getReportToTerminalView = (): {
    startDate: string;
    terminalId: number;
    viewObj: {
      routeView: boolean;
      gridView: boolean;
      valueBoxesView: boolean;
      infoView: boolean;
    };
  } => {
    const startDate = getCustomConfiguration(TABLE_NAMES.TFTReadOnly, 'date');
    const gridView = getCustomConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'gridView',
    );
    const terminalId = getCustomConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'terminalId',
    );
    const infoView = getCustomConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'infoView',
    );
    const routeView = getCustomConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'routeView',
    );
    const valueBoxesView = getCustomConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'valueBoxesView',
    );
    const viewObj = {
      gridView: typeof gridView === 'boolean' ? gridView : false,
      infoView: typeof infoView === 'boolean' ? infoView : false,
      routeView: typeof routeView === 'boolean' ? routeView : false,
      valueBoxesView:
        typeof valueBoxesView === 'boolean' ? valueBoxesView : false,
    };
    return {
      startDate: typeof startDate === 'string' ? startDate : '',
      terminalId: typeof terminalId === 'number' ? terminalId : 0,
      viewObj: viewObj,
    };
  };

  const updateCustomConfiguration = (
    tableName: string,
    key: string,
    value: string,
  ) => {
    const newCustomConfiguration = {
      ...(configurationState.configuration[tableName]?.custom ?? {}),
      [key]: value,
    };
    updateConfiguration(tableName, 'custom', newCustomConfiguration);
  };

  const updateTftDateConfiguration = (
    masterDate: string,
    dateValue: string,
    tftTime: string,
    timeValue: string,
  ) => {
    const newCustomConfiguration = {
      ...(configurationState.configuration[TABLE_NAMES.TruckFillAndTime]
        ?.custom ?? {}),
      [masterDate]: dateValue,
      [tftTime]: timeValue,
    };
    updateConfiguration(
      TABLE_NAMES.TruckFillAndTime,
      'custom',
      newCustomConfiguration,
    );
  };

  const updateTFTReadOnlyDateConfiguration = (
    tftTime: string,
    timeValue: string,
  ) => {
    const newCustomConfiguration = {
      ...(configurationState.configuration[TABLE_NAMES.TFTReadOnly]?.custom ??
        {}),
      [tftTime]: timeValue,
    };
    updateConfiguration(
      TABLE_NAMES.TFTReadOnly,
      'custom',
      newCustomConfiguration,
    );
  };

  const updateTftDateAndDayConfiguration = (
    masterDate: string,
    dateValue: string,
    tftTime: string,
    timeValue: string,
    sameDay: boolean,
  ) => {
    const newCustomConfiguration = {
      ...(configurationState.configuration[TABLE_NAMES.TruckFillAndTime]
        ?.custom ?? {}),
      [masterDate]: dateValue,
      [tftTime]: timeValue,
      ['isSameDay']: sameDay,
    };
    updateConfiguration(
      TABLE_NAMES.TruckFillAndTime,
      'custom',
      newCustomConfiguration,
    );
  };

  const clearConfiguration = useCallback(() => {
    setConfiguration({});
  }, []);

  return (
    <LoadingAndError data={data} loading={loading} error={error}>
      {() => (
        <UserConfigurationContext.Provider
          value={{
            attachConfiguration,
            getColumnsConfiguration,
            getFilterModel,
            getSortModel,
            getCustomConfiguration,
            getGridDensity,
            updateColumnConfiguration,
            updateColumnVisibilityConfiguration,
            updateBulkVisibilityConfiguration,
            updateGridDensity,
            updateFilterConfiguration,
            updateSortConfiguration,
            updateCustomConfiguration,
            clearConfiguration,
            department,
            getTFTTime,
            getTFTReadOnlyTime,
            getTFTSameDay,
            setMasterDate,
            setMasterDateForTFTReadOnly,
            setFilterForFixed,
            getMasterDate,
            getReportToTerminalView,
            hasTerminalAccess: data?.me.terminalAccess,
            hasCustomsAccess: data?.me.customsAccess,
            hasTrafficAccess: data?.me.trafficAccess,
            isAdmin: data?.me.admin,
            myUserId: data?.me.id ?? '',
            setReportToTerminalView,
          }}
        >
          {children}
        </UserConfigurationContext.Provider>
      )}
    </LoadingAndError>
  );
}

export function useUserConfiguration() {
  const context = useContext(UserConfigurationContext);
  if (context === undefined) {
    throw new Error(
      'useUserConfiguration must be used within a UserConfigurationProvider',
    );
  }

  return context as {
    attachConfiguration: AttachConfiguration;
    filterModel: GridFilterModel;
    getFilterModel: GetFilterModel;
    getSortModel: GetSortModel;
    getCustomConfiguration: GetCustomConfiguration;
    getColumnsConfiguration: GetColumnsConfiguration;
    getGridDensity: GetGridDensity;
    updateColumnConfiguration: UpdateColumnConfiguration;
    updateColumnVisibilityConfiguration: UpdateColumnConfiguration;
    updateBulkVisibilityConfiguration: UpdateBulkVisibilityConfiguration;
    updateFilterConfiguration: UpdateFilterConfiguration;
    updateSortConfiguration: UpdateSortConfiguration;
    updateCustomConfiguration: UpdateCustomConfiguration;
    updateGridDensity: UpdateGridDensity;
    clearConfiguration: ClearConfiguration;
    hasTerminalAccess: boolean;
    hasCustomsAccess: boolean;
    hasTrafficAccess: boolean;
    isAdmin: boolean;
    myUserId: string;
    department: Department;
    getTFTTime: GetTFTTime;
    getTFTReadOnlyTime: GetTFTTime;
    getTFTSameDay: GetTFTSameDay;
    setMasterDate: SetMasterDate;
    setMasterDateForTFTReadOnly: SetTrafficTFTTime;
    setFilterForFixed: SetFilterForFixed;
    getMasterDate: GetMasterDate;
    setReportToTerminalView: SetReportToTerminalView;
    getReportToTerminalView: GetReportToTerminalView;
  };
}
