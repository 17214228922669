import { Button, Grid, CircularProgress } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FrequencyDays,
  GetMasterValueBoxDocument,
  useAddMasterValueBoxMutation,
  useDeleteMasterValueBoxMutation,
  useEditMasterValueBoxMutation,
  useGetMasterValueBoxQuery,
} from '../../generated/graphql';
import { DialogModal } from '../DialogModal';
import { AddValueBoxForm } from '../forms/AddValueBoxForm';
import { ValueBoxDays } from '../ValueBoxDays';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface ValueBoxInput {
  id?: number | undefined;
  boxName: string;
  frequency: FrequencyDays[];
  isYesNoBox: boolean;
}

interface NewBoxInput {
  id?: number | undefined;
  internalTerminalId: number;
  boxName: string;
  frequency: FrequencyDays[];
  isFixedBox: boolean;
  isYesNoBox: boolean;
  isTimeBox: boolean;
  isAddedFromTerminal: boolean;
}

interface ValueBoxModalProps {
  handleClose: () => void;
  open: boolean;
  internalTerminalId: number;
  terminalName: string;
  isOpenFromTerminal: boolean;
}

export function ValueBoxModal(props: ValueBoxModalProps) {
  const {
    handleClose,
    open,
    internalTerminalId,
    terminalName,
    isOpenFromTerminal,
  } = props;
  const { t } = useTranslation();

  const [isAddFormVisible, setAddFormVisible] = useState<boolean>(false);
  const [isDeleteBoxVisible, setDeleteBoxVisible] = useState<boolean>(false);
  const [initValues, setInitValues] = useState<NewBoxInput>({
    id: undefined,
    internalTerminalId: internalTerminalId,
    boxName: '',
    frequency: [],
    isAddedFromTerminal: false,
    isFixedBox: false,
    isYesNoBox: false,
    isTimeBox: false,
  });

  const { data, loading } = useGetMasterValueBoxQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      internalTerminalId: internalTerminalId,
      forTerminal: isOpenFromTerminal,
    },
  });
  const [addNewValueBoxApi] = useAddMasterValueBoxMutation({
    refetchQueries: [
      {
        query: GetMasterValueBoxDocument,
        variables: {
          internalTerminalId: internalTerminalId,
          forTerminal: isOpenFromTerminal,
        },
      },
    ],
  });
  const [editValueBoxApi] = useEditMasterValueBoxMutation({
    refetchQueries: [
      {
        query: GetMasterValueBoxDocument,
        variables: {
          internalTerminalId: internalTerminalId,
          forTerminal: isOpenFromTerminal,
        },
      },
    ],
  });
  const [deleteValueBoxApi] = useDeleteMasterValueBoxMutation({
    refetchQueries: [
      {
        query: GetMasterValueBoxDocument,
        variables: {
          internalTerminalId: internalTerminalId,
          forTerminal: isOpenFromTerminal,
        },
      },
    ],
  });

  const openUpdateForm = useCallback((box: ValueBoxInput) => {
    setInitValues({
      ...initValues,
      id: box.id,
      frequency: box.frequency,
      boxName: box.boxName,
      isYesNoBox: box.isYesNoBox,
    });
    setAddFormVisible(true);
  }, []);

  const openDeleteDialog = useCallback((box: ValueBoxInput) => {
    setInitValues({
      ...initValues,
      id: box.id,
      frequency: box.frequency,
      boxName: box.boxName,
    });
    setDeleteBoxVisible(true);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{'VALUE BOXES'}</Typography>
          <Typography style={{ fontSize: 16 }}>{`${terminalName}`}</Typography>
          <IconButton style={{ marginLeft: 8 }} onClick={handleClose}>
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
        </Box>
        {isAddFormVisible === false && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setInitValues({
                id: undefined,
                internalTerminalId: internalTerminalId,
                boxName: '',
                frequency: [],
                isFixedBox: false,
                isYesNoBox: false,
                isTimeBox: false,
                isAddedFromTerminal: isOpenFromTerminal,
              });
              setAddFormVisible(true);
            }}
          >
            {t('button.add', {
              item: t('attributes.box'),
            })}
          </Button>
        )}
        <>
          <DialogModal
            open={isDeleteBoxVisible}
            setOpen={setDeleteBoxVisible}
            closeAction={() => setDeleteBoxVisible(false)}
            contentText={t('validation.confirmation', {
              item: `${initValues.boxName}`,
              action: t('actions.delete.lowercased'),
            })}
            doAction={() => {
              deleteValueBoxApi({
                variables: {
                  boxId: initValues.id ? initValues.id : 0,
                },
              });
            }}
          />
          <Grid item container>
            {isAddFormVisible && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                mb={2}
              >
                <AddValueBoxForm
                  isOpenFromTerminal={isOpenFromTerminal}
                  initialValues={initValues}
                  onSubmit={(boxData) => {
                    if (boxData.id) {
                      //update
                      editValueBoxApi({
                        variables: {
                          boxData: {
                            boxId: boxData.id,
                            boxName: boxData.boxName,
                            frequency: boxData.frequency,
                          },
                        },
                      });
                    } else {
                      //add
                      addNewValueBoxApi({
                        variables: {
                          boxData,
                        },
                      });
                    }
                    setAddFormVisible(false);
                  }}
                  onCancel={() => {
                    setAddFormVisible(false);
                  }}
                />
              </Box>
            )}
          </Grid>
          {loading && (
            <div style={{ width: '90%', margin: 8 }}>
              <CircularProgress style={{ margin: 8, alignSelf: 'center' }} />
            </div>
          )}
          {!loading &&
          data &&
          data.getMasterValueBoxes.length > 0 &&
          !isAddFormVisible ? (
            <div style={{ width: '90%', margin: 8, alignSelf: 'center' }}>
              <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item xs={isOpenFromTerminal ? 5 : 3}>{`BOX NAME`}</Grid>
                <Grid item xs={1}>{`Only Y/N`}</Grid>
                {!isOpenFromTerminal && <Grid item xs={6}>{`FREQUENCY`}</Grid>}
              </Grid>
              {data.getMasterValueBoxes.map((box) => {
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      {!box.isFixedBox && (
                        <div style={{ flexDirection: 'row' }}>
                          <IconButton
                            style={{ marginRight: 4 }}
                            onClick={() => openDeleteDialog(box)}
                          >
                            <DeleteIcon fontSize="small" color="action" />
                          </IconButton>
                          <IconButton
                            style={{ marginRight: 4 }}
                            onClick={() => openUpdateForm(box)}
                          >
                            <EditIcon fontSize="small" color="action" />
                          </IconButton>
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={box.isAddedFromTerminal ? 5 : 3}>
                      <div
                        style={{
                          padding: '4px 6px 4px 6px',
                          backgroundColor: '#d4d4d4',
                          fontSize: 13,
                          justifyContent: 'center',
                          border: '1px solid #000',
                          marginTop: 3,
                        }}
                      >
                        {`${box.boxName}`}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div
                        style={{
                          padding: '4px 6px 4px 6px',
                          justifyContent: 'center',
                          marginTop: 3,
                        }}
                      >
                        {`${box.isYesNoBox ? '✅' : ''}`}
                      </div>
                    </Grid>
                    {!box.isAddedFromTerminal && (
                      <Grid item xs={6}>
                        <ValueBoxDays
                          daysArray={box.frequency}
                          isReadOnly={true}
                        />
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </div>
          ) : (
            !loading &&
            isAddFormVisible == false && (
              <div style={{ width: '90%', margin: 8 }}>{`No box found!`}</div>
            )
          )}
        </>
      </Box>
    </Modal>
  );
}
