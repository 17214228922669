import { Box, FormControlLabel } from '@mui/material';
import {
  Button,
  Checkbox,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ValueBoxDays } from '../ValueBoxDays';
import { useState } from 'react';
import { FrequencyDays } from '../../generated/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    justifyContent: 'center',
    border: '1px solid black',
    borderRadius: 10,
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0, 'auto', 1),
    },
    '& > *:last-child': {
      margin: theme.spacing(0, 'auto', 0),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  distribute: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(1, 1),
    },
  },
  end: {
    justifyContent: 'flex-end',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  errorText: {
    fontSize: 11,
    color: 'red',
  },
}));

interface NewBoxInput {
  id?: number | undefined;
  internalTerminalId: number;
  boxName: string;
  frequency: FrequencyDays[];
  isFixedBox: boolean;
  isYesNoBox: boolean;
  isTimeBox: boolean;
  isAddedFromTerminal: boolean;
}

interface ValueBoxFormProps {
  initialValues: NewBoxInput;
  onSubmit: (values: NewBoxInput) => void;
  onCancel: () => void;
  isOpenFromTerminal: boolean;
}

export function AddValueBoxForm(props: ValueBoxFormProps) {
  const { initialValues, isOpenFromTerminal, onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [showError, setShowError] = useState<boolean>(false);
  const [booleanBox, setBooleanBox] = useState<{
    isYesNoOnly: boolean;
    isTimeBox: boolean;
  }>({
    isYesNoOnly: initialValues.isYesNoBox,
    isTimeBox: initialValues.isTimeBox,
  });
  const [currentBoxName, setCurrBoxName] = useState<string>(
    initialValues.boxName ? initialValues.boxName : '',
  );
  const [currentBoxFrequency, setCurrBoxFrequency] = useState<FrequencyDays[]>(
    initialValues.frequency ? initialValues.frequency : [],
  );

  return (
    <Box>
      <div className={classes.root}>
        <Box className={clsx(classes.row)}>
          <TextField
            id="boxName"
            name="boxName"
            style={{ minWidth: 380, marginRight: 12 }}
            placeholder={t('attributes.boxName')}
            value={currentBoxName}
            onChange={(e) => {
              setCurrBoxName(e.target.value);
              if (showError) setShowError(false);
            }}
          />
          <FormControlLabel
            label={
              <Typography style={{ fontSize: 12 }}>{`Yes/No value`}</Typography>
            }
            control={
              <Checkbox
                name={'yesNo'}
                size="small"
                disabled={initialValues.id !== undefined}
                checked={booleanBox.isYesNoOnly}
                style={{
                  paddingRight: 2,
                  paddingLeft: 4,
                  paddingTop: 6,
                  paddingBottom: 6,
                }}
                onChange={(e) => {
                  setBooleanBox({
                    isYesNoOnly: e.target.checked,
                    isTimeBox: false,
                  });
                }}
              />
            }
          />
          <FormControlLabel
            label={
              <Typography style={{ fontSize: 12 }}>{`Add Time Box`}</Typography>
            }
            control={
              <Checkbox
                name={'timebox'}
                size="small"
                disabled={initialValues.id !== undefined}
                checked={booleanBox.isTimeBox}
                style={{
                  paddingRight: 2,
                  paddingLeft: 4,
                  paddingTop: 6,
                  paddingBottom: 6,
                }}
                onChange={(e) => {
                  setBooleanBox({
                    isYesNoOnly: false,
                    isTimeBox: e.target.checked,
                  });
                }}
              />
            }
          />
        </Box>
        {!isOpenFromTerminal && (
          <Box className={clsx(classes.row)}>
            <ValueBoxDays
              isReadOnly={false}
              daysArray={currentBoxFrequency}
              onDaysChanged={(newFrequency) => {
                setCurrBoxFrequency(newFrequency);
                if (showError) setShowError(false);
              }}
            />
          </Box>
        )}
        {showError && currentBoxName === '' && (
          <Typography
            className={classes.errorText}
          >{`Box name is required!`}</Typography>
        )}
        {showError &&
          currentBoxFrequency.length === 0 &&
          !isOpenFromTerminal && (
            <Typography
              className={classes.errorText}
            >{`Choose at least one day!`}</Typography>
          )}
        <Box className={clsx(classes.row, classes.center)}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //validate
              if (
                currentBoxName === '' ||
                (currentBoxFrequency.length === 0 && !isOpenFromTerminal)
              ) {
                setShowError(true);
              } else {
                onSubmit({
                  id: initialValues.id,
                  boxName: currentBoxName,
                  internalTerminalId: initialValues.internalTerminalId,
                  frequency: currentBoxFrequency,
                  isFixedBox: false,
                  isYesNoBox: booleanBox.isYesNoOnly,
                  isAddedFromTerminal: isOpenFromTerminal,
                  isTimeBox: booleanBox.isTimeBox,
                });
              }
            }}
          >
            {initialValues.id
              ? t('actions.update')
              : t('actions.create.capitalized')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            {t('actions.cancel')}
          </Button>
        </Box>
      </div>
    </Box>
  );
}
