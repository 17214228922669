import { CostAllocationItemType, Department } from '../../generated/graphql';

const DEFAULT_DEPARTMENT = 'se';

export function newCostAllocationItem(
  departments: Pick<Department, 'id'>[] = [],
  totalPercentage = 0,
) {
  return {
    departmentId: departments[0].id ?? DEFAULT_DEPARTMENT,
    type: CostAllocationItemType.Transport,
    includedKpi: true,
    includedPrice: true,
    bringCost: true,
    additionalCost: false,
    additionalDiscount: false,
    percentage: Math.max(100 - totalPercentage, 0),
    cost: 0,
    comment: '',
  };
}
