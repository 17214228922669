import { makeStyles, TextFieldProps, TextField } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface CommonTextFieldProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  maxTextLength?: number;
  variant?: 'outlined' | 'standard' | 'filled';
  labelText?: string;
}

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  smallFontSize: {
    '& input': {
      fontSize: 12,
    },
    '&.MuiOutlinedInput-multiline': {
      fontSize: 12,
    },
  },
  smallPadding: {
    '& input': {
      padding: 4,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 4,
    },
  },
}));

export function CommonTextField(props: CommonTextFieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const {
    name,
    readOnly = false,
    fullWidth = false,
    helperText = undefined,
    InputProps = {},
    size = 'small',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    maxTextLength = 0,
    variant = 'outlined',
    labelText = '',
    ...rest
  } = props;
  const classes = useStyles();

  const nameSplit = name.split('.');
  const label = labelText === '' ? nameSplit[nameSplit.length - 1] : labelText;

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <TextField
          {...props}
          {...field}
          id={name}
          size={size}
          variant={variant}
          inputProps={{
            maxlength: maxTextLength > 0 ? maxTextLength : null,
            style: {
              color: variant === 'standard' ? 'Highlight' : undefined,
            },
          }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          placeholder={noLabel ? undefined : t(`attributes.${label}`)}
          label={noLabel ? undefined : t(`attributes.${label}`)}
          error={meta.touched && Boolean(meta.error)}
          helperText={helperText || (meta.touched && meta.error)}
          fullWidth={fullWidth}
          InputProps={{
            className: clsx([
              classes.input,
              {
                [classes.smallFontSize]: smallFontSize,
                [classes.smallPadding]: smallPadding,
              },
            ]),
            readOnly,
            ...InputProps,
          }}
          InputLabelProps={{
            shrink: true,
            style: {
              color: variant === 'standard' ? 'Highlight' : undefined,
            },
          }}
          {...rest}
        />
      )}
    </Field>
  );
}
