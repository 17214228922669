import { Box, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import {
  CustomerLevelDeviationResponse,
  EditLocationDeviationInput,
} from '../generated/graphql';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { TABLE_NAMES } from '../lib/constants';
import {
  GridCellParams,
  GridColDef,
  GridDensityTypes,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

interface RemainingGoodsListProps {
  currentGoodsData: CustomerLevelDeviationResponse[];
  onEditAction: (
    editDeviation: EditLocationDeviationInput,
    openEditDeviation: boolean,
  ) => void;
  onStateChangeAction: (visibleRows: any) => void;
}

export function RemainingGoodsList(props: RemainingGoodsListProps) {
  const { t } = useTranslation();
  const { currentGoodsData, onEditAction, onStateChangeAction } = props;

  const goodsGridColumns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50 },
    {
      field: 'deviationId',
      headerName: 'Deviation Id',
    },
    {
      field: 'toLocation',
      headerName: 'To Location',
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      valueGetter({ row }) {
        if (row.customerName && `${row.customerName}` !== 'BLANK') {
          return row.customerName;
        } else {
          return row.newCustomer;
        }
      },
    },
    {
      field: 'packages',
      headerName: 'Packages',
    },
    {
      field: 'pallets',
      headerName: 'Pallets',
      valueGetter({ row }) {
        if (row.pallets && row.lastUpdatedBy !== 0) return row.pallets;
        else return '';
      },
    },
    {
      field: 'palletSpace',
      headerName: 'palletSpace',
    },
    {
      field: 'weight',
      headerName: 'weight',
    },
    {
      field: 'reason',
      headerName: 'reason',
      valueGetter({ row }) {
        if (row.reason && `${row.reason}` !== 'Other') {
          return t(`enums.remainingGoodsReasonClass.${row.reason}`);
        } else {
          return row.otherReason;
        }
      },
    },
    {
      field: 'isDangerous',
      headerName: 'Dangerous Item ',
      type: 'boolean',
    },
    {
      field: 'locationItemNote',
      headerName: 'Note',
    },
    {
      field: 'fromToToCountry',
      headerName: 'From-To Country',
    },
    {
      field: 'toCity',
      headerName: 'To City',
    },
    {
      field: 'createdBy',
      type: 'string',
      headerName: t('attributes.createdBy'),
      minWidth: 100,
    },
    {
      field: 'lastUpdatedBy',
      type: 'string',
      headerName: t('attributes.lastUpdatedBy'),
      minWidth: 100,
      valueGetter({ row }) {
        if (row.lastUpdatedBy && row.lastUpdatedBy !== 'Unknown')
          return `${row.lastUpdatedBy}`;
        else return '';
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      type: 'action',
      filterable: false,
      hideable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            onClick={() => {
              onEditAction(
                {
                  id: params.row.deviationId,
                  routeId: params.row.routeId,
                  faultLocationId: params.row.faultLocationId,
                  toLocationId: params.row.toLocationId,
                  fault: params.row.fault,
                  consequence: params.row.consequence,
                  deviationApplicableDate: params.row.deviationApplicableDate,
                  note: params.row.note,
                  customerDeviationListItemsInput: [],
                },
                true,
              );
            }}
          >
            <Tooltip title={`${t('actions.edit')}`}>
              <EditIcon color="action" />
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const apiRef = useGridApiRef();

  return (
    <UserConfiguredDataGridPro
      apiRef={apiRef}
      tableName={TABLE_NAMES.CustomerDeviations}
      rows={currentGoodsData || []}
      loading={false}
      disableMultipleSelection
      disableSelectionOnClick
      columns={goodsGridColumns}
      density={GridDensityTypes.Compact}
      autoHeight={true}
      onStateChange={(state) => {
        const visibleRows = state.filter.visibleRowsLookup;
        onStateChangeAction(visibleRows);
      }}
    />
  );
}
