import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import { DataGridPro, GridCloseIcon } from '@mui/x-data-grid-pro';
import { useState } from 'react';

const styleForTimeModal = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '70vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface terminalTimeProps {
  routeId: string;
  timeObj: {
    originalArrTime: string;
    actualArrTime: string;
    originalDepartTime: string;
    actualDepartTime: string;
    terminalName: string;
    legId: number;
    transportationDate: string;
    arrivingPallets: number;
    arrivingPalletSpace: number;
  }[];
}

export function TerminalTimeInfo(props: terminalTimeProps) {
  const { timeObj, routeId } = props;

  const [viewModal, setViewModal] = useState(false);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        disabled={timeObj.length > 0 ? false : true}
        onClick={() => setViewModal(!viewModal)}
      >
        {'Time Info'}
      </Button>
      <Modal
        open={viewModal}
        onClose={() => setViewModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
      >
        <Box sx={styleForTimeModal}>
          <IconButton
            style={{ marginLeft: 800 }}
            onClick={() => setViewModal(false)}
            edge="start"
          >
            <GridCloseIcon fontSize="large" color="action" />
          </IconButton>
          <Typography align="center" style={{ color: 'black', fontSize: 20 }}>
            {`Route Id: ${routeId}`}
          </Typography>
          <DataGridPro
            getRowId={(row) => row.legId}
            rows={timeObj}
            columns={[
              {
                headerName: 'Terminal name',
                field: 'terminalName',
              },
              {
                headerName: 'Transportation Date',
                field: 'transportationDate',
                minWidth: 110,
                type: 'date',
              },
              {
                headerName: 'Original arrival time',
                field: 'originalArrTime',
              },
              {
                headerName: 'Actual arrival time',
                field: 'actualArrTime',
              },
              {
                headerName: 'Original depart time',
                field: 'originalDepartTime',
              },
              {
                headerName: 'Actual depart time',
                field: 'actualDepartTime',
              },
              {
                headerName: 'Arriving pallets',
                field: 'arrivingPallets',
                editable: false,
                type: 'number',
              },
              {
                headerName: 'Arriving Pallet-Space',
                field: 'arrivingPalletSpace',
                editable: false,
                type: 'number',
              },
            ]}
            autoHeight={true}
          />
        </Box>
      </Modal>
    </>
  );
}
