import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../svgIcons/infoicon.svg';
import { ReactComponent as ImportantIcon } from '../svgIcons/important.svg';
import ReplyIcon from '@mui/icons-material/Reply';
import {
  DocumentFile,
  InfoType,
  InternalTerminalReportInfo,
  InternalTerminalReportInfoResponse,
} from '../generated/graphql';
import { useCallback, useState } from 'react';
import { ReportToTerminalEditReplyToInfoModal } from './modal/ReportToTerminalEditReplyToInfoModal';
import Download from '@mui/icons-material/Download';
import { useHttpClient } from '../providers/HttpClientProvider';

interface ReportFromTrafficInfoProps {
  refinedInfo: Array<
    { __typename?: 'InternalTerminalReportInfoResponse' } & Pick<
      InternalTerminalReportInfoResponse,
      | 'id'
      | 'internalTerminalId'
      | 'dateForAddInfo'
      | 'info'
      | 'infoType'
      | 'isAddedFromTerminal'
      | 'replyInfo'
      | 'replyText'
    > & {
        files: Array<
          { __typename?: 'DocumentFile' } & Pick<
            DocumentFile,
            'id' | 'originalname' | 'mimetype' | 'path' | 'size'
          >
        >;
      }
  >;
  isReplyEnabled: boolean;
}

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
})(TextField);
const DarkerDisabledTextFieldForReply = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.7)',
      fontSize: 14,
    },
  },
})(TextField);
//readonly - for info but reply enabled
export function ReportFromTrafficInfo(props: ReportFromTrafficInfoProps) {
  const { t } = useTranslation();
  const { refinedInfo, isReplyEnabled } = props;
  const { httpClient } = useHttpClient();
  const [selected, setSelected] = useState<
    | Pick<
        InternalTerminalReportInfo,
        | 'id'
        | 'info'
        | 'infoType'
        | 'internalTerminalId'
        | 'dateForAddInfo'
        | 'isAddedFromTerminal'
        | 'replyInfo'
        | 'replyText'
      >
    | undefined
  >(undefined);

  const handleShowEditInfoModal = useCallback(() => {
    setSelected(undefined);
  }, [selected]);

  const handleInfoDocDownload = useCallback(
    (myFile: { path: string; originalname: string }) => {
      httpClient
        .downloadFile(
          {
            path: myFile.path,
          },
          'blob',
        )
        .then((res) => {
          //convert Blob to File and download
          const docFile: any = res.data;
          docFile.name = `${myFile.originalname}`;
          docFile.originalName = `${myFile.originalname}`;
          docFile.lastModifiedDate = new Date();

          const url = window.URL.createObjectURL(docFile);
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute('download', `${myFile.originalname}`);
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    },
    [],
  );

  if (!refinedInfo) {
    return <></>;
  }

  return (
    <Paper>
      {selected && selected.id ? (
        <ReportToTerminalEditReplyToInfoModal
          handleClose={handleShowEditInfoModal}
          open={Boolean(selected)}
          internalTerminalReportInfo={selected}
        />
      ) : (
        ''
      )}
      <List>
        {refinedInfo.length > 0 ? (
          refinedInfo.map((item) =>
            item && item.id ? (
              <ListItem
                key={item.id}
                style={
                  isReplyEnabled
                    ? { border: '2px solid #c2c2c2', marginBottom: 4 }
                    : {}
                }
              >
                <Grid container direction="row">
                  <Grid item xs={1}>
                    {item.infoType === InfoType.Info ? (
                      <ListItemIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          fontSize: '1.2em',
                        }}
                      >
                        <InfoIcon fill="#0096FF" fill-rule="nonzero" />
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          fontSize: '1.2em',
                        }}
                      >
                        <ImportantIcon fill="#0096FF" />
                      </ListItemIcon>
                    )}
                  </Grid>

                  <Grid item xs={9}>
                    <DarkerDisabledTextField
                      id={item.id.toString()}
                      multiline
                      rows={2}
                      disabled={true}
                      maxRows={Infinity}
                      variant={'standard'}
                      value={item.info}
                      fullWidth={true}
                    />
                  </Grid>

                  <Grid item container xs={2} justifyContent="flex-end">
                    {isReplyEnabled && (
                      <Button
                        style={{ minWidth: '0px', width: 'fit-content' }}
                        onClick={() => setSelected(item)}
                      >
                        <ReplyIcon />
                      </Button>
                    )}
                    {item.files && item.files.length > 0 && (
                      <Button
                        style={{ minWidth: '0px', width: 'fit-content' }}
                        onClick={() =>
                          handleInfoDocDownload({
                            path: item.files[0].path, //As of now only one file is there
                            originalname: item.files[0].originalname,
                          })
                        }
                      >
                        <Download />
                      </Button>
                    )}
                  </Grid>

                  {isReplyEnabled &&
                    item.replyText &&
                    item.replyText !== '' && (
                      <>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={7}>
                          <DarkerDisabledTextFieldForReply
                            id={'1' + item.id.toString()}
                            multiline
                            rows={1}
                            disabled={true}
                            maxRows={Infinity}
                            variant={'standard'}
                            value={item.replyText}
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item container alignContent="flex-end" xs={3}>
                          <Typography
                            style={{
                              fontStyle: 'italic',
                              fontSize: 12,
                            }}
                          >{`${item.replyInfo ?? ''}`}</Typography>
                        </Grid>
                      </>
                    )}
                </Grid>
              </ListItem>
            ) : (
              <></>
            ),
          )
        ) : (
          <></>
        )}

        {!refinedInfo?.length ? (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography variant="subtitle1" style={{ paddingRight: '1%' }}>
                {`${t('resource.noInfoAdded.capitalized')}`}
              </Typography>
            </Box>
          </>
        ) : (
          <></>
        )}
      </List>
    </Paper>
  );
}
