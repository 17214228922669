import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ViewCard } from '../components/ViewCard';
import {
  GetCheckpointDocument,
  GetCheckpointsDocument,
  useDeleteCheckpointMutation,
  useGetCheckpointQuery,
  useUpdateCheckpointMutation,
} from '../generated/graphql';

interface ViewCheckpointProps {
  terminal?: boolean;
}

export function ViewCheckpoint(props: ViewCheckpointProps) {
  const { terminal = false } = props;
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const { loading, error, data } = useGetCheckpointQuery({
    variables: { id: parseInt(id, 10) },
  });
  const [editOpen, setEditOpen] = useState(false);
  const [updateCheckpoint, {}] = useUpdateCheckpointMutation({
    refetchQueries: [
      {
        query: GetCheckpointDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const [deleteCheckpoint] = useDeleteCheckpointMutation({
    refetchQueries: [
      {
        query: GetCheckpointsDocument,
      },
    ],
  });

  const handleDeleteCheckpoint = (id: number) => {
    deleteCheckpoint({ variables: { id } });
    router.push(`/traffic/cards/checkpoints`);
  };

  const onSubmit = (values: { name: string; id: number }) => {
    updateCheckpoint({ variables: { ...values } });
    setEditOpen(false);
  };

  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading || data == null) {
    return <div>loading...</div>;
  }

  return (
    <ViewCard
      name={data.checkpoint.name}
      id={data.checkpoint.id}
      type="checkpoint"
      createdBy={data.checkpoint.createdBy}
      lastUpdatedBy={data.checkpoint.lastUpdatedBy}
      updatedAt={data.checkpoint.updatedAt}
      files={data.checkpoint.files}
      contacts={data.checkpoint.contacts}
      onSubmit={onSubmit}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      deleteCard={handleDeleteCheckpoint}
      terminal={terminal}
    />
  );
}
