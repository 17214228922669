import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

interface FastCheckboxFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  isShipmentBox?: boolean;
  checkedChange?: (checked: boolean) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.fontSize - 1,
    },
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: 1,
    },
  }),
);

export function FastCheckbox(props: FastCheckboxFieldProps) {
  const classes = useStyles();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const {
    name,
    label,
    readOnly = false,
    size = undefined,
    disabled = false,
    isShipmentBox = false,
    checkedChange = undefined,
  } = props;

  const [checked, setChecked] = useState(getFieldProps(`${name}`).value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}`, event.target.checked);
  };
  useEffect(() => {
    setChecked(getFieldProps(`${name}`).value);
    checkedChange ? checkedChange(getFieldProps(`${name}`).value) : null;
    if (
      getFieldProps(`${name}`).value === true &&
      isShipmentBox &&
      name.split('.')[2] === 'terminalCleared'
    ) {
      setFieldValue(
        `${name.split('.')[0]}.${name.split('.')[1]}.terminalNotCleared`,
        false,
      );
      setFieldValue(
        `${name.split('.')[0]}.${
          name.split('.')[1]
        }.terminalShipmentNotArrived`,
        false,
      );
    } else if (
      getFieldProps(`${name}`).value === true &&
      isShipmentBox &&
      name.split('.')[2] === 'terminalNotCleared'
    ) {
      setFieldValue(
        `${name.split('.')[0]}.${name.split('.')[1]}.terminalCleared`,
        false,
      );
      setFieldValue(
        `${name.split('.')[0]}.${
          name.split('.')[1]
        }.terminalShipmentNotArrived`,
        false,
      );
    } else if (
      getFieldProps(`${name}`).value === true &&
      isShipmentBox &&
      name.split('.')[2] === 'terminalShipmentNotArrived'
    ) {
      setFieldValue(
        `${name.split('.')[0]}.${name.split('.')[1]}.terminalNotCleared`,
        false,
      );
      setFieldValue(
        `${name.split('.')[0]}.${name.split('.')[1]}.terminalCleared`,
        false,
      );
    }
  }, [getFieldProps(`${name}`).value]);

  return (
    <FormControlLabel
      className={classes.formControl}
      disabled={readOnly || disabled}
      control={
        <Checkbox
          size={size}
          color="primary"
          className={classes.checkBox}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={
        <Typography
          variant="body2"
          className={classes.label}
          color="textPrimary"
        >
          {label}
        </Typography>
      }
    />
  );
}
