import { PDFDocument } from 'pdf-lib';
import { HttpClient } from '../httpClient';

export async function mergePdfs(
  blob: any,
  files: { path: string }[],
  httpClient: HttpClient,
) {
  // Create a new PDFDocument which is the one all pdf will merge into
  const mergedPdf = await PDFDocument.create();

  // Embed the transport agreement to mergedPdf
  const bufferFromBlob = await blob.arrayBuffer();
  const pdf = await PDFDocument.load(bufferFromBlob);
  await mergedPdf.copyPages(pdf, pdf.getPageIndices()).then((pages) => {
    pages.forEach((page) => mergedPdf.addPage(page));
  });

  // Download arrayBuffer from files
  const fileArrayBuffer: ArrayBuffer[] = [];
  for (const file of files) {
    const arrayBuffer = await httpClient.downloadFile(file, 'arraybuffer');
    fileArrayBuffer.push(arrayBuffer.data);
  }

  for (const arrayBuffer of fileArrayBuffer) {
    const pdf = await PDFDocument.load(arrayBuffer);
    await mergedPdf.copyPages(pdf, pdf.getPageIndices()).then((pages) => {
      pages.forEach((page) => mergedPdf.addPage(page));
    });
  }

  // Serialize the PDFDocument to bytes (a Uint8Array)
  return await mergedPdf.save();
}

export async function mergeTourPdfs(
  blob: any,
  files: { path: string }[],
  httpClient: HttpClient,
) {
  // Create a new PDFDocument which is the one all pdf will merge into
  const mergedPdf = await PDFDocument.create();
  // Embed the transport agreement to mergedPdf
  const bufferFromBlob = await blob.arrayBuffer();
  const pdf = await PDFDocument.load(bufferFromBlob);
  await mergedPdf.copyPages(pdf, pdf.getPageIndices()).then((pages) => {
    pages.forEach((page) => mergedPdf.addPage(page));
  });

  // Download arrayBuffer from files
  const fileArrayBuffer: ArrayBuffer[] = [];
  for (const file of files) {
    const arrayBuffer = await httpClient.downloadFile(file, 'arraybuffer');
    fileArrayBuffer.push(arrayBuffer.data);
  }

  for (const arrayBuffer of fileArrayBuffer) {
    const pdf = await PDFDocument.load(arrayBuffer);
    await mergedPdf.copyPages(pdf, pdf.getPageIndices()).then((pages) => {
      pages.forEach((page) => mergedPdf.addPage(page));
    });
  }
  // Serialize the PDFDocument to bytes (a Uint8Array)
  return await mergedPdf.save();
}
