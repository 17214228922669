import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import PreviousIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import PlusIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageviewIcon from '@mui/icons-material/Pageview';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import { ReportToTerminalAddInfoModal } from '../components/modal/ReportToTerminalAddInfoModal';
import { ReportToTerminalInfo } from '../components/ReportToTerminalInfo';
import {
  DocumentFile,
  GetInternalTerminalReportInfoQuery,
  GetValueBoxForDateQuery,
  InternalTerminalReportInfoResponse,
  useGetInternalTerminalReportInfoQuery,
  useGetInternalTerminalsQuery,
  useGetValueBoxForDateQuery,
} from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { getUserName } from '../lib/useRoles';
import { LoadingAndError } from '../utils/LoadingAndError';
import { ValueBoxModal } from '../components/modal/ValueBoxModal';
import { ReportToTerminalValueBox } from '../components/ReportToTerminalValueBox';
import { GridFunctionForTerminal } from '../components/TerminalGridFunction';
import { TerminalGridValues } from '../components/TerminalGridValues';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { getTimeAsNumberOfMinutes } from '../lib/date';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { useHttpClient } from '../providers/HttpClientProvider';
import {
  ListIncomingRoutesLegs,
  StyledAccordionSummary,
  styleForCountryModal,
} from './TrafficTFT';
import { ReportToTerminalSporadicRoutes } from '../components/ReportToTerminalSporadicRoutes';
import CloseIcon from '@mui/icons-material/Close';
import { DataTerminal } from './TrafficDashboard';
import { DownloadTftPdf } from '../components/DownloadTftPdf';
import { BoxType } from './ViewReportFromTerminal';

const useStyles = makeStyles((theme: Theme) => ({
  filterButtonRoot: {
    marginLeft: 2,
    border: '2px solid #e8e8e8',
    borderRadius: 3,
    marginTop: 4,
    marginBottom: 4,
    padding: 2,
  },
  filterButton: {
    marginLeft: 4,
    marginTop: 4,
    border: '2px solid #e8e8e8',
    borderRadius: 2,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  btnSelected: {
    border: '1px solid #68BC46',
    backgroundColor: '#AAE590',
  },
  unseenData: {
    border: '2px solid #ff9800',
    backgroundColor: '#76ff03',
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
}));

export function ReportToTerminal() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const [user] = useState<string>(getUserName());
  const NEW_DATE_FORMAT = 'yyyy-MM-dd HH:mm';

  const {
    getTFTReadOnlyTime,
    setMasterDateForTFTReadOnly,
    setReportToTerminalView,
    getReportToTerminalView,
  } = useUserConfiguration();
  const startHour = parseInt(getTFTReadOnlyTime(true).split(':')[0]);
  const startMinute = parseInt(getTFTReadOnlyTime(true).split(':')[1]);
  const endHour = parseInt(getTFTReadOnlyTime(false).split(':')[0]);
  const endMinute = parseInt(getTFTReadOnlyTime(false).split(':')[1]);
  const [viewCancelledLegs, setViewCancelledLegs] = useState(true);
  const [startDateTime, setStartDateTime] = useState(
    new Date().setHours(startHour, startMinute),
  );

  const [endDateTime, setEndDateTime] = useState(
    new Date(new Date().getTime()).setHours(endHour, endMinute),
  );

  const [reportStartDate, setReportStartDate] = useState<string>(
    format(startDateTime, NEW_DATE_FORMAT),
  );
  const [reportEndDate, setReportEndDate] = useState<string>(
    format(endDateTime, NEW_DATE_FORMAT),
  );
  const [isBothDateValid, setBothDateValid] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<string | undefined>(
    localStorage.getItem('reportToTerminalExpanded_' + user) ?? undefined,
  );

  const [boxData, setBoxData] = useState<BoxType[]>([]);
  const [legsData, setLegsData] = useState<ListIncomingRoutesLegs[]>([]);
  const [reportLoading, setreportLoading] = useState(false);
  const [showAddInfoModal, setShowAddInfoModal] = useState(false);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [hideGridData, setHideGridData] = useState<boolean>(false);
  const [refreshGrid, setRefreshGrid] = useState<boolean>(false);
  const handleShowAddInfoModal = useCallback(() => {
    setShowAddInfoModal(!showAddInfoModal);
  }, [showAddInfoModal]);

  const [showValueBoxModal, setShowValueBoxModal] = useState(false);
  const [legsDataLoading, setLegsDataLoading] = useState(true);
  const [displayTerminals, setDisplayTerminals] = useState<boolean>(false);
  const [selectedTerminals, setSelectedTerminals] = useState<DataTerminal>();
  const [reciverEmail, setReceiverEmail] = useState<string[]>([]);
  const [excelSheetBlob, setExcelSheetBlob] = useState<Blob>();
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [view, setView] = useState({
    routeView: false,
    infoView: false,
    gridView: false,
    valueBoxesView: false,
  });

  const [refreshConfigView, setRefreshConfigView] = useState(false);
  const [configView, setConfigView] = useState({
    routeView: false,
    infoView: false,
    gridView: false,
    valueBoxesView: false,
  });

  const initView = {
    routeView: false,
    infoView: false,
    gridView: false,
    valueBoxesView: false,
  };
  const handleShowValueBoxModal = useCallback(() => {
    setShowValueBoxModal(!showValueBoxModal);
  }, [showValueBoxModal]);

  const handleChange =
    (country: string) =>
    (_event: React.SyntheticEvent, isExpanded: boolean) => {
      localStorage.setItem('reportToTerminalExpanded_' + user, country);
      setExpanded(isExpanded ? country : undefined);
    };
  const [startDate, setStartDate] = useState<{ date: string; day: string }>({
    date: format(new Date(), DATE_FORMAT),
    day: new Date().toLocaleDateString(undefined, {
      weekday: 'long',
    }),
  });

  const {
    data: data,
    loading: loading,
    error,
  } = useGetInternalTerminalsQuery({
    variables: {
      fetchFromAllDepartments: true,
    },
    fetchPolicy: 'cache-and-network',
  });
  const internalTerminals = (data?.internalTerminals || [])
    .filter(
      (item) =>
        typeof item !== 'undefined' && item.id && item.terminal?.locations[0],
    )
    .map((x) => {
      const location = x.terminal.locations[0];
      const locationId = x.terminal.locations[0].id;
      const locationString =
        location != null
          ? `${location.address}, ${location.city}, ${location.countryLong}`
          : '';
      const terminalLocationCountry: string = x.terminal.locations[0]?.country;
      const contactsWithTag = x.terminal.contacts.filter(
        (contact) => contact.tags.length > 0,
      );

      return {
        id: x.id,
        name: x.terminal.name,
        location: locationString,
        terminalLocationCountry: terminalLocationCountry,
        locationId: locationId,
        contacts: contactsWithTag,
      };
    });
  const countryList: string[] = internalTerminals?.length
    ? Array.from(
        new Set(
          internalTerminals.flatMap((item) => item.terminalLocationCountry),
        ),
      )
    : [];
  const handleStartDateChanged = useCallback((value) => {
    setLegsDataLoading(true);
    if (value && value != 'Invalid Date') {
      setStartDateTime(value);
      //Mon Jun 19 2023 10:00:00 GMT+0530
      setReportStartDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setReportStartDate('');
    }
  }, []);

  const changeDatesAction = (isNext: boolean) => {
    const newDateStart = isNext
      ? new Date(new Date(reportStartDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(reportStartDate).getTime() - 1000 * 60 * 60 * 24);
    const newDateEnd = isNext
      ? new Date(new Date(reportEndDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(reportEndDate).getTime() - 1000 * 60 * 60 * 24);

    setStartDateTime(newDateStart.getTime());
    setEndDateTime(newDateEnd.getTime());
    const newTFTStartDateTime = format(newDateStart, NEW_DATE_FORMAT);
    const newTFTEndDateTime = format(newDateEnd, NEW_DATE_FORMAT);

    setReportStartDate(newTFTStartDateTime);
    setReportEndDate(newTFTEndDateTime);
    setMasterDateForTFTReadOnly(`${newTFTStartDateTime}#${newTFTEndDateTime}`);

    if (selectedTerminals && selectedTerminals.terminalId) {
      getRouteLegsData(newTFTStartDateTime, newTFTEndDateTime, true);
    }
  };

  const getRouteLegsData = (
    tftStartDateTime: string,
    tftEndDateTime: string,
    sporadicOnly = false,
    allCancelledLegs = false,
  ) => {
    setreportLoading(true);
    setLegsDataLoading(true);
    httpClient
      .getIncomingRouteLegs({
        terminalId: selectedTerminals?.terminalId
          ? [selectedTerminals.terminalId]
          : [],
        startDateTime: tftStartDateTime,
        endDateTime: tftEndDateTime,
        sporadicOnly: sporadicOnly,
        allCancelledLegs: allCancelledLegs,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setLegsData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setLegsData([]);
          console.error('# tft API error=', res.data.message);
        }
        setreportLoading(false);
        setLegsDataLoading(false);
      })
      .catch((e) => {
        console.error('# tft UI error=', e);
        setreportLoading(false);
        setLegsDataLoading(false);
      });
  };

  const isValidTime = (startTime: string, endTime: string): boolean => {
    const startMins = getTimeAsNumberOfMinutes(startTime);
    const endMins = getTimeAsNumberOfMinutes(endTime);
    return startMins < endMins;
  };

  const handleEndDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setEndDateTime(value);
      setReportEndDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setReportEndDate('');
    }
  }, []);

  const handleInfoDataChanged = (value: GetInternalTerminalReportInfoQuery) => {
    const infoList: GetInternalTerminalReportInfoQuery['internalTerminalReportInfo'] =
      value.internalTerminalReportInfo;

    refinedInfo = infoList?.length
      ? infoList.flatMap((info) => {
          return {
            id: info.id,
            dateForAddInfo: info.dateForAddInfo,
            info: info.info,
            infoType: info.infoType,
            isAddedFromTerminal: info.isAddedFromTerminal,
            internalTerminalId: info.internalTerminalId,
            replyInfo: info.replyInfo ?? '',
            replyText: info.replyText ?? '',
            files: info.files,
          };
        })
      : [];
  };

  useEffect(() => {
    if (selectedTerminals && selectedTerminals.terminalId && isBothDateValid) {
      getRouteLegsData(reportStartDate, reportEndDate, true);
      setMasterDateForTFTReadOnly(`${reportStartDate}#${reportEndDate}`);
      const configStartDate = getReportToTerminalView().startDate;
      const storedStartDate =
        reportStartDate.length > 0 ? reportStartDate.split(' ')[0] : '';
      if (
        storedStartDate === configStartDate &&
        selectedTerminals.terminalId === getReportToTerminalView().terminalId
      ) {
        setConfigView(getReportToTerminalView().viewObj);
      }
      if (
        selectedTerminals.terminalId !== getReportToTerminalView().terminalId
      ) {
        setReportToTerminalView(storedStartDate, selectedTerminals.terminalId, {
          gridView: view.gridView,
          infoView: view.infoView,
          routeView: view.routeView,
          valueBoxesView: view.valueBoxesView,
        });

        setConfigView({
          gridView: view.gridView,
          infoView: view.infoView,
          routeView: view.routeView,
          valueBoxesView: view.valueBoxesView,
        });
      }
    }
    const emailContact: string[] = [];
    selectedTerminals?.contacts?.forEach((contact) => {
      contact.tags.find((tag) => {
        if (tag.title.trim().toLocaleUpperCase() == 'REPORT RECEIVER') {
          emailContact.push(contact.email);
          return true;
        } else false;
      });
    });
    setReceiverEmail(emailContact);
  }, [selectedTerminals]);

  useEffect(() => {
    onExportTFTClicked(false);
  }, [legsData]);

  useEffect(() => {
    if (reportStartDate !== '') {
      const selDate = reportStartDate.split(' ')[0];
      setStartDate({
        date: selDate,
        day: parse(selDate, DATE_FORMAT, new Date()).toLocaleDateString(
          undefined,
          {
            weekday: 'long',
          },
        ),
      });
    }

    if (reportStartDate !== '' && reportEndDate !== '') {
      if (reportStartDate.split(' ')[0] === reportEndDate.split(' ')[0]) {
        const isTimeValid = isValidTime(
          reportStartDate.split(' ')[1],
          reportEndDate.split(' ')[1],
        );
        setBothDateValid(isTimeValid);
      } else {
        setBothDateValid(true);
      }
    } else {
      setBothDateValid(false);
    }
  }, [reportStartDate, reportEndDate]);

  const onExportTFTClicked = (downloadExcelSheet = true) => {
    if (legsData && legsData.length > 0) {
      setExportExcelLoading(true);
      httpClient
        .downloadTftExcelBlob({
          terminalId: selectedTerminals?.terminalId
            ? [selectedTerminals.terminalId]
            : [],
          startDateTime: reportStartDate,
          endDateTime: reportEndDate,
        })
        .then((res) => {
          if (downloadExcelSheet) {
            setExcelSheetBlob(res.data);
            const blob = res.data;
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.setAttribute('href', url);
            anchor.setAttribute(
              'download',
              `TFT-(${startDateTime}_to_${endDateTime})`,
            );
            setExportExcelLoading(false);

            anchor.click();
            window.URL.revokeObjectURL(url);
          } else {
            setExcelSheetBlob(res.data);
            setExportExcelLoading(false);
          }
        });
    }
  };

  const { data: valueBoxData, loading: valueBoxLoading } =
    useGetValueBoxForDateQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        internalTerminalId:
          selectedTerminals && selectedTerminals.terminalId
            ? selectedTerminals.terminalId
            : 0,
        date: startDate.date,
        forTerminal: false,
      },
    });

  //for info API
  const { data: infoData, loading: infoLoading } =
    useGetInternalTerminalReportInfoQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        internalTerminalId:
          selectedTerminals && selectedTerminals.terminalId
            ? selectedTerminals.terminalId
            : 0,
        dateForAddInfo: startDate.date,
        forTerminal: false,
      },
    });

  useEffect(() => {
    if (
      valueBoxData &&
      valueBoxData.getValueBoxesForDay &&
      valueBoxData.getValueBoxesForDay.length > 0
    ) {
      const finalBoxes = valueBoxData.getValueBoxesForDay.filter(
        (box) => box.boxValue !== 0,
      );
      setBoxData(finalBoxes);
    } else {
      setBoxData([]);
    }
  }, [valueBoxData]);

  let refinedInfo: Array<
    { __typename?: 'InternalTerminalReportInfoResponse' } & Pick<
      InternalTerminalReportInfoResponse,
      | 'id'
      | 'internalTerminalId'
      | 'dateForAddInfo'
      | 'info'
      | 'infoType'
      | 'isAddedFromTerminal'
      | 'replyInfo'
      | 'replyText'
    > & {
        files: Array<
          { __typename?: 'DocumentFile' } & Pick<
            DocumentFile,
            'id' | 'originalname' | 'mimetype' | 'path' | 'size'
          >
        >;
      }
  > = [];

  if (
    infoData &&
    infoData.internalTerminalReportInfo &&
    infoData.internalTerminalReportInfo.length > 0
  ) {
    const infoList: GetInternalTerminalReportInfoQuery['internalTerminalReportInfo'] =
      infoData.internalTerminalReportInfo;

    refinedInfo = infoList?.length
      ? infoList.flatMap((info) => {
          return {
            id: info.id,
            dateForAddInfo: info.dateForAddInfo,
            info: info.info,
            infoType: info.infoType,
            isAddedFromTerminal: info.isAddedFromTerminal,
            internalTerminalId: info.internalTerminalId,
            replyInfo: info.replyInfo ?? '',
            replyText: info.replyText ?? '',
            files: info.files,
          };
        })
      : [];
  }

  const handleValueBoxDataChanged = (value: GetValueBoxForDateQuery) => {
    if (
      value &&
      value.getValueBoxesForDay &&
      value.getValueBoxesForDay.length > 0
    ) {
      const finalBoxes = value.getValueBoxesForDay.filter(
        (box) => box.boxValue !== 0,
      );
      setBoxData(finalBoxes);
    }
  };
  useEffect(() => {
    const storedStartDate =
      reportStartDate.length > 0 ? reportStartDate.split(' ')[0] : '';
    const configDate = getReportToTerminalView().startDate;
    const terminalId = selectedTerminals?.terminalId
      ? selectedTerminals?.terminalId
      : 0;
    if (terminalId) {
      if (
        storedStartDate === configDate &&
        terminalId === getReportToTerminalView().terminalId
      )
        setReportToTerminalView(storedStartDate, terminalId, configView);
      else {
        setConfigView({
          gridView: view.gridView,
          infoView: view.infoView,
          routeView: view.routeView,
          valueBoxesView: view.valueBoxesView,
        });
        setReportToTerminalView(storedStartDate, terminalId, {
          gridView: view.gridView,
          infoView: view.infoView,
          routeView: view.routeView,
          valueBoxesView: view.valueBoxesView,
        });
      }
    }
  }, [refreshConfigView]);

  return (
    <LoadingAndError error={error} loading={loading} data={internalTerminals}>
      {() => (
        <>
          <HelmetComponent title={t('resource.reportToTerminal.capitalized')} />
          {showAddInfoModal &&
          selectedTerminals &&
          selectedTerminals.terminalId ? (
            <ReportToTerminalAddInfoModal
              handleClose={handleShowAddInfoModal}
              open={showAddInfoModal}
              internalTerminalId={selectedTerminals?.terminalId}
              terminalName={
                selectedTerminals?.name ? selectedTerminals?.name : ''
              }
              dateForAddInfo={startDate.date}
              dayForAddInfo={startDate.day}
              isForTerminal={false}
            />
          ) : null}

          {showValueBoxModal &&
          selectedTerminals &&
          selectedTerminals.terminalId ? (
            <ValueBoxModal
              open={showValueBoxModal}
              handleClose={() => {
                setShowValueBoxModal(false);
              }}
              internalTerminalId={selectedTerminals?.terminalId}
              terminalName={
                selectedTerminals.name ? selectedTerminals.name : ''
              }
              isOpenFromTerminal={false}
            />
          ) : null}

          <Modal
            open={displayTerminals}
            onClose={() => setDisplayTerminals(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock
          >
            <Box sx={styleForCountryModal}>
              <IconButton
                style={{ marginLeft: 550 }}
                onClick={() => setDisplayTerminals(false)}
                edge="start"
              >
                <CloseIcon fontSize="large" color="action" />
              </IconButton>
              <LoadingAndError
                error={error}
                loading={loading}
                data={internalTerminals}
              >
                {({ loadedData }) => (
                  <Grid item>
                    {countryList.flatMap((ctryItem) => (
                      <Accordion
                        expanded={expanded === ctryItem}
                        onChange={handleChange(ctryItem)}
                      >
                        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="subtitle1">
                            <strong>
                              {
                                COUNTRIES_MAP[
                                  ctryItem as keyof typeof COUNTRIES_MAP
                                ]
                              }
                            </strong>
                          </Typography>
                        </StyledAccordionSummary>
                        <AccordionDetails>
                          <List dense={true}>
                            {loadedData
                              .filter(
                                (l) => l.terminalLocationCountry === ctryItem,
                              )
                              .map((item) => (
                                <>
                                  <ListItem key={item.id} divider>
                                    <Typography
                                      onClick={() =>
                                        setSelectedTerminals({
                                          terminalId: item.id,
                                          shortCountryCode:
                                            item.terminalLocationCountry,
                                          name: item.name,
                                          contacts: item.contacts,
                                        })
                                      }
                                      variant="body2"
                                      color={
                                        selectedTerminals &&
                                        selectedTerminals.terminalId === item.id
                                          ? 'primary'
                                          : 'inherit'
                                      }
                                    >
                                      {item.name}
                                    </Typography>
                                  </ListItem>
                                </>
                              ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Grid>
                )}
              </LoadingAndError>
            </Box>
          </Modal>
          <Grid container>
            <Grid item xs={5} justifyContent="flex-start">
              <Box className={classes.filterButtonRoot}>
                <Grid item container justifyContent="center">
                  <Tooltip title={`Previous Date`}>
                    <IconButton
                      style={{
                        backgroundColor: '#e2e2e2',
                        height: 38,
                        width: 38,
                        marginRight: 0,
                      }}
                      onClick={() => {
                        changeDatesAction(false);
                      }}
                    >
                      <PreviousIcon />
                    </IconButton>
                  </Tooltip>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    allowKeyboardControl={true}
                    label="Production Start"
                    value={startDateTime}
                    onChange={handleStartDateChanged}
                    format="yyyy-MM-dd HH:mm"
                    id="startDT"
                    InputProps={{
                      style: {
                        fontSize: 15,
                      },
                      classes: { input: classes.inputValTime },
                      maxRows: 1,
                    }}
                    invalidDateMessage={'Invalid DateTime'}
                    maxDate={endDateTime}
                    maxDateMessage={'Invalid Range'}
                    onError={(e) => {
                      if (e) setBothDateValid(false);
                    }}
                    KeyboardButtonProps={{
                      disabled: true,
                      style: { display: 'none' },
                    }}
                  />
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    allowKeyboardControl={true}
                    label="Production End"
                    value={endDateTime}
                    onChange={handleEndDateChanged}
                    format="yyyy-MM-dd HH:mm"
                    id="endDT"
                    InputProps={{
                      style: {
                        fontSize: 15,
                      },
                      classes: { input: classes.inputValTime },
                      maxRows: 1,
                    }}
                    minDate={startDateTime}
                    minDateMessage={'Invalid Range'}
                    invalidDateMessage={'Invalid DateTime'}
                    onError={(e) => {
                      if (e) setBothDateValid(false);
                    }}
                    KeyboardButtonProps={{
                      disabled: true,
                      style: { display: 'none' },
                    }}
                  />
                  <Tooltip title={`Next Date`}>
                    <IconButton
                      style={{
                        backgroundColor: '#e2e2e2',
                        height: 38,
                        width: 38,
                      }}
                      onClick={() => {
                        changeDatesAction(true);
                      }}
                    >
                      <NextIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item container justifyContent="center">
                  <Button
                    style={{ marginTop: 5, marginRight: 2 }}
                    variant="contained"
                    color="primary"
                    disabled={
                      !isBothDateValid || !selectedTerminals?.terminalId
                    }
                    onClick={() => {
                      if (
                        reportStartDate !== '' &&
                        reportEndDate !== '' &&
                        isBothDateValid
                      ) {
                        if (selectedTerminals && selectedTerminals.terminalId) {
                          getRouteLegsData(
                            reportStartDate,
                            reportEndDate,
                            true,
                          );
                        }
                        setMasterDateForTFTReadOnly(
                          `${reportStartDate}#${reportEndDate}`,
                        );
                        setRefreshConfigView(!refreshConfigView);
                      }
                    }}
                    size="small"
                  >
                    {`VIEW`}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 2, marginTop: 5, marginLeft: 2 }}
                    disabled={exportExcelLoading || legsData.length === 0}
                    onClick={() => onExportTFTClicked()}
                    size="small"
                  >
                    {exportExcelLoading
                      ? `${t('validation.loadingApi')}`
                      : `${t('attributes.getExcel')}`}
                  </Button>
                  {!valueBoxLoading &&
                  !infoLoading &&
                  !legsDataLoading &&
                  selectedTerminals?.terminalId &&
                  !reportLoading &&
                  isBothDateValid ? (
                    <DownloadTftPdf
                      startDateTime={reportStartDate}
                      endDateTime={reportEndDate}
                      routeLegs={legsData}
                      terminalName={
                        selectedTerminals && selectedTerminals.name
                          ? selectedTerminals.name
                          : ''
                      }
                      reportInfoData={refinedInfo}
                      reportValueBoxData={boxData.length > 0 ? boxData : []}
                      excelSheetData={excelSheetBlob}
                      mailIds={reciverEmail}
                    />
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        style={{ marginRight: 2, marginTop: 5, marginLeft: 2 }}
                        color="primary"
                        disabled={true}
                      >
                        {'GET PDF'}
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        color="primary"
                        style={{ marginTop: 5 }}
                        disabled={true}
                      >
                        {'SEND REPORT'}
                      </Button>
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <div className={classes.filterButton}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setDisplayTerminals(!displayTerminals);
                  }}
                >
                  {t('attributes.selectTerminals')}
                </Button>
                {selectedTerminals &&
                selectedTerminals.terminalId &&
                startDate.date ? (
                  <>
                    {Object.entries(view).map(([key, value]) => (
                      <Button
                        key={key}
                        className={
                          key === 'routeView'
                            ? configView.routeView
                              ? value
                                ? classes.btnSelected
                                : undefined
                              : classes.unseenData
                            : key === 'gridView'
                            ? configView.gridView
                              ? value
                                ? classes.btnSelected
                                : undefined
                              : classes.unseenData
                            : key === 'infoView'
                            ? configView.infoView
                              ? value
                                ? classes.btnSelected
                                : undefined
                              : classes.unseenData
                            : configView.valueBoxesView
                            ? value
                              ? classes.btnSelected
                              : undefined
                            : classes.unseenData
                        }
                        variant="contained"
                        size="small"
                        onClick={() => {
                          const thisView =
                            view[key as keyof typeof view] ?? false;
                          const newView = {
                            ...initView,
                            [key]: !thisView,
                          };

                          setView(newView);
                          setConfigView({
                            ...configView,
                            [key]: true,
                          });
                          setRefreshConfigView(!refreshConfigView);
                        }}
                      >
                        {key === 'routeView'
                          ? configView.routeView
                            ? t(`view.${key}`)
                            : `${t(`view.${key}`)}**`
                          : key === 'gridView'
                          ? configView.gridView
                            ? t(`view.${key}`)
                            : `${t(`view.${key}`)}**`
                          : key === 'infoView'
                          ? configView.infoView
                            ? t(`view.${key}`)
                            : `${t(`view.${key}`)}**`
                          : configView.valueBoxesView
                          ? t(`view.${key}`)
                          : `${t(`view.${key}`)}**`}
                      </Button>
                    ))}
                  </>
                ) : (
                  <Typography
                    variant="subtitle1"
                    align="center"
                    style={{ marginLeft: 10 }}
                  >
                    <strong>{t('validation.selectTerminal')}</strong>
                  </Typography>
                )}
              </div>

              {selectedTerminals && selectedTerminals.name ? (
                <div className={classes.filterButton}>
                  <Typography variant="subtitle1" align="center">
                    <strong>
                      {`${selectedTerminals.name} | ${startDate.date} | ${startDate.day}`}
                    </strong>
                  </Typography>
                </div>
              ) : null}
            </Grid>
          </Grid>
          {selectedTerminals &&
            selectedTerminals.terminalId &&
            view.infoView && (
              <Grid item container>
                <div style={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PlusIcon />}
                    onClick={handleShowAddInfoModal}
                  >
                    {t('button.add', {
                      item: t('attributes.info'),
                    })}
                  </Button>
                </div>
                <div style={{ width: '100%', marginTop: 10 }}>
                  <ReportToTerminalInfo
                    selectedTerminal={{
                      id: selectedTerminals.terminalId,
                      name: selectedTerminals.name
                        ? selectedTerminals.name
                        : '',
                    }}
                    selectedDateAndDay={startDate}
                    isForTerminal={false}
                    infoDataFunction={(data) => handleInfoDataChanged(data)}
                  />
                </div>
              </Grid>
            )}
          {selectedTerminals &&
            selectedTerminals.terminalId &&
            view.valueBoxesView && (
              <Grid item container>
                <div style={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowValueBoxModal}
                  >
                    {`${t('resource.valueBoxes.capitalized')}`}
                  </Button>
                </div>
                <div style={{ width: '100%', marginTop: 10 }}>
                  <ReportToTerminalValueBox
                    selectedTerminal={{ id: selectedTerminals.terminalId }}
                    selectedDateAndDay={startDate}
                    refreshToggle={showValueBoxModal}
                    createBoxForTerminal={false}
                    valueBoxFunction={(data) => handleValueBoxDataChanged(data)}
                  />
                </div>
              </Grid>
            )}
          {selectedTerminals &&
            selectedTerminals.terminalId &&
            view.gridView && (
              <Grid container item>
                <div style={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setShowGrid(!showGrid);
                      setHideGridData(true);
                    }}
                  >
                    {t('button.add', {
                      item: t('attributes.grid'),
                    })}
                  </Button>
                </div>
                {showGrid ? (
                  <div style={{ width: '100%' }}>
                    <GridFunctionForTerminal
                      open={showGrid}
                      handleClose={() => {
                        setShowGrid(false);
                        setRefreshGrid(!refreshGrid);
                        setHideGridData(false);
                      }}
                      internalTerminalId={selectedTerminals?.terminalId}
                      terminalName={
                        selectedTerminals.name ? selectedTerminals.name : ''
                      }
                      gridType="Traffic"
                    />
                  </div>
                ) : null}
                <div style={{ width: '100%', marginTop: 5 }}>
                  <TerminalGridValues
                    internalTerminalId={selectedTerminals?.terminalId}
                    refreshGrid={refreshGrid}
                    selectedDateAndDay={startDate.date}
                    hideGridData={hideGridData}
                    gridType="Traffic"
                  />
                </div>
              </Grid>
            )}

          {selectedTerminals &&
            selectedTerminals.shortCountryCode &&
            view.routeView && (
              <Grid container style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={10}
                  style={{
                    backgroundColor: '#68bc46',
                    borderRadius: 3,
                  }}
                >
                  <Typography variant="subtitle1" align="center">
                    <strong>{`${t('attributes.tftTitle')}`}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Tooltip
                    title={
                      viewCancelledLegs
                        ? `${t('attributes.viewAllLegs')}`
                        : `${t('attributes.viewSporadicOnly')}`
                    }
                  >
                    <Button
                      startIcon={<PageviewIcon />}
                      color="primary"
                      variant="contained"
                      disabled={reportLoading}
                      onClick={() => {
                        if (viewCancelledLegs)
                          getRouteLegsData(
                            reportStartDate,
                            reportEndDate,
                            false,
                            true,
                          );
                        else
                          getRouteLegsData(
                            reportStartDate,
                            reportEndDate,
                            true,
                          );
                        setViewCancelledLegs(!viewCancelledLegs);
                      }}
                    >
                      {viewCancelledLegs
                        ? `${t('button.allCancelled')}`
                        : `${t('button.onlySporadic')}`}
                    </Button>
                  </Tooltip>
                </Grid>
                <div style={{ width: '100%', marginTop: 10 }}>
                  {reportLoading ? (
                    'Loading...'
                  ) : (
                    <ReportToTerminalSporadicRoutes
                      shortCountryCode={selectedTerminals.shortCountryCode}
                      legs={legsData}
                      onGetTFTExcelClicked={() => onExportTFTClicked()}
                    />
                  )}
                </div>
              </Grid>
            )}
        </>
      )}
    </LoadingAndError>
  );
}
