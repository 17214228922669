import { isBefore, isEqual } from 'date-fns';
import {
  addDaysToDateString,
  getTimeFromDateString,
  parseDateString,
} from './date_time';

export const BREAK_POINT_HOUR = 4;

interface Leg {
  arrivalTime: string | null;
  transportationDateOffset: number;
  position: number;
}

type WithRouteLegDates<T> = T & {
  transportationDate: string;
  daysDiff: number;
};

export function calculateTourTransportationDates<T extends Leg>(
  legs: T[],
): WithRouteLegDates<T>[] {
  const transportationDateString = '2023-01-01';
  const sortedLegs = [...legs].sort((a, b) => a.position - b.position);

  return sortedLegs.reduce((newLegs, leg, index) => {
    const previousLeg = index !== 0 ? newLegs[index - 1] : undefined;

    if (leg.arrivalTime == null) {
      return [
        ...newLegs,
        {
          ...leg,
          transportationDate: transportationDateString,
          daysDiff: 0,
        },
      ];
    }

    if (previousLeg?.arrivalTime == null) {
      return [
        ...newLegs,
        {
          ...leg,
          transportationDate: transportationDateString,
          daysDiff: 0,
        },
      ];
    }

    //Transportation date
    const legTransportationDateString = addDaysToDateString(
      determineTransportationDate(
        index === 0
          ? transportationDateString
          : newLegs[index - 1].transportationDate,
        leg.arrivalTime,
        previousLeg?.arrivalTime,
      ),
      leg.transportationDateOffset,
    );

    return [
      ...newLegs,
      {
        ...leg,
        transportationDate: legTransportationDateString,
        daysDiff: getDateDiff(
          transportationDateString,
          legTransportationDateString,
        ),
      },
    ];
  }, [] as WithRouteLegDates<T>[]);
}

function determineTransportationDate(
  dateString: string,
  currentTimeString: string,
  previousTimeString?: string,
): string {
  const date = parseDateString(dateString);
  const time = getTimeFromDateString(date, currentTimeString);

  if (previousTimeString != null) {
    const previousTime = getTimeFromDateString(date, previousTimeString);
    if (isBefore(time, previousTime) || isEqual(time, previousTime)) {
      return addDaysToDateString(dateString, 1);
    }
  }
  return dateString;
}

function getDateDiff(
  baseDateString: string,
  arrivalDateString: string,
): number {
  const a = new Date(baseDateString);
  const b = new Date(arrivalDateString);
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}
