import {
  Box,
  Grid,
  IconButton,
  Input,
  TextField,
  Typography,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import * as yup from 'yup';
import {
  GetCardDocument,
  GetCheckpointDocument,
  GetCustomerDocument,
  GetOneTerminalDocument,
  GetSubcontractorDocument,
  GetTagsQuery,
  useCreateCardContactMutation,
  useGetTagsQuery,
} from '../generated/graphql';
import { iconForType } from '../lib/iconForType';
import { ContactTagModal } from './modal/ContactTagModal';

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.name'),
          len: 3,
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    role: yup.string(),
    phoneNumber: yup.string().min(
      3,
      t('validation.atLeastLen', {
        phoneNumber: t('attributes.phoneNumber'),
        len: 3,
      }),
    ),
    email: yup.string().min(
      3,
      t('validation.atLeastLen', {
        email: t('attributes.email'),
        len: 3,
      }),
    ),
  });

interface addLocationProps {
  next: string;
}

export function getRefetchCardQueryByUrl(type: string) {
  switch (type) {
    case 'checkpoints':
      return GetCheckpointDocument;
    case 'terminals':
      return GetOneTerminalDocument;
    case 'subcontractors':
      return GetSubcontractorDocument;
    case 'customers':
      return GetCustomerDocument;
    default:
      return GetCardDocument;
  }
}

export function AddContact(props: addLocationProps) {
  const { t } = useTranslation();
  const router = useHistory();
  const { path } = useRouteMatch();
  const cardType = path.split('/')[3];
  const cardTypeName =
    cardType && cardType !== ''
      ? cardType.substring(0, cardType.length - 1)
      : '';
  const Icon = iconForType(cardTypeName);
  const refreshQueryDocument = getRefetchCardQueryByUrl(cardType);
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetTagsQuery();
  const [cardName, setCardName] = useState<string>('');
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);

  const location = useLocation<{
    cardName: string;
    timestamp: number;
  }>();

  useEffect(() => {
    if (location.state && location.state.cardName) {
      setCardName(location.state.cardName);
    }
  }, [location.state?.cardName, location.state?.timestamp]);

  const [createCardContact, {}] = useCreateCardContactMutation({
    refetchQueries: [
      {
        query: refreshQueryDocument,
        variables: { id: parseInt(id, 10) },
      },
    ],
  });

  if (loading || !data) {
    return <div>loading...</div>;
  }
  if (error) {
    console.error(error);
    return <div>{error.message}</div>;
  }

  return (
    <Box>
      {showAddTagModal && (
        <ContactTagModal
          open={showAddTagModal}
          handleClose={() => {
            setShowAddTagModal(false);
          }}
        />
      )}
      <Box display="flex" alignItems="center" mb={3}>
        <Typography align="left" variant="h2">
          {cardName}
        </Typography>
        <Box ml={2} display="flex" alignItems="center">
          <Typography align="left" variant="subtitle2">
            {t(`resource.${cardTypeName}.capitalized`)}
          </Typography>
          {Icon && <Icon />}
        </Box>
      </Box>

      <Box mb={6}>
        <Typography variant="h2">
          {t('pages.addPage.title', {
            item: t('resource.contact.lowercased'),
          })}
        </Typography>
      </Box>
      <Box>
        <Formik
          initialValues={{
            name: '',
            email: '',
            role: '',
            phoneNumber: '',
            tagIds: [],
            note: '',
          }}
          enableReinitialize
          validationSchema={validationSchema(t)}
          onSubmit={(values) => {
            createCardContact({
              variables: {
                input: { ...values },
                cardId: parseInt(id, 10),
              },
            });
            router.push(`/traffic/cards/${props.next}/${id}`);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Grid container spacing={5} justifyContent="center">
                <Grid item sm={10} container spacing={3}>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label={t('attributes.name')}
                      value={props.values.name}
                      onChange={props.handleChange}
                      error={props.touched.name && Boolean(props.errors.name)}
                      helperText={props.touched.name && props.errors.name}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="role"
                      name="role"
                      label={t('attributes.role')}
                      value={props.values.role}
                      onChange={props.handleChange}
                      error={props.touched.role && Boolean(props.errors.role)}
                      helperText={props.touched.role && props.errors.role}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="phoneNumber"
                      name="phoneNumber"
                      label={t('attributes.phoneNumber')}
                      value={props.values.phoneNumber}
                      onChange={props.handleChange}
                      error={
                        props.touched.phoneNumber &&
                        Boolean(props.errors.phoneNumber)
                      }
                      helperText={
                        props.touched.phoneNumber && props.errors.phoneNumber
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label={t('attributes.email')}
                      value={props.values.email}
                      onChange={props.handleChange}
                      error={props.touched.email && Boolean(props.errors.email)}
                      helperText={props.touched.email && props.errors.email}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      id="note"
                      name="note"
                      label={t('attributes.note')}
                      value={props.values.note}
                      onChange={props.handleChange}
                      error={props.touched.note && Boolean(props.errors.note)}
                      helperText={props.touched.note && props.errors.note}
                    />
                  </Grid>

                  <Grid item sm={11}>
                    <Autocomplete
                      id="tagIds"
                      multiple
                      autoHighlight
                      options={data.cardContactTags}
                      getOptionLabel={(
                        tag: GetTagsQuery['cardContactTags'][number],
                      ) => tag.title}
                      onChange={(
                        _event: ChangeEvent<unknown>,
                        value: GetTagsQuery['cardContactTags'],
                      ) => {
                        props.setFieldValue(
                          'tagIds',
                          value.map((tag) => {
                            return tag.id;
                          }),
                        );
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Add contact role"
                          placeholder="Tags"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item sm={1}>
                    <IconButton
                      onClick={() => {
                        setShowAddTagModal(true);
                      }}
                    >
                      <AddCircleOutline color="action" />
                    </IconButton>
                  </Grid>

                  <Grid item sm={12} container justifyContent="center">
                    <Input
                      type="submit"
                      value={t('button.add', {
                        item: t('resource.contact.lowercased'),
                      })}
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
