import { Button, makeStyles, Theme } from '@material-ui/core';
import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '../lib/date_time';

const useStyles = makeStyles((theme: Theme) => ({
  formRoot: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 2),
    },
  },
}));

interface SelectDateRangeValues {
  startDate: string;
  endDate: string;
}

interface SelectDateRangeFormProps {
  startDate: string;
  endDate: string;
  onDateSelected: (values: { startDate: string; endDate: string }) => void;
  buttonText: string;
  startDateText?: string;
  endDateText?: string;
}

export function SelectDateRangeForm(props: SelectDateRangeFormProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    startDate,
    endDate,
    onDateSelected,
    buttonText,
    startDateText,
    endDateText,
  } = props;
  const initialValues: SelectDateRangeValues = { startDate, endDate };
  const [dateChanged, setDateChanged] = useState(false);

  const onChangeDate = (values: SelectDateRangeValues) => {
    onDateSelected(values);
    setDateChanged(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onChangeDate}
    >
      {() => (
        <Form className={classes.formRoot}>
          <Field name="startDate">
            {({ field, form }: FieldProps) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  value={field.value}
                  label={startDateText ? startDateText : t('filter.startDate')}
                  onChange={(newValue) => {
                    if (newValue) {
                      const formatDate = format(
                        new Date(newValue),
                        DATE_FORMAT,
                      );
                      setDateChanged(formatDate !== startDate);
                      form.setFieldValue('startDate', formatDate);
                    }
                  }}
                  inputFormat="yyyy-MM-dd"
                  maxDate={form.getFieldProps('endDate').value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      sx={{ width: 140 }}
                      id="date"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      onChange={(event) => {
                        field.onChange(event);
                      }}
                      size="small"
                      fullWidth={false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </Field>
          <Field name="endDate">
            {({ field, form }: FieldProps) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  value={field.value}
                  label={endDateText ? endDateText : t('filter.endDate')}
                  onChange={(newValue) => {
                    if (newValue) {
                      const formatDate = format(
                        new Date(newValue),
                        DATE_FORMAT,
                      );
                      setDateChanged(formatDate !== endDate);
                      form.setFieldValue('endDate', formatDate);
                    }
                  }}
                  inputFormat="yyyy-MM-dd"
                  minDate={form.getFieldProps('startDate').value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      sx={{ width: 140, marginLeft: 2 }}
                      id="date"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                      onChange={(event) => {
                        field.onChange(event);
                      }}
                      size="small"
                      fullWidth={false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          </Field>
          <Button
            type="submit"
            variant="contained"
            disabled={!dateChanged}
            color="primary"
          >
            {buttonText}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
