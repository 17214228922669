import { ApolloError } from '@apollo/client/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { SporadicRouteTemplatesQuery } from '../../generated/graphql';
import { TABLE_NAMES } from '../../lib/constants';
import { ViewSporadicRouteTemplateColumns } from '../../utils/ViewSporadicRouteTemplateColumns';
import { UserConfiguredDataGridPro } from '../datagrid/UserConfiguredDataGridPro';
import { COUNTRIES_MAP } from '../../lib/constants';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '70vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface ViewSporadicRouteTemplateModalProps {
  item: SporadicRouteTemplatesQuery['sporadicRouteTemplates'] | undefined;
  handleClose: () => void;
  open: boolean;
  loading: boolean;
  error: ApolloError | undefined;
}

interface sporadicRouteTemplateProps {
  id: number;
  name: string;
  routeId: string;
  createdAt: any;
  departureCountry: string | undefined;
  arrivalCountry: string | undefined;
}

interface ListSporadicRouteTemplatesProps {
  item: sporadicRouteTemplateProps[] | undefined;
  onClose: () => void;
  loading: boolean;
  error: ApolloError | undefined;
}

export function ViewSporadicRouteTemplateModal(
  props: ViewSporadicRouteTemplateModalProps,
) {
  const { item, handleClose, open, loading, error } = props;
  const itemModified = item
    ? item.map((sporadicRouteTemplate) => {
        return {
          id: sporadicRouteTemplate.id,
          name: sporadicRouteTemplate.name,
          routeId: sporadicRouteTemplate.routeId,
          createdAt: sporadicRouteTemplate.createdAt,
          departureCountry:
            sporadicRouteTemplate.legs && sporadicRouteTemplate.legs.length > 0
              ? COUNTRIES_MAP[
                  sporadicRouteTemplate.legs.find((leg) => leg.position === 0)
                    ?.location.country as keyof typeof COUNTRIES_MAP
                ].toLocaleUpperCase()
              : '',
          arrivalCountry:
            sporadicRouteTemplate.legs && sporadicRouteTemplate.legs.length > 0
              ? COUNTRIES_MAP[
                  sporadicRouteTemplate.legs.find(
                    (leg) =>
                      leg.position === sporadicRouteTemplate.legs.length - 1,
                  )?.location.country as keyof typeof COUNTRIES_MAP
                ].toLocaleUpperCase()
              : '',
        };
      })
    : undefined;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ListSporadicRouteTemplates
        item={itemModified}
        onClose={handleClose}
        loading={loading}
        error={error}
      />
    </Modal>
  );
}
export function ListSporadicRouteTemplates(
  props: ListSporadicRouteTemplatesProps,
) {
  const { item, onClose, loading, error } = props;
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  if (error || loading || item == null || item == undefined) {
    if (error) {
      console.error(error);
    }
    return (
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h4" gutterBottom component="h2">
            Sporadic route templates
          </Typography>

          <IconButton onClick={onClose}>
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
        </Box>

        <div style={{ height: '100%', width: '100%' }}>
          {error ? error.message : 'Loading...'}
        </div>
      </Box>
    );
  }
  return (
    <Box sx={style}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h4" gutterBottom component="h2">
          Sporadic route templates
        </Typography>

        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" color="action" />
        </IconButton>
      </Box>

      <div style={{ height: '100%', width: '100%' }}>
        <UserConfiguredDataGridPro
          tableName={TABLE_NAMES.ViewSporadicRouteTemplates}
          rows={item}
          columns={ViewSporadicRouteTemplateColumns({ t, path })}
          pageSize={5}
          rowHeight={50}
          autoHeight={true}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </div>
    </Box>
  );
}
