import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import { DataGridPro, GridDensityTypes } from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useListAssociatedRoutesQuery } from '../generated/graphql';

interface CardAssociatedRoutesProps {
  cardId: number;
}

export function CardAssociatedRoutes(props: CardAssociatedRoutesProps) {
  const { cardId } = props;
  const { t } = useTranslation();
  const { data, loading } = useListAssociatedRoutesQuery({
    variables: {
      id: cardId,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return null;
  }

  const allRoutes = data ? data.associatedRoutes || [] : [];

  return (
    <>
      <Grid container>
        <Box display="flex" flexDirection="row" width="100%" mb={2}>
          <Typography variant="h3">
            {t('resource.associatedRoutes.active')}
          </Typography>
        </Box>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={allRoutes || []}
            disableSelectionOnClick
            columns={[
              {
                headerName: 'View',
                field: 'view',
                minWidth: 80,
                type: 'action',
                renderCell: (params) => {
                  const linkPath = `/traffic/tours/${params.row.tourId}?route=${params.row.routePosition}`;
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                    >
                      <Tooltip title={`${t('actions.view')}`}>
                        <Link
                          to={linkPath}
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <PageviewIcon color="action" />
                        </Link>
                      </Tooltip>
                    </Box>
                  );
                },
              },
              {
                field: 'routeId',
                headerName: t('attributes.id'),
                hide: true,
              },
              {
                field: 'routeName',
                headerName: t('resource.route.capitalized'),
                width: 150,
              },
              {
                field: 'tourName',
                headerName: t('resource.tour.capitalized'),
                width: 150,
              },
              {
                field: 'departureLocation',
                headerName: t('attributes.departureLocation'),
                width: 200,
              },
              {
                field: 'departureTime',
                headerName: t('attributes.departureTime'),
                width: 170,
                valueFormatter(params) {
                  const { value } = params;
                  if (typeof value === 'string') {
                    return format(
                      parse(value, 'HH:mm:ss', new Date()),
                      'HH:mm',
                    );
                  }
                  return '';
                },
              },
              {
                field: 'arrivalLocation',
                headerName: t('attributes.arrivalLocation'),
                width: 200,
              },
              {
                field: 'arrivalTime',
                headerName: t('attributes.arrivalTime'),
                width: 150,
                valueFormatter(params) {
                  const { value } = params;
                  if (typeof value === 'string') {
                    return format(
                      parse(value, 'HH:mm:ss', new Date()),
                      'HH:mm',
                    );
                  }
                  return '';
                },
              },
              {
                field: 'subContractorName',
                headerName: t('attributes.subcontractorId'),
                width: 200,
              },
              {
                field: 'frequency',
                headerName: t('attributes.frequency'),
                width: 200,
                sortable: false,
              },
            ]}
            rowHeight={50}
            autoHeight={true}
            density={GridDensityTypes.Compact}
          />
        </div>
      </Grid>
    </>
  );
}
