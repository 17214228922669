import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { HttpClient } from '../lib/httpClient';
import { useHttpClient } from '../providers/HttpClientProvider';
import { updateRouteNameToValidName } from '../utils/UpdateRouteNameToValidName';

type DownloadLoadingListProps = {
  id: string;
  routeId: string;
  isLegAvailable: boolean;
};

// Create Document Component
export function DownloadLoadingList(props: DownloadLoadingListProps) {
  const { routeId, id, isLegAvailable } = props;
  const { httpClient } = useHttpClient();
  const { t } = useTranslation();

  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      disabled={!isLegAvailable}
      onClick={() => {
        getLoadingList(parseInt(id), routeId, httpClient);
      }}
    >
      {t('button.download', {
        item: t(`resource.loadingList.lowercased`),
      })}
    </Button>
  );
}

export function getLoadingList(
  id: number, //routeid2
  routeId: string,
  httpClient: HttpClient,
) {
  const updatedSafeRouteID = updateRouteNameToValidName(routeId);
  httpClient
    .downloadLoadingListBlob({
      routeId: id,
    })
    .then((res) => {
      const blob = res.data;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', `${updatedSafeRouteID} - loadinglist`);
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
}
