import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../lib/date_time';
import { TextField, TextFieldProps } from '@mui/material';

interface CommonTextFieldProps {
  name: string;
  fullWidth?: boolean;
  helperText?: string;
  noLabel?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  minDate?: boolean;
  maxDate?: boolean;
  width?: number;
  dateString?: string;
  linkName?: string;
  endDateString?: string;
  readOnly?: boolean;
  isClearButton?: boolean;
  isTouchedOn?: boolean;
}

export function CommonDateField(props: CommonTextFieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const {
    name,
    noLabel = false,
    disabled,
    minDate = false,
    helperText = undefined,
    fullWidth = false,
    variant = 'standard',
    dateString, //for minDate String
    endDateString = 'endDate', //for endDate String
    maxDate = false,
    readOnly = false,
    isClearButton = false,
    isTouchedOn = false,
    linkName = '',
  } = props;

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              componentsProps={
                isClearButton
                  ? {
                      actionBar: {
                        actions: ['clear'],
                      },
                    }
                  : undefined
              }
              value={field.value}
              label={noLabel ? undefined : t(`attributes.${label}`)}
              readOnly={readOnly}
              onChange={(newValue) => {
                if (newValue) {
                  const formatDate = format(new Date(newValue), DATE_FORMAT);
                  form.setFieldValue(name, formatDate);
                } else if (newValue == null) {
                  form.setFieldValue(name, '');
                  if (linkName != '' && name.includes('startExceptionDate'))
                    form.setFieldValue(linkName, '');
                }
              }}
              inputFormat="yyyy-MM-dd"
              disabled={disabled}
              minDate={
                minDate ? new Date() : form.getFieldProps(`${dateString}`).value
              }
              maxDate={
                maxDate
                  ? form.getFieldProps(`${endDateString}`).value
                  : undefined
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  id="date"
                  variant={variant}
                  error={(meta.touched || isTouchedOn) && Boolean(meta.error)}
                  helperText={
                    helperText || ((meta.touched || isTouchedOn) && meta.error)
                  }
                  placeholder="yyyy-MM-dd"
                  inputProps={{
                    readOnly: true,
                  }}
                  size="small"
                  fullWidth={fullWidth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        );
      }}
    </Field>
  );
}
