import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  SporadicRouteTemplateForm,
  SporadicRouteTemplateFormInput,
} from '../components/SporadicRouteTemplateForm';
import {
  GetSporadicRouteTemplateDocument,
  SporadicRouteTemplatesDocument,
  useDeleteSporadicRouteTemplateMutation,
  useGetSporadicRouteTemplateQuery,
  useUpdateSporadicRouteTemplateMutation,
} from '../generated/graphql';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { CreatedAndUpdatedView } from '../components/CreatedAndUpdatedView';
import { DialogModal } from '../components/DialogModal';
import { routeStyle } from './ViewRoute';
import { timeOrNull } from '../lib/date_time';
import { numberOrNull } from '../lib/formHelpers/number';

export function ViewSporadicRouteTemplate() {
  const router = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState(false);
  const classes = routeStyle();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const { data, loading, error } = useGetSporadicRouteTemplateQuery({
    variables: { id: parseInt(id, 10) },
  });

  const [updateSporadicRouteTemplate] = useUpdateSporadicRouteTemplateMutation({
    refetchQueries: [
      {
        query: GetSporadicRouteTemplateDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const [deleteRouteTemplate] = useDeleteSporadicRouteTemplateMutation({
    refetchQueries: [
      {
        query: SporadicRouteTemplatesDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const onSubmit = async (
    values: Omit<
      SporadicRouteTemplateFormInput,
      'dispatcherId' | 'subcontractorId'
    > & {
      dispatcherId?: number;
      subcontractorId?: number;
    },
  ) => {
    await updateSporadicRouteTemplate({
      variables: {
        id: parseInt(id, 10),
        SporadicRouteTemplateInput: {
          name: values.name,
          agreedPrice: values.agreedPrice,
          currency: values.currency,
          note: values.note,
          externalNote: values.externalNote,
          driverName: values.driverName,
          driverPhoneNumber: values.driverPhoneNumber,
          licensePlate: values.licensePlate,
          capacity: numberOrNull(values.capacity),
          kilometer: numberOrNull(values.kilometer),
          weight: numberOrNull(values.weight),
          routeId: values.routeId,
          dispatcherId: values?.dispatcherId as number,
          subcontractorId: values?.subcontractorId as number,
          files: values.files.map((file) => ({
            id: file.id,
          })),
          ftl: values.ftl
            ? {
                cdc: values.ftl?.cdc || '',
                cmr: values.ftl?.cmr || '',
                currency: values.ftl?.currency || '',
                invoiceNote: values.ftl?.invoiceNote || '',
                price: values.ftl?.price || 0,
                customerNumber: values.ftl?.customerNumber || '',
                customerReference: values.ftl?.customerReference || '',
                customerId: values.ftl?.customerId as number,
              }
            : undefined,
          legs: values.legs.map((leg, index) => ({
            load: leg.load,
            unload: leg.unload,
            fuel: leg.fuel,
            gateNumber: leg.gateNumber ?? '',
            carRegistrationNumber: leg.carRegistrationNumber ?? '',
            trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
            routeDriverName: leg.routeDriverName ?? '',
            routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
            note: leg.note ?? '',
            arrivalTime: leg.arrivalTime ?? '00:00',
            departureTime: timeOrNull(leg.departureTime),
            position: index,
            transportationDateOffset: leg.transportationDateOffset,
            locationId: leg.locationId as number,
            location: undefined,
            loadingListItems: leg.load
              ? leg.loadingListItems.map((loadingListItem) => ({
                  ...loadingListItem,
                  __typename: undefined,
                  createdBy: undefined,
                  lastUpdatedBy: undefined,
                  updatedAt: undefined,
                  locationId: loadingListItem.locationId as number,
                  packages: numberOrNull(loadingListItem.packages),
                  pallets: numberOrNull(loadingListItem.pallets),
                  palletSpace: numberOrNull(loadingListItem.palletSpace),
                  weight: numberOrNull(loadingListItem.weight),
                  classification: loadingListItem.classification,
                  unNumber: loadingListItem.unNumber,
                  isLimitedQty: loadingListItem.isLimitedQty,
                }))
              : [],
          })),
        },
      },
    });
    setEdit(false);
  };
  if (loading || data == null) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const initialValues = (): Omit<
    SporadicRouteTemplateFormInput,
    'dispatcherId' | 'subcontractorId'
  > & {
    dispatcherId?: number;
    subcontractorId?: number;
  } => ({
    name: data.sporadicRouteTemplate.name,
    agreedPrice: data.sporadicRouteTemplate.agreedPrice,
    currency: data.sporadicRouteTemplate.currency,
    note: data.sporadicRouteTemplate.note,
    externalNote: data.sporadicRouteTemplate.externalNote ?? '',
    driverName: data.sporadicRouteTemplate.driverName,
    driverPhoneNumber: data.sporadicRouteTemplate.driverPhoneNumber,
    licensePlate: data.sporadicRouteTemplate.licensePlate,
    capacity: data.sporadicRouteTemplate.capacity,
    kilometer: data.sporadicRouteTemplate.kilometer,
    weight: data.sporadicRouteTemplate.weight,
    routeId: data.sporadicRouteTemplate.routeId,
    dispatcherId: data.sporadicRouteTemplate.dispatcher?.id,
    subcontractorId: data.sporadicRouteTemplate.subcontractor?.id,
    files: data.sporadicRouteTemplate.files,
    ftl: data?.sporadicRouteTemplate.ftl || undefined,
    legs: data.sporadicRouteTemplate.legs.map((leg) => ({
      ...leg,
      note: leg.note === '' ? undefined : leg.note,
      fuel: leg.fuel,
      gateNumber: leg.gateNumber ?? '',
      carRegistrationNumber: leg.carRegistrationNumber ?? '',
      trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
      routeDriverName: leg.routeDriverName ?? '',
      routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
      loadingListItems: leg.loadingListItems.map((loadingListItem) => ({
        ...loadingListItem,
        packages: loadingListItem.packages ?? '',
        pallets: loadingListItem.pallets ?? '',
        palletSpace: loadingListItem.palletSpace ?? '',
        weight: loadingListItem.weight ?? '',
      })),
    })),
  });

  return (
    <Grid container spacing={1} direction="column">
      <Grid item xs={12} direction="row">
        <Box className={classes.header}>
          <Typography variant="h1" align="left">
            {`${t('resource.sporadicRoute.capitalized')} ${t(
              'resource.template.lowercased',
            )}`}
          </Typography>
          <Box>
            <Tooltip
              title={edit ? `${t('actions.cancel')}` : `${t('actions.edit')}`}
            >
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => setEdit(!edit)}
              >
                {edit ? <CancelIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <CreatedAndUpdatedView
          createdBy={data.sporadicRouteTemplate.createdBy}
          lastUpdatedBy={data.sporadicRouteTemplate.lastUpdatedBy}
          updatedAt={data.sporadicRouteTemplate.updatedAt}
        />
      </Grid>
      <Grid item>
        {edit && (
          <Grid item xs={12}>
            <Button
              classes={{
                root: classes.button,
              }}
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            >
              {t('button.delete', {
                item: t(`resource.sporadicRoute.lowercased`),
              })}
            </Button>
            <DialogModal
              open={openDeleteDialog}
              setOpen={setOpenDeleteDialog}
              contentText={`${t('validation.confirmation', {
                action: t('actions.delete.lowercased'),
                item: data.sporadicRouteTemplate.name,
              })}`}
              doAction={() => {
                deleteRouteTemplate({
                  variables: { id: data.sporadicRouteTemplate.id },
                });
                router.push(`/traffic/sporadic-routes`);
              }}
              buttonText={t('button.continue')}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <SporadicRouteTemplateForm
          onSubmit={onSubmit}
          initialValues={initialValues()}
          readOnly={!edit}
          submitButtonLabel={t('button.update', {
            item: `${t('resource.sporadicRoute.capitalized')} ${t(
              'resource.template.lowercased',
            )}`,
          })}
          fromCreateRoute="ViewSporadicRouteTemplate"
        />
      </Grid>
    </Grid>
  );
}
