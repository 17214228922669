import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ReactComponent as NotesIcon } from '../../svgIcons/notesicon.svg';
import { ReactComponent as DangerIcon } from '../../svgIcons/dangericon.svg';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { styleForTimeModal } from '../datagrid/LoadingListModal';
import { CheckboxIconField } from '../form/CheckboxIconField';
import { FastCheckbox } from '../form/FastCheckbox';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { SelectLocation } from '../form/SelectLocation';
import { useStylesForLoadingList } from '../LoadingList';
import { SimpleMessageDialog } from '../SimpleMessageDialog';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import {
  DangerousGoodsClassification,
  CustomsProcedure,
  EditShipmentLoadingList,
} from '../../generated/graphql';
import { FastTextFieldUnNumber } from '../form/FastTextFieldUnNumber';
import { InfoOutlined } from '@material-ui/icons';
import { shipmentOrderYupValidation } from '../../lib/validationSchema/loadingListItem';
import * as yup from 'yup';
import { CommonTextField } from '../form/CommonTextField';

const shipmentOrderValidation = (t: TFunction) =>
  yup.object().shape({
    loadingListValue: shipmentOrderYupValidation(t),
  });

interface shipmentProps {
  showModal: boolean;
  onClose: () => void;
  from: string;
  onSubmit: (shipmentInput: EditShipmentLoadingList[]) => void;
}

export function ShipmentOrderModal(props: shipmentProps) {
  const { showModal, onClose, onSubmit } = props;
  const { t } = useTranslation();
  const classes = useStylesForLoadingList();
  const selectedGreen = '#66ab49';
  const dangerRed = '#ff0000';
  const normalGrey = '#6e6e6e';
  const loadingListValue = [
    {
      id: undefined,
      packages: 0,
      pallets: 0,
      palletSpace: 0,
      note: '',
      locationId: 0,
      checked: false,
      isDangerous: false,
      addNote: false,
      isCustom: false,
      addFile: false,
      classification: undefined,
      customsProcedure: undefined,
      unNumber: 0,
      isLimitedQty: false,
      isVoecCustomer: false,
      customWaybillNo: '',
    },
  ];

  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(-1);
  const [openLimitedQtyInfoDialog, setOpenLimitedQtyInfoDialog] =
    useState<boolean>(false);

  const handleBorderVisibility = (index: number) => {
    if (index !== currentFocusedIndex) setCurrentFocusedIndex(index);
  };
  function getChecked(isChecked: boolean): string {
    if (isChecked) return classes.greenChecked;
    else return '';
  }

  function getDangerous(isDangerous: boolean): string {
    if (isDangerous) return classes.redBorder;
    else return '';
  }

  return (
    <Modal
      open={showModal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={styleForTimeModal}>
        <Box display="flex" flexDirection="row" justifyContent="" width="100%">
          <Typography variant="h6">{'Order'}</Typography>
        </Box>
        <Formik
          initialValues={{ loadingListValue }}
          validate={(value) => {
            try {
              validateYupSchema(value, shipmentOrderValidation(t), true, value);
            } catch (err) {
              const errors = yupToFormErrors<{ error: string }>(err);
              return errors;
            }
            return {};
          }}
          validateOnBlur
          validateOnChange={false}
          onSubmit={(value) => {
            const loadingList: EditShipmentLoadingList[] = [];
            value.loadingListValue.forEach((list) => {
              if (list.locationId) {
                loadingList.push({
                  checked: list.checked,
                  classification: list.classification,
                  isDangerous: list.isDangerous,
                  isLimitedQty: list.isLimitedQty,
                  locationId: list.locationId,
                  note: list.note,
                  packages: list.packages,
                  palletSpace: list.palletSpace,
                  pallets: list.pallets,
                  unNumber: list.unNumber,
                  isVoecCustomer: list.isVoecCustomer,
                  isRemainingGoods: false,
                  customCleared: false,
                  customClearedForImport: false,
                  terminalCleared: false,
                  terminalNotCleared: false,
                  terminalShipmentNotArrived: false,
                  customsProcedure: list.customsProcedure,
                  customWaybillNo: list.customWaybillNo,
                });
              }
            });
            onSubmit(loadingList);
            onClose();
          }}
        >
          {(props) => {
            return (
              <Form>
                <FieldArray
                  name={'loadingListValue'}
                  render={(arrayHelpers) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        spacing={2}
                        container
                        className={classes.root}
                      >
                        <SimpleMessageDialog
                          open={openLimitedQtyInfoDialog}
                          setOpen={setOpenLimitedQtyInfoDialog}
                          title={t('validation.limitedQtyInfoTitle')}
                          contentText={t('validation.limitedQtyInfo')}
                          isMultiLine={true}
                        />
                        <Grid item xs={10}>
                          <Typography variant="subtitle1" align="left">
                            {t('resource.loadingList.capitalized')}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                arrayHelpers.push({
                                  id: undefined,
                                  packages: 0,
                                  pallets: 0,
                                  palletSpace: 0,
                                  note: '',
                                  locationId: 0,
                                  checked: false,
                                  isDangerous: false,
                                  addNote: false,
                                  isCustom: false,
                                  addFiles: false,
                                  classification: undefined,
                                  unNumber: 0,
                                  isLimitedQty: false,
                                  isVoecCustomer: false,
                                  customWaybillNo: '',
                                });
                              }}
                            >
                              {t('button.add', { item: t('order') })}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          xs={12}
                          item
                          container
                          style={{
                            backgroundColor: '#c0c4bc',
                            marginBottom: 5,
                            borderRadius: 2,
                            marginTop: 5,
                          }}
                        >
                          <Grid item xs={4}>
                            <Typography variant="body2" color="textPrimary">
                              {'Customer'}
                            </Typography>
                          </Grid>

                          <Grid item xs={1}>
                            <Typography variant="body2" color="textPrimary">
                              {'Packages'}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body2" color="textPrimary">
                              {'Pallets'}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            <Typography variant="body2" color="textPrimary">
                              {'Pallet space'}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography variant="body2" color="textPrimary">
                              {'Cust. WayBill'}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2" color="textPrimary">
                              {'Customs procedure'}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{ backgroundColor: '#f6fce8' }}
                        >
                          <List className={classes.noPadding}>
                            {props.values.loadingListValue.map(
                              (_order, index) => (
                                <>
                                  <ListItem
                                    key={index}
                                    divider
                                    className={`${getChecked(
                                      _order.checked,
                                    )} ${getDangerous(_order.isDangerous)} ${
                                      classes.noPadding
                                    }`}
                                  >
                                    <Grid
                                      xs={12}
                                      item
                                      container
                                      key={index}
                                      style={{ width: '100%' }}
                                      onFocus={(event) => {
                                        if (
                                          !event.currentTarget.contains(
                                            event.relatedTarget as Node,
                                          )
                                        ) {
                                          handleBorderVisibility(index);
                                        }
                                      }}
                                      onBlur={(event) => {
                                        if (
                                          !event.currentTarget.contains(
                                            event.relatedTarget as Node,
                                          )
                                        ) {
                                          setCurrentFocusedIndex(-1);
                                        }
                                      }}
                                      className={`${
                                        currentFocusedIndex === index
                                          ? classes.greyBackFocused
                                          : ''
                                      } ${classes.noPadding}`}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        style={{ width: '100%' }}
                                      >
                                        <Grid item xs={4}>
                                          <SelectLocation
                                            key={`loadingListValue${index}`}
                                            name={`loadingListValue.${index}.locationId`}
                                            className={
                                              classes.selecLocationField
                                            }
                                            size="small"
                                            smallFontSize
                                            noLabel
                                            smallPadding
                                            shortAddress={true}
                                          />
                                        </Grid>

                                        <Grid item xs={1}>
                                          <FastTextFieldPackage
                                            noLabel
                                            size="small"
                                            smallFontSize
                                            name={`loadingListValue.${index}.packages`}
                                            className={classes.numberFields}
                                            type="number"
                                            smallPadding
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <FastTextFieldPallets
                                            noLabel
                                            size="small"
                                            smallFontSize
                                            name={`loadingListValue.${index}.pallets`}
                                            className={classes.numberFields}
                                            type="number"
                                            smallPadding
                                          />
                                        </Grid>
                                        <Grid item xs={1}>
                                          <FastTextFieldPalletSpace
                                            noLabel
                                            size="small"
                                            smallFontSize
                                            smallPadding
                                            name={`loadingListValue.${index}.palletSpace`}
                                            className={classes.numberFields}
                                            type="number"
                                          />
                                        </Grid>
                                        <Grid item container xs={2}>
                                          <CommonTextField
                                            name={`loadingListValue.${index}.customWaybillNo`}
                                            size="small"
                                            label={false}
                                            className={classes.field}
                                            smallPadding
                                            noLabel
                                            smallFontSize
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          container
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <Box
                                            width={'100%'}
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                          >
                                            <SelectEnumFieldSmall
                                              name={`loadingListValue.${index}.customsProcedure`}
                                              key={`loadingListValue.${index}.customsProcedure`}
                                              enumObject={CustomsProcedure}
                                              translationPath={
                                                'enums.customsProcedure'
                                              }
                                            />
                                            <Tooltip title="Add note">
                                              <div>
                                                <CheckboxIconField
                                                  name={`loadingListValue.${index}.addNote`}
                                                  icon={
                                                    <NotesIcon
                                                      height={24}
                                                      width={24}
                                                      fill={normalGrey}
                                                    />
                                                  }
                                                  checkedIcon={
                                                    <NotesIcon
                                                      height={24}
                                                      width={24}
                                                      fill={selectedGreen}
                                                    />
                                                  }
                                                  size="small"
                                                />
                                              </div>
                                            </Tooltip>
                                            <Tooltip
                                              title={`${t(
                                                'attributes.tooltipDangerousGoodsIcon',
                                              )}`}
                                            >
                                              <div>
                                                <CheckboxIconField
                                                  name={`loadingListValue.${index}.isDangerous`}
                                                  icon={
                                                    <DangerIcon
                                                      height={24}
                                                      width={24}
                                                      fill={normalGrey}
                                                    />
                                                  }
                                                  checkedIcon={
                                                    <DangerIcon
                                                      height={24}
                                                      width={24}
                                                      fill={dangerRed}
                                                    />
                                                  }
                                                  size="small"
                                                />
                                              </div>
                                            </Tooltip>
                                            <>
                                              {props.values.loadingListValue
                                                .length >= 2 && (
                                                <IconButton
                                                  className={
                                                    classes.deleteButton
                                                  }
                                                  onClick={() => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              )}
                                            </>
                                          </Box>
                                        </Grid>
                                      </Box>
                                      {_order.addNote && (
                                        <Grid
                                          container
                                          // justifyContent="flex-start"
                                          alignItems="center"
                                          style={{ margin: 5 }}
                                        >
                                          <Grid item xs={1}>
                                            <Typography
                                              style={{
                                                fontSize: 12,
                                              }}
                                              color="textPrimary"
                                            >{`Note-`}</Typography>
                                          </Grid>
                                          <Grid item xs={5}>
                                            <FastTextFieldNote
                                              noLabel
                                              size="small"
                                              multiline
                                              smallFontSize
                                              name={`loadingListValue.${index}.note`}
                                              className={classes.field}
                                              smallPadding
                                            />
                                          </Grid>
                                        </Grid>
                                      )}

                                      {_order.isDangerous && (
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="flex-start"
                                          style={{
                                            width: '100%',
                                            marginTop: 1,
                                          }}
                                        >
                                          {/* new for dangerous goods info */}
                                          <Grid
                                            item
                                            xs={5}
                                            container
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <Grid item xs={2}>
                                              <Typography
                                                style={{
                                                  fontSize: 12,
                                                }}
                                                color="textPrimary"
                                              >{`Goods Info -`}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                              <SelectEnumFieldSmall
                                                name={`loadingListValue.${index}.classification`}
                                                enumObject={
                                                  DangerousGoodsClassification
                                                }
                                                translationPath={
                                                  'enums.dangerousGoodsClass'
                                                }
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            container
                                            direction="row"
                                            alignItems="center"
                                          >
                                            <Grid item xs={2}>
                                              <Typography
                                                style={{
                                                  fontSize: 12,
                                                }}
                                                color="textPrimary"
                                              >{`UN-`}</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                              <FastTextFieldUnNumber
                                                noLabel
                                                size="small"
                                                smallFontSize
                                                name={`loadingListValue.${index}.unNumber`}
                                                className={classes.numberFields}
                                                type="number"
                                                placeholder="4 digit number"
                                                smallPadding
                                                autoComplete="off"
                                                onInput={(e) => {
                                                  (
                                                    e.target as HTMLInputElement
                                                  ).value = Math.max(
                                                    0,
                                                    parseInt(
                                                      (
                                                        e.target as HTMLInputElement
                                                      ).value,
                                                    ),
                                                  )
                                                    .toString()
                                                    .slice(0, 4);
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            container
                                            direction="row"
                                          >
                                            <Grid item xs={10}>
                                              <FastCheckbox
                                                name={`loadingListValue.${index}.isLimitedQty`}
                                                label={t(
                                                  'attributes.limitedQty',
                                                )}
                                                size="small"
                                              />
                                            </Grid>
                                            <Grid item xs={2}>
                                              <Tooltip
                                                title={`${t(
                                                  'attributes.tooltipLimitedQtyInfo',
                                                )}`}
                                              >
                                                <IconButton
                                                  className={
                                                    classes.deleteButton
                                                  }
                                                  onClick={() =>
                                                    setOpenLimitedQtyInfoDialog(
                                                      true,
                                                    )
                                                  }
                                                >
                                                  <InfoOutlined />
                                                </IconButton>
                                              </Tooltip>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={3}></Grid>
                                        </Box>
                                      )}
                                    </Grid>
                                  </ListItem>
                                  <Divider
                                    style={{
                                      backgroundColor: 'green',
                                      marginTop: 5,
                                      marginBottom: 5,
                                    }}
                                  />
                                </>
                              ),
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </>
                  )}
                />
                <Grid
                  item
                  container
                  justifyContent="center"
                  style={{ marginTop: 5 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                  >
                    {'Update'}
                  </Button>

                  <Button variant="contained" color="primary" onClick={onClose}>
                    {t('button.close')}
                  </Button>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
}
