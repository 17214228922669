import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function TrueOrFalseCell(props: GridRenderCellParams) {
  const { value } = props;
  if (value) {
    return <CheckIcon />;
  }

  return <ClearIcon />;
}
