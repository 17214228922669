import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { Box, TextField, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import {
  DataGridPro,
  GridCellParams,
  GridDensityTypes,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  BookmarkedDispatchedRoutesDocument,
  FixedTourGenerateResponseStatus,
  useUpdateBookmarkMutation,
} from '../../generated/graphql';
import { useHttpClient } from '../../providers/HttpClientProvider';
import { AggregatesLoadingList } from '../AggregatesLoadingList';
import { DialogModal } from '../DialogModal';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../lib/date_time';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  width: '30vw',
  height: '55vh',
  bgcolor: 'background.paper',
  borderRadius: '2%',
  transform: 'translate(-1%,3%)',
  boxShadow: 24,
  opacity: 1,
  '& .cursor-hover': {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  '& .cursor-normal': {
    '&:hover': {
      cursor: 'default',
    },
  },
};

const BigTooltip = withStyles({
  tooltip: {
    fontSize: 14,
    color: 'black',
    backgroundColor: '#a0db87',
  },
})(Tooltip);

interface LoadingListItems {
  packages?: number;
  pallets?: number;
  palletSpace?: number;
  weight?: number;
}
interface BookmarkRoutesResponse {
  id: number;
  routeId: string;
  tourRouteId: number;
  capacity?: number;
  truckLicencePlateNo?: string;
  status: FixedTourGenerateResponseStatus;
  legs: { loadingListItems: LoadingListItems[] }[];
}

export function BookmarksModal() {
  const history = useHistory();
  const { httpClient } = useHttpClient();
  const [startDate, setStartDate] = useState(format(new Date(), DATE_FORMAT));
  const [hideEditBookmark, setHideEditBookmark] = useState<boolean>(true);
  const [routeData, setRouteData] = useState<BookmarkRoutesResponse[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState(false);
  const [errorObj, setErrorObj] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });
  const [routeToRemoveBookmark, setRouteToRemoveBookmark] = useState<
    { tourRouteId: number; routeid: string } | undefined
  >(undefined);
  const { t } = useTranslation();

  const handleEditBookmarkClick = useCallback(() => {
    setHideEditBookmark(!hideEditBookmark);
  }, [hideEditBookmark]);

  const handleRemoveBookmarkClick = useCallback(
    (params: GridCellParams) => {
      setRouteToRemoveBookmark({
        tourRouteId: params.row.tourRouteId,
        routeid: params.row.routeId,
      });
      setOpenDeleteDialog(true);
    },
    [openDeleteDialog],
  );

  const getAllData = (startDate: string) => {
    setLoadingState(true);
    httpClient
      .getBookmarkedRoutes({ date: startDate })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setRouteData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setRouteData([]);
          setErrorObj({
            error: true,
            message: `${res.data.message}`,
          });
          console.error('# bookmarked Res error=', res.data.message);
        }
        setLoadingState(false);
      })
      .catch((e) => {
        console.error('# bookmarked dispatch routes error=', e);
        setLoadingState(false);
      });
    return;
  };
  useEffect(() => {
    getAllData(startDate);
  }, [startDate]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'status',
      sort: 'desc',
    },
    {
      field: 'routeId',
      sort: 'asc',
    },
  ]);
  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      if (newSortModel && !_.isEqual(newSortModel, sortModel)) {
        setSortModel(newSortModel);
      }
    },
    [sortModel],
  );
  const [updateBookmarkMutation] = useUpdateBookmarkMutation({
    refetchQueries: [
      {
        query: BookmarkedDispatchedRoutesDocument,
        variables: { date: startDate },
      },
    ],
  });

  const updateBookmark = useCallback(() => {
    if (routeToRemoveBookmark && routeToRemoveBookmark.tourRouteId) {
      updateBookmarkMutation({
        variables: {
          id: routeToRemoveBookmark.tourRouteId,
          bookmark: false,
        },
      }).then(() => {
        getAllData(startDate);
      });
    }
  }, [routeToRemoveBookmark?.tourRouteId]);
  if (errorObj.error) {
    return <div>{errorObj.message}</div>;
  }

  return (
    <Box sx={style}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1" fontSize={'small'} gutterBottom>
            <strong>{t('info.bookmarkedDispatchRoutes')}</strong>
          </Typography>
        </Box>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              value={startDate}
              label={t('filter.date')}
              onChange={(newValue) => {
                if (newValue) {
                  const formatDate = format(new Date(newValue), DATE_FORMAT);
                  setStartDate(formatDate);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ width: 140 }}
                  id="date"
                  variant="standard"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  size="small"
                  fullWidth={false}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              inputFormat="yyyy-MM-dd"
            />
          </LocalizationProvider>
        </Box>
        <Box>
          <IconButton onClick={handleEditBookmarkClick}>
            <EditIcon color="action" />
          </IconButton>
        </Box>
      </Box>
      {
        <div style={{ height: '45vh' }}>
          {loadingState || routeData == null ? (
            <div style={{ marginTop: 5 }}>loading...</div>
          ) : (
            <DataGridPro
              rows={routeData}
              columns={[
                {
                  field: 'routeId',
                  headerName: 'Route',
                  type: 'string',
                  flex: 1,
                  minWidth: 200,
                  hideSortIcons: true,
                  renderCell: (params: GridCellParams) => {
                    const linkPath = `/traffic/dispatched-routes/${params.row.id}`;

                    return params.row.status !==
                      FixedTourGenerateResponseStatus.NotFound ? (
                      <BigTooltip title={params.row.truckLicencePlateNo}>
                        <Link
                          color="inherit"
                          id={params.row.id}
                          href={linkPath}
                          underline={'hover'}
                        >
                          <strong>{params.row.routeId}</strong>
                        </Link>
                      </BigTooltip>
                    ) : (
                      <BigTooltip title={params.row.truckLicencePlateNo}>
                        <>{`${params.row.routeId} (Not Dispatched)`}</>
                      </BigTooltip>
                    );
                  },
                },
                {
                  field: 'loadCapacity',
                  headerName: 'Load / Capacity',
                  type: 'string',
                  flex: 1,
                  minWidth: 100,
                  renderCell: (params: GridCellParams) => {
                    return params.row.legs?.length &&
                      params.row.status !==
                        FixedTourGenerateResponseStatus.NotFound &&
                      params.row.legs?.length ? (
                      <AggregatesLoadingList
                        loadingListItems={params.row.legs.flatMap(
                          (leg: { loadingListItems: any }) =>
                            leg.loadingListItems,
                        )}
                        capacity={params.row.capacity}
                        from={'Bookmarks'}
                      />
                    ) : (
                      <></>
                    );
                  },
                },
                {
                  field: 'removeBookmark',
                  headerName: 'Remove Bookmark',
                  type: 'action',
                  minWidth: 100,
                  hide: hideEditBookmark,
                  renderCell: (params: GridCellParams) => {
                    return (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveBookmarkClick(params);
                        }}
                      >
                        <BookmarkRemoveIcon color="action" />
                      </IconButton>
                    );
                  },
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  type: 'string',
                  hide: true,
                },
              ]}
              pageSize={5}
              rowHeight={50}
              autoHeight={false}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              density={GridDensityTypes.Compact}
              getRowClassName={(params: GridRowParams) =>
                params.row?.status &&
                (params.row?.status ===
                  FixedTourGenerateResponseStatus.RouteAlreadyDispatched ||
                  params.row?.status ===
                    FixedTourGenerateResponseStatus.Success)
                  ? `cursor-hover`
                  : `cursor-normal`
              }
              onRowClick={(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                _params,
              ) => {
                if (
                  _params.row.status ===
                    FixedTourGenerateResponseStatus.RouteAlreadyDispatched ||
                  _params.row.status === FixedTourGenerateResponseStatus.Success
                )
                  history.push(`/traffic/dispatched-routes/${_params.row.id}`);
              }}
            />
          )}
        </div>
      }
      <DialogModal
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        contentText={t('validation.confirmation', {
          item: routeToRemoveBookmark?.routeid ?? '',
          action: t('actions.removeBookmark.lowercased'),
        })}
        doAction={updateBookmark}
        buttonText={t('button.remove', {
          item: t('resource.bookmark.capitalized'),
        })}
      />
    </Box>
  );
}
