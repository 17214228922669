import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { Maybe } from '../generated/graphql';
import { LegFieldInput } from './form/LegFieldsRHF';
import { LoadingListItemInterface } from './LoadOrderRHF';
import { SporadicRouteFormInput } from './RouteFormRHF';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  overUtilized: {
    color: 'red',
  },
  underUtilized: {
    color: '#0ea00e',
  },
  labelBold: {
    fontWeight: 'bold',
  },
}));

interface AggregatesLoadingListProps {
  from?: Maybe<string> | undefined;
}

export function AggregatesLoadingListRHF(props: AggregatesLoadingListProps) {
  const { from = '' } = props;
  const classes = useStyles();

  const { control } = useFormContext<SporadicRouteFormInput>();

  const [capacity, legs] = useWatch({
    control,
    name: ['capacity', 'legs'],
  });

  const loadingListItems = legs.flatMap(
    (leg: LegFieldInput) => leg.loadingListItems,
  );

  const sumPallets = loadingListItems.reduce(
    (sum: number, item: LoadingListItemInterface) => {
      const { pallets } = item;
      if (typeof pallets === 'number') {
        return sum + pallets;
      } else if (typeof pallets === 'string') {
        return sum + (pallets === '' ? 0 : parseInt(pallets));
      }

      return sum;
    },
    0,
  );

  const sumPalletSpace = loadingListItems.reduce(
    (sum: number, item: LoadingListItemInterface) => {
      const { palletSpace } = item;
      if (typeof palletSpace === 'number') {
        return sum + palletSpace;
      } else if (typeof palletSpace === 'string') {
        return sum + (palletSpace === '' ? 0 : parseInt(palletSpace));
      }

      return sum;
    },
    0,
  );

  const utilPercent = capacity
    ? Math.ceil((sumPalletSpace / capacity) * 100)
    : 0;

  return from === 'Bookmarks' ? (
    <Box className={classes.root}>
      <Typography align="left">
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{sumPalletSpace}</strong>
        </label>
        /{capacity}
      </Typography>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Total pallets:</strong>
        </label>{' '}
        {sumPallets}
      </Typography>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Total pallet space:</strong>
        </label>{' '}
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{sumPalletSpace}</strong>
        </label>
        /{capacity}
      </Typography>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Util:</strong>
        </label>{' '}
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{utilPercent}%</strong>
        </label>
      </Typography>
    </Box>
  );
}
