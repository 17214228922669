import {
  Box,
  Collapse,
  Grid,
  ListItem,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import { ImportShipmentFormInput } from '../views/ImportShipment';
import { ShipmentsOrdersListRHF } from './ShipmentsOrdersListRHF';
import { SelectLocationCLRHF } from './form/SelectLocationCLRHF';
import OkIcon from '@mui/icons-material/Done';
import NotOkIcon from '@mui/icons-material/Clear';
import { EditShipmentModal } from './modal/EditShipmentModal';

interface ImportShipmentCollapsibleListProps {
  readOnly: boolean;
  index: number;
  isPastDate: boolean;
  isAllItemsOk: boolean;
  shipmentUpdated: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    selecLocationField: {
      width: '70%',
      fontSize: '11px',
      margin: 0,
      padding: 0,
    },
    iconButton: {
      padding: '0 !important',
    },
    iconDivOK: {
      width: 100,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#3c9317',
      marginRight: 8,
    },
    iconDivNotOK: {
      width: 100,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ff0000',
      marginRight: 8,
    },
  }),
);

export function ImportShipmentCollapsibleList(
  props: ImportShipmentCollapsibleListProps,
) {
  const {
    readOnly = false,
    index,
    isPastDate,
    isAllItemsOk,
    shipmentUpdated,
  } = props;
  const classes = useStyles();
  const { getValues: getFieldProps } =
    useFormContext<ImportShipmentFormInput>();

  const shipment = getFieldProps(`shipments.${index}`);
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box style={{ border: '1px solid green', padding: 1, marginBottom: 3 }}>
        <Grid item container xs={12} direction="row">
          <Grid item xs={6}>
            <ListItem key={shipment.id} style={{ padding: 0 }}>
              <div style={{ backgroundColor: '#e3e3e3' }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              <Typography
                style={{
                  fontSize: 14,
                  maxLines: 1,
                  cursor: 'default',
                  paddingLeft: 10,
                }}
              >{`To: `}</Typography>
              <SelectLocationCLRHF
                key={`shipments.${index}.toLocationId`}
                name={`shipments.${index}.toLocationId`}
                controllerName={`shipments.${index}.toLocationId`}
                className={classes.selecLocationField}
                readOnly
                size="small"
                smallFontSize
                noLabel
                smallPadding
                noBorder
                shortAddress
              />
            </ListItem>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              style={{ fontWeight: 'bold', fontSize: 15 }}
            >{`${shipment.name}`}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Box display="flex" justifyContent="flex-end">
              {isPastDate ? null : (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  style={{ backgroundColor: '#e3e3e3', marginRight: 8 }}
                >
                  <EditShipmentModal
                    key={`k${shipment.id}`}
                    id={shipment.id}
                    shipmentDate={shipment.shipmentDate ?? ''}
                    showDate={false}
                    templateUpdated={() => {
                      shipmentUpdated();
                    }}
                    updatedFrom="Terminal"
                  />
                  <Typography
                    style={{ fontSize: 14, marginLeft: 1, marginRight: 5 }}
                  >{`Modify`}</Typography>
                </Box>
              )}
              <div
                className={
                  isAllItemsOk ? classes.iconDivOK : classes.iconDivNotOK
                }
              >
                <IconButton className={classes.iconButton}>
                  {isAllItemsOk ? (
                    <OkIcon htmlColor={'#ffffff'} />
                  ) : (
                    <NotOkIcon htmlColor={'#ffffff'} />
                  )}
                </IconButton>
              </div>
              <div style={{ backgroundColor: '#e3e3e3' }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Collapse key={`s${shipment.id}`} in={open} timeout="auto" unmountOnExit>
        <ShipmentsOrdersListRHF
          readOnly={readOnly}
          shipmentIndex={index}
          isPastDate={isPastDate}
        />
      </Collapse>
    </>
  );
}
