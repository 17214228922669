import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../../views/CheckListRoutesTraffic';

interface CheckboxFieldProps {
  name: string;
  controllerName: Path<CheckListRoutesFormInput>;
  label: string;
  readOnly: boolean;
  handleEdit?: (
    e: React.SyntheticEvent,
    name: string,
    editedField: string,
    checked?: boolean,
  ) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.fontSize - 1,
    },
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: 1,
    },
  }),
);

export function CheckboxCLRHF(props: CheckboxFieldProps) {
  const classes = useStyles();
  const { control } = useFormContext<CheckListRoutesFormInput>();

  const { name, label, readOnly = false, controllerName } = props;

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          id={name}
          className={classes.formControl}
          disabled={readOnly}
          control={
            <Checkbox
              size={'small'}
              color="primary"
              className={classes.checkBox}
              checked={field.value === true}
              onChange={field.onChange}
            />
          }
          label={
            <Typography
              variant="body2"
              className={classes.label}
              color="textPrimary"
            >
              {label}
            </Typography>
          }
        />
      )}
    />
  );
}
