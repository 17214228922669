import { Checkbox, makeStyles, TextField, withStyles } from '@material-ui/core';

const useStyles = makeStyles({
  input: {
    width: '70%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #585858',
    margin: 8,
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  rowContainer: {
    display: 'flex',
    border: '1px solid #585858',
    margin: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputVal: {
    height: 10,
    fontSize: 14,
  },
});

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.7)',
    },
  },
})(TextField);

type BoxDataType = {
  boxId: number;
  boxNameText: string;
  boxValue: number | undefined;
  entryId: number | undefined;
  isReadOnly: boolean;
  isFixedBox: boolean;
  isYesNoBox: boolean;
  isAddedFromTerminal: boolean;
  isTimeBox: boolean;
};

interface ValueBoxProps {
  boxData: BoxDataType;
}

export function TerminalValueBox({ boxData }: ValueBoxProps) {
  const classes = useStyles();
  const getProperTimeString = (timeValStr: string): string => {
    if (timeValStr !== '') {
      return timeValStr.substring(0, 2) + ':' + timeValStr.substring(2);
    } else {
      return '';
    }
  };
  const getTimeStringFromNumber = (numberValue: number): string => {
    const numStr = numberValue.toString();

    if (numStr.length === 4) {
      return getProperTimeString(numStr);
    } else if (numStr.length === 3) {
      return getProperTimeString('0' + numStr);
    } else if (numStr.length === 2) {
      return '00:' + numStr;
    } else if (numStr.length === 1) {
      return '00:0' + numStr;
    } else {
      return '';
    }
  };

  const myValue = boxData.boxValue
    ? boxData.boxValue === 0
      ? ''
      : boxData.isFixedBox || boxData.isTimeBox
      ? getTimeStringFromNumber(boxData.boxValue)
      : boxData.boxValue.toString()
    : '';

  return (
    <div className={classes.rowContainer}>
      <div
        style={{
          padding: '2px 4px 2px 4px',
          backgroundColor: '#d1d1d1',
          fontSize: 13,
        }}
      >
        {`${boxData.boxNameText}`}
      </div>

      <div
        style={{
          width: 38,
          backgroundColor: '#ffffff',
          fontSize: 13,
          marginLeft: boxData.isYesNoBox ? 0 : 6,
        }}
      >
        {boxData.isYesNoBox ? (
          <Checkbox
            id={`yesNo-${boxData.boxId}`}
            name={`yesNo-${boxData.boxId}`}
            size="small"
            disabled={true}
            checked={myValue === '' || myValue === '-2' ? false : true}
            style={{
              paddingRight: 2,
              paddingLeft: 2,
              paddingTop: 1,
              paddingBottom: 1,
            }}
          />
        ) : (
          <DarkerDisabledTextField
            id={`val-${boxData.boxId}`}
            name={`val-${boxData.boxId}`}
            disabled={true}
            InputProps={{
              classes: { input: classes.inputVal },
              disableUnderline: true,
              maxRows: 1,
            }}
            value={myValue}
          />
        )}
      </div>
    </div>
  );
}
