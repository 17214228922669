import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Input,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import format from 'date-fns/format';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  CardNote,
  GetNotesForCardDocument,
  useAddCardNoteMutation,
  useDeleteCardNoteMutation,
  useGetNotesForCardQuery,
} from '../generated/graphql';
import { UpdateNote } from './UpdateNote';
import { DialogModal } from './DialogModal';

const useStyles = makeStyles({
  titleHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noteHover: {
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
});

const validationSchema = (t: TFunction) =>
  yup.object({
    title: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.title'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.title') })),
    text: yup.string().nullable(),
  });

interface Note {
  id: number;
  title: string;
  text: string;
}

interface CardNotesProps {
  cardId: number;
}

interface NotesOpen {
  [key: string]: boolean;
}

export function CardNotes(props: CardNotesProps) {
  const { t } = useTranslation();
  const { cardId } = props;
  const classes = useStyles();

  const [modalNote, setModalNote] = useState<Note>();
  const [modalOpen, setModalOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState<NotesOpen>({});
  const [createNoteView, setCreateNoteView] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<CardNote | null>(
    null,
  );

  const { data } = useGetNotesForCardQuery({
    variables: {
      cardId,
    },
  });

  const notes = data?.card.notes;

  const [addCardNote] = useAddCardNoteMutation({
    refetchQueries: [{ query: GetNotesForCardDocument, variables: { cardId } }],
  });

  const [deleteNote] = useDeleteCardNoteMutation({
    refetchQueries: [{ query: GetNotesForCardDocument, variables: { cardId } }],
  });

  const handleDeleteNote = (note: CardNote) => {
    deleteNote({ variables: { id: note.id } });
  };

  const handleClick = (id: number) => {
    setNotesOpen({
      ...notesOpen,
      [id]: !notesOpen[id],
    });
  };

  const handleNewNote = () => {
    setCreateNoteView(true);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={2}
          >
            <Typography variant="h3" align="left">
              {t('components.notes')}
            </Typography>
            <Button
              onClick={handleNewNote}
              disabled={createNoteView}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              {t('button.add', { item: t('attributes.note') })}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <List>
          {(notes || []).map((note) => (
            <React.Fragment key={note.id}>
              <ListItem divider className={classes.noteHover}>
                <Grid container direction="column">
                  <ListItem
                    onClick={() => handleClick(note.id)}
                    className={classes.titleHover}
                  >
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid xs={10}>
                        <ListItemText
                          primary={note.title}
                          secondary={`Created by: ${
                            note.createdBy
                          }. Last updated by: ${note.lastUpdatedBy} at ${format(
                            new Date(note.updatedAt),
                            'hh:mm - dd/MM/yy ',
                          )}`}
                          primaryTypographyProps={{ variant: 'subtitle1' }}
                        />
                      </Grid>
                      <Grid xs={1}>
                        <EditIcon
                          onClick={() => {
                            setModalNote(note);
                            setModalOpen(true);
                          }}
                        />
                        <DeleteIcon
                          color="error"
                          onClick={() => {
                            setOpenDeleteDialog(note);
                          }}
                        />
                        {notesOpen[note.id] ? <ExpandLess /> : <ExpandMore />}
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Grid item>
                    <Collapse
                      in={notesOpen[note.id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Divider light variant="fullWidth" />
                        </Grid>
                        <Grid item xs={12}>
                          <Box mt={2} mb={2} pl={2.3}>
                            <Typography
                              variant="body1"
                              style={{ whiteSpace: 'pre-wrap' }}
                            >
                              {note.text}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
        {modalOpen && modalNote && (
          <UpdateNote
            cardId={cardId}
            open={modalOpen}
            setOpen={setModalOpen}
            note={modalNote}
            setModalNote={setModalNote}
          />
        )}
      </Grid>
      <DialogModal
        open={openDeleteDialog != null}
        setOpen={() => setOpenDeleteDialog(null)}
        contentText={t('validation.confirmation', {
          item: openDeleteDialog?.title,
          action: t('actions.delete.lowercased'),
        })}
        doAction={() => {
          if (openDeleteDialog != null) handleDeleteNote(openDeleteDialog);
        }}
        buttonText={t('button.continue')}
      />
      <Collapse in={createNoteView} timeout="auto" unmountOnExit>
        <Typography variant="h3">Create new note</Typography>

        <Formik
          initialValues={{
            title: '',
            text: '',
          }}
          validationSchema={validationSchema(t)}
          onSubmit={async (values, { resetForm }) => {
            await addCardNote({
              variables: {
                cardId: cardId,
                input: values,
              },
            });
            resetForm();
            setCreateNoteView(false);
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <TextField
                fullWidth
                id="title"
                name="title"
                label={t('attributes.title')}
                value={props.values.title}
                onChange={props.handleChange}
                error={props.touched.title && Boolean(props.errors.title)}
                helperText={props.touched.title && props.errors.title}
              />
              <TextField
                fullWidth
                multiline
                rows={5}
                id="text"
                name="text"
                label={t('attributes.text')}
                value={props.values.text}
                onChange={props.handleChange}
                error={props.touched.text && Boolean(props.errors.text)}
                helperText={props.touched.text && props.errors.text}
              />
              <Input
                type="submit"
                value={t('button.create', {
                  item: t('attributes.note'),
                })}
                color="primary"
              />
              <Button
                onClick={() => {
                  props.resetForm();
                  setCreateNoteView(false);
                }}
                color="secondary"
              >
                {t('button.cancel')}
              </Button>
            </form>
          )}
        </Formik>
      </Collapse>
    </Grid>
  );
}
