import { Button, Typography } from '@material-ui/core';
import { Alert, Box, ClassNameMap, Grid, List } from '@mui/material';
import { FieldArray, FormikProps, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Department } from '../../../generated/graphql';
import { newCostAllocationItem } from '../../../lib/formHelpers/costAllocation';
import { calculateFromPercentage } from '../../../lib/percentage';
import { TourTemplateFormInput } from '../../TourTemplateForm';
import { HideableFormFields } from '../HideableFormFields';
import { TourRouteCostAllocationItemFields } from './TourRouteCostAllocationItemFields';

interface CostAllocationFieldsProps {
  readOnly: boolean;
  terminal: boolean;
  index: number;
  classes: ClassNameMap<'subResources' | 'routeCostAllocItems'>;
  props: FormikProps<
    Omit<TourTemplateFormInput, 'subcontractorId'> & {
      subcontractorId?: number | undefined;
    }
  >;
  departments: Pick<Department, 'id' | 'name'>[];
  onHideClicked: () => void;
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function TourRouteCostAllocationFields({
  readOnly,
  terminal,
  index,
  props,
  classes,
  departments,
  onHideClicked,
  show,
  setOpen,
}: CostAllocationFieldsProps) {
  const { t } = useTranslation();
  const { getFieldProps, getFieldMeta, setFieldValue } = useFormikContext();

  const routePrice = getFieldProps(`routes.${index}.price`).value;
  const itemsProps = getFieldMeta(`routes.${index}.costAllocations`);
  const items = getFieldProps(`routes.${index}.costAllocations`).value as {
    percentage: number;
    includedPrice: boolean;
    cost: number;
    bringCost: boolean;
    additionalCost: boolean;
    additionalDiscount: boolean;
  }[];

  let totalIncludedCost = 0;
  let totalBringCost = 0;
  let totalAdditionalCost = 0;
  let totalAdditionalDiscount = 0;
  let newAgreedPrice = 0;
  let finalEveryCost = 0;
  let finalAdditionalCost = 0;

  const updateCost = (newRoutePrice: number) => {
    items.forEach((item, legIndex) => {
      if (item.includedPrice) {
        setFieldValue(
          `routes.${index}.costAllocations.${legIndex}.cost`,
          calculateFromPercentage(newRoutePrice, item.percentage ?? 0),
        );
      }
    });
  };

  const updateCostDebounce = useCallback(debounce(updateCost, 500), [items]);

  useEffect(() => {
    updateCostDebounce(routePrice);
  }, [routePrice, items]);

  if (items) {
    totalIncludedCost = items
      .filter((i) => i.includedPrice === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    totalBringCost = items
      .filter((i) => i.includedPrice === false && i.bringCost === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    totalAdditionalCost = items
      .filter((i) => i.includedPrice === false && i.additionalCost === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    totalAdditionalDiscount = items
      .filter((i) => i.includedPrice === false && i.additionalDiscount === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    //newAgreedPrice is for subcontractor - excluding bring cost
    newAgreedPrice =
      totalIncludedCost + totalAdditionalCost - totalAdditionalDiscount;
    //totalEvery is all substracting discount
    finalEveryCost =
      totalIncludedCost +
      totalBringCost +
      totalAdditionalCost -
      totalAdditionalDiscount;
    //only additional cost substracting discount
    finalAdditionalCost = totalAdditionalCost - totalAdditionalDiscount;
  }

  return (
    <HideableFormFields
      show={show}
      title={t('resource.costAllocation.plural')}
      onHideClicked={onHideClicked}
      setOpen={setOpen}
      errors={[itemsProps.error]}
    >
      {!terminal && (
        <FieldArray name={`routes.${index}.costAllocations`}>
          {(arrayHelpers) => (
            <Box display="flex">
              <Grid container>
                {typeof props.getFieldMeta(`routes.${index}.costAllocations`)
                  .error === 'string' ? (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      {
                        props.getFieldMeta(`routes.${index}.costAllocations`)
                          .error
                      }
                    </Alert>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    {!readOnly && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const firstCostAlloc =
                            props.values.routes[index].costAllocations[0];

                          arrayHelpers.push(
                            newCostAllocationItem(
                              [{ id: firstCostAlloc?.departmentId }],
                              items.reduce((sum, x) => sum + x.percentage, 0),
                            ),
                          );
                        }}
                      >
                        {t('button.add', {
                          item: t('resource.costAllocation.lowercased'),
                        })}
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: 'bolder' }}>
                    {`Overall Cost: ${finalEveryCost} | Total BringCost: ${totalBringCost} | New AgreedPrice: ${newAgreedPrice} | Total AdditionalCost: ${finalAdditionalCost}`}{' '}
                  </Typography>
                  <List>
                    {props.values.routes[index].costAllocations.map(
                      (item, costAllIndex) => (
                        <TourRouteCostAllocationItemFields
                          index={index}
                          costAllIndex={costAllIndex}
                          removeAllocation={(i) => arrayHelpers.remove(i)}
                          classes={classes}
                          departments={departments}
                          props={props}
                          readOnly={readOnly}
                          key={`CA${costAllIndex}`}
                        />
                      ),
                    )}
                  </List>
                </Grid>
              </Grid>
            </Box>
          )}
        </FieldArray>
      )}
    </HideableFormFields>
  );
}
