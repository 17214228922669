import { Box, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../CenteredBox';
import { SelectCurrency } from '../SelectCurrency';
import { CommonTextField } from './CommonTextField';
import { SelectCustomer } from './SelectCustomer';

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: '95%',
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface TourRouteFTLFieldsProps {
  readOnly: boolean;
  index: number;
}

export function TourRouteFTLFields(props: TourRouteFTLFieldsProps) {
  const { readOnly, index } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <CenteredBox>
          <CommonTextField
            name={`routes.${index}.ftl.cdc`}
            className={classes.field}
            readOnly={readOnly}
          />
          <CommonTextField
            name={`routes.${index}.ftl.cmr`}
            className={classes.field}
            readOnly={readOnly}
          />
        </CenteredBox>

        <CenteredBox>
          <SelectCustomer
            name={`routes.${index}.ftl.customerId`}
            variant="outlined"
            label={t('attributes.customerId')}
            readOnly={readOnly}
            fullWidth
          />
        </CenteredBox>
      </Box>
      <Box className={classes.row}>
        <CenteredBox>
          <CommonTextField
            name={`routes.${index}.ftl.price`}
            type="number"
            className={classes.field}
            readOnly={readOnly}
          />
          <SelectCurrency
            fieldName={`routes.${index}.ftl.currency`}
            classes={classes}
          />
        </CenteredBox>
        <CenteredBox>
          <CommonTextField
            name={`routes.${index}.ftl.customerNumber`}
            className={classes.field}
            readOnly={readOnly}
          />
          <CommonTextField
            name={`routes.${index}.ftl.customerReference`}
            className={classes.field}
            readOnly={readOnly}
          />
          <CommonTextField
            name={`routes.${index}.ftl.invoiceNote`}
            className={classes.field}
            readOnly={readOnly}
          />
        </CenteredBox>
      </Box>
    </Box>
  );
}
