import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Box, Modal, Typography } from '@mui/material';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { useTranslation } from 'react-i18next';
import {
  DeviationFault,
  LocationDeviationInput,
  Reason,
} from '../../generated/graphql';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { SelectLocation } from '../form/SelectLocation';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { CheckboxField } from '../form/CheckboxField';
import { CommonTextField } from '../form/CommonTextField';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import { CommonDateField } from '../form/CommonDateField';
import { RemainingGoodsObj } from '../../views/RemainingGoodsInTerminal';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { validationSchemaForCustDeviation } from '../modal/RemainingGoodsModal';
import { SelectEnumField } from '../form/SelectEnumField';
import { CheckboxIconField } from '../form/CheckboxIconField';
import DangerIcon from '@mui/icons-material/Report';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '68vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface DeviationModalProps {
  onCancel: () => void;
  open: boolean;
  initialValue: RemainingGoodsObj;
  terminalName?: string;
  handleSubmit: (data: LocationDeviationInput[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    '& > *': {
      width: '150px',
    },
  },
  halfRow: {
    display: 'flex',
    width: '100%',
    marginBottom: 26,
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  root: {
    margin: 0,
    padding: 0,
    '& > .MuiGrid-item': {
      padding: 0,
    },
  },
  noPadding: {
    padding: '0 !important',
  },
  noPaddingListRow: {
    paddingLeft: '0 !important',
    width: '100%',
    paddingBottom: 4,
  },
  deleteButton: {
    padding: '0 !important',
  },
  numberFields: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
  selecLocationField: {
    width: '99%',
    fontSize: '10px',
    margin: 0,
    padding: 0,
  },
  field: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
}));

export function DeviationModal(props: DeviationModalProps) {
  const { onCancel, open, initialValue, handleSubmit } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const afterValidation = (values: RemainingGoodsObj) => {
    const validatedInput = values.obj as LocationDeviationInput[];
    handleSubmit(validatedInput);
    onCancel();
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Formik
          initialValues={initialValue}
          onSubmit={afterValidation}
          validate={(value) => {
            try {
              validateYupSchema(
                value,
                validationSchemaForCustDeviation(t),
                true,
                value,
              );
            } catch (err) {
              const errors = yupToFormErrors<{ error: string }>(err);
              return errors;
            }
            return {};
          }}
        >
          {(props) => (
            <Form>
              <Box m={1} p={2}>
                <FieldArray
                  name="obj"
                  render={(arrayProps) => (
                    <>
                      <Grid
                        container
                        style={{
                          marginLeft: 18,
                        }}
                      >
                        <Grid item xs={6}>
                          <SelectLocation
                            name={`obj.0.faultLocationId`}
                            fullWidth
                            size="small"
                            label={'toTerminal'}
                          />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={() => {
                              arrayProps.push({
                                id: undefined,
                                faultLocationId:
                                  props.values.obj[0].faultLocationId,
                                deviationApplicableDate:
                                  props.values.obj[0].deviationApplicableDate,
                                toLocationId: 0,
                                consequence: false,
                                fault: undefined,
                                note: '',
                                customerDeviationListItemsInput: [
                                  {
                                    locationId: 0,
                                    packages: null,
                                    pallets: null,
                                    palletSpace: null,
                                    weight: null,
                                    locationItemNote: '',
                                    reason: undefined,
                                    otherReason: '',
                                    newCustomer: '',
                                    isNewCustomer: false,
                                    isDangerous: false,
                                  },
                                ],
                              });
                            }}
                          >
                            {t('button.add', {
                              item: t('location'),
                            })}
                          </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <List>
                          {props.values.obj.map((_newLocation, locIndex) => (
                            <ListItem>
                              <Box
                                p={1}
                                style={{
                                  marginTop: 0,
                                  border: '0.5px solid #AAA',
                                }}
                              >
                                <Grid container spacing={1}>
                                  <Grid item xs={4}>
                                    {/* To Terminal */}
                                    <SelectLocation
                                      name={`obj.${locIndex}.toLocationId`}
                                      fullWidth
                                      size="small"
                                      label={'toTerminal'}
                                    />
                                  </Grid>

                                  <Grid item xs={3}>
                                    <CommonDateField
                                      name={`obj.${locIndex}.deviationApplicableDate`}
                                      label={t('attributes.date')}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <SelectEnumField
                                      name={`obj.${locIndex}.fault`}
                                      enumObject={DeviationFault}
                                      label={t('attributes.fault')}
                                      translationPath="enums.deviationFault"
                                      fullWidth={true}
                                      isRequired={true}
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <CheckboxField
                                      name={`obj.${locIndex}.consequence`}
                                      label={t('attributes.consequence')}
                                      size="small"
                                      labelPlacement={true}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    <>
                                      {locIndex === 0 ? null : (
                                        <IconButton
                                          className={classes.deleteButton}
                                          onClick={() => {
                                            arrayProps.remove(locIndex);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </>
                                  </Grid>
                                </Grid>
                                <FieldArray
                                  name={`obj.${locIndex}.customerDeviationListItemsInput`}
                                  render={(arrayHelpers) => (
                                    <Grid
                                      item
                                      xs={12}
                                      spacing={2}
                                      container
                                      className={classes.root}
                                    >
                                      {/* Lables */}
                                      <Grid
                                        xs={12}
                                        item
                                        container
                                        className={classes.noPadding}
                                        style={{
                                          backgroundColor: '#e3e3e3',
                                          marginTop: 5,
                                        }}
                                      >
                                        <Grid item xs={5}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                          >
                                            {'Customer'}
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={1}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                          >
                                            {'Packages'}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                          >
                                            {'Pallets'}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                          >
                                            {'Pallet space'}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                          >
                                            {'Weight'}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                          <Typography
                                            variant="body2"
                                            color="textPrimary"
                                          >
                                            {'Note'}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                              arrayHelpers.push({
                                                locationId: 0,
                                                packages: null,
                                                pallets: null,
                                                palletSpace: null,
                                                weight: null,
                                                locationItemNote: '',
                                                reason: undefined,
                                                otherReason: '',
                                                newCustomer: '',
                                                isDangerous: false,
                                              });
                                            }}
                                          >
                                            {t('button.add', {
                                              item: t('customer'),
                                            })}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                      {/* list items */}
                                      <Grid item xs={12}>
                                        <List>
                                          {_newLocation.customerDeviationListItemsInput?.map(
                                            (_customer, index) => {
                                              return (
                                                <ListItem
                                                  divider
                                                  key={index}
                                                  className={
                                                    classes.noPaddingListRow
                                                  }
                                                >
                                                  <Grid
                                                    xs={12}
                                                    item
                                                    container
                                                    key={index}
                                                    style={{
                                                      width: '100%',
                                                    }}
                                                  >
                                                    <Box
                                                      display="flex"
                                                      justifyContent="center"
                                                      alignItems="flex-start"
                                                      style={{
                                                        width: '100%',
                                                        marginBottom: 5,
                                                      }}
                                                    >
                                                      <Grid item xs={4}>
                                                        {props.values.obj[
                                                          locIndex
                                                        ]
                                                          .customerDeviationListItemsInput[
                                                          index
                                                        ].isNewCustomer ? (
                                                          <CommonTextField
                                                            name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.newCustomer`}
                                                            size="small"
                                                          />
                                                        ) : (
                                                          <SelectLocation
                                                            key={`obj.${locIndex}.customerDeviationListItemsInput_${index}`}
                                                            name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.locationId`}
                                                            className={
                                                              classes.selecLocationField
                                                            }
                                                            size="small"
                                                            smallFontSize
                                                            noLabel
                                                            smallPadding
                                                            shortAddress={true}
                                                          />
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <CheckboxField
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.isNewCustomer`}
                                                          label={t(
                                                            'attributes.newCustomer',
                                                          )}
                                                          customerName={`obj.${locIndex}.customerDeviationListItemsInput.${index}`}
                                                          size="small"
                                                        />
                                                      </Grid>

                                                      <Grid item xs={1}>
                                                        <FastTextFieldPackage
                                                          noLabel
                                                          size="small"
                                                          key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.packages`}
                                                          smallFontSize
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.packages`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                          smallPadding
                                                        />
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <FastTextFieldPallets
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.pallets`}
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.pallets`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                          smallPadding
                                                        />
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <FastTextFieldPalletSpace
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          smallPadding
                                                          key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.palletSpace`}
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.palletSpace`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={1}>
                                                        <FastTextFieldWeight
                                                          noLabel
                                                          size="small"
                                                          smallFontSize
                                                          smallPadding
                                                          key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.weight`}
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.weight`}
                                                          className={
                                                            classes.numberFields
                                                          }
                                                          type="number"
                                                        />
                                                      </Grid>
                                                      <Grid item xs={2}>
                                                        <FastTextFieldNote
                                                          noLabel
                                                          size="small"
                                                          multiline
                                                          smallFontSize
                                                          key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.locationItemNote`}
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.locationItemNote`}
                                                          className={
                                                            classes.field
                                                          }
                                                          smallPadding
                                                        />
                                                      </Grid>
                                                      <Grid
                                                        item
                                                        xs={1}
                                                        container
                                                        direction="row"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                      >
                                                        <>
                                                          {index ===
                                                          0 ? null : (
                                                            <IconButton
                                                              className={
                                                                classes.deleteButton
                                                              }
                                                              onClick={() => {
                                                                arrayHelpers.remove(
                                                                  index,
                                                                );
                                                              }}
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          )}
                                                        </>
                                                      </Grid>
                                                    </Box>
                                                    <Grid container>
                                                      {/* for reason */}

                                                      <Grid
                                                        item
                                                        xs={1}
                                                        direction="row"
                                                      >
                                                        <Typography
                                                          style={{
                                                            fontSize: 12,
                                                          }}
                                                          color="textPrimary"
                                                        >{`Reason -  `}</Typography>
                                                      </Grid>
                                                      <Grid item xs={5}>
                                                        <SelectEnumFieldSmall
                                                          name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.reason`}
                                                          key={`obj.${locIndex}.customerDeviationListItemsInput.${index}.reason`}
                                                          enumObject={Reason}
                                                          translationPath={
                                                            'enums.remainingGoodsReasonClass'
                                                          }
                                                          reasonPath={`obj.${locIndex}.customerDeviationListItemsInput.${index}`}
                                                        />
                                                      </Grid>

                                                      {props.values.obj[
                                                        locIndex
                                                      ]
                                                        .customerDeviationListItemsInput[
                                                        index
                                                      ].reason &&
                                                      props.values.obj[locIndex]
                                                        .customerDeviationListItemsInput[
                                                        index
                                                      ].reason ===
                                                        Reason.Other ? (
                                                        <Grid item xs={5}>
                                                          <CommonTextField
                                                            name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.otherReason`}
                                                            smallPadding
                                                            fullWidth
                                                          />
                                                        </Grid>
                                                      ) : null}
                                                      <Grid item xs={1}>
                                                        <Tooltip
                                                          title={`${t(
                                                            'attributes.tooltipDangerousGoodsIcon',
                                                          )}`}
                                                        >
                                                          <div>
                                                            <CheckboxIconField
                                                              name={`obj.${locIndex}.customerDeviationListItemsInput.${index}.isDangerous`}
                                                              icon={
                                                                <DangerIcon
                                                                  style={{
                                                                    color:
                                                                      'grey',
                                                                  }}
                                                                />
                                                              }
                                                              checkedIcon={
                                                                <DangerIcon
                                                                  style={{
                                                                    color:
                                                                      'red',
                                                                  }}
                                                                />
                                                              }
                                                              size="small"
                                                            />
                                                          </div>
                                                        </Tooltip>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </ListItem>
                                              );
                                            },
                                          )}
                                        </List>
                                      </Grid>
                                    </Grid>
                                  )}
                                />
                              </Box>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </>
                  )}
                />
              </Box>

              <Box className={clsx(classes.row, classes.center)}>
                <Button type="submit" variant="contained" color="primary">
                  {t('actions.create.capitalized')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {t('actions.cancel')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
