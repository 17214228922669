import { debounce, TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetCustomerLazyQuery,
  useSearchCustomersLazyQuery,
} from '../../generated/graphql';

interface SelectLocationProps {
  onCellChange: (location?: { id: number; name: string } | null) => void;
  initialValue: number | undefined;
}

interface SelectCustomerInterface {
  id: number;
  name: string;
}

export function SelectCustomerCell(
  props: SelectLocationProps & TextFieldProps,
) {
  const { onCellChange, initialValue } = props;
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<SelectCustomerInterface[]>([]);

  const [value, setValue] = useState<SelectCustomerInterface | null>(null);
  const [getCustomer, { data: customerData, loading }] =
    useGetCustomerLazyQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValue != null) {
      getCustomer({ variables: { id: initialValue } });
    }
  }, [initialValue]);

  const [getLocationsforLeg, { data: optionsData, loading: loadingOptions }] =
    useSearchCustomersLazyQuery();

  useEffect(() => {
    if (search.length > 2) {
      getLocationsforLeg({ variables: { customersSearch: search } });
    }
  }, [search]);

  useEffect(() => {
    if (customerData) {
      setOptions([customerData.customer]);
      setValue(customerData.customer);
    }
  }, [customerData]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.customers]);
      } else {
        setOptions(optionsData.customers);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  //TODO: Fix this before friday
  const label = 'customer';

  return (
    <Autocomplete
      value={value}
      multiple={false}
      sx={{
        display: 'inline-block',
        '& input': {
          width: '100%',
          height: 30,
          bgcolor: 'background.paper',
          color: (theme) =>
            theme.palette.getContrastText(theme.palette.background.paper),
        },
      }}
      fullWidth={true}
      autoHighlight
      onChange={(event, val) => {
        onCellChange(val);
      }}
      onInputChange={debouncedChangeHandler}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading || loadingOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          variant="outlined"
          label={t(`attributes.${label}`)}
          InputProps={{ ...params.InputProps, style: { height: 28 } }}
        />
      )}
    />
  );
}
