import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { FileItem } from '../FileListComponent';
import { HideableFormFields } from './HideableFormFields';
import { SelectFiles } from './SelectFiles';

interface FilesFieldProps {
  files: FileItem[];
  readOnly: boolean;
  onUpdate: (files: FileItem[]) => void;
  onHideClicked: () => void;
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function FilesField({
  readOnly,
  files,
  onHideClicked,
  onUpdate,
  show,
  setOpen,
}: FilesFieldProps) {
  const { t } = useTranslation();

  return (
    <HideableFormFields
      title={t('resource.documentFile.plural')}
      show={show}
      onHideClicked={onHideClicked}
      setOpen={setOpen}
    >
      <SelectFiles
        files={files ?? []}
        readOnly={readOnly}
        handleDelete={(file: FileItem) => {
          const remainingFiles = files.filter((x) => x.id !== file.id);
          onUpdate(remainingFiles);
        }}
        addFile={(file) => {
          onUpdate([...files, file]);
        }}
      />
    </HideableFormFields>
  );
}
