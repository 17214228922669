import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Box } from '@mui/system';
import { CenteredBox } from '../../CenteredBox';
import { SelectCurrencyRHF } from '../../SelectCurrencyRHF';
import { CommonDateFieldRHF } from '../CommonDateFieldRHF';
import { CommonTextFieldRHF } from '../CommonTextFieldRHF';

interface RouteAggreementFieldsProps {
  trafficReadOnly: boolean;
  terminal?: boolean;
  classes: ClassNameMap<'button' | 'field' | 'categoryHeader'>;
  template?: boolean;
}

export function RouteAgreementFieldsRHF({
  trafficReadOnly,
  classes,
  terminal = false,
  template = false,
}: RouteAggreementFieldsProps) {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <CenteredBox>
        <CommonTextFieldRHF
          name="routeId"
          controllerName={'routeId'}
          className={classes.field}
          readOnly={trafficReadOnly}
        />
      </CenteredBox>

      <CenteredBox>
        {!template && (
          <CommonDateFieldRHF
            name="transportationDate"
            controllerName={'transportationDate'}
            readOnly={trafficReadOnly}
          />
        )}
      </CenteredBox>

      <CenteredBox visible={!terminal}>
        {!terminal && (
          <CommonTextFieldRHF
            name="agreedPrice"
            controllerName={'agreedPrice'}
            className={classes.field}
            type="number"
            readOnly={trafficReadOnly}
          />
        )}

        {!terminal && (
          <SelectCurrencyRHF
            name="currency"
            controllerName={'currency'}
            readOnly={trafficReadOnly}
          />
        )}
      </CenteredBox>
      <CenteredBox>
        <CommonTextFieldRHF
          name="capacity"
          controllerName={'capacity'}
          className={classes.field}
          type="number"
          readOnly={trafficReadOnly}
        />
        <CommonTextFieldRHF
          name="kilometer"
          controllerName={'kilometer'}
          className={classes.field}
          type="number"
          readOnly={trafficReadOnly}
        />
        <CommonTextFieldRHF
          name="weight"
          controllerName={'weight'}
          labelText={'weightKg'}
          className={classes.field}
          type="number"
          readOnly={trafficReadOnly}
        />
      </CenteredBox>
    </Box>
  );
}
