import { List } from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ImportShipmentCollapsibleList } from './ImportShipmentCollapsibleList';
import { ImportShipmentFormInput } from '../views/ImportShipment';

interface ImportShipmentsListProps {
  readOnly: boolean;
  shipmentUpdated: () => void;
}

export function ImportShipmentsListRHF(props: ImportShipmentsListProps) {
  const { readOnly = false, shipmentUpdated } = props;

  const { control, getValues } = useFormContext<ImportShipmentFormInput>();

  const { fields } = useFieldArray({
    control,
    keyName: '_key',
    name: 'shipments',
  });

  const shipmentDateStr = getValues(`shipments.0.shipmentDate`) ?? '2020-01-01';

  const todayDate = new Date().setHours(0, 0, 0, 0);
  const shipmentDateDate = new Date(shipmentDateStr).setHours(0, 0, 0, 0);
  const isPastShipmentDate = todayDate > shipmentDateDate;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <List>
        {fields.map((shipment, index) => {
          const errorIndex = shipment.loadingListItems.findIndex(
            (sl) => sl.customCleared === false || sl.terminalCleared === false,
          );
          return (
            <ImportShipmentCollapsibleList
              key={`cc${index}`}
              readOnly={readOnly}
              index={index}
              isPastDate={isPastShipmentDate}
              isAllItemsOk={errorIndex === -1}
              shipmentUpdated={() => {
                shipmentUpdated();
              }}
            />
          );
        })}
      </List>
    </div>
  );
}
