import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    primary: {
      dark: '#00643A',
      main: '#69bc46',
      light: '#C8DC8C',
    },
    secondary: {
      main: '#FDBB2F',
      light: '#ffcd5e',
    },
    error: {
      main: '#E32D22',
      light: '#FECFCD',
    },
    warning: {
      main: '#ffcc00',
    },
  },
  typography: {
    button: {
      fontSize: 12,
    },
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: 30,
    },
    h2: {
      fontSize: 24,
    },
    h3: {
      fontSize: 16,
    },
    h4: {
      fontSize: 12,
    },
  },
});
