import {
  Box,
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Alert } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteType, useDispatchRouteMutation } from '../generated/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',

    '& > *': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  action: {
    display: 'flex',
    justifyContent: 'center',

    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
    '& input': {
      fontSize: 12,
      padding: 4,
    },
  },
}));

interface DispatchRouteProps {
  routeId?: number;
}

export function DispatchRoute(props: DispatchRouteProps) {
  const { routeId } = props;
  const { t } = useTranslation();
  const [date, setDate] = useState<string | null>(null);
  const classes = useStyles();
  const [dispatchRoute] = useDispatchRouteMutation();
  const [text, setText] = useState<{
    type: 'success' | 'warning';
    text: string;
    link: string;
  } | null>(null);

  const onSubmit = useCallback(async () => {
    if (date != null && routeId != null) {
      const response = await dispatchRoute({
        variables: {
          input: {
            dateString: date,
            routeId,
          },
        },
      });

      if (response.data != null) {
        const statusResponse = response.data?.generateFixedTour.status;
        switch (statusResponse) {
          case 'Success':
            setText({
              type: 'success',
              text: t(
                `info.manualDispath.${
                  response.data.generateFixedTour.routeType === RouteType.Fixed
                    ? 'createdFixed'
                    : 'createdSporadic'
                }`,
                { date: response.data.generateFixedTour.date },
              ),
              link:
                response.data.generateFixedTour.routeType === RouteType.Fixed
                  ? `/traffic/dispatched-routes/${response.data.generateFixedTour.createdRouteId}`
                  : `/traffic/sporadic-routes/${response.data.generateFixedTour.createdRouteId}`,
            });
            break;
          case 'RouteAlreadyDispatched':
            setText({
              type: 'warning',
              text: t('info.manualDispath.alreadyCreated'),
              link:
                response.data.generateFixedTour.routeType === RouteType.Fixed
                  ? `/traffic/dispatched-routes/${response.data.generateFixedTour.createdRouteId}`
                  : `/traffic/sporadic-routes/${response.data.generateFixedTour.createdRouteId}`,
            });
            break;
        }
      }
    }
  }, [date]);

  const onChangeDate = useCallback((e) => {
    setDate(e.target.value);
    setText(null);
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.action}>
        <TextField
          id="dispatchRoute"
          size="small"
          type="date"
          variant="outlined"
          label={t(`attributes.date`)}
          onChange={onChangeDate}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          onClick={onSubmit}
          variant="contained"
          color="primary"
          disabled={date == null}
        >
          {t('actions.create.capitalized')}
        </Button>
      </Box>
      {text && (
        <Box>
          <Alert
            severity={text.type}
            action={
              <Button
                to={text.link}
                component={Link}
                variant="contained"
                color="secondary"
              >
                {t('actions.open')}
              </Button>
            }
          >
            {text.text}
          </Alert>
        </Box>
      )}
      <Box>
        <Typography variant="body2" style={{ fontSize: 12 }}>
          <strong>{t('info.note')}:</strong> {t('info.manualDispatchNote')}
        </Typography>
      </Box>
    </Box>
  );
}
