import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DescriptionIcon from '@mui/icons-material/Description';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonTextField } from '../components/form/CommonTextField';
import { FuelType, Maybe } from '../generated/graphql';
import { createFTL } from '../lib/formHelpers/ftl';
import { normalizeRouteIdForRoute } from '../lib/normalizeRouteId';
import { routeTemplateValidationSchema } from '../lib/validationSchema/routeTemplate';
import { AutoReset } from '../utils/AutoReset';
import { CenteredBox } from './CenteredBox';
import { FileItem } from './FileListComponent';
import { AccordionContainer } from './form/AccordionContainer';
import { FilesField } from './form/FilesField';
import { HideableFormFields } from './form/HideableFormFields';
import { RouteAgreementFields } from './form/routeFields/RouteAgreementFields';
import { RouteFTLFields } from './form/RouteFTLFields';
import { SubcontractorFields } from './form/SubcontractorFields';
import { SupressEnterForm } from './form/SupressEnterForm';
import { LegsFields } from './LegsFields';
import { LoadingListItemInterface } from './LoadingList';

export type SporadicRouteTemplateFormInput = {
  name: string;
  agreedPrice?: Maybe<number>;
  currency: string;
  note: string;
  externalNote: string;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  capacity?: Maybe<number>;
  kilometer?: Maybe<number>;
  weight?: Maybe<number>;
  routeId: string;
  dispatcherId: number;
  subcontractorId: number;
  files: {
    id: number;
    originalname: string;
    path: string;
    createdBy: string;
    createdAt: Date;
  }[];
  ftl:
    | {
        cdc: string;
        cmr: string;
        price: number;
        currency: string;
        customerId?: Maybe<number>;
        customerNumber: string;
        customerReference: string;
        invoiceNote: string;
      }
    | undefined;
  legs: {
    load: boolean;
    unload: boolean;
    fuel: FuelType;
    gateNumber?: string;
    carRegistrationNumber?: string;
    trailerRegistrationNumber?: string;
    routeDriverName?: string;
    routeDriverPhoneNumber?: string;
    note?: string;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    position: number;
    locationId?: number;
    transportationDateOffset: number;
    loadingListItems: LoadingListItemInterface[];
  }[];
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(1, 'auto'),
  },
  subResources: {
    margin: theme.spacing(1, 'auto'),
    width: '95%',
  },
  button: {
    margin: theme.spacing(1, 'auto'),
  },
  formContent: {
    margin: theme.spacing(1, 0, 2, 1),
    padding: theme.spacing(0, 2, 2, 2),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      padding: theme.spacing(1, 0),
    },

    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface SporadicRouteTemplateFormProps {
  initialValues: Omit<
    SporadicRouteTemplateFormInput,
    'dispatcherId' | 'subcontractorId'
  > & {
    dispatcherId?: number;
    subcontractorId?: number;
  };
  onSubmit: (
    values: Omit<
      SporadicRouteTemplateFormInput,
      'dispatcherId' | 'subcontractorId'
    > & {
      dispatcherId?: number;
      subcontractorId?: number;
    },
  ) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
  includeInvoice?: boolean;
  handleDeleteFile?: (file: FileItem) => void;
  fromCreateRoute?: string;
}
export function SporadicRouteTemplateForm(
  props: SporadicRouteTemplateFormProps,
) {
  const {
    initialValues,
    onSubmit,
    submitButtonLabel,
    readOnly = false,
    includeInvoice = false,
    fromCreateRoute,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  const [aggreementOpen, setAggreementOpen] = useState<boolean>(true);
  const [noteOpen, setNoteOpen] = useState<boolean>(true);
  const [subcontractorOpen, setSubcontractorOpen] = useState<boolean>(true);
  const [driverOpen, setDriverOpen] = useState<boolean>(true);
  const [filesOpen, setFilesOpen] = useState<boolean>(false);
  const [ftlOpen, setFtlOpen] = useState<boolean>(false);

  return (
    <Paper className={classes.formContent}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnBlur
        validateOnChange={false}
        validate={(value) => {
          try {
            validateYupSchema(
              value,
              routeTemplateValidationSchema(t),
              true,
              value,
            );
          } catch (err) {
            const errors = yupToFormErrors<{ costAllocation: string }>(err); //for rendering validation errors
            return errors;
          }

          return {};
        }}
        onSubmit={normalizeRouteIdForRoute(onSubmit)}
      >
        {(props) => (
          <SupressEnterForm
            onSubmit={props.handleSubmit}
            className={classes.root}
          >
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-start" width="50%">
                  <CommonTextField
                    name="name"
                    className={classes.field}
                    readOnly={readOnly}
                  />
                </Box>
              </Grid>
              <AutoReset readOnly={readOnly} />
              <Grid item xs={6}>
                <AccordionContainer
                  open={aggreementOpen}
                  setOpen={setAggreementOpen}
                  accordionTitle={t('attributeGrouping.agreement')}
                >
                  <RouteAgreementFields
                    template
                    trafficReadOnly={readOnly}
                    classes={classes}
                  />
                </AccordionContainer>

                <AccordionContainer
                  open={noteOpen}
                  setOpen={setNoteOpen}
                  accordionTitle={t('attributeGrouping.note')}
                >
                  <CenteredBox>
                    <CommonTextField
                      className={classes.field}
                      name="note"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={readOnly}
                    />
                  </CenteredBox>
                  <CenteredBox>
                    <CommonTextField
                      className={classes.field}
                      name="externalNote"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={readOnly}
                    />
                  </CenteredBox>
                </AccordionContainer>
              </Grid>
              <Grid item xs={6}>
                <AccordionContainer
                  open={subcontractorOpen}
                  setOpen={setSubcontractorOpen}
                  errors={[props.errors.subcontractorId]}
                  accordionTitle={t('resource.subcontractor.capitalized')}
                >
                  <SubcontractorFields readOnly={readOnly} />
                </AccordionContainer>
                {includeInvoice && (
                  <AccordionContainer
                    open={driverOpen}
                    setOpen={setDriverOpen}
                    accordionTitle={`${t('attributeGrouping.invoice')}`}
                  >
                    <>
                      <Typography
                        variant="subtitle1"
                        className={classes.categoryHeader}
                        align="left"
                      >
                        {t('attributeGrouping.invoice')}
                      </Typography>
                      <CommonTextField
                        name="invoiceNumber"
                        className={classes.field}
                        readOnly={readOnly}
                      />
                      <CommonTextField
                        name="invoiceNote"
                        className={classes.field}
                        readOnly={readOnly}
                      />
                    </>
                  </AccordionContainer>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.buttonWrapper}>
                  {!(readOnly && props.values.files.length === 0) && (
                    <Tooltip
                      title={`${t('button.view', {
                        item: t('resource.documentFile.plural'),
                      })}`}
                    >
                      <IconButton
                        color={filesOpen ? 'primary' : undefined}
                        onClick={() => {
                          setFilesOpen(!filesOpen);
                        }}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!(readOnly && props.values.ftl == null) && (
                    <Tooltip
                      title={`${t('button.view', {
                        item: t('resource.routeFtl.plural'),
                      })}`}
                    >
                      <IconButton
                        color={ftlOpen ? 'primary' : undefined}
                        onClick={() => {
                          if (!Boolean(props.values.ftl)) {
                            props.setFieldValue('ftl', createFTL());
                          }
                          setFtlOpen(!ftlOpen);
                        }}
                      >
                        <StorefrontIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <HideableFormFields
                  show={ftlOpen}
                  title={t('resource.routeFtl.capitalized')}
                  onHideClicked={() => setFtlOpen(false)}
                  setOpen={setFtlOpen}
                >
                  <RouteFTLFields readOnly={readOnly} />
                </HideableFormFields>
              </Grid>
              <Grid item xs={12}>
                <FilesField
                  files={
                    props.values.files
                      ? props.values.files.filter((f) =>
                          f.originalname.includes('.pdf'),
                        )
                      : []
                  }
                  show={filesOpen}
                  readOnly={readOnly}
                  onHideClicked={() => setFilesOpen(false)}
                  setOpen={setFilesOpen}
                  onUpdate={(files) => {
                    props.setFieldValue('files', files);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box mt="5px">
                  <LegsFields
                    trafficReadOnly={readOnly}
                    readOnly={readOnly}
                    fieldsPath="legs"
                    disableOffsetCalculation
                    fromCreateRoute={fromCreateRoute}
                    isFromTerminal={false}
                  />
                </Box>
              </Grid>
            </Grid>
            {!readOnly && (
              <Grid container justifyContent="flex-end">
                <Button
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            )}
          </SupressEnterForm>
        )}
      </Formik>
    </Paper>
  );
}
