import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Box, FormLabel } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SporadicRouteFormInput } from '../../RouteFormRHF';
import { CheckboxFieldRHF } from '../CheckboxFieldRHF';

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  componentWrapper: {
    display: 'flex',
    marginTop: 2,
    marginLeft: 8,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
  daysWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
    '& > *': {
      marginRight: theme.spacing(2),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  formLabel: {
    fontSize: 14,
  },
}));

export function DaysFieldForPlannedRoutesRHF(props: { fieldErr: boolean }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { control, getFieldState, setError, clearErrors } =
    useFormContext<SporadicRouteFormInput>();

  const [fieldState] = useWatch({
    control,
    name: ['selectedDays'],
  });

  useEffect(() => {
    const fState = getFieldState('selectedDays');

    if (
      fieldState &&
      Object.values(fieldState).every((x) => !x) &&
      fState.isTouched
    ) {
      setError('selectedDays', { message: 'err' });
    } else {
      clearErrors('selectedDays');
    }
  }, [fieldState]);

  return (
    <Box className={classes.fullWidth}>
      <Box className={classes.componentWrapper}>
        <Typography variant="h3" align="left" style={{ marginBottom: 4 }}>
          {t('attributes.days')}
        </Typography>

        <Box className={classes.daysWrapper}>
          <CheckboxFieldRHF
            controllerName="selectedDays.mon"
            name={`selectedDays.mon`}
            label={t('weekDays.mon')}
            size="small"
          />
          <CheckboxFieldRHF
            controllerName="selectedDays.tue"
            name={`selectedDays.tue`}
            label={t('weekDays.tue')}
            size="small"
          />
          <CheckboxFieldRHF
            controllerName="selectedDays.wed"
            name={`selectedDays.wed`}
            label={t('weekDays.wed')}
            size="small"
          />
          <CheckboxFieldRHF
            controllerName="selectedDays.thu"
            name={`selectedDays.thu`}
            label={t('weekDays.thu')}
            size="small"
          />
          <CheckboxFieldRHF
            controllerName="selectedDays.fri"
            name={`selectedDays.fri`}
            label={t('weekDays.fri')}
            size="small"
          />
          <CheckboxFieldRHF
            controllerName="selectedDays.sat"
            name={`selectedDays.sat`}
            label={t('weekDays.sat')}
            size="small"
          />
          <CheckboxFieldRHF
            controllerName="selectedDays.sun"
            name={`selectedDays.sun`}
            label={t('weekDays.sun')}
            size="small"
          />
        </Box>
        <FormLabel style={{ fontSize: 14 }} error={true}>
          {props.fieldErr && `${t('validation.atLeastOneDay')}`}
        </FormLabel>
      </Box>
    </Box>
  );
}
