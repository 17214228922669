import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
  Snackbar,
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@material-ui/icons/Add';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { HelmetComponent } from '../components/HelmetComponent';
import { ViewSporadicRouteTemplateModal } from '../components/modal/ViewSporadicRouteTemplateModal';
import { SelectDateRangeForm } from '../components/SelectDateRangeForm';
import {
  FuelType,
  RouteType,
  useDeleteMultiplePlannedRouteMutation,
  useSporadicRouteTemplatesQuery,
  useUpdateDriverMutation,
  useUpdateExtraColumnsMutation,
  useUpdateInvoiceMutation,
  useUpdateRouteLegDataColumnsMutation,
} from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { routeColumns } from '../utils/RouteColumns';
import { Alert } from '@material-ui/lab';
import { getCurrentDate } from '../lib/date';
import { DialogConfirmationModal } from '../components/DialoConfirmationgModal';
import { useHttpClient } from '../providers/HttpClientProvider';

const useStyles = makeStyles(() =>
  createStyles({
    editableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& p:first-child': {
        maxWidth: '75%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        inlineSize: '75%',
      },
    },
  }),
);

export interface ListRoutes {
  id: number;
  routeId: string;
  arrivingPallets?: number;
  arrivingPalletSpace?: number;
  agreementNumber: number;
  transportationDate?: string;
  subcontractorName: string;
  dispatcherName: string;
  departureCity?: string;
  departureCountry: string;
  departureArrivalTime?: string;
  departureDepartureTime?: string;
  arrivalCity: string;
  arrivalCountry: string;
  arrivalArrivalTime: string;
  arrivalDepartureTime?: string;
  agreedPrice?: number;
  note?: string;
  externalNote?: string;
  capacity?: number;
  currency: string;
  fuel?: FuelType;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  ftlCurrency: string;
  ftlPrice: number;
  ftlCustomerNumber: string;
  ftlCmr: string;
  ftlCdc: string;
  ftlInvoiceNote: string;
  tourName: string;
  invoiceNumber: string;
  invoiceNote: string;
  isCreatedFromTerminal: boolean;
  isCreatedFromPlanned: boolean;
  multipleSporadicRouteId?: number;
  totalIncludedCost: number;
  totalBringCost: number;
  firstLegId: number;
}

export function SporadicRoutes() {
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const apiRef = useGridApiRef();
  const classes = useStyles();
  const [gridHeight, setGridHeight] = useState(0);
  const [startDate, setStartDate] = useState(format(new Date(), DATE_FORMAT));
  const [endDate, setEndDate] = useState(format(new Date(), DATE_FORMAT));
  const [refreshGrid, setRefreshGrid] = useState<boolean>(false);
  const [alertSuccess, setAlertSuccess] = useState<boolean>(false);
  const [alertLoading, setAlertLoading] = useState<boolean>(false);
  const [isPlannedRoutesView, setPlannedRoutesView] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const [createdStartDate, setCreatedStartDate] = useState<string>(
    format(getCurrentDate(), DATE_FORMAT),
  );
  const [createdEndDate, setCreatedEndDate] = useState<string>(
    format(getCurrentDate(), DATE_FORMAT),
  );
  const [refreshCustGrid, setRefreshCustGrid] = useState(false);
  const [showSporadicRouteTemplateModal, setShowSporadicRouteTemplateModal] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [routeData, setRouteData] = useState<ListRoutes[]>([]);
  const [loadingState, setLoadingState] = useState(false);
  const [errorObj, setErrorObj] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });
  const [updateInvoice] = useUpdateInvoiceMutation({});
  const [updateDriver] = useUpdateDriverMutation();
  const [updateExtraColumns] = useUpdateExtraColumnsMutation();
  const [updateRouteLegDataColumns] = useUpdateRouteLegDataColumnsMutation();

  const { path } = useRouteMatch();
  const {
    data: templateData,
    loading: templateDataLoading,
    error: templateDataFetchError,
    refetch: refetchSporadicRouteTemplates,
  } = useSporadicRouteTemplatesQuery();

  const [deleteSelectedPlannedRoutes] = useDeleteMultiplePlannedRouteMutation();

  const handleShowSporadicRouteTemplateModal = useCallback(() => {
    setShowSporadicRouteTemplateModal(false);
  }, []);

  const handleDateRangeChanged = useCallback((values) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setRefreshGrid((current) => !current);
  }, []);

  const handleCreatedDateChanged = useCallback(
    (values) => {
      setCreatedStartDate(values.startDate);
      setCreatedEndDate(values.endDate);
      setRefreshCustGrid((current) => !current);
    },

    [createdStartDate, createdEndDate],
  );

  //for a snackbar of planned routes created
  const location = useLocation<{
    isFromPlannedRoutes: boolean;
    totalCreatedRoutes: number;
    timestamp: number;
  }>();

  useEffect(() => {
    if (
      location.state &&
      location.state.isFromPlannedRoutes &&
      location.state.totalCreatedRoutes > 0
    ) {
      setAlertMessage(
        `${t('validation.totalRoutesSuccess', {
          total: location.state.totalCreatedRoutes,
        })}`,
      );
      setAlertSuccess(true);
      window.history.replaceState({}, document.title);
    }
  }, [
    location.state?.totalCreatedRoutes,
    location.state?.isFromPlannedRoutes,
    location.state?.timestamp,
  ]);

  const getAllPlannedRoutes = (
    createdStartAt: string,
    createdEndAt: string,
    showLoading = false,
  ) => {
    if (showLoading) setLoadingState(true);
    httpClient
      .getPlannedRoutes({
        createdStartDate: createdStartAt,
        createdEndDate: createdEndAt,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setRouteData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setRouteData([]);
          setErrorObj({
            error: true,
            message: `${res.data.message}`,
          });
          console.error('# Planned Res error=', res.data.message);
        }
        if (showLoading) setLoadingState(false);
      })
      .catch((e) => {
        console.error('# planned error=', e);
        setLoadingState(false);
      });
  };

  const getAllData = (
    startDate: string,
    endDate: string,
    showLoading = false,
  ) => {
    if (showLoading) setLoadingState(true);
    httpClient
      .getListRoutes({
        type: RouteType.Sporadic,
        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setRouteData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setRouteData([]);
          setErrorObj({
            error: true,
            message: `${res.data.message}`,
          });
          console.error('# Sporadic Res error=', res.data.message);
        }
        if (showLoading) setLoadingState(false);
      })
      .catch((e) => {
        console.error('# Sporadic error=', e);
        setLoadingState(false);
      });
  };
  useEffect(() => {
    getAllData(startDate, endDate, true);
  }, [refreshGrid]);

  useEffect(() => {
    const element = document.getElementById('sporadicRouteGrid');
    const positions = element?.getBoundingClientRect();
    if (positions) {
      setGridHeight(window.innerHeight - (positions.top + positions.top / 5));
    }
  }, []);

  useEffect(() => {
    if (
      isPlannedRoutesView &&
      createdStartDate !== '' &&
      createdEndDate !== ''
    ) {
      //refetch planned routes
      getAllPlannedRoutes(createdStartDate, createdEndDate, true);
    } else if (!isPlannedRoutesView) {
      getAllData(startDate, endDate, true);
    }
  }, [isPlannedRoutesView, refreshCustGrid]);

  useEffect(() => {
    refetchSporadicRouteTemplates();
  }, [showSporadicRouteTemplateModal]);

  const handleDeleteApi = () => {
    setAlertLoading(true);
    deleteSelectedPlannedRoutes({
      variables: {
        ids: selectedIds,
      },
    }).then((res) => {
      setAlertMessage(
        `${t('validation.totalRoutesDeleted', {
          total: res.data?.deleteMultipleRoute,
        })}`,
      );
      setAlertLoading(false);
      setAlertSuccess(true);
      getAllPlannedRoutes(createdStartDate, createdEndDate);
    });
  };

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      if (['invoiceNumber', 'invoiceNote', 'agreedPrice'].includes(field)) {
        updateInvoice({
          variables: {
            id: parseInt(id, 10),
            invoiceData: {
              [field]: value,
            },
          },
        }).then(() => {
          {
            isPlannedRoutesView
              ? getAllPlannedRoutes(createdStartDate, createdEndDate)
              : getAllData(startDate, endDate);
          }
        });
      } else if (
        ['driverName', 'driverPhoneNumber', 'licensePlate'].includes(field)
      ) {
        updateDriver({
          variables: {
            id: parseInt(id, 10),
            driverData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      } else if (
        [
          'turnummer',
          'togref',
          'bpx',
          'pri03',
          'pri49',
          'upri03',
          'upri49',
          'parti',
          'brev',
          'pru',
          'rutekode',
          'trainNumber',
          'inneholdsbeskrivelse',
          'containerId',
        ].includes(field)
      ) {
        updateExtraColumns({
          variables: {
            id: parseInt(id, 10),
            extraColumnData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      } else if (
        ['carRegistrationNumber', 'trailerRegistrationNumber'].includes(field)
      ) {
        const row = apiRef.current.getRow(id);
        updateRouteLegDataColumns({
          variables: {
            id: row.firstLegId,
            routeLegData: {
              [field]: value,
            },
          },
        }).then(() => {
          getAllData(startDate, endDate);
        });
      }
    },
    [startDate, endDate, createdStartDate, isPlannedRoutesView, createdEndDate],
  );

  if (errorObj.error) {
    return <div>{errorObj.message}</div>;
  }

  return (
    <Grid container>
      <HelmetComponent title={t('resource.sporadicRoute.plural')} />
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={alertSuccess}
          key={'alertSuccess'}
          autoHideDuration={4000}
          onClose={() => {
            setAlertSuccess(false);
          }}
        >
          <Alert
            onClose={() => {
              setAlertSuccess(false);
            }}
            severity="success"
          >
            {`${alertMessage}`}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={alertLoading}
          key={'alertLoading'}
        >
          <Alert severity="info">{`${t('validation.loadingApi')}`}</Alert>
        </Snackbar>
        <DialogConfirmationModal
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          contentText={`${t('validation.confirmation', {
            action: t('actions.delete.lowercased'),
            item: ' selected routes',
          })}`}
          doAction={() => {
            handleDeleteApi();
          }}
          cancelAction={() => {
            setOpenDeleteDialog(false);
          }}
          buttonText={t('button.delete')}
        />
      </div>
      {showSporadicRouteTemplateModal ? (
        <ViewSporadicRouteTemplateModal
          item={
            typeof templateData === 'undefined'
              ? undefined
              : templateData.sporadicRouteTemplates
          }
          loading={templateDataLoading}
          error={templateDataFetchError}
          handleClose={handleShowSporadicRouteTemplateModal}
          open={showSporadicRouteTemplateModal}
        />
      ) : null}
      <Grid item container alignItems="center" justifyContent="space-between">
        <Typography variant="h1">
          {isPlannedRoutesView
            ? t('attributes.myPlannedRoutes')
            : t('resource.sporadicRoute.plural')}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-evenly"
          width="100%"
          mt={1}
          mb={1}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`${path}/routeTemplate/create`}
            startIcon={<AddIcon />}
          >
            {t('button.create', {
              item: t('resource.template.lowercased'),
            })}
          </Button>
          <Grid item sm={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowSporadicRouteTemplateModal(true)}
              startIcon={<ListAltSharpIcon />}
            >
              {t('button.view', {
                item: t('resource.template.plural'),
              })}
            </Button>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`${path}/create`}
            startIcon={<AddIcon />}
          >
            {t('button.create', {
              item: t('resource.sporadicRoute.lowercased'),
            })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setPlannedRoutesView(!isPlannedRoutesView)}
            startIcon={<ListAltSharpIcon />}
          >
            {`${
              isPlannedRoutesView
                ? t('attributes.viewSporadicRoutes')
                : t('attributes.viewPlannedRoutes')
            }`}
          </Button>
        </Box>
      </Grid>
      <Grid item>
        {isPlannedRoutesView ? (
          <div style={{ marginTop: 6, marginBottom: 12 }}>
            <SelectDateRangeForm
              startDate={createdStartDate}
              endDate={createdEndDate}
              onDateSelected={handleCreatedDateChanged}
              buttonText={t('actions.apply')}
              startDateText={t('attributes.startCreatedDate')}
              endDateText={t('attributes.endCreateDate')}
            />
          </div>
        ) : (
          <SelectDateRangeForm
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDateRangeChanged}
            buttonText={t('actions.apply')}
          />
        )}
      </Grid>
      <Grid item container>
        {isPlannedRoutesView && (
          <Grid container item direction="row">
            <Grid xs={10}></Grid>
            <Grid xs={2}>
              {selectedIds.length > 0 && (
                <Button
                  style={{ width: '100%' }}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenDeleteDialog(true)}
                  startIcon={<DeleteIcon />}
                >
                  {`${t('attributes.deleteSelected')}`}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} id="sporadicRouteGrid">
          {loadingState ? (
            <div>Loading...</div>
          ) : (
            <Box
              style={{
                height: gridHeight ? gridHeight : 600,
                width: '100%',
              }}
            >
              <UserConfiguredDataGridPro
                tableName={TABLE_NAMES.SporadicRoutes}
                apiRef={apiRef}
                columns={routeColumns({
                  t,
                  path,
                  apiRef,
                  classes,
                  updateExtraColumns,
                  getAllData,
                  startDate,
                  endDate,
                })}
                rows={routeData ?? []}
                onCellEditCommit={handleCellEditCommit}
                rowHeight={50}
                disableSelectionOnClick
                checkboxSelection={isPlannedRoutesView}
                onSelectionModelChange={(ids) => {
                  const selIds: number[] = [];
                  if (ids.length > 0) {
                    ids.forEach((id) => {
                      selIds.push(parseInt(id.toString()));
                    });
                    setSelectedIds(selIds);
                  } else {
                    setSelectedIds([]);
                  }
                }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
