import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  RouteTemplateForm,
  RouteTemplateFormInput,
} from '../components/RouteTemplateForm';
import {
  SporadicRouteTemplatesDocument,
  useCreateRouteTemplateMutation,
} from '../generated/graphql';
import { timeOrNull } from '../lib/date_time';
import { newLeg } from '../lib/formHelpers/leg';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getCurrencyFromDepartment } from '../utils/GetCurrencyFromDepartment';
import { numberOrNull } from '../lib/formHelpers/number';

export function CreateRouteTemplate() {
  const router = useHistory();
  const { t } = useTranslation();
  const { department } = useUserConfiguration();

  const [createRouteTemplate] = useCreateRouteTemplateMutation({
    refetchQueries: [
      {
        query: SporadicRouteTemplatesDocument,
      },
    ],
  });

  const onSubmit = async (
    values: Omit<RouteTemplateFormInput, 'subcontractorId' | 'dispatcherId'> & {
      subcontractorId?: number;
      dispatcherId?: number;
    },
  ) => {
    if (values.subcontractorId == null) {
      return;
    }

    await createRouteTemplate({
      variables: {
        RouteTemplateInput: {
          name: values.name,
          agreedPrice: values.agreedPrice,
          currency: values.currency,
          note: values.note,
          externalNote: values.externalNote,
          driverName: values.driverName,
          driverPhoneNumber: values.driverPhoneNumber,
          licensePlate: values.licensePlate,
          capacity: values.capacity,
          routeId: values.routeId,
          dispatcherId: values?.dispatcherId as number,
          subcontractorId: values?.subcontractorId as number,
          files: values.files.map((file) => ({
            id: file.id,
          })),
          ftl: values.ftl || undefined,
          legs: values.legs.map((leg, index) => ({
            load: leg.load,
            fuel: leg.fuel,
            unload: leg.unload,
            note: leg.note ?? '',
            arrivalTime: leg.arrivalTime ?? '00:00',
            departureTime: timeOrNull(leg.departureTime),
            position: index,
            transportationDateOffset: leg.transportationDateOffset,
            locationId: leg.locationId as number,
            location: undefined,
            loadingListItems: leg.loadingListItems.map((loadingListItem) => ({
              ...loadingListItem,
              locationId: loadingListItem.locationId as number,
              packages: numberOrNull(loadingListItem.packages),
              pallets: numberOrNull(loadingListItem.pallets),
              palletSpace: numberOrNull(loadingListItem.palletSpace),
              weight: numberOrNull(loadingListItem.weight),
            })),
          })),
        },
      },
    });
    router.push('/traffic/sporadic-routes');
  };

  const initialValues = (): Omit<
    RouteTemplateFormInput,
    'subcontractorId' | 'dispatcherId'
  > & { subcontractorId?: number; dispatcherId?: number } => ({
    name: '',
    agreedPrice: 0,
    currency: getCurrencyFromDepartment(department.id),
    note: '',
    externalNote: '',
    driverName: '',
    driverPhoneNumber: '',
    licensePlate: '',
    capacity: 0,
    routeId: '',
    dispatcherId: undefined,
    subcontractorId: undefined,
    files: [],
    ftl: undefined,
    legs: newLeg(''),
  });

  return (
    <Grid container spacing={5} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.route.capitalized',
        )} ${t('resource.template.lowercased')}`}
      />
      <Grid item>
        <Typography variant="h1">
          {`${t('resource.route.capitalized')} ${t(
            'resource.template.lowercased',
          )}`}
        </Typography>
      </Grid>
      <Grid item>
        <RouteTemplateForm
          onSubmit={onSubmit}
          initialValues={initialValues()}
          submitButtonLabel={t('button.create', {
            item: `${t('resource.route.capitalized')} ${t(
              'resource.template.lowercased',
            )}`,
          })}
        />
      </Grid>
    </Grid>
  );
}
