import { Box, makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../CenteredBox';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { CheckboxFieldRHF } from './CheckboxFieldRHF';
import { CommonTextFieldRHF } from './CommonTextFieldRHF';

interface FieldsProps {
  readOnly: boolean;
}

const useStyle = makeStyles(() => ({
  legField: {
    width: '100%',
    fontSize: '10px',
    marginRight: 20,
    marginTop: 18,
    marginBottom: 18,
  },
}));

export function CopyDriverFieldsRouteRHF(props: FieldsProps) {
  const { readOnly } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    control,
    setValue: setFieldValue,
    getValues: getFieldProps,
  } = useFormContext<SporadicRouteFormInput>();

  const [isCopyDriverPlateInfo] = useWatch({
    control,
    name: ['isCopyDriverPlateInfo'],
  });

  //for copy all driver data in legs
  useEffect(() => {
    if (isCopyDriverPlateInfo) {
      const allLegs = getFieldProps('legs');

      const routeDriverName = getFieldProps('routeDriverName');
      const routeDriverPhoneNumber = getFieldProps('routeDriverPhoneNumber');
      const routeCarRegNumber = getFieldProps('routeCarRegNumber');
      const routeTrailerRegNumber = getFieldProps('routeTrailerRegNumber');

      allLegs.forEach((leg, index) => {
        setFieldValue(
          `legs.${index}.routeDriverName`,
          routeDriverName ?? undefined,
        );
        setFieldValue(
          `legs.${index}.routeDriverPhoneNumber`,
          routeDriverPhoneNumber ?? undefined,
        );
        setFieldValue(
          `legs.${index}.carRegistrationNumber`,
          routeCarRegNumber ?? undefined,
        );
        setFieldValue(
          `legs.${index}.trailerRegistrationNumber`,
          routeTrailerRegNumber ?? undefined,
        );
      });
    }
  }, [isCopyDriverPlateInfo]);

  return (
    <>
      <CenteredBox>
        <CheckboxFieldRHF
          name={'isCopyDriverPlateInfo'}
          controllerName={'isCopyDriverPlateInfo'}
          label={t('attributes.copyDriverPlateInfo')}
          readOnly={readOnly}
        />
      </CenteredBox>

      <Box display="flex" alignItems="centre" justifyContent="space-between">
        <CommonTextFieldRHF
          name="routeCarRegNumber"
          controllerName={'routeCarRegNumber'}
          type="string"
          className={classes.legField}
          readOnly={readOnly}
        />

        <CommonTextFieldRHF
          name="routeTrailerRegNumber"
          controllerName={'routeTrailerRegNumber'}
          type="string"
          className={classes.legField}
          readOnly={readOnly}
        />
        <CommonTextFieldRHF
          name="routeDriverName"
          controllerName={'routeDriverName'}
          className={classes.legField}
          readOnly={readOnly}
        />

        <CommonTextFieldRHF
          name="routeDriverPhoneNumber"
          controllerName={'routeDriverPhoneNumber'}
          className={classes.legField}
          readOnly={readOnly}
        />
      </Box>
    </>
  );
}
