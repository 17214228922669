import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sortBy } from 'lodash';
import { COUNTRIES } from '../../components/AddTransport';
import { Maybe } from '../../generated/graphql';
import { getFirstLeg, getLastLeg } from '../../lib/legs';
import { calculateTransportationAndProductionDates } from '../../lib/route_leg_dates';
import { removeSecondsFromTimeString } from '../../lib/time';
import RobotoBold from '../assets/Roboto-Bold.ttf';
import Roboto from '../assets/Roboto-Regular.ttf';
import Logo from '../pdfImages/logo.png';

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fontWeight: 'bold',
  src: RobotoBold,
});
Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: Roboto,
});

// Create styles
const classes = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
    width: '100%',
    fontFamily: 'Roboto',
  },
  title: {
    margin: 10,
  },
  infoField: {
    width: '40%',
    fontSize: 10,
    fontWeight: 300,

    margin: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoFieldBold: {
    width: '40%',
    fontSize: 10,
    fontWeight: 600,
    margin: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoFieldNote: {
    width: '90%',
    fontSize: 10,
    fontWeight: 600,
    margin: 4,
  },
  infoBox: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0pt 10pt',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'grey',
  },
  section: {
    padding: '20pt 10pt',
  },
  viewer: {
    height: '100vh',
  },
  routeStyles: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  routeTitle: {
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 10,
  },
  routeAttributeFieldsIndex: {
    fontWeight: 600,
    fontSize: 10,
    width: '2%',
  },
  routeAttributeFields: {
    fontWeight: 600,
    fontSize: 8,
    width: '20%',
  },
  routeAttributeFieldsTime: {
    fontWeight: 600,
    fontSize: 8,
    width: '6%',
  },
  routeField: {
    fontWeight: 300,
    fontSize: 8,
    width: '20%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  routeLegRegNoField: {
    fontWeight: 300,
    fontSize: 8,
    width: '22%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  routeFieldTime: {
    fontWeight: 300,
    fontSize: 8,
    width: '6%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  oneRoute: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 4,
    fontSize: 10,
  },
});

export interface TransportAgreementInterface {
  id: number;
  routeAgreementNo?: Maybe<number>;
  routeId: string;
  transportationDate: string;
  capacity?: Maybe<number>;
  kilometer?: Maybe<number>;
  weight?: Maybe<number>;
  agreedPrice?: Maybe<number>;
  currency?: string;
  subcontractor: { name: string };
  licensePlate: string;
  note: string;
  createdBy: string;
  dispatcher?: Maybe<{
    name: string;
  }>;
  legs: {
    arrivalTime: string;
    departureTime?: Maybe<string>;
    note: string;
    transportationDateOffset: number;
    carRegistrationNumber?: Maybe<string>;
    trailerRegistrationNumber?: Maybe<string>;
    position: number;
    location?: Maybe<{
      name: string;
      country: string;
      address: string;
      postalCode: string;
      city: string;
    }>;
  }[];
  files: {
    path: string;
  }[];
}

function getCountry(country: string) {
  return COUNTRIES[country as keyof typeof COUNTRIES];
}

type TransportAgreementProps = {
  route: TransportAgreementInterface;
  multipleDates?: string;
};

// Create Document Component
export const TransportAgreement = (props: TransportAgreementProps) => {
  const { route, multipleDates } = props;

  const sortedLegs = sortBy(route.legs, 'position');

  const dateField = multipleDates ? multipleDates : route.transportationDate;
  const agreementNo = route.routeAgreementNo
    ? `${route.id}${route.routeAgreementNo}`
    : `${route.id}1`;
  const legsWithTransportationDate = calculateTransportationAndProductionDates(
    sortedLegs,
    route.transportationDate,
  );
  const dateFieldName = multipleDates
    ? 'Planned transport dates:'
    : 'Transport date:';
  return (
    <Document
      title={`Transport Agreement ${route.routeId}`}
      author="Posten - Nexus linehaul"
      subject={`Transport Agreement ${route.routeId}`}
    >
      <Page size="A4" style={classes.page}>
        <Image
          style={{
            width: 'auto',
          }}
          src={Logo}
          fixed
        />
        <View fixed style={classes.title}>
          <Text>TRANSPORT AGREEMENT</Text>
        </View>
        <View>
          <View style={classes.infoBox}>
            <Text style={classes.infoFieldBold}>
              Route id: <Text style={classes.infoField}>{route.routeId} </Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Agreement nr:{' '}
              <Text style={classes.infoField}>{agreementNo} </Text>
            </Text>
            <Text style={classes.infoFieldNote}>
              {dateFieldName} <Text style={classes.infoField}>{dateField}</Text>
            </Text>
          </View>
          <View style={classes.divider}></View>
          <View style={classes.infoBox}>
            <Text style={classes.infoFieldBold}>
              Subcontractor:{' '}
              <Text style={classes.infoField}>{route.subcontractor.name}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Dispatcher:{' '}
              <Text style={classes.infoField}>{route.dispatcher?.name}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Price:{' '}
              <Text style={classes.infoField}>{`${
                route.agreedPrice
                  ? `${route.agreedPrice} ${
                      route.currency ? route.currency?.toUpperCase() : ''
                    }`
                  : ''
              }`}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Contractor Bring:{' '}
              <Text style={classes.infoField}>{route.createdBy}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Capacity:{' '}
              <Text style={classes.infoField}>{route.capacity ?? ''}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Weight:{' '}
              <Text style={classes.infoField}>
                {`${route.weight != null ? route.weight + ' kg' : ''}    ` ??
                  '    '}
              </Text>
              <Text style={classes.infoFieldBold}>
                Kilometer:{' '}
                <Text style={classes.infoField}>{route.kilometer ?? ''}</Text>
              </Text>
            </Text>
          </View>
          <View style={classes.divider}></View>
          <View style={classes.infoBox}>
            <Text style={classes.infoFieldBold}>
              Departure City:{' '}
              <Text style={classes.infoField}>
                {getFirstLeg(sortedLegs)?.location?.city || ''}
              </Text>
            </Text>
            {sortedLegs.length && (
              <>
                <Text style={classes.infoFieldBold}>
                  Departure Country:{' '}
                  <Text style={classes.infoField}>
                    {getCountry(
                      getFirstLeg(sortedLegs)?.location?.country || '',
                    ) || ''}
                  </Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  Arrival City:{' '}
                  <Text style={classes.infoField}>
                    {getLastLeg(sortedLegs)?.location?.city || ''}
                  </Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  Arrival Country:{' '}
                  <Text style={classes.infoField}>
                    {getCountry(
                      getLastLeg(sortedLegs)?.location?.country || '',
                    ) || ''}
                  </Text>
                </Text>
              </>
            )}
            <Text style={classes.infoFieldNote}>
              Note: <Text style={classes.infoField}>{route.note || ''}</Text>
            </Text>
          </View>
          <View style={classes.divider}></View>
        </View>
        <View style={classes.section}>
          <Text style={classes.routeTitle}>Schedule: </Text>
          <View>
            <View style={classes.routeStyles}>
              <Text style={classes.routeAttributeFieldsIndex}>#</Text>
              <Text style={classes.routeAttributeFields}>Location</Text>
              <Text style={classes.routeAttributeFields}>Address </Text>
              <Text style={classes.routeAttributeFields}>P.Code & City </Text>
              <Text style={classes.routeAttributeFieldsTime}>Date </Text>
              <Text style={classes.routeAttributeFieldsTime}>Arrival </Text>
              <Text style={classes.routeAttributeFieldsTime}>Depart </Text>
            </View>
            {legsWithTransportationDate.map((leg, index) => {
              return (
                <View key={index}>
                  <View style={classes.oneRoute}>
                    <Text style={classes.routeAttributeFieldsIndex}>
                      {index + 1}
                    </Text>
                    <Text style={classes.routeField}>
                      {leg.location?.name || ''}
                    </Text>
                    <Text style={classes.routeField}>
                      {leg.location?.address || ''}{' '}
                    </Text>
                    <Text style={classes.routeField}>
                      {`${leg.location?.postalCode} - ${leg.location?.city}` ||
                        ''}
                    </Text>
                    <Text style={classes.routeFieldTime}>
                      {leg.transportationDate || ''}
                    </Text>
                    <Text style={classes.routeFieldTime}>
                      {removeSecondsFromTimeString(leg.arrivalTime) ?? ''}
                    </Text>
                    <Text style={classes.routeFieldTime}>
                      {removeSecondsFromTimeString(leg.departureTime) ?? ''}
                    </Text>
                  </View>
                  {(leg.note && leg.note != '') ||
                  (leg.carRegistrationNumber &&
                    leg.carRegistrationNumber != '') ||
                  (leg.trailerRegistrationNumber &&
                    leg.trailerRegistrationNumber != '') ? (
                    <View style={classes.oneRoute}>
                      <Text
                        style={classes.routeAttributeFieldsIndex}
                      >{``}</Text>
                      <Text style={classes.routeField}>{``}</Text>
                      {leg.note && leg.note != '' ? (
                        <Text style={classes.routeLegRegNoField}>
                          {`Note: ${leg.note}`}
                        </Text>
                      ) : (
                        <Text style={classes.routeLegRegNoField}>{``}</Text>
                      )}
                      {leg.carRegistrationNumber &&
                      leg.carRegistrationNumber != '' ? (
                        <Text style={classes.routeLegRegNoField}>
                          {`Car RegNo: ${leg.carRegistrationNumber}`}
                        </Text>
                      ) : (
                        <Text style={classes.routeLegRegNoField}>{``}</Text>
                      )}
                      {leg.trailerRegistrationNumber &&
                      leg.trailerRegistrationNumber != '' ? (
                        <Text style={classes.routeLegRegNoField}>
                          {`Trailer RegNo: ${leg.trailerRegistrationNumber}`}
                        </Text>
                      ) : (
                        <Text style={classes.routeLegRegNoField}>{``}</Text>
                      )}
                    </View>
                  ) : null}

                  <View style={classes.divider}></View>
                </View>
              );
            })}
          </View>
        </View>
      </Page>
    </Document>
  );
};
