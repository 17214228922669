import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  makeStyles,
  Modal,
  TextField,
  Theme,
} from '@material-ui/core';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  GetCustomerAccountsForCardDocument,
  useDeleteCustomerAccountMutation,
  useGetCustomerAccountQuery,
  useUpdateCustomerAccountMutation,
} from '../../generated/graphql';
import { LoadingAndError } from '../../utils/LoadingAndError';
import { CreatedAndUpdatedView } from '../CreatedAndUpdatedView';
import { DialogModal } from '../DialogModal';
import { buttonStyle } from '../ViewCard';

function getModalStyle() {
  return {
    width: '100%',
    height: '100vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    width: '100%',
  },
  field: {
    width: '80%',
    margin: theme.spacing(2),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(2, 'auto'),
  },
  subResources: {
    margin: theme.spacing(2, 'auto'),
    width: '90%',
  },
  button: {
    margin: theme.spacing(2, 'auto'),
  },
  formContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
  },
}));

const validationSchema = (t: TFunction) =>
  yup.object({
    accountNumber: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.accountNumber'),
          len: 3,
        }),
      )
      .matches(
        /^[0-9a-zA-Z]*$/,
        t('validation.onlyAlphaNumeric', {
          name: t('attributes.accountNumber'),
        }),
      )
      .required(
        t('validation.isRequired', { name: t('attributes.accountNumber') }),
      ),
    salesPersonName: yup
      .string()
      .nullable()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.salesPersonName'),
          len: 3,
        }),
      ),
    logisticPersonName: yup
      .string()
      .nullable()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.logisticPersonName'),
          len: 3,
        }),
      ),
  });

interface UpdateCustomerAccountProps {
  id: number;
  cardId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isProtected?: boolean;
}

export function UpdateCustomerAccount(props: UpdateCustomerAccountProps) {
  const { id, open, setOpen, cardId, isProtected = false } = props;
  const { loading, error, data } = useGetCustomerAccountQuery({
    variables: { id },
  });
  const classes = useStyle();
  const { t } = useTranslation();
  const deleteButtonClass = buttonStyle();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [updateCustomerAccount, {}] = useUpdateCustomerAccountMutation({
    refetchQueries: [
      {
        query: GetCustomerAccountsForCardDocument,
        variables: {
          cardId,
        },
      },
    ],
  });
  const [deleteCustomerAccount] = useDeleteCustomerAccountMutation({
    refetchQueries: [
      {
        query: GetCustomerAccountsForCardDocument,
        variables: {
          cardId,
        },
      },
    ],
  });
  const handleDeleteLocation = () => {
    deleteCustomerAccount({ variables: { id: id } });
    setOpen(false);
  };

  const onSubmit = (values: {
    id: number;
    accountNumber: string;
    note: string;
    salesPersonName: string;
    logisticPersonName: string;
  }) => {
    updateCustomerAccount({
      variables: {
        input: values,
      },
    });
    setOpen(false);
  };

  return (
    <>
      <LoadingAndError loading={loading} error={error} data={data}>
        {({ loadedData }) => (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={getModalStyle()}
          >
            <Box className={classes.paper}>
              <Formik
                initialValues={{
                  id: loadedData.customerAccount.id,
                  accountNumber: loadedData.customerAccount.accountNumber,
                  note: loadedData.customerAccount.note,
                  salesPersonName: loadedData.customerAccount.salesPersonName,
                  logisticPersonName:
                    loadedData.customerAccount.logisticPersonName,
                }}
                validationSchema={validationSchema(t)}
                onSubmit={onSubmit}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <Grid container spacing={5} justifyContent="center">
                      <Grid item sm={6} container spacing={3}>
                        <Grid item sm={12}>
                          <CreatedAndUpdatedView
                            createdBy={loadedData.customerAccount.createdBy}
                            lastUpdatedBy={
                              loadedData.customerAccount.lastUpdatedBy
                            }
                            updatedAt={loadedData.customerAccount.updatedAt}
                          />
                          <Divider />
                          <TextField
                            fullWidth
                            id="accountNumber"
                            name="accountNumber"
                            label={t('attributes.accountNumber')}
                            value={props.values.accountNumber}
                            onChange={props.handleChange}
                            error={
                              props.touched.accountNumber &&
                              Boolean(props.errors.accountNumber)
                            }
                            helperText={
                              props.touched.accountNumber &&
                              props.errors.accountNumber
                            }
                          />
                          <TextField
                            fullWidth
                            id="note"
                            name="note"
                            label={t('attributes.note')}
                            value={props.values.note}
                            onChange={props.handleChange}
                            error={
                              props.touched.note && Boolean(props.errors.note)
                            }
                            helperText={props.touched.note && props.errors.note}
                          />
                          <TextField
                            fullWidth
                            id="salesPersonName"
                            name="salesPersonName"
                            label={t('attributes.salesPersonName')}
                            value={props.values.salesPersonName}
                            onChange={props.handleChange}
                            error={
                              props.touched.salesPersonName &&
                              Boolean(props.errors.salesPersonName)
                            }
                            helperText={
                              props.touched.salesPersonName &&
                              props.errors.salesPersonName
                            }
                          />
                          <TextField
                            fullWidth
                            id="logisticPersonName"
                            name="logisticPersonName"
                            label={t('attributes.logisticPersonName')}
                            value={props.values.logisticPersonName}
                            onChange={props.handleChange}
                            error={
                              props.touched.logisticPersonName &&
                              Boolean(props.errors.logisticPersonName)
                            }
                            helperText={
                              props.touched.logisticPersonName &&
                              props.errors.logisticPersonName
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          sm={12}
                          container
                          justifyContent="center"
                          spacing={2}
                        >
                          <Grid item>
                            <Input
                              type="submit"
                              value={t('button.update', {
                                item: t('resource.accounts.lowercased'),
                              })}
                              color="primary"
                            />
                          </Grid>
                          {!isProtected && (
                            <Grid item>
                              <Button
                                variant="outlined"
                                classes={{
                                  root: deleteButtonClass.button,
                                }}
                                onClick={() => {
                                  setOpenDeleteDialog(true);
                                }}
                              >
                                {t('button.delete')}
                              </Button>
                            </Grid>
                          )}
                          <Grid item sm={12} container justifyContent="center">
                            <Button
                              type="submit"
                              variant="outlined"
                              onClick={() => {
                                setOpen(false);
                              }}
                              color="primary"
                            >
                              {t('button.close')}
                            </Button>
                            <DialogModal
                              open={openDeleteDialog}
                              setOpen={setOpenDeleteDialog}
                              contentText={t('validation.confirmation', {
                                item: `${loadedData.customerAccount.accountNumber}`,
                                action: t('actions.delete.lowercased'),
                              })}
                              doAction={handleDeleteLocation}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Modal>
        )}
      </LoadingAndError>
    </>
  );
}
