import { Box, makeStyles } from '@material-ui/core';
import { usePDF } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { mergePdfs } from '../lib/pdf/mergePdfs';
import { useHttpClient } from '../providers/HttpClientProvider';
import {
  TransportAgreement,
  TransportAgreementInterface,
} from './TransportAgreement/TransportAgreement';

const useStyles = makeStyles({
  pdfPage: {
    margin: 0,
    width: '100%',
  },
  pdfWrapper: {
    margin: 0,
    width: '90vw',
  },
});

interface ViewPdfProps {
  route: TransportAgreementInterface;
}

export function ViewPdf(props: ViewPdfProps) {
  const { route } = props;
  const classes = useStyles();
  const { httpClient } = useHttpClient();
  const [allPdfsAsArrayBuffer, setArrayBuffer] = useState<ArrayBuffer>();
  const [numPages, setNumPages] = useState<number>();
  const document = <TransportAgreement route={route} />;
  const [instance] = usePDF({ document });
  useEffect(() => {
    if (!allPdfsAsArrayBuffer && instance.blob) {
      mergePdfs(instance.blob, route.files, httpClient).then(function (data) {
        setArrayBuffer(data);
      });
    }
  }, [instance, route]);

  function onDocumentLoadSuccess(numPages: any) {
    setNumPages(numPages.numPages);
  }
  return (
    <>
      {allPdfsAsArrayBuffer && (
        <Box className={classes.pdfWrapper}>
          <Document
            className={classes.pdfPage}
            file={{ data: allPdfsAsArrayBuffer }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (_el, index) => (
              <div key={`wrapper_page_wrapper${index + 1}`}>
                <br key={`space_page_${index + 1}`} />
                <Page
                  className={classes.pdfPage}
                  height={window.innerWidth}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              </div>
            ))}
          </Document>
        </Box>
      )}
    </>
  );
}
