import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { Field, FieldProps, Formik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  GetLocationsForCardDocument,
  useDeleteLocationMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
} from '../../generated/graphql';
import { COUNTRIES_MAP } from '../../lib/constants';
import { LoadingAndError } from '../../utils/LoadingAndError';
import { CreatedAndUpdatedView } from '../CreatedAndUpdatedView';
import { DialogModal } from '../DialogModal';
import { buttonStyle } from '../ViewCard';

function getModalStyle() {
  return {
    width: '100%',
    height: '100vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    width: '100%',
  },
  field: {
    width: '80%',
    margin: theme.spacing(2),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(2, 'auto'),
  },
  subResources: {
    margin: theme.spacing(2, 'auto'),
    width: '90%',
  },
  button: {
    margin: theme.spacing(2, 'auto'),
  },
  formContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
  },
}));

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.name'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    address: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          address: t('attributes.address'),
          len: 3,
        }),
      )
      .required(
        t('validation.isRequired', { address: t('attributes.address') }),
      ),
    postalCode: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          postalCode: t('attributes.postalCode'),
          len: 3,
        }),
      )
      .required(
        t('validation.isRequired', { postalCode: t('attributes.postalCode') }),
      ),
    city: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          city: t('attributes.city'),
          len: 3,
        }),
      )
      .required(t('validation.isRequired', { city: t('attributes.city') })),
    country: yup
      .string()
      .min(
        2,
        t('validation.atLeastLen', {
          country: t('attributes.country'),
          len: 2,
        }),
      )
      .required(
        t('validation.isRequired', { country: t('attributes.country') }),
      ),
    phLocationId: yup.string().when('cardType', {
      is: (value: string) => value && value === 'terminals',
      then: yup
        .string()
        .min(
          3,
          t('validation.atLeastLen', {
            name: t('attributes.phLocationId'),
            len: 3,
          }),
        )
        .required(
          t('validation.isRequired', { name: t('attributes.phLocationId') }),
        ),
      otherwise: yup.string(),
    }),

    locationShortCode: yup.string().when('cardType', {
      is: (value: string) => value && value === 'terminals',
      then: yup
        .string()
        .min(
          3,
          t('validation.atLeastLen', {
            name: t('attributes.locationCode'),
            len: 3,
          }),
        )
        .required(
          t('validation.isRequired', { name: t('attributes.locationCode') }),
        ),
      otherwise: yup.string(),
    }),
  });

interface UpdateLocationProps {
  id: number;
  cardId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isProtected?: boolean;
  cardType: string;
}

export function UpdateLocation(props: UpdateLocationProps) {
  const { id, open, setOpen, cardId, isProtected = false, cardType } = props;
  const { loading, error, data } = useGetLocationQuery({
    variables: { id },
  });
  const classes = useStyle();
  const { t } = useTranslation();
  const deleteButtonClass = buttonStyle();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [errPHLocationId, setErrPHLocationId] = useState<boolean>(false);
  const [errLocationCode, setErrLocationCode] = useState<boolean>(false);
  const [updateLocation, {}] = useUpdateLocationMutation({
    refetchQueries: [
      {
        query: GetLocationsForCardDocument,
        variables: {
          cardId,
        },
      },
    ],
  });
  const [deleteLocation] = useDeleteLocationMutation({
    refetchQueries: [
      {
        query: GetLocationsForCardDocument,
        variables: {
          cardId,
        },
      },
    ],
  });
  const handleDeleteLocation = () => {
    deleteLocation({ variables: { id: id } });
    setOpen(false);
  };

  const onSubmit = (values: {
    id: number;
    name: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    phLocationId: string;
    locationShortCode: string;
  }) => {
    updateLocation({
      variables: { ...values, phLocationId: `${values.phLocationId}` },
    })
      .then(() => {
        setOpen(false);
      })
      .catch((e) => {
        if (e.message && e.message.includes('LocationCode'))
          setErrLocationCode(true);
        else setErrLocationCode(false);
        if (e.message && e.message.includes('PHLocationId'))
          setErrPHLocationId(true);
        else setErrPHLocationId(false);
      });
  };

  return (
    <>
      <LoadingAndError loading={loading} error={error} data={data}>
        {({ loadedData }) => (
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={getModalStyle()}
          >
            <Box className={classes.paper}>
              <Formik
                initialValues={{
                  id: loadedData.location.id,
                  name: loadedData.location.name,
                  address: loadedData.location.address,
                  postalCode: loadedData.location.postalCode,
                  city: loadedData.location.city,
                  country: loadedData.location.country,
                  phLocationId: loadedData.location.phLocationId,
                  locationShortCode: loadedData.location.locationShortCode,
                  cardType: cardType,
                }}
                validationSchema={validationSchema(t)}
                onSubmit={onSubmit}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <Grid container spacing={5} justifyContent="center">
                      <Grid item sm={8} container spacing={4}>
                        <Grid item sm={12}>
                          <CreatedAndUpdatedView
                            createdBy={loadedData.location.createdBy}
                            lastUpdatedBy={loadedData.location.lastUpdatedBy}
                            updatedAt={loadedData.location.updatedAt}
                          />
                          <Divider />
                          <TextField
                            fullWidth
                            id="name"
                            name="name"
                            label={t('attributes.name')}
                            value={props.values.name}
                            onChange={props.handleChange}
                            error={
                              props.touched.name && Boolean(props.errors.name)
                            }
                            helperText={props.touched.name && props.errors.name}
                          />
                          <TextField
                            fullWidth
                            id="address"
                            name="address"
                            label={t('attributes.address')}
                            value={props.values.address}
                            onChange={props.handleChange}
                            error={
                              props.touched.address &&
                              Boolean(props.errors.address)
                            }
                            helperText={
                              props.touched.address && props.errors.address
                            }
                          />
                          <Grid container sm={12} spacing={2} direction="row">
                            <Grid item sm={5}>
                              <TextField
                                fullWidth
                                inputProps={{ maxLength: 12 }}
                                id="postalCode"
                                name="postalCode"
                                label={t('attributes.postalCode')}
                                value={props.values.postalCode}
                                onChange={props.handleChange}
                                error={
                                  props.touched.postalCode &&
                                  Boolean(props.errors.postalCode)
                                }
                                helperText={
                                  props.touched.postalCode &&
                                  props.errors.postalCode
                                }
                              />
                            </Grid>
                            <Grid item sm={7}>
                              <TextField
                                fullWidth
                                id="city"
                                name="city"
                                label={t('attributes.city')}
                                value={props.values.city}
                                onChange={props.handleChange}
                                error={
                                  props.touched.city &&
                                  Boolean(props.errors.city)
                                }
                                helperText={
                                  props.touched.city && props.errors.city
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* new fields */}
                          {cardType === 'terminals' ? (
                            <Grid container sm={12} spacing={2} direction="row">
                              <Grid item sm={5}>
                                <TextField
                                  fullWidth
                                  type={'number'}
                                  id="phLocationId"
                                  name="phLocationId"
                                  label={t('attributes.phLocationId')}
                                  value={props.values.phLocationId}
                                  onChange={props.handleChange}
                                  error={
                                    props.touched.phLocationId &&
                                    (Boolean(props.errors.phLocationId) ||
                                      errPHLocationId)
                                  }
                                  helperText={
                                    (props.touched.phLocationId &&
                                      props.errors.phLocationId) ||
                                    (errPHLocationId && 'Already Exist')
                                  }
                                />
                              </Grid>
                              <Grid item sm={7}>
                                <TextField
                                  fullWidth
                                  inputProps={{ maxLength: 6 }}
                                  id="locationShortCode"
                                  name="locationShortCode"
                                  label={t('attributes.locationCode')}
                                  value={props.values.locationShortCode}
                                  onChange={props.handleChange}
                                  error={
                                    props.touched.locationShortCode &&
                                    (Boolean(props.errors.locationShortCode) ||
                                      errLocationCode)
                                  }
                                  helperText={
                                    (props.touched.locationShortCode &&
                                      props.errors.locationShortCode) ||
                                    (errLocationCode && 'Already Exist')
                                  }
                                />
                              </Grid>
                            </Grid>
                          ) : null}
                        </Grid>
                        <Field name="country">
                          {({ field }: FieldProps) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              className={classes.field}
                            >
                              <InputLabel shrink id="country">
                                {t('attributes.country')}
                              </InputLabel>
                              <Select
                                id="country"
                                {...field}
                                labelId="country"
                                label={t('attributes.country')}
                                IconComponent={(props) => (
                                  <ArrowDropDown
                                    {...props}
                                    style={{
                                      display: 'inline-block',
                                    }}
                                  />
                                )}
                              >
                                {Object.entries(COUNTRIES_MAP).map(
                                  ([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                      {value}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                        <Grid
                          item
                          sm={12}
                          container
                          justifyContent="center"
                          spacing={2}
                        >
                          <Grid item>
                            <Input
                              type="submit"
                              value={t('button.update', {
                                item: t('resource.location.lowercased'),
                              })}
                              color="primary"
                            />
                          </Grid>
                          {!isProtected && (
                            <Grid item>
                              <Button
                                variant="outlined"
                                classes={{
                                  root: deleteButtonClass.button,
                                }}
                                onClick={() => {
                                  setOpenDeleteDialog(true);
                                }}
                              >
                                {t('button.delete')}
                              </Button>
                            </Grid>
                          )}
                          <Grid item sm={12} container justifyContent="center">
                            <Button
                              type="submit"
                              variant="outlined"
                              onClick={() => {
                                setOpen(false);
                              }}
                              color="primary"
                            >
                              {t('button.close')}
                            </Button>
                            <DialogModal
                              open={openDeleteDialog}
                              setOpen={setOpenDeleteDialog}
                              contentText={t('validation.confirmation', {
                                item: `${loadedData.location.name}`,
                                action: t('actions.delete.lowercased'),
                              })}
                              doAction={handleDeleteLocation}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Box>
          </Modal>
        )}
      </LoadingAndError>
    </>
  );
}
