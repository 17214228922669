import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useState } from 'react';
import { useHttpClient } from '../providers/HttpClientProvider';

const createStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 200px)',
  },
}));

enum CurrentState {
  Waiting,
  Requesting,
  Failed,
  Done,
}

interface State {
  state: CurrentState;
  error?: string;
  stats?: {
    generatedFrom: Date;
    generatedTo: Date;
    newRoutesCreated: number;
  };
}

export function GenerateFixed() {
  const classes = createStyles();
  const { httpClient } = useHttpClient();
  const [response, setResponse] = useState<State>({
    state: CurrentState.Waiting,
  });

  const onButtonClicked = async () => {
    setResponse({
      state: CurrentState.Requesting,
    });
    const res = await httpClient.generateFixedRoutes();

    if (res.status === 201) {
      setResponse({
        state: CurrentState.Done,
        stats: res.data.stats,
      });
    } else {
      setResponse({
        state: CurrentState.Failed,
      });
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      spacing={8}
      alignItems="center"
      className={classes.root}
    >
      <Grid item sm={3}>
        <Button variant="outlined" onClick={onButtonClicked}>
          Generate Fixed Routes
        </Button>
        <StatsView
          state={response.state}
          stats={response.stats}
          error={response.error}
        />
      </Grid>
    </Grid>
  );
}

interface StatsViewProps {
  state: CurrentState;
  error?: string;
  stats?: {
    generatedFrom: Date;
    generatedTo: Date;
    newRoutesCreated: number;
  };
}

function StatsView(props: StatsViewProps) {
  switch (props.state) {
    case CurrentState.Waiting:
      return null;
    case CurrentState.Requesting:
      return <Typography>Requesting...</Typography>;
    case CurrentState.Failed:
      return <Typography>Something went wrong: {props.error}</Typography>;
    case CurrentState.Done:
      const from =
        props.stats?.generatedFrom != null
          ? format(new Date(props.stats.generatedFrom), 'dd-MM-yy')
          : '';
      const to =
        props.stats?.generatedTo != null
          ? format(new Date(props.stats.generatedTo), 'dd-MM-yy')
          : '';
      return (
        <Box>
          <Typography variant="subtitle1">Done</Typography>
          <Box mt="20px">
            <Typography variant="subtitle2">Fixed routes stats:</Typography>
            <Typography>- Generated from: {from}</Typography>
            <Typography>- Generated to: {to}</Typography>
            <Typography>
              - New routes created: {props.stats?.newRoutesCreated}
            </Typography>
          </Box>
        </Box>
      );
  }
}
