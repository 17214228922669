import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import TourIcon from '@mui/icons-material/Tour';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import { TourTemplatesRouteView } from '../components/TourTemplatesRouteView';
import { TourTemplatesTourView } from '../components/TourTemplatesTourView';
import { TABLE_NAMES } from '../lib/constants';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';

export function TourTemplates() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { getCustomConfiguration, updateCustomConfiguration } =
    useUserConfiguration();

  const showTour = getCustomConfiguration(
    TABLE_NAMES.TourTemplateOverview,
    'showTour',
  );

  const handleChangeView = () => {
    updateCustomConfiguration(
      TABLE_NAMES.TourTemplateOverview,
      'showTour',
      !showTour,
    );
  };

  const routeViewTitle = t('actions.viewItemSwitched', {
    item: t('resource.route.capitalized'),
  });

  const tourViewTitle = t('actions.viewItemSwitched', {
    item: t('resource.tour.capitalized'),
  });

  const title = `${t('resource.tour.capitalized')} ${t(
    'resource.template.lowercased',
  )} - ${showTour ? tourViewTitle : routeViewTitle}`;

  return (
    <Grid container>
      <HelmetComponent title={title} />
      <Grid item container alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={5}
        >
          <Typography variant="h1">{title}</Typography>

          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`${path}/create`}
            startIcon={<AddIcon />}
          >
            {t('button.create', {
              item: `${t('resource.tour.lowercased')} ${t(
                'resource.template.lowercased',
              )}`,
            })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeView}
            startIcon={showTour ? <AltRouteIcon /> : <TourIcon />}
          >
            {showTour ? routeViewTitle : tourViewTitle}
          </Button>
        </Box>
        {showTour ? <TourTemplatesTourView /> : <TourTemplatesRouteView />}
      </Grid>
    </Grid>
  );
}
