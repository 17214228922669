import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface AutoResetProps {
  readOnly: boolean;
}

export function AutoResetRHF(props: AutoResetProps) {
  const { readOnly } = props;
  const { reset } = useFormContext();
  useEffect(() => {
    if (readOnly === true) {
      reset();
    }
  }, [readOnly]);
  return null;
}
