import { Box, CircularProgress } from '@material-ui/core';
import { useGetMeQuery } from '../generated/graphql';
import { SelectTerminal } from './SelectTerminal';
import { TerminalPageContent } from './TerminalPageContent';

export function TerminalPage() {
  const { data, loading } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const connectedTerminal = data?.me.internalTerminal != null;

  if (loading) {
    return (
      <Box
        display="flex"
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return connectedTerminal ? (
    <TerminalPageContent user={data?.me} />
  ) : (
    <SelectTerminal />
  );
}
