export const COUNTRIES_MAP = {
  dk: 'Denmark',
  fi: 'Finland',
  no: 'Norway',
  se: 'Sweden',
  ax: 'Aaland Islands',
  at: 'Austria',
  br: 'Belgium',
  cz: 'Czech Republic',
  ee: 'Estonia',
  fo: 'Faroe Islands',
  fr: 'France',
  de: 'Germany',
  fl: 'Greenland',
  is: 'Iceland',
  it: 'Italy',
  lv: 'Latvia',
  lt: 'Lithuania',
  nl: 'Netherlands',
  pl: 'Poland',
  sk: 'Slovakia',
  uk: 'United Kingdom',
  ch: 'Switzerland',
  row: 'RestOftheWorld',
};

export const CURRENCIES = {
  dkk: 'Danish krone',
  sek: 'Swedish krona',
  nok: 'Norwegian krone',
  eur: 'Euro',
};

export const FUEL = {
  Diesel: 'Diesel',
  Gasoline: 'Gasoline',
  Electric: 'Electric',
  HVO: 'HVO',
  BIO: 'BIO',
  Train: 'Train',
};

export const TABLE_NAMES = {
  Checkpoints: 'checkpoints',
  Customers: 'customers',
  Deviations: 'deviations',
  RouteDeviations: 'routeDeviations',
  CustomerDeviations: 'customerDeviations',
  FixedRoutes: 'fixedRoutes',
  FixedTrafficOverview: 'fixedTrafficOverview',
  PeakPlanning: 'peakPlanning',
  PeakPlanningDebit: 'peakPlanningDebit',
  SporadicRoutes: 'sporadicRoutes',
  Subcontractors: 'subcontractors',
  Terminals: 'terminals',
  TourRouteTemplates: 'tourRouteTemplates',
  TourTemplateOverview: 'tourTemplateOverview',
  TourTemplates: 'tourTemplates',
  TruckFillAndTime: 'truckFillAndTime',
  TFTReadOnly: 'truckFillAndTimeReadOnly',
  ViewSporadicRouteTemplates: 'ViewSporadicRouteTemplates',
  OtherTours: 'OtherTours',
  RemainingGoods: 'RemainingGoods',
  RemainingGoodsReport: 'RemainingGoodsReport',
  RouteLegInReport: 'RouteLegInReport',
  ShipmentList: 'ShipmentList',
  MatchingShipment: 'MatchingShipment',
  ShipmentGrid: 'ShipmentGrid',
};
export const OVERRIDE_DEPARTMENT_HEADER = 'x-override-department';
