import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { printDateTimeLocale } from '../lib/format';
import { useHttpClient } from '../providers/HttpClientProvider';
import { DialogModal } from './DialogModal';

export interface FileItem {
  id: number;
  originalname: string;
  path: string;
  createdBy: string;
  createdAt: Date;
  tags?: {
    id: number;
    title: string;
  }[];
}

interface FileListProps {
  files: FileItem[];
  header?: string;
  handleDelete?: (item: FileItem) => void;
  handleFormShowClicked?: () => void;
  form?: boolean;
  showForm?: boolean;
  children?: React.ReactNode;
  readOnly?: boolean;
}

export function FileListComponent(props: FileListProps) {
  const {
    files,
    header,
    handleDelete,
    handleFormShowClicked,
    children,
    form = false,
    showForm = false,
    readOnly = false,
  } = props;
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<FileItem | null>(
    null,
  );

  const handleDownload = (f: { originalname: string; path: string }) => () => {
    httpClient.downloadFile(f, 'blob').then((response: AxiosResponse<Blob>) => {
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', f.originalname);
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Grid container>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent={header != null ? 'space-between' : 'flex-end'}
              width="100%"
              mt={1}
              mb={1}
            >
              {header && (
                <Typography variant="h3" align="left">
                  {header}
                </Typography>
              )}
              {handleFormShowClicked && !readOnly && (
                <Button
                  onClick={handleFormShowClicked}
                  disabled={showForm}
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                >
                  {t('button.add', { item: t('attributes.file') })}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <List dense>
            {files?.map((f) => (
              <ListItem key={f.id} divider>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <Grid container spacing={1}>
                  <Grid item>
                    <ListItemText primary={f.originalname} />
                    <ListItemText
                      secondary={`Uploaded by: ${
                        f.createdBy
                      }, Uploaded at: ${printDateTimeLocale(f.createdAt)}`}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container spacing={1}>
                      {(f.tags || []).map((t) => (
                        <Grid item>
                          <Chip
                            label={t.title}
                            variant="outlined"
                            color="primary"
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={handleDownload(f)}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                  {handleDelete && !readOnly && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setOpenDeleteDialog(f);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        {form && (
          <Grid item xs={12} md={12}>
            <Collapse in={showForm} timeout="auto" unmountOnExit>
              {children}
            </Collapse>
          </Grid>
        )}
      </Grid>
      {handleDelete && (
        <DialogModal
          open={openDeleteDialog != null}
          setOpen={() => setOpenDeleteDialog}
          contentText={t('validation.confirmation', {
            item: openDeleteDialog?.originalname,
            action: t('actions.delete.lowercased'),
          })}
          doAction={() => {
            if (openDeleteDialog) {
              handleDelete(openDeleteDialog);
              setOpenDeleteDialog(null);
            }
          }}
          closeAction={() => {
            setOpenDeleteDialog(null);
          }}
          buttonText={t('button.continue')}
        />
      )}
    </>
  );
}
