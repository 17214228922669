import { Path } from 'react-hook-form';
import { CommonTextFieldRHF } from './form/CommonTextFieldRHF';
import { SporadicRouteFormInput } from './RouteFormRHF';

interface TransportationDateOffsetFieldProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly: boolean;
  fullWidth?: boolean;
}

export function TransportationDateOffsetFieldRHF(
  props: TransportationDateOffsetFieldProps,
) {
  const { name, readOnly, fullWidth = false, controllerName } = props;
  return (
    <CommonTextFieldRHF
      name={name}
      controllerName={controllerName}
      size="small"
      readOnly={readOnly}
      type="number"
      fullWidth={fullWidth}
      variant="outlined"
      style={{
        fontSize: 14,
      }}
      InputProps={{
        style: {
          fontSize: 14,
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: 12,
        },
      }}
      inputProps={{
        min: -1,
        max: 20,
        style: {
          padding: '8, 0',
        },
      }}
    />
  );
}
