import { useUserConfiguration } from '../providers/UserConfigurationProvider';

interface NexusAccess {
  terminal: boolean;
  traffic: boolean;
  customsOfficer: boolean;
  department: string;
}

export function useAccess(): NexusAccess {
  const { hasTerminalAccess, hasTrafficAccess, hasCustomsAccess, department } =
    useUserConfiguration();

  return {
    terminal: hasTerminalAccess,
    traffic: hasTrafficAccess,
    customsOfficer: hasCustomsAccess,
    department: department.id,
  };
}
