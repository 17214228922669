import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';

function capitalizeURL(str: string) {
  return str.replace(/\b\w+/g, function (s: string) {
    return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
  });
}

export default function BreadCrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="Breadcrumb">
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        return index === pathnames.length - 1 ? (
          <Typography color="textPrimary" key={to}>
            {capitalizeURL(value)}
          </Typography>
        ) : (
          <Link color="inherit" component={RouterLink} to={to} key={to}>
            {capitalizeURL(value)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
