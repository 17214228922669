import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useGetResponsiblePersonQuery } from '../../generated/graphql';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiControl-root': {
      width: '100%',
    },
  },
}));

interface SelectSubcontractorProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
}

export function SelectResponsiblePerson(
  props: SelectSubcontractorProps & TextFieldProps,
) {
  const { name, readOnly = false, fullWidth = false } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { data } = useGetResponsiblePersonQuery({ fetchPolicy: 'no-cache' });
  const optionsData = data?.tourTemplatesWithResponsiblePerson;
  const [options, setOptions] = useState<string[]>([]);
  const [val, setVal] = useState<string | null>(null);

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const { getFieldProps } = useFormikContext();
  const newSetVal = getFieldProps('responsiblePerson').value;
  useEffect(() => {
    if (newSetVal !== null && newSetVal !== undefined) {
      setVal(newSetVal);
    }
  }, [newSetVal]);
  useEffect(() => {
    if (optionsData) {
      setOptions(optionsData);
    }
  });

  if (readOnly) {
    return (
      <TextField
        {...props}
        variant="outlined"
        label={t(`attributes.${label}`)}
        size="small"
        value={val}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Autocomplete
          className={classes.root}
          onBlur={field.onBlur}
          value={val}
          multiple={false}
          freeSolo
          fullWidth={fullWidth}
          onChange={(_event, value: string | null) => {
            form.setFieldValue(name, value ?? null);
            setVal(value);
          }}
          noOptionsText=""
          options={options}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size="small"
              onBlur={(e) => {
                form.setFieldValue(name, e.target.value ?? null);
              }}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              variant="outlined"
              label={t(`attributes.${label}`)}
              InputProps={{ ...params.InputProps }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    </Field>
  );
}
