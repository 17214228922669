import { LocationEntity, Maybe } from '../generated/graphql';

export function renderLocation(location?: Maybe<LocationEntity>) {
  if (location && location?.name === 'BLANK') {
    return '';
  } else if (location) {
    return `${location?.name} | ${location?.address} | ${
      location?.postalCode
    } | ${location?.city} | ${location?.country?.toUpperCase()}`;
  } else {
    return '';
  }
}
