import { Button, Radio } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import {
  GridCellParams,
  GridColDef,
  GridDensityTypes,
  GridRowParams,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PageviewIcon from '@material-ui/icons/Pageview';
import CheckIcon from '@mui/icons-material/Check';
import UnLockIcon from '@mui/icons-material/LockOpen';
import PingIcon from '@mui/icons-material/NotificationsActiveOutlined';
import {
  useGetLegsMatchingShipmentLazyQuery,
  usePingRouteLockUserLazyQuery,
  useUpdateLockMutation,
  useRouteLockSubSubscription,
} from '../../generated/graphql';
import { TABLE_NAMES } from '../../lib/constants';
import { UserConfiguredDataGridPro } from '../datagrid/UserConfiguredDataGridPro';
import { format } from 'date-fns';
import { DialogModal } from '../DialogModal';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface LinkShipmentModalProps {
  fromLocationId: number;
  toLocationId: number;
  transportDate: string;
  handleClose: () => void;
  open: boolean;
  onSelectConfirm: (tourId: number) => void;
}

let selectedRadio: GridSelectionModel = [0];

export function LinkShipmentModal(props: LinkShipmentModalProps) {
  const {
    handleClose,
    open,
    onSelectConfirm,
    fromLocationId,
    toLocationId,
    transportDate,
  } = props;

  const { t } = useTranslation();
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([0]);
  selectedRadio = selectionModel;

  const [updateLockMutation] = useUpdateLockMutation();
  const [pingToUnlockApi] = usePingRouteLockUserLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [openUnlockAnywayDialog, setOpenUnlockAnywayDialog] =
    useState<boolean>(false);
  const [dialogRouteId, setDialogRouteId] = useState<number>(0);
  const [selectedLegId, setSelectedLegId] = useState<number>(0);

  let availableRoutesIds: number[] = [];
  const [getMatchingLegsApi, { data, error, loading }] =
    useGetLegsMatchingShipmentLazyQuery({
      fetchPolicy: 'cache-and-network',
    });

  useEffect(() => {
    getMatchingLegsApi({
      variables: {
        fromLocationId,
        toLocationId,
        transportDate,
      },
    });
  }, []);

  const refetchList = useCallback(() => {
    getMatchingLegsApi({
      variables: {
        fromLocationId,
        toLocationId,
        transportDate,
      },
    });
  }, []);

  useRouteLockSubSubscription({
    variables: {
      routeId: 0,
    },
    async onSubscriptionData({ subscriptionData }) {
      if (subscriptionData.data && subscriptionData.data.routeLockSub) {
        if (
          availableRoutesIds.includes(
            subscriptionData.data.routeLockSub.routeId,
          )
        ) {
          refetchList();
        }
      }
    },
  });

  const unlockAnyway = (routeId: number) => {
    if (routeId !== 0) {
      updateLockMutation({
        variables: {
          routeId: routeId,
          lock: 0,
          reload: 1,
        },
      });
    }
  };

  const pingToUnlock = (routeId: number) => {
    pingToUnlockApi({
      variables: {
        routeId: routeId,
      },
    });
  };

  const gridColumns: GridColDef[] = [
    {
      field: 'radiobutton',
      headerName: 'Choose',
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const isLocked =
          params.row.route.routeLock != null &&
          params.row.route.routeLock.lockedBy != '';

        if (isLocked) return null;
        else
          return (
            <Radio
              checked={selectedRadio[0] === parseInt(params.row.id)}
              value={params.row.id}
              color={'primary'}
            />
          );
      },
    },
    {
      field: 'id',
      type: 'string',
      headerName: 'legID',
      minWidth: 100,
    },
    {
      field: 'legPos',
      type: 'string',
      headerName: 'legPosition',
      minWidth: 100,
      valueGetter({ row }) {
        return row.position;
      },
    },
    {
      field: 'routeName',
      type: 'string',
      headerName: 'RouteName',
      minWidth: 100,
      valueGetter({ row }) {
        return row.route.routeId;
      },
    },
    {
      field: 'routeType',
      type: 'string',
      headerName: 'RouteType',
      minWidth: 100,
      valueGetter({ row }) {
        return row.route.type;
      },
    },
    {
      field: '',
      headerName: 'View Route',
      minWidth: 100,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <Link
                to={`/terminal/routes/${params.row.route.id}-${params.row.arrivalTime}`}
                target={'_blank'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PageviewIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'routeLockedBy',
      type: 'string',
      headerName: 'LockedBy',
      minWidth: 100,
      valueGetter({ row }) {
        if (row.route.routeLock != null && row.route.routeLock.lockedBy != '')
          return row.route.routeLock.lockedBy;
        else return '';
      },
    },
    {
      field: 'routeLockedAt',
      type: 'string',
      headerName: 'LockedAt',
      minWidth: 100,
      valueFormatter({ value }) {
        if (value != '') return format(value as Date, 'dd-MM-yyyy HH:mm');
      },
      valueGetter({ row }) {
        if (row.route.routeLock != null && row.route.routeLock.lockedBy != '')
          return new Date(row.route.routeLock.lockedAt as string);
        else return '';
      },
    },
    {
      field: 'isChecklistLocked',
      headerName: 'ChecklistLocked',
      minWidth: 80,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        if (
          row.route.routeLock != null &&
          row.route.routeLock.lockedBy != '' &&
          row.route.routeLock.isChecklistLocked == true
        )
          return <CheckIcon />;
        else return null;
      },
    },
    {
      field: 'ping',
      headerName: 'Ping',
      minWidth: 80,
      disableColumnMenu: true,
      type: 'actions',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        if (
          row.route.routeLock == null ||
          row.route.routeLock.lockedBy == '' ||
          row.route.routeLock.isChecklistLocked == true
        )
          return null;
        else
          return (
            <Tooltip title={`${t('attributes.pingUser')}`}>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => {
                  pingToUnlock(row.route.id);
                }}
              >
                <PingIcon />
              </IconButton>
            </Tooltip>
          );
      },
    },
    {
      field: 'unlock',
      headerName: 'Unlock',
      minWidth: 80,
      disableColumnMenu: true,
      type: 'actions',
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        if (
          row.route.routeLock == null ||
          row.route.routeLock.lockedBy == '' ||
          row.route.routeLock.isChecklistLocked == true
        )
          return null;
        else
          return (
            <Tooltip title={`${t('attributes.unlockAnyway')}`}>
              <IconButton
                style={{ padding: 0 }}
                onClick={() => {
                  setDialogRouteId(row.route.id);
                  setOpenUnlockAnywayDialog(true);
                }}
              >
                <UnLockIcon />
              </IconButton>
            </Tooltip>
          );
      },
    },
  ];

  if (data && data.fetchLegsMatchingShipment) {
    availableRoutesIds = data.fetchLegsMatchingShipment.map(
      (sh) => sh.route.id,
    );
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        {error && <div>{error.message}</div>}

        {data && (
          <>
            <DialogModal
              open={openUnlockAnywayDialog}
              setOpen={setOpenUnlockAnywayDialog}
              contentText={String.raw`${t(
                'validation.unlockConfirmation',
              )}this route?  ${t('validation.unlockWarning')}`}
              doAction={() => {
                setOpenUnlockAnywayDialog(false);
                unlockAnyway(dialogRouteId);
              }}
              buttonText={t('button.yes')}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h6">
                {'Link LoadingItem - Choose one leg'}
              </Typography>

              <IconButton style={{ marginBottom: 2 }} onClick={handleClose}>
                <CloseIcon fontSize="large" color="action" />
              </IconButton>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems={'center'}
              width="60%"
            >
              <Typography style={{ fontSize: 13, color: 'orange' }}>
                {'You can not link - If the route is locked'}
              </Typography>
            </Box>

            <div style={{ width: '100%', marginBottom: 8 }}>
              <UserConfiguredDataGridPro
                tableName={TABLE_NAMES.MatchingShipment}
                rows={data.fetchLegsMatchingShipment}
                loading={loading}
                onSelectionModelChange={(sModel) => {
                  setSelectionModel(sModel);
                  setSelectedLegId(parseInt(sModel[0] as string));
                }}
                isRowSelectable={(params: GridRowParams) => {
                  if (
                    params.row.route.routeLock &&
                    params.row.route.routeLock.lockedBy != ''
                  ) {
                    return false;
                  }
                  return true;
                }}
                selectionModel={selectionModel}
                disableMultipleSelection
                columns={gridColumns}
                onFilterModelChange={() => {
                  setSelectionModel([0]);
                  setSelectedLegId(0);
                }}
                density={GridDensityTypes.Compact}
                autoHeight={true}
              />
            </div>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignSelf="flex-end | end"
              width="100%"
              mt={1}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isNaN(selectedLegId) || selectedLegId == 0}
                onClick={() => {
                  onSelectConfirm(selectedLegId);
                  handleClose();
                }}
              >
                {t('button.confirmAndLink')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
