import {
  Box,
  Dialog,
  IconButton,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as InfoIcon } from '../../svgIcons/infoicon.svg';
import { ReactComponent as ImportantIcon } from '../../svgIcons/important.svg';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  EditInternalTerminalReportInfoInput,
  GetInternalTerminalReportInfoDocument,
  InternalTerminalReportInfo,
  useDeleteInternalTerminalReportInfoMutation,
  useEditInternalTerminalReportInfoMutation,
  InfoType,
} from '../../generated/graphql';
import { DialogModal } from '../DialogModal';
import { useState } from 'react';

interface ReportToTerminalEditInfoModalProps {
  handleClose: () => void;
  open: boolean;
  internalTerminalReportInfo: Pick<
    InternalTerminalReportInfo,
    | 'id'
    | 'info'
    | 'infoType'
    | 'internalTerminalId'
    | 'dateForAddInfo'
    | 'isAddedFromTerminal'
  >;
  terminalName: string;
  dayForAddInfo: string;
}

export function ReportToTerminalEditInfoModal(
  props: ReportToTerminalEditInfoModalProps,
) {
  const {
    open,
    handleClose,
    internalTerminalReportInfo,
    terminalName,
    dayForAddInfo,
  } = props;
  const { t } = useTranslation();
  const {
    id: internalTerminalReportInfoId,
    info,
    infoType,
    internalTerminalId,
    dateForAddInfo,
    isAddedFromTerminal,
  } = internalTerminalReportInfo;
  const titleString =
    terminalName && dayForAddInfo && dateForAddInfo
      ? dayForAddInfo
          .concat(' ')
          .concat(dateForAddInfo)
          .concat(' | ')
          .concat(terminalName)
      : '';
  const theme = useTheme();
  const lessThan1400 = useMediaQuery(theme.breakpoints.between(0, 1400));
  const [editInternalTerminalReportInfoMutation] =
    useEditInternalTerminalReportInfoMutation({
      refetchQueries: [
        {
          query: GetInternalTerminalReportInfoDocument,
          variables: {
            internalTerminalId,
            dateForAddInfo,
            forTerminal: isAddedFromTerminal,
          },
        },
      ],
    });

  const [deleteInternalTerminalReportInfoMutation] =
    useDeleteInternalTerminalReportInfoMutation({
      refetchQueries: [
        {
          query: GetInternalTerminalReportInfoDocument,
          variables: {
            internalTerminalId,
            dateForAddInfo,
            forTerminal: isAddedFromTerminal,
          },
        },
      ],
    });
  const onDelete = (internalTerminalReportInfoId: number) => {
    deleteInternalTerminalReportInfoMutation({
      variables: {
        internalTerminalReportInfoId,
      },
    });
    handleClose();
  };
  const [openDeleteDialog, setOpenDeleteDialog] = useState<{
    edit: EditInternalTerminalReportInfoInput;
    name: string;
  } | null>(null);
  if (!internalTerminalReportInfo) {
    return <></>;
  }
  return (
    <Box>
      <Dialog
        maxWidth={lessThan1400 ? 'sm' : 'md'}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <Formik
          initialValues={{
            internalTerminalReportInfoId,
            info,
            infoType,
            internalTerminalId,
            dateForAddInfo,
          }}
          onSubmit={async (values: EditInternalTerminalReportInfoInput) => {
            editInternalTerminalReportInfoMutation({
              variables: {
                input: {
                  internalTerminalReportInfoId:
                    values.internalTerminalReportInfoId,
                  info: values.info,
                  infoType: values.infoType,
                },
              },
            });
            handleClose();
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <DialogTitle>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  mb={2}
                >
                  <Typography variant="body1">
                    <strong>{`${t('actions.editItem', {
                      item: t('attributes.info'),
                    })}`}</strong>
                  </Typography>
                  <Typography variant="body1">{titleString}</Typography>
                  <IconButton style={{ padding: '0px' }} onClick={handleClose}>
                    <CloseIcon fontSize="medium" color="action" />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingBottom: '1%',
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.type')}
                  </Typography>
                  <Select
                    variant="standard"
                    name="infoType"
                    id="infoType"
                    autoWidth={true}
                    defaultValue={props.values.infoType}
                    value={props.values.infoType}
                    onChange={props.handleChange}
                  >
                    <MenuItem value={InfoType.Important}>
                      <ListItemIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          fontSize: '1.5em',
                        }}
                      >
                        <ImportantIcon fill="#0096FF" />
                      </ListItemIcon>
                    </MenuItem>
                    <MenuItem value={InfoType.Info}>
                      <ListItemIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          fontSize: '1.5em',
                        }}
                      >
                        <InfoIcon fill="#0096FF" fill-rule="nonzero" />
                      </ListItemIcon>
                    </MenuItem>
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '2%',
                    marginTop: '1%',
                  }}
                >
                  <TextField
                    label="Edit Info"
                    id="info"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={props.values.info}
                    onChange={props.handleChange}
                  />
                </Box>
              </DialogContent>
              <DialogActions
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  paddingBottom: '1%',
                }}
              >
                <Box>
                  <Button type="submit" variant="contained" color="success">
                    {t('button.update')}
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      setOpenDeleteDialog({
                        edit: props.values,
                        name: terminalName,
                      });
                    }}
                    variant="contained"
                    color="warning"
                  >
                    {t('button.delete')}
                  </Button>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      {openDeleteDialog && (
        <DialogModal
          open={openDeleteDialog != null}
          setOpen={() => setOpenDeleteDialog}
          contentText={t('validation.confirmation', {
            item: `Info of Type:${openDeleteDialog?.edit.infoType} at ${openDeleteDialog?.name}`,
            action: t('actions.delete.lowercased'),
          })}
          doAction={() => {
            if (
              openDeleteDialog &&
              openDeleteDialog.edit?.internalTerminalReportInfoId
            ) {
              onDelete(openDeleteDialog.edit.internalTerminalReportInfoId);
              setOpenDeleteDialog(null);
            }
          }}
          closeAction={() => {
            setOpenDeleteDialog(null);
          }}
          buttonText={t('button.continue')}
        />
      )}
    </Box>
  );
}
