import { Box, Button, Grid, makeStyles, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { GridSortModel } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  CardType,
  useGetTerminalsLazyQuery,
  useImportTerminalsMutation,
} from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { cardColumns } from '../utils/CardColumns';
import { LoadingAndError } from '../utils/LoadingAndError';
import { locationString } from '../utils/LocationString';
import ImportIcon from '@mui/icons-material/DocumentScanner';
import {
  EntityCardColumns,
  ImportCardModal,
} from '../components/modal/ImportCardModal';

export const gridStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': {
      backgroundColor: ' #fff !important',
      color: `${theme.palette.primary.main} !important`,
    },
    cursor: 'pointer',
  },
}));

interface TerminalProps {
  searchText?: string;
}

export function Terminals(props: TerminalProps) {
  const { searchText = '' } = props;
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [showImportLoading, setShowImportLoading] = useState<boolean>(false);

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [isAlertSuccess, setAlertSuccess] = useState<boolean>(true);

  const [importTerminals, {}] = useImportTerminalsMutation();

  const [getTerminals, { data, loading, error }] = useGetTerminalsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  useEffect(() => {
    getTerminals({
      variables: { search: searchText },
    });
  }, [searchText]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'locations',
      sort: 'asc',
    },
  ]);

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      if (newSortModel && !_.isEqual(newSortModel, sortModel)) {
        setSortModel(newSortModel);
      }
    },
    [sortModel],
  );

  return (
    <LoadingAndError loading={loading} error={error} data={data?.terminals}>
      {({ loadedData }) => (
        <Grid container>
          <HelmetComponent title={t('resource.terminal.plural')} />
          <div>
            <Snackbar
              open={showAlert}
              key={'alert'}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              autoHideDuration={isAlertSuccess ? 5000 : null}
              onClose={() => {
                setShowAlert(false);
              }}
            >
              <Alert
                onClose={() => {
                  setShowAlert(false);
                }}
                severity={isAlertSuccess ? 'success' : 'error'}
              >
                {`${alertMessage}`}
              </Alert>
            </Snackbar>
          </div>
          {showImportModal ? (
            <ImportCardModal
              open={showImportModal}
              handleClose={() => {
                setShowImportModal(false);
              }}
              cardType={CardType.Terminal}
              showImportLoading={showImportLoading}
              getDataArray={(arr: EntityCardColumns[]) => {
                if (loadedData.length > 0) {
                  //check for duplicate rec
                  const excludeExistingIndex: number[] = [];
                  arr.forEach((arrRec, i) => {
                    const objArrWithSameName = loadedData.filter(
                      (existingCust) =>
                        arrRec.CardName.trim().toUpperCase() ===
                        existingCust.name.trim().toUpperCase(),
                    );
                    if (objArrWithSameName && objArrWithSameName.length > 0) {
                      objArrWithSameName.forEach((existingData) => {
                        if (
                          existingData.locations &&
                          existingData.locations.length > 0
                        ) {
                          existingData.locations.forEach((loc) => {
                            if (
                              loc.name === arrRec.LocationName &&
                              loc.address === arrRec.Address &&
                              loc.city === arrRec.City &&
                              loc.postalCode === arrRec.PostalCode &&
                              loc.country === arrRec.CountryCode
                            ) {
                              //same name and loc at this index
                              excludeExistingIndex.push(i);
                            }
                          });
                        }
                      });
                    }
                  });

                  //Remove duplicate entry from excel arr
                  if (excludeExistingIndex.length > 0) {
                    const uniqArr = _.uniq(excludeExistingIndex);
                    uniqArr.forEach((index, i) => {
                      arr.splice(index - i, 1);
                    });
                  }

                  if (arr.length > 0) {
                    //Start import
                    setShowImportLoading(true);
                    importTerminals({ variables: { input: arr } })
                      .then((res) => {
                        setShowImportLoading(false);

                        const count = res.data?.importTerminals
                          ? res.data?.importTerminals.length
                          : 0;
                        setAlertMessage(
                          `${t('validation.alertImportSuccess', {
                            count: count,
                            type: 'terminals',
                          })}`,
                        );
                        setAlertSuccess(true);
                        setShowAlert(true);
                        setShowImportModal(false);

                        getTerminals({
                          variables: { search: searchText },
                        });
                      })
                      .catch((err) => {
                        setAlertMessage(`${t('validation.importFailed')}`);
                        setAlertSuccess(false);
                        setShowImportModal(false);
                        setShowAlert(true);
                        console.error('Import terminals api err=', err);
                      });
                  } else {
                    setAlertMessage(`${t('validation.noDataInExcelImport')}`);
                    setAlertSuccess(false);
                    setShowImportModal(false);
                    setShowAlert(true);
                  }
                }
              }}
            />
          ) : null}
          <Grid container style={{ marginBottom: 4 }}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width="100%"
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ImportIcon />}
                  onClick={() => setShowImportModal(true)}
                >
                  {'Import'}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: '20px' }}
                  component={Link}
                  to={`${path}terminals/create`}
                  startIcon={<AddIcon />}
                >
                  {t('button.create', {
                    item: t('resource.terminal.lowercased'),
                  })}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <div style={{ height: '100%', width: '100%' }}>
            <UserConfiguredDataGridPro
              tableName={TABLE_NAMES.Terminals}
              rows={
                loadedData.map((c) => ({
                  ...c,
                  locations: c.locations
                    .map((l) => locationString(l))
                    .join(', '),
                })) || []
              }
              columns={cardColumns({ t, path: `${path}terminals` })}
              loading={loading}
              pageSize={5}
              rowHeight={50}
              autoHeight={true}
              pagination={true}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
            />
          </div>
        </Grid>
      )}
    </LoadingAndError>
  );
}
