import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

export type UserRole = 'Terminal.User' | 'Traffic.User';

type ExtendedAccountInfo = AccountInfo & {
  idTokenClaims: { roles: UserRole[] };
};

function isExtendedAccountInfo(
  account: AccountInfo,
): account is ExtendedAccountInfo {
  if (account && account.idTokenClaims !== null) {
    return true;
  }

  return false;
}

export function useRoles(): UserRole[] {
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const account = accounts[0];
  if (isExtendedAccountInfo(account)) {
    return account.idTokenClaims.roles || [];
  }

  return [];
}

export function getUserName(): string {
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const account = accounts[0];
  let firstName = '';
  let lastName = '';
  if (isExtendedAccountInfo(account)) {
    if (account.name) {
      lastName = account.name?.split(' ')[0];
      firstName = account.name?.split(' ')[1];
    }
    return `${firstName} ${lastName}` || '';
  }

  return '';
}

export function getUserInitials(): string {
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const account = accounts[0];
  let firstName = '';
  let lastName = '';
  if (isExtendedAccountInfo(account)) {
    if (account.name) {
      lastName = account.name?.split(' ')[0] || '';
      firstName = account.name?.split(' ')[1] || '';
    }
    return `${firstName[0]}${lastName[0]}` || '';
  }

  return '';
}

export function logOutUser(): void {
  const { instance } = useMsal();
  instance.logoutRedirect();
}
