import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { TFunction, useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import BreadCrumbs from '../components/BreadCrumbs';
import { Layout } from '../components/Layout';
import { useAccess } from '../lib/useAccess';
import { Button, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import AccessDeniedIcon from '@mui/icons-material/DoNotDisturb';
import { Shipment } from './Shipment';
import { ShipmentLoadingList } from '../components/datagrid/ShipmentLoadingListGrid';

const links = (t: TFunction<'translation'>) => {
  return [
    {
      text: t('pages.dashboard.title'),
      path: '/customs',
      Icon: HomeIcon,
    },
    {
      text: `Shipments`,
      path: '/customs/shipment',
      Icon: LocalShippingIcon,
    },
  ];
};

export function CustomsPage() {
  const { path } = useRouteMatch();
  const { customsOfficer } = useAccess();
  const { t } = useTranslation();
  const router = useHistory();
  if (!customsOfficer) {
    return (
      <Stack alignItems={'center'}>
        <div
          style={{
            maxHeight: 250,
            minWidth: 300,
            border: '1px solid red',
            alignItems: 'center',
            marginTop: 20,
            padding: 10,
          }}
        >
          <AccessDeniedIcon style={{ minHeight: 50, minWidth: 50 }} />
          <Typography style={{ margin: 10, fontWeight: 'bold' }}>
            'No Customs page access!'
          </Typography>
          <Button
            onClick={() => {
              router.push(`${path.split('/')[0]}`);
            }}
            variant="contained"
            color="primary"
          >
            {'Go to home'}
          </Button>
        </div>
      </Stack>
    );
  }
  return (
    <Layout pageName="Customs" drawerLinks={links(t)}>
      <BreadCrumbs />
      <Switch>
        <Route path={`${path}/shipment`} exact>
          <Shipment from="Custom" />
        </Route>
        <Route path={`${path}/shipment/:id`} exact>
          <ShipmentLoadingList updatedFrom="Custom" />
        </Route>
      </Switch>
    </Layout>
  );
}
