import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Box, FormLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CheckboxField } from '../CheckboxField';

interface DaysFieldsProps {
  readOnly: boolean;
  index: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
  },
  componentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
  },
  daysWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
    },
    '& > *': {
      marginRight: theme.spacing(2),
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
  formLabel: {
    fontSize: 14,
  },
}));

export function DaysFields({ readOnly, index }: DaysFieldsProps) {
  const { t } = useTranslation();
  const { getFieldMeta } = useFormikContext();
  const touched = getFieldMeta(`routes.${index}.days`).touched;
  const error = getFieldMeta(`routes.${index}.days`).error;
  const classes = useStyles();

  return (
    <Box className={classes.fullWidth}>
      <Box className={classes.componentWrapper}>
        <Typography variant="h3" align="left">
          {t('attributes.days')}
        </Typography>
        <Box className={classes.daysWrapper}>
          <CheckboxField
            name={`routes.${index}.days.mon`}
            label={t('weekDays.mon')}
            readOnly={readOnly}
            size="small"
          />
          <CheckboxField
            name={`routes.${index}.days.tue`}
            label={t('weekDays.tue')}
            readOnly={readOnly}
            size="small"
          />
          <CheckboxField
            name={`routes.${index}.days.wed`}
            label={t('weekDays.wed')}
            readOnly={readOnly}
            size="small"
          />
          <CheckboxField
            name={`routes.${index}.days.thu`}
            label={t('weekDays.thu')}
            readOnly={readOnly}
            size="small"
          />
          <CheckboxField
            name={`routes.${index}.days.fri`}
            label={t('weekDays.fri')}
            readOnly={readOnly}
            size="small"
          />
          <CheckboxField
            name={`routes.${index}.days.sat`}
            label={t('weekDays.sat')}
            readOnly={readOnly}
            size="small"
          />
          <CheckboxField
            name={`routes.${index}.days.sun`}
            label={t('weekDays.sun')}
            readOnly={readOnly}
            size="small"
          />
        </Box>
        <FormLabel style={{ fontSize: 14 }} error={true}>
          {touched && error}
        </FormLabel>
      </Box>
    </Box>
  );
}
