import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  PickersDay,
  pickersDayClasses,
  PickersDayProps,
} from '@mui/x-date-pickers/PickersDay';
import startOfDay from 'date-fns/startOfDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { Box, FormLabel, Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, useFormikContext } from 'formik';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus, &:checked,': {
      backgroundColor: '#69bc48',
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

export default function DateSelectorInTour(props: {
  startDate: Date;
  endDate: Date | undefined;
  readOnly: boolean;
  index: number;
  forException: boolean;
}) {
  const [dateStr, setDateStr] = React.useState<string[]>([]);
  const { startDate, endDate, readOnly, index, forException } = props;
  const { setFieldValue, getFieldProps } = useFormikContext();

  const exceptionDates =
    index === -1
      ? getFieldProps(`exceptionDates`).value
      : getFieldProps(`routes.${index}.exceptionDates`).value;
  const arrExceptionDates = exceptionDates as string[];

  const pathString =
    index === -1 ? `exceptionDates` : `routes.${index}.exceptionDates`;
  const [values, setValues] = React.useState<Date[]>(
    arrExceptionDates
      ? arrExceptionDates.map((sd) => new Date(new Date(sd).setHours(0, 0, 0)))
      : [],
  );
  const { t } = useTranslation();

  React.useEffect(() => {
    if (values) {
      //sort and format all
      const sortedDates = values.sort((a, b) => a.getTime() - b.getTime());
      const arrStrDates: string[] = [];
      sortedDates.forEach((d) => {
        arrStrDates.push(format(d, 'yyyy-MM-dd'));
      });
      setFieldValue(pathString, arrStrDates);
      setDateStr(arrStrDates);
    } else {
      setFieldValue(pathString, []);
      setDateStr([]);
    }
  }, [values]);

  const findDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates.find((item) => item.getTime() === dateTime);
  };

  const findIndexDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const renderPickerDay = React.useCallback(
    (
      date: Date,
      selectedDates: Date[],
      pickersDayProps: PickersDayProps<any>,
    ) => {
      if (!values) {
        return <PickersDay {...pickersDayProps} />;
      }

      const selected = findDate(values, date);
      return (
        <CustomPickersDay
          {...pickersDayProps}
          sx={{
            [`&&.${pickersDayClasses.selected}`]: {
              backgroundColor: 'green',
            },
          }}
          disableMargin
          selected={selected !== undefined}
        />
      );
    },
    [values],
  );

  return (
    <Grid item container alignItems="flex-start" direction="column">
      {dateStr.length > 0 ? (
        <Grid item xs={12}>
          <Box
            style={{
              backgroundColor: '#ebebed',
              width: '100%',
              marginTop: 20,
              padding: 6,
            }}
          >
            <Typography style={{ fontSize: 13 }} align="left">
              {forException
                ? `${t('attributes.selectedExcDates')}`
                : `${t('attributes.selectedDates')}`}
            </Typography>

            <Typography
              style={{ fontSize: 13, lineHeight: 2 }}
              align="left"
            >{`${dateStr.join(' | ')}`}</Typography>
          </Box>
        </Grid>
      ) : (
        <ErrorMessage
          name={pathString}
          render={(msg) => (
            <div>
              <FormLabel
                style={{ fontSize: 14, textAlign: 'left' }}
                error={true}
              >
                {msg}
              </FormLabel>
            </div>
          )}
        />
      )}

      {readOnly == false ? (
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              componentsProps={{
                actionBar: {
                  actions: ['clear'],
                },
              }}
              displayStaticWrapperAs="desktop"
              label="Multiple Days"
              maxDate={endDate}
              minDate={startDate}
              value={values}
              readOnly={readOnly}
              onChange={(newValue) => {
                //copying the values array

                if (newValue == null) {
                  setValues([]);
                  return;
                }
                const array = [...values];
                const date = startOfDay(
                  newValue === null ? new Date() : newValue,
                );
                const index = findIndexDate(array, date);
                if (index >= 0) {
                  array.splice(index, 1);
                } else {
                  array.push(date);
                }
                setValues(array);
              }}
              renderDay={renderPickerDay}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="'Week of' MMM d"
            />
          </LocalizationProvider>
        </Grid>
      ) : null}
    </Grid>
  );
}
