export function updateRouteNameToValidName(routeId: string): string {
  const maxLength = 31;
  let safeName: string = routeId;
  if (routeId.length > maxLength) {
    safeName = routeId.substring(0, maxLength);
  }
  return safeName
    .replace(/['".*:\\n<>+?^${}()|[\]\\\/]/g, '')
    .replace(/  +/g, ' ');
}
