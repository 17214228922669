import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { IPublicClientApplication } from '@azure/msal-browser';
import { configuration } from '../../configuration';
import { getAccessToken } from '../azure_ad';
import { COUNTRIES_MAP } from '../constants';

const httpLink = (headers: { [key: string]: string } = {}) =>
  createHttpLink({
    uri: '/api/graphql',
    headers,
  });

const { host } = document.location;
const scheme = configuration.production ? 'wss' : 'ws';
const wsLink = new WebSocketLink({
  uri: `${scheme}://${host}/api/graphql`,
  options: {
    reconnect: true,
  },
});

const splitLink = (headers: { [key: string]: string } = {}) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink(headers),
  );

export const authLink = (instance: IPublicClientApplication) =>
  setContext(async (_, { headers }) => {
    const accessToken = await getAccessToken(instance);

    if (accessToken != null) {
      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      };
    }
  });

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        holidays: {
          merge: false,
        },
        tourTemplates: {
          merge: false,
        },
      },
    },
    LocationEntity: {
      fields: {
        countryLong: {
          read(_, { readField }) {
            const country = readField('country') as keyof typeof COUNTRIES_MAP;
            return COUNTRIES_MAP[country] || country;
          },
        },
      },
    },
  },
});

export function buildNewLinkWithHeaders(
  instance: IPublicClientApplication,
  headers: { [key: string]: string } = {},
) {
  return authLink(instance).concat(splitLink(headers));
}

export const apolloClient = (instance: IPublicClientApplication) =>
  new ApolloClient({
    link: buildNewLinkWithHeaders(instance),
    cache,
  });
