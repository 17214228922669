import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import React, { ReactElement } from 'react';
import QRCode from 'react-qr-code';
import QrCode2Icon from '@mui/icons-material/QrCode2';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface qrModalProp {
  id: string;
  routeName: string;
  routeId: number;
  Icon: ReactElement;
}

export function QRModal(props: qrModalProp) {
  const [open, setOpen] = React.useState(false);
  const { id, routeName, routeId, Icon } = props;

  return (
    <div>
      <Button onClick={() => setOpen(true)}>
        {Icon}
        <QrCode2Icon />
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              Route Name: {routeName}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 1 }}>
              Route ID: {routeId}
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {id}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <QRCode
              size={512}
              style={{ height: '200px', width: '200px' }}
              value={id}
              viewBox={`0 0 256 256`}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
