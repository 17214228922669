import { debounce, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SearchCustomersQuery,
  useGetCustomerLazyQuery,
  useSearchCustomersLazyQuery,
} from '../../generated/graphql';
import {
  useFormContext,
  Controller,
  Path,
  useWatch,
  useFormState,
} from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

interface SelectCustomer {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
}

export function SelectCustomerRHF(props: SelectCustomer & TextFieldProps) {
  const { name, readOnly = false, controllerName } = props;
  const { t } = useTranslation();
  const { control, getFieldState } = useFormContext<SporadicRouteFormInput>();
  const { errors } = useFormState<SporadicRouteFormInput>();

  const isErrorForField = errors && getFieldState(controllerName).error;
  const errorMessage = isErrorForField && isErrorForField?.message;

  const [options, setOptions] = useState<SearchCustomersQuery['customers']>([]);
  const [value, setValue] = useState<
    SearchCustomersQuery['customers'][number] | null
  >(null);
  const [search, setSearch] = useState('');
  const [searchCustomers, { data: optionsData, loading: loadingOptions }] =
    useSearchCustomersLazyQuery();
  const [getCustomer, { data: customerData, loading }] =
    useGetCustomerLazyQuery();

  const initialValue = useWatch({
    control,
    name: 'ftl.customerId',
  });

  useEffect(() => {
    if (initialValue !== undefined && initialValue != null) {
      getCustomer({ variables: { id: initialValue } });
    }
  }, [initialValue]);

  useEffect(() => {
    if (customerData) {
      setOptions([customerData.customer]);
      setValue(customerData.customer);
    }
  }, [customerData]);

  useEffect(() => {
    if (search.length > 2) {
      searchCustomers({
        variables: {
          customersSearch: search,
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.customers]);
      } else {
        setOptions(optionsData.customers);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];

  if (readOnly) {
    return (
      <TextField
        {...props}
        variant="outlined"
        label={t(`attributes.${label}`)}
        value={value?.name}
        size="small"
        fullWidth={props.fullWidth}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          fullWidth={props.fullWidth}
          value={value}
          multiple={false}
          autoHighlight
          onChange={(_event, val) => {
            onChange(val?.id);
            if (!val || !val.id) {
              setOptions([]);
              setValue(null);
            }
          }}
          onInputChange={debouncedChangeHandler}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={options}
          loading={loading || loadingOptions}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size="small"
              error={Boolean(isErrorForField)}
              helperText={
                (isErrorForField && errorMessage) || Boolean(isErrorForField)
              }
              InputProps={{ ...params.InputProps }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        />
      )}
    />
  );
}
