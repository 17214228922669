import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useFormContext, Controller, Path } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

interface CommonTextFieldProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  labelText?: string;
}

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  smallFontSize: {
    '& input': {
      fontSize: 12,
    },
    '&.MuiOutlinedInput-multiline': {
      fontSize: 12,
    },
  },
  smallPadding: {
    '& input': {
      padding: 4,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 4,
    },
  },
}));

export function CommonTextFieldRHF(
  props: CommonTextFieldProps & TextFieldProps,
) {
  const { t } = useTranslation();
  const { control } = useFormContext<SporadicRouteFormInput>();

  const {
    name,
    readOnly = false,
    fullWidth = false,
    InputProps = {},
    size = 'small',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    labelText = '',
    controllerName,
  } = props;

  const classes = useStyles();
  const nameSplit = name.split('.');
  const label = labelText === '' ? nameSplit[nameSplit.length - 1] : labelText;

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...props}
          {...field}
          id={name}
          size={size}
          variant="outlined"
          placeholder={noLabel ? undefined : t(`attributes.${label}`)}
          label={noLabel ? undefined : t(`attributes.${label}`)}
          error={fieldState.error && Boolean(fieldState.error)}
          helperText={
            fieldState.error &&
            Boolean(fieldState.error) &&
            fieldState.error?.message
          }
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          fullWidth={fullWidth}
          InputProps={{
            className: clsx([
              classes.input,
              {
                [classes.smallFontSize]: smallFontSize,
                [classes.smallPadding]: smallPadding,
              },
            ]),
            readOnly,
            ...InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
}
