import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GridOnIcon from '@material-ui/icons/GridOn';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReportIcon from '@mui/icons-material/Task';
import InfoIcon from '@material-ui/icons/Info';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ImportShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import BreadCrumbs from '../components/BreadCrumbs';
import { Layout } from '../components/Layout';
import { PdfWrapper } from '../pdf/PdfWrapper';
import { FixedTrafficOverview } from './FixedTrafficOverview';
import { ManualCMR } from './ManualCMR';
import { SelectTerminal } from './SelectTerminal';
import { TruckFillAndTime } from './TruckFillAndTime';
import { ViewCustomer } from './ViewCustomer';
import { ViewSubcontractor } from './ViewSubcontractor';
import { ViewTerminal } from './ViewTerminal';
import { ViewCheckpoint } from './ViewCheckpoint';
import { ViewTourTemplate } from './ViewTourTemplate';
import { ViewReportFromTerminal } from './ViewReportFromTerminal';
import { RemainingGoodsInTerminal } from './RemainingGoodsInTerminal';
import { ReportToTrafficInTerminal } from './ReportToTrafficInTerminal';
import { TrafficDashboard } from './TrafficDashboard';
import { Shipment } from './Shipment';
import { useAccess } from '../lib/useAccess';
import { Button, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import AccessDeniedIcon from '@mui/icons-material/DoNotDisturb';
import { ImportShipment } from './ImportShipment';
import { ShipmentLoadingList } from '../components/datagrid/ShipmentLoadingListGrid';
import { CreateSporadicRouteRHF } from './CreateSporadicRouteRHF';
import { ViewRouteRHF } from './ViewRouteRHF';

const links = [
  {
    text: `Truck fill and time`,
    path: '/terminal',
    Icon: LocalShippingIcon,
  },
  {
    text: `Information from Traffic`,
    path: '/terminal/info-from-traffic',
    Icon: InfoIcon,
  },
  {
    text: 'Shipment',
    path: '/terminal/shipment',
    Icon: LocalShippingIcon,
  },
  {
    text: `Import Shipments`,
    path: '/terminal/import-shipments',
    Icon: ImportShippingIcon,
  },
  {
    text: `Fixed Traffic Overview`,
    path: '/terminal/fixed-traffic-overview',
    Icon: CalendarTodayIcon,
  },
  {
    text: 'CMR',
    path: '/terminal/cmr',
    Icon: GridOnIcon,
  },
  {
    text: 'Remaining Goods',
    path: '/terminal/remaining-goods',
  },
  {
    text: 'Report to traffic',
    path: '/terminal/report-to-traffic',
    Icon: SummarizeIcon,
  },
  {
    text: 'Production Report',
    path: '/terminal/traffic-production-report',
    Icon: ReportIcon,
  },
];

interface TerminalPageContentProps {
  user?: {
    internalTerminal?: Maybe<{
      id: number;
      name: string;
      terminal: {
        locations: Array<{ id: number; country: string }>;
      };
    }>;
  };
}

export function TerminalPageContent(props: TerminalPageContentProps) {
  const { user } = props;
  const { t } = useTranslation();
  const { terminal } = useAccess();
  const { path } = useRouteMatch();
  const router = useHistory();

  if (!terminal) {
    return (
      <Stack alignItems={'center'}>
        <div
          style={{
            maxHeight: 250,
            minWidth: 300,
            border: '1px solid red',
            alignItems: 'center',
            marginTop: 20,
            padding: 10,
          }}
        >
          <AccessDeniedIcon style={{ minHeight: 50, minWidth: 50 }} />
          <Typography style={{ margin: 10, fontWeight: 'bold' }}>
            'No Terminal page access!'
          </Typography>
          <Button
            onClick={() => {
              router.push(`${path.split('/')[0]}`);
            }}
            variant="contained"
            color="primary"
          >
            {'Go to home'}
          </Button>
        </div>
      </Stack>
    );
  }
  return (
    <Layout drawerLinks={links} pageName={t('pages.terminal.title')}>
      <BreadCrumbs />
      <Switch>
        {user && user.internalTerminal != null && (
          <>
            <Route path={`${path}/`} exact>
              <TruckFillAndTime terminal={user?.internalTerminal} />
            </Route>
            <Route path={`${path}/info-from-traffic`} exact>
              <ViewReportFromTerminal
                terminal={{
                  id: user.internalTerminal.id,
                  name: user.internalTerminal.name,
                  shortCountryCode:
                    user.internalTerminal.terminal.locations.length > 0
                      ? user.internalTerminal.terminal.locations[0].country
                      : '',
                }}
              />
            </Route>
            <Route path={`${path}/shipment`} exact>
              <Shipment from="Terminal" />
            </Route>
            <Route path={`${path}/shipment/:id`} exact>
              <ShipmentLoadingList updatedFrom="terminal" />
            </Route>
            <Route path={`${path}/import-shipments`}>
              <ImportShipment
                from="Terminal"
                terminal={{
                  id: user.internalTerminal.id,
                  name: user.internalTerminal.name,
                  locationId:
                    user.internalTerminal.terminal.locations.length > 0
                      ? user.internalTerminal.terminal.locations[0].id
                      : -1,
                  country:
                    user.internalTerminal.terminal.locations.length > 0
                      ? user.internalTerminal.terminal.locations[0].country
                      : '-',
                }}
              />
            </Route>
            <Route path={`${path}/fixed-traffic-overview`} exact>
              <FixedTrafficOverview terminal={user?.internalTerminal} />
            </Route>
            {/* CMR */}
            <Route path={`${path}/cmr`}>
              <ManualCMR />
            </Route>
            {/* Remaining goods */}
            <Route path={`${path}/remaining-goods`} exact>
              <RemainingGoodsInTerminal terminal={user?.internalTerminal} />
            </Route>
            {/* Report Export */}
            {/* Hiding this feature by hiding its icon in UI 
            <Route path={`${path}/production-report`} exact>
              <ExportReportInTerminal terminal={user?.internalTerminal} />
            </Route>*/}

            {/* Report - TrafficDashboard */}
            <Route path={`${path}/traffic-production-report`} exact>
              <TrafficDashboard
                isInTerminal={true}
                terminal={user?.internalTerminal}
              />
            </Route>
            {/* ReportToTraffic */}
            <Route path={`${path}/report-to-traffic`} exact>
              <ReportToTrafficInTerminal terminal={user?.internalTerminal} />
            </Route>
            <Route path={`${path}/change-terminal`}>
              <SelectTerminal />
            </Route>
            <Route exact path={`${path}/routes/:id`}>
              <ViewRouteRHF terminal />
            </Route>
            <Route exact path={`${path}/routes/:id/pdf`}>
              <PdfWrapper />
            </Route>
            <Route exact path={`${path}/tours/:id`}>
              <ViewTourTemplate terminal />
            </Route>
            <Route exact path={`${path}/cards/terminals/:id`}>
              <ViewTerminal terminal />
            </Route>
            <Route exact path={`${path}/cards/customers/:id`}>
              <ViewCustomer terminal />
            </Route>
            <Route exact path={`${path}/cards/subcontractors/:id`}>
              <ViewSubcontractor terminal />
            </Route>
            <Route exact path={`${path}/cards/checkpoints/:id`}>
              <ViewCheckpoint terminal />
            </Route>
            <Route exact path={`${path}/create/sporadic-route`}>
              <CreateSporadicRouteRHF terminal />
            </Route>
          </>
        )}
      </Switch>
    </Layout>
  );
}
