import { Box, createStyles, Link, makeStyles } from '@material-ui/core';
import {
  GridCellParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../App.css';
import { COUNTRIES_MAP, TABLE_NAMES } from '../lib/constants';
import { timeOrNull } from '../lib/date_time';
import { UserConfiguredDataGridPro } from './datagrid/UserConfiguredDataGridPro';
import { ListIncomingRoutesLegs } from '../views/TrafficTFT';
import { TrueOrFalseCellWithNoClearIcon } from './datagrid/TrueOrFalseCellWithNoClearIcon';

const useStylesIncomingRoutes = makeStyles(() =>
  createStyles({
    editableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      '& p:first-child': {
        maxWidth: '75%',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        inlineSize: '75%',
      },
    },
    muiOdd: {
      backgroundColor: '#e7e7e7',
    },
    muiEven: {
      backgroundColor: '#fff',
    },
  }),
);

interface ListIncomingRoutesProps {
  shortCountryCode: string;
  legs?: ListIncomingRoutesLegs[];
  onGetTFTExcelClicked: () => void;
}

export function ReportToTerminalSporadicRoutes(props: ListIncomingRoutesProps) {
  const classes = useStylesIncomingRoutes();
  const apiRef = useGridApiRef();
  const { t } = useTranslation();

  const { legs, shortCountryCode, onGetTFTExcelClicked } = props;
  const rows = buildRows(legs, shortCountryCode);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'arrivalTime',
      sort: 'asc',
    },
  ]);

  const [dataGrid, setDataGrid] = useState(rows ?? []);

  useEffect(() => {
    setDataGrid(rows);
  }, [legs]);

  return (
    <>
      <Box style={dataGrid.length > 5 ? { height: 500 } : { height: 300 }}>
        <UserConfiguredDataGridPro
          tableName={TABLE_NAMES.TFTReadOnly}
          apiRef={apiRef}
          columnBuffer={10}
          disableSelectionOnClick
          pinnedColumns={{ left: ['routeId'] }}
          componentsProps={{
            columnMenu: {
              background: 'red',
              counter: rows.length,
            },
          }}
          isCellEditable={() => {
            return false;
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          columns={[
            {
              headerName: 'Route ID',
              field: 'routeId',
              minWidth: 100,
              pinnable: true,
              disableColumnMenu: true,
            },
            {
              field: 'transportationDate',
              headerName: 'Transportation Date',
              minWidth: 110,
              type: 'date',
            },
            {
              headerName: 'Subcontractor',
              field: 'subcontractorName',
              minWidth: 100,
              align: 'left',
              renderCell: (params: GridCellParams) => {
                const { row } = params;
                const { subcontractorId } = row;
                if (!subcontractorId || subcontractorId == 0) {
                  return null;
                }
                return (
                  <Link
                    variant="body2"
                    href={`/traffic/cards/subcontractors/${subcontractorId}`}
                    target={'_blank'}
                    underline={'hover'}
                    color={'inherit'}
                  >
                    {row.subcontractorName}
                  </Link>
                );
              },
            },
            {
              headerName: 'Route Type',
              field: 'type',
              minWidth: 100,
              valueGetter({ row }) {
                if (row.isCreatedFromTerminal) {
                  return 'Terminal';
                }
                return row.type;
              },
            },
            {
              headerName: 'Capacity',
              field: 'capacity',
              minWidth: 50,
              valueFormatter({ value }) {
                if (value == null) {
                  return '';
                }
                return value;
              },
            },
            {
              headerName: 'Arrival time',
              field: 'arrivalTime',
              minWidth: 50,
              valueFormatter(params) {
                const { value } = params;
                if (typeof value === 'string') {
                  return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
                }
                return '';
              },
            },
            {
              headerName: 'Departure time',
              field: 'departureTime',
              minWidth: 50,
              valueFormatter(params) {
                const { value } = params;
                if (typeof value === 'string') {
                  return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
                }
                return '';
              },
            },
            {
              headerName: 'Cancelled',
              field: 'isNonArrivalMarked',
              minWidth: 100,
              align: 'center',
              sortable: false,
              editable: false,
              type: 'boolean',
            },
            {
              field: 'inboundFlag',
              headerName: 'Inbound',
              minWidth: 50,
              type: 'boolean',
              renderCell(params) {
                return <TrueOrFalseCellWithNoClearIcon {...params} />;
              },
            },
            {
              field: 'outboundFlag',
              headerName: 'Outbound',
              minWidth: 50,
              type: 'boolean',
              renderCell(params) {
                return <TrueOrFalseCellWithNoClearIcon {...params} />;
              },
            },
            {
              field: 'importFlag',
              headerName: 'Import',
              minWidth: 50,
              type: 'boolean',
              renderCell(params) {
                return <TrueOrFalseCellWithNoClearIcon {...params} />;
              },
            },
            {
              field: 'exportFlag',
              headerName: 'Export',
              minWidth: 50,
              type: 'boolean',
              renderCell(params) {
                return <TrueOrFalseCellWithNoClearIcon {...params} />;
              },
            },
            {
              field: 'licensePlateAndRouteNote',
              headerName: t('attributes.licensePlateAndNote'),
              minWidth: 180,
              disableColumnMenu: true,
            },
          ]}
          rows={dataGrid}
          onGetTFTExcelClicked={() => onGetTFTExcelClicked()}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? classes.muiEven
              : classes.muiOdd
          }
        />
      </Box>
    </>
  );
}

function buildRows(
  legs: ListIncomingRoutesProps['legs'],
  shortTerminalCountry: string,
) {
  return (
    legs?.map((routeLeg) => {
      const { route } = routeLeg;
      const leg = routeLeg;
      const thisTerminalCountry =
        shortTerminalCountry !== ''
          ? COUNTRIES_MAP[
              shortTerminalCountry as keyof typeof COUNTRIES_MAP
            ]?.toUpperCase()
          : '';
      const { routeId, capacity } = route;
      const firstLegLocation = route.legs.find((leg) => leg.position === 0);
      const lastLegLocation = route.legs.find(
        (leg) => leg.position === route.legs.length - 1,
      );
      const departureCountry =
        firstLegLocation?.location && firstLegLocation?.location.country
          ? COUNTRIES_MAP[
              firstLegLocation?.location.country as keyof typeof COUNTRIES_MAP
            ].toLocaleUpperCase()
          : '';
      const arrivalCountry =
        lastLegLocation?.location && lastLegLocation?.location.country
          ? COUNTRIES_MAP[
              lastLegLocation?.location.country as keyof typeof COUNTRIES_MAP
            ].toLocaleUpperCase()
          : '';
      const subcontractorName = route.subcontractor.name;

      const licensePlateAndRouteNote = route.licensePlate
        ? route.note
          ? route.licensePlate.concat(' | ').concat(route.note)
          : route.licensePlate
        : route.note;

      return {
        id: leg.id,
        routeId: routeId,
        capacity,
        type: route.type,
        arrivalTime: leg.arrivalTime,
        departureTime: timeOrNull(leg.departureTime),
        subcontractorName,
        licensePlateAndRouteNote: licensePlateAndRouteNote,
        inboundFlag: leg.unload && thisTerminalCountry === departureCountry,
        outboundFlag: leg.load && thisTerminalCountry === arrivalCountry,
        importFlag: leg.unload && thisTerminalCountry !== departureCountry,
        exportFlag: leg.load && thisTerminalCountry !== arrivalCountry,
        transportationDate: leg.transportationDate,
        isNonArrivalMarked: leg.isNonArrivalMarked,
        isCreatedFromTerminal: route.isCreatedFromTerminal,
      };
    }) || []
  );
}
