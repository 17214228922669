import { sortBy } from 'lodash';

export function getFirstLeg<T extends { position: number }>(
  legs: T[],
): T | undefined {
  return sortBy(legs, 'position').find(Boolean);
}

export function getLastLeg<T extends { position: number }>(
  legs: T[],
): T | undefined {
  return sortBy(legs, 'position').slice(-1)[0];
}

export function getFirstOneLeg<T>(legs: T[]): T | undefined {
  return legs[0];
}

export function getLastOneLeg<T>(legs: T[]): T | undefined {
  return legs.slice(-1)[0];
}
