import { Typography } from '@material-ui/core';
import format from 'date-fns/format';
import { DATE_FORMAT } from '../lib/date_time';

interface CreatedAndUpdatedViewProps {
  createdBy: string;
  lastUpdatedBy: string;
  updatedAt: string;
  deletedAt?: string;
  deletedBy?: string;
  lockedBy?: string;
  lockedAt?: string;
}

export function CreatedAndUpdatedView({
  createdBy,
  lastUpdatedBy,
  updatedAt,
  deletedAt = undefined,
  deletedBy = undefined,
  lockedBy,
  lockedAt = '',
}: CreatedAndUpdatedViewProps) {
  return (
    <>
      <Typography align="left">
        {`Created by: ${createdBy}. Last updated by: ${lastUpdatedBy} at ${format(
          new Date(updatedAt),
          `HH:mm - ${DATE_FORMAT} `,
        )}`}
      </Typography>
      {deletedAt && (
        <Typography align="left">
          {`Deleted by: ${deletedBy}. Deleted at: ${format(
            new Date(deletedAt),
            `HH:mm - ${DATE_FORMAT} `,
          )}`}
        </Typography>
      )}
      {lockedBy && (
        <Typography
          align="left"
          style={{ color: 'orangered' }}
        >{`Locked by: ${lockedBy} at ${lockedAt}`}</Typography>
      )}
    </>
  );
}
