import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../views/CheckListRoutesTraffic';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  overUtilized: {
    color: 'red',
  },
  underUtilized: {
    color: '#0ea00e',
  },
  labelBold: {
    fontWeight: 'bold',
    fontSize: 13,
  },
}));

interface AggregatesLoadingListProps {
  routeIndex: number;
}

export function AggregatesLoadingListCLRHF(props: AggregatesLoadingListProps) {
  const { routeIndex } = props;
  const classes = useStyles();

  const { control } = useFormContext<CheckListRoutesFormInput>();

  const [capacity, legs] = useWatch({
    control,
    name: [`routes.${routeIndex}.capacity`, `routes.${routeIndex}.legs`],
  });

  const loadingListItems = legs.flatMap(
    (leg: {
      idNum: number;
      position: number;
      locationId: number;
      load: boolean;
      unload: boolean;
      productionDate: string;
      transportationDate: string;
      transportationDateOffset: number;
      arrivalTime: string;
      departureTime?: string;
      note: string | null;
      loadingListItems?: {
        id?: number;
        locationId?: number;
        checked: boolean;
        isDangerous: boolean;
        palletSpace?: number | string;
        note: string;
      }[];
    }) => leg.loadingListItems ?? [],
  );

  const sumPalletSpace = loadingListItems.reduce(
    (
      sum: number,
      item: {
        id?: number;
        locationId?: number;
        checked: boolean;
        isDangerous: boolean;
        palletSpace?: number | string;
        note: string;
      },
    ) => {
      const { palletSpace } = item;
      if (typeof palletSpace === 'number') {
        return sum + palletSpace;
      } else if (typeof palletSpace === 'string') {
        return sum + (palletSpace === '' ? 0 : parseInt(palletSpace));
      }

      return sum;
    },
    0,
  );

  const utilPercent = capacity
    ? Math.ceil((sumPalletSpace / capacity) * 100)
    : 0;

  return (
    <Box className={classes.root}>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Total PPL:</strong>
        </label>{' '}
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{sumPalletSpace}</strong>
        </label>
        /{capacity}
      </Typography>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Util:</strong>
        </label>{' '}
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{utilPercent}%</strong>
        </label>
      </Typography>
    </Box>
  );
}
