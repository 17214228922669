import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Box } from '@mui/system';
import { CenteredBox } from '../../CenteredBox';
import { SelectCurrency } from '../../SelectCurrency';
import { CommonTextField } from '../CommonTextField';

interface RouteAggreementFieldsProps {
  trafficReadOnly: boolean;
  terminal?: boolean;
  classes: ClassNameMap<'button' | 'field' | 'categoryHeader'>;
  template?: boolean;
}

export function RouteAgreementFields({
  trafficReadOnly,
  classes,
  terminal = false,
  template = false,
}: RouteAggreementFieldsProps) {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <CenteredBox>
        <CommonTextField
          name="routeId"
          className={classes.field}
          readOnly={trafficReadOnly}
        />
      </CenteredBox>

      <CenteredBox>
        {!template && (
          <CommonTextField
            name="transportationDate"
            type="date"
            className={classes.field}
            readOnly={trafficReadOnly}
          />
        )}
      </CenteredBox>

      <CenteredBox visible={!terminal}>
        {!terminal && (
          <CommonTextField
            name="agreedPrice"
            className={classes.field}
            type="number"
            readOnly={trafficReadOnly}
          />
        )}

        {!terminal && (
          <SelectCurrency fieldName="currency" readOnly={trafficReadOnly} />
        )}
      </CenteredBox>
      <CenteredBox>
        <CommonTextField
          name="capacity"
          className={classes.field}
          type="number"
          readOnly={trafficReadOnly}
        />
        <CommonTextField
          name="kilometer"
          className={classes.field}
          type="number"
          readOnly={trafficReadOnly}
        />
        <CommonTextField
          name="weight"
          labelText={'weightKg'}
          className={classes.field}
          type="number"
          readOnly={trafficReadOnly}
        />
      </CenteredBox>
    </Box>
  );
}
