import {
  Box,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import * as yup from 'yup';
import {
  GetCardDocument,
  GetCheckpointDocument,
  GetCustomerDocument,
  GetLocationsForCardDocument,
  GetOneTerminalDocument,
  GetSubcontractorDocument,
  useAddCardLocationMutation,
} from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { iconForType } from '../lib/iconForType';

//
const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.name'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    address: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.address'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.address') })),
    postalCode: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.postalCode'),
          len: 3,
        }),
      )
      .required(
        t('validation.isRequired', { name: t('attributes.postalCode') }),
      ),
    city: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.city'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.city') })),

    country: yup
      .string()
      .oneOf(Object.keys(COUNTRIES_MAP))
      .required(t('validation.isRequired', { name: t('attributes.country') })),
    phLocationId: yup.string().when('cardType', {
      is: (value: string) => value && value === 'terminals',
      then: yup
        .string()
        .min(
          3,
          t('validation.atLeastLen', {
            name: t('attributes.phLocationId'),
            len: 3,
          }),
        )
        .required(
          t('validation.isRequired', { name: t('attributes.phLocationId') }),
        ),
      otherwise: yup.string(),
    }),

    locationShortCode: yup.string().when('cardType', {
      is: (value: string) => value && value === 'terminals',
      then: yup
        .string()
        .min(
          3,
          t('validation.atLeastLen', {
            name: t('attributes.locationCode'),
            len: 3,
          }),
        )
        .required(
          t('validation.isRequired', { name: t('attributes.locationCode') }),
        ),
      otherwise: yup.string(),
    }),
  });

const useStyles = makeStyles({
  select: {
    textAlign: 'left',
  },
  selectLabel: {
    textAlign: 'left',
  },
});

interface addLocationProps {
  next: string;
}

function getRefetchGueryByUrl(type: string) {
  switch (type) {
    case 'checkpoints':
      return GetCheckpointDocument;
    case 'terminals':
      return GetOneTerminalDocument;
    case 'subcontractors':
      return GetSubcontractorDocument;
    case 'customers':
      return GetCustomerDocument;
    default:
      return GetCardDocument;
  }
}

export function AddLocation(props: addLocationProps) {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const router = useHistory();
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();

  const cardType = path.split('/')[3];
  const cardTypeName =
    cardType && cardType !== ''
      ? cardType.substring(0, cardType.length - 1)
      : '';
  const Icon = iconForType(cardTypeName);

  const refreshQueryDocument = getRefetchGueryByUrl(cardType);
  const [cardName, setCardName] = useState<string>('');
  const [errPHLocationId, setErrPHLocationId] = useState<boolean>(false);
  const [errLocationCode, setErrLocationCode] = useState<boolean>(false);

  const location = useLocation<{
    cardName: string;
    timestamp: number;
  }>();

  useEffect(() => {
    if (location.state && location.state.cardName) {
      setCardName(location.state.cardName);
    }
  }, [location.state?.cardName, location.state?.timestamp]);

  const [addCardLocation, {}] = useAddCardLocationMutation({
    refetchQueries: [
      {
        query: GetLocationsForCardDocument,
        variables: {
          cardId: parseInt(id, 10),
        },
      },
      {
        query: refreshQueryDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
      phLocationId: '',
      locationShortCode: '',
      cardType: cardType,
    },
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      const valCopy: {
        name: string;
        address: string;
        postalCode: string;
        city: string;
        country: string;
        phLocationId: string;
        locationShortCode: string;
        cardType?: string;
      } = _.cloneDeep(values);
      //No need of cardType in api
      delete valCopy.cardType;
      addCardLocation({
        variables: {
          locationInput: {
            ...valCopy,
            phLocationId: `${valCopy.phLocationId}`,
          },
          id: parseInt(id, 10),
        },
      })
        .then(() => {
          router.push(`/traffic/cards/${props.next}/${id}`);
        })
        .catch((e) => {
          if (e.message && e.message.includes('LocationCode'))
            setErrLocationCode(true);
          else setErrLocationCode(false);
          if (e.message && e.message.includes('PHLocationId'))
            setErrPHLocationId(true);
          else setErrPHLocationId(false);
        });
    },
  });

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography align="left" variant="h2">
          {cardName}
        </Typography>
        <Box ml={2} display="flex" alignItems="center">
          <Typography align="left" variant="subtitle2">
            {t(`resource.${cardTypeName}.capitalized`)}
          </Typography>
          {Icon && <Icon />}
        </Box>
      </Box>

      <Box mb={6}>
        <Typography variant="h2">
          {t('pages.addPage.title', {
            item: t('resource.location.lowercased'),
          })}
        </Typography>
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item sm={10} container spacing={3}>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label={t('attributes.name')}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="address"
                  name="address"
                  label={t('attributes.address')}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="postalCode"
                  name="postalCode"
                  label={t('attributes.postalCode')}
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.postalCode &&
                    Boolean(formik.errors.postalCode)
                  }
                  helperText={
                    formik.touched.postalCode && formik.errors.postalCode
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label={t('attributes.city')}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              {/* new fields only for Terminal */}

              {cardTypeName === 'terminal' ? (
                <>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      type={'number'}
                      id="phLocationId"
                      name="phLocationId"
                      label={t('attributes.phLocationId')}
                      value={formik.values.phLocationId}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phLocationId &&
                        (Boolean(formik.errors.phLocationId) || errPHLocationId)
                      }
                      helperText={
                        (formik.touched.phLocationId &&
                          formik.errors.phLocationId) ||
                        (errPHLocationId && 'Already Exist')
                      }
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 6 }}
                      id="locationShortCode"
                      name="locationShortCode"
                      label={t('attributes.locationCode')}
                      value={formik.values.locationShortCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.locationShortCode &&
                        (Boolean(formik.errors.locationShortCode) ||
                          errLocationCode)
                      }
                      helperText={
                        (formik.touched.locationShortCode &&
                          formik.errors.locationShortCode) ||
                        (errLocationCode && 'Already Exist')
                      }
                    />
                  </Grid>
                </>
              ) : null}

              <Grid item sm={6}>
                <InputLabel
                  className={classes.selectLabel}
                  shrink
                  id="countryLabel"
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                >
                  {t('attributes.country')}
                </InputLabel>
                <Select
                  className={classes.select}
                  fullWidth
                  labelId="countryLabel"
                  id="country"
                  value={formik.values.country}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  onChange={(val) => {
                    formik.setFieldValue('country', val.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.entries(COUNTRIES_MAP).map(([cy, country]) => (
                    <MenuItem key={cy} value={cy}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                >
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              </Grid>

              <Grid item sm={12} container justifyContent="center">
                <Input
                  type="submit"
                  value={t('button.add', {
                    item: t('resource.location.lowercased'),
                  })}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
