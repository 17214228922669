import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CardForm } from '../components/CardForm';
import { HelmetComponent } from '../components/HelmetComponent';
import { useCreateCustomerMutation } from '../generated/graphql';

export function CreateCustomer() {
  const { t } = useTranslation();
  const router = useHistory();
  const [createCustomer, {}] = useCreateCustomerMutation();

  const onSubmit = (values: { name: string }) => {
    createCustomer({ variables: { ...values } }).then((response) => {
      const id = response.data?.createCustomer.id;
      router.push(`/traffic/cards/customers${id ? `/${id}` : ''}`);
    });
  };

  return (
    <Box>
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.customer.lowercased',
        )}`}
      />
      <Box mb={10}>
        <Typography variant="h2">
          {t('pages.createPage.title', {
            card: t('resource.customer.lowercased'),
          })}
        </Typography>
      </Box>
      <Box>
        <CardForm onSubmit={onSubmit} type="customer" />
      </Box>
    </Box>
  );
}
