import { Box, Grid, Typography } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import { useGetLocationsQuery } from '../generated/graphql';

const columns = (t: TFunction) => [
  { field: 'id', type: 'number', headerName: t(`attributes.id`), width: 50 },
  { field: 'name', headerName: t('attributes.name'), width: 130, flex: 2 },
  {
    field: 'address',
    headerName: t('attributes.address'),
    width: 130,
    flex: 1,
  },
  {
    field: 'postalCode',
    headerName: t('attributes.postalCode'),
    width: 130,
    flex: 1,
  },
  {
    field: 'city',
    headerName: t('attributes.city'),
    width: 130,
    flex: 1,
  },
  {
    field: 'countryLong',
    headerName: t('attributes.country'),
    width: 130,
    flex: 1,
  },
];

export function Locations() {
  const { t } = useTranslation();
  const { data, loading, error } = useGetLocationsQuery();
  const { path } = useRouteMatch();
  const router = useHistory();

  if (error) {
    console.error(error);
    return <div>{error.message}</div>;
  }
  return (
    <>
      <HelmetComponent title={t('resource.location.plural')} />
      <Grid container>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Box mb={2}>
            <Typography variant="h2">
              {t('resource.location.plural')}
            </Typography>
          </Box>
        </Grid>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={data?.locations || []}
            columns={columns(t)}
            loading={loading}
            pageSize={5}
            rowHeight={50}
            autoHeight={true}
            onRowClick={(key) => {
              router.push(`${path}/${key.row.id}`);
            }}
          />
        </div>
      </Grid>
    </>
  );
}
