import { Dispatch, SetStateAction } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FileItem } from '../FileListComponent';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { HideableFormFieldsRHF } from './HideableFormFieldsRHF';
import { SelectFiles } from './SelectFiles';

interface FilesFieldProps {
  readOnly: boolean;
  onHideClicked: () => void;
  show: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function FilesFieldRHF({
  readOnly,
  onHideClicked,
  show,
  setOpen,
}: FilesFieldProps) {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<SporadicRouteFormInput>();

  const [files] = useWatch({
    control,
    name: ['files'],
  });

  return (
    <HideableFormFieldsRHF
      title={t('resource.documentFile.plural')}
      show={show}
      onHideClicked={onHideClicked}
      setOpen={setOpen}
    >
      <SelectFiles
        files={
          files
            ? files.filter((f: FileItem) => f.originalname.includes('.pdf'))
            : []
        }
        readOnly={readOnly}
        handleDelete={(file: FileItem) => {
          const remainingFiles = files.filter(
            (x: FileItem) => x.id !== file.id,
          );
          setValue('files', remainingFiles);
        }}
        addFile={(file) => {
          setValue('files', [...files, file]);
        }}
      />
    </HideableFormFieldsRHF>
  );
}
