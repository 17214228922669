import SvgIcon from '@material-ui/icons/AccountBalanceWallet';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import HomeIcon from '@material-ui/icons/Home';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PaymentIcon from '@material-ui/icons/Payment';

export enum CardType {
  Cards = 'cards',
  Customer = 'customer',
  Subcontractor = 'subcontractor',
  Terminal = 'terminal',
  Checkpoint = 'checkpoint',
}

export function iconForType(type: string): typeof SvgIcon | undefined {
  switch (type) {
    case CardType.Cards:
      return PaymentIcon;
    case CardType.Subcontractor:
      return LocalShippingIcon;
    case CardType.Terminal:
      return HomeIcon;
    case CardType.Customer:
      return AccountBalanceWalletIcon;
    case CardType.Checkpoint:
      return DirectionsBoatIcon;
    default:
      return undefined;
  }
}
