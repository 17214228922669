import { Box, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { GridApiRef, GridCellParams } from '@mui/x-data-grid-pro';

interface EditableCellProps {
  className: string;
  apiRef: GridApiRef;
  editable?: boolean;
  highlightField?: boolean;
}

export function EditableCell(props: EditableCellProps & GridCellParams) {
  const {
    value,
    className,
    id,
    field,
    apiRef,
    editable = true,
    highlightField = false,
  } = props;

  return (
    <Box
      sx={{ bgcolor: highlightField && editable ? 'primary.light' : '' }}
      className={className}
    >
      <Typography>{value}</Typography>
      {editable && (
        <IconButton
          onClick={() => {
            apiRef.current.setCellMode(id, field, 'edit');
          }}
        >
          <EditIcon />
        </IconButton>
      )}
    </Box>
  );
}
