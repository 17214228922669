import { IconButton, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { DialogModal } from '../DialogModal';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: `${theme.palette.error.main} !important`,
  },
}));

interface DeleteActionCellProps {
  itemTitle: string;
  deleteAction: () => void;
}

export function DeleteActionCell(props: DeleteActionCellProps) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { deleteAction, itemTitle } = props;

  return (
    <>
      <IconButton
        onClick={() => {
          setOpenDeleteDialog(true);
        }}
      >
        <DeleteIcon
          classes={{
            root: classes.deleteButton,
          }}
          type="light"
        />
      </IconButton>
      <DialogModal
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        contentText={t('validation.confirmation', {
          item: itemTitle,
          action: t('actions.delete.lowercased'),
        })}
        doAction={deleteAction}
        buttonText={t('button.delete')}
      />
    </>
  );
}
