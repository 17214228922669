import { List, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import UnlockIcon from '@mui/icons-material/LockOpen';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckListRoutesFormInput } from '../views/CheckListRoutesTraffic';
import { ChecklistCollapsibleList } from './ChecklistCollapsibleList';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import LoadingIcon from '@mui/icons-material/HourglassFull';
import { Stack } from '@mui/material';
import { DialogModal } from './DialogModal';
import { useUpdateLockOnBulkRoutesMutation } from '../generated/graphql';

interface ChkRoutesListProps {
  readOnly: boolean;
  isUpdateInProgress: boolean;
}

export function ChecklistRoutesListRHF(props: ChkRoutesListProps) {
  const { readOnly = false, isUpdateInProgress } = props;
  const [updateBulkLockMutation] = useUpdateLockOnBulkRoutesMutation();
  const { t } = useTranslation();
  const { control } = useFormContext<CheckListRoutesFormInput>();

  const { fields } = useFieldArray({
    control,
    name: 'routes',
  });
  const [showUnlockConfirmation, setShowUnlockConfirmation] =
    useState<boolean>(false);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <List>
        {fields.map((route, index) => (
          <ChecklistCollapsibleList readOnly={readOnly} index={index} />
        ))}
      </List>
      <DialogModal
        open={showUnlockConfirmation}
        setOpen={setShowUnlockConfirmation}
        contentText={`${t('attributes.sureToUnlockAll')}`}
        doAction={() => {
          updateBulkLockMutation({
            variables: {
              routeIds: fields.map((r) => r.idNum) as number[],
              isLock: false,
              forGroup:
                fields && fields[0].routeGroupTag
                  ? fields[0].routeGroupTag
                  : '',
            },
          });
        }}
        buttonText={t('button.yes')}
      />
      <div
        style={{
          position: 'fixed',
          bottom: 80,
          right: 10,
          zIndex: 4,
        }}
      >
        <Stack direction="column" spacing={1}>
          <Fab color="success" type="submit" disabled={isUpdateInProgress}>
            <Tooltip title={`${t('button.saveChanges')}`}>
              {isUpdateInProgress ? <LoadingIcon /> : <SaveIcon />}
            </Tooltip>
          </Fab>

          <Fab
            color="warning"
            disabled={isUpdateInProgress}
            onClick={() => setShowUnlockConfirmation(true)}
          >
            <Tooltip title={`${t('button.unlockAll')}`}>
              <UnlockIcon />
            </Tooltip>
          </Fab>
        </Stack>
      </div>
    </div>
  );
}
