import {
  Input,
  Button,
  Box,
  FormHelperText,
  Grid,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { Formik } from 'formik';
import { SyntheticEvent, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  GetCardFilesDocument,
  useDeleteCardFileMutation,
  useGetCardFilesQuery,
} from '../generated/graphql';
import { useHttpClient } from '../providers/HttpClientProvider';
import { FileItem, FileListComponent } from './FileListComponent';
import * as yup from 'yup';

const MAX_FILE_SIZE_MB = 30;
const FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1024;

const validationSchema = (t: TFunction) =>
  yup.object({
    file: yup
      .mixed()
      .test(
        'fileSize',
        t('validation.fileTooBig'),
        (value) => value && value.size <= FILE_SIZE,
      )
      .required(t('validation.isRequired', { name: t('attributes.file') })),
  });

interface CardFilesProps {
  cardId: number;
}

export function CardFiles(props: CardFilesProps) {
  const { cardId } = props;
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const [showForm, setShowForm] = useState(false);
  const { data, loading, refetch } = useGetCardFilesQuery({
    variables: {
      cardId,
    },
  });
  const [deleteCardFileMutation] = useDeleteCardFileMutation({
    refetchQueries: [{ query: GetCardFilesDocument, variables: { cardId } }],
  });

  if (loading) {
    return null;
  }

  const files = data?.card.files;

  const handleDelete = (item: FileItem) => {
    deleteCardFileMutation({ variables: { deleteCardFileId: item.id } });
  };

  const onFileUpload = async (values: { file?: File }) => {
    if (values.file == null) return;

    await httpClient.uploadCardFile(props.cardId, values.file);
    refetch();

    setShowForm(false);
  };

  const handleFormShowClicked = () => {
    setShowForm(true);
  };

  return (
    <FileListComponent
      header={t('components.files')}
      files={files || []}
      handleDelete={handleDelete}
      handleFormShowClicked={handleFormShowClicked}
      form
      showForm={showForm}
    >
      <Card>
        <CardContent>
          <Formik
            initialValues={{
              file: undefined,
            }}
            onSubmit={onFileUpload}
            validationSchema={validationSchema(t)}
          >
            {({
              setFieldValue,
              handleSubmit,
              submitForm,
              resetForm,
              errors,
              touched,
            }) => (
              <>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={8} md={8}>
                    <Typography variant="subtitle1">
                      {t('button.add', { item: t('attributes.file') })}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={2}
                        direction="column"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Input
                              type="file"
                              error={touched.file && Boolean(errors.file)}
                              onChange={(event: SyntheticEvent) => {
                                const target = event.target as HTMLInputElement;
                                const files = target.files;
                                if (files != null) {
                                  setFieldValue('file', files[0]);
                                }
                              }}
                            />
                            <FormHelperText
                              error={touched.file && Boolean(errors.file)}
                            >
                              {errors.file}
                            </FormHelperText>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Button onClick={submitForm}>
                            {t('button.add', { item: t('attributes.file') })}
                          </Button>
                          <Button
                            onClick={() => {
                              resetForm();
                              setShowForm(false);
                            }}
                            color="secondary"
                          >
                            {t('button.cancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </CardContent>
      </Card>
    </FileListComponent>
  );
}
