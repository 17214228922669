import { Tooltip } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import format from 'date-fns/format';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from '../lib/date_time';
interface viewSporadicRouteTemplateColumnsOptions {
  t: TFunction;
  path: string;
}

export const ViewSporadicRouteTemplateColumns = (
  options: viewSporadicRouteTemplateColumnsOptions,
): GridColDef[] => {
  const { t, path } = options;
  return [
    {
      field: 'use',
      headerName: 'Use',
      type: 'action',
      renderCell: (params: GridCellParams) => {
        const linkPath = `${path}/create/${params.id}`;
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.create.capitalized')}`}>
              <Link
                to={linkPath}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <AddCircleIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Id',
    },
    {
      field: 'name',
      headerName: 'Template name',
      width: 200,
    },
    {
      field: 'routeId',
      headerName: 'Route Id',
      width: 200,
      valueGetter({ row }) {
        return row.routeId;
      },
    },
    {
      field: 'departureCountry',
      headerName: 'Departure country',
      width: 100,
    },
    {
      field: 'arrivalCountry',
      headerName: 'Arrival country',
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created date',
      width: 100,
      valueGetter({ row }) {
        return format(new Date(row.createdAt), DATE_FORMAT);
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      type: 'action',
      renderCell: (params: GridCellParams) => {
        const linkPath = `${path}/routetemplate/${params.id}/edit`;
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.edit')}`}>
              <Link
                to={linkPath}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <EditIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
};
