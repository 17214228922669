import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CommonTextField } from '../components/form/CommonTextField';
import {
  DangerousGoodsClassification,
  FuelType,
  Maybe,
} from '../generated/graphql';
import { CURRENCIES } from '../lib/constants';
import { createFTL } from '../lib/formHelpers/ftl';
import { newLeg } from '../lib/formHelpers/leg';
import { AutoReset } from '../utils/AutoReset';
import { CheckboxField } from './form/CheckboxField';
import { SelectContactFromCard } from './form/SelectContactFromCard';
import { SelectCustomer } from './form/SelectCustomer';
import { SelectFiles } from './form/SelectFiles';
import { SelectLocation } from './form/SelectLocation';
import { SelectSubcontractor } from './form/SelectSubcontractor';
import { SupressEnterForm } from './form/SupressEnterForm';
import { TimePickerField } from './form/TimePickerField';
import { TransportationDateOffsetField } from './ProductionDateOffsetField';

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup.string(),
    agreedPrice: yup
      .number()
      .min(1)
      .required(
        t('validation.isRequired', { name: t('attributes.agreedPrice') }),
      ),
    currency: yup.string().oneOf(Object.keys(CURRENCIES)),
    note: yup.string(),
    driverName: yup.string(),
    driverPhoneNumber: yup.string(),
    licensePlate: yup.string(),
    capacity: yup.number().nullable().min(0),
    routeId: yup.string(),
    invoiceNumber: yup.string().optional().nullable(),
    invoiceNote: yup.string().optional().nullable(),
    dispatcherId: yup
      .string()
      .matches(
        /^\d+$/,
        t('validation.isRequired', { name: t('attributes.dispatcher') }),
      ),
    subcontractorId: yup
      .string()
      .matches(
        /^\d+$/,
        t('validation.isRequired', { name: t('attributes.subcontractorId') }),
      )
      .required(
        t('validation.isRequired', { name: t('attributes.subcontractorId') }),
      ),
    legs: yup
      .array(
        yup.object().shape({
          arrivalTime: yup
            .string()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/, t('validation.timeFormatError'))
            .required(
              t('validation.isRequired', { name: t('attributes.arrivalTime') }),
            ),
          departureTime: yup
            .string()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/, t('validation.timeFormatError'))
            .required(
              t('validation.isRequired', {
                name: t('attributes.departureTime'),
              }),
            ),
          locationId: yup.string().required(
            t('validation.isRequired', {
              name: t('attributes.location'),
            }),
          ),
          note: yup.string(),
          load: yup.boolean(),
          unload: yup.boolean(),
        }),
      )
      .required(),
    files: yup.array(
      yup.object().shape({
        id: yup.number(),
      }),
    ),
    ftl: yup
      .object({
        cdc: yup.string(),
        cmr: yup.string(),
        currency: yup.string(),
        customerId: yup.number().min(1),
        customerNumber: yup.string(),
        customerReference: yup.string(),
        invoiceNote: yup.string(),
        price: yup.number().min(1),
      })
      .nullable(),
  });

export type RouteTemplateFormInput = {
  name: string;
  agreedPrice?: Maybe<number>;
  currency: string;
  note: string;
  externalNote: string;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  capacity?: number;
  routeId: string;
  dispatcherId: number;
  subcontractorId?: number;
  files: {
    originalname: string;
    id: number;
    path: string;
    createdBy: string;
    createdAt: Date;
  }[];
  ftl:
    | {
        cdc: string;
        cmr: string;
        price: number;
        currency: string;
        customerId: number;
        customerNumber: string;
        customerReference: string;
        invoiceNote: string;
      }
    | undefined;
  legs: {
    load: boolean;
    unload: boolean;
    fuel: FuelType;
    note?: string;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    position: number;
    transportationDateOffset: number;
    locationId?: number;
    loadingListItems: {
      packages?: Maybe<number | string>;
      pallets?: Maybe<number | string>;
      palletSpace?: Maybe<number | string>;
      weight?: Maybe<number | string>;
      note: string;
      locationId?: number;
      checked: boolean;
      isDangerous: boolean;
      unNumber?: Maybe<number> | undefined;
      isLimitedQty: boolean;
      classification?: Maybe<DangerousGoodsClassification> | undefined;
    }[];
  }[];
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '80%',
    margin: theme.spacing(2),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(2, 'auto'),
  },
  subResources: {
    margin: theme.spacing(2, 'auto'),
    width: '90%',
  },
  button: {
    margin: theme.spacing(2, 'auto'),
  },
  formContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
  },
}));

interface RouteTemplateFormProps {
  initialValues: Omit<
    RouteTemplateFormInput,
    'dispatcherId' | 'subcontractorId'
  > & { subcontractorId?: number; dispatcherId?: number };
  onSubmit: (
    values: Omit<RouteTemplateFormInput, 'dispatcherId' | 'subcontractorId'> & {
      dispatcherId?: number;
      subcontractorId?: number;
    },
  ) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
  includeInvoice?: boolean;
}
export function RouteTemplateForm(props: RouteTemplateFormProps) {
  const {
    initialValues,
    onSubmit,
    submitButtonLabel,
    readOnly = false,
    includeInvoice = false,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Paper className={classes.formContent}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema(t)}
        onSubmit={onSubmit}
      >
        {(props) => (
          <SupressEnterForm
            onSubmit={props.handleSubmit}
            className={classes.root}
          >
            <Grid container>
              <AutoReset readOnly={readOnly} />
              <Grid item xs={6}>
                <CommonTextField
                  name="name"
                  className={classes.field}
                  readOnly={readOnly}
                />
                <Typography
                  display="block"
                  variant="subtitle1"
                  align="left"
                  className={classes.categoryHeader}
                >
                  {t('attributeGrouping.agreement')}
                </Typography>
                <CommonTextField
                  name="tourIdSuffix"
                  className={classes.field}
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="agreedPrice"
                  className={classes.field}
                  type="number"
                  readOnly={readOnly}
                />
                <Field name="fuel">
                  {({ field }: FieldProps) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.field}
                    >
                      <InputLabel shrink id="fuel">
                        {t('attributes.fuel')}
                      </InputLabel>
                      <Select
                        id="fuel"
                        {...field}
                        labelId="fuel"
                        label={t('attributes.fuel')}
                        readOnly={readOnly}
                        inputProps={{ readOnly }}
                        IconComponent={(props) => (
                          <ArrowDropDown
                            {...props}
                            style={{
                              display: readOnly ? 'none' : 'inline-block',
                            }}
                          />
                        )}
                      >
                        {Object.entries(CURRENCIES).map(([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <CommonTextField
                  name="capacity"
                  className={classes.field}
                  type="number"
                  readOnly={readOnly}
                />
                <Typography
                  variant="subtitle1"
                  className={classes.categoryHeader}
                  align="left"
                >
                  {t('attributeGrouping.notes')}
                </Typography>
                <CommonTextField
                  className={classes.field}
                  name="note"
                  multiline
                  fullWidth
                  rows={4}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  className={classes.categoryHeader}
                  align="left"
                >
                  {t('resource.subcontractor.capitalized')}
                </Typography>
                <SelectSubcontractor
                  name="subcontractorId"
                  className={classes.field}
                  readOnly={readOnly}
                />
                <SelectContactFromCard
                  name="dispatcherId"
                  cardId={props.values.subcontractorId}
                  label={t('attributes.dispatcherId')}
                  className={classes.field}
                  readOnly={readOnly}
                />
                <Typography
                  variant="subtitle1"
                  className={classes.categoryHeader}
                  align="left"
                >
                  {t('attributeGrouping.driver')}
                </Typography>
                <CommonTextField
                  name="driverName"
                  className={classes.field}
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="driverPhoneNumber"
                  className={classes.field}
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="licensePlate"
                  className={classes.field}
                  readOnly={readOnly}
                />
                {includeInvoice && (
                  <>
                    <Typography
                      variant="subtitle1"
                      className={classes.categoryHeader}
                      align="left"
                    >
                      {t('attributeGrouping.invoice')}
                    </Typography>
                    <CommonTextField
                      name="invoiceNumber"
                      className={classes.field}
                      readOnly={readOnly}
                    />
                    <CommonTextField
                      name="invoiceNote"
                      className={classes.field}
                      readOnly={readOnly}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {(props.values.ftl || !readOnly) && <Divider />}
                <Box className={classes.subResources}>
                  {(props.values.ftl || !readOnly) && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1" align="left">
                        {t('resource.routeFtl.capitalized')}
                      </Typography>
                      {!readOnly && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (props.values.ftl == null) {
                              props.setFieldValue('ftl', createFTL());
                            } else {
                              props.setFieldValue('ftl', undefined);
                            }
                          }}
                        >
                          {props.values.ftl == null
                            ? t('button.add', {
                                item: t('resource.routeFtl.capitalized'),
                              })
                            : t('button.remove', {
                                item: t('resource.routeFtl.capitalized'),
                              })}
                        </Button>
                      )}
                    </Box>
                  )}
                  <Grid container justifyContent="flex-end">
                    {props.values.ftl && (
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <CommonTextField
                              name="ftl.cdc"
                              className={classes.field}
                              readOnly={readOnly}
                            />
                            <CommonTextField
                              name="ftl.cmr"
                              className={classes.field}
                              readOnly={readOnly}
                            />
                            <CommonTextField
                              name="ftl.price"
                              type="number"
                              className={classes.field}
                              readOnly={readOnly}
                            />
                            <Field name="ftl.currency">
                              {({ field }: FieldProps) => (
                                <FormControl
                                  fullWidth
                                  variant="outlined"
                                  className={classes.field}
                                >
                                  <InputLabel shrink id="ftl-currency">
                                    {t('attributes.currency')}
                                  </InputLabel>
                                  <Select
                                    id="ftl-currency"
                                    {...field}
                                    labelId="ftl-currency"
                                    label={t('attributes.currency')}
                                    IconComponent={(props) => (
                                      <ArrowDropDown
                                        {...props}
                                        style={{
                                          display: readOnly
                                            ? 'none'
                                            : 'inline-block',
                                        }}
                                      />
                                    )}
                                    readOnly={readOnly}
                                  >
                                    {Object.entries(CURRENCIES).map(
                                      ([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                          {value}
                                        </MenuItem>
                                      ),
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={6}>
                            <SelectCustomer
                              name="ftl.customerId"
                              variant="outlined"
                              className={classes.field}
                              label={t('attributes.customerId')}
                              readOnly={readOnly}
                            />
                            <CommonTextField
                              name="ftl.customerNumber"
                              className={classes.field}
                              readOnly={readOnly}
                            />
                            <CommonTextField
                              name="ftl.customerReference"
                              className={classes.field}
                              readOnly={readOnly}
                            />
                            <CommonTextField
                              name="ftl.invoiceNote"
                              className={classes.field}
                              readOnly={readOnly}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Box className={classes.subResources}>
                  <Typography variant="subtitle1" align="left">
                    {t('resource.documentFile.plural')}
                  </Typography>
                  <SelectFiles
                    files={props.values.files ?? []}
                    readOnly={readOnly}
                    addFile={(file) => {
                      props.setFieldValue('files', [
                        ...props.values.files,
                        file,
                      ]);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Box className={classes.subResources}>
                  <Typography variant="subtitle1" align="left">
                    {t('resource.routeLeg.plural')}
                  </Typography>
                  <FieldArray
                    name="legs"
                    render={(arrayHelpers) => (
                      <>
                        {!readOnly && (
                          <Grid container justifyContent="flex-end">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                arrayHelpers.push(newLeg('')[0]);
                              }}
                            >
                              {t('button.add', { item: t('leg') })}
                            </Button>
                          </Grid>
                        )}
                        <List>
                          {props.values.legs.map((_leg, index) => (
                            <ListItem key={index}>
                              <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Grid item xs={3}>
                                  <SelectLocation
                                    name={`legs.${index}.locationId`}
                                    className={classes.field}
                                    readOnly={readOnly}
                                  />
                                </Grid>
                                <CheckboxField
                                  name={`legs.${index}.load`}
                                  label={t('attributes.load')}
                                  readOnly={readOnly}
                                />
                                <CheckboxField
                                  name={`legs.${index}.unload`}
                                  label={t('attributes.unload')}
                                  readOnly={readOnly}
                                />
                                <Grid item>
                                  <Grid container direction="column">
                                    <TimePickerField
                                      name={`legs.${index}.arrivalTime`}
                                      readOnly={readOnly}
                                    />
                                    <TimePickerField
                                      name={`legs.${index}.departureTime`}
                                      readOnly={readOnly}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <CommonTextField
                                    name={`legs.${index}.note`}
                                    readOnly={readOnly}
                                  />
                                </Grid>
                                <Grid item>
                                  <TransportationDateOffsetField
                                    name={`legs.${index}.transportationDateOffset`}
                                    readOnly={readOnly}
                                  />
                                </Grid>
                                {!readOnly && (
                                  <Grid item>
                                    <Button
                                      disabled={index === 0}
                                      onClick={() => {
                                        arrayHelpers.swap(index, index - 1);
                                      }}
                                      startIcon={<ArrowUpwardIcon />}
                                    />
                                    <Button
                                      disabled={
                                        index === props.values.legs.length - 1
                                      }
                                      onClick={() => {
                                        arrayHelpers.swap(index, index + 1);
                                      }}
                                      startIcon={<ArrowDownwardIcon />}
                                    />
                                    <Button
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      startIcon={<DeleteIcon />}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            {!readOnly && (
              <Grid container justifyContent="flex-end">
                <Button
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            )}
          </SupressEnterForm>
        )}
      </Formik>
    </Paper>
  );
}
