import { Grid } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../../CenteredBox';
import { CheckboxField } from '../CheckboxField';
import { CommonDateField } from '../CommonDateField';
import DateSelectorInTour from './DateSelectorInTour';
import { DaysFields } from './DaysField';
import { DATE_FORMAT } from '../../../lib/date_time';
import { format } from 'date-fns';

interface DatesFieldsProps {
  readOnly: boolean;
  routeIndex: number;
  isFromUpdate: boolean;
  isNewRouteAdd: boolean;
}

export function TourRouteDatesFields({
  readOnly,
  routeIndex,
  isFromUpdate,
  isNewRouteAdd,
}: DatesFieldsProps) {
  const { t } = useTranslation();
  const { getFieldProps, setFieldValue } = useFormikContext();
  const routeDateType = getFieldProps(
    `routes.${routeIndex}.routeDateType`,
  ).value;
  const [selectedValue, setSelectedValue] = useState(routeDateType ?? 'dr');

  const hasExceptionDates = getFieldProps(
    `routes.${routeIndex}.hasExceptionDates`,
  ).value;
  const startExceptionDate = getFieldProps(
    `routes.${routeIndex}.startExceptionDate`,
  ).value;
  const endExceptionDate = getFieldProps(
    `routes.${routeIndex}.endExceptionDate`,
  ).value;
  const startDate = getFieldProps(`routes.${routeIndex}.startDate`).value;

  const endDate = getFieldProps(`routes.${routeIndex}.endDate`).value;

  useEffect(() => {
    if (hasExceptionDates === false) {
      if (startExceptionDate != null || endExceptionDate != null) {
        setFieldValue(`routes.${routeIndex}.startExceptionDate`, null);
        setFieldValue(`routes.${routeIndex}.endExceptionDate`, null);
      }
    }
    if (hasExceptionDates === false && routeDateType == 'dr') {
      setFieldValue(`routes.${routeIndex}.exceptionDates`, []);
    }
  }, [hasExceptionDates, startExceptionDate, endExceptionDate]);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 6 }}>
        <Grid container alignContent="flex-start" style={{ marginBottom: 6 }}>
          <Grid item xs={5}>
            {' '}
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedValue}
              onChange={(event) => {
                setSelectedValue(event.target.value);
                setFieldValue(
                  `routes.${routeIndex}.routeDateType`,
                  event.target.value,
                );

                setFieldValue(`routes.${routeIndex}.exceptionDates`, []);
                if (event.target.value === 'cd') {
                  setFieldValue(
                    `routes.${routeIndex}.startDate`,
                    format(new Date(), DATE_FORMAT),
                  );
                  setFieldValue(`routes.${routeIndex}.endDate`, null);
                  setFieldValue(
                    `routes.${routeIndex}.startExceptionDate`,
                    null,
                  );
                  setFieldValue(`routes.${routeIndex}.endExceptionDate`, null);
                } else if (event.target.value === 'dr') {
                  setFieldValue(`routes.${routeIndex}.tourRouteType`, 'Fixed');
                }
              }}
            >
              <FormControlLabel
                value="dr"
                disabled={readOnly || (isFromUpdate && !isNewRouteAdd)}
                control={
                  <Radio
                    value="dr"
                    name="radio1"
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: '#69bc48',
                      },
                    }}
                  />
                }
                label={`Date range & Days`}
              />
              <FormControlLabel
                value="cd"
                disabled={readOnly || (isFromUpdate && !isNewRouteAdd)}
                control={
                  <Radio
                    value="cd"
                    name="radio2"
                    size="small"
                    sx={{
                      '&.Mui-checked': {
                        color: '#69bc48',
                      },
                    }}
                  />
                }
                label={`Choose from Calendar`}
              />
            </RadioGroup>
          </Grid>
          {selectedValue === 'dr' && (
            <Grid item xs={7}>
              <DaysFields index={routeIndex} readOnly={readOnly} />
            </Grid>
          )}
        </Grid>

        {selectedValue === 'dr' ? (
          <Grid item xs={3}>
            <CommonDateField
              name={`routes.${routeIndex}.startDate`}
              variant="outlined"
              fullWidth
              minDate
              readOnly={readOnly || (isFromUpdate && !isNewRouteAdd)}
            />
            <p />
            <CommonDateField
              name={`routes.${routeIndex}.endDate`}
              variant="outlined"
              disabled={startDate == null || startDate == ''}
              dateString={`routes.${routeIndex}.startDate`}
              fullWidth
              isClearButton
              readOnly={readOnly}
            />
            {!readOnly && startDate ? (
              <CenteredBox>
                <CheckboxField
                  name={`routes.${routeIndex}.hasExceptionDates`}
                  label={t('attributes.hasExceptionDates')}
                  readOnly={readOnly}
                />
              </CenteredBox>
            ) : null}
          </Grid>
        ) : (
          <Grid item xs={6}>
            <DateSelectorInTour
              key={`Dt${routeIndex}`}
              startDate={new Date()}
              endDate={undefined}
              index={routeIndex}
              readOnly={readOnly}
              forException={false}
            />
          </Grid>
        )}

        {hasExceptionDates &&
        (routeDateType == null || routeDateType == 'dr') &&
        startDate ? (
          <Grid container item xs={9} spacing={2}>
            <Grid item xs={6}>
              <CommonDateField
                name={`routes.${routeIndex}.startExceptionDate`}
                linkName={`routes.${routeIndex}.endExceptionDate`}
                variant="outlined"
                dateString={`routes.${routeIndex}.startDate`}
                endDateString={`routes.${routeIndex}.endDate`}
                disabled={startDate == null}
                maxDate
                isClearButton
                fullWidth
                isTouchedOn
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <CommonDateField
                name={`routes.${routeIndex}.endExceptionDate`}
                variant="outlined"
                maxDate
                isTouchedOn
                fullWidth
                isClearButton
                dateString={`routes.${routeIndex}.startExceptionDate`}
                endDateString={`routes.${routeIndex}.endDate`}
                disabled={
                  startExceptionDate == null || startExceptionDate == ''
                }
                readOnly={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelectorInTour
                key={`Exc${routeIndex}`}
                startDate={startDate != undefined ? startDate : new Date()}
                endDate={endDate}
                index={routeIndex}
                readOnly={readOnly}
                forException={true}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
