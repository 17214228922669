import { Button, FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetFilesQuery, useGetFilesQuery } from '../../generated/graphql';
import { FileItem, FileListComponent } from '../FileListComponent';

interface SelectFiles {
  files: FileItem[];
  addFile: (file: GetFilesQuery['files'][number]) => void;
  readOnly?: boolean;
  handleDelete?: (item: FileItem) => void;
}

export function SelectFiles(props: SelectFiles) {
  const { files, addFile, readOnly, handleDelete } = props;
  const { t } = useTranslation();
  const { data } = useGetFilesQuery();
  const [showForm, setShowForm] = useState(false);
  const [file, setFile] = useState<GetFilesQuery['files'][number] | undefined>(
    undefined,
  );

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value;
    const file = data?.files.find((f) => f.id === id);
    setFile(file);
  };

  const handleAddFile = () => {
    if (file != null) {
      addFile(file);
      setFile(undefined);
      setShowForm(false);
    }
  };

  return (
    <FileListComponent
      files={files || []}
      handleFormShowClicked={() => setShowForm(!showForm)}
      showForm={showForm}
      form
      handleDelete={handleDelete}
      readOnly={readOnly}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <Select onChange={handleChange}>
              {data?.files
                .filter((file) => !files.some((f) => f.id === file.id))
                .map((file) => (
                  <MenuItem key={file.id} value={file.id}>
                    {file.originalname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Button onClick={handleAddFile} variant="outlined">
            {t('button.add', {
              item: t('resource.documentFile.lowercased'),
            })}
          </Button>
          <Button onClick={() => setShowForm(false)} variant="outlined">
            {t('button.cancel')}
          </Button>
        </Grid>
      </Grid>
    </FileListComponent>
  );
}
