import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../../CenteredBox';
import { useFormContext, useWatch } from 'react-hook-form';
import { SporadicRouteFormInput } from '../../RouteFormRHF';
import { CheckboxFieldRHF } from '../CheckboxFieldRHF';
import { CommonDateFieldRHF } from '../CommonDateFieldRHF';
import { Grid } from '@material-ui/core';

interface DatesFieldsProps {
  readOnly: boolean;
}

export function DatesFieldsRHF({ readOnly }: DatesFieldsProps) {
  const { t } = useTranslation();

  const { control, setValue: setFormValue } =
    useFormContext<SporadicRouteFormInput>();

  const [hasExceptionDates, startExceptionDate, endExceptionDate, startDate] =
    useWatch({
      control,
      name: [
        'hasExceptionDates',
        'startExceptionDate',
        'endExceptionDate',
        'startDate',
        'endDate',
      ],
    });

  useEffect(() => {
    if (
      hasExceptionDates === false &&
      (startExceptionDate != null || endExceptionDate != null)
    ) {
      setFormValue('startExceptionDate', undefined);
      setFormValue('endExceptionDate', undefined);
    }
  }, [hasExceptionDates, startExceptionDate, endExceptionDate]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CommonDateFieldRHF
            controllerName={'startDate'}
            name="startDate"
            minDate
            fullWidth
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <CommonDateFieldRHF
            controllerName={'endDate'}
            name="endDate"
            disabled={startDate == null || startDate == ''}
            fullWidth
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      {!readOnly && (
        <CenteredBox>
          <CheckboxFieldRHF
            controllerName={'hasExceptionDates'}
            name={'hasExceptionDates'}
            label={t('attributes.hasExceptionDates')}
            readOnly={readOnly}
          />
        </CenteredBox>
      )}
      {hasExceptionDates && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CommonDateFieldRHF
              name="startExceptionDate"
              maxDate
              variant="outlined"
              controllerName={'startExceptionDate'}
              fullWidth
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonDateFieldRHF
              name="endExceptionDate"
              variant="outlined"
              controllerName={'endExceptionDate'}
              maxDate
              dateString="startExceptionDate"
              fullWidth
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
