import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { COUNTRIES_MAP } from '../../lib/constants';

interface SelectCountryFieldProps {
  name: string;
  fullWidth?: boolean;
}

export function SelectCountryField(props: SelectCountryFieldProps) {
  const { name, fullWidth = false } = props;
  const { t } = useTranslation();
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControl fullWidth={fullWidth}>
          <InputLabel id="country-label" variant="outlined">
            {t('attributes.country')}
          </InputLabel>
          <Select
            {...field}
            variant="outlined"
            labelId="country-label"
            id="country-select"
            label="Country"
          >
            {Object.entries(COUNTRIES_MAP).map(([key, value]) => (
              <MenuItem value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}
