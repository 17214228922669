import { Button, makeStyles, Theme } from '@material-ui/core';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  DeviationCause,
  DeviationFault,
  EditDeviationInput,
} from '../../generated/graphql';
import { CheckboxField } from '../form/CheckboxField';
import { CommonTextField } from '../form/CommonTextField';
import { SelectEnumField } from '../form/SelectEnumField';
import { SelectLocation } from '../form/SelectLocation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '80%',
    margin: '0 auto',
    padding: theme.spacing(2),
    justifyContent: 'center',
    border: '1px solid black',
    borderRadius: 20,
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0, 'auto', 1),
    },
    '& > *:last-child': {
      margin: theme.spacing(0, 'auto', 0),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    '& > *': {
      width: '150px',
    },
  },
  distribute: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  maximizeNote: {
    justifyContent: 'flex-start',
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      flex: 2,
    },
  },
}));

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    routeId: yup.number(),
    actualArrivalTime: yup
      .string()
      .nullable()
      .matches(/^\d{2}:\d{2}(:\d{2})?$/)
      .required(
        t('validation.isRequired', {
          name: t('attributes.arrivalTime'),
        }),
      ),
    consequence: yup.boolean(),
    cause: yup.string().oneOf(Object.keys(DeviationCause)),
    fault: yup.string().oneOf(Object.keys(DeviationFault)),
    faultLocationId: yup.number().optional(),
    note: yup.string().optional(),
  });

interface EditDeviationFormProps {
  editValues: Partial<EditDeviationInput>;
  onSubmit: (values: EditDeviationInput) => void;
  onCancel: () => void;
}

export function EditDeviationForm(props: EditDeviationFormProps) {
  const { editValues, onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const afterValidation = (values: Partial<EditDeviationInput>) => {
    const validatedInput = values as EditDeviationInput;
    onSubmit(validatedInput);
  };

  return (
    <Box>
      <Formik
        initialValues={editValues}
        onSubmit={afterValidation}
        validate={(value) => {
          try {
            validateYupSchema(value, validationSchema(t), true, value);
          } catch (err) {
            const errors = yupToFormErrors<{ costAllocation: string }>(err); //for rendering validation error
            return errors;
          }

          return {};
        }}
      >
        {() => (
          <Form className={classes.root}>
            <Box className={clsx(classes.row, classes.distribute)}>
              <CommonTextField name="actualArrivalTime" type="time" />
              <SelectEnumField
                name="cause"
                translationPath="enums.deviationCause"
                enumObject={DeviationCause}
                label={t('attributes.cause')}
              />
              <SelectEnumField
                name="fault"
                enumObject={DeviationFault}
                label={t('attributes.fault')}
                translationPath="enums.deviationFault"
              />
            </Box>
            <Box className={clsx(classes.row, classes.maximizeNote)}>
              <CheckboxField
                name="consequence"
                label={t('attributes.consequence')}
                size="small"
              />
              <SelectLocation name="faultLocationId" size="small" />
            </Box>
            <Box className={clsx(classes.row, classes.maximizeNote)}>
              <CommonTextField
                name="note"
                label={t('attributes.internalNote')}
                placeholder={t('attributes.internalNote')}
                multiline
              />
            </Box>
            <Box className={clsx(classes.row, classes.center)}>
              <Button type="submit" variant="contained" color="primary">
                {t('actions.update')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  onCancel();
                }}
              >
                {t('actions.cancel')}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
