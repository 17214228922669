import {
  Box,
  Button,
  Grid,
  makeStyles,
  Snackbar,
  Tooltip,
  Typography,
  Link,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { DataGridPro, GridCellParams } from '@mui/x-data-grid-pro';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { configuration } from '../configuration';
import { CardContactTag, GetCardQuery } from '../generated/graphql';
import { UpdateContact } from './UpdateContact';
import { useHistory } from 'react-router-dom';

interface CardContactProps {
  contacts?: GetCardQuery['card']['contacts'];
  terminal?: boolean;
  name: string;
}

const useStyles = makeStyles((theme) => {
  return {
    link: {
      color: theme.palette.primary.main,
    },
  };
});

export function CardContacts(props: CardContactProps) {
  const { terminal = false, name, contacts } = props;
  const [open, setOpen] = useState(false);
  const [modalLocationId, setmodalLocationId] = useState<number | null>(null);
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const classes = useStyles();
  const router = useHistory();

  //To calculate width of TAGS column Char wise
  let maxCharCount = 0;
  if (contacts && contacts.length > 0) {
    contacts.forEach((c) => {
      if (c.tags && c.tags.length > 0) {
        let currTagCharCount = 0;
        c.tags.forEach((t) => {
          currTagCharCount += t && t.title ? t.title.length : 0;
        });

        if (currTagCharCount > maxCharCount) {
          maxCharCount = currTagCharCount;
        }
      }
    });
  }

  return (
    <>
      <Grid container>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={2}
          >
            <Typography variant="h3">Contacts</Typography>
            {!terminal && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  router.push({
                    pathname: `${url}/createcontact`,
                    state: {
                      cardName: name,
                      timestamp: new Date().getTime(),
                    },
                  });
                }}
                startIcon={<AddIcon />}
              >
                {t('button.create', { item: 'contact' })}
              </Button>
            )}
          </Box>
        </Grid>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={contacts || []}
            disableSelectionOnClick
            onCellDoubleClick={(params) => {
              if (
                typeof params.value === 'string' ||
                typeof params.value === 'number'
              ) {
                navigator.clipboard.writeText(
                  typeof params.value === 'number'
                    ? params.value.toString()
                    : params.value,
                );
                setOpenAlert(true);
              }
            }}
            columns={[
              {
                headerName: '',
                field: '',
                width: 80,
                hide: terminal,
                renderCell: (params) => {
                  return terminal ? (
                    <></>
                  ) : (
                    <Tooltip title={`${t('actions.edit')}`}>
                      <Button
                        onClick={() => {
                          setmodalLocationId(params.row.id);
                          setOpen(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'id',
                type: 'number',
                headerName: t(`attributes.id`),
                width: 50,
                hide: configuration.production,
              },
              {
                field: 'name',
                headerName: t('attributes.name'),
                width: 140,
              },
              {
                field: 'role',
                headerName: t('attributes.role'),
                width: 140,
              },
              {
                field: 'email',
                headerName: t('attributes.email'),
                width: 160,
                renderCell: (params) => {
                  return (
                    <Link
                      className={classes.link}
                      href={`mailto:${(params.value as string).replace(
                        ' ',
                        ';',
                      )}`}
                      target="_blank"
                    >
                      {params.value}
                    </Link>
                  );
                },
              },
              {
                field: 'phoneNumber',
                headerName: t('attributes.phoneNumber'),
                width: 160,
              },
              {
                field: 'note',
                headerName: t('attributes.note'),
                width: 140,
              },
              {
                field: 'tags',
                headerName: t('attributes.contactType'),
                width: maxCharCount > 0 ? maxCharCount * 10 : 400,
                sortable: false,
                filterable: false,
                renderCell: (params: GridCellParams) => {
                  return (
                    <>
                      {params.row.tags.map(
                        (tag: CardContactTag, index: number) => {
                          return (
                            <Chip
                              label={tag.title}
                              variant="outlined"
                              color="primary"
                              key={index}
                            />
                          );
                        },
                      )}
                    </>
                  );
                },
              },
            ]}
            pageSize={5}
            rowHeight={50}
            autoHeight={true}
          />
          <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={() => {
              setOpenAlert(false);
            }}
          >
            <Alert
              onClose={() => {
                setOpenAlert(false);
              }}
              severity="success"
            >
              Copied to clipboard!
            </Alert>
          </Snackbar>
        </div>
      </Grid>
      {open && modalLocationId && (
        <UpdateContact id={modalLocationId} open={open} setOpen={setOpen} />
      )}
    </>
  );
}
