import { makeStyles } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { CenteredBox } from './CenteredBox';
import { CommonTextField } from './form/CommonTextField';

const useStyles = makeStyles({
  field: {
    width: '80%',
  },
  priceField: {
    width: '65%',
  },
});

interface RoutePriceBoxProps {
  index: number;
  readOnly: boolean;
}

export function TourTemplateRoutePriceBox({
  index,
  readOnly,
}: RoutePriceBoxProps) {
  const classes = useStyles();
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [updatingPrice, setUpdatingPrice] = useState<boolean>(true);

  const isPriceDivisionEnabled = getFieldProps(`isPriceEquallyDivided`).value;
  const mainPrice = getFieldProps(`price`).value;

  useEffect(() => {
    if (!isPriceDivisionEnabled && updatingPrice) {
      const routeCurrPrice = getFieldProps(`routes.${index}.price`).value;
      const percentage =
        Math.round(((routeCurrPrice * 100) / mainPrice) * 100) / 100;
      setFieldValue(`routes.${index}.pricePercentage`, percentage);
    }
  }, [getFieldProps(`routes.${index}.price`).value]);

  useEffect(() => {
    if (!isPriceDivisionEnabled && !updatingPrice) {
      const routeCurrPercentage = getFieldProps(
        `routes.${index}.pricePercentage`,
      ).value;
      const price =
        Math.round(((routeCurrPercentage * mainPrice) / 100) * 100) / 100;
      setFieldValue(`routes.${index}.price`, price);
    }
  }, [getFieldProps(`routes.${index}.pricePercentage`).value]);

  return (
    <CenteredBox>
      <CommonTextField
        name={`routes.${index}.price`}
        className={classes.priceField}
        type="number"
        readOnly={readOnly || isPriceDivisionEnabled === true}
        size="small"
        onFocus={() => setUpdatingPrice(true)}
      />
      <CommonTextField
        name={`routes.${index}.pricePercentage`}
        className={classes.field}
        type="number"
        readOnly={readOnly || isPriceDivisionEnabled === true}
        size="small"
        onFocus={() => setUpdatingPrice(false)}
      />
    </CenteredBox>
  );
}
