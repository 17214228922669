import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import GridOnIcon from '@mui/icons-material/GridOn';
import { updateRouteNameToValidName } from '../../utils/UpdateRouteNameToValidName';
import { useTranslation } from 'react-i18next';
import { useHttpClient } from '../../providers/HttpClientProvider';

interface DownloadCMRprops {
  legId: number;
  routeId: string;
  downloadCMRError: () => void;
}

export function DownloadCMR(props: DownloadCMRprops) {
  const { httpClient } = useHttpClient();
  const { t } = useTranslation();
  const { legId, routeId, downloadCMRError } = props;

  const handleDownloadCMR = async (legId: number) => {
    try {
      const response = await httpClient.downloadCMRBlobFromTFT({ legId });
      const updatedSafeRouteID = updateRouteNameToValidName(routeId);
      const blob = response.data;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', `${updatedSafeRouteID} - CMR`);
      anchor.click();
      window.URL.revokeObjectURL(url);
    } catch (err: any) {
      if (err.response.status === 404) {
        downloadCMRError();
      }
    }
  };
  return (
    <>
      <Tooltip
        title={`${t('actions.download', {
          item: t('resource.cmr.capitalized'),
        })}`}
      >
        <IconButton onClick={() => handleDownloadCMR(legId)}>
          <GridOnIcon color={'primary'} />
        </IconButton>
      </Tooltip>
    </>
  );
}
