import {
  debounce,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SearchSubcontractorsQuery,
  useGetSubcontractorLazyQuery,
  useGetSubcontractorsLazyQuery,
} from '../../generated/graphql';
import {
  useFormContext,
  Controller,
  Path,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiControl-root': {
      width: '100%',
    },
  },
}));

interface SelectSubcontractorProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
  fullWidth?: boolean;
}

export function SelectSubcontractorRHF(
  props: SelectSubcontractorProps & TextFieldProps,
) {
  const { name, readOnly = false, fullWidth = false, controllerName } = props;
  const { t } = useTranslation();
  const { control, getFieldState } = useFormContext<SporadicRouteFormInput>();
  const { errors } = useFormState<SporadicRouteFormInput>();

  const isErrorForField = errors && getFieldState(controllerName).error;
  const errorMessage = isErrorForField && isErrorForField?.message;

  const classes = useStyles();
  const [options, setOptions] = useState<
    SearchSubcontractorsQuery['subcontractors']
  >([]);
  const [value, setValue] = useState<
    SearchSubcontractorsQuery['subcontractors'][number] | null
  >(null);

  const [search, setSearch] = useState('');
  const [searchSubcontractors, { data: optionsData, loading: loadingOptions }] =
    useGetSubcontractorsLazyQuery({ fetchPolicy: 'network-only' });
  const [getSubcontractor, { data: subcontractorData, loading }] =
    useGetSubcontractorLazyQuery({ fetchPolicy: 'network-only' });

  const initialValue = useWatch({
    control,
    name: 'subcontractorId',
  });

  useEffect(() => {
    if (initialValue !== undefined && initialValue != null) {
      getSubcontractor({ variables: { id: initialValue } });
    }
  }, [initialValue]);

  useEffect(() => {
    if (subcontractorData) {
      setOptions([subcontractorData.subcontractor]);
      setValue(subcontractorData.subcontractor);
    }
  }, [subcontractorData]);

  useEffect(() => {
    if (search.length > 2) {
      searchSubcontractors({
        variables: {
          search: search,
          withTerminal: true,
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.subcontractors]);
      } else {
        setOptions(optionsData.subcontractors);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];

  if (readOnly) {
    return (
      <TextField
        {...props}
        variant="outlined"
        label={t(`attributes.${label}`)}
        value={value?.name}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          className={classes.root}
          value={value}
          multiple={false}
          fullWidth={fullWidth}
          autoHighlight
          onChange={(_event, val) => {
            onChange(val?.id);
            if (!val || !val.id) {
              setOptions([]);
              setValue(null);
            }
          }}
          noOptionsText=""
          onInputChange={debouncedChangeHandler}
          getOptionSelected={(option, value) => option.id === value?.id}
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={options}
          loading={loading || loadingOptions}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size="small"
              error={Boolean(isErrorForField)}
              helperText={
                (isErrorForField && errorMessage) || Boolean(isErrorForField)
              }
              variant="outlined"
              label={t(`attributes.${label}`)}
              InputProps={{ ...params.InputProps }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    />
  );
}
