import { Box, Grid, Typography } from '@material-ui/core';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { DataGridPro, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../components/Layout';
import {
  GetMeDocument,
  useConnectTerminalMutation,
  useGetInternalTerminalsQuery,
} from '../generated/graphql';

export function SelectTerminal() {
  const { t } = useTranslation();
  const history = useHistory();
  const [connectTerminal] = useConnectTerminalMutation({
    refetchQueries: [
      {
        query: GetMeDocument,
      },
    ],
  });
  const { data, loading, error } = useGetInternalTerminalsQuery({
    variables: {
      fetchFromAllDepartments: true,
    },
    fetchPolicy: 'network-only',
  });
  const internalTerminals = (data?.internalTerminals || []).map((x) => {
    const location = x.terminal.locations[0];
    const locationString =
      location != null
        ? `${location.address}, ${location.city}, ${location.countryLong}`
        : '';
    return {
      id: x.id,
      name: x.terminal.name,
      location: locationString,
    };
  });

  const onSelectTerminal = (params: GridRowParams) => {
    connectTerminal({
      variables: {
        terminalId: params.row.id,
      },
    }).then(() => {
      history.push('/terminal');
    });
  };

  return (
    <Layout pageName={t('pages.terminal.title')}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography align="left">
            <Typography variant="subtitle1" color="textPrimary">
              Date Filter Type:{' '}
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={'productionDate'}
              disabled
            >
              <ToggleButton value="productionDate">
                {t('attributes.productionDate')}
              </ToggleButton>
            </ToggleButtonGroup>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <h1>{t('pages.terminal.title')}</h1>
        </Grid>
        <Grid item xs={12}>
          <Box flexDirection="column" alignItems="center">
            <Typography variant="subtitle1">
              {t('pages.terminal.selectTerminalTitle')}
            </Typography>

            <DataGridPro
              loading={loading && data == null}
              error={error}
              autoHeight
              onRowClick={onSelectTerminal}
              columns={[
                {
                  field: 'id',
                  headerName: 'id',
                  hide: true,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  flex: 1,
                },
                {
                  headerName: 'Location',
                  field: 'location',
                  flex: 2,
                },
              ]}
              rows={internalTerminals}
            />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
