import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UnLockIcon from '@mui/icons-material/LockOpen';
import PingIcon from '@mui/icons-material/NotificationsActiveOutlined';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CreatedAndUpdatedView } from '../components/CreatedAndUpdatedView';
import { DialogModal } from '../components/DialogModal';
import { DownloadLoadingList } from '../components/DownloadLoadingList';
import { HelmetComponent } from '../components/HelmetComponent';
import { RouteDeviations } from '../components/RouteDeviations';
import { RouteForm, SporadicRouteFormInput } from '../components/RouteForm';
import {
  GetRouteLockDocument,
  RouteType,
  SporadicRouteInput,
  useDeleteSporadicRouteMutation,
  useGetMeQuery,
  useGetRouteLockQuery,
  useGetRouteQuery,
  useRouteLockSubSubscription,
  useRouteLockPingSubSubscription,
  useUpdateBookmarkMutation,
  useUpdateLockMutation,
  useUpdateSporadicRouteMutation,
  usePingRouteLockUserLazyQuery,
} from '../generated/graphql';
import { DATE_FORMAT, timeOrNull } from '../lib/date_time';
import { numberOrNull, _toNumberOrUndefined } from '../lib/formHelpers/number';
import { calculatePercentage } from '../lib/percentage';
import { useQueryParams } from '../lib/useQuery';
import { routeValidationSchema } from '../lib/validationSchema/route';
import { CreatePdf } from '../pdf/CreatePdf';
import { Alert } from '@material-ui/lab';
import PingSound from '../sound/notification_tone.wav';
import { format } from 'date-fns';
import { Prompt } from 'react-router';
import _ from 'lodash';
import { FormikProps, FormikValues } from 'formik';

export const routeStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  header: {
    margin: theme.spacing(0.5, 0, 0.5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    borderColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.main} !important`,
    cursor: 'pointer',
  },
  goBackWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(1, 0),
  },
}));

interface ViewRouteProps {
  terminal?: boolean;
}

export function ViewRoute(props: ViewRouteProps) {
  const { terminal = false } = props;
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState(false);
  const [viewBackButton, setViewBackButton] = useState(true);
  const [bookmark, setBookmark] = useState(false);
  const { t } = useTranslation();
  const classes = routeStyle();
  const router = useHistory();
  const location = useLocation();
  const locationForLoadingList = useLocation<{
    enableEditMode: boolean;
    legIdForLoadingList: number;
    viewLoadingList: boolean;
  }>();
  const formRef = useRef<FormikProps<FormikValues>>();

  const audioPlayer = useRef<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openUnlockAnywayDialog, setOpenUnlockAnywayDialog] =
    useState<boolean>(false);
  const trafficReadOnly = !terminal && !edit;
  const [routeLock, setRouteLockedBy] = useState('');
  const [routeLockAt, setRouteLockedAt] = useState('');
  const [latestPingBy, setLatestPingBy] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [showPing, setShowPing] = useState<boolean>(false);
  const [routeMsg, setRouteMsg] = useState<string>('');
  const [showRouteUpdatedStatus, setRouteUpdatedStatus] =
    useState<boolean>(false);
  const [isDeletingRoute, setDeletingRoute] = useState<boolean>(false);
  const [isUpdatingRoute, setUpdatingRoute] = useState<boolean>(false);
  const query = useQueryParams();
  const deviationQuery = query.get('deviation');
  const [deviationOpen, setDeviationOpen] = useState(deviationQuery === 'true');
  const { data: getme, loading: getmeloading } = useGetMeQuery();
  const { data: lock, loading: lockloading } = useGetRouteLockQuery({
    variables: {
      routeId: parseInt(id, 10),
    },
  });
  const legId: number | undefined =
    locationForLoadingList.state?.legIdForLoadingList;
  const enableEditMode: boolean | undefined =
    locationForLoadingList.state?.enableEditMode;

  useEffect(() => {
    if (getme && getme.me && getme.me.fullName) {
      setCurrentUser(getme.me.fullName);
      setCurrentUserId(getme.me.id ?? '-');
    }
  }, [getme]);

  useEffect(() => {
    if (lock && lock.routeLock) {
      setRouteLockedBy(lock.routeLock.lockedBy ? lock.routeLock.lockedBy : '');
      setRouteLockedAt(
        lock.routeLock.lockedAt
          ? getFormattedDate(lock.routeLock.lockedAt)
          : '',
      );
    }
  }, [lock]);

  const getFormattedDate = (date: any): string => {
    if (date && date !== '-') {
      const dt = new Date(date);
      return format(dt, `HH:mm - ${DATE_FORMAT}`);
    } else return '';
  };

  const keySaveFunction = useCallback(
    (e) => {
      if (e.altKey && e.key === 'Enter') {
        //Quick update Alt+Enter
        if (
          edit &&
          formRef &&
          formRef.current &&
          !formRef.current.isSubmitting
        ) {
          formRef.current.setFieldValue('isUpdateAndClose', false);
          formRef.current.handleSubmit();
        }
      }
    },
    [edit],
  );

  useEffect(() => {
    document.addEventListener('keydown', keySaveFunction);

    return function cleanup() {
      document.removeEventListener('keydown', keySaveFunction);
    };
  }, [keySaveFunction]);

  useRouteLockPingSubSubscription({
    variables: {
      routeId: parseInt(id, 10),
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.routeLockPing &&
        subscriptionData.data.routeLockPing.routeId === parseInt(id, 10) &&
        subscriptionData.data.routeLockPing.lockedByUserId === currentUserId &&
        subscriptionData.data.routeLockPing.pingedByUserName !== ''
      ) {
        //show snackbar
        setLatestPingBy(subscriptionData.data.routeLockPing.pingedByUserName);
        setShowPing(true);
        if (audioPlayer && audioPlayer.current) audioPlayer.current.play();
      }
    },
  });

  //for lock/unlock refresh
  useRouteLockSubSubscription({
    variables: {
      routeId: parseInt(id, 10),
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.routeLockSub &&
        subscriptionData.data.routeLockSub.routeId === parseInt(id, 10)
      ) {
        if (
          subscriptionData.data.routeLockSub.lock === 3 &&
          subscriptionData.data.routeLockSub.reload === 3 &&
          data &&
          data.route
        ) {
          //this route is deleted by other user
          if (data.route.type === 'Sporadic') {
            router.push(`/traffic/sporadic-routes`);
          } else {
            router.push('/traffic/dispatched-routes');
          }
        } else if (
          subscriptionData.data.routeLockSub.lock === 4 &&
          subscriptionData.data.routeLockSub.reload === 4 &&
          data &&
          data.route
        ) {
          //Some order is linked/unlinked by other user in this route's leg  (4)
          window.location.reload();
        } else {
          setRouteLockedBy(subscriptionData.data.routeLockSub.lockedBy);
          setRouteLockedAt(
            getFormattedDate(subscriptionData.data.routeLockSub.lockedAt),
          );

          if (
            subscriptionData.data.routeLockSub.lockedBy === '' &&
            subscriptionData.data.routeLockSub.lock === 0 &&
            subscriptionData.data.routeLockSub.reload === 1
          ) {
            window.location.reload();
          }
          if (
            subscriptionData.data.routeLockSub.lockedBy === '' &&
            subscriptionData.data.routeLockSub.lock === 0 &&
            subscriptionData.data.routeLockSub.reload === 0
          ) {
            setEdit(false);
          }
        }
      }
    },
  });

  const [updateSporadicRoute] = useUpdateSporadicRouteMutation();

  const {
    data,
    loading,
    error,
    refetch: refetchRoute,
  } = useGetRouteQuery({
    variables: {
      id: parseInt(id, 10),
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (locationForLoadingList.state?.viewLoadingList) {
      setEdit(true);
      setViewBackButton(false);
    }
    window.history.replaceState({}, document.title);
  }, [locationForLoadingList.state?.enableEditMode]);

  const [deleteRoute] = useDeleteSporadicRouteMutation();
  const [pingToUnlockApi] = usePingRouteLockUserLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleDeleteRoute = (
    id: string,
    type: string,
    isCreatedFromTerminal: boolean,
  ) => {
    deleteRoute({ variables: { id: parseInt(id, 10) } }).then((res) => {
      if (res.data?.deleteRoute) {
        if (isCreatedFromTerminal && terminal) {
          router.push(`/terminal`);
        } else if (type === 'Sporadic') {
          router.push(`/traffic/sporadic-routes`);
        } else {
          router.push('/traffic/dispatched-routes');
        }
      }
    });
  };

  const [updateLockMutation] = useUpdateLockMutation({
    refetchQueries: [
      {
        query: GetRouteLockDocument,
        variables: {
          routeId: parseInt(id, 10),
        },
      },
    ],
  });

  const [updateBookmarkMutation, { data: dataUpdateBookmark }] =
    useUpdateBookmarkMutation();

  const updateBookmark = useCallback(() => {
    if (data?.route?.tourRoute?.id) {
      updateBookmarkMutation({
        variables: {
          id: data.route.tourRoute.id,
          bookmark: !bookmark,
        },
      });
    }
  }, [bookmark, data?.route?.tourRoute?.id]);

  useEffect(() => {
    if (
      data?.route?.tourRoute?.bookmarkTourRouteTemplate?.length === 1 &&
      bookmark !== data?.route.tourRoute?.bookmarkTourRouteTemplate[0].bookmark
    ) {
      setBookmark(
        data?.route.tourRoute?.bookmarkTourRouteTemplate[0].bookmark ?? false,
      );
    }
  }, [data?.route?.tourRoute?.bookmarkTourRouteTemplate]);

  useEffect(() => {
    if (bookmark !== dataUpdateBookmark?.updateBookmark?.bookmark) {
      setBookmark(dataUpdateBookmark?.updateBookmark?.bookmark ?? false);
    }
  }, [dataUpdateBookmark?.updateBookmark?.bookmark]);

  useEffect(() => {
    if (edit) {
      if (!routeLock) {
        updateLockMutationFunc(1, 0);
      } else if (routeLock) {
        setEdit(false);
      }
    } else if (!edit) {
      if (routeLock) {
        updateLockMutationFunc(0, 1);
      }
    }
  }, [edit]);
  const onSubmit = async (values: SporadicRouteFormInput) => {
    const isCloseEditMode = values.isUpdateAndClose;
    values.isUpdateAndClose = false;

    const changes = _.differenceWith(
      _.toPairs(initialValues()),
      _.toPairs(values),
      _.isEqual,
    );

    if (changes.length > 0) {
      setUpdatingRoute(true);

      const input: SporadicRouteInput = {
        id: parseInt(id, 10),
        isCreatedFromPlanned: values.isCreatedFromPlanned,
        isCreatedFromTerminal: values.isCreatedFromTerminal,
        transportationDate: values.transportationDate,
        agreedPrice:
          typeof values.agreedPrice !== 'number' ? null : values.agreedPrice,
        currency: values.currency,
        note: values.note,
        externalNote: values.externalNote,
        driverName: values.driverName,
        driverPhoneNumber: values.driverPhoneNumber,
        licensePlate: values.licensePlate,
        capacity: numberOrNull(values.capacity),
        kilometer: _toNumberOrUndefined(values.kilometer),
        weight: _toNumberOrUndefined(values.weight),
        routeId: values.routeId,
        dispatcherId: values?.dispatcherId as number,
        subcontractorId: values?.subcontractorId as number,
        invoiceNumber: values.invoiceNumber,
        invoiceNote: values.invoiceNote,
        costAllocation: {
          id: values.costAllocation?.id,
          items:
            values.costAllocation?.items.map((item) => ({
              id: item.id,
              comment: item.comment,
              cost: item.cost,
              departmentId: item.departmentId,
              includedKpi: item.includedKpi,
              includedPrice: item.includedPrice,
              bringCost: item.bringCost,
              additionalCost: item.additionalCost,
              additionalDiscount: item.additionalDiscount,
              type: item.type,
            })) ?? [],
        },
        ftl: values.ftl,
        files: values.files.map((file) => ({
          id: file.id,
        })),
        legs: values.legs.map((leg, index) => ({
          id: leg.id,
          fuel: leg.fuel,
          position: index,
          locationId: leg.locationId as number,
          gateNumber: leg.gateNumber ?? '',
          carRegistrationNumber: leg.carRegistrationNumber ?? '',
          trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
          routeDriverName: leg.routeDriverName ?? '',
          routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
          load: leg.load,
          unload: leg.unload,
          isNonArrivalMarked: leg.isNonArrivalMarked,
          arrivalTime: leg.arrivalTime ?? '00:00',
          departureTime: timeOrNull(leg.departureTime),
          note: leg.note ?? '',
          transportationDateOffset: leg.transportationDateOffset,
          productionDate: leg.productionDate ?? values.transportationDate,
          transportationDate:
            leg.transportationDate ?? values.transportationDate,
          loadingListItems: leg.load
            ? leg.loadingListItems.map((loadingListItem) => ({
                id: loadingListItem.id as number,
                packages: numberOrNull(loadingListItem.packages),
                pallets: numberOrNull(loadingListItem.pallets),
                palletSpace: numberOrNull(loadingListItem.palletSpace),
                weight: numberOrNull(loadingListItem.weight),
                note: loadingListItem.note,
                locationId: loadingListItem.locationId as number,
                checked: loadingListItem.checked,
                isDangerous: loadingListItem.isDangerous,
                classification: loadingListItem.classification,
                unNumber: loadingListItem.unNumber,
                isLimitedQty: loadingListItem.isLimitedQty,
              }))
            : [],
        })),
      };

      await updateSporadicRoute({
        variables: {
          routeId: parseInt(id, 10),
          input,
        },
      });

      if (isCloseEditMode) {
        setUpdatingRoute(false);
        setEdit(false);
      } else {
        setRouteMsg(`${t('validation.routeUpdated')}`);
        refetchRoute({
          id: parseInt(id, 10),
        }).then(() => {
          setUpdatingRoute(false);
          setRouteUpdatedStatus(true);
        });
      }
    } else {
      //same content
      if (isCloseEditMode) {
        setEdit(false);
      } else if (edit && formRef && formRef.current) {
        setRouteMsg(`${t('validation.routeSameContent')}`);
        setRouteUpdatedStatus(true);
        formRef.current.setSubmitting(false);
      }
    }
  };

  const pingToUnlock = () => {
    pingToUnlockApi({
      variables: {
        routeId: parseInt(id, 10),
      },
    });
  };

  if (getmeloading || lockloading || loading || data == null) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const { route } = data;

  const initialValues = (): SporadicRouteFormInput => ({
    isUpdateAndClose: false,
    isCreatedFromPlanned: route.isCreatedFromPlanned,
    isCreatedFromTerminal: route.isCreatedFromTerminal,
    transportationDate: route.transportationDate,
    agreedPrice: route.agreedPrice,
    currency: route.currency,
    note: route.note,
    externalNote: route.externalNote ?? '',
    driverName: route.driverName,
    driverPhoneNumber: route.driverPhoneNumber,
    licensePlate: route.licensePlate,
    capacity: route.capacity,
    kilometer: route.kilometer,
    weight: route.weight,
    routeId: route.routeId,
    dispatcherId: route.dispatcherId || undefined,
    subcontractorId: route.subcontractorId,
    invoiceNote: route.invoiceNote,
    invoiceNumber: route.invoiceNumber,
    files: route.files,
    costAllocation: {
      ...(route.costAllocation ?? {}),
      items:
        route.costAllocation?.items.map((item) => ({
          ...item,
          percentage: item.includedPrice
            ? calculatePercentage(route.agreedPrice, item.cost)
            : 0,
        })) ?? [],
    },
    ftl: route.ftl
      ? {
          cdc: route.ftl.cdc,
          cmr: route.ftl.cmr,
          price: route.ftl.price,
          currency: route.ftl.currency,
          customerId: route.ftl.customerId,
          customerNumber: route.ftl.customerNumber,
          customerReference: route.ftl.customerReference,
          invoiceNote: route.ftl.invoiceNote,
        }
      : undefined,
    legs: route.legs.map((leg) => ({
      ...leg,
      nonArrivalMarkedFrom: leg.nonArrivalMarkedFrom ?? '',
      note: leg.note === '' ? undefined : leg.note,
      key: `${leg.id}`,
      fuel: leg.fuel,
      gateNumber: leg.gateNumber ?? '',
      carRegistrationNumber: leg.carRegistrationNumber ?? '',
      trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
      routeDriverName: leg.routeDriverName ?? '',
      routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
      loadingListItems: leg.loadingListItems.map((lli) => {
        return {
          ...lli,
          packages: lli.packages ?? '',
          pallets: lli.pallets ?? '',
          palletSpace: lli.palletSpace ?? '',
          weight: lli.weight ?? '',
          customWaybillNo: lli.customWaybillNo ?? '-',
        };
      }),
    })),
  });

  const resourceName =
    route.type === RouteType.Sporadic
      ? 'resource.sporadicRoute'
      : 'resource.dispatchedRoute';
  return (
    <Grid container spacing={1} direction="column">
      <Prompt
        key={'prompt'}
        when={edit && !isDeletingRoute}
        message={`${t('validation.routeLeaveConfirmation')}`}
      />
      <HelmetComponent
        title={showPing ? `🔔 -${route.routeId}` : route.routeId}
      />
      <div>
        <audio ref={audioPlayer} src={PingSound} />
        <Snackbar
          open={showPing}
          key={`sp-${latestPingBy}`}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={() => {
            setShowPing(false);
          }}
        >
          <Alert
            onClose={() => {
              setShowPing(false);
            }}
            elevation={4}
            severity="warning"
          >
            {`${t('validation.pingBy')} ${latestPingBy}`}
          </Alert>
        </Snackbar>
        <Snackbar
          open={showRouteUpdatedStatus}
          key={'route_updated'}
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={() => {
            setRouteUpdatedStatus(false);
          }}
        >
          <Alert elevation={4} severity="success">
            {`${routeMsg}`}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isUpdatingRoute}
          key={'route_updating'}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Alert elevation={4} severity="info">{`${t(
            'validation.updatingRoute',
          )}`}</Alert>
        </Snackbar>
      </div>
      {terminal && location.key && viewBackButton && (
        <Grid item>
          <Box className={classes.goBackWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => router.goBack()}
            >
              {t('actions.goBack')}
            </Button>
          </Box>
        </Grid>
      )}
      <Grid item>
        <Box className={classes.header}>
          <Typography variant="h1">
            {t(`${resourceName}.capitalized`)}: {route.routeId}
          </Typography>
          {route.deletedAt == null && (
            <Box>
              {routeLock && routeLock !== currentUser ? (
                <>
                  <Tooltip title={`${t('actions.unlockAnyway')}`}>
                    <IconButton
                      edge="end"
                      aria-label="Unlock"
                      style={{ marginLeft: 8 }}
                      onClick={() => setOpenUnlockAnywayDialog(true)}
                    >
                      {<UnLockIcon />}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={`${t('actions.ping')}`}>
                    <IconButton
                      edge="end"
                      aria-label="Ping"
                      style={{ marginLeft: 8 }}
                      onClick={() => pingToUnlock()}
                    >
                      {<PingIcon />}
                    </IconButton>
                  </Tooltip>

                  <DialogModal
                    open={openUnlockAnywayDialog}
                    setOpen={setOpenUnlockAnywayDialog}
                    contentText={String.raw`${t(
                      'validation.unlockConfirmation',
                    )}${data.route.routeId}?  ${t('validation.unlockWarning')}`}
                    doAction={() => {
                      setEdit(!edit);
                    }}
                    buttonText={t('button.yes')}
                  />
                </>
              ) : routeLock && routeLock === currentUser && !edit ? (
                <Tooltip title={`${t('actions.unlock')}`}>
                  <IconButton
                    edge="end"
                    aria-label="Unlock"
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    {<UnLockIcon />}
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    edit
                      ? `${t('actions.updateAndExit')}`
                      : `${t('actions.edit')}`
                  }
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => {
                      if (edit && formRef && formRef.current) {
                        formRef.current.setFieldValue('isUpdateAndClose', true);
                        formRef.current.handleSubmit();
                      } else {
                        setEdit(!edit);
                      }
                    }}
                  >
                    {edit ? <CancelIcon /> : <EditIcon />}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
        </Box>
        <Grid item>
          <CreatedAndUpdatedView
            createdBy={route.createdBy}
            lastUpdatedBy={route.lastUpdatedBy}
            updatedAt={route.updatedAt}
            deletedAt={route.deletedAt}
            deletedBy={route.deletedBy}
            lockedBy={routeLock}
            lockedAt={routeLockAt}
          />
        </Grid>
        <Box mt={1}>
          <Grid
            item
            container
            direction={'row'}
            spacing={1}
            justifyContent="space-around"
          >
            {!terminal && trafficReadOnly && routeLock === '' && (
              <CreatePdf route={route} />
            )}
            {!terminal && trafficReadOnly && routeLock === '' && (
              <Grid item xs={3}>
                <DownloadLoadingList
                  id={id}
                  routeId={route.routeId}
                  isLegAvailable={route.legs && route.legs.length > 0}
                />
              </Grid>
            )}
            {!terminal && trafficReadOnly && routeLock === '' && (
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  onClick={() => setDeviationOpen(!deviationOpen)}
                >
                  {t(deviationOpen ? 'button.closeItem' : 'button.view', {
                    item: t('resource.deviation.lowercased'),
                  })}
                </Button>
              </Grid>
            )}
            {!terminal &&
              !edit &&
              route.type === RouteType.Fixed &&
              route.deletedAt == null &&
              routeLock === '' && (
                <Grid item xs={1} style={{ transform: 'translate(0%,-15%)' }}>
                  <Tooltip
                    title={
                      bookmark
                        ? `${t('actions.remove.withItem', {
                            item: t('resource.bookmark.capitalized'),
                          })}`
                        : `${t('actions.add', {
                            item: t('resource.bookmark.capitalized'),
                          })}`
                    }
                  >
                    <IconButton
                      disabled={
                        routeLock && routeLock !== currentUser ? true : false
                      }
                      onClick={updateBookmark}
                    >
                      {bookmark ? (
                        <BookmarkRemoveIcon color="action" fontSize="large" />
                      ) : (
                        <BookmarkAddIcon color="action" fontSize="large" />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            {(!terminal || (data.route.isCreatedFromTerminal && edit)) && (
              <Grid item xs={12}>
                <Button
                  classes={{
                    root: classes.button,
                  }}
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setOpenDeleteDialog(true);
                  }}
                >
                  {t('button.delete', {
                    item: t(`${resourceName}.lowercased`),
                  })}
                </Button>
                <DialogModal
                  open={openDeleteDialog}
                  setOpen={setOpenDeleteDialog}
                  contentText={String.raw`${t(
                    'validation.deleteConfirmation',
                  )}${data.route.routeId}?`}
                  doAction={() => {
                    const type = data.route.type;
                    const isCreatedFromTerminal =
                      data.route.isCreatedFromTerminal;
                    setDeletingRoute(true);
                    handleDeleteRoute(id, type, isCreatedFromTerminal);
                  }}
                  buttonText={t('button.continue')}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
      {deviationOpen && (
        <Grid item>
          <RouteDeviations routeId={parseInt(id)} />
        </Grid>
      )}
      <Grid item>
        <RouteForm
          terminal={terminal}
          onSubmit={onSubmit}
          validationSchema={routeValidationSchema(t, !terminal)}
          initialValues={initialValues()}
          submitButtonLabel={t('button.update', {
            item: t(`${resourceName}.lowercased`),
          })}
          readOnly={edit ? edit && routeLock === '' : !edit}
          includeInvoice
          hideCostAllocation={terminal}
          formRef={formRef}
          fromCreateRoute={'ViewRoute'}
          legIdForLoadingList={legId}
          enableEditMode={enableEditMode}
        />
      </Grid>
    </Grid>
  );

  function updateLockMutationFunc(lock: number, reload: number) {
    updateLockMutation({
      variables: {
        routeId: parseInt(id, 10),
        lock: lock,
        reload: reload,
      },
    });
  }
}
