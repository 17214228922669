import { Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  DangerousGoodsClassification,
  CreateShipmentInput,
  useCreateShipmentMutation,
  useGetShipmentQuery,
  useGetShipmentTemplatesQuery,
  useShipmentListUpdateSubSubscription,
} from '../generated/graphql';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../lib/date_time';
import { ShipmentGrid } from '../components/datagrid/ShipmentGrid';
import { SelectDateRangeForm } from '../components/SelectDateRangeForm';
import { ShipmentTemplateGrid } from '../components/datagrid/ShipmentTemplateGrid';
import { CreateShipmentModal } from '../components/modal/CreateShipmentModal';

export const shipmentValidation = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(3)
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    date: yup
      .date()
      .typeError('Date is required')
      .nullable()
      .when('isTemplate', {
        is: false,
        then: yup
          .date()
          .typeError('This is required')
          .required(
            t('validation.isRequired', {
              name: t('filter.date'),
            }),
          ),
      }),
    fromLocationId: yup
      .number()
      .min(
        1,
        t('validation.isRequired', {
          name: t('attributes.location'),
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.terminal') })),
    toLocationId: yup
      .number()
      .required(t('validation.isRequired', { name: t('attributes.terminal') }))
      .positive(t('validation.isRequired', { name: t('attributes.terminal') }))
      .min(
        1,
        t('validation.isRequired', {
          name: t('attributes.terminal'),
        }),
      ),
    loadingListValue: yup
      .array(
        yup.object().shape({
          packages: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .integer(
              t('validation.noDecimals', {
                name: t('attributes.packages'),
                len: '0',
              }),
            )
            .nullable()
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.packages'),
                len: '0',
              }),
            ),
          pallets: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .integer(
              t('validation.noDecimals', {
                name: t('attributes.agreedPrice'),
                len: '0',
              }),
            )
            .nullable()
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.agreedPrice'),
                len: '0',
              }),
            ),
          palletSpace: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.palletSpace'),
                len: '0',
              }),
            ),
          weight: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.weight'),
                len: '0',
              }),
            ),
          note: yup.string().optional(),
          locationId: yup
            .number()
            .min(
              1,
              t('validation.isRequired', {
                name: t('attributes.location'),
              }),
            )
            .required(
              t('validation.isRequired', {
                name: t('attributes.location'),
              }),
            ),
          isDangerous: yup.boolean(),
          classification: yup.string().when('isDangerous', {
            is: true,
            then: yup
              .string()
              .required()
              .oneOf(Object.keys(DangerousGoodsClassification)),
            otherwise: yup.string().nullable(),
          }),
          unNumber: yup.string().when('isDangerous', {
            is: true,
            then: yup
              .string()
              .typeError(t('validation.unNumber'))
              .required(t('validation.unNumber'))
              .matches(/^[0-9]{4}$/, t('validation.unNumberLength')),
            otherwise: yup.string().nullable(),
          }),
        }),
      )
      .min(1, 'Atleast One Item is required')
      .required('Atleast One Item is required'),
  });
interface shipmentProps {
  from: string;
}

export function Shipment(props: shipmentProps) {
  const { from } = props;
  const { t } = useTranslation();
  const [createShipment] = useCreateShipmentMutation();
  const [startDate, setStartDate] = useState(format(new Date(), DATE_FORMAT));
  const [endDate, setEndDate] = useState(format(new Date(), DATE_FORMAT));
  const [showModal, setShowModal] = useState(false);

  const [viewTemplate, setViewTemplate] = useState(false);
  const { data, loading, refetch } = useGetShipmentQuery({
    variables: { startDate: startDate, endDate: endDate },
    fetchPolicy: 'no-cache',
  });
  const { data: templateData, refetch: refetchTemplates } =
    useGetShipmentTemplatesQuery({
      fetchPolicy: 'no-cache',
    });
  const handleDateRangeChanged = useCallback((values) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
  }, []);

  useShipmentListUpdateSubSubscription({
    variables: {
      startDate: startDate,
      endDate: endDate,
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.shipmentListUpdateSub
      ) {
        if (
          subscriptionData.data.shipmentListUpdateSub.shipmentDate !=
            undefined &&
          subscriptionData.data.shipmentListUpdateSub.shipmentDate != ''
        ) {
          refetch();
        } else {
          refetchTemplates();
        }
      }
    },
  });

  const onShipmentSubmit = (shipmentInput: CreateShipmentInput) => {
    createShipment({
      variables: {
        input: shipmentInput,
      },
    }).then(() => {
      if (shipmentInput.shipmentDate) {
        refetch({
          startDate: startDate,
          endDate: endDate,
        });
      } else {
        refetchTemplates();
      }
      setShowModal(false);
    });
  };

  return (
    <>
      <Grid container spacing={1}>
        <HelmetComponent
          title={`${t('actions.create.capitalized')} ${t(
            'resource.shipment.lowercased',
          )}`}
        />
      </Grid>
      {from !== 'Custom' && (
        <Grid item container alignItems="flex-start" style={{ marginTop: 10 }}>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => setShowModal(!showModal)}
              startIcon={<AddIcon />}
            >
              {t('button.create', { item: t('resource.shipment.lowercased') })}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={() => setViewTemplate(!viewTemplate)}
              startIcon={<ListAltSharpIcon />}
            >
              {!viewTemplate
                ? t('actions.viewItem', { item: t('resource.template.plural') })
                : t('actions.viewItem', {
                    item: t('resource.shipment.lowercased'),
                  })}
            </Button>
          </Grid>
          <Grid item xs={8}></Grid>
        </Grid>
      )}
      {!viewTemplate && (
        <Grid item container style={{ marginTop: 10 }} alignItems="flex-start">
          <SelectDateRangeForm
            startDate={startDate}
            endDate={endDate}
            onDateSelected={handleDateRangeChanged}
            buttonText={t('actions.apply')}
          />
        </Grid>
      )}

      <Grid item container>
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            {viewTemplate ? (
              <Grid item container style={{ marginTop: 10 }}>
                <ShipmentTemplateGrid
                  rows={templateData?.shipmentTemplates ?? []}
                  listUpdated={() => {
                    refetch({
                      startDate: startDate,
                      endDate: endDate,
                    });
                    refetchTemplates();
                  }}
                  from={from}
                />
              </Grid>
            ) : (
              <Grid item container style={{ marginTop: '5' }}>
                <ShipmentGrid
                  rows={data?.shipments ?? []}
                  listUpdated={() => {
                    refetch({
                      startDate: startDate,
                      endDate: endDate,
                    });
                    refetchTemplates();
                  }}
                  from={from}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      {showModal && (
        <CreateShipmentModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          from={from}
          onSubmit={(input) => onShipmentSubmit(input)}
        />
      )}
    </>
  );
}
