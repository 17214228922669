import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useFormState } from 'react-hook-form';
//import { useFormikContext } from 'formik';

interface AccordionContainerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children: React.ReactNode;
  accordionTitle: string;
  summaryInputProps?: AccordionSummaryProps;
  button?: boolean;
  buttonTitle?: string;
  buttonOnClick?: () => void;
  errors?: unknown[];
}

const useStyle = makeStyles((theme: Theme) => ({
  accordionContainer: {
    marginBottom: theme.spacing(1),
    '&.MuiAccordionDetails-root': {
      padding: 0,
    },
    '& > .MuiAccordionSummary': {
      margin: 0,
    },
  },
  summary: {
    padding: '0 !important',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',

    '& .withButton': {
      justifyContent: 'space-between',
    },
  },
  headerText: {
    paddingLeft: theme.spacing(1),
  },
}));

export function AccordionContainerRHF(
  props: AccordionContainerProps & AccordionProps,
) {
  const {
    open,
    setOpen,
    children,
    accordionTitle,
    summaryInputProps,
    button = false,
    buttonOnClick = undefined,
    errors = [],
    buttonTitle = '',
    ...rest
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const { isSubmitting } = useFormState();

  useEffect(() => {
    if (isSubmitting && errors.some(Boolean)) {
      setOpen(true);
    }
  }, [isSubmitting, errors]);

  return (
    <Accordion
      {...rest}
      expanded={open}
      onChange={() => {
        setOpen(!open);
      }}
      style={{ boxShadow: 'none', ...rest.style }}
    >
      <AccordionSummary
        {...summaryInputProps}
        expandIcon={open ? <RemoveIcon /> : <AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.summary}
      >
        <Box
          className={clsx([
            classes.header,
            {
              withButton: button,
            },
          ])}
        >
          <Typography className={classes.headerText} variant="subtitle1">
            {t(accordionTitle)}
          </Typography>
          {button && <Button onClick={buttonOnClick}>{buttonTitle}</Button>}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContainer}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
