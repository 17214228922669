import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ViewCard } from '../components/ViewCard';
import {
  GetSubcontractorDocument,
  GetSubcontractorsDocument,
  useDeleteSubcontractorMutation,
  useGetSubcontractorQuery,
  useUpdateSubcontractorMutation,
} from '../generated/graphql';

interface ViewSubcontractorProps {
  terminal?: boolean;
}

export function ViewSubcontractor(props: ViewSubcontractorProps) {
  const { terminal = false } = props;
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const { loading, error, data } = useGetSubcontractorQuery({
    variables: { id: parseInt(id, 10) },
  });
  const [editOpen, setEditOpen] = useState(false);

  const [updateSubcontractor, {}] = useUpdateSubcontractorMutation({
    refetchQueries: [
      {
        query: GetSubcontractorDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const [deleteSubcontractor] = useDeleteSubcontractorMutation({
    refetchQueries: [
      {
        query: GetSubcontractorsDocument,
      },
    ],
  });

  const handleDeleteSubcontractor = (id: number) => {
    deleteSubcontractor({ variables: { id } });
    router.push(`/traffic/cards/subcontractors`);
  };

  const onSubmit = (values: { name: string; id: number }) => {
    updateSubcontractor({ variables: { ...values } });
    setEditOpen(false);
  };

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }

  return (
    <ViewCard
      name={data.subcontractor.name}
      id={data.subcontractor.id}
      type="subcontractor"
      createdBy={data.subcontractor.createdBy}
      lastUpdatedBy={data.subcontractor.lastUpdatedBy}
      updatedAt={data.subcontractor.updatedAt}
      files={data.subcontractor.files}
      contacts={data.subcontractor.contacts}
      onSubmit={onSubmit}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      deleteCard={handleDeleteSubcontractor}
      terminal={terminal}
    />
  );
}
