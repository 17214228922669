import { gql } from '@apollo/client';
import { Tooltip } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { format, parseISO } from 'date-fns';
import { Field, FieldProps, Form, Formik } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Deviation,
  DeviationToCustomer,
  GetDeviationForEmailDocument,
  GetDeviationForEmailQuery,
  useGetDeviationForEmailQuery,
  useSendDeviationMutation,
} from '../generated/graphql';
import { printDateTimeLocale } from '../lib/format';
import { renderLocation } from '../lib/location';
import { usePrevious } from '../utils/UsePrevious';
import { CommonTextField } from './form/CommonTextField';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '70vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

const infoTextStyle: SxProps<Theme> = {
  mt: 1,
};

type ViewDeviationItem = Partial<Deviation>;

interface ViewDeviationModalProps {
  item: ViewDeviationItem | null;
  handleClose: () => void;
  type?: string;
}

export function ViewDeviationModal(props: ViewDeviationModalProps) {
  const { item, handleClose, type } = props;
  return (
    <Modal
      open={!!item}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton style={{ marginLeft: 800 }} onClick={handleClose}>
          <CloseIcon fontSize="large" color="action" />
        </IconButton>
        <ViewDeviation item={item} type={type} />
      </Box>
    </Modal>
  );
}

interface ViewDeviationProps {
  item: ViewDeviationItem | null;
  type?: string;
}

export function ViewDeviation(props: ViewDeviationProps) {
  const { item, type } = props;
  const { t } = useTranslation();
  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Deviation: {item?.id}
      </Typography>
      <Typography id="modal-modal-description" style={{ marginTop: 2 }}>
        {t('attributes.faultLocationId')}:
        {item && typeof item?.faultLocation === 'string'
          ? item?.faultLocation
          : renderLocation(item?.faultLocation)}
      </Typography>
      <Typography id="modal-modal-description" style={{ marginTop: 2 }}>
        {t('attributes.toLocationId')}:
        {item && typeof item?.toLocation === 'string'
          ? item?.toLocation
          : renderLocation(item?.toLocation)}
      </Typography>
      <Typography sx={infoTextStyle}>
        {t('attributes.actualArrTime')}: {item?.actualArrivalTime}
      </Typography>
      <Typography sx={infoTextStyle}>
        {t('attributes.emailSent')} :{' '}
        {typeof item?.emailSent === 'string'
          ? format(parseISO(item?.emailSent), 'yyyy-MM-dd HH:mm')
          : ''}
      </Typography>
      <Typography sx={infoTextStyle}>
        {t('attributes.causeReason')}:{' '}
        {t(`enums.remainingGoodsReasonClass.${item?.cause}`)}
      </Typography>
      <Typography sx={infoTextStyle}>
        {t('attributes.fault')}: {t(`enums.deviationFault.${item?.fault}`)}
      </Typography>
      <Typography
        sx={{ ...infoTextStyle, display: 'flex', alignItems: 'center' }}
      >
        {t('attributes.consequence')}:{' '}
        {item?.consequence === true ? <CheckIcon /> : <CloseIcon />}
      </Typography>
      <Typography
        sx={{ ...infoTextStyle, display: 'flex', alignItems: 'center' }}
      >
        {t('attributes.deviationType')}:{' '}
        {type && type === 'Terminal' ? 'Terminal' : 'Route'}
      </Typography>
      <Box>
        <SendDeviationEmail deviationId={item?.id} type={type} />
      </Box>
    </>
  );
}

const fullRowNote = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  mb: 2,
  '& > *': {
    width: '90%',
  },
  '& > *:first-child': {
    mt: 2,
  },
};

const sendEmailFormStyle: SxProps<Theme> = {
  border: '1px solid black',
  borderRadius: 2,
  padding: 2,
  mt: 2,
};

interface SendDeviationEmailProps {
  deviationId?: number;
  type?: string;
}

export interface DeviationAlert {
  type: 'error' | 'success';
  message: string;
}

export function SendDeviationEmail(props: SendDeviationEmailProps) {
  const { deviationId, type } = props;
  const { t } = useTranslation();
  const [alert, setAlert] = useState<DeviationAlert | null>(null);
  const [customersWithEMailSent, setCustomersWithEMailSent] = useState<
    | Map<
        DeviationToCustomer['customerId'],
        DeviationToCustomer['lastEmailSent']
      >
    | undefined
  >(undefined);

  if (deviationId == null) {
    return null;
  }

  const { data } = useGetDeviationForEmailQuery({
    variables: {
      id: deviationId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const [sendDeviation, { loading }] = useSendDeviationMutation();

  let customers: GetDeviationForEmailQuery['deviation']['customers'] =
    data?.deviation.customers ?? [];
  if (customers) {
    const sortCustomer = [...customers];
    sortCustomer.sort((a, b) => a.name.localeCompare(b.name));
    customers = sortCustomer;
  }
  const deviation: GetDeviationForEmailQuery['deviation'] | undefined =
    data?.deviation;
  const previousDeviation: GetDeviationForEmailQuery['deviation'] | undefined =
    usePrevious(deviation);

  useEffect(() => {
    if (
      deviation &&
      deviation.deviationToCustomer &&
      deviation.deviationToCustomer.length > 0 &&
      !_.isEqual(deviation, previousDeviation)
    ) {
      const custWithEmailSentMap: Map<
        DeviationToCustomer['customerId'],
        DeviationToCustomer['lastEmailSent']
      > = new Map();
      deviation.deviationToCustomer.map((deviationToCustomerWithMailSent) => {
        if (
          deviationToCustomerWithMailSent &&
          deviationToCustomerWithMailSent.customerId &&
          deviationToCustomerWithMailSent.lastEmailSent
        ) {
          return custWithEmailSentMap.set(
            deviationToCustomerWithMailSent.customerId,
            printDateTimeLocale(
              deviationToCustomerWithMailSent.lastEmailSent,
              'yyyy-MM-dd HH:mm',
            ),
          );
        }
      });
      if (custWithEmailSentMap.size > 0) {
        setCustomersWithEMailSent(custWithEmailSentMap);
      }
    } else if (
      deviation &&
      deviation?.deviationToLocation &&
      deviation?.deviationToLocation?.length > 0 &&
      !_.isEqual(deviation, previousDeviation)
    ) {
      const custWithEmailSentMap: Map<
        DeviationToCustomer['customerId'],
        DeviationToCustomer['lastEmailSent']
      > = new Map();
      deviation.deviationToLocation.map((deviationToLocationWithMailSent) => {
        if (
          deviationToLocationWithMailSent &&
          deviationToLocationWithMailSent.location?.card?.id &&
          deviationToLocationWithMailSent.lastEmailSent
        ) {
          return custWithEmailSentMap.set(
            deviationToLocationWithMailSent.location.card.id,
            printDateTimeLocale(
              deviationToLocationWithMailSent.lastEmailSent,
              'yyyy-MM-dd HH:mm',
            ),
          );
        }
      });
      if (custWithEmailSentMap.size > 0) {
        setCustomersWithEMailSent(custWithEmailSentMap);
      }
    }
  }, [deviation]);

  const initialValues: {
    customers: number[];
    englishMessageCustomers: string;
    localMessageCustomers: string;
    type?: string;
  } = {
    customers: customers
      .filter((customer) => customer.contacts.length > 0)
      .map((x) => x.id),
    englishMessageCustomers: deviation?.englishMessageCustomers ?? '',
    localMessageCustomers: deviation?.localMessageCustomers ?? '',
    type: type ?? 'Route',
  };

  const onSubmit = async (values: {
    customers: number[];
    localMessageCustomers: string;
    englishMessageCustomers: string;
    type?: string;
  }) => {
    setAlert(null);

    try {
      await sendDeviation({
        variables: {
          input: {
            customers: values.customers,
            englishMessageCustomers: values.englishMessageCustomers,
            id: deviationId,
            localMessageCustomers: values.localMessageCustomers,
            type: type ?? 'Route',
          },
        },
        refetchQueries: [
          {
            query: GetDeviationForEmailDocument,
            variables: {
              id: deviationId,
            },
          },
        ],
        update(cache, { data }) {
          cache.writeFragment({
            id: `Deviation:${deviationId}`,
            data: {
              ...data?.sendDeviation,
            },
            fragment: gql`
              fragment NewDeviation on Deviation {
                id
                englishMessageCustomers
                localMessageCustomers
                emailSent
              }
            `,
          });
        },
      });
      setAlert({
        type: 'success',
        message: 'Email sent',
      });
    } catch (err) {
      setAlert({
        type: 'error',
        message: (err as Error).message,
      });
    }
  };

  const [open, setOpen] = useState<number[]>([]);

  return (
    <Box sx={sendEmailFormStyle}>
      <Typography variant="subtitle1">
        {t('actions.send', { item: t('attributes.email') })}
      </Typography>
      <Collapse in={alert != null}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlert(null);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={alert?.type}
        >
          {alert?.message}
        </Alert>
      </Collapse>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form>
            <List dense>
              {customers.map((customer) => {
                const labelId = `checkbox-list-label-${customer.id}`;
                return (
                  <Box key={customer.id}>
                    <ListItem
                      dense
                      secondaryAction={
                        customer.contacts.length > 0 && (
                          <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => {
                              const newOpen = open.filter(
                                (x) => x !== customer.id,
                              );
                              if (newOpen.length === open.length) {
                                newOpen.push(customer.id);
                              }
                              setOpen(newOpen);
                            }}
                          >
                            {open.includes(customer.id) ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        )
                      }
                    >
                      <Field
                        name="customers"
                        type="checkbox"
                        value={customer.id}
                      >
                        {({ field, form }: FieldProps) => (
                          <ListItemButton
                            onClick={() => {
                              if (customer.contacts.length === 0) {
                                return;
                              }

                              if (field.checked) {
                                const values = form.getFieldProps(
                                  field.name,
                                ).value;
                                form.setFieldValue(
                                  field.name,
                                  values.filter(
                                    (x: number) => x !== customer.id,
                                  ),
                                );
                              } else {
                                const values = form.getFieldProps(
                                  field.name,
                                ).value;
                                form.setFieldValue(field.name, [
                                  ...values,
                                  customer.id,
                                ]);
                              }
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={field.checked ?? false}
                                disabled={customer.contacts.length === 0}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            {customersWithEMailSent &&
                              customersWithEMailSent.get(customer.id) && (
                                <>
                                  <Tooltip
                                    title={`Last Email Sent At: ${customersWithEMailSent.get(
                                      customer.id,
                                    )}`}
                                  >
                                    <ListItemIcon>
                                      <MarkEmailReadIcon
                                        fontSize="small"
                                        color="info"
                                      />
                                    </ListItemIcon>
                                  </Tooltip>
                                  <ListItemText
                                    secondary={`${customersWithEMailSent.get(
                                      customer.id,
                                    )}`}
                                  />
                                </>
                              )}
                            <ListItemText
                              id={labelId}
                              primary={`${customer.name}${
                                customer.contacts.length > 0
                                  ? ''
                                  : ' - No Deviation receivers for customer'
                              }`}
                            />
                          </ListItemButton>
                        )}
                      </Field>
                    </ListItem>
                    <Collapse
                      in={open.includes(customer.id)}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List dense>
                        {customer.contacts.map((contact) => (
                          <ListItem
                            dense
                            key={`${contact.name}-${contact.email}`}
                          >
                            <ListItemText
                              primary={`${contact.name} - ${contact.email}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </Box>
                );
              })}
            </List>
            <Box sx={fullRowNote}>
              <CommonTextField name="localMessageCustomers" multiline />
            </Box>
            <Box sx={fullRowNote}>
              <CommonTextField name="englishMessageCustomers" multiline />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                disabled={values.customers.length === 0 || loading}
                variant="contained"
                color="primary"
              >
                {t('actions.send', { item: t('attributes.email') })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
