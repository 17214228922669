import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonStyle } from './ViewCard';

interface DialogModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  contentText: string;
  buttonText?: string;
  doAction: () => void;
  cancelAction: () => void;
}

export function DialogConfirmationModal(props: DialogModalProps) {
  const {
    open,
    setOpen,
    title,
    contentText,
    doAction,
    buttonText = 'Delete',
    cancelAction = undefined,
  } = props;
  const classes = buttonStyle();
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancelAction} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button
          classes={{
            root: classes.button,
          }}
          onClick={() => {
            doAction();
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
