import { CircularProgress, Divider } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentFile } from '../../generated/graphql';
import { useHttpClient } from '../../providers/HttpClientProvider';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height: '55vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface UploadDocModalProps {
  handleClose: () => void;
  open: boolean;
  showLoading: boolean;
  extraData:
    | {
        infoType: string;
        infoId: number;
        existingFiles: Pick<
          DocumentFile,
          'id' | 'size' | 'path' | 'originalname' | 'mimetype'
        >[];
      }
    | undefined;
  onUploadStart: (infoId: number, file: File) => void;
  onDeleteClicked: (infoId: number, fileId: number) => void;
}

export function UploadInfoDocModal(props: UploadDocModalProps) {
  const {
    handleClose,
    open,
    showLoading,
    extraData,
    onUploadStart,
    onDeleteClicked,
  } = props;
  const { t } = useTranslation();
  const MAX_FILE_SIZE_ALLOWED = 1024 * 1024 * 5; //5 MB
  const MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE_ALLOWED / 1024 / 1024; //for message

  const { httpClient } = useHttpClient();
  const [fileError, setFileError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChange = (files: FileList | null) => {
    setFileError(false);
    if (files && files.length) {
      const file = files[0];

      if (
        file &&
        (file.type.includes('officedocument') ||
          file.type.includes('jpg') ||
          file.type.includes('jpeg') ||
          file.type.includes('png') ||
          file.type.includes('pdf')) &&
        file.size < MAX_FILE_SIZE_ALLOWED
      ) {
        onUploadStart(extraData ? extraData.infoId : 0, file);
      } else {
        if (fileInput.current) {
          const f = fileInput.current as HTMLInputElement;
          f.value = '';
        }
        setErrorMessage(`
          ${t('validation.improperDocFileForUpload', {
            sizeInMB: MAX_FILE_SIZE_IN_MB,
          })}
        `);
        setFileError(true);
      }
    }
  };

  const handleDeleteFile = () => {
    //As of now only one file is there so delete 1st file
    if (extraData?.existingFiles && extraData.existingFiles.length > 0) {
      const myFile = extraData.existingFiles[0];
      onDeleteClicked(extraData.infoId, myFile.id);
    }
  };

  const handleDownloadFile = () => {
    if (extraData?.existingFiles && extraData.existingFiles.length > 0) {
      const myFile = extraData.existingFiles[0];
      httpClient
        .downloadFile(
          {
            path: myFile.path,
          },
          'blob',
        )
        .then((res) => {
          //convert Blob to File and download
          const imageFile: any = res.data;
          imageFile.name = `${myFile.originalname}`;
          imageFile.originalName = `${myFile.originalname}`;
          imageFile.lastModifiedDate = new Date();

          const url = window.URL.createObjectURL(imageFile);
          const anchor = document.createElement('a');
          anchor.setAttribute('href', url);
          anchor.setAttribute('download', `${myFile.originalname}`);
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{`${t('info.uploadDocument')}`}</Typography>

          {!showLoading && (
            <IconButton style={{ marginLeft: 8 }} onClick={handleClose}>
              <CloseIcon fontSize="large" color="action" />
            </IconButton>
          )}
        </Box>

        {!showLoading && (
          <>
            <Typography
              style={{ fontSize: 15 }}
            >{`Type: ${extraData?.infoType}`}</Typography>
            <Typography
              style={{ fontSize: 13 }}
            >{`InfoID: [${extraData?.infoId}]`}</Typography>

            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            <Typography style={{ fontSize: 13 }}>{`${t(
              'info.docUploadMessage',
              { maxFileSizeInMB: MAX_FILE_SIZE_IN_MB },
            )}`}</Typography>

            {/* upload file controls */}
            <div style={{ marginTop: '24px' }}>
              <input
                ref={fileInput}
                type="file"
                onChange={(e) => handleChange(e.target.files)}
                accept=".xlsx,.xls,.jpg,.jpeg,.png,.doc,.docx,.ppt,.pptx,.pdf"
              />
            </div>
            {fileError && (
              <Typography style={{ fontSize: 13, color: 'red', marginTop: 5 }}>
                {errorMessage}
              </Typography>
            )}
            {extraData &&
              extraData.existingFiles &&
              extraData.existingFiles.length > 0 && (
                <>
                  <Typography style={{ fontSize: 11, marginTop: 6 }}>
                    {`${t('info.fileWillBeReplaced')}`}
                  </Typography>
                  <Divider style={{ marginTop: 10, marginBottom: 10 }} />

                  <Stack direction="row" spacing={1}>
                    <Typography style={{ fontSize: 13 }}>
                      {`${t('info.fileExistHere', {
                        fileName: extraData.existingFiles[0].originalname,
                      })}`}
                    </Typography>
                    <div onClick={handleDownloadFile}>
                      <Typography
                        style={{
                          fontSize: 13,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {`${t('attributes.clickToDownload')}`}
                      </Typography>
                    </div>
                    <Typography style={{ fontSize: 13 }}>{` | `}</Typography>
                    <div onClick={handleDeleteFile}>
                      <Typography
                        style={{
                          fontSize: 13,
                          color: '#fc3926',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                      >
                        {`${t('attributes.clickToDelete')}`}
                      </Typography>
                    </div>
                  </Stack>
                </>
              )}
          </>
        )}

        {showLoading && (
          <CircularProgress style={{ marginTop: 5, alignSelf: 'center' }} />
        )}
      </Box>
    </Modal>
  );
}
