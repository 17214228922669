import { makeStyles } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';

interface TimePickerFieldProps {
  name: string;
  readOnly?: boolean;
}
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  inputValTime: {
    height: 15,
    width: 60,
    fontSize: 15,
  },
}));

export function TimePickerField(props: TimePickerFieldProps) {
  const { name, readOnly = false } = props;
  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Box
          ml={1}
          mr={1}
          alignItems="center"
          border={1}
          borderColor="grey.500"
          sx={{
            minWidth: 150,
            borderRadius: 5,
          }}
          justifyContent="center"
          display="flex"
        >
          <KeyboardTimePicker
            {...field}
            placeholder="00:00"
            autoComplete="off"
            mask="__:__"
            label={t(`attributes.${label}`)}
            id="name"
            KeyboardButtonProps={{
              disabled: true,
              style: { display: 'none' },
            }}
            value={field.value ? `1-1-2023 ${field.value}` : null}
            InputProps={{
              inputProps: {
                step: 300, // 5 min
                style: {
                  padding: 8,
                },
              },
              readOnly,
              style: {
                fontSize: 14,
              },
              classes: { input: classes.inputValTime },
              disableUnderline: true,
              maxRows: 1,
            }}
            invalidDateMessage={'Invalid'}
            ampm={false}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            onChange={(date) => {
              date ? date.toString() : '';
              if (date && date.toString() !== 'Invalid Date') {
                const dt = new Date(date);
                const newDt = format(dt, 'HH:mm');
                form.setFieldValue(name, newDt);
              } else {
                form.setFieldValue(name, date);
              }
            }}
            onBlur={(e) => {
              form.handleBlur(e);
            }}
          />
        </Box>
      )}
    </Field>
  );
}
