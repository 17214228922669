import { ClassNameMap } from '@material-ui/styles';
import { CenteredBox } from '../CenteredBox';
import { CommonTextField } from './CommonTextField';

interface DriverFieldsProps {
  classes: ClassNameMap<'button' | 'field' | 'categoryHeader'>;
  trafficReadOnly: boolean;
  terminalEditable?: boolean;
}

export function DriverFields(props: DriverFieldsProps) {
  const { classes, trafficReadOnly, terminalEditable = false } = props;
  return (
    <>
      <CenteredBox>
        <CommonTextField
          name="driverName"
          className={classes.field}
          readOnly={trafficReadOnly && !terminalEditable}
        />
      </CenteredBox>
      <CenteredBox>
        <CommonTextField
          name="driverPhoneNumber"
          className={classes.field}
          readOnly={trafficReadOnly && !terminalEditable}
        />
      </CenteredBox>
      <CenteredBox>
        <CommonTextField
          name="licensePlate"
          className={classes.field}
          readOnly={trafficReadOnly}
        />
      </CenteredBox>
    </>
  );
}
