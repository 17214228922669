import { Box, IconButton, Typography } from '@material-ui/core';
import RemoveIcon from '@mui/icons-material/Remove';
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { useFormState } from 'react-hook-form';

interface HideableFormFieldsProps {
  show: boolean;
  onHideClicked: () => void;
  children: ReactNode;
  title: string;
  errors?: unknown[];
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function HideableFormFieldsRHF(props: HideableFormFieldsProps) {
  const { show, onHideClicked, children, title, errors, setOpen } = props;

  if (errors != null) {
    const { isSubmitting } = useFormState();
    useEffect(() => {
      if (isSubmitting && errors.some(Boolean)) {
        setOpen(true);
      }
    }, [isSubmitting, errors]);
  }

  if (!show) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="subtitle1">{title}</Typography>
        <IconButton onClick={onHideClicked}>
          <RemoveIcon />
        </IconButton>
      </Box>
      {children}
    </Box>
  );
}
