import { useGetGridValuesForTrafficQuery } from '../generated/graphql';
import { Form, Formik, FieldArray } from 'formik';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextCellForGrid } from '../components/form/TextCellForGrid';

interface GridProps {
  internalTerminalId: number;
  selectedDate: string;
  gridType: string;
}

export function TrafficGridValues(props: GridProps) {
  const { internalTerminalId, selectedDate, gridType } = props;
  const { t } = useTranslation();
  const { data: gridData, loading: gridLoading } =
    useGetGridValuesForTrafficQuery({
      fetchPolicy: 'network-only',
      variables: {
        internalTerminalId: internalTerminalId,
        valueAddedDate: selectedDate,
        gridType: gridType,
      },
    });

  return (
    <Paper
      style={{
        width: '100%',
      }}
    >
      <Box>
        {gridLoading && (
          <div style={{ width: '90%', margin: 8 }}>
            <CircularProgress style={{ margin: 8, alignSelf: 'center' }} />
          </div>
        )}

        {/* {showGrid && */}

        {!gridLoading &&
        gridData?.getGridValuesForTraffic !== undefined &&
        gridData?.getGridValuesForTraffic.length > 0
          ? gridData?.getGridValuesForTraffic.map((record) => {
              const rowHeaders = record.rowHeaders;
              const rowHeaderLength = record.rowHeaders.find(
                (rowHeader) => rowHeader.length > 11,
              );
              const colHeaders = record.columnHeaders;
              const masterId = record.gridEntryId;
              const gridEntries = record.gridEntries;
              const gridName = record.gridName;
              const date = record?.valueAddedDate ? record.valueAddedDate : '';
              const isNumberGridWithTotal = record.isNumberGridWithTotal;

              //to sort rows and their cells accordingly
              const sortedRowHeaders = [...rowHeaders].sort();
              //if grid with "Total row" then removing the 'total row',
              //then sorting the row cells and then again attach 'total row'
              const gridEntriesTotal = isNumberGridWithTotal
                ? [...gridEntries].pop()
                : null;
              const gridEntriesExcludedTotal = isNumberGridWithTotal
                ? [...gridEntries].slice(0, gridEntries.length - 1)
                : null;
              const GridEntriesToSort = isNumberGridWithTotal
                ? gridEntriesExcludedTotal
                : gridEntries;

              //stored the postion of index before and after sorting the rows
              //so that we can sort gridEntries in the same way we sorted rows
              const indexAfterSorting = sortedRowHeaders.map((value) =>
                rowHeaders.indexOf(value),
              );

              const sortedGridEntries = GridEntriesToSort?.slice().sort(
                (a: any, b: any) => {
                  const aRowPos = a.rowPos;
                  const bRowPos = b.rowPos;
                  const aIndex = indexAfterSorting.indexOf(aRowPos);
                  const bIndex = indexAfterSorting.indexOf(bRowPos);
                  return aIndex - bIndex;
                },
              );

              isNumberGridWithTotal
                ? sortedGridEntries.push(gridEntriesTotal)
                : null;

              return (
                <>
                  <Formik
                    initialValues={{
                      rowHeadersArr: rowHeaders
                        ? isNumberGridWithTotal
                          ? [...sortedRowHeaders, 'Total >']
                          : sortedRowHeaders
                        : [],
                      columnHeadersArr: colHeaders ? colHeaders : [],
                      gridEntriesArray: sortedGridEntries
                        ? sortedGridEntries
                        : [],
                    }}
                    enableReinitialize={true}
                    onSubmit={() => {
                      return;
                    }}
                  >
                    {(props) => (
                      <Form>
                        <>
                          <div style={{ marginTop: 2, marginBottom: 2 }}>
                            <FieldArray
                              name="gridEntriesArray"
                              render={() => {
                                return (
                                  <>
                                    <Grid item container>
                                      <Grid
                                        item
                                        xs={
                                          gridName.length > 11 ||
                                          rowHeaderLength
                                            ? 2
                                            : 1
                                        }
                                      >
                                        <div
                                          style={{
                                            padding: '4px 6px 4px 6px',
                                            backgroundColor: '#76ff03',
                                            fontSize: 12,
                                            justifyContent: 'center',
                                            border: '1px solid #000',
                                            marginTop: 3,
                                            marginBottom: 1,
                                            borderRadius: 2,
                                            minHeight: 29,
                                          }}
                                        >
                                          {`${gridName}`}
                                        </div>
                                      </Grid>
                                      {props.values.columnHeadersArr.map(
                                        (colHeader) => {
                                          return (
                                            <Grid item xs={1}>
                                              <div
                                                style={{
                                                  padding: '4px 6px 4px 6px',
                                                  backgroundColor: '#d4d4d4',
                                                  fontSize: 12,
                                                  justifyContent: 'center',
                                                  border: '1px solid #000',
                                                  marginTop: 3,
                                                  flexDirection: 'column',
                                                  borderRadius: 2,
                                                  minHeight: 29,
                                                }}
                                              >
                                                {`${colHeader}`}
                                              </div>
                                            </Grid>
                                          );
                                        },
                                      )}
                                    </Grid>
                                    {props.values.rowHeadersArr.map(
                                      (rowHeader, rowIndex) => {
                                        return (
                                          <>
                                            <Grid container direction="row">
                                              <Grid
                                                item
                                                xs={
                                                  gridName.length > 11 ||
                                                  rowHeaderLength
                                                    ? 2
                                                    : 1
                                                }
                                              >
                                                <div
                                                  style={{
                                                    // padding: '4px 6px 4px 6px',
                                                    backgroundColor: '#d4d4d4',
                                                    fontSize: 12,
                                                    justifyContent: 'center',
                                                    border: '1px solid #000',
                                                    borderRadius: 2,
                                                    minHeight: 29,
                                                  }}
                                                >
                                                  {`${rowHeader}`}
                                                </div>
                                              </Grid>
                                              {props.values.columnHeadersArr.map(
                                                (colHeader, colIndex) => {
                                                  const rowPosFromGridEntriesArr =
                                                    props.values
                                                      ?.gridEntriesArray[
                                                      rowIndex
                                                    ]?.rowPos;
                                                  const colPosFromGridEntriesArr =
                                                    props.values
                                                      ?.gridEntriesArray[
                                                      rowIndex
                                                    ]?.columnArr[colIndex]
                                                      ?.columnPos;
                                                  return (
                                                    <Grid item xs={1}>
                                                      <TextCellForGrid
                                                        name={`gridEntriesArray[${rowIndex}].columnArr[${colIndex}].value`}
                                                        noLabel={true}
                                                        smallPadding={true}
                                                        isTotalCell={
                                                          isNumberGridWithTotal &&
                                                          rowHeader ===
                                                            'Total >'
                                                        }
                                                        allowOnlyNumbers={
                                                          isNumberGridWithTotal
                                                        }
                                                        type={
                                                          isNumberGridWithTotal
                                                            ? 'number'
                                                            : 'text'
                                                        }
                                                        readOnly={true}
                                                        gridMasterId={masterId}
                                                        columnPosition={
                                                          colPosFromGridEntriesArr
                                                        }
                                                        rowPosition={
                                                          rowPosFromGridEntriesArr
                                                        }
                                                        valueAddedDate={date}
                                                      />
                                                    </Grid>
                                                  );
                                                },
                                              )}
                                            </Grid>
                                          </>
                                        );
                                      },
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div>
                          <Divider color="black" variant="fullWidth" />
                        </>
                      </Form>
                    )}
                  </Formik>
                </>
              );
            })
          : !gridLoading && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    {`${t('resource.noGridAdded.capitalized')}`}
                  </Typography>
                </Grid>
              </Grid>
            )}
      </Box>
    </Paper>
  );
}
