import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useFormContext, Path, Controller } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

interface CheckboxIconFieldProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  icon: ReactNode;
  checkedIcon: ReactNode;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  handleEdit?: (
    e: React.SyntheticEvent,
    name: string,
    editedField: string,
    checked?: boolean,
  ) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: '1px',
    },
  }),
);

export function CheckboxIconFieldRHF(props: CheckboxIconFieldProps) {
  const classes = useStyles();
  const { control } = useFormContext<SporadicRouteFormInput>();

  const {
    name,
    readOnly = false,
    size = undefined,
    disabled = false,
    icon,
    checkedIcon,
    controllerName,
  } = props;

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          id={name}
          className={classes.formControl}
          disabled={readOnly || disabled}
          control={
            <Checkbox
              size={size}
              color="primary"
              className={classes.checkBox}
              checked={field.value === true}
              onChange={field.onChange}
              icon={icon}
              checkedIcon={checkedIcon}
            />
          }
          label={''}
        />
      )}
    />
  );
}
