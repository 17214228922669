import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectEnumFieldProps<T> {
  name: string;
  enumObject: T;
  readOnly?: boolean;
  translationPath?: string;
  reasonPath?: string;
}

export function SelectEnumFieldSmall<T extends { [key: string]: unknown }>(
  props: SelectEnumFieldProps<T>,
) {
  const {
    name,
    enumObject,
    readOnly = false,
    translationPath,
    reasonPath,
  } = props;
  const values = Object.values(enumObject);
  const { t } = useTranslation();
  const { getFieldProps, setFieldValue } = useFormikContext();
  useEffect(() => {
    if (reasonPath && `${getFieldProps(`${name}`).value}` == 'DangerousGoods') {
      setFieldValue(`${reasonPath}.isDangerous`, true);
      setFieldValue(`${reasonPath}.otherReason`, '');
    } else if (reasonPath && `${getFieldProps(`${name}`).value}` != 'Other') {
      setFieldValue(`${reasonPath}.otherReason`, '');
    }
  }, [getFieldProps(`${name}`).value]);

  const renderValue = useCallback(
    (val: string) => {
      if (translationPath != null) {
        return t(`${translationPath}.${val}`);
      }
      return val;
    },
    [translationPath],
  );

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          error={meta.touched && Boolean(meta.error)}
          disabled={readOnly}
          style={{
            width: '97%',
            height: 26,
            padding: 0,
          }}
        >
          <Select
            disableUnderline
            style={{
              fontSize: 12,
              borderRadius: 4,
              border:
                meta.touched && Boolean(meta.error)
                  ? '1px solid red'
                  : '1px solid #bababa',
              paddingLeft: 2,
              backgroundColor: 'transparent',
            }}
            {...field}
            variant="standard"
            id={`${name}-select`}
            size="small"
          >
            {values.map((value: any) => (
              <MenuItem value={value}>{renderValue(value)}</MenuItem>
            ))}
          </Select>
          {meta.touched && Boolean(meta.error) && (
            <FormHelperText>{`Please select one`}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
}
