import {
  Box,
  Button,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useGetLocationsForCardQuery } from '../generated/graphql';
import { DialogModal } from './DialogModal';
import { UpdateLocation } from './modal/UpdateLocation';

interface CardLocationsProps {
  cardId: number;
  isProtected?: boolean;
  terminal?: boolean;
  name: string;
  cardType: string;
}

export function CardLocations(props: CardLocationsProps) {
  const {
    cardId,
    isProtected = false,
    terminal = false,
    name,
    cardType,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [modalLocationId, setmodalLocationId] = useState<number | null>(null);
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { url } = useRouteMatch();
  const type = url.split('/')[3];
  const router = useHistory();
  const { data } = useGetLocationsForCardQuery({
    variables: {
      cardId,
    },
  });

  const locations = data?.locations || [];

  const allColumns: GridColumns = [
    {
      headerName: '',
      field: '',
      width: 75,
      hide: terminal,
      renderCell: (params) => {
        return terminal ? (
          <></>
        ) : (
          <Tooltip title={`${t('actions.edit')}`}>
            <Button
              onClick={() => {
                setmodalLocationId(params.row.id);
                setOpen(true);
              }}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        );
      },
    },
    {
      field: 'name',
      headerName: t('attributes.name'),
      width: 200,
    },
    {
      field: 'address',
      headerName: t('attributes.address'),
      width: 160,
    },
    {
      field: 'postalCode',
      headerName: t('attributes.postalCode'),
      width: 110,
    },
    {
      field: 'city',
      headerName: t('attributes.city'),
      width: 140,
    },
    {
      field: 'countryLong',
      headerName: t('attributes.country'),
      width: 130,
    },
  ];

  return (
    <>
      <Grid container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={2}
        >
          <Typography variant="h3">{t('resource.location.plural')}</Typography>
          {!terminal && !isProtected && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (!data?.locations.length || type === 'customers') {
                  router.push({
                    pathname: `${url}/create`,
                    state: {
                      cardName: name,
                      timestamp: new Date().getTime(),
                    },
                  });
                }
                setOpenDeleteDialog(true);
              }}
              startIcon={<AddIcon />}
            >
              {t('button.create', { item: 'location' })}
            </Button>
          )}
          <DialogModal
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            contentText={t('dialog.location')}
            doAction={() => {
              router.push({
                pathname: `${url}/create`,
                state: {
                  cardName: name,
                  timestamp: new Date().getTime(),
                },
              });
            }}
            buttonText={t('button.continue')}
          />
        </Box>

        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={locations || []}
            disableSelectionOnClick
            onCellDoubleClick={(params) => {
              if (
                typeof params.value === 'string' ||
                typeof params.value === 'number'
              ) {
                navigator.clipboard.writeText(
                  typeof params.value === 'number'
                    ? params.value.toString()
                    : params.value,
                );
                setOpenAlert(true);
              }
            }}
            columns={
              cardType === 'terminal'
                ? allColumns.concat([
                    {
                      field: 'phLocationId',
                      headerName: t('attributes.phLocationId'),
                      width: 120,
                    },
                    {
                      field: 'locationShortCode',
                      headerName: t('attributes.locationCode'),
                      width: 110,
                    },
                  ])
                : allColumns
            }
            autoHeight={true}
          />
          <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={() => {
              setOpenAlert(false);
            }}
          >
            <Alert
              onClose={() => {
                setOpenAlert(false);
              }}
              severity="success"
            >
              Copied to clipboard!
            </Alert>
          </Snackbar>
        </div>
      </Grid>
      {open && modalLocationId != null && (
        <UpdateLocation
          id={modalLocationId}
          cardId={cardId}
          open={open}
          setOpen={setOpen}
          isProtected={isProtected}
          cardType={type}
        />
      )}
    </>
  );
}
