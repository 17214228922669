import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, FieldProps } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useGetGroupingTagQuery } from '../../generated/graphql';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiControl-root': {
      width: '100%',
    },
  },
}));

interface SelectGroupTagProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
}

export function SelectGroupTag(props: SelectGroupTagProps & TextFieldProps) {
  const { name, readOnly = false, fullWidth = false } = props;
  const { data } = useGetGroupingTagQuery({ fetchPolicy: 'no-cache' });
  const optionsData = data?.tourTemplatesWithGroupTag;
  const { t } = useTranslation();
  const classes = useStyles();
  const [options, setOptions] = useState<string[]>([]);
  const [value, setValue] = useState<string | null>(null);
  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const { getFieldProps } = useFormikContext();
  const newSetVal = getFieldProps('groupingTag').value;
  useEffect(() => {
    if (newSetVal !== null && newSetVal !== undefined) {
      setValue(newSetVal);
    }
  }, [newSetVal]);
  useEffect(() => {
    if (optionsData) {
      setOptions(optionsData);
    }
  });

  if (readOnly) {
    return (
      <TextField
        {...props}
        variant="outlined"
        label={t(`attributes.${label}`)}
        value={value}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <Autocomplete
          className={classes.root}
          onBlur={field.onBlur}
          value={value}
          multiple={false}
          fullWidth={fullWidth}
          freeSolo
          onChange={(_event, value: string | null) => {
            form.setFieldValue(name, value ?? null);
            setValue(value);
          }}
          noOptionsText=""
          options={options}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size="small"
              onBlur={(e) => {
                form.setFieldValue(name, e.target.value ?? null);
              }}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              variant="outlined"
              label={t(`attributes.${label}`)}
              InputProps={{ ...params.InputProps }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    </Field>
  );
}
