import { Box } from '@mui/material';
import {
  Button,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    justifyContent: 'center',
    border: '1px solid black',
    borderRadius: 10,
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0, 'auto', 1),
    },
    '& > *:last-child': {
      margin: theme.spacing(0, 'auto', 0),
    },
  },
  row: {
    display: 'flex',
    width: '100%',
  },
  distribute: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  end: {
    justifyContent: 'flex-end',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  errorText: {
    fontSize: 11,
    color: 'red',
  },
}));

interface AddTagFormProps {
  onSubmit: (title: string) => void;
  onCancel: () => void;
}

export function AddContactTagForm(props: AddTagFormProps) {
  const { onSubmit, onCancel } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [showError, setShowError] = useState<boolean>(false);
  const [currentTagName, setCurrTagName] = useState<string>('');

  return (
    <Box>
      <div className={classes.root}>
        <Box className={clsx(classes.row)}>
          <TextField
            id="tagName"
            name="tagName"
            maxRows={1}
            style={{ margin: 6, minWidth: 250 }}
            placeholder={t('attributes.tagName')}
            value={currentTagName}
            onChange={(e) => {
              setCurrTagName(e.target.value);
              if (showError) setShowError(false);
            }}
          />
        </Box>

        {showError && currentTagName === '' && (
          <Typography
            className={classes.errorText}
          >{`Tag name is required!`}</Typography>
        )}

        <Box className={clsx(classes.row, classes.center)}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //validate
              if (currentTagName === '' || currentTagName.length === 0) {
                setShowError(true);
              } else {
                onSubmit(currentTagName);
              }
            }}
          >
            {t('actions.create.capitalized')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            {t('actions.cancel')}
          </Button>
        </Box>
      </div>
    </Box>
  );
}
