import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../../CenteredBox';
import { CheckboxField } from '../CheckboxField';
import { CommonDateField } from '../CommonDateField';
import DateSelectorInTour from './DateSelectorInTour';

interface DatesFieldsProps {
  readOnly: boolean;
  showInitialStartDate?: boolean;
}

export function DatesFields({
  readOnly,
  showInitialStartDate = false,
}: DatesFieldsProps) {
  const { t } = useTranslation();
  const { getFieldProps, setFieldValue } = useFormikContext();
  const hasExceptionDates = getFieldProps('hasExceptionDates').value;
  const startExceptionDate = getFieldProps('startExceptionDate').value;
  const endExceptionDate = getFieldProps('endExceptionDate').value;
  const startDate = getFieldProps('startDate').value;
  const endDate = getFieldProps('endDate').value;

  useEffect(() => {
    if (
      hasExceptionDates === false &&
      (startExceptionDate != null || endExceptionDate != null)
    ) {
      setFieldValue('startExceptionDate', null);
      setFieldValue('endExceptionDate', null);
    }
  }, [hasExceptionDates, startExceptionDate, endExceptionDate]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CommonDateField
            name="startDate"
            variant="outlined"
            minDate
            fullWidth
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <CommonDateField
            name="endDate"
            variant="outlined"
            disabled={startDate == null || startDate == ''}
            dateString="startDate"
            fullWidth
            readOnly={readOnly}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{ marginTop: 5, marginBottom: 5, paddingRight: 10 }}
        >
          {showInitialStartDate ? (
            <CommonDateField
              name="originalStartDate"
              readOnly
              variant="outlined"
              fullWidth
            />
          ) : null}
        </Grid>
      </Grid>
      {!readOnly && startDate ? (
        <CenteredBox>
          <CheckboxField
            name={'hasExceptionDates'}
            label={t('attributes.hasExceptionDates')}
            readOnly={readOnly}
          />
        </CenteredBox>
      ) : null}
      {hasExceptionDates && startDate ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CommonDateField
              name="startExceptionDate"
              variant="outlined"
              dateString="startDate"
              disabled={startDate == null}
              maxDate
              fullWidth
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={6}>
            <CommonDateField
              name="endExceptionDate"
              variant="outlined"
              maxDate
              fullWidth
              dateString="startExceptionDate"
              disabled={startExceptionDate == null || startExceptionDate == ''}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <DateSelectorInTour
              startDate={startDate != undefined ? startDate : new Date()}
              endDate={endDate}
              readOnly={readOnly}
              index={-1}
              forException={true}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
