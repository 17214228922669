import { Button, Grid, List, ListItem, Typography } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { newLegAppend } from '../lib/formHelpers/leg';
import { calculateTransportationAndProductionDates } from '../lib/route_leg_dates';
import { LegFieldsRHF } from './form/LegFieldsRHF';
import { SporadicRouteFormInput } from './RouteFormRHF';

interface LegsFieldsProps {
  trafficReadOnly: boolean;
  readOnly: boolean;
  disableOffsetCalculation?: boolean;
  fromCreateRoute?: string;
  isCreateTerminalRoute?: boolean;
  isFromTerminal: boolean;
  legIdForLoadingList?: number;
  enableEditMode?: boolean;
}

export function LegsFieldsRHF(props: LegsFieldsProps) {
  const {
    trafficReadOnly,
    readOnly,
    disableOffsetCalculation = false,
    fromCreateRoute,
    isCreateTerminalRoute = false,
    isFromTerminal = false,
    legIdForLoadingList,
    enableEditMode = false,
  } = props;
  const { t } = useTranslation();

  const ref = useRef<any>();
  const {
    control,
    setValue: setFieldValue,
    getValues: getFieldProps,
  } = useFormContext<SporadicRouteFormInput>();
  const [posChanged, setPosChanged] = useState<boolean>(false);

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'legs',
  });

  const [isCopyDriverPlateInfo] = useWatch({
    control,
    name: ['isCopyDriverPlateInfo'],
  });

  const [driverName, driverPhoneNo, carRegNo, trailerRegNo] = useWatch({
    control,
    name: [
      'routeDriverName',
      'routeDriverPhoneNumber',
      'routeCarRegNumber',
      'routeTrailerRegNumber',
    ],
  });

  const [transportationDateString] = useWatch({
    control,
    name: ['transportationDate'],
  });

  //for dates calculations on swap/remove
  useEffect(() => {
    if (
      disableOffsetCalculation ||
      readOnly ||
      transportationDateString === ''
    ) {
      return;
    }
    const allLegs = getFieldProps('legs');

    calculateTransportationAndProductionDates(
      allLegs,
      transportationDateString,
    ).forEach((leg, index) => {
      setFieldValue(`legs.${index}.productionDate`, leg.productionDate);
      setFieldValue(`legs.${index}.transportationDate`, leg.transportationDate);
    });
  }, [posChanged, transportationDateString]);

  useEffect(() => {
    if (enableEditMode) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [enableEditMode]);

  return (
    <>
      {!trafficReadOnly && !isCreateTerminalRoute && (
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Typography variant="subtitle1" align="left">
            {t('resource.routeLeg.plural')}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              append(
                newLegAppend(
                  transportationDateString,
                  parseInt(`${fields.length}`),
                  isCopyDriverPlateInfo
                    ? {
                        routeDriverName: driverName ?? '',
                        routeDriverPhoneNumber: driverPhoneNo ?? '',
                        carRegistrationNumber: carRegNo ?? '',
                        trailerRegistrationNumber: trailerRegNo ?? '',
                      }
                    : undefined,
                )[0],
              );
            }}
          >
            {t('button.add', { item: t('leg') })}
          </Button>
        </Grid>
      )}
      <List>
        {fields.map((leg, index) => (
          <ListItem key={leg.key} style={{ padding: 0 }}>
            {enableEditMode ? (
              <>
                {legIdForLoadingList === leg.id ? (
                  <div ref={ref}>
                    <LegFieldsRHF
                      onRemove={(index) => {
                        remove(index);
                        setPosChanged(!posChanged);
                      }}
                      onMoveUp={() => {
                        swap(index, index - 1);
                        setPosChanged(!posChanged);
                      }}
                      onMoveDown={() => {
                        swap(index, index + 1);
                        setPosChanged(!posChanged);
                      }}
                      disableOffsetCalculation={disableOffsetCalculation}
                      index={index}
                      legsLength={fields.length}
                      readOnly={readOnly}
                      trafficReadOnly={trafficReadOnly}
                      isFromTerminal={isFromTerminal}
                      from={
                        fromCreateRoute === 'ViewSporadicRouteTemplate'
                          ? 'ViewSporadicTemplate'
                          : 'LegsFields'
                      }
                      fromCreateRoute={fromCreateRoute ?? ''}
                      isCreateTerminalRoute={isCreateTerminalRoute}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <LegFieldsRHF
                onRemove={(index) => {
                  remove(index);
                  setPosChanged(!posChanged);
                }}
                onMoveUp={() => {
                  swap(index, index - 1);
                  setPosChanged(!posChanged);
                }}
                onMoveDown={() => {
                  swap(index, index + 1);
                  setPosChanged(!posChanged);
                }}
                disableOffsetCalculation={disableOffsetCalculation}
                index={index}
                legsLength={fields.length}
                readOnly={readOnly}
                trafficReadOnly={trafficReadOnly}
                isFromTerminal={isFromTerminal}
                from={
                  fromCreateRoute === 'ViewSporadicRouteTemplate'
                    ? 'ViewSporadicTemplate'
                    : 'LegsFields'
                }
                fromCreateRoute={fromCreateRoute ?? ''}
                isCreateTerminalRoute={isCreateTerminalRoute}
              />
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
}
