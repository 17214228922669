import {
  Box,
  Dialog,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  GetInternalTerminalReportInfoDocument,
  InternalTerminalReportInfo,
  useEditReportInfoReplyMutation,
} from '../../generated/graphql';

interface ReportToTerminalEditReplyModalProps {
  handleClose: () => void;
  open: boolean;
  internalTerminalReportInfo: Pick<
    InternalTerminalReportInfo,
    | 'id'
    | 'info'
    | 'infoType'
    | 'internalTerminalId'
    | 'dateForAddInfo'
    | 'isAddedFromTerminal'
    | 'replyInfo'
    | 'replyText'
  >;
}

export function ReportToTerminalEditReplyToInfoModal(
  props: ReportToTerminalEditReplyModalProps,
) {
  const { open, handleClose, internalTerminalReportInfo } = props;
  const { t } = useTranslation();
  const {
    id: internalTerminalReportInfoId,
    info,
    infoType,
    internalTerminalId,
    dateForAddInfo,
    isAddedFromTerminal,
    replyInfo,
    replyText,
  } = internalTerminalReportInfo;

  const theme = useTheme();
  const lessThan1400 = useMediaQuery(theme.breakpoints.between(0, 1400));

  const [editReplyToInfoMutation] = useEditReportInfoReplyMutation({
    refetchQueries: [
      {
        query: GetInternalTerminalReportInfoDocument,
        variables: {
          internalTerminalId,
          dateForAddInfo,
          forTerminal: isAddedFromTerminal,
        },
      },
    ],
  });

  if (!internalTerminalReportInfo) {
    return <></>;
  }

  return (
    <Box>
      <Dialog
        maxWidth={lessThan1400 ? 'sm' : 'md'}
        fullWidth={true}
        open={open}
        onClose={handleClose}
      >
        <Formik
          initialValues={{
            internalTerminalReportInfoId,
            info,
            infoType,
            internalTerminalId,
            dateForAddInfo,
            replyInfo: replyInfo ?? '',
            replyText: replyText ?? '',
          }}
          onSubmit={async (submit_val) => {
            editReplyToInfoMutation({
              variables: {
                input: {
                  internalTerminalReportInfoId:
                    submit_val.internalTerminalReportInfoId,
                  replyText: submit_val.replyText,
                },
              },
            });
            handleClose();
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <DialogTitle>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                  mb={2}
                >
                  <Typography variant="body1">
                    <strong>{t('attributes.replyToInfo')}</strong>
                  </Typography>
                  <Typography variant="body1">{``}</Typography>
                  <IconButton style={{ padding: '0px' }} onClick={handleClose}>
                    <CloseIcon fontSize="medium" color="action" />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    paddingBottom: '2%',
                  }}
                >
                  <Typography variant="body1">{`${props.values.info}`}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '2%',
                  }}
                >
                  <TextField
                    label="Reply"
                    id="replyText"
                    multiline
                    rows={3}
                    variant="outlined"
                    value={props.values.replyText}
                    onChange={props.handleChange}
                  />
                </Box>
              </DialogContent>
              <DialogActions
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  paddingBottom: '1%',
                }}
              >
                <Box>
                  <Button type="submit" variant="contained" color="success">
                    {t('button.save')}
                  </Button>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </Box>
  );
}
