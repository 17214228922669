import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Maybe } from '../generated/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',

    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  overUtilized: {
    color: 'red',
  },
  underUtilized: {
    color: '#0ea00e',
  },
  labelBold: {
    fontWeight: 'bold',
  },
}));

interface AggregatesLoadingListProps {
  loadingListItems: {
    pallets?: Maybe<number | string>;
    palletSpace?: Maybe<number | string>;
  }[];
  capacity: Maybe<number> | undefined;
  from?: Maybe<string> | undefined;
}

export function AggregatesLoadingList(props: AggregatesLoadingListProps) {
  const { loadingListItems, capacity } = props;
  const classes = useStyles();

  const sumPallets = loadingListItems.reduce((sum, item) => {
    const { pallets } = item;
    if (typeof pallets === 'number') {
      return sum + pallets;
    }

    return sum;
  }, 0);
  const sumPalletSpace = loadingListItems.reduce((sum, item) => {
    const { palletSpace } = item;
    if (typeof palletSpace === 'number') {
      return sum + palletSpace;
    }

    return sum;
  }, 0);

  const utilPercent = capacity
    ? Math.ceil((sumPalletSpace / capacity) * 100)
    : 0;

  return props.from === 'Bookmarks' ? (
    <Box className={classes.root}>
      <Typography align="left">
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{sumPalletSpace}</strong>
        </label>
        /{capacity}
      </Typography>
    </Box>
  ) : (
    <Box className={classes.root}>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Total pallets:</strong>
        </label>{' '}
        {sumPallets}
      </Typography>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Total pallet space:</strong>
        </label>{' '}
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{sumPalletSpace}</strong>
        </label>
        /{capacity}
      </Typography>
      <Typography align="left">
        <label className={classes.labelBold}>
          <strong>Util:</strong>
        </label>{' '}
        <label
          className={
            utilPercent <= 100 ? classes.underUtilized : classes.overUtilized
          }
        >
          <strong>{utilPercent}%</strong>
        </label>
      </Typography>
    </Box>
  );
}
