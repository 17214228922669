import { ApolloError } from '@apollo/client';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface LoadingAndErrorProps<T> {
  loading: boolean;
  error: ApolloError | undefined;
  data?: T;
  children: (params: {
    loadedData: T;
    loading: boolean;
    error: ApolloError | undefined;
  }) => React.ReactNode;
}

export function LoadingAndError<T>(props: LoadingAndErrorProps<T>) {
  const { loading, error, data, children } = props;
  const { t } = useTranslation();
  if (error) {
    console.error(error);
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        mb={2}
        height="80vh"
      >
        <CircularProgress />
        <Typography variant="h2">{`${t('states.loading')}...`}</Typography>
      </Box>
    );
  }

  return <>{children({ loadedData: data, loading, error })}</>;
}
