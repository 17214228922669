import { Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useGetTransportAgreementDataQuery } from '../generated/graphql';
import { ViewPdf } from './ViewPdf';

export function PdfWrapper() {
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const { t } = useTranslation();
  const { loading, error, data } = useGetTransportAgreementDataQuery({
    variables: { id: parseInt(id, 10) },
  });

  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading || data == null) {
    return <div>{t('states.loading')}...</div>;
  }

  return (
    <>
      <Box margin={5}>
        <Grid item sm={6} md={1}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              router.push(`/traffic/sporadic-routes/${id}`);
            }}
          >
            {t('button.back')}
          </Button>
        </Grid>
      </Box>
      {data && <ViewPdf route={data.route} />}
    </>
  );
}
