import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

interface CheckboxFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  handleEdit?: (
    e: React.SyntheticEvent,
    name: string,
    editedField: string,
    checked?: boolean,
  ) => void;
  routeIndex?: number;
  customerName?: string;
  labelPlacement?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.fontSize - 1,
    },
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: 1,
    },
  }),
);

export function CheckboxField(props: CheckboxFieldProps) {
  const classes = useStyles();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const {
    name,
    label,
    readOnly = false,
    size = undefined,
    disabled = false,
    routeIndex = -1,
    customerName = '',
    labelPlacement = false,
  } = props;
  const [checked, setChecked] = useState(getFieldProps(`${name}`).value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}`, event.target.checked);
    //below ternary is for remaining goods new customer use only
    customerName
      ? checked
        ? setFieldValue(`${customerName}.newCustomer`, '')
        : setFieldValue(`${customerName}.locationId`, 0)
      : null;
    //
    if (name === 'isPriceEquallyDivided' && event.target.checked) {
      const allRoutes = getFieldProps(`routes`).value;
      if (allRoutes && allRoutes.length > 0) {
        const totalRoutes = allRoutes.length;
        const actualPrice = getFieldProps(`price`).value;
        const calculatedPrice = actualPrice / totalRoutes;
        const calculatedPercentage = 100 / totalRoutes;

        for (let i = 0; i < totalRoutes; i++) {
          setFieldValue(
            `routes.${i}.price`,
            Math.round(calculatedPrice * 100) / 100,
          );
          setFieldValue(
            `routes.${i}.pricePercentage`,
            Math.round(calculatedPercentage * 100) / 100,
          );
        }
      }
    }
    //for copy leg DriverPlateInfo

    if (routeIndex >= 0 && event.target.checked) {
      const thisRoute = getFieldProps(`routes.${routeIndex}`).value;
      const routeDriverName = getFieldProps(
        `routes.${routeIndex}.routeDriverName`,
      ).value;
      const routeDriverPhoneNumber = getFieldProps(
        `routes.${routeIndex}.routeDriverPhoneNumber`,
      ).value;
      const routeCarRegNumber = getFieldProps(
        `routes.${routeIndex}.routeCarRegNumber`,
      ).value;
      const routeTrailerRegNumber = getFieldProps(
        `routes.${routeIndex}.routeTrailerRegNumber`,
      ).value;

      if (thisRoute && thisRoute.legs && thisRoute.legs.length > 0) {
        for (let i = 0; i < thisRoute.legs.length; i++) {
          setFieldValue(
            `routes.${routeIndex}.legs.${i}.carRegistrationNumber`,
            routeCarRegNumber,
          );
          setFieldValue(
            `routes.${routeIndex}.legs.${i}.trailerRegistrationNumber`,
            routeTrailerRegNumber,
          );
          setFieldValue(
            `routes.${routeIndex}.legs.${i}.routeDriverName`,
            routeDriverName,
          );
          setFieldValue(
            `routes.${routeIndex}.legs.${i}.routeDriverPhoneNumber`,
            routeDriverPhoneNumber,
          );
        }
      }
    }
  };
  useEffect(() => {
    setChecked(getFieldProps(`${name}`).value);
  }, [getFieldProps(`${name}`).value]);
  return (
    <Field name={name}>
      {({ field }: FieldProps) => (
        <FormControlLabel
          {...field}
          className={classes.formControl}
          disabled={readOnly || disabled}
          control={
            <Checkbox
              size={size}
              color="primary"
              className={classes.checkBox}
              checked={checked}
              onChange={handleChange}
            />
          }
          label={
            <Typography
              variant="body2"
              className={classes.label}
              color="textPrimary"
            >
              {label}
            </Typography>
          }
          labelPlacement={labelPlacement ? 'bottom' : undefined}
        />
      )}
    </Field>
  );
}
