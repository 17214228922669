import {
  Box,
  Button,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useGetCustomerAccountsForCardQuery } from '../generated/graphql';
import { UpdateCustomerAccount } from './modal/UpdateCustomerAccount';

interface CardCustomerAccountsProps {
  cardId: number;
  isProtected?: boolean;
  terminal?: boolean;
  name: string;
}

export function CardCustomerAccounts(props: CardCustomerAccountsProps) {
  const { cardId, isProtected = false, terminal = false, name } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [modalAccountId, setModalAccountId] = useState<number | null>(null);
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const type = url.split('/')[3];
  const router = useHistory();
  const { data } = useGetCustomerAccountsForCardQuery({
    variables: {
      cardId,
    },
  });

  const accounts = data?.customerAccounts || [];

  return (
    <>
      <Grid container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={2}
        >
          <Typography variant="h3">{t('resource.accounts.plural')}</Typography>
          {!terminal && !isProtected && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (type === 'customers') {
                  router.push({
                    pathname: `${url}/createaccount`,
                    state: {
                      cardName: name,
                      timestamp: new Date().getTime(),
                    },
                  });
                }
              }}
              startIcon={<AddIcon />}
            >
              {t('button.create', { item: 'account' })}
            </Button>
          )}
        </Box>

        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={accounts || []}
            disableSelectionOnClick
            onCellDoubleClick={(params) => {
              if (
                typeof params.value === 'string' ||
                typeof params.value === 'number'
              ) {
                navigator.clipboard.writeText(
                  typeof params.value === 'number'
                    ? params.value.toString()
                    : params.value,
                );
                setOpenAlert(true);
              }
            }}
            columns={[
              {
                headerName: '',
                field: '',
                width: 75,
                hide: terminal,
                renderCell: (params) => {
                  return terminal ? (
                    <></>
                  ) : (
                    <Tooltip title={`${t('actions.edit')}`}>
                      <Button
                        onClick={() => {
                          setModalAccountId(params.row.id);
                          setOpen(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    </Tooltip>
                  );
                },
              },
              {
                field: 'accountNumber',
                headerName: t('attributes.accountNumber'),
                width: 200,
              },
              {
                field: 'salesPersonName',
                headerName: t('attributes.salesPerson'),
                width: 160,
              },
              {
                field: 'logisticPersonName',
                headerName: t('attributes.logisticPerson'),
                width: 160,
              },
              {
                field: 'note',
                headerName: t('attributes.note'),
                width: 200,
              },
            ]}
            pageSize={5}
            rowHeight={50}
            autoHeight={true}
          />
          <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={() => {
              setOpenAlert(false);
            }}
          >
            <Alert
              onClose={() => {
                setOpenAlert(false);
              }}
              severity="success"
            >
              Copied to clipboard!
            </Alert>
          </Snackbar>
        </div>
      </Grid>
      {open && modalAccountId != null && (
        <UpdateCustomerAccount
          id={modalAccountId}
          cardId={cardId}
          open={open}
          setOpen={setOpen}
          isProtected={isProtected}
        />
      )}
    </>
  );
}
