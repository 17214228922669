import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  PickersDay,
  pickersDayClasses,
  PickersDayProps,
} from '@mui/x-date-pickers/PickersDay';
import startOfDay from 'date-fns/startOfDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useFormikContext } from 'formik';
import { Box, Grid, Typography, FormLabel } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus, &:checked,': {
      backgroundColor: '#69bc48',
    },
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  }),
}));

export default function DatePickerMultiple() {
  const [values, setValues] = React.useState<Date[]>([]);
  const [dateStr, setDateStr] = React.useState<string[]>([]);
  const { getFieldMeta, setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const touched = getFieldMeta(`selectedCalDates`).touched;
  const error = getFieldMeta(`selectedCalDates`).error;

  React.useEffect(() => {
    if (values) {
      //sort and format all
      const sortedDates = values.sort((a, b) => a.getTime() - b.getTime());
      const arrStrDates: string[] = [];
      sortedDates.forEach((d) => {
        arrStrDates.push(format(d, 'dd-MMM-yyyy'));
      });
      setFieldValue('selectedCalDates', arrStrDates);
      setDateStr(arrStrDates);
    } else {
      setFieldValue('selectedCalDates', []);
      setDateStr([]);
    }
  }, [values]);

  const findDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates.find((item) => item.getTime() === dateTime);
  };

  const findIndexDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates.findIndex((item) => item.getTime() === dateTime);
  };

  const renderPickerDay = (
    date: Date,
    selectedDates: Date[],
    pickersDayProps: PickersDayProps<any>,
  ) => {
    if (!values) {
      return <PickersDay {...pickersDayProps} />;
    }

    const selected = findDate(values, date);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: 'green',
          },
        }}
        disableMargin
        selected={selected !== undefined}
      />
    );
  };

  return (
    <Grid item container alignItems="flex-start" direction="row">
      <Grid item xs={5}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            label="Multiple Days"
            maxDate={new Date(new Date().getTime() + 91 * 24 * 60 * 60 * 1000)} //91 for 3 months
            minDate={new Date()}
            value={values}
            onChange={(newValue) => {
              //copying the values array
              const array = [...values];

              const date = startOfDay(
                newValue === null ? new Date() : newValue,
              );

              const index = findIndexDate(array, date);
              if (index >= 0) {
                array.splice(index, 1);
              } else {
                array.push(date);
              }
              setValues(array);
            }}
            renderDay={renderPickerDay}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="'Week of' MMM d"
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={7}>
        <Box
          style={{
            backgroundColor: '#ebebed',
            width: '100%',
            marginTop: 20,
            padding: 6,
          }}
        >
          <Typography style={{ fontSize: 13 }} align="left">{`${t(
            'attributes.selectedDates',
          )}`}</Typography>
          <Typography
            style={{ fontSize: 13, lineHeight: 2 }}
            align="left"
          >{`${dateStr.join(' | ')}`}</Typography>
        </Box>
        {dateStr.length === 0 && (
          <FormLabel style={{ fontSize: 14, textAlign: 'left' }} error={true}>
            {touched && error}
          </FormLabel>
        )}
      </Grid>
    </Grid>
  );
}
