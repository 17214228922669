import {
  Box,
  Collapse,
  Grid,
  ListItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../views/CheckListRoutesTraffic';
import { ChecklistLegsListRHF } from './ChecklistLegsListRHF';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AggregatesLoadingListCLRHF } from './AggregatesLoadingListCLRHF';

interface CollapsibleListProps {
  readOnly: boolean;
  index: number;
}

export function ChecklistCollapsibleList(props: CollapsibleListProps) {
  const { readOnly = false, index } = props;

  const { getValues: getFieldProps } =
    useFormContext<CheckListRoutesFormInput>();

  const route = getFieldProps(`routes.${index}`);
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <Box style={{ border: '1px solid green', padding: 4, marginBottom: 3 }}>
        <Grid item container xs={12} direction="row">
          <Grid item xs={5}>
            <ListItem key={route.idNum} style={{ padding: 0 }}>
              <div style={{ backgroundColor: '#e3e3e3' }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
              <div style={{ backgroundColor: '#e3e3e3', marginLeft: 8 }}>
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => {
                    window.open(
                      `/traffic/dispatched-routes/${route.idNum}`,
                      '_blank',
                    );
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </div>
              <Typography
                style={{ fontWeight: 'bold', fontSize: 18, paddingLeft: 8 }}
              >{`${route.routeId}`}</Typography>
            </ListItem>
          </Grid>
          <Grid
            item
            xs={3}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Tooltip title={`Subcontractor Name - ${route.subcontractorName}`}>
              <Typography
                style={{
                  fontSize: 13,
                  maxLines: 1,
                  cursor: 'default',
                }}
              >{`${route.subcontractorName}`}</Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <AggregatesLoadingListCLRHF routeIndex={index} />
          </Grid>
          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end">
              <div style={{ backgroundColor: '#e3e3e3' }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Collapse key={`C${route.idNum}`} in={open} timeout="auto" unmountOnExit>
        <ChecklistLegsListRHF readOnly={readOnly} routeIndex={index} />
      </Collapse>
    </>
  );
}
