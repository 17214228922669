import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PageviewIcon from '@material-ui/icons/Pageview';
import { usePDF } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { FileItem } from '../components/FileListComponent';
import { downloadPDF } from '../lib/pdf/downloadPdf';
import { useHttpClient } from '../providers/HttpClientProvider';
import {
  TransportAgreement,
  TransportAgreementInterface,
} from './TransportAgreement/TransportAgreement';

type CreatePdfProps = {
  route: TransportAgreementInterface & { files: FileItem[] };
  multipleDates?: string;
};

// Create Document Component
export function CreatePdf(props: CreatePdfProps) {
  const document = (
    <TransportAgreement
      route={props.route}
      multipleDates={props.multipleDates}
    />
  );
  const [instance] = usePDF({ document });
  const { httpClient } = useHttpClient();
  const { id } = useParams<{ id: string }>();
  const router = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={2}>
        <Button
          variant="contained"
          startIcon={<CloudDownloadIcon />}
          onClick={() => {
            downloadPDF(instance.blob, props.route.files, httpClient);
          }}
        >
          {t('button.download', { item: 'pdf' })}
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          startIcon={<PageviewIcon />}
          onClick={() => {
            router.push(`/traffic/sporadic-routes/${id}/pdf`);
          }}
        >
          {t('button.view', { item: 'pdf' })}
        </Button>
      </Grid>
    </>
  );
}
