import { GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import parse from 'date-fns/parse';

export function printDateTimeLocale(date: Date, dateFormat?: string) {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(date, tz);
  if (dateFormat) {
    return format(zonedDate, dateFormat);
  }
  return format(zonedDate, 'hh:mm - dd/MM/yyyy');
}

export function printDateLocale(date: Date) {
  return format(date, 'dd/MM/yy');
}

export function formatTime(
  params: GridValueFormatterParams,
): string | undefined {
  if (params == null || params.value == null) {
    return undefined;
  }
  const date = parse(params.value.toString(), 'HH:mm:ss', new Date());
  return format(date, 'HH:mm');
}

export function formatPrice(
  params: GridValueFormatterParams,
): string | undefined {
  if (params == null) {
    return undefined;
  }
  if (!params.value) {
    return '';
  }
  const val = new String(params.value);
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatCurrency(
  params: GridValueFormatterParams,
): string | undefined {
  if (typeof params?.value !== 'string') {
    return undefined;
  }
  return params.value.toUpperCase();
}
