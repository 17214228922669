import { Box, IconButton, Tooltip } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@mui/icons-material/Edit';
import { DataGridPro, GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { format, parse, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import {
  Deviation,
  LocationEntity,
  useDeleteDeviationMutation,
  useGetDeviationsForRouteQuery,
} from '../generated/graphql';
import { renderLocation } from '../lib/location';
import { DeleteActionCell } from './datagrid/DeleteActionCell';

interface ListDeviationsProps {
  routeId: number;
  onDeviationOpen: (deviation: Partial<Deviation>) => void;
  setEditDeviationFormOpen: (deviation: Partial<Deviation>) => void;
}

export function ListDeviations(props: ListDeviationsProps) {
  const { routeId, onDeviationOpen, setEditDeviationFormOpen } = props;
  const { t } = useTranslation();
  const { data } = useGetDeviationsForRouteQuery({
    variables: {
      routeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [deleteDeviation] = useDeleteDeviationMutation({});

  const onDeleteAction = (id: number) => {
    deleteDeviation({
      variables: { id },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'Deviation' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const rows = data?.deviationsForRoute ?? [];
  const columns: GridColDef[] = [
    {
      field: 'view',
      headerName: 'View',
      minWidth: 100,
      type: 'action',
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <IconButton
                onClick={() => onDeviationOpen(params.row)}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PageviewIcon color="action" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'arrivalTime',
      headerName: 'Original Arrival Time',
      renderCell(params) {
        const { row } = params;
        const { route } = row;
        if (route.legs && typeof route.legs[0].arrivalTime === 'string') {
          if (route.legs[0].arrivalTime.match(/\d{2}:\d{2}:\d{2}/)) {
            return format(
              parse(route.legs[0].arrivalTime, 'HH:mm:ss', new Date()),
              'HH:mm',
            );
          } else {
            return route.legs[0].arrivalTime;
          }
        } else {
          return '';
        }
      },
    },
    {
      field: 'actualArrivalTime',
      headerName: 'Actual Arrival Time',
      flex: 1,
      renderCell(params) {
        const { value } = params;

        if (value && typeof value === 'string') {
          if (value.match(/\d{2}:\d{2}:\d{2}/)) {
            return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
          } else {
            return value;
          }
        } else {
          return '';
        }
      },
    },
    {
      field: 'cause',
      headerName: 'Cause',
      flex: 1,
      valueFormatter({ value }) {
        return t(`enums.deviationCause.${value}`);
      },
    },
    {
      field: 'fault',
      headerName: 'Fault',
      flex: 1,
      valueFormatter({ value }) {
        return t(`enums.deviationFault.${value}`);
      },
    },
    {
      field: 'faultLocation',
      headerName: 'Fault location',
      flex: 1,
      valueFormatter({ value }) {
        const location = value as LocationEntity | null;

        return renderLocation(location);
      },
    },
    {
      field: 'consequence',
      headerName: 'Consequnce',
      type: 'boolean',
      flex: 1,
    },
    {
      field: 'note',
      headerName: 'Internal note',
      flex: 1,
    },
    {
      field: 'emailSent',
      headerName: 'Email sent',
      flex: 1,
      valueFormatter({ value }) {
        if (typeof value === 'string') {
          return format(parseISO(value), 'yyyy-MM-dd HH:mm');
        }

        return '';
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      type: 'action',
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            onClick={() => setEditDeviationFormOpen(params.row)}
          >
            <Tooltip title={`${t('actions.edit')}`}>
              <EditIcon color="action" />
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      type: 'action',
      flex: 1,
      renderCell({ row }) {
        return (
          <DeleteActionCell
            itemTitle={
              row.faultLocation
                ? `Deviation at ${row.faultLocation.card.name}(${row.actualArrivalTime})`
                : `Deviation at ${row.actualArrivalTime}`
            }
            deleteAction={() => {
              onDeleteAction(row.id);
            }}
          />
        );
      },
    },
  ];

  return (
    <Box>
      <DataGridPro columns={columns} rows={rows} autoHeight />
    </Box>
  );
}
