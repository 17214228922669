import { Grid, Input, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';

interface CardFormProps {
  onSubmit: (values: { name: string }) => void;
  type: string;
}

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', { name: t('attributes.name'), len: 3 }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
  });

export function CardForm(props: CardFormProps) {
  const { t } = useTranslation();
  const { type } = props;

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={validationSchema(t)}
      onSubmit={props.onSubmit}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <Grid container spacing={5} justifyContent="center">
            <Grid item sm={6} container spacing={3}>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label={t('attributes.name')}
                  value={props.values.name}
                  onChange={props.handleChange}
                  error={props.touched.name && Boolean(props.errors.name)}
                  helperText={props.touched.name && props.errors.name}
                />
              </Grid>
              <Grid item sm={12} container justifyContent="center">
                <Input
                  type="submit"
                  value={t('button.create', {
                    item: t(`resource.${type}.lowercased`),
                  })}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
