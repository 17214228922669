import {
  Box,
  createStyles,
  Grid,
  Input,
  makeStyles,
  Modal,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  GetTagsQuery,
  useCreateCardContactMutation,
  useGetTagsQuery,
} from '../../generated/graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 800,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);
function getModalStyle() {
  return {
    width: '100%',
    height: '100vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.name'),
          len: 3,
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    role: yup.string(),
    phoneNumber: yup.string().min(
      3,
      t('validation.atLeastLen', {
        phoneNumber: t('attributes.phoneNumber'),
        len: 3,
      }),
    ),
    email: yup.string().min(
      3,
      t('validation.atLeastLen', {
        email: t('attributes.email'),
        len: 3,
      }),
    ),
  });

interface UpdateContactProps {
  cardId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onCreate: (contactId: number) => void;
}

export function AddContactModal(props: UpdateContactProps) {
  const { cardId, open, setOpen, onCreate } = props;
  const { data: allTags } = useGetTagsQuery();
  const classes = useStyles();
  const { t } = useTranslation();
  const [createCardContact, {}] = useCreateCardContactMutation();

  if (allTags == null) {
    return <></>;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={getModalStyle()}
      >
        <Box className={classes.paper}>
          <Formik
            initialValues={{
              name: '',
              role: '',
              email: '',
              phoneNumber: '',
              tagIds: [],
              note: '',
            }}
            enableReinitialize
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
              createCardContact({
                variables: {
                  cardId,
                  input: { ...values },
                },
              }).then((contact) => {
                const id = contact.data?.createCardContact.id;
                if (id) {
                  onCreate(id);
                }
              });
              setOpen(false);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={5} justifyContent="center">
                  <Grid item sm={10} container spacing={3}>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label={t('attributes.name')}
                        value={props.values.name}
                        onChange={props.handleChange}
                        error={props.touched.name && Boolean(props.errors.name)}
                        helperText={props.touched.name && props.errors.name}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="role"
                        name="role"
                        label={t('attributes.role')}
                        value={props.values.role}
                        onChange={props.handleChange}
                        error={props.touched.role && Boolean(props.errors.role)}
                        helperText={props.touched.role && props.errors.role}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label={t('attributes.phoneNumber')}
                        value={props.values.phoneNumber}
                        onChange={props.handleChange}
                        error={
                          props.touched.phoneNumber &&
                          Boolean(props.errors.phoneNumber)
                        }
                        helperText={
                          props.touched.phoneNumber && props.errors.phoneNumber
                        }
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label={t('attributes.email')}
                        value={props.values.email}
                        onChange={props.handleChange}
                        error={
                          props.touched.email && Boolean(props.errors.email)
                        }
                        helperText={props.touched.email && props.errors.email}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="note"
                        name="note"
                        label={t('attributes.note')}
                        value={props.values.note}
                        onChange={props.handleChange}
                        error={props.touched.note && Boolean(props.errors.note)}
                        helperText={props.touched.note && props.errors.note}
                      />
                    </Grid>

                    <Grid item sm={12}>
                      <Autocomplete
                        id="tagIds"
                        multiple
                        autoHighlight
                        options={allTags.cardContactTags}
                        getOptionLabel={(
                          tag: GetTagsQuery['cardContactTags'][number],
                        ) => tag.title}
                        onChange={(
                          _event: ChangeEvent<unknown>,
                          value: GetTagsQuery['cardContactTags'],
                        ) => {
                          props.setFieldValue(
                            'tagIds',
                            value.map((tag) => {
                              return tag.id;
                            }),
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Add contact role"
                            placeholder="Tags"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={12} container justifyContent="center">
                      <Input
                        type="submit"
                        value={t('button.create', {
                          item: t('resource.contact.lowercased'),
                        })}
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
