import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  GridRenderEditCellParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { add, format, isMatch } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SelectContactCell } from '../components/datagrid/SelectContactCell';
import { SelectCustomerCell } from '../components/datagrid/SelectCustomerCell';
import { SelectLocationCell } from '../components/datagrid/SelectLocationCell';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { DownloadPeakPlanningDebitList } from '../components/DownLoadPeakPlanningDebitList';
import { HelmetComponent } from '../components/HelmetComponent';
import { SelectDateRangeForm } from '../components/SelectDateRangeForm';
import { COUNTRIES } from '../components/AddTransport';

import {
  GetPeakPlanningDebitsDocument,
  PeakPlanningDebitStatus,
  useGetPeakPlanningDebitsLazyQuery,
  useUpdatePeakPlanningDebitMutation,
} from '../generated/graphql';
import { COUNTRIES_MAP, CURRENCIES, TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';

const useStyles = makeStyles({
  root: {
    '& .low': {
      color: '#E32D22',
    },
    '& .medium': {
      color: '#FDBB2F',
    },
    '& .high': {
      color: '#7BC144',
    },
    '& .booked': {
      backgroundColor: '#7BC144',
    },
    '& .unEditable': {
      backgroundColor: '#F0F0F0',
      opacity: 0.6,
    },
  },
});

function getLocation(params: GridValueGetterParams) {
  const location = params.row.location;
  const locationCountry =
    location && location.country
      ? COUNTRIES[
          location.country as keyof typeof COUNTRIES
        ].toLocaleUpperCase()
      : '';
  return location
    ? `${location.name} - ${location.address} - ${location.city},  ${locationCountry}`
    : '';
}

function getCountry(params: GridValueGetterParams) {
  const country = params.row.destinationCountry;
  return country
    ? COUNTRIES_MAP[country as keyof typeof COUNTRIES_MAP].toLocaleUpperCase()
    : '';
}

function getSelfCostCurrency(params: GridValueGetterParams) {
  const selfCost = params.row.selfCostCurrency;
  return CURRENCIES[selfCost as keyof typeof CURRENCIES];
}

function getCustomerCurrency(params: GridValueGetterParams) {
  const selfCost = params.row.customerCurrency;
  return CURRENCIES[selfCost as keyof typeof CURRENCIES];
}

function getCustomer(params: GridValueGetterParams) {
  const customer = params.row.customer;
  return customer ? customer.name : '';
}

function getSalesContact(params: GridValueGetterParams) {
  const salesContact = params.row.salesContact;
  return salesContact ? `${salesContact.name}` : '';
}

function getDifference(params: GridValueGetterParams) {
  const bookedPPL = params.row.bookedPPL;
  const sentPPL = params.row.sentPPL;
  if (bookedPPL == null || sentPPL == null) {
    return undefined;
  }
  return bookedPPL - sentPPL;
}

export function PeakPlanningDebit() {
  const { t } = useTranslation();
  const styles = useStyles();
  const [startDate, setStartDate] = useState<string>(
    format(add(new Date(), { months: -3 }), DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState<string>(
    format(new Date(), DATE_FORMAT),
  );
  const [getPeakPlanningItems, { data, loading, error }] =
    useGetPeakPlanningDebitsLazyQuery({ fetchPolicy: 'cache-and-network' });
  const apiRef = useGridApiRef();

  const [updatePeakPlanningDebit] = useUpdatePeakPlanningDebitMutation({
    refetchQueries: [
      {
        query: GetPeakPlanningDebitsDocument,
        variables: { startDate, endDate },
      },
    ],
  });
  const onDateSelected = React.useCallback(
    (values) => {
      if (isMatch(startDate, DATE_FORMAT) && isMatch(endDate, DATE_FORMAT)) {
        setStartDate(values.startDate);
        setEndDate(values.endDate);
      }
    },
    [startDate, endDate],
  );

  useEffect(() => {
    if (isMatch(startDate, DATE_FORMAT) && isMatch(endDate, DATE_FORMAT)) {
      getPeakPlanningItems({ variables: { startDate, endDate } });
    }
  }, [startDate, endDate]);

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      if (value == null) {
        return;
      }
      if (field === 'customer') {
        if (typeof value === 'string') {
          return;
        }
        updatePeakPlanningDebit({
          variables: {
            id,
            input: {
              customerId: value,
            },
          },
        });
        return;
      }
      if (field === 'destinationCountry') {
        updatePeakPlanningDebit({
          variables: {
            id,
            input: {
              destinationCountry: Object.keys(COUNTRIES_MAP).find(
                (key) =>
                  COUNTRIES_MAP[key as keyof typeof COUNTRIES_MAP] === value,
              ),
            },
          },
        });
        return;
      }
      if (['selfCostCurrency', 'customerCurrency'].includes(field)) {
        updatePeakPlanningDebit({
          variables: {
            id,
            input: {
              [field]: Object.keys(CURRENCIES).find(
                (key) => CURRENCIES[key as keyof typeof CURRENCIES] === value,
              ),
            },
          },
        });
        return;
      }
      updatePeakPlanningDebit({
        variables: {
          id,
          input: {
            [field]: value,
          },
        },
      });
    },
    [data],
  );

  if (error) {
    console.error(error);
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }
  return (
    <Grid container>
      <HelmetComponent title={t('resource.peakPlanning.capitalized')} />
      <Grid item container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="180%"
          mb={2}
        >
          <Typography variant="h2">
            {t('resource.peakPlanning.capitalized')}
          </Typography>
          <SelectDateRangeForm
            startDate={startDate}
            endDate={endDate}
            onDateSelected={onDateSelected}
            buttonText={'Filter'}
          />
          <Box>
            <DownloadPeakPlanningDebitList
              startDate={startDate}
              endDate={endDate}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} className={styles.root}>
        <UserConfiguredDataGridPro
          tableName={TABLE_NAMES.PeakPlanningDebit}
          onCellEditCommit={handleCellEditCommit}
          rows={data?.peakPlanningDebits || []}
          apiRef={apiRef}
          columns={[
            {
              field: 'id',
              type: 'number',
              headerName: t(`attributes.id`),
              width: 180,
            },
            {
              field: 'status',
              headerName: t('attributes.status'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values(PeakPlanningDebitStatus),
              editable: true,
            },
            {
              field: 'sporadicRouteId',
              headerName: t('attributes.routeId'),
              width: 180,
              type: 'string',
              renderCell: (params) => {
                return params.row.sporadicRoute ? (
                  <Link
                    to={`/traffic/sporadic-routes/${params.row.sporadicRoute.id}`}
                    target={'_blank'}
                  >
                    {params.row.sporadicRoute.routeId}
                  </Link>
                ) : (
                  ''
                );
              },
            },
            {
              field: 'productionDate',
              headerName: t('attributes.productionDate'),
              width: 180,
              type: 'date',
            },
            {
              field: 'customer',
              headerName: t('attributes.customer'),
              width: 180,
              type: 'string',
              editable: true,
              valueGetter: getCustomer,
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  locationId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: locationId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectCustomerCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'customerNumber',
              headerName: t('attributes.customerNumber'),
              width: 180,
              type: 'string',
              editable: true,
            },
            {
              field: 'location',
              headerName: t('attributes.location'),
              width: 180,
              type: 'string',
              valueGetter: getLocation,
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  locationId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: locationId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectLocationCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'salesContactId',
              headerName: t('attributes.salesContact'),
              width: 180,
              type: 'string',
              editable: true,
              valueGetter: getSalesContact,
              renderEditCell(params: GridRenderEditCellParams) {
                const { id, field } = params;
                const cardId = params.row.customerId;
                const contactId = params.row.salesContactId;
                const updateCellValue = (
                  salesContactId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: salesContactId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectContactCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                    cardId={cardId}
                    contactId={contactId}
                  />
                );
              },
            },
            {
              field: 'destinationCountry',
              headerName: t('attributes.destinationCountry'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values({ Mix: 'Mix', ...COUNTRIES_MAP }),
              valueGetter: getCountry,
              editable: true,
            },
            {
              field: 'bookedPPL',
              headerName: t('attributes.bookedPPL'),
              width: 180,
              type: 'number',
            },
            {
              field: 'sentPPL',
              headerName: t('attributes.sentPPL'),
              width: 180,
              type: 'number',
              editable: true,
            },
            {
              field: 'difference',
              headerName: t('attributes.difference'),
              width: 180,
              type: 'number',
              valueGetter: getDifference,
            },
            {
              field: 'selfCost',
              headerName: t('attributes.selfCost'),
              width: 180,
              type: 'number',
              editable: true,
            },
            {
              field: 'selfCostCurrency',
              headerName: t('attributes.selfCostCurrency'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values(CURRENCIES),
              valueGetter: getSelfCostCurrency,
              editable: true,
            },
            {
              field: 'customerCost',
              headerName: t('attributes.customerCost'),
              width: 180,
              type: 'number',
              editable: true,
            },
            {
              field: 'customerCurrency',
              headerName: t('attributes.customerCurrency'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values(CURRENCIES),
              valueGetter: getCustomerCurrency,
              editable: true,
            },
          ]}
          rowHeight={30}
          autoHeight={true}
          autoPageSize={true}
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  );
}
