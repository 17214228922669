import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { CommonTextField } from '../components/form/CommonTextField';
import { OrderInput } from '../generated/graphql';
import { AutoReset } from '../utils/AutoReset';
import { SelectCustomer } from './form/SelectCustomer';
import { SupressEnterForm } from './form/SupressEnterForm';

const validationSchema = (t: TFunction) =>
  yup.object({
    transportationDate: yup.date().required(
      t('validation.atLeastLen', {
        name: t('attributes.transportationDate'),
        len: 3,
      }),
    ),
    note: yup.string(),
    name: yup.string(),
    packages: yup.number().min(1),
    pallets: yup.number().min(1),
    customerId: yup
      .string()
      .matches(
        /^\d+$/,
        t('validation.isRequired', { name: t('attributes.customer') }),
      ),
  });

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '80%',
    margin: theme.spacing(2),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(2, 'auto'),
  },
  subResources: {
    margin: theme.spacing(2, 'auto'),
    width: '90%',
  },
  button: {
    margin: theme.spacing(2, 'auto'),
  },
  formContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(2),
  },
}));

interface OrderFormProps {
  initialValues: Omit<OrderInput, 'customerId'> & { customerId?: number };
  onSubmit: (
    values: Omit<OrderInput, 'customerId'> & { customerId?: number },
  ) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
}
export function OrderForm(props: OrderFormProps) {
  const {
    initialValues,
    onSubmit,
    submitButtonLabel,
    readOnly = false,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Paper className={classes.formContent}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema(t)}
        onSubmit={onSubmit}
      >
        {(props) => (
          <SupressEnterForm
            onSubmit={props.handleSubmit}
            className={classes.root}
          >
            <Grid container>
              <AutoReset readOnly={readOnly} />
              <Grid item xs={6}>
                <CommonTextField
                  name="transportationDate"
                  type="date"
                  className={classes.field}
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="packages"
                  className={classes.field}
                  type="number"
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="pallets"
                  className={classes.field}
                  type="number"
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="palletSpace"
                  className={classes.field}
                  type="number"
                  readOnly={readOnly}
                />
                <CommonTextField
                  name="weight"
                  className={classes.field}
                  type="number"
                  readOnly={readOnly}
                />
                <Typography
                  variant="subtitle1"
                  className={classes.categoryHeader}
                  align="left"
                >
                  {t('attributeGrouping.notes')}
                </Typography>
                <CommonTextField
                  className={classes.field}
                  name="note"
                  multiline
                  fullWidth
                  rows={4}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle1"
                  className={classes.categoryHeader}
                  align="left"
                >
                  {t('resource.customer.capitalized')}
                </Typography>
                <SelectCustomer
                  name="customerId"
                  className={classes.field}
                  readOnly={readOnly}
                />
              </Grid>
            </Grid>
            {!readOnly && (
              <Grid container justifyContent="flex-end">
                <Button
                  className={classes.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            )}
          </SupressEnterForm>
        )}
      </Formik>
    </Paper>
  );
}
