import { isBefore, isMatch, set, sub, add, format, parse } from 'date-fns';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { DATE_FORMAT } from './date_time';
import { BREAK_POINT_HOUR } from './route_leg_dates';

export function getCurrentDate(): Date {
  const currentDate = new Date();
  const breakPoint = set(currentDate, { hours: BREAK_POINT_HOUR, minutes: 0 });

  if (isBefore(currentDate, breakPoint)) {
    const cDate = sub(currentDate, { days: 1 });
    return cDate;
  }
  return currentDate;
}

export function getTFTMasterDate(isStart: boolean): Date {
  const { getMasterDate } = useUserConfiguration();
  const masterTftDate = isStart
    ? getMasterDate().split('#')[0] //start
    : getMasterDate().split('#')[1]; //end
  if (masterTftDate == null || masterTftDate === '-') {
    const todayDate = getCurrentDate();
    return todayDate;
  } else {
    return parse(masterTftDate, DATE_FORMAT, new Date());
  }
}

export function getTimeOfToday(isSubstract: boolean, hr: number): string {
  const currentDate = new Date();
  let newDate;
  if (isSubstract) {
    newDate = sub(currentDate, { hours: hr });
  } else {
    newDate = add(currentDate, { hours: hr });
  }
  return format(newDate, 'HH:mm');
}

export function isDateString(val: string | null): boolean {
  return isMatch(val ?? '', DATE_FORMAT);
}

export function getTimeAsNumberOfMinutes(time: string) {
  //HH:mm
  const timeParts = time.split(':');
  return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
}

export function getProductionDateFromUserTime(time: string): Date {
  //HH:mm
  const startHour = parseInt(time.split(':')[0]);
  const startMinute = parseInt(time.split(':')[1]);

  const currentDate = new Date();
  const breakPoint = set(currentDate, {
    hours: startHour,
    minutes: startMinute,
  });

  if (isBefore(currentDate, breakPoint)) {
    const cDate = sub(currentDate, { days: 1 });
    return cDate;
  }

  return currentDate;
}

export function getYesterdayDate(): Date {
  const currentDate = new Date();

  const cDate = sub(currentDate, { days: 1 });
  return cDate;
}

export function getRandomNumber(excludedNo: number): number {
  let n = Math.floor(Math.random() * (99 - 40) + 40);
  if (n >= excludedNo) n++;
  return n;
}
