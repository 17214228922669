import { useTranslation } from 'react-i18next';
import { useFormContext, Controller, Path } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../lib/date_time';
import { TextField, TextFieldProps } from '@mui/material';

interface CommonDateFieldProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  disabled?: boolean;
  minDate?: boolean;
  maxDate?: boolean;
  dateString?: string;
  fullWidth?: boolean;
}

export function CommonDateFieldRHF(
  props: CommonDateFieldProps & TextFieldProps,
) {
  const { t } = useTranslation();
  const { control, setValue, getValues } =
    useFormContext<SporadicRouteFormInput>();

  const {
    name,
    readOnly = false,
    noLabel = false,
    controllerName,
    disabled,
    minDate,
    maxDate,
    fullWidth = false,
  } = props;

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            value={field.value}
            label={noLabel ? undefined : t(`attributes.${label}`)}
            readOnly={readOnly}
            onChange={(newValue) => {
              if (newValue) {
                const formatDate = format(new Date(`${newValue}`), DATE_FORMAT);
                setValue(controllerName, formatDate);
              }
            }}
            inputFormat="yyyy-MM-dd"
            disabled={disabled}
            minDate={
              minDate
                ? new Date()
                : name === 'endExceptionDate'
                ? getValues('startExceptionDate')
                : getValues('startDate')
            }
            maxDate={maxDate ? getValues('endDate') : undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                {...field}
                id={`${controllerName}_date`}
                variant="outlined"
                fullWidth={fullWidth}
                error={fieldState.error && Boolean(fieldState.error)}
                helperText={
                  fieldState.error &&
                  Boolean(fieldState.error) &&
                  fieldState.error?.message
                }
                placeholder="yyyy-mm-dd"
                inputProps={{
                  readOnly: true,
                }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
}
