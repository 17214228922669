import { Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';
import { HolidayForm } from '../components/HolidayForm';
import { HolidayFormValues } from '../components/HolidayForm';
import { ListHoliday } from '../components/ListHoliday';
import {
  GetHolidaysDocument,
  useAddHolidayMutation,
} from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(3),
  },
  componentTitle: {
    marginBottom: theme.spacing(2),
  },
}));

export function Holidays() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { department } = useUserConfiguration();
  const [country, setCountry] = useState<string>(
    department?.id ?? Object.keys(COUNTRIES_MAP)[0],
  );

  const [addHoliday] = useAddHolidayMutation({});

  const onSubmit = async (values: HolidayFormValues) => {
    const { country: newCountry } = values;
    await addHoliday({
      variables: {
        input: values,
      },
      refetchQueries: [
        { query: GetHolidaysDocument, variables: { country: newCountry } },
      ],
    });
    setCountry(newCountry);
  };

  return (
    <Grid container spacing={3}>
      <HelmetComponent title={t('resource.holiday.plural')} />
      <Grid item xs={12}>
        <Typography variant="h1">{t('resource.holiday.plural')}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Typography
            className={classes.componentTitle}
            align="left"
            variant="subtitle1"
          >
            {t('pages.holidays.addComponentTitle')}
          </Typography>
          <HolidayForm onSubmit={onSubmit} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper className={classes.paper}>
          <ListHoliday country={country} setCountry={setCountry} />
        </Paper>
      </Grid>
    </Grid>
  );
}
