import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import clsx from 'clsx';
import { useFormContext, Path, Controller } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../../views/CheckListRoutesTraffic';
import { ImportShipmentFormInput } from '../../views/ImportShipment';

interface FastTextNoteProps {
  name: string;
  controllerName: Path<CheckListRoutesFormInput | ImportShipmentFormInput>;
  readOnly?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  smallFontSize: {
    '& input': {
      fontSize: 12,
    },
    '&.MuiOutlinedInput-multiline': {
      fontSize: 12,
    },
  },
  smallPadding: {
    '& input': {
      padding: 4,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 4,
    },
  },
}));

export function FastTextNoteCLRHF(props: FastTextNoteProps & TextFieldProps) {
  const {
    name,
    controllerName,
    readOnly = false,
    fullWidth = false,
    InputProps = {},
    ...rest
  } = props;

  const classes = useStyles();
  const { control } = useFormContext<
    CheckListRoutesFormInput | ImportShipmentFormInput
  >();

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...props}
          {...field}
          id={name}
          size={'small'}
          onKeyDown={(e) => {
            if (e.altKey) {
              if (e.target && e.target instanceof HTMLTextAreaElement) {
                (e.target as HTMLTextAreaElement).blur();
              }
              e.preventDefault();
            }
          }}
          variant="outlined"
          error={fieldState?.error && Boolean(fieldState.error)}
          helperText={fieldState?.error && fieldState.error.message}
          fullWidth={fullWidth}
          InputProps={{
            className: clsx([
              classes.input,
              {
                [classes.smallFontSize]: true,
                [classes.smallPadding]: true,
              },
            ]),
            readOnly,
            ...InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      )}
    />
  );
}
