import { Box, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentFile,
  GetInternalTerminalReportInfoQuery,
  InternalTerminalReportInfoResponse,
  useGetInternalTerminalReportInfoQuery,
  useGetValueBoxForDateQuery,
} from '../generated/graphql';
import { ReportFromTrafficValueBox } from '../components/ReportFromTrafficValueBox';
import { ReportFromTrafficInfo } from '../components/ReportFromTrafficInfo';
import { BoxType } from './ViewReportFromTerminal';
import { TrafficGridValues } from './TrafficGridValues';

interface DashReportsProps {
  selectedTerminalId: number;
  selectedDate: string;
}

export function TrafficDashBoxAndInfo(props: DashReportsProps) {
  const { t } = useTranslation();
  const { selectedTerminalId, selectedDate } = props;
  const [isLoadingSelected, setPageLoadingSelected] = useState<boolean>(false);

  //for valueBox API
  const { data: valueBoxData } = useGetValueBoxForDateQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      internalTerminalId: selectedTerminalId,
      date: selectedDate,
      forTerminal: true,
    },
  });

  let finalBoxes: BoxType[] = [];
  if (
    valueBoxData &&
    valueBoxData.getValueBoxesForDay &&
    valueBoxData.getValueBoxesForDay.length > 0
  ) {
    valueBoxData.getValueBoxesForDay.forEach((b) => {
      if (b.boxValue !== 0) {
        finalBoxes = finalBoxes.concat(b);
      }
    });

    finalBoxes = [...finalBoxes].sort((a, b) =>
      a.boxNameText.localeCompare(b.boxNameText, undefined, {
        sensitivity: 'base',
      }),
    );

    finalBoxes = _.orderBy(finalBoxes, ['isFixedBox'], ['desc']);
  }

  //for info API
  const { data: infoData, loading } = useGetInternalTerminalReportInfoQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      internalTerminalId: selectedTerminalId,
      dateForAddInfo: selectedDate,
      forTerminal: true,
    },
  });

  let refinedInfo: Array<
    { __typename?: 'InternalTerminalReportInfoResponse' } & Pick<
      InternalTerminalReportInfoResponse,
      | 'id'
      | 'internalTerminalId'
      | 'dateForAddInfo'
      | 'info'
      | 'infoType'
      | 'isAddedFromTerminal'
      | 'replyInfo'
      | 'replyText'
    > & {
        files: Array<
          { __typename?: 'DocumentFile' } & Pick<
            DocumentFile,
            'id' | 'originalname' | 'mimetype' | 'path' | 'size'
          >
        >;
      }
  > = [];

  if (
    infoData &&
    infoData.internalTerminalReportInfo &&
    infoData.internalTerminalReportInfo.length > 0
  ) {
    const infoList: GetInternalTerminalReportInfoQuery['internalTerminalReportInfo'] =
      infoData.internalTerminalReportInfo;

    refinedInfo = infoList?.length
      ? infoList.flatMap((info) => {
          return {
            id: info.id,
            dateForAddInfo: info.dateForAddInfo,
            info: info.info,
            infoType: info.infoType,
            isAddedFromTerminal: info.isAddedFromTerminal,
            internalTerminalId: info.internalTerminalId,
            replyInfo: info.replyInfo ?? '',
            replyText: info.replyText ?? '',
            files: info.files,
          };
        })
      : [];
  }

  useEffect(() => {
    if (selectedTerminalId && selectedTerminalId !== 0) {
      setPageLoadingSelected(true);
      if (!loading) setPageLoadingSelected(false);
    } else {
      //no terminal is selected
      setPageLoadingSelected(false);
    }
  }, [selectedDate, selectedTerminalId, loading]);

  return (
    <Grid container item xs={12} justifyContent="center">
      {isLoadingSelected ? (
        <Typography variant="subtitle1" align="center">
          <strong>{t('validation.loadingApi')}</strong>
        </Typography>
      ) : selectedTerminalId === 0 ? (
        <Typography variant="subtitle1" align="center">
          <strong>{t('validation.noTerminalSelected')}</strong>
        </Typography>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <Box height="100%">
            <ReportFromTrafficValueBox finalBoxes={finalBoxes} />
            <br />
            <TrafficGridValues
              internalTerminalId={selectedTerminalId}
              selectedDate={selectedDate}
              gridType="Terminal"
            />
            <br />
            <ReportFromTrafficInfo
              refinedInfo={refinedInfo}
              isReplyEnabled={true}
            />
          </Box>
        </div>
      )}
    </Grid>
  );
}
