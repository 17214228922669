import {
  createStyles,
  Grid,
  IconButton,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box } from '@mui/system';
import { useState } from 'react';
import { DangerousGoodsClassification, Maybe } from '../generated/graphql';
import { useFormContext } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../views/CheckListRoutesTraffic';
import { SelectLocationCLRHF } from './form/SelectLocationCLRHF';
import { FastTextPalletSpaceCLRHF } from './form/FastTextPalletSpaceCLRHF';
import { FastTextNoteCLRHF } from './form/FastTextNoteCLRHF';
import { FastTextPackageCLRHF } from './form/FastTextPackageCLRHF';
import { FastTextPalletsCLRHF } from './form/FastTextPalletsCLRHF';
import { FastTextWeightCLRHF } from './form/FastTextWeightCLRHF';

export interface LoadingListItemInterface {
  id?: number;
  packages?: Maybe<number | string>;
  pallets?: Maybe<number | string>;
  palletSpace?: Maybe<number | string>;
  weight?: Maybe<number | string>;
  note: string;
  locationId?: number;
  checked: boolean;
  isDangerous: boolean;
  unNumber?: Maybe<number> | undefined;
  isLimitedQty: boolean;
  classification?: Maybe<DangerousGoodsClassification> | undefined;
}

interface LoadOrderProps {
  removeOrder: (index: number) => void;
  readOnly: boolean;
  routeIndex: number;
  legIndex: number;
  orderIndex: number;
  key: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      margin: 0,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    deleteButton: {
      padding: '0 !important',
      marginRight: 1,
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
  }),
);

export function LoadOrderCLRHF(props: LoadOrderProps) {
  const { removeOrder, readOnly, routeIndex, legIndex, orderIndex, key } =
    props;
  const classes = useStyles();

  const { getValues: getFieldProps } =
    useFormContext<CheckListRoutesFormInput>();

  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(-1);
  function getChecked(index: number): string {
    if (
      getFieldProps(
        `routes.${routeIndex}.legs.${legIndex}.loadingListItems.${index}.checked`,
      )
    ) {
      return classes.greenChecked;
    }
    return '';
  }

  function getDangerous(index: number): string {
    if (
      getFieldProps(
        `routes.${routeIndex}.legs.${legIndex}.loadingListItems.${index}.isDangerous`,
      )
    ) {
      return classes.redBorder;
    }
    return '';
  }

  const handleBorderVisibility = (index: number) => {
    if (index !== currentFocusedIndex) setCurrentFocusedIndex(index);
  };

  return (
    <>
      <Grid item xs={12} spacing={2} container className={classes.root}>
        <ListItem
          key={key}
          divider
          className={`${getChecked(orderIndex)} ${getDangerous(orderIndex)} ${
            classes.noPadding
          }`}
        >
          <Grid
            xs={12}
            item
            container
            key={orderIndex}
            style={{ width: '100%' }}
            onFocus={(event) => {
              if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                handleBorderVisibility(orderIndex);
              }
            }}
            onBlur={(event) => {
              if (!event.currentTarget.contains(event.relatedTarget as Node)) {
                setCurrentFocusedIndex(-1);
              }
            }}
            className={`${
              currentFocusedIndex === orderIndex && !readOnly
                ? classes.greyBackFocused
                : ''
            } ${classes.noPadding}`}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: '100%' }}
            >
              <Grid item xs={6}>
                <SelectLocationCLRHF
                  key={`routes.${routeIndex}.legs.${legIndex}_${orderIndex}`}
                  name={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.locationId`}
                  controllerName={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.locationId`}
                  className={classes.selecLocationField}
                  readOnly={readOnly}
                  size="small"
                  smallFontSize
                  noLabel
                  smallPadding
                  shortAddress={true}
                />
              </Grid>

              <Grid item xs={1}>
                <FastTextPackageCLRHF
                  name={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.packages`}
                  controllerName={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.packages`}
                  className={classes.numberFields}
                  type="number"
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={1}>
                <FastTextPalletsCLRHF
                  name={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.pallets`}
                  controllerName={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.pallets`}
                  className={classes.numberFields}
                  type="number"
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={1}>
                <FastTextPalletSpaceCLRHF
                  name={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.palletSpace`}
                  controllerName={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.palletSpace`}
                  className={classes.numberFields}
                  type="number"
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={1}>
                <FastTextWeightCLRHF
                  name={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.weight`}
                  controllerName={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.weight`}
                  className={classes.numberFields}
                  type="number"
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={2} style={{ marginRight: 2 }}>
                <FastTextNoteCLRHF
                  multiline
                  name={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.note`}
                  controllerName={`routes.${routeIndex}.legs.${legIndex}.loadingListItems.${orderIndex}.note`}
                  readOnly={readOnly}
                  className={classes.field}
                />
              </Grid>
              <Grid
                item
                xs={1}
                container
                alignItems="center"
                justifyContent="center"
              >
                <Box width={'100%'} display="flex" alignItems="center">
                  {!readOnly && (
                    <IconButton
                      className={classes.deleteButton}
                      onClick={() => {
                        removeOrder(orderIndex);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Box>
          </Grid>
        </ListItem>
      </Grid>
    </>
  );
}
