import { Grid, Container, Paper, Typography } from '@material-ui/core';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetValueBoxForDateQuery,
  useAddEditValueBoxEntryMutation,
  useDeleteValueBoxEntryMutation,
  useGetValueBoxForDateQuery,
} from '../generated/graphql';
import { getRandomNumber } from '../lib/date';
import { DATE_FORMAT } from '../lib/date_time';
import { ValueBox } from './ValueBox';

interface ReportToTerminalValueBoxProps {
  selectedTerminal: {
    id: number;
  };
  selectedDateAndDay: { date: string; day: string };
  refreshToggle: boolean;
  createBoxForTerminal: boolean;
  valueBoxFunction?: (data: GetValueBoxForDateQuery) => void;
}

type BoxType = {
  boxId: number;
  entryId: number;
  boxNameText: string;
  boxValue: number;
  isReadOnly: boolean;
  isFixedBox: boolean;
  isYesNoBox: boolean;
  isAddedFromTerminal: boolean;
  isTimeBox: boolean;
};

export function ReportToTerminalValueBox(props: ReportToTerminalValueBoxProps) {
  const { t } = useTranslation();
  const {
    selectedTerminal,
    selectedDateAndDay,
    refreshToggle,
    createBoxForTerminal,
    valueBoxFunction,
  } = props;
  const { id } = selectedTerminal;
  const { date } = selectedDateAndDay;
  const curTerminalId = selectedTerminal.id;
  const { data, loading, error, refetch } = useGetValueBoxForDateQuery({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    variables: {
      internalTerminalId: id,
      date: date,
      forTerminal: createBoxForTerminal,
    },
  });

  const [editValueBoxEntryApi] = useAddEditValueBoxEntryMutation();
  const [deleteValueBoxEntryApi] = useDeleteValueBoxEntryMutation();
  const [allBoxData, setAllBoxData] = useState<BoxType[]>([]);

  useEffect(() => {
    if (data && data.getValueBoxesForDay && valueBoxFunction) {
      valueBoxFunction(data);
    }
  }, [data]);

  useEffect(() => {
    if (id && date) {
      refetch({
        internalTerminalId: id,
        date: date,
        forTerminal: createBoxForTerminal,
      });
    }
  }, [id, date, refreshToggle]);

  useEffect(() => {
    if (loading === false && data) {
      let finalBoxes: BoxType[] = [];
      if (data.getValueBoxesForDay && data.getValueBoxesForDay.length > 0) {
        data.getValueBoxesForDay.forEach((b) => {
          if (b.isReadOnly && b.boxValue === 0) {
          } else {
            finalBoxes = finalBoxes.concat(b);
          }
        });

        if (createBoxForTerminal) {
          finalBoxes = [...finalBoxes].sort((a, b) =>
            a.boxNameText.localeCompare(b.boxNameText, undefined, {
              sensitivity: 'base',
            }),
          );
          finalBoxes = _.orderBy(finalBoxes, ['isFixedBox'], ['desc']);
        }

        setAllBoxData(finalBoxes);
      } else {
        setAllBoxData([]);
      }
    }
  }, [data, loading]);

  if (loading) {
    return <>Loading...</>;
  }

  if (!data || error) {
    return (
      <>
        <Typography variant="subtitle1" style={{ paddingRight: '1%' }}>
          {`No box-${error?.message}`}
        </Typography>
      </>
    );
  }

  return (
    <Paper>
      <Container style={{ flexDirection: 'row' }}>
        {allBoxData.length > 0 ? (
          <div>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {allBoxData.map((b) => {
                return (
                  <ValueBox
                    key={`${b.boxId}${getRandomNumber(1)}`}
                    boxData={b}
                    onValueChanged={(id, val, entryId) => {
                      if (val && val !== '') {
                        const dayOfDate = format(
                          parse(date, DATE_FORMAT, new Date()),
                          'EEE',
                        );

                        editValueBoxEntryApi({
                          variables: {
                            boxEntry: {
                              valueBoxId: id,
                              entryId: entryId ? entryId : 0,
                              boxNameText: b.boxNameText,
                              boxValue: val && val !== '' ? parseInt(val) : 0,
                              valueAddedDate: date,
                              valueAddedDay: dayOfDate,
                            },
                          },
                        }).then(() => {
                          refetch({
                            internalTerminalId: curTerminalId,
                            date: date,
                            forTerminal: createBoxForTerminal,
                          });
                        });
                      } else if (entryId && entryId !== 0 && val === '') {
                        deleteValueBoxEntryApi({
                          variables: {
                            entryId,
                          },
                        }).then(() => {
                          refetch({
                            internalTerminalId: curTerminalId,
                            date: date,
                            forTerminal: createBoxForTerminal,
                          });
                        });
                      }
                    }}
                  />
                );
              })}
            </Grid>
          </div>
        ) : (
          <Typography variant="subtitle1" style={{ paddingRight: '1%' }}>
            {`${t('resource.noBoxAdded.capitalized')}`}
          </Typography>
        )}
      </Container>
    </Paper>
  );
}
