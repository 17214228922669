import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

export function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarFilterButton
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
      <GridToolbarDensitySelector
        style={{ color: '#69bc46' }}
        onResize={undefined}
        onResizeCapture={undefined}
      />
    </GridToolbarContainer>
  );
}
