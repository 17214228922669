import { Box, Button, IconButton, Theme, Tooltip } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Tab, Tabs } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export interface TabsContainerProps<T> {
  title: (index: number) => string;
  list: T[];
  children: (
    params: { item: T; index: number },
    setTab: (tabIndex: number) => void,
  ) => React.ReactNode;
  tab: number;
  setTab: (tabIndex: number) => void;
  addAction: () => void;
  onMove: (fromTab: number, toTab: number) => void;
  addButtonTitle: string;
  readOnly: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    padding: '0px',
  },
  tabColor: {
    '&.MuiButtonBase-root.MuiTab-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    width: '80%',
    '&.MuiButtonBase-root.MuiTab-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTabs-flexContainer': {
      maxWidth: 400,
    },
  },
}));

function getRouteIdForTabs(
  title: (index: number) => string,
  index: number,
  routeId: string,
  isErr: boolean,
) {
  if (routeId == null || routeId === '') {
    return `${title(index)}${isErr ? ' ⚠️' : ''}`;
  }

  return `${routeId}${isErr ? ' ⚠️' : ''}`;
}

export function TabsContainer<T>(props: TabsContainerProps<T>) {
  const { list, title, children, tab, setTab, addAction, readOnly, onMove } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { errors } = useFormikContext<{
    routes: { [key: string]: unknown }[];
  }>();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(parseInt(newValue, 10));
  };
  const { getFieldProps } = useFormikContext();
  const data = getFieldProps('routes');
  const tabString = tab.toString();
  const routeErrors = errors?.routes;
  let allErrArr: boolean[] = [];

  if (routeErrors != null) {
    for (const err of routeErrors) {
      if (err == undefined) {
        allErrArr = allErrArr.concat(false);
      } else {
        allErrArr = allErrArr.concat(true);
      }
    }
  }

  return (
    <TabContext value={tabString}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Tabs
          onChange={handleChange}
          className={classes.tabs}
          textColor="primary"
          variant="scrollable"
          scrollButtons
          indicatorColor="primary"
          value={tabString}
        >
          {list.map((_item, index: number) => (
            <Tab
              label={getRouteIdForTabs(
                title,
                index,
                data.value[index].routeId,
                allErrArr ? allErrArr[index] : false,
              )}
              value={index.toString()}
              key={index}
              className={classes.tabColor}
            />
          ))}
        </Tabs>
        {!readOnly && (
          <Box display="flex" justifyContent="flex-end">
            <Tooltip
              title={`${t('actions.moveItem', {
                item: t('resource.route.lowercased'),
                direction: t('actions.right'),
              })}`}
            >
              <IconButton
                size="small"
                disabled={tab === 0}
                onClick={() => {
                  onMove(tab, tab - 1);
                  setTab(tab - 1);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`${t('actions.moveItem', {
                item: t('resource.route.lowercased'),
                direction: t('actions.left'),
              })}`}
            >
              <IconButton
                size="small"
                onClick={() => {
                  onMove(tab, tab + 1);
                  setTab(tab + 1);
                }}
                disabled={tab === list.length - 1 || list.length === 0}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Tooltip>

            <Button variant="contained" color="primary" onClick={addAction}>
              {t('button.add', { item: t('resource.route.lowercased') })}
            </Button>
          </Box>
        )}
      </Box>
      {list.map((item, index: number) => (
        <TabPanel value={index.toString()} key={index} className={classes.tab}>
          {children({ item, index }, setTab)}
        </TabPanel>
      ))}
    </TabContext>
  );
}
