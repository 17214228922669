import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@mui/material/Grid';
import {
  DataGridPro,
  GridCellParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import clsx from 'clsx';
import { format } from 'date-fns';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { HelmetComponent } from '../components/HelmetComponent';
import { configuration } from '../configuration';
import {
  GetPeakPlanningItemsDocument,
  PeakPlanningProductType,
  PeakPlanningStatus,
  useCreatePeakPlanningItemMutation,
} from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { SelectLocationCell } from './datagrid/SelectLocationCell';
import { SelectSubcontractorCell } from './datagrid/SelectSubcontractorCell';

interface peakPlanning {
  id: string;
  productionDate: string;
  productType?: PeakPlanningProductType;
  status: PeakPlanningStatus;
  productCountry?: string;
  subcontractor?: {
    id: number;
    name: string;
  } | null;
  capacity?: number;
  arrivalCity: string;
  departureCity: string;
  note: string;
  peakPlanningLocations: {
    position: number;
    bookedPPL?: number;
    id?: number;
    loadEnd?: string;
    loadStart?: string;
    location: {
      id: number;
      name: string;
    };
  };
}

function getBookedPPL(params: GridValueGetterParams) {
  const value = params.row.peakPlanningLocations.bookedPPL;
  return value;
}

function getCountry(params: GridValueGetterParams) {
  const country = params.row.productCountry;
  return COUNTRIES[country as keyof typeof COUNTRIES];
}

function newTransport(
  locationId = 0,
  name = '',
  productType: PeakPlanningProductType | undefined = undefined,
  productCountry: string | undefined = undefined,
) {
  return {
    id: uuidv4(),
    productionDate: format(new Date(), DATE_FORMAT),
    status: PeakPlanningStatus.NotBooked,
    productType: productType,
    productCountry: productCountry,
    capacity: undefined,
    arrivalCity: '',
    departureCity: '',
    note: '',
    subcontractor: undefined,
    peakPlanningLocations: {
      bookedPPL: undefined,
      loadStart: '',
      loadEnd: '',
      location: {
        id: locationId,
        name,
      },
      position: 1,
    },
  };
}

function getDepartureLocation(params: any) {
  const value = params.getValue(params.id, 'peakPlanningLocations' || '');
  return value.location.name || '';
}

function validateTransportRows(transportRows: peakPlanning[]): boolean {
  let valid = true;
  for (const transportRow of transportRows) {
    if (
      transportRow.peakPlanningLocations.location == null ||
      transportRow.peakPlanningLocations.location.id < 1
    ) {
      valid = false;
    }
  }
  return valid;
}

export const COUNTRIES = { mix: 'Mix', ...COUNTRIES_MAP };

const useStyles = makeStyles((theme) => {
  return {
    root: {
      '& .error': {
        backgroundColor: theme.palette.error.light,
        borderColor: theme.palette.error.light,
      },
      '& .unEditable': {
        backgroundColor: theme.palette.grey[300],
        opacity: 0.6,
      },
    },
  };
});

interface AddTransportProps {
  setAddTransportOpen: Dispatch<SetStateAction<boolean>>;
  startDate: string;
  endDate: string;
  departmentId: string;
}

export function AddTransport(props: AddTransportProps) {
  const { setAddTransportOpen, startDate, endDate, departmentId } = props;
  const apiRef = useGridApiRef();
  const { t } = useTranslation();
  const [validationChecked, setValidationChecked] = useState<boolean>(false);

  const [createPeakPlanningItem] = useCreatePeakPlanningItemMutation({
    refetchQueries: [
      {
        query: GetPeakPlanningItemsDocument,
        variables: { startDate, endDate, departmentId },
      },
    ],
  });
  const [transportRows, setTransportRows] = useState<peakPlanning[]>([
    newTransport(),
  ]);

  const savePeakPlanningItems = () => {
    setValidationChecked(true);
    if (transportRows && validateTransportRows(transportRows)) {
      for (const transportRow of transportRows) {
        createPeakPlanningItem({
          variables: {
            input: {
              id: undefined,
              subcontractorId: transportRow.subcontractor?.id,
              productionDate: transportRow.productionDate,
              status: transportRow.status,
              productType: transportRow.productType,
              productCountry: transportRow.productCountry,
              capacity: transportRow.capacity,
              note: transportRow.note,
              peakPlanningLocations: [
                {
                  bookedPPL: transportRow.peakPlanningLocations.bookedPPL,
                  loadStart:
                    transportRow.peakPlanningLocations.loadStart || undefined,
                  loadEnd:
                    transportRow.peakPlanningLocations.loadEnd || undefined,
                  locationId: transportRow.peakPlanningLocations.location.id,
                  position: 1,
                },
              ],
            },
          },
        });
      }
      setTransportRows([]);
      setAddTransportOpen(false);
    }
  };

  const createNewRow = () => {
    setTransportRows([
      ...transportRows,
      newTransport(
        transportRows[0]?.peakPlanningLocations?.location.id || undefined,
        transportRows[0]?.peakPlanningLocations?.location.name || undefined,
        transportRows[0]?.productType,
        transportRows[0]?.productCountry || undefined,
      ),
    ]);
  };

  const setField =
    (params: GridCellParams) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setTransportRows(
        transportRows.map((row) => {
          if (row.id == params.id) {
            return {
              ...row,
              peakPlanningLocations: {
                ...row.peakPlanningLocations,
                [params.field]: e.target.value,
              },
            };
          }
          return row;
        }),
      );
    };

  const styles = useStyles();
  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      const updatedRows = transportRows.map((row) => {
        if (['location', 'loadStart', 'loadEnd'].includes(field)) {
          return {
            ...row,
            peakPlanningLocations: {
              ...row.peakPlanningLocations,
              [field]: value,
            },
          };
        }
        if (['productType'].includes(field)) {
          return {
            ...row,
            [field]: Object.keys(PeakPlanningProductType).find(
              (key) =>
                PeakPlanningProductType[
                  key as keyof typeof PeakPlanningProductType
                ] === value,
            ),
          };
        }
        if (['productCountry'].includes(field)) {
          return {
            ...row,
            [field]: Object.keys(COUNTRIES).find(
              (key) => COUNTRIES[key as keyof typeof COUNTRIES] === value,
            ),
          };
        }
        if (['bookedPPL'].includes(field)) {
          if (row.id == id) {
            return {
              ...row,
              peakPlanningLocations: {
                ...row.peakPlanningLocations,
                bookedPPL: value,
              },
            };
          }
        }
        if (row.id == id) {
          if (field === 'productionDate') {
            return { ...row, [field]: format(value, DATE_FORMAT) };
          }
          return { ...row, [field]: value };
        }
        return row;
      });
      setTransportRows(updatedRows);
      setValidationChecked(false);
    },
    [transportRows],
  );
  return (
    <Grid container sx={{ mb: 5 }}>
      <HelmetComponent title={t('resource.peakPlanning.capitalized')} />
      <Grid item container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={2}
        >
          <Typography variant="h2">
            {t('pages.addPage.title', { item: 'Transport' })}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {transportRows.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={savePeakPlanningItems}
              >
                {t('button.save')}
              </Button>
            )}
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={createNewRow}
                startIcon={<AddIcon />}
              >
                {t('button.create', {
                  item: 'new row',
                })}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <DataGridPro
          className={styles.root}
          rows={transportRows}
          onCellEditCommit={handleCellEditCommit}
          apiRef={apiRef}
          isCellEditable={({ id, field }) => {
            if (
              ['location', 'productType', 'productCountry'].includes(field) &&
              id !== transportRows[0].id
            ) {
              return false;
            }
            return true;
          }}
          columns={[
            {
              field: 'id',
              type: 'number',
              headerName: t(`attributes.id`),
              width: 100,
              hide: configuration.production,
            },
            {
              field: 'productionDate',
              headerName: t(`attributes.productionDate`),
              width: 200,
              type: 'date',
              editable: true,
            },
            {
              field: 'location',
              headerName: t('attributes.loadingLocation'),
              width: 200,
              editable: true,
              sortable: false,
              valueGetter: getDepartureLocation,
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  location?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: location,
                  });
                };

                return (
                  <SelectLocationCell
                    onCellChange={updateCellValue}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
              cellClassName: (params: GridCellParams) =>
                clsx('', {
                  error: validationChecked
                    ? (params.value as number) < 1
                    : false,
                  unEditable: !params.isEditable,
                }),
            },
            {
              field: 'loadStart',
              headerName: t('attributes.loadStart'),
              width: 200,
              renderCell: (params) => (
                <TextField
                  id="loadStart"
                  placeholder={t(`attributes.loadStart`)}
                  fullWidth={true}
                  type="time"
                  value={
                    transportRows.find((x) => x.id === params.id)
                      ?.peakPlanningLocations.loadStart
                  }
                  onChange={setField(params)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ),
            },
            {
              field: 'loadEnd',
              headerName: t('attributes.loadEnd'),
              width: 200,
              renderCell: (params) => (
                <TextField
                  id="loadEnd"
                  placeholder={t(`attributes.loadEnd`)}
                  fullWidth={true}
                  value={
                    transportRows.find((x) => x.id === params.id)
                      ?.peakPlanningLocations.loadEnd
                  }
                  onChange={setField(params)}
                  type="time"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              ),
            },
            {
              field: 'bookedPPL',
              headerName: t('attributes.bookedPPL'),
              width: 200,
              type: 'number',
              editable: true,
              valueGetter: getBookedPPL,
            },
            {
              field: 'productType',
              headerName: t('attributes.productType'),
              width: 200,
              type: 'singleSelect',
              valueOptions: Object.values(PeakPlanningProductType),
              editable: true,
              sortable: false,
              cellClassName: (params: GridCellParams) =>
                clsx('', {
                  unEditable: !params.isEditable,
                }),
            },
            {
              field: 'productCountry',
              headerName: t('attributes.productCountry'),
              width: 200,
              type: 'singleSelect',
              valueOptions: Object.values(COUNTRIES),
              valueGetter: getCountry,
              editable: true,
              sortable: false,
              cellClassName: (params: GridCellParams) =>
                clsx('', {
                  unEditable: !params.isEditable,
                }),
            },
            {
              field: 'subcontractor',
              headerName: t('resource.subcontractor.capitalized'),
              width: 200,
              editable: true,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return '';
                }
                return (params.value as { id: number; name: string }).name;
              },
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  subcontractor?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: subcontractor,
                  });
                };
                return (
                  <SelectSubcontractorCell
                    onCellChange={updateCellValue}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'capacity',
              headerName: t('attributes.capacity'),
              width: 200,
              type: 'number',
              editable: true,
              valueFormatter({ value }) {
                if (value == null) {
                  return '';
                }

                return value;
              },
            },
          ]}
          pageSize={10}
          rowHeight={30}
          autoHeight={true}
          disableSelectionOnClick
        />
      </Grid>
    </Grid>
  );
}
