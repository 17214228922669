import { RouteFtlInput } from '../../generated/graphql';

export function createFTL(): Partial<RouteFtlInput> {
  return {
    cdc: '',
    cmr: '',
    customerId: undefined,
    customerNumber: '',
    customerReference: '',
    invoiceNote: '',
    price: 0,
    currency: 'dkk',
  };
}
