import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { FUEL } from '../lib/constants';
import { useFormContext, Controller, Path } from 'react-hook-form';
import { SporadicRouteFormInput } from './RouteFormRHF';

interface SelectFuelProps {
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
  classes?: ClassNameMap<'field'>;
}

export function SelectFuelRHF(props: SelectFuelProps) {
  const { readOnly = false, controllerName, classes = { field: '' } } = props;
  const { t } = useTranslation();
  const { control } = useFormContext<SporadicRouteFormInput>();

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <FormControl variant="outlined" size="small">
          <InputLabel shrink id="fuel">
            {t('attributes.fuel')}
          </InputLabel>
          <Select
            id="fuel"
            {...field}
            className={classes?.field}
            labelId="fuel"
            label={t('attributes.fuel')}
            readOnly={readOnly}
            style={{
              margin: 0,
              minWidth: 140,
            }}
            inputProps={{ readOnly }}
            IconComponent={(props) => (
              <ArrowDropDown
                {...props}
                style={{
                  display: readOnly ? 'none' : 'inline-block',
                }}
              />
            )}
          >
            {Object.entries(FUEL).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}
