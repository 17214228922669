import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@material-ui/core';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { buttonStyle } from './ViewCard';

interface DialogModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  contentText: string;
  buttonText?: string;
  doAction: () => void;
  selectAction: React.Dispatch<any>;
  locations: {
    location: { name: string };
    locationId: number;
    id: number;
  }[];
  debitLocation: any;
}

export function DialogModalDebit(props: DialogModalProps) {
  const {
    open,
    setOpen,
    title,
    contentText,
    doAction,
    selectAction,
    buttonText = 'Delete',
    locations,
    debitLocation,
  } = props;
  const classes = buttonStyle();
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  const handleSelectChange = (event: SelectChangeEvent) => {
    const selectedLocation = locations.find(
      (location) => location.id === parseInt(event.target.value),
    );
    if (selectedLocation != null) {
      selectAction(selectedLocation);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
        {locations?.length && (
          <Select
            labelId="location"
            id="demo-simple-select"
            value={debitLocation.id.toString()}
            label="Location"
            onChange={handleSelectChange}
          >
            {locations.map((location) => (
              <MenuItem key={location.locationId} value={location.id}>
                {location.location.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            doAction();
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          {buttonText}
        </Button>
        <Button
          classes={{
            root: classes.button,
          }}
          onClick={handleClose}
          color="primary"
        >
          {t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
