import { Box, Button, Grid, Tooltip, Typography } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import PlusIcon from '@mui/icons-material/Add';
import RouteWiseIcon from '@mui/icons-material/AltRoute';
import EditIcon from '@mui/icons-material/Edit';
import CustomerWiseIcon from '@mui/icons-material/Person';
import {
  GridCellParams,
  GridColDef,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import { format, parseISO, sub, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DeleteActionCell } from '../components/datagrid/DeleteActionCell';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { DeviationModal } from '../components/forms/DeviationModal';
import { EditCustomerDeviationForm } from '../components/forms/EditCustomerDeviationForm';
import { EditDeviationForm } from '../components/forms/EditDeviationForm';
import { HelmetComponent } from '../components/HelmetComponent';
import { SearchInput } from '../components/SearchInput';
import { SelectDateRangeForm } from '../components/SelectDateRangeForm';
import { ViewDeviationModal } from '../components/ViewDeviation';
import {
  Deviation,
  DeviationFault,
  EditDeviationInput,
  EditLocationDeviationInput,
  EditLocationDeviationListItemsInput,
  GetDeviationsDocument,
  LocationDeviationInput,
  LocationEntity,
  Maybe,
  RouteType,
  useCreateLocationDeviationMutation,
  useDeleteDeviationMutation,
  useEditDeviationMutation,
  useEditLocationDeviationMutation,
  useGetDeviationsLazyQuery,
  useGetLocationDeviationsLazyQuery,
} from '../generated/graphql';
import { COUNTRIES_MAP, TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { printDateTimeLocale } from '../lib/format';
import { renderLocation } from '../lib/location';
import { LoadingAndError } from '../utils/LoadingAndError';
import { RemainingGoodsObj } from './RemainingGoodsInTerminal';

export function Deviations() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<Maybe<string> | undefined>();
  const [refreshGrid, setRefreshGrid] = useState(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'deviationApplicableDate',
      sort: 'desc',
    },
  ]);

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      if (sortModel?.length) {
        const previousSortField = sortModel[0]?.field;
        const previousSortDirection = sortModel[0]?.sort;
        if (newSortModel?.length && newSortModel[0]) {
          if (
            previousSortField !== newSortModel[0]?.field ||
            previousSortDirection !== newSortModel[0]?.sort
          ) {
            setSortModel(newSortModel);
          }
        }
      }
    },
    [sortModel],
  );

  const [startDate, setStartDate] = useState(
    format(sub(new Date(), { days: 1 }), DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState(format(new Date(), DATE_FORMAT));
  const [isCustomerWise, setCustomerWise] = useState(false);
  const [viewDeviation, setViewDeviation] = useState<Partial<Deviation> | null>(
    null,
  );
  const handleViewDeviationClose = useCallback(() => {
    setViewDeviation(null);
  }, []);
  const [getDeviations, { data, error, loading }] = useGetDeviationsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [
    getLocationDeviationsLazy,
    { data: custData, error: custError, loading: custLoading },
  ] = useGetLocationDeviationsLazyQuery({ fetchPolicy: 'no-cache' });

  const [deleteDeviation] = useDeleteDeviationMutation({
    refetchQueries: [
      {
        query: GetDeviationsDocument,
        variables: { startDate, endDate },
      },
    ],
  });

  const [newCustomerDeviationOpen, setNewCustomerDeviationOpen] =
    useState(false);

  const [editCustomerDeviationOpen, setEditCustomerDeviationOpen] =
    useState(false);
  const [editCustomerDeviation, setEditCustomerDeviation] =
    useState<EditLocationDeviationInput>({
      id: -1,
      deviationApplicableDate: undefined,
      consequence: false,
      fault: DeviationFault.BringCustoms,
      faultLocationId: 0,
      toLocationId: 0,
      note: '',
      customerDeviationListItemsInput: [],
    });
  const [editDeviationValues, setEditDeviationValues] = useState<
    Partial<EditDeviationInput>
  >({
    actualArrivalTime: undefined,
    consequence: undefined,
    emailSent: undefined,
    fault: undefined,
    faultLocationId: undefined,
    id: undefined,
    note: undefined,
    routeId: undefined,
  });
  const [editDeviationFormOpen, setEditDeviationFormOpen] = useState(false);
  const [editDeviationMutation] = useEditDeviationMutation();

  const onEditSubmit = useCallback(
    async (values: EditDeviationInput) => {
      if (values && values.id && values.routeId) {
        setEditDeviationFormOpen(false);
        await editDeviationMutation({
          variables: {
            input: values,
          },
        });

        setRefreshGrid(!refreshGrid);
      }
    },
    [editDeviationFormOpen, viewDeviation],
  );

  const routeViewTitle = t('actions.viewItemSwitched', {
    item: t('resource.route.capitalized'),
  });

  const customerViewTitle = t('actions.viewItemSwitched', {
    item: t('resource.customer.capitalized'),
  });

  const onDeleteAction = useCallback((id: number, customerId?: number) => {
    setEditCustomerDeviationOpen(false);
    deleteDeviation({
      variables: { id, customerId },
    }).then(() => {
      setRefreshGrid(!refreshGrid);
    });
  }, []);

  useEffect(() => {
    if (isCustomerWise) {
      getLocationDeviationsLazy({
        variables: { startDate, endDate, searchTerm: searchQuery },
      });
    } else {
      getDeviations({
        variables: { startDate, endDate, searchTerm: searchQuery },
      });
    }
  }, [isCustomerWise, searchQuery, endDate, startDate, refreshGrid]);

  const onQuerySelected = useCallback(
    (values) => {
      setSearchQuery(values.searchQuery);
    },
    [searchQuery],
  );

  const handleDateRangeChanged = useCallback(
    (values) => {
      setStartDate(values.startDate);
      setEndDate(values.endDate);
    },
    [startDate, endDate],
  );

  function newCustomerDeviationValues(): RemainingGoodsObj {
    return {
      obj: [
        {
          deviationApplicableDate: undefined,
          consequence: false,
          fault: DeviationFault.BringCustoms,
          faultLocationId: 0,
          toLocationId: 0,
          note: '',
          customerDeviationListItemsInput: [
            {
              locationId: 0,
              locationItemNote: '',
              packages: null,
              pallets: null,
              palletSpace: null,
              weight: null,
              reason: undefined,
              otherReason: '',
              newCustomer: '',
              isNewCustomer: false,
              isDangerous: false,
            },
          ],
        },
      ],
    };
  }

  const [createLocationDeviationMutation] =
    useCreateLocationDeviationMutation();

  const [editLocationDeviationMutation] = useEditLocationDeviationMutation();
  const onCreateCustomerDeviationSubmit = (
    locationDeviationInput: LocationDeviationInput[],
  ) => {
    //deviation call
    createLocationDeviationMutation({
      variables: { input: locationDeviationInput },
    }).then(() => {
      setRefreshGrid(!refreshGrid);
    });
    setNewCustomerDeviationOpen(false);
  };

  const onEditCustomerDeviationSubmit = (
    editlocationDeviationInput: EditLocationDeviationInput,
  ) => {
    const deviationToLocationArr: EditLocationDeviationListItemsInput[] = [];
    editlocationDeviationInput.customerDeviationListItemsInput.forEach(
      (customerDeviationListItem) => {
        deviationToLocationArr.push({
          deviationId: customerDeviationListItem.deviationId,
          id: customerDeviationListItem.id ?? -1,
          locationId: customerDeviationListItem.locationId,
          locationItemNote: customerDeviationListItem.locationItemNote,
          pallets:
            typeof customerDeviationListItem.pallets === 'number'
              ? customerDeviationListItem.pallets
              : null,
          packages: customerDeviationListItem.packages,
          palletSpace: customerDeviationListItem.palletSpace,
          weight: customerDeviationListItem.weight,
          reason: customerDeviationListItem.reason,
          otherReason: customerDeviationListItem.otherReason,
          isDangerous: customerDeviationListItem.isDangerous,
        });
      },
    );
    editLocationDeviationMutation({
      variables: {
        input: {
          ...editlocationDeviationInput,
          customerDeviationListItemsInput: deviationToLocationArr,
        },
      },
    }).then(() => {
      setRefreshGrid(!refreshGrid);
    });

    setEditCustomerDeviationOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'view',
      headerName: 'View',
      minWidth: 100,
      type: 'action',
      renderCell: (params: GridCellParams) => {
        const linkPath = `/traffic/${
          params.row.route.type === RouteType.Sporadic
            ? `sporadic-routes/`
            : 'dispatched-routes/'
        }${params.row.route.id}?deviation=true`;
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <Link
                to={linkPath}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PageviewIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'transportationDate',
      type: 'date',
      headerName: 'Transportation Date',
      valueGetter({ row }) {
        if (row.route.transportationDate) return row.route.transportationDate;
        else return '';
      },
      width: 160,
    },
    {
      field: 'routeId',
      headerName: 'Route ID',
      valueGetter({ row }) {
        return row.route?.routeId;
      },
    },
    {
      field: 'departureCity',
      headerName: 'Departure City',
      valueGetter({ row }) {
        if (row.route.legs) {
          if (row.route.legs.length >= 1) {
            return row.route.legs[0].location.city;
          } else {
            return '';
          }
        } else {
          return '';
        }
      },
      width: 150,
    },
    {
      field: 'departureCountry',
      headerName: 'Departure Country',
      valueGetter({ row }) {
        if (row.route.legs) {
          if (row.route.legs.length >= 1) {
            const country =
              COUNTRIES_MAP[
                row.route.legs[0].location.country as keyof typeof COUNTRIES_MAP
              ];
            return country ? country.toUpperCase() : '';
          } else {
            return '';
          }
        } else {
          return '';
        }
      },
      width: 150,
    },
    {
      field: 'arrivalCity',
      headerName: 'Arrival City',
      valueGetter({ row }) {
        if (row.route.legs) {
          if (row.route.legs.length >= 1) {
            return row.route.legs[row.route.legs.length - 1].location.city;
          } else {
            return '';
          }
        } else {
          return '';
        }
      },
      width: 150,
      hide: true,
    },
    {
      field: 'arrivalCountry',
      headerName: 'Arrival Country',
      valueGetter({ row }) {
        if (row.route.legs) {
          if (row.route.legs.length >= 1) {
            const country =
              COUNTRIES_MAP[
                row.route.legs[row.route.legs.length - 1].location
                  .country as keyof typeof COUNTRIES_MAP
              ];
            return country ? country.toUpperCase() : '';
          } else {
            return '';
          }
        } else {
          return '';
        }
      },
      width: 150,
    },

    {
      field: 'routeSubcontractorName',
      headerName: 'Subcontractor',
      width: 160,
      valueGetter({ row }) {
        if (row.route.subcontractor) return row.route.subcontractor.name;
        else return '';
      },
    },
    {
      field: 'routeCapacity',
      headerName: 'Capacity',
      valueGetter({ row }) {
        if (row.route.capacity) return row.route.capacity;
        else return '';
      },
    },
    {
      field: 'routeLicensePlate',
      headerName: 'License plate',
      valueGetter({ row }) {
        if (row.route.licensePlate) return row.route.licensePlate;
        else return '';
      },
    },
    {
      field: 'cause',
      headerName: 'Cause',
    },
    {
      field: 'fault',
      headerName: 'Fault',
    },
    {
      field: 'faultLocation',
      headerName: 'Fault location',
      valueGetter({ row }) {
        if (row.faultLocation) {
          const location = row.faultLocation as LocationEntity | null;
          if (location) {
            return renderLocation(location);
          } else {
            return '';
          }
        } else return '';
      },
    },
    {
      field: 'arrivalTime',
      headerName: 'Original Arrival Time',
      renderCell(params) {
        const { row } = params;
        const { route } = row;
        if (route.legs && typeof route.legs[0].arrivalTime === 'string') {
          if (route.legs[0].arrivalTime.match(/\d{2}:\d{2}:\d{2}/)) {
            return format(
              parse(route.legs[0].arrivalTime, 'HH:mm:ss', new Date()),
              'HH:mm',
            );
          } else {
            return route.legs[0].arrivalTime;
          }
        } else {
          return '';
        }
      },
    },
    {
      field: 'actualArrivalTime',
      headerName: 'Actual Arrival Time',
      valueFormatter(params) {
        const { value } = params;
        if (typeof value === 'string') {
          return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
        }
        return '';
      },
    },
    {
      field: 'note',
      headerName: 'Internal note',
      valueFormatter({ value }) {
        if (!value) {
          return '';
        }
        return (value as string).replaceAll('\n', ' ');
      },
    },
    {
      field: 'consequence',
      headerName: 'Consequence',
      type: 'boolean',
    },

    {
      field: 'createdBy',
      headerName: 'Created By',
    },
    {
      field: 'delete',
      headerName: 'Delete',
      type: 'action',
      renderCell({ row }) {
        return (
          <DeleteActionCell
            itemTitle={
              row.faultLocation && row.faultLocation.card.name != 'BLANK'
                ? `Deviation at ${row.faultLocation.card.name}(${row.actualArrivalTime})`
                : `Deviation at ${row.actualArrivalTime}`
            }
            deleteAction={() => {
              onDeleteAction(row.id);
            }}
          />
        );
      },
    },
  ];

  const locationDeviationColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'view',
      headerName: 'View',
      minWidth: 100,
      type: 'action',
      renderCell: (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        params: GridCellParams,
      ) => {
        const linkPath = `#`;
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <Link
                to={linkPath}
                onClick={() => {
                  setViewDeviation({
                    id: params.row.deviationId,
                    routeId: params.row.routeId,
                    emailSent: params.row.emailSentTimestamp
                      ? printDateTimeLocale(
                          params.row.emailSentTimestamp,
                          'yyyy-MM-dd HH:mm',
                        )
                      : undefined,
                    cause:
                      params.row.type && params.row.type === 'Route'
                        ? params.row.cause
                        : params.row.reason,
                    faultLocation: params.row.faultLocation,
                    faultLocationId: params.row.faultLocationId,
                    toLocation: params.row.toLocation,
                    toLocationId: params.row.toLocationId,
                    fault: params.row.fault,
                    consequence: params.row.consequence,
                    actualArrivalTime: params.row.actualArrivalTime,
                  });
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PageviewIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'deviationApplicableDate',
      headerName: 'Date',
      type: 'date',
    },
    {
      field: 'routeNameOrFaultLocation',
      headerName: 'Route/Terminal',
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      valueGetter({ row }) {
        if (row.customerName && `${row.customerName}` !== 'BLANK') {
          return row.customerName;
        } else {
          return row.newCustomer;
        }
      },
    },
    {
      field: 'packages',
      headerName: 'Packages',
    },
    {
      field: 'pallets',
      headerName: 'Pallets',
      valueGetter({ row }) {
        if (row.pallets && row.pallets !== 0) return row.pallets;
        else return '';
      },
    },
    {
      field: 'palletSpace',
      headerName: 'palletSpace',
    },
    {
      field: 'weight',
      headerName: 'weight',
    },
    {
      field: 'reason',
      headerName: 'Reason/Cause',
      valueGetter({ row }) {
        if (row.type && row.type === 'Route') return row.cause;
        else return t(`enums.remainingGoodsReasonClass.${row.reason}`);
      },
    },
    {
      field: 'otherReason',
      headerName: 'Other reason',
    },
    {
      field: 'fault',
      headerName: 'Fault',
    },
    {
      field: 'faultLocation',
      headerName: 'Fault Location',
    },
    {
      field: 'toLocation',
      headerName: 'To Location',
    },
    {
      field: 'fromToToCountry',
      headerName: 'From-To Country',
    },
    {
      field: 'toCity',
      headerName: 'To City',
    },
    {
      field: 'consequence',
      headerName: 'Consequence',
      type: 'boolean',
    },
    {
      field: 'isDangerous',
      headerName: 'Dangerous Item',
      type: 'boolean',
    },
    {
      field: 'locationItemNote',
      headerName: 'Internal Note',
      valueFormatter({ value }) {
        if (!value) {
          return '';
        }
        return (value as string).replaceAll('\n', ' ');
      },
    },
    {
      field: 'actualArrivalTime',
      headerName: 'Actual Arrival Time',
      valueFormatter(params) {
        const { value } = params;
        if (typeof value === 'string' && value?.length > 3) {
          return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
        }
        return '';
      },
    },
    {
      field: 'type',
      headerName: 'Type',
    },
    {
      field: 'emailSent',
      headerName: 'Email Sent',
      type: 'boolean',
    },
    {
      field: 'emailSentTimestamp',
      headerName: 'Email Sent Time',
      valueFormatter({ value }) {
        if (typeof value === 'string') {
          return format(parseISO(value), 'yyyy-MM-dd HH:mm');
        }
        return '';
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Last Updated By',
      valueGetter({ row }) {
        if (row.lastUpdatedBy && row.lastUpdatedBy !== 'Unknown')
          return `${row.lastUpdatedBy}`;
        else return '';
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      type: 'action',
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.edit')}`}>
              <Link
                to={`#`}
                onClick={() => {
                  if (params.row.routeId) {
                    setEditDeviationFormOpen(true);
                    setEditDeviationValues({
                      id: params.row.deviationId,
                      routeId: params.row.routeId,
                      faultLocationId: params.row.faultLocationId
                        ? params.row.faultLocationId
                        : 0,
                      cause: params.row.cause,
                      fault: params.row.fault,
                      consequence: params.row.consequence,
                      note: params.row.locationItemNote,
                      actualArrivalTime: params.row.actualArrivalTime,
                    });
                  } else {
                    setEditCustomerDeviationOpen(true);
                    setEditCustomerDeviation({
                      id: params.row.deviationId,
                      routeId: params.row.routeId,
                      faultLocationId: params.row.faultLocationId,
                      toLocationId: params.row.toLocationId,
                      fault: params.row.fault,
                      consequence: params.row.consequence,
                      deviationApplicableDate:
                        params.row.deviationApplicableDate,
                      note: params.row.note,
                      customerDeviationListItemsInput: [],
                    });
                  }
                }}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <EditIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return isCustomerWise ? (
    <LoadingAndError loading={custLoading} error={custError} data={custData}>
      {({ loadedData }) => (
        <Grid container>
          <HelmetComponent title={t('pages.deviations.title')} />
          <Grid item container>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <Typography variant="h2">
                {t('pages.customerDeviations.title')}
              </Typography>
              <SearchInput
                searchQuery={searchQuery}
                onQuerySelected={onQuerySelected}
              />
            </Box>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <SelectDateRangeForm
                startDate={startDate}
                endDate={endDate}
                onDateSelected={handleDateRangeChanged}
                buttonText={t('actions.apply')}
              />

              <Button
                variant="contained"
                color="primary"
                startIcon={<PlusIcon />}
                onClick={() => {
                  setNewCustomerDeviationOpen(true);
                }}
              >
                {t('button.create', {
                  item: `${t('resource.customer.lowercased')} ${t(
                    'resource.deviation.lowercased',
                  )}`,
                })}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setCustomerWise(!isCustomerWise)}
                startIcon={<RouteWiseIcon />}
              >
                {routeViewTitle}
              </Button>
            </Box>
          </Grid>
          {editCustomerDeviationOpen && (
            <EditCustomerDeviationForm
              open={editCustomerDeviationOpen}
              initialValues={{
                ...editCustomerDeviation,
                customerDeviationListItemsInput: [],
              }}
              onSubmit={(d: EditLocationDeviationInput) =>
                onEditCustomerDeviationSubmit({
                  ...d,
                })
              }
              onDeleteAction={onDeleteAction}
              onCancel={() => setEditCustomerDeviationOpen(false)}
            />
          )}
          {editDeviationFormOpen && (
            <Box
              sx={{
                width: '86%',
                margin: 16,
              }}
            >
              <EditDeviationForm
                editValues={{ ...editDeviationValues }}
                onSubmit={onEditSubmit}
                onCancel={() => setEditDeviationFormOpen(false)}
              />
            </Box>
          )}
          {newCustomerDeviationOpen && (
            <DeviationModal
              open={newCustomerDeviationOpen}
              initialValue={{ ...newCustomerDeviationValues() }}
              handleSubmit={(d: LocationDeviationInput[]) =>
                onCreateCustomerDeviationSubmit(d)
              }
              onCancel={() => setNewCustomerDeviationOpen(false)}
            />
          )}

          <div style={{ height: '100%', width: '100%' }}>
            <UserConfiguredDataGridPro
              tableName={TABLE_NAMES.CustomerDeviations}
              rows={loadedData.getLocationDeviations}
              getRowId={(r) => r.id}
              columns={locationDeviationColumns}
              loading={custLoading}
              pageSize={10}
              rowHeight={50}
              autoHeight={true}
              disableSelectionOnClick
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
            />
          </div>
          <ViewDeviationModal
            item={viewDeviation}
            handleClose={handleViewDeviationClose}
            type={viewDeviation && viewDeviation.routeId ? 'Route' : 'Terminal'}
          />
        </Grid>
      )}
    </LoadingAndError>
  ) : (
    <LoadingAndError loading={loading} error={error} data={data}>
      {({ loadedData }) => (
        <Grid container>
          <HelmetComponent title={t('pages.deviations.title')} />
          <Grid item container>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <Typography variant="h2">
                {t('pages.deviations.title')}
              </Typography>
              <SearchInput
                searchQuery={searchQuery}
                onQuerySelected={onQuerySelected}
              />
            </Box>
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={2}
            >
              <SelectDateRangeForm
                startDate={startDate}
                endDate={endDate}
                onDateSelected={handleDateRangeChanged}
                buttonText={t('actions.apply')}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => setCustomerWise(!isCustomerWise)}
                startIcon={<CustomerWiseIcon />}
              >
                {customerViewTitle}
              </Button>
            </Box>
          </Grid>

          <div style={{ height: '100%', width: '100%' }}>
            <UserConfiguredDataGridPro
              tableName={TABLE_NAMES.RouteDeviations}
              rows={loadedData?.deviations}
              columns={columns}
              loading={loading}
              pageSize={10}
              rowHeight={50}
              autoHeight={true}
              disableSelectionOnClick
            />
          </div>
        </Grid>
      )}
    </LoadingAndError>
  );
}
