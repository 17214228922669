import { makeStyles, TextFieldProps, TextField } from '@material-ui/core';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAddGridValueMutation } from '../../generated/graphql';

interface CommonTextFieldProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  allowOnlyNumbers: boolean;
  isTotalCell: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  gridMasterId: number;
  rowPosition: number;
  columnPosition: number;
  valueAddedDate: string;
  doRefetchGrid?: () => void;
}

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  smallFontSize: {
    '& input': {
      fontSize: 12,
    },
    '&.MuiOutlinedInput-multiline': {
      fontSize: 12,
    },
  },
  smallPadding: {
    '& input': {
      padding: 4,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 4,
    },
  },
  highlightCell: {
    fontWeight: 'bold',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    backgroundColor: '#e7e7e7',
    cursor: 'pointer',
  },
}));

export function TextCellForGrid(props: CommonTextFieldProps & TextFieldProps) {
  const { t } = useTranslation();
  const {
    name,
    readOnly = false,
    fullWidth = false,
    helperText = undefined,
    InputProps = {},
    size = 'small',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    gridMasterId,
    columnPosition,
    rowPosition,
    valueAddedDate,
    allowOnlyNumbers,
    isTotalCell,
    doRefetchGrid = () => {
      return;
    },
    ...rest
  } = props;
  const classes = useStyles();
  const exceptThisSymbols = ['e', 'E', '+', '-', '.', ' '];
  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const [addGridValueApi] = useAddGridValueMutation();

  return (
    <>
      <Field name={name}>
        {({ field, meta }: FieldProps) => (
          <TextField
            {...props}
            {...field}
            value={field.value === '0' ? '' : field.value}
            id={name}
            size={size}
            variant="outlined"
            inputProps={{
              maxLength: 10,
            }}
            placeholder={noLabel ? undefined : t(`attributes.${label}`)}
            label={noLabel ? undefined : t(`attributes.${label}`)}
            error={meta.touched && Boolean(meta.error)}
            helperText={helperText || (meta.touched && meta.error)}
            fullWidth={fullWidth}
            InputProps={{
              className: clsx([
                classes.input,
                {
                  [classes.smallFontSize]: smallFontSize,
                  [classes.smallPadding]: smallPadding,
                  [classes.highlightCell]: isTotalCell,
                },
              ]),
              readOnly,
              ...InputProps,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            onKeyDown={(e) => {
              allowOnlyNumbers &&
                exceptThisSymbols.includes(e.key) &&
                e.preventDefault();
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            onBlur={(e) => {
              {
                !readOnly
                  ? addGridValueApi({
                      variables: {
                        gridValue: {
                          gridMasterId: gridMasterId,
                          valueAddedDate: valueAddedDate,
                          columnPosition: columnPosition,
                          rowPosition: rowPosition,
                          value: allowOnlyNumbers
                            ? e.target.value == null || e.target.value == ''
                              ? '0'
                              : e.target.value
                            : e.target.value ?? '',
                        },
                      },
                    }).then((res) => {
                      if (res && allowOnlyNumbers) doRefetchGrid();
                    })
                  : null;
              }
            }}
            {...rest}
          />
        )}
      </Field>
    </>
  );
}
