import { gql } from '@apollo/client';
import {
  Box,
  Button,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Deviation,
  DeviationCause,
  DeviationFault,
  DeviationInput,
  EditDeviationInput,
  useCreateDeviationMutation,
  useEditDeviationMutation,
} from '../generated/graphql';
import { DeviationForm } from './forms/DeviationForm';
import { EditDeviationForm } from './forms/EditDeviationForm';
import { ListDeviations } from './ListDeviations';
import { ViewDeviationModal } from './ViewDeviation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 1),
  },
  paperRoot: {
    padding: theme.spacing(2, 1),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface DeviationsProps {
  routeId: number;
}

function newDeviationValues(routeId: number): Partial<DeviationInput> {
  return {
    routeId,
    actualArrivalTime: undefined,
    cause: DeviationCause.LeftoverCargo,
    consequence: false,
    fault: DeviationFault.BringCustoms,
    faultLocationId: undefined,
    note: '',
  };
}

function editDeviationValues(
  deviation: Partial<EditDeviationInput> | null,
): Partial<EditDeviationInput> {
  return {
    actualArrivalTime: deviation?.actualArrivalTime,
    cause: deviation?.cause,
    consequence: deviation?.consequence,
    emailSent: deviation?.emailSent,
    fault: deviation?.fault,
    faultLocationId: deviation
      ? deviation?.faultLocation
        ? deviation?.faultLocation?.id
        : undefined
      : undefined,
    id: deviation?.id,
    note: deviation?.note,
    routeId: deviation?.routeId,
  };
}
export function RouteDeviations(props: DeviationsProps) {
  const { routeId } = props;
  const [newDeviationFormOpen, setNewDeviationFormOpen] = useState(false);
  const [editDeviationFormOpen, setEditDeviationFormOpen] = useState(false);

  const [viewDeviation, setViewDeviation] = useState<Partial<Deviation> | null>(
    null,
  );
  const [editDeviation, setEditDeviation] =
    useState<Partial<EditDeviationInput> | null>(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const [createDeviation] = useCreateDeviationMutation();
  const [editDeviationMutation] = useEditDeviationMutation();

  const onEditSubmit = async (values: EditDeviationInput) => {
    const { data: newDeviationData } = await editDeviationMutation({
      variables: {
        input: values,
      },
    });
    setEditDeviationFormOpen(false);
    if (
      newDeviationData &&
      newDeviationData?.editDeviation &&
      newDeviationData?.editDeviation.consequence
    ) {
      const viewDev: any = {
        ...newDeviationData?.editDeviation,
        faultLocation: newDeviationData?.editDeviation.faultLocation,
      };
      setViewDeviation(viewDev);
    }
  };
  const onCreateSubmit = async (values: DeviationInput) => {
    const { data: newDeviationData } = await createDeviation({
      variables: {
        input: values,
      },
      update(cache, { data: createDeviation }) {
        cache.modify({
          fields: {
            [`deviationsForRoute({"routeId":${routeId}})`]: () => {
              const newDeviationRef = cache.writeFragment({
                data: createDeviation?.createDeviation,
                fragment: gql`
                  fragment NewDeviation on Deviation {
                    id
                    cause
                    fault
                    routeId
                    faultLocation {
                      id
                      name
                      address
                      postalCode
                      city
                      country
                      card {
                        name
                      }
                    }
                    note
                    consequence
                    actualArrivalTime
                    createdBy
                  }
                `,
              });

              return [newDeviationRef];
            },
          },
        });
      },
    });
    if (
      newDeviationData &&
      newDeviationData?.createDeviation &&
      newDeviationData?.createDeviation.consequence
    ) {
      const viewDev: any = {
        ...newDeviationData?.createDeviation,
        faultLocation: newDeviationData?.createDeviation.faultLocation,
      };
      setViewDeviation(viewDev);
    }
    setNewDeviationFormOpen(false);
  };
  const handleViewDeviationClose = useCallback(() => {
    setViewDeviation(null);
  }, []);

  return (
    <Box className={classes.root}>
      <Paper className={classes.paperRoot}>
        <Box className={classes.header}>
          <Typography variant="h2">
            {t('resource.deviation.capitalized')}
          </Typography>
          {!newDeviationFormOpen && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setNewDeviationFormOpen(true)}
            >
              {t('actions.add', { item: t('resource.deviation.lowercased') })}
            </Button>
          )}
        </Box>

        {newDeviationFormOpen && (
          <DeviationForm
            initialValues={{ ...newDeviationValues(routeId) }}
            onSubmit={onCreateSubmit}
            onCancel={() => setNewDeviationFormOpen(false)}
          />
        )}
        {editDeviationFormOpen && (
          <EditDeviationForm
            editValues={{ ...editDeviationValues(editDeviation) }}
            onSubmit={onEditSubmit}
            onCancel={() => setEditDeviationFormOpen(false)}
          />
        )}
        <ListDeviations
          routeId={routeId}
          setEditDeviationFormOpen={(deviation) => {
            setEditDeviation(deviation);
            setEditDeviationFormOpen(true);
          }}
          onDeviationOpen={(deviation) => {
            setViewDeviation(deviation);
          }}
        />
        <ViewDeviationModal
          item={viewDeviation}
          handleClose={handleViewDeviationClose}
        />
      </Paper>
    </Box>
  );
}
