import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Modal } from '@mui/material';
import clsx from 'clsx';
import { FieldArray, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DeviationFault,
  EditLocationDeviationInput,
  GetDeviationToLocationForDeviationIdQuery,
  LocationDeviationListItemsInput,
  Reason,
  useGetDeviationToLocationForDeviationIdLazyQuery,
} from '../../generated/graphql';
import { usePrevious } from '../../utils/UsePrevious';
import { CheckboxField } from '../form/CheckboxField';
import { CommonTextField } from '../form/CommonTextField';
import { SelectEnumField } from '../form/SelectEnumField';
import { SelectLocation } from '../form/SelectLocation';
import { SupressEnterForm } from '../form/SupressEnterForm';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import { CheckboxIconField } from '../form/CheckboxIconField';
import DangerIcon from '@mui/icons-material/Report';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '68vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    '& > *': {
      width: '150px',
    },
  },
  halfRow: {
    display: 'flex',
    width: '100%',
    marginBottom: 26,
  },
  distribute: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  maximizeNote: {
    justifyContent: 'flex-start',
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      flex: 2,
    },
  },
  root: {
    margin: 0,
    padding: 0,
    '& > .MuiGrid-item': {
      padding: 0,
    },
  },
  noPadding: {
    padding: '0 !important',
  },
  noPaddingListRow: {
    paddingLeft: '0 !important',
    width: '100%',
    paddingBottom: 4,
  },
  deleteButton: {
    padding: '0 !important',
  },
  listItem: {
    '&.MuiListItem': {
      padding: 0,
    },
    '&.MuiListItem-gutters': {
      padding: 0,
    },
    '.MuiListItem-root': {
      padding: 0,
    },
  },
  numberFields: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
  selecLocationField: {
    width: '99%',
    fontSize: '10px',
    margin: 0,
    padding: 0,
  },
  field: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
}));

interface CustomerDeviationFormProps {
  initialValues: EditLocationDeviationInput;
  onSubmit: (values: EditLocationDeviationInput) => void;
  onDeleteAction: (deviationId: number) => void;
  onCancel: () => void;
  open: boolean;
}

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    customerDeviationListItemsInput: yup
      .array(
        yup.object().shape({
          pallets: yup
            .number()
            .integer(
              t('validation.noDecimals', {
                name: t('attributes.pallets'),
                len: '0',
              }),
            )
            .nullable()
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.pallets'),
                len: '0',
              }),
            ),
          palletSpace: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.palletSpace'),
                len: '0',
              }),
            )
            .typeError('Required')
            .required('Required'),
          packages: yup
            .number()
            .nullable(true)
            .integer(
              t('validation.noDecimals', { name: t('attributes.packages') }),
            )
            .positive(
              t('validation.fieldPositive', {
                name: t('attributes.packages'),
              }),
            ),
          weight: yup
            .number()
            .nullable(true)
            .positive(
              t('validation.fieldPositive', { name: t('attributes.weight') }),
            ),
          locationItemNote: yup.string().optional(),
          locationId: yup.number().when('isNewCustomer', {
            is: false,
            then: yup
              .number()
              .min(
                1,
                t('validation.isRequired', {
                  name: t('attributes.location'),
                }),
              )
              .required(
                t('validation.isRequired', {
                  name: t('attributes.location'),
                }),
              ),
          }),
          newCustomer: yup.string().when('isNewCustomer', {
            is: true,
            then: yup.string().required(
              t('validation.isRequired', {
                name: t('attributes.location'),
              }),
            ),
          }),
          reason: yup.string().required().oneOf(Object.keys(Reason)),
          otherReason: yup.string().when('reason', {
            is: 'Other',
            then: yup.string().required(
              t('validation.isRequired', {
                name: t('attributes.customer'),
              }),
            ),
          }),
        }),
      )

      .min(1, 'Atleast One Item is required')
      .required('Atleast One Item is required'),
    consequence: yup.boolean(),
    fault: yup.string().oneOf(Object.keys(DeviationFault)).required('Required'),
    faultLocationId: yup
      .number()
      .min(
        1,
        t('validation.isRequired', {
          name: t('attributes.location'),
        }),
      )
      .required('Required'),
    toLocationId: yup
      .number()
      .min(
        1,
        t('validation.isRequired', {
          name: t('attributes.location'),
        }),
      )
      .required('Required'),
    deviationApplicableDate: yup.date().required('Applicable Date Required'),
  });

export function EditCustomerDeviationForm(props: CustomerDeviationFormProps) {
  const { initialValues, onSubmit, onDeleteAction, onCancel, open } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  if (!initialValues || !initialValues.id) {
    return <div>Error: deviation values are null</div>;
  }
  const [
    getDeviationToLocationForDeviationIdLazyQuery,
    { data, loading, error },
  ] = useGetDeviationToLocationForDeviationIdLazyQuery({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    getDeviationToLocationForDeviationIdLazyQuery({
      variables: {
        deviationId: initialValues.id,
      },
    });
  }, []);

  const deviationToLocationForDeviationId:
    | GetDeviationToLocationForDeviationIdQuery['deviationToLocationForDeviationId']
    | undefined = data?.deviationToLocationForDeviationId;
  const previousDeviationToLocationForDeviationId:
    | GetDeviationToLocationForDeviationIdQuery['deviationToLocationForDeviationId']
    | undefined = usePrevious(deviationToLocationForDeviationId);
  const [rerender, setReRender] = useState(false);
  useEffect(() => {
    if (
      deviationToLocationForDeviationId &&
      deviationToLocationForDeviationId.length > 0 &&
      !_.isEqual(
        deviationToLocationForDeviationId,
        previousDeviationToLocationForDeviationId,
      )
    ) {
      deviationToLocationForDeviationId.forEach((customer) => {
        initialValues.customerDeviationListItemsInput.push({
          id: customer.id,
          deviationId: customer.deviationId,
          locationId: customer.locationId,
          locationItemNote: customer.locationItemNote,
          newCustomer: customer.newCustomer ?? '',
          isNewCustomer: customer.newCustomer ? true : false,
          otherReason: customer.otherReason,
          packages: customer.packages,
          pallets: customer.pallets,
          palletSpace: customer.palletSpace,
          reason: customer.reason,
          weight: customer.weight,
          isDangerous: customer.isDangerous,
        });
      });
      setReRender(!rerender);
    }
  }, [deviationToLocationForDeviationId]);

  const afterValidation = (values: EditLocationDeviationInput) => {
    const validatedInput = values as EditLocationDeviationInput;
    onSubmit(validatedInput);
  };

  if (loading || data == null) {
    return <div>loading....</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Formik
          initialValues={initialValues}
          onSubmit={afterValidation}
          validate={(value) => {
            try {
              validateYupSchema(value, validationSchema(t), true, value);
            } catch (err) {
              const errors = yupToFormErrors<{ costAllocation: string }>(err);
              return errors;
            }

            return {};
          }}
        >
          {(props) => (
            <SupressEnterForm
              onSubmit={props.handleSubmit}
              className={classes.root}
            >
              <FieldArray
                name={'customerDeviationListItemsInput'}
                render={(arrayHelpers) => (
                  <Grid
                    item
                    xs={12}
                    spacing={2}
                    container
                    style={{ padding: '5px' }}
                  >
                    <Grid container xs={12} className={clsx(classes.halfRow)}>
                      <Grid item xs={5}>
                        <SelectLocation name="faultLocationId" size="small" />
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5}>
                        <SelectLocation name="toLocationId" size="small" />
                      </Grid>
                    </Grid>
                    <Grid container xs={12} style={{ margin: 5 }}>
                      <Grid item xs={2}>
                        <CheckboxField
                          name="consequence"
                          label={t('attributes.consequence')}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <CommonTextField
                          name="deviationApplicableDate"
                          type="date"
                          label={t('attributes.date')}
                        />
                      </Grid>

                      <Grid item xs={3}>
                        <SelectEnumField
                          name="fault"
                          enumObject={DeviationFault}
                          label={t('attributes.fault')}
                          translationPath="enums.deviationFault"
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item container xs={3} justifyContent="flex-end">
                        <Box alignItems="center">
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              arrayHelpers.push({
                                deviationId: initialValues.id,
                                locationId: 0,
                                packages: null,
                                pallets: null,
                                palletSpace: null,
                                weight: null,
                                note: '',
                                reason: undefined,
                                otherReason: '',
                                newCustomer: '',
                                isNewCustomer: false,
                                isDangerous: false,
                              });
                            }}
                          >
                            {t('button.add', { item: t('customer') })}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    {props?.values?.customerDeviationListItemsInput && (
                      <Grid
                        xs={12}
                        item
                        container
                        className={classes.noPadding}
                        style={{ backgroundColor: '#e3e3e3' }}
                      >
                        <Grid item xs={5}>
                          <Typography variant="body2" color="textPrimary">
                            {'Customer'}
                          </Typography>
                        </Grid>

                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {'Packages'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {'Pallets'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {'Pallet space'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {'Weight'}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" color="textPrimary">
                            {'Note'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    )}
                    {
                      <Grid item xs={12}>
                        <List>
                          {props?.values?.customerDeviationListItemsInput &&
                            props.values.customerDeviationListItemsInput.map(
                              (
                                customerDeviationListItem: LocationDeviationListItemsInput,
                                index: number,
                              ): JSX.Element => (
                                <ListItem key={index}>
                                  <Grid
                                    xs={12}
                                    item
                                    key={index}
                                    container
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{
                                        width: '100%',
                                        marginBottom: 5,
                                      }}
                                    >
                                      <Grid item xs={4}>
                                        {props.values
                                          .customerDeviationListItemsInput[
                                          index
                                        ].isNewCustomer ? (
                                          <CommonTextField
                                            name={`customerDeviationListItemsInput.${index}.newCustomer`}
                                            size="small"
                                          />
                                        ) : (
                                          <SelectLocation
                                            name={`customerDeviationListItemsInput.${index}.locationId`}
                                            key={`customerDeviationListItemsInput.${index}`}
                                            className={
                                              classes.selecLocationField
                                            }
                                            size="small"
                                            smallFontSize
                                            noLabel
                                            smallPadding
                                            shortAddress={true}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={1}>
                                        <CheckboxField
                                          name={`customerDeviationListItemsInput.${index}.isNewCustomer`}
                                          label={t('attributes.newCustomer')}
                                          customerName={`customerDeviationListItemsInput.${index}`}
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <FastTextFieldPackage
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          name={`customerDeviationListItemsInput.${index}.packages`}
                                          className={classes.numberFields}
                                          type="number"
                                          smallPadding
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <CommonTextField
                                          noLabel
                                          smallFontSize
                                          size="small"
                                          name={`customerDeviationListItemsInput.${index}.pallets`}
                                          className={classes.numberFields}
                                          type="number"
                                          smallPadding
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <FastTextFieldPalletSpace
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          smallPadding
                                          name={`customerDeviationListItemsInput.${index}.palletSpace`}
                                          className={classes.numberFields}
                                          type="number"
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <FastTextFieldWeight
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          smallPadding
                                          name={`customerDeviationListItemsInput.${index}.weight`}
                                          className={classes.numberFields}
                                          type="number"
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <CommonTextField
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          smallPadding
                                          className={classes.field}
                                          name={`customerDeviationListItemsInput.${index}.locationItemNote`}
                                          type="text"
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <>
                                          {index === 0 ? null : (
                                            <IconButton
                                              className={classes.deleteButton}
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          )}
                                        </>
                                      </Grid>
                                    </Box>
                                    <Grid container>
                                      {/* for reason */}
                                      <Grid item xs={1}>
                                        <Typography
                                          style={{
                                            fontSize: 12,
                                          }}
                                          color="textPrimary"
                                        >{`Reason -  `}</Typography>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <SelectEnumFieldSmall
                                          name={`customerDeviationListItemsInput.${index}.reason`}
                                          enumObject={Reason}
                                          translationPath={
                                            'enums.remainingGoodsReasonClass'
                                          }
                                          reasonPath={`customerDeviationListItemsInput.${index}`}
                                        />
                                      </Grid>
                                      {props.values
                                        .customerDeviationListItemsInput[index]
                                        .reason === Reason.Other ? (
                                        <Grid item xs={5}>
                                          <CommonTextField
                                            name={`customerDeviationListItemsInput.${index}.otherReason`}
                                            smallPadding
                                            fullWidth
                                          />
                                        </Grid>
                                      ) : null}
                                      <Grid item xs={2}>
                                        <Tooltip
                                          title={`${t(
                                            'attributes.tooltipDangerousGoodsIcon',
                                          )}`}
                                        >
                                          <div>
                                            <CheckboxIconField
                                              name={`customerDeviationListItemsInput.${index}.isDangerous`}
                                              icon={
                                                <DangerIcon
                                                  style={{
                                                    color: 'grey',
                                                  }}
                                                />
                                              }
                                              checkedIcon={
                                                <DangerIcon
                                                  style={{
                                                    color: 'red',
                                                  }}
                                                />
                                              }
                                              size="small"
                                            />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ),
                            )}
                        </List>
                      </Grid>
                    }
                  </Grid>
                )}
              />
              <Box className={clsx(classes.row, classes.center)}>
                <Button type="submit" variant="contained" color="primary">
                  {t('actions.update')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: '#f07267' }}
                  onClick={() => {
                    if (initialValues && initialValues.id) {
                      onDeleteAction(initialValues.id);
                    }
                  }}
                >
                  {t('actions.delete.capitalized')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    onCancel();
                  }}
                >
                  {t('actions.cancel')}
                </Button>
              </Box>
            </SupressEnterForm>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
