import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  createStyles,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useLinkUnlinkLegWithShipmentMutation } from '../generated/graphql';
import { ImportShipmentFormInput } from '../views/ImportShipment';
import { ShipmentOrderRHF } from './ShipmentOrderRHF';

interface ShipmentOrdersListProps {
  readOnly: boolean;
  isPastDate: boolean;
  shipmentIndex: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    textLable: {
      fontSize: 12,
      maxLines: 1,
      textAlign: 'left',
    },
  }),
);

export function ShipmentsOrdersListRHF(props: ShipmentOrdersListProps) {
  const { readOnly = false, shipmentIndex, isPastDate } = props;
  const classes = useStyles();
  const { control, getValues } = useFormContext<ImportShipmentFormInput>();

  //const [posChanged, setPosChanged] = useState<boolean>(false);
  const [linkOrUnlinkOrder] = useLinkUnlinkLegWithShipmentMutation();
  const shipmentDate = getValues(`shipments.${shipmentIndex}.shipmentDate`);
  const shipmentFromLocationId = getValues(
    `shipments.${shipmentIndex}.fromLocationId`,
  );
  const { fields } = useFieldArray({
    control,
    keyName: '_key',
    name: `shipments.${shipmentIndex}.loadingListItems`,
  });

  return (
    <Box style={{ marginLeft: 12, marginRight: 12 }}>
      <List>
        <Grid
          item
          xs={12}
          spacing={1}
          container
          style={{ width: '100%', padding: '0 !important' }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ width: '100%', marginLeft: 5, marginBottom: 5 }}
          >
            <Grid item xs={4}>
              <Typography
                className={classes.textLable}
                style={{ marginLeft: 28 }}
              >
                {'Customer'}
              </Typography>
            </Grid>
            <Grid item container xs={2}>
              <Grid item xs={4}>
                <Typography className={classes.textLable}>{'PCS'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.textLable}>{'PAL'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.textLable}>{'PPL'}</Typography>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Typography className={classes.textLable}>
                {'Cust.WayBill'}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ marginRight: 2 }}>
              <Typography className={classes.textLable}>
                {'RouteName - Link/Unlink'}
              </Typography>
            </Grid>
            <Grid item container xs={1}>
              <Grid item xs={6}>
                <Tooltip title="VOEC Customer?">
                  <Typography className={classes.textLable}>{'VC'}</Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Remaining Goods?">
                  <Typography className={classes.textLable}>{'RG'}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={2} style={{ backgroundColor: 'green' }}>
              <Typography> </Typography>
            </Grid>
          </Box>
        </Grid>
        {fields.map((order, index) => (
          <ListItem key={order._key} style={{ padding: 0 }} divider>
            <ShipmentOrderRHF
              key={`o-${order.id}`}
              readOnly={readOnly}
              isPastDate={isPastDate}
              shipmentIndex={shipmentIndex}
              orderIndex={index}
              orderId={order.id}
              linkNow={(legId, orderId) => {
                linkOrUnlinkOrder({
                  variables: {
                    input: {
                      isLink: true,
                      legId: legId,
                      orderId: orderId,
                      terminalId: shipmentFromLocationId,
                      transDate: shipmentDate ?? '',
                    },
                  },
                });
              }}
              unLinkNow={(orderId) => {
                linkOrUnlinkOrder({
                  variables: {
                    input: {
                      isLink: false,
                      legId: 0,
                      orderId: orderId,
                      terminalId: shipmentFromLocationId,
                      transDate: shipmentDate ?? '',
                    },
                  },
                });
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
