import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import { OrderForm } from '../components/OrderForm';
import {
  GetOrdersDocument,
  OrderInput,
  useCreateOrderMutation,
} from '../generated/graphql';
import { DATE_FORMAT } from '../lib/date_time';

export function CreateOrder() {
  const router = useHistory();
  const { t } = useTranslation();

  const [createOrder] = useCreateOrderMutation({
    refetchQueries: [
      {
        query: GetOrdersDocument,
      },
    ],
  });

  const onSubmit = async (
    values: Omit<OrderInput, 'customerId'> & { customerId?: number },
  ) => {
    if (values.customerId == null) {
      return;
    }

    await createOrder({
      variables: {
        input: {
          id: values.id,
          transportationDate: values.transportationDate,
          note: values.note,
          customerId: values.customerId,
          packages: values.packages,
          pallets: values.pallets,
          palletSpace: values.palletSpace,
          weight: values.weight,
        },
      },
    });
    router.push('/traffic/orders');
  };

  const initialValues = (): Omit<OrderInput, 'customerId'> & {
    customerId?: number;
  } => ({
    id: null,
    transportationDate: format(new Date(), DATE_FORMAT),
    note: '',
    customerId: undefined,
    packages: 0,
    pallets: 0,
    palletSpace: 0,
    weight: 0,
  });

  return (
    <Grid container spacing={5} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.order.capitalized',
        )}`}
      />
      <Grid item>
        <Typography variant="h1">{t('resource.order.capitalized')}</Typography>
      </Grid>
      <Grid item>
        <OrderForm
          onSubmit={onSubmit}
          initialValues={initialValues()}
          submitButtonLabel={t('button.create', {
            item: t('resource.order.lowercased'),
          })}
        />
      </Grid>
    </Grid>
  );
}
