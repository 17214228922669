import { LocationsFieldsFragment } from '../generated/graphql';

export function locationString(
  l: {
    __typename?: 'LocationEntity';
  } & LocationsFieldsFragment,
) {
  return [
    l.name,
    l.address,
    `${l.postalCode} ${l.city}`,
    typeof l.countryLong !== 'undefined' ? l.countryLong.toUpperCase() : '',
  ]
    .filter(Boolean)
    .join(', ');
}
