import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../CenteredBox';
import { SelectContactFromCard } from './SelectContactFromCard';
import { SelectSubcontractor } from './SelectSubcontractor';

interface AggreementFieldsProps {
  readOnly: boolean;
  fromTerminal?: boolean;
}

export function SubcontractorFields({
  readOnly,
  fromTerminal = false,
}: AggreementFieldsProps) {
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext();
  const cardId = getFieldProps('subcontractorId').value;
  return (
    <>
      <CenteredBox>
        <SelectSubcontractor
          name="subcontractorId"
          readOnly={readOnly || fromTerminal}
        />
      </CenteredBox>
      <CenteredBox>
        <SelectContactFromCard
          name="dispatcherId"
          cardId={cardId}
          label={t('attributes.dispatcherId')}
          readOnly={readOnly || fromTerminal}
        />
      </CenteredBox>
    </>
  );
}
