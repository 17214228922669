import { Box, Tooltip, Link } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Days, FixedTrafficLeg, Subcontractor } from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { CustomGridToolbar } from './datagrid/CustomGridToolbar';
import { TrueOrFalseCell } from './datagrid/TrueOrFalseCell';
import { UserConfiguredDataGridPro } from './datagrid/UserConfiguredDataGridPro';

export interface ListFixedTrafficRoutesProps {
  loading: boolean;
  legs: Array<
    { __typename?: 'FixedTrafficLeg' } & Pick<
      FixedTrafficLeg,
      | 'id'
      | 'routeId'
      | 'tourId'
      | 'capacity'
      | 'days'
      | 'arrivalTime'
      | 'departureTime'
      | 'departureCity'
      | 'arrivalCity'
      | 'load'
      | 'unload'
      | 'note'
      | 'startDate'
      | 'endDate'
      | 'startExceptionDate'
      | 'endExceptionDate'
    > & {
        subcontractor: { __typename?: 'Subcontractor' } & Pick<
          Subcontractor,
          'id' | 'name'
        >;
      }
  >;
}

export function ListFixedTrafficRoutes(props: ListFixedTrafficRoutesProps) {
  const { legs, loading } = props;
  const { t } = useTranslation();

  if (legs == null) {
    return null;
  }

  return (
    <Box>
      <UserConfiguredDataGridPro
        tableName={TABLE_NAMES.FixedTrafficOverview}
        autoHeight
        loading={loading}
        components={{
          Toolbar: CustomGridToolbar,
        }}
        columns={[
          {
            field: 'id',
            headerName: 'id',
            disableColumnMenu: true,
          },
          {
            field: 'viewTour',
            headerName: 'View Tour',
            minWidth: 100,
            type: 'actions',
            renderCell: (params: GridCellParams) => {
              const { row } = params;
              const { tourId } = row;
              if (tourId == null) {
                return null;
              }

              return (
                <Tooltip
                  title={`${t('actions.viewItem', {
                    item: t('resource.tour.capitalized'),
                  })}`}
                >
                  <Link
                    variant="body2"
                    href={`/terminal/tours/${tourId}`}
                    target={'_blank'}
                  >
                    <PageviewIcon color="action" />
                  </Link>
                </Tooltip>
              );
            },
          },
          { field: 'routeId', headerName: 'Route Id', minWidth: 100 },
          {
            field: 'arrivalCity',
            headerName: 'Arrival city',
            minWidth: 100,
          },
          {
            field: 'departureCity',
            headerName: 'Departure city',
            minWidth: 100,
          },
          {
            field: 'subcontractorName',
            headerName: 'Subcontractor',
            minWidth: 100,
            align: 'left',
            renderCell: (params: GridCellParams) => {
              const { row } = params;
              const { subcontractor } = row;
              if (!subcontractor || !subcontractor.id) {
                return null;
              }

              return (
                <Link
                  variant="body2"
                  href={`/terminal/subcontractors/${subcontractor.id}`}
                  target={'_blank'}
                  underline={'hover'}
                  color={'inherit'}
                >
                  {subcontractor.name}
                </Link>
              );
            },
          },
          {
            field: 'capacity',
            headerName: 'Capacity',
            minWidth: 75,
            type: 'number',
            valueFormatter({ value }) {
              if (value == null) {
                return '';
              }

              return value;
            },
          },
          {
            field: 'days',
            headerName: 'Frequency',
            minWidth: 100,
            valueFormatter({ value }) {
              return (value as Days[]).join(' ');
            },
          },
          {
            field: 'arrivalTime',
            headerName: 'Arrival time',
            minWidth: 100,
            valueFormatter(params) {
              const { value } = params;
              if (typeof value === 'string' && value !== '') {
                return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
              }
              return '';
            },
          },
          {
            field: 'departureTime',
            headerName: 'Departure time',
            minWidth: 100,
            valueFormatter(params) {
              const { value } = params;
              if (typeof value === 'string' && value !== '') {
                return format(parse(value, 'HH:mm:ss', new Date()), 'HH:mm');
              }
              return '';
            },
          },
          {
            field: 'load',
            headerName: 'Load',
            minWidth: 100,
            type: 'boolean',
            renderCell(params) {
              return <TrueOrFalseCell {...params} />;
            },
          },
          {
            field: 'unload',
            headerName: 'Unload',
            minWidth: 100,
            type: 'boolean',
            renderCell(params) {
              return <TrueOrFalseCell {...params} />;
            },
          },
          {
            field: 'note',
            headerName: 'Note from traffic',
            minWidth: 100,
          },
          {
            field: 'startDate',
            headerName: 'Tour start date',
            type: 'dateTime',
            minWidth: 100,
            valueGetter({ value }) {
              if (value == null) {
                return null;
              }
              return new Date(value as string);
            },
            valueFormatter({ value }) {
              if (value == null) {
                return null;
              }
              return format(value as Date, 'dd-MM-yyyy');
            },
          },
          {
            field: 'endDate',
            headerName: 'Tour end date',
            type: 'dateTime',
            minWidth: 100,
            valueGetter({ value }) {
              if (value == null) {
                return null;
              }
              return new Date(value as string);
            },
            valueFormatter({ value }) {
              if (value == null) {
                return null;
              }
              return format(value as Date, 'dd-MM-yyyy');
            },
          },
          {
            field: 'startExceptionDate',
            headerName: 'Tour start exception date',
            type: 'dateTime',
            minWidth: 100,
            valueGetter({ value }) {
              if (value == null) {
                return null;
              }
              return new Date(value as string);
            },
            valueFormatter({ value }) {
              if (value == null) {
                return null;
              }
              return format(value as Date, 'dd-MM-yyyy');
            },
          },
          {
            field: 'endExceptionDate',
            headerName: 'Tour end exception date',
            type: 'dateTime',
            minWidth: 100,
            valueGetter({ value }) {
              if (value == null) {
                return null;
              }
              return new Date(value as string);
            },
            valueFormatter({ value }) {
              if (value == null) {
                return null;
              }
              return format(value as Date, 'dd-MM-yyyy');
            },
          },
        ]}
        rows={legs}
      />
    </Box>
  );
}
