import { Button, Grid, Radio } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import {
  GridColDef,
  GridDensityTypes,
  GridSelectionModel,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Maybe, useGetOtherToursLazyQuery } from '../../generated/graphql';
import { TABLE_NAMES } from '../../lib/constants';
import { UserConfiguredDataGridPro } from '../datagrid/UserConfiguredDataGridPro';
import { SearchInput } from '../SearchInput';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface SelectOtherToursModalProps {
  handleClose: () => void;
  open: boolean;
  onSelectConfirm: (tourId: number) => void;
}

let selectedRadio: GridSelectionModel = [0];

export function SelectOtherTourModal(props: SelectOtherToursModalProps) {
  const { handleClose, open, onSelectConfirm } = props;
  const { params } = useRouteMatch<{ id: string }>();
  const { t } = useTranslation();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([0]);
  selectedRadio = selectionModel;

  const [searchQuery, setSearchQuery] = useState<Maybe<string> | undefined>();

  const [selectedTourId, setselectedTourId] = useState<number>(0);

  const [getToursApi, { data, error, loading }] = useGetOtherToursLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    getToursApi({
      variables: {
        excludeId: parseInt(params.id),
        searchTerm: searchQuery,
      },
    });
  }, [searchQuery]);

  const onQuerySelected = useCallback(
    (values) => {
      setSearchQuery(values.searchQuery);
    },
    [searchQuery],
  );

  const tourData = data?.otherTours.map((tour) => ({
    ...tour,
    subcontractorName: tour.subcontractor.name,
  }));

  const gridColumns: GridColDef[] = [
    {
      field: 'radiobutton',
      headerName: 'Choose',
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Radio
            checked={selectedRadio[0] === parseInt(params.row.id)}
            value={params.row.id}
            color={'primary'}
          />
        );
      },
    },
    {
      field: 'name',
      type: 'string',
      headerName: t(`attributes.nameWithResource`, {
        item: t('resource.tour.capitalized'),
      }),
      minWidth: 100,
    },
    {
      field: 'startDate',
      type: 'string',
      headerName: t(`attributes.startDate`),
      minWidth: 100,
    },
    {
      field: 'endDate',
      type: 'string',
      headerName: t(`attributes.endDate`),
      minWidth: 100,
    },
    {
      field: 'isActive',
      type: 'boolean',
      headerName: t(`attributes.active`),
      minWidth: 100,
    },
    {
      field: 'subcontractorName',
      headerName: t('resource.subcontractor.capitalized'),
      minWidth: 100,
    },
    {
      field: 'capacity',
      type: 'string',
      headerName: t(`attributes.capacity`),
      minWidth: 100,
      valueFormatter({ value }) {
        if (value == null) {
          return '';
        }

        return value;
      },
    },
    {
      field: 'price',
      type: 'number',
      headerName: t(`attributes.price`),
      minWidth: 100,
      valueGetter({ value, row }) {
        const currency =
          typeof row.currency === 'string' ? row.currency.toUpperCase() : '';
        return `${value} ${currency}`;
      },
    },

    {
      field: 'id',
      type: 'string',
      headerName: t(`attributes.agreementNumber`),
      minWidth: 100,
    },
    {
      field: 'createdBy',
      type: 'string',
      headerName: t('attributes.createdBy'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.createdBy;
      },
    },
    {
      field: 'lastUpdatedBy',
      type: 'string',
      headerName: t('attributes.lastUpdatedBy'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.lastUpdatedBy;
      },
    },
    {
      field: 'lastUpdated',
      type: 'string',
      headerName: t('attributes.updatedAt'),
      minWidth: 100,
      valueGetter({ row }) {
        return row.updatedAt;
      },
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{'Select one tour'}</Typography>
          <IconButton style={{ marginBottom: 6 }} onClick={handleClose}>
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
        </Box>

        {error && <div>{error.message}</div>}

        {(data || loading) && (
          <>
            <Grid item container>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                width="100%"
                mb={2}
              >
                <SearchInput
                  searchQuery={searchQuery}
                  onQuerySelected={onQuerySelected}
                />
              </Box>
            </Grid>

            <div style={{ width: '100%', marginBottom: 8 }}>
              <UserConfiguredDataGridPro
                tableName={TABLE_NAMES.OtherTours}
                rows={tourData || []}
                loading={loading}
                onSelectionModelChange={(sModel) => {
                  setSelectionModel(sModel);
                  setselectedTourId(parseInt(sModel[0] as string));
                }}
                selectionModel={selectionModel}
                disableMultipleSelection
                columns={gridColumns}
                pageSize={8}
                onFilterModelChange={() => {
                  setSelectionModel([0]);
                  setselectedTourId(0);
                }}
                density={GridDensityTypes.Compact}
                autoHeight={true}
              />
            </div>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignSelf="flex-end | end"
              width="100%"
              mt={1}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isNaN(selectedTourId) || selectedTourId == 0}
                onClick={() => {
                  onSelectConfirm(selectedTourId);
                  handleClose();
                }}
              >
                {t('button.confirmAndCopy')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
