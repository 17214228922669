import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  Input,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { FileItem, FileListComponent } from '../components/FileListComponent';
import * as yup from 'yup';
import {
  GetDocumentFileTagsQuery,
  GetFilesDocument,
  useDeleteDocumentFileMutation,
  useGetDocumentFileTagsQuery,
  useGetFilesQuery,
} from '../generated/graphql';
import { useHttpClient } from '../providers/HttpClientProvider';
import { HelmetComponent } from '../components/HelmetComponent';

const SUPPORTED_FORMATS = ['application/pdf'];
const MAX_FILE_SIZE_MB = 30;
const FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1024;

const validationSchema = (t: TFunction) =>
  yup.object({
    file: yup
      .mixed()
      .test(
        'fileSize',
        t('validation.fileTooBig'),
        (value) => value && value.size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        t('validation.onlyPDF'),
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      )
      .required(t('validation.isRequired', { name: t('attributes.file') })),
  });

interface Tag {
  id: number;
  title: string;
}

export function DocumentBank() {
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const { data, loading, error, refetch } = useGetFilesQuery({});
  const { data: tagsData } = useGetDocumentFileTagsQuery();
  const [deleteDocumentFileMutation] = useDeleteDocumentFileMutation({
    refetchQueries: [{ query: GetFilesDocument }],
  });

  const [showForm, setShowForm] = useState(false);

  if (loading || data == null) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const handleDelete = (file: FileItem) => {
    // TODO: Should change cache instead of refetching files
    deleteDocumentFileMutation({
      variables: { deleteDocumentFileId: file.id },
    });
  };

  const onFileUpload = async (values: { file?: File; tagIds: number[] }) => {
    if (values.file == null) return;

    await httpClient.uploadDocumentFile(values.file, values.tagIds);
    refetch();

    setShowForm(false);
  };

  const handleFormShowClicked = () => {
    setShowForm(true);
  };

  return (
    <Grid container>
      <HelmetComponent title={t('pages.documentBank.title')} />
      <Grid item>
        <Typography variant="h2">{t('pages.documentBank.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FileListComponent
          files={data.files}
          handleDelete={handleDelete}
          handleFormShowClicked={handleFormShowClicked}
          form
          showForm={showForm}
        >
          <Card>
            <CardContent>
              <Formik
                initialValues={{
                  file: undefined,
                  tagIds: [],
                }}
                onSubmit={onFileUpload}
                validationSchema={validationSchema(t)}
              >
                {({
                  setFieldValue,
                  handleSubmit,
                  submitForm,
                  resetForm,
                  errors,
                  touched,
                }) => (
                  <>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs={8} md={8}>
                        <Typography variant="subtitle1">
                          {t('button.add', { item: t('attributes.file') })}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} md={8}>
                        <form onSubmit={handleSubmit}>
                          <Grid
                            container
                            spacing={2}
                            justifyContent="center"
                            alignItems="stretch"
                          >
                            <Grid item xs={12} md={12}>
                              <Grid container direction="column">
                                <Input
                                  type="file"
                                  error={touched.file && Boolean(errors.file)}
                                  onChange={(event: SyntheticEvent) => {
                                    const target =
                                      event.target as HTMLInputElement;
                                    const files = target.files;
                                    if (files != null) {
                                      setFieldValue('file', files[0]);
                                    }
                                  }}
                                />
                                <FormHelperText
                                  error={touched.file && Boolean(errors.file)}
                                >
                                  {errors.file}
                                </FormHelperText>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Grid container>
                                <Autocomplete
                                  id="tagIds"
                                  fullWidth
                                  multiple
                                  autoHighlight
                                  options={tagsData?.documentFileTags || []}
                                  getOptionLabel={(tag: Tag) => tag.title}
                                  onChange={(
                                    _event: ChangeEvent<unknown>,
                                    value: GetDocumentFileTagsQuery['documentFileTags'],
                                  ) => {
                                    setFieldValue(
                                      'tagIds',
                                      value.map((tag) => tag.id),
                                    );
                                  }}
                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      variant="standard"
                                      label={t('attributes.fileTagIds')}
                                      placeholder="Tags"
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Button onClick={submitForm}>
                                {t('button.add', {
                                  item: t('attributes.file'),
                                })}
                              </Button>
                              <Button
                                onClick={() => {
                                  resetForm();
                                  setShowForm(false);
                                }}
                                color="secondary"
                              >
                                {t('button.cancel')}
                              </Button>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Formik>
            </CardContent>
          </Card>
        </FileListComponent>
      </Grid>
    </Grid>
  );
}
