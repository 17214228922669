import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Divider,
  Stack,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { read, utils } from 'xlsx';
import { CardType } from '../../generated/graphql';
import { useHttpClient } from '../../providers/HttpClientProvider';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  height: '45vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

export interface EntityCardColumns {
  CardName: string;
  LocationName: string;
  Address: string;
  PostalCode: string;
  City: string;
  CountryCode: string;
}

export interface ExcelCardColumns {
  CustomerName?: string;
  TerminalName?: string;
  SubcontractorName?: string;
  CheckpointName?: string;
  LocationName: string;
  Address: string;
  PostalCode: string;
  City: string;
  CountryCode: string;
}
interface SheetInfo {
  IMPORT_DATA_INFO: string;
}

interface ImportCardModalProps {
  handleClose: () => void;
  open: boolean;
  showImportLoading: boolean;
  cardType: string;
  getDataArray: (arr: EntityCardColumns[]) => void;
}

export function ImportCardModal(props: ImportCardModalProps) {
  const { handleClose, open, showImportLoading, cardType, getDataArray } =
    props;
  const { t } = useTranslation();
  const MAX_FILE_SIZE_ALLOWED = 1024 * 1024 * 10; //10 MB
  const MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE_ALLOWED / 1024 / 1024; //for message

  const { httpClient } = useHttpClient();
  const [fileError, setFileError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const fileInput = useRef<HTMLInputElement>(null);

  const getProperCardName = (value: any): string => {
    switch (cardType) {
      case CardType.Customer:
        return value.CustomerName && value.CustomerName !== ''
          ? value.CustomerName.trim().toUpperCase()
          : '';
      case CardType.Terminal:
        return value.TerminalName && value.TerminalName !== ''
          ? value.TerminalName.trim().toUpperCase()
          : '';
      case CardType.Checkpoint:
        return value.CheckpointName && value.CheckpointName !== ''
          ? value.CheckpointName.trim().toUpperCase()
          : '';
      case CardType.Subcontractor:
        return value.SubcontractorName && value.SubcontractorName !== ''
          ? value.SubcontractorName.trim().toUpperCase()
          : '';
      default:
        return '';
    }
  };

  const handleChange = (files: FileList | null) => {
    setFileError(false);
    if (files && files.length) {
      const file = files[0];

      if (
        file &&
        file.type.includes('.sheet') &&
        file.size < MAX_FILE_SIZE_ALLOWED
      ) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const wb = read(event.target?.result);
          const sheets = wb.SheetNames;

          if (sheets.length == 2) {
            let isValidCardTypeInSheet = false;
            const rawJsonInfo = utils.sheet_to_json(wb.Sheets[sheets[1]]);
            if (
              rawJsonInfo &&
              rawJsonInfo.length > 1 &&
              (rawJsonInfo[0] as SheetInfo).IMPORT_DATA_INFO === cardType
            ) {
              //check for card type
              isValidCardTypeInSheet = true;
            }

            const rawJsonArray = utils.sheet_to_json(wb.Sheets[sheets[0]]);

            if (rawJsonArray && rawJsonArray.length > 0) {
              //check header/title
              const arrKeys: string[] = [];
              Object.entries(rawJsonArray[0] as ExcelCardColumns).forEach(
                (key) => {
                  arrKeys.push(key[0]);
                },
              );

              if (
                isValidCardTypeInSheet &&
                arrKeys.length === 6 &&
                arrKeys.join('-') ===
                  `${cardType}Name-LocationName-Address-PostalCode-City-CountryCode`
              ) {
                let dataArrFromSheet: EntityCardColumns[] = [];
                Object.values(rawJsonArray as ExcelCardColumns[]).forEach(
                  (value) => {
                    dataArrFromSheet = dataArrFromSheet.concat({
                      CardName: getProperCardName(value),
                      LocationName: value.LocationName ?? '',
                      Address: value.Address ?? '',
                      PostalCode: `${value.PostalCode}`,
                      City: value.City ?? '',
                      CountryCode:
                        value.CountryCode && value.CountryCode.includes(':')
                          ? value.CountryCode.split(':')[0]
                          : '',
                    });
                  },
                );

                getDataArray(dataArrFromSheet);
              } else {
                if (fileInput.current) {
                  const f = fileInput.current as HTMLInputElement;
                  f.value = '';
                }
                setErrorMessage(`${t('validation.invalidExcelFormat')}`);
                setFileError(true);
              }
            } else {
              //no data
              if (fileInput.current) {
                const f = fileInput.current as HTMLInputElement;
                f.value = '';
              }
              setErrorMessage(`${t('validation.emptyRowInSheet')}`);
              setFileError(true);
            }
          } else {
            if (fileInput.current) {
              const f = fileInput.current as HTMLInputElement;
              f.value = '';
            }
            setErrorMessage(`${t('validation.invalidExcelFormat')}`);
            setFileError(true);
          }
        };
        reader.readAsArrayBuffer(file);
      } else {
        if (fileInput.current) {
          const f = fileInput.current as HTMLInputElement;
          f.value = '';
        }
        setErrorMessage(`
          ${t('validation.improperFileForImport', {
            sizeInMB: MAX_FILE_SIZE_IN_MB,
          })}
        `);
        setFileError(true);
      }
    }
  };

  const handleDownloadTemplate = () => {
    httpClient
      .downloadImportTemplateBlob({
        cardType,
      })
      .then((res) => {
        //convert Blob to File and download
        const templateFile: any = res.data;
        templateFile.name = `ImportTemplate_${cardType}`;
        templateFile.originalName = `ImportTemplate_${cardType}`;
        templateFile.lastModifiedDate = new Date();

        const url = window.URL.createObjectURL(templateFile);
        const anchor = document.createElement('a');
        anchor.setAttribute('href', url);
        anchor.setAttribute('download', `ImportTemplate_${cardType}`);
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{t('info.importCardData')}</Typography>

          {!showImportLoading && (
            <IconButton style={{ marginLeft: 8 }} onClick={handleClose}>
              <CloseIcon fontSize="large" color="action" />
            </IconButton>
          )}
        </Box>

        {!showImportLoading && (
          <>
            <Typography
              style={{ fontSize: 16 }}
            >{`Type: ${cardType}`}</Typography>

            <Typography style={{ fontSize: 13 }}>{`${t(
              'info.fileUploadMessage',
              { maxFileSizeInMB: MAX_FILE_SIZE_IN_MB },
            )}`}</Typography>
            {/* Download template */}

            {/* upload excel controls */}
            <div style={{ marginTop: '28px' }}>
              <input
                ref={fileInput}
                type="file"
                onChange={(e) => handleChange(e.target.files)}
                accept=".xlsx"
              />
            </div>
            {fileError && (
              <Typography style={{ fontSize: 13, color: 'red', marginTop: 5 }}>
                {errorMessage}
              </Typography>
            )}
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <Stack direction="row" spacing={1}>
              <Typography style={{ fontSize: 13 }}>
                {`${t('attributes.dontHaveTemplate')}`}
              </Typography>
              <div onClick={handleDownloadTemplate}>
                <Typography
                  style={{
                    fontSize: 13,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {`${t('attributes.clickToDownload')}`}
                </Typography>
              </div>
            </Stack>
          </>
        )}

        {showImportLoading && (
          <CircularProgress style={{ marginTop: 5, alignSelf: 'center' }} />
        )}
      </Box>
    </Modal>
  );
}
