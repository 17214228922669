import { debounce, TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLocationsForLegLazyQuery } from '../../generated/graphql';
import {
  useFormContext,
  Controller,
  Path,
  useFormState,
} from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

interface SelectLocationProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  readOnly?: boolean;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  smallFontSize?: boolean;
  noLabel?: boolean;
  smallPadding?: boolean;
  withLink?: boolean;
  shortAddress?: boolean;
  locationPath: Path<SporadicRouteFormInput>;
}

interface SelectLocationInterface {
  id: number;
  name: string;
  address: string;
  postalCode: string;
  country: string;
  city: string;
}

function getLocationInfo(
  location: SelectLocationInterface | null,
  short: boolean,
) {
  if (location?.name === 'BLANK') {
    return '';
  } else {
    if (short) {
      return `${location?.name} | ${
        location?.city
      } | ${location?.country?.toUpperCase()}`;
    } else {
      return `${location?.name} | ${location?.address} | ${
        location?.postalCode
      } | ${location?.city} | ${location?.country?.toUpperCase()}`;
    }
  }
}

export function SelectLocationRHF(props: SelectLocationProps & TextFieldProps) {
  const {
    name,
    controllerName,
    readOnly = false,
    fullWidth = false,
    size = 'medium',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    shortAddress = false,
    locationPath,
  } = props;
  const { control, getFieldState, getValues } =
    useFormContext<SporadicRouteFormInput>();
  const locationObj = getValues(locationPath);

  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<SelectLocationInterface[]>([]);

  const [value, setValue] = useState<SelectLocationInterface | null>(
    locationObj as SelectLocationInterface,
  );
  const [callApi, setCallApi] = useState(false);
  const { t } = useTranslation();
  const { errors } = useFormState<SporadicRouteFormInput>();

  const isErrorForField = errors && getFieldState(controllerName).error;
  const errorMessage = isErrorForField && isErrorForField?.message;

  const [getLocationsforLeg, { data: optionsData, loading: loadingOptions }] =
    useGetLocationsForLegLazyQuery();

  useEffect(() => {
    if (search.length > 2) {
      getLocationsforLeg({ variables: { locationsSearch: search } });
    }
  }, [search]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.locations]);
      } else {
        setOptions(optionsData.locations);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    if (newVal && newVal.length < 15) setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];

  if (readOnly) {
    const textComponent = (
      <TextField
        {...props}
        variant="outlined"
        className={props.className}
        label={noLabel ? undefined : t(`attributes.${label}`)}
        value={getLocationInfo(value, shortAddress)}
        size={size}
        inputProps={{
          style: {
            fontSize: smallFontSize ? '12px' : undefined,
            padding: smallPadding ? '4px' : undefined,
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
    return textComponent;
  }

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field: { onChange } }) => (
        <Autocomplete
          value={value}
          multiple={false}
          fullWidth={fullWidth}
          autoHighlight
          size={size}
          onChange={(_event, val) => {
            onChange(val && val.id ? val.id : '0');
            if (val?.id) {
              setValue({ ...val });
              setSearch('');
            } else if (!val || !val.id) {
              setOptions([]);
              setValue(null);
            }
          }}
          onInputChange={(_event, newVal) => {
            if (callApi) debouncedChangeHandler(_event, newVal);
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={(option) => getLocationInfo(option, shortAddress)}
          options={options}
          loading={loadingOptions}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size={size}
              error={Boolean(isErrorForField)}
              helperText={
                (isErrorForField && errorMessage) || Boolean(isErrorForField)
              }
              variant="outlined"
              label={noLabel ? undefined : t(`attributes.${label}`)}
              InputProps={{
                ...params.InputProps,
                style: {
                  fontSize: smallFontSize ? '12px' : undefined,
                  padding: smallPadding ? '0' : undefined,
                },
              }}
              onChange={() => {
                if (!callApi) setCallApi(true);
              }}
            />
          )}
        />
      )}
    />
  );
}
