export const configuration = {
  production: process.env.NODE_ENV === 'production',
  azure: {
    clientId: process.env.REACT_APP_AZURE_APP_ID || '',
    scopes: process.env.REACT_APP_AZURE_API_SCOPE
      ? [process.env.REACT_APP_AZURE_API_SCOPE]
      : [],
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI || '',
    authority:
      `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}/` ||
      '',
  },
};
