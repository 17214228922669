import { debounce, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useCallback, useEffect, useState } from 'react';
import {
  SearchSubcontractorsQuery,
  useGetSubcontractorLazyQuery,
  useGetSubcontractorsLazyQuery,
} from '../../generated/graphql';

interface SelectSubcontractorProps {
  onCellChange: (subcontractor?: { id: number; name: string } | null) => void;
  initialValue: number | undefined;
}

export function SelectSubcontractorCell(
  props: SelectSubcontractorProps & TextFieldProps,
) {
  const { onCellChange, initialValue } = props;

  const [options, setOptions] = useState<
    SearchSubcontractorsQuery['subcontractors']
  >([]);
  const [value, setValue] = useState<
    SearchSubcontractorsQuery['subcontractors'][number] | null
  >(null);
  const [search, setSearch] = useState('');
  const [searchSubcontractors, { data: optionsData, loading: loadingOptions }] =
    useGetSubcontractorsLazyQuery();
  const [getSubcontractor, { data: subcontractorData, loading }] =
    useGetSubcontractorLazyQuery();

  useEffect(() => {
    if (initialValue != null) {
      getSubcontractor({ variables: { id: initialValue } });
    }
  }, [initialValue]);

  useEffect(() => {
    if (subcontractorData) {
      setOptions([subcontractorData.subcontractor]);
      setValue(subcontractorData.subcontractor);
    }
  }, [subcontractorData]);

  useEffect(() => {
    if (search.length > 2) {
      searchSubcontractors({
        variables: {
          search: search,
          withTerminal: true,
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.subcontractors]);
      } else {
        setOptions(optionsData.subcontractors);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  return (
    <Autocomplete
      value={value}
      multiple={false}
      fullWidth={true}
      autoHighlight
      onChange={(event, val) => {
        onCellChange(val);
      }}
      onInputChange={debouncedChangeHandler}
      getOptionLabel={(option) => {
        return option.name;
      }}
      sx={{
        height: 30,
      }}
      openOnFocus
      options={options}
      loading={loading || loadingOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          size={'small'}
          variant="outlined"
          hiddenLabel
          InputProps={{ ...params.InputProps }}
          autoFocus
        />
      )}
    />
  );
}
