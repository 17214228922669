import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { ObjectShape } from 'yup/lib/object';
import { Maybe, RouteFtlInput } from '../generated/graphql';
import { normalizeRouteIdForRoute } from '../lib/normalizeRouteId';
import { getUserName } from '../lib/useRoles';
import { AutoReset } from '../utils/AutoReset';
import { AggregatesLoadingList } from './AggregatesLoadingList';
import { CenteredBox } from './CenteredBox';
import { AccordionContainer } from './form/AccordionContainer';
import { CommonTextField } from './form/CommonTextField';
import {
  CostAllocationFields,
  CostAllocationItemInterface,
} from './form/CostAllocationFields';
import { FilesField } from './form/FilesField';
import { HideableFormFields } from './form/HideableFormFields';
import { LegFieldInput } from './form/LegFields';
import { RouteAgreementFields } from './form/routeFields/RouteAgreementFields';
import { RouteFTLFields } from './form/RouteFTLFields';
import { SubcontractorFields } from './form/SubcontractorFields';
import { SupressEnterForm } from './form/SupressEnterForm';
import { LegsFields } from './LegsFields';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DatesFields } from './form/tourFields/DatesFields';
import { DaysFieldForPlannedRoutes } from './form/tourFields/DaysFieldForPlannedRoutes';
import DatePickerMultiple from './form/tourFields/DatePickerMultiple';
import { format, isWithinInterval } from 'date-fns';
import { getDayNumber } from '../views/CreateMultipleSporadicRouteRHF';
import { DialogConfirmationModal } from './DialoConfirmationgModal';

function createFTL(): Partial<RouteFtlInput> {
  return {
    cdc: '',
    cmr: '',
    customerId: undefined,
    customerNumber: '',
    customerReference: '',
    invoiceNote: '',
    price: 0,
    currency: 'dkk',
  };
}

export type SporadicRouteFormInput = {
  dateType?: string;
  selectedCalDates?: string[];
  hasExceptionDates?: boolean;
  startDate?: string;
  endDate?: string;
  startExceptionDate?: string;
  endExceptionDate?: string;
  selectedDays?: unknown;
  isUpdateAndClose?: boolean;
  isCreatedFromPlanned: boolean;
  isCreatedFromTerminal: boolean;
  transportationDate: string;
  agreedPrice?: Maybe<number>;
  currency: string;
  note: string;
  externalNote: string;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  capacity?: Maybe<number>;
  kilometer?: Maybe<number>;
  weight?: Maybe<number>;
  routeId: string;
  invoiceNumber: Maybe<string> | undefined;
  invoiceNote: Maybe<string> | undefined;
  subcontractorId?: number;
  dispatcherId?: number;
  costAllocation?: Maybe<{
    id?: number;
    items: CostAllocationItemInterface[];
  }>;
  files: {
    id: number;
    originalname: string;
    path: string;
    createdBy: string;
    createdAt: Date;
  }[];
  ftl:
    | {
        cdc: string;
        cmr: string;
        price: number;
        currency: string;
        customerId?: Maybe<number>;
        customerNumber: string;
        customerReference: string;
        invoiceNote: string;
      }
    | undefined;
  legs: LegFieldInput[];
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '100%',
    margin: theme.spacing(1),
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(1, 'auto'),
  },
  subResources: {
    margin: theme.spacing(1, 'auto'),
    width: '95%',
  },
  button: {
    margin: theme.spacing(1, 'auto'),
  },
  formContent: {
    margin: theme.spacing(1, 0, 2, 1),
    padding: theme.spacing(0, 2, 2, 2),
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      padding: theme.spacing(1, 0),
    },

    '& button:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface RouteFormProps<T> {
  initialValues: T;
  onSubmit: (values: T) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
  includeInvoice?: boolean;
  terminal?: boolean;
  hideCostAllocation?: boolean;
  validationSchema: ObjectSchema<ObjectShape>;
  fromCreateRoute?: string;
  formRef?: any;
  legIdForLoadingList?: number;
  enableEditMode?: boolean;
}

export function RouteForm<T extends SporadicRouteFormInput>(
  props: RouteFormProps<T>,
) {
  const {
    initialValues,
    onSubmit,
    submitButtonLabel,
    readOnly = false,
    includeInvoice = false,
    terminal = false,
    hideCostAllocation = false,
    validationSchema,
    fromCreateRoute,
    formRef = null,
    legIdForLoadingList,
    enableEditMode,
  } = props;
  const classes = useStyle();
  const { t } = useTranslation();
  const user = getUserName();
  const MAX_ROUTES_ALLOWED = 365;
  const SELECTED_DATE_FORMAT = 'dd-MMM-yyyy';
  const FROM_CREATE_PLANNED = 'CreateMultipleSporadicRoute';

  const collapseSections = useCallback(() => {
    if (localStorage.getItem('collapseTruckSections_' + user) === 'true') {
      return true;
    } else {
      return false;
    }
  }, [localStorage.getItem('collapseTruckSections_' + user)]);
  const [aggreementOpen, setAggreementOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [noteOpen, setNoteOpen] = useState<boolean>(!collapseSections());
  const [subcontractorOpen, setSubcontractorOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [driverOpen, setDriverOpen] = useState<boolean>(!collapseSections());
  const terminalORreadonly = terminal || readOnly;

  const [costAllocationOpen, setCostAllocationOpen] = useState<boolean>(false);
  const [filesOpen, setFilesOpen] = useState<boolean>(true);
  const [ftlOpen, setFtlOpen] = useState<boolean>(
    initialValues && initialValues.ftl ? true : false,
  );
  const [submitAfterConfirmation, setIsSubmitConfirmation] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [alertError, setAlertError] = useState<boolean>(false);
  const [alertLoading, setAlertLoading] = useState<boolean>(false);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState('dr');

  function calculateDatesAndSubmit<
    T extends {
      routeId: string;
      dateType?: string;
      startDate?: string;
      endDate?: string;
      hasExceptionDates?: boolean;
      startExceptionDate?: string;
      endExceptionDate?: string;
      selectedDays?: unknown;
      selectedCalDates?: string[];
    },
  >(onSubmit: (values: T) => void): (values: T) => void {
    return (values: T) => {
      const routeId = values.routeId.trim().replaceAll('  ', ' ').toUpperCase();
      let finalDatesArr: string[] = [];
      const finalDays: number[] = [];

      //calculate dates
      if (values.dateType && values.dateType === 'dr') {
        const startDate =
          values.startDate && values.startDate != ''
            ? new Date(values.startDate)
            : null;
        const endDate =
          values.endDate && values.endDate != ''
            ? new Date(values.endDate)
            : null;

        //
        const selDays: any = values.selectedDays ?? null;
        if (selDays) {
          for (const key in selDays) {
            if (key && Boolean(selDays[key]) === true) {
              finalDays.push(getDayNumber(key.toString()));
            }
          }
        }

        if (startDate != null && endDate != null) {
          for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            const loopDay = new Date(d);

            if (values.hasExceptionDates) {
              const startExcDate =
                values.startExceptionDate && values.startExceptionDate != ''
                  ? new Date(values.startExceptionDate)
                  : null;
              const endExcDate =
                values.endExceptionDate && values.endExceptionDate != ''
                  ? new Date(values.endExceptionDate)
                  : null;

              if (
                startExcDate != null &&
                endExcDate != null &&
                !isWithinInterval(loopDay, {
                  start: startExcDate,
                  end: endExcDate,
                }) &&
                finalDays.includes(loopDay.getDay())
              ) {
                finalDatesArr.push(format(loopDay, SELECTED_DATE_FORMAT));
              }
            } else if (finalDays.includes(loopDay.getDay())) {
              finalDatesArr.push(format(loopDay, SELECTED_DATE_FORMAT));
            }
          }
        }
      } else {
        finalDatesArr = values.selectedCalDates ?? [];
      }

      if (finalDatesArr.length === 0) {
        //no proper date
        setErrorMessage(`${t('validation.plannedRoutesErrorDate')}`);
        setAlertError(true);
      } else if (finalDatesArr.length > MAX_ROUTES_ALLOWED) {
        //too long range
        setErrorMessage(`${t('validation.plannedRoutesErrorRange')}`);
        setAlertError(true);
      } else {
        const newValues = {
          ...values,
          routeId,
          selectedCalDates: finalDatesArr,
        };
        if (submitAfterConfirmation === false) {
          setIsSubmitConfirmation(true);
          setOpenCreateDialog(true);
        } else {
          setAlertLoading(true);
          onSubmit(newValues);
          setIsSubmitConfirmation(false);
        }
      }
    };
  }

  return (
    <Paper className={classes.formContent}>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnBlur
        validateOnChange={false}
        validate={(value) => {
          try {
            validateYupSchema(value, validationSchema, true, value);
          } catch (err) {
            const errors = yupToFormErrors<{ costAllocation: string }>(err); //for rendering validation errors
            if (errors?.costAllocation) {
              setCostAllocationOpen(true);
            }
            return errors;
          }
          return {};
        }}
        onSubmit={
          fromCreateRoute === FROM_CREATE_PLANNED
            ? calculateDatesAndSubmit(onSubmit)
            : normalizeRouteIdForRoute(onSubmit)
        }
      >
        {(props) => (
          <SupressEnterForm
            onSubmit={props.handleSubmit}
            className={classes.root}
          >
            <Grid container>
              <AutoReset readOnly={readOnly} />
              {fromCreateRoute === FROM_CREATE_PLANNED ? (
                <Grid item xs={12}>
                  <div>
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={alertError}
                      key={'alertError'}
                      autoHideDuration={3000}
                      onClose={() => {
                        setAlertError(false);
                      }}
                    >
                      <Alert
                        onClose={() => {
                          setAlertError(false);
                        }}
                        severity="error"
                      >
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                    <Snackbar
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      open={alertLoading}
                      key={'alertLoading'}
                    >
                      <Alert severity="info">
                        {`${t('validation.loadingApi')}`}
                      </Alert>
                    </Snackbar>
                    <DialogConfirmationModal
                      open={openCreateDialog}
                      setOpen={setOpenCreateDialog}
                      contentText={`${t('validation.confirmation', {
                        action: t('actions.create.lowercased'),
                        item: ' routes',
                      })}`}
                      doAction={() => {
                        props.handleSubmit();
                      }}
                      cancelAction={() => {
                        props.setSubmitting(false);
                        setIsSubmitConfirmation(false);
                        setOpenCreateDialog(false);
                      }}
                      buttonText={t('button.continue')}
                    />
                  </div>
                  <Paper className={classes.formContent}>
                    <Grid container alignContent="flex-start">
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={selectedValue}
                        onChange={(event) => {
                          setSelectedValue(event.target.value);
                          props.setFieldValue('dateType', event.target.value);
                          if (event.target.value === 'dr') {
                            props.setFieldValue('selectedCalDates', []);
                          } else if (event.target.value === 'cd') {
                            props.setFieldValue('startDate', '');
                            props.setFieldValue('endDate', '');
                            props.setFieldValue('startExceptionDate', '');
                            props.setFieldValue('endExceptionDate', '');
                          }
                        }}
                      >
                        <FormControlLabel
                          value="dr"
                          control={
                            <Radio
                              value="dr"
                              name="radio1"
                              size="small"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#69bc48',
                                },
                              }}
                            />
                          }
                          label={`Select Date range & Days`}
                        />
                        <FormControlLabel
                          value="cd"
                          control={
                            <Radio
                              value="cd"
                              name="radio2"
                              size="small"
                              sx={{
                                '&.Mui-checked': {
                                  color: '#69bc48',
                                },
                              }}
                            />
                          }
                          label={`Choose from calendar`}
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid item>
                      {selectedValue === 'dr' ? (
                        <>
                          <DatesFields readOnly={false} />
                          <DaysFieldForPlannedRoutes />
                        </>
                      ) : (
                        <Grid item container alignItems="flex-start">
                          <DatePickerMultiple />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              ) : null}

              <Grid item xs={6}>
                <AccordionContainer
                  open={aggreementOpen}
                  errors={[props.errors.transportationDate]}
                  setOpen={setAggreementOpen}
                  accordionTitle={t('attributeGrouping.agreement')}
                >
                  <RouteAgreementFields
                    terminal={terminal}
                    trafficReadOnly={terminalORreadonly}
                    classes={classes}
                    template={fromCreateRoute === FROM_CREATE_PLANNED}
                  />
                </AccordionContainer>

                <AccordionContainer
                  open={noteOpen}
                  setOpen={setNoteOpen}
                  accordionTitle={t('attributeGrouping.note')}
                >
                  <CenteredBox>
                    <CommonTextField
                      className={classes.field}
                      name="note"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={terminalORreadonly}
                    />
                  </CenteredBox>
                  <CenteredBox>
                    <CommonTextField
                      className={classes.field}
                      name="externalNote"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={terminalORreadonly}
                    />
                  </CenteredBox>
                </AccordionContainer>
              </Grid>
              <Grid item xs={6}>
                <AccordionContainer
                  open={subcontractorOpen}
                  errors={[props.errors.subcontractorId]}
                  setOpen={setSubcontractorOpen}
                  accordionTitle={t('resource.subcontractor.capitalized')}
                >
                  <SubcontractorFields
                    readOnly={readOnly}
                    fromTerminal={terminal}
                  />
                </AccordionContainer>
                {includeInvoice && !terminal && (
                  <AccordionContainer
                    open={driverOpen}
                    setOpen={setDriverOpen}
                    accordionTitle={`${t('attributeGrouping.invoice')}`}
                  >
                    <>
                      <Typography
                        variant="subtitle1"
                        className={classes.categoryHeader}
                        align="left"
                      >
                        {t('attributeGrouping.invoice')}
                      </Typography>
                      <CommonTextField
                        name="invoiceNumber"
                        className={classes.field}
                        readOnly={terminalORreadonly}
                      />
                      <CommonTextField
                        name="invoiceNote"
                        className={classes.field}
                        readOnly={terminalORreadonly}
                      />
                    </>
                  </AccordionContainer>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.buttonWrapper}>
                  {!terminal &&
                    !hideCostAllocation &&
                    !(
                      readOnly &&
                      props.values.costAllocation?.items.length === 0
                    ) && (
                      <Tooltip
                        title={`${t('button.view', {
                          item: t('resource.costAllocation.lowercased'),
                        })}`}
                      >
                        <IconButton
                          color={costAllocationOpen ? 'primary' : undefined}
                          onClick={() => {
                            setCostAllocationOpen(!costAllocationOpen);
                          }}
                        >
                          <AttachMoneyIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  {!(readOnly && props.values.files.length === 0) && (
                    <Tooltip
                      title={`${t('button.view', {
                        item: t('resource.documentFile.plural'),
                      })}`}
                    >
                      <IconButton
                        color={filesOpen ? 'primary' : undefined}
                        onClick={() => {
                          setFilesOpen(!filesOpen);
                        }}
                      >
                        <DescriptionIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!(readOnly && props.values.ftl == null) && (
                    <Tooltip
                      title={`${t('button.view', {
                        item: t('resource.routeFtl.plural'),
                      })}`}
                    >
                      <IconButton
                        color={ftlOpen ? 'primary' : undefined}
                        onClick={() => {
                          if (!Boolean(props.values.ftl)) {
                            props.setFieldValue('ftl', createFTL());
                          }
                          setFtlOpen(!ftlOpen);
                        }}
                      >
                        <StorefrontIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <HideableFormFields
                  setOpen={setFtlOpen}
                  show={ftlOpen}
                  title={t('resource.routeFtl.capitalized')}
                  onHideClicked={() => setFtlOpen(false)}
                >
                  <RouteFTLFields readOnly={terminalORreadonly} />
                </HideableFormFields>
              </Grid>

              <Grid item xs={12}>
                <FilesField
                  files={props.values.files ?? []}
                  show={filesOpen}
                  readOnly={terminalORreadonly}
                  setOpen={setFilesOpen}
                  onHideClicked={() => setFilesOpen(false)}
                  onUpdate={(files) => {
                    props.setFieldValue('files', files);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CostAllocationFields
                  setOpen={setCostAllocationOpen}
                  show={!hideCostAllocation && costAllocationOpen}
                  onHideClicked={() => setCostAllocationOpen(false)}
                  fieldsPath="costAllocation"
                  readOnly={readOnly}
                />
              </Grid>

              <AggregatesLoadingList
                loadingListItems={props.values.legs.flatMap(
                  (leg) => leg.loadingListItems,
                )}
                capacity={props.values.capacity}
              />
              <Grid item xs={12}>
                <Divider />

                <Box mt="5px">
                  <LegsFields
                    trafficReadOnly={terminalORreadonly}
                    readOnly={readOnly}
                    fieldsPath="legs"
                    fromCreateRoute={fromCreateRoute}
                    isFromTerminal={terminal}
                    legIdForLoadingList={legIdForLoadingList}
                    enableEditMode={enableEditMode}
                  />
                </Box>
              </Grid>
            </Grid>
            {!readOnly && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  className={classes.button}
                  onClick={() => {
                    props.setFieldValue('isUpdateAndClose', false);
                    props.handleSubmit();
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    fromCreateRoute === FROM_CREATE_PLANNED
                      ? alertLoading
                      : props.isSubmitting
                  }
                >
                  {submitButtonLabel}
                </Button>
              </Grid>
            )}
          </SupressEnterForm>
        )}
      </Formik>
    </Paper>
  );
}
