import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  GridCellParams,
  GridRenderCellParams,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { add, format, isMatch } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { AddTransport, COUNTRIES } from '../components/AddTransport';
import { SelectLocationCell } from '../components/datagrid/SelectLocationCell';
import { SelectSubcontractorCell } from '../components/datagrid/SelectSubcontractorCell';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { DialogModal } from '../components/DialogModal';
import { DialogModalDebit } from '../components/DialogModalDebit';
import { DownloadPeakPlanningList } from '../components/DownloadPeakPlanningList';
import { HelmetComponent } from '../components/HelmetComponent';
import { SelectDateRangeForm } from '../components/SelectDateRangeForm';
import {
  GetPeakPlanningDebitsDocument,
  GetPeakPlanningItemsDocument,
  ListRoutesDocument,
  PeakPlanningProductType,
  PeakPlanningStatus,
  RouteType,
  useCreatePeakPlanningDebitMutation,
  useCreateSporadicRouteWithoutDispatcherMutation,
  useDeletePeakPlanningItemMutation,
  useGetPeakPlanningItemsLazyQuery,
  useUpdatePeakPlanningItemMutation,
} from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { DATE_FORMAT } from '../lib/date_time';
import { calculateTransportationAndProductionDates } from '../lib/route_leg_dates';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';

const DEPARTURE_LOCATION_POSITION = 1;
const LOADING_LOCATION_1_POSITION = 2;
const LOADING_LOCATION_2_POSITION = 3;
const ARRIVAL_LOCATION_POSITION = 4;

function getValueOrText(text: string) {
  return (params: GridValueFormatterParams) => {
    const { value } = params;
    if (value == null) {
      return text;
    }

    return value;
  };
}

function getBooked(params: GridValueGetterParams) {
  const value = params.row.generated;
  return value;
}

function getCountry(params: GridValueGetterParams) {
  const country = params.row.productCountry;
  return country
    ? COUNTRIES[country as keyof typeof COUNTRIES].toLocaleUpperCase()
    : undefined;
}

function getDepartureCountry(params: GridValueGetterParams) {
  const value = params.row.peakPlanningLocations;
  const departureLocation = value.find(
    (x: { position: number }) => x.position === 1,
  );
  const departureCountry = departureLocation?.location?.country;
  return departureCountry
    ? COUNTRIES[departureCountry as keyof typeof COUNTRIES].toLocaleUpperCase()
    : 'Set departure location';
}

function getPeakPlanningLocationCity(position: number) {
  return (params: GridValueGetterParams) => {
    const value = params.row.peakPlanningLocations;
    const peakPlanningLocation = value.find(
      (x: { position: number }) => x.position === position,
    );
    return peakPlanningLocation
      ? peakPlanningLocation.location?.city
      : undefined;
  };
}

function getArrivalCountry(params: GridValueGetterParams) {
  const value = params.row.peakPlanningLocations;
  const arrivalLocation = value.find(
    (x: { position: number }) => x.position === 4,
  );
  const arrivalCountry = arrivalLocation?.location?.country;
  return arrivalCountry
    ? COUNTRIES[arrivalCountry as keyof typeof COUNTRIES].toLocaleUpperCase()
    : undefined;
}

function getDebitLines(params: GridValueGetterParams) {
  const sporadicRoute = params.row.sporadicRoute;
  if (sporadicRoute == null) {
    return undefined;
  }
  return sporadicRoute.debitsCount ? sporadicRoute.debitsCount : undefined;
}

function getLoadingLocationName(position: number) {
  return (params: GridValueGetterParams) => {
    const sporadicRoutePosition = position - 1;
    const sporadicRoute = params.row.sporadicRoute;
    if (sporadicRoute != null && sporadicRoute.legs != null) {
      const routeLeg = sporadicRoute.legs.find(
        (x: { position: number }) => x.position === sporadicRoutePosition,
      );
      if (routeLeg != undefined && routeLeg.location != undefined) {
        const country = routeLeg.location?.country
          ? COUNTRIES[
              routeLeg.location?.country as keyof typeof COUNTRIES
            ].toLocaleUpperCase()
          : '';
        return `${routeLeg.location?.name} - ${routeLeg.location?.address} - ${routeLeg.location?.city},  ${country}`;
      }
    }
    const value = params.row.peakPlanningLocations;
    const loadingLocation = value.find(
      (x: { position: number }) => x.position === position,
    );
    const loadingLocationCountry =
      loadingLocation &&
      loadingLocation.location &&
      loadingLocation.location?.country
        ? COUNTRIES[
            loadingLocation.location?.country as keyof typeof COUNTRIES
          ].toLocaleUpperCase()
        : '';
    return loadingLocation
      ? `${loadingLocation.location?.name} - ${loadingLocation.location?.address} - ${loadingLocation.location?.city},  ${loadingLocationCountry}`
      : undefined;
  };
}
function getLocationLoadStart(position: number) {
  return (params: GridValueGetterParams) => {
    const sporadicRoutePosition = position - 1;
    const sporadicRoute = params.row.sporadicRoute;
    if (sporadicRoute != null && sporadicRoute.legs != null) {
      const routeLeg = sporadicRoute.legs.find(
        (x: { position: number }) => x.position === sporadicRoutePosition,
      );
      if (routeLeg != undefined && routeLeg.location != undefined) {
        return routeLeg.arrivalTime;
      }
    }
    const value = params.row.peakPlanningLocations;
    const loadingLocation = value.find(
      (x: { position: number }) => x.position === position,
    );
    return loadingLocation ? loadingLocation.loadStart : undefined;
  };
}

function getLocationLoadEnd(position: number) {
  return (params: GridValueGetterParams) => {
    const sporadicRoutePosition = position - 1;
    const sporadicRoute = params.row.sporadicRoute;
    if (sporadicRoute != null && sporadicRoute.legs != null) {
      const routeLeg = sporadicRoute.legs.find(
        (x: { position: number }) => x.position === sporadicRoutePosition,
      );
      if (routeLeg != undefined && routeLeg.location != undefined) {
        return routeLeg.departureTime;
      }
    }
    const value = params.row.peakPlanningLocations;
    const loadingLocation = value.find(
      (x: { position: number }) => x.position === position,
    );
    return loadingLocation ? loadingLocation.loadEnd : undefined;
  };
}

function getLocationBookedPPL(position: number) {
  return (params: GridValueGetterParams) => {
    const sporadicRoutePosition = position - 1;
    const sporadicRoute = params.row.sporadicRoute;
    if (sporadicRoute != null && sporadicRoute.legs != null) {
      const routeLeg = sporadicRoute.legs.find(
        (x: { position: number }) => x.position === sporadicRoutePosition,
      );
      if (routeLeg != undefined && routeLeg.location != undefined) {
        return routeLeg.bookedPPL;
      }
    }
    const value = params.row.peakPlanningLocations;
    const loadingLocation = value.find(
      (x: { position: number }) => x.position === position,
    );
    return loadingLocation ? loadingLocation.bookedPPL : undefined;
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .low': {
      color: theme.palette.error.main,
    },
    '& .medium': {
      color: theme.palette.secondary.main,
    },
    '& .high': {
      color: theme.palette.primary.dark,
    },
    '& .booked': {
      backgroundColor: '#7BC144',
    },
    '& .unEditable': {
      backgroundColor: '#F0F0F0',
      opacity: 0.6,
    },
    '& .departureLocation': {
      backgroundColor: theme.palette.info.main,
    },
    '& .loadingLocation1': {
      backgroundColor: theme.palette.info.light,
    },
    '& .loadingLocation2': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .arrivalLocation': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& .noOpacity': {
      opacity: '1 !important',
    },
  },
}));

const planningLocationAttributes = [
  'locationId',
  'loadStart',
  'loadEnd',
  'bookedPPL',
];

const findPlanningLocationPosition = (field: string): number | undefined => {
  const plAttributes = {
    departure: DEPARTURE_LOCATION_POSITION,
    loadingLocation1: LOADING_LOCATION_1_POSITION,
    loadingLocation2: LOADING_LOCATION_2_POSITION,
    arrival: ARRIVAL_LOCATION_POSITION,
  };

  const postition = Object.entries(plAttributes).find(([key]) =>
    field.startsWith(key),
  );
  return postition != null ? postition[1] : undefined;
};

const getattributeFromField = (field: string): string | undefined => {
  return planningLocationAttributes.find((x) => field.endsWith(x));
};

export function PeakPlanning() {
  const { t } = useTranslation();
  const styles = useStyles();
  const apiRef = useGridApiRef();
  const router = useHistory();
  const [addTransportOpen, setAddTransportOpen] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(
    format(new Date(), DATE_FORMAT),
  );
  const [endDate, setEndDate] = useState<string>(
    format(add(new Date(), { months: 3 }), DATE_FORMAT),
  );
  const [getPeakPlanningItems, { data, loading, error }] =
    useGetPeakPlanningItemsLazyQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    });

  //For CreateDebit items
  const [openDebitDialog, setOpenDebitDialog] = useState<boolean>(false);
  const [debitPeakPlanningLocations, setDebitPeakPlanningLocations] = useState<
    { location: { name: string }; locationId: number; id: number }[]
  >([]);
  const [selectedCreateDebitRow, setSelectedCreateDebitRow] = useState<any>();
  const [debitLocation, setDebitLocation] = useState<any>();
  const [peakPlanningItemToDelete, setPeakPlanningItemToDelete] =
    useState<any>();
  const [openAlertMessage, setOpenAlertMessage] = useState<string | null>(null);
  const { department } = useUserConfiguration();
  const [departmentId, setDepartmentId] = useState<string>(department.id);
  const [updatePeakPlanningItem] = useUpdatePeakPlanningItemMutation({
    refetchQueries: [
      {
        query: GetPeakPlanningItemsDocument,
        variables: { startDate, endDate, departmentId },
      },
    ],
  });

  const [createPeakPlanningDebit] = useCreatePeakPlanningDebitMutation({
    refetchQueries: [
      {
        query: GetPeakPlanningDebitsDocument,
        variables: {
          startDate: format(add(new Date(), { months: -3 }), DATE_FORMAT),
          endDate: format(new Date(), DATE_FORMAT),
        },
      },
      {
        query: GetPeakPlanningItemsDocument,
        variables: { startDate, endDate, departmentId },
      },
    ],
  });
  const [deletePeakPlanningItem] = useDeletePeakPlanningItemMutation({
    refetchQueries: [
      {
        query: GetPeakPlanningItemsDocument,
        variables: { startDate, endDate, departmentId },
      },
    ],
  });
  const onDateSelected = React.useCallback(
    (values) => {
      if (isMatch(startDate, DATE_FORMAT) && isMatch(endDate, DATE_FORMAT)) {
        setStartDate(values.startDate);
        setEndDate(values.endDate);
      }
    },
    [startDate, endDate],
  );

  useEffect(() => {
    if (isMatch(startDate, DATE_FORMAT) && isMatch(endDate, DATE_FORMAT)) {
      getPeakPlanningItems({
        variables: { startDate, endDate, departmentId },
      });
    }
  }, [startDate, endDate, departmentId]);

  const [createSporadicRouteWithoutDispatcher] =
    useCreateSporadicRouteWithoutDispatcherMutation({
      refetchQueries: [
        {
          query: GetPeakPlanningItemsDocument,
        },
        {
          query: ListRoutesDocument,
          variables: {
            type: RouteType.Sporadic,
          },
        },
      ],
    });

  const handleCellEditCommit = React.useCallback(
    ({ id, field, value }) => {
      const peakPlanningItem = data?.peakPlanningItems.find(
        (peakPlanningItem) => peakPlanningItem.id === id,
      );

      if (value == null) {
        return;
      }
      if (
        planningLocationAttributes.some((x) => {
          return field.endsWith(x);
        })
      ) {
        const peakPlanningLocations = peakPlanningItem?.peakPlanningLocations;
        const position = findPlanningLocationPosition(field);
        const parsedField = getattributeFromField(field);
        const peakPlanningLocation =
          peakPlanningItem?.peakPlanningLocations.find(
            (x) => position === x.position,
          );
        if (
          parsedField != null &&
          peakPlanningLocations != null &&
          position != null
        ) {
          if (field.endsWith('locationId') && value == null) {
            return;
          }
          updatePeakPlanningItem({
            variables: {
              id,
              input: {
                peakPlanningLocations: peakPlanningLocation
                  ? peakPlanningLocations.map((ppLocation) => {
                      //If it is choosen location with existing entity in backend
                      if (peakPlanningLocation.id == ppLocation.id) {
                        if (
                          field.endsWith('locationId') &&
                          typeof value === 'string'
                        ) {
                          //Filter out if locationId gets delivered as string
                          return {
                            ...ppLocation,
                            location: undefined,
                            __typename: undefined,
                          };
                        }
                        return {
                          ...ppLocation,
                          [parsedField]: value,
                          location: undefined,
                          __typename: undefined,
                        };
                      }
                      //Return all fields for existing entity but not the edited one
                      return {
                        ...ppLocation,
                        location: undefined,
                        __typename: undefined,
                      };
                    })
                  : [
                      ...peakPlanningLocations.map(
                        (existingPlanningLocation) => {
                          return {
                            ...existingPlanningLocation,
                            __typename: undefined,
                            location: undefined,
                          };
                        },
                      ),
                      //New peakPlanningLocation
                      {
                        position: position,
                        [parsedField]: value,
                      },
                    ],
              },
            },
          });
        }
      } else {
        if (field === 'subcontractor') {
          updatePeakPlanningItem({
            variables: {
              id,
              input: {
                subcontractorId: value.id,
              },
            },
          });
          return;
        }
        if (field === 'productCountry') {
          updatePeakPlanningItem({
            variables: {
              id,
              input: {
                productCountry: Object.keys(COUNTRIES).find(
                  (key) => COUNTRIES[key as keyof typeof COUNTRIES] === value,
                ),
              },
            },
          });
        } else {
          updatePeakPlanningItem({
            variables: {
              id,
              input: {
                [field]:
                  field === 'productionDate'
                    ? typeof value === 'string'
                      ? value
                      : format(value, DATE_FORMAT)
                    : value,
              },
            },
          });
        }
      }
    },
    [data],
  );
  const handleCreateDebit = () => {
    createPeakPlanningDebit({
      variables: {
        input: {
          sporadicRouteId: selectedCreateDebitRow.sporadicRoute.id,
          productionDate: selectedCreateDebitRow.productionDate,
          customerId: selectedCreateDebitRow.subcontractorId,
          destinationCountry: debitLocation.location.country,
          bookedPPL: debitLocation.loadingListItems[0].palletSpace || undefined,
          locationId: debitLocation.locationId,
        },
      },
    }),
      router.push('/traffic/debit');
  };

  const handleCreateSporadicRoute = (params: GridCellParams) => {
    if (params.row.subcontractor == null) {
      setOpenAlertMessage('Peakplanning item is missing subcontractor');
      return;
    }

    if (
      params.row.peakPlanningLocations.some(
        (p: { loadStart: Date }) => p.loadStart == null,
      )
    ) {
      setOpenAlertMessage('Peakplanning location is missing start date');
      return;
    }

    const legsWithTransportationDate =
      calculateTransportationAndProductionDates(
        params.row.peakPlanningLocations.map(
          (leg: {
            loadStart: Date;
            loadEnd: Date;
            bookedPPL: number;
            location: {
              id: number;
            };
            position: number;
          }) => ({
            ...leg,
            arrivalTime: leg.loadStart,
            departureTime: leg.loadEnd,
          }),
        ),
        params.row.productionDate,
      );
    createSporadicRouteWithoutDispatcher({
      variables: {
        input: {
          isCreatedFromPlanned: false,
          isCreatedFromTerminal: false,
          transportationDate: params.row.productionDate,
          agreedPrice: 0,
          currency: 'dkk',
          note: params.row.note,
          externalNote: '',
          driverName: '',
          driverPhoneNumber: '',
          licensePlate: '',
          capacity: params.row.capacity,
          routeId: '',
          subcontractorId: params.row?.subcontractorId as number,
          ftl: undefined,
          files: [],
          legs: params.row.peakPlanningLocations.map(
            (
              peakPlanningLocation: {
                loadStart: Date;
                loadEnd: Date;
                bookedPPL: number;
                location: {
                  id: number;
                };
                position: number;
              },
              index: number,
            ) => ({
              load: peakPlanningLocation.position < 4 ? true : false,
              unload: false,
              note: '',
              arrivalTime: peakPlanningLocation.loadStart || '00:00',
              departureTime: peakPlanningLocation.loadEnd,
              position: peakPlanningLocation.position,
              locationId: peakPlanningLocation.location.id as number,
              productionDate: legsWithTransportationDate[index].productionDate,
              transportationDate:
                legsWithTransportationDate[index].transportationDate,
              transportationDateOffset: 0,
              location: undefined,
              loadingListItems: [
                {
                  palletSpace: peakPlanningLocation.bookedPPL || 0,
                  locationId: peakPlanningLocation.location.id,
                  note: '',
                  checked: false,
                  pallets: 0,
                  packages: 0,
                  weight: 0,
                },
              ],
            }),
          ),
        },
      },
    }).then((res) => {
      updatePeakPlanningItem({
        variables: {
          id: params.row.id,
          input: {
            status: PeakPlanningStatus.Booked,
            sporadicRouteId:
              res.data?.createSporadicRouteWithoutDispatcher.id || undefined,
          },
        },
      });
      const win = window.open(
        `/traffic/sporadic-routes${
          res.data?.createSporadicRouteWithoutDispatcher.id
            ? `/${res.data?.createSporadicRouteWithoutDispatcher.id}`
            : ''
        }`,
        '_blank',
      );
      if (win != null) {
        win.focus();
      }
    });
  };

  if (error) {
    console.error(error);
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }
  return (
    <Grid container>
      <HelmetComponent title={t('resource.peakPlanning.capitalized')} />
      {addTransportOpen && (
        <AddTransport
          setAddTransportOpen={setAddTransportOpen}
          startDate={startDate}
          endDate={endDate}
          departmentId={departmentId}
        />
      )}
      <Grid item container>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="180%"
          mb={2}
        >
          <Typography variant="h2">
            {t('resource.peakPlanning.capitalized')}
          </Typography>
          <SelectDateRangeForm
            startDate={startDate}
            endDate={endDate}
            onDateSelected={onDateSelected}
            buttonText={'Filter'}
          />
          <Select
            labelId="selectDepartment"
            id="selectDepartment"
            value={departmentId}
            label={t('resource.department.capitalized')}
            onChange={(event) => {
              setDepartmentId(event.target.value as string);
            }}
          >
            <MenuItem value={'dk'}>Denmark</MenuItem>
            <MenuItem value={'se'}>Sweden</MenuItem>
          </Select>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box mr={2}>
              <DownloadPeakPlanningList
                startDate={startDate}
                endDate={endDate}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                color={addTransportOpen ? 'secondary' : 'primary'}
                onClick={() => {
                  setAddTransportOpen(!addTransportOpen);
                }}
                startIcon={addTransportOpen ? <CloseIcon /> : <AddIcon />}
              >
                {!addTransportOpen
                  ? t('button.add', {
                      item: 'transport',
                    })
                  : 'cancel'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} className={styles.root}>
        <UserConfiguredDataGridPro
          tableName={TABLE_NAMES.PeakPlanning}
          onCellEditCommit={handleCellEditCommit}
          rows={data?.peakPlanningItems || []}
          apiRef={apiRef}
          isCellEditable={({ field, row }) => {
            if (row.generated) {
              return false;
            }
            if (
              [
                'departureloadEnd',
                'departureloadStart',
                'departurebookedPPL',
              ].includes(field) &&
              !row.peakPlanningLocations.find(
                (x: { position: number }) => x.position === 1,
              )
            ) {
              return false;
            }
            if (
              [
                'loadingLocation1loadEnd',
                'loadingLocation1loadStart',
                'loadingLocation1bookedPPL',
              ].includes(field) &&
              !row.peakPlanningLocations.find(
                (x: { position: number }) => x.position === 2,
              )
            ) {
              return false;
            }
            if (
              [
                'loadingLocation2loadEnd',
                'loadingLocation2loadStart',
                'loadingLocation2bookedPPL',
              ].includes(field) &&
              !row.peakPlanningLocations.find(
                (x: { position: number }) => x.position === 3,
              )
            ) {
              return false;
            }
            if (
              [
                'arrivalLocationloadEnd',
                'arrivalLocationloadStart',
                'arrivalLocationbookedPPL',
              ].includes(field) &&
              !row.peakPlanningLocations.find(
                (x: { position: number }) => x.position === 4,
              )
            ) {
              return false;
            }
            return true;
          }}
          getRowClassName={(params: GridRowParams) => {
            return params.row.generated ? 'unEditable' : '';
          }}
          getCellClassName={(params: GridCellParams) => {
            if (params.field === 'utilization') {
              const valueFormatted = Number((params.value as number) * 100);
              if (valueFormatted < 40) return 'low';
              if (valueFormatted < 70) return 'medium';
              return 'high';
            }
            if (params.field === 'booked') {
              return params.value ? 'booked' : '';
            }
            return '';
          }}
          columns={[
            {
              field: 'id',
              type: 'number',
              headerName: t(`attributes.id`),
              width: 180,
            },
            {
              field: 'note',
              headerName: t('attributes.note'),
              width: 180,
              type: 'string',
              editable: true,
            },
            {
              field: 'status',
              headerName: t('attributes.status'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values(PeakPlanningStatus),
              editable: true,
            },
            {
              field: 'booked',
              headerName: t('attributes.booked'),
              type: 'boolean',
              width: 120,
              valueGetter: getBooked,
            },
            {
              field: 'responsible',
              headerName: t('attributes.responsible'),
              width: 180,
              type: 'string',
              editable: true,
            },
            {
              field: 'routeId',
              headerName: t('attributes.routeId'),
              width: 180,
              type: 'string',
              renderCell: (params) => {
                return params.row.sporadicRoute ? (
                  <Link
                    to={`/traffic/sporadic-routes/${params.row.sporadicRoute.id}`}
                    target={'_blank'}
                  >
                    {params.row.sporadicRoute.routeId}
                  </Link>
                ) : (
                  ''
                );
              },
            },
            {
              field: 'productType',
              headerName: t('attributes.productType'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values(PeakPlanningProductType),
              editable: true,
            },
            {
              field: 'productCountry',
              headerName: t('attributes.productCountry'),
              width: 180,
              type: 'singleSelect',
              valueOptions: Object.values(COUNTRIES),
              editable: true,
              valueGetter: getCountry,
            },
            {
              field: 'productionDate',
              headerName: t('attributes.productionDate'),
              width: 180,
              type: 'date',
              editable: true,
            },

            {
              field: 'departureCountry',
              headerName: t('attributes.departureCountry'),
              width: 180,
              type: 'string',
              valueGetter: getDepartureCountry,
              valueFormatter: getValueOrText('Set arrival country'),
            },
            {
              field: 'arrivalCountry',
              headerName: t('attributes.arrivalCountry'),
              width: 180,
              type: 'string',
              valueGetter: getArrivalCountry,
              valueFormatter: getValueOrText('Set arrival country'),
            },
            {
              field: 'subcontractor',
              headerName: t('attributes.subcontractorId'),
              width: 180,
              editable: true,
              valueGetter: (params) => {
                if (params.row.subcontractor == null) {
                  return '';
                }
                return params.row.subcontractor.name;
              },
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  subcontractor?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: subcontractor,
                  });
                };
                return (
                  <SelectSubcontractorCell
                    onCellChange={updateCellValue}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'capacity',
              headerName: t('attributes.capacity'),
              width: 180,
              type: 'number',
              editable: true,
              valueFormatter({ value }) {
                if (value == null) {
                  return '';
                }

                return value;
              },
            },
            {
              field: 'departureCity',
              headerName: t('attributes.departureCity'),
              width: 180,
              valueGetter: getPeakPlanningLocationCity(
                DEPARTURE_LOCATION_POSITION,
              ),
              valueFormatter: getValueOrText('Set departure location'),
            },
            {
              field: 'arrivalCity',
              headerName: t('attributes.arrivalCity'),
              width: 180,
              valueGetter: getPeakPlanningLocationCity(4),
              valueFormatter: getValueOrText('Set arrival location'),
            },
            {
              field: 'departurelocationId',
              headerName: t('attributes.departureLocation'),
              width: 180,
              valueGetter: getLoadingLocationName(DEPARTURE_LOCATION_POSITION),
              valueFormatter: getValueOrText('Set departure location'),
              editable: true,
              headerClassName: 'departureLocation',
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  locationId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: locationId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectLocationCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'departureloadStart',
              headerName: t('attributes.loadStart'),
              width: 200,
              valueGetter: getLocationLoadStart(DEPARTURE_LOCATION_POSITION),
              editable: true,
              renderCell: (params) => (
                <TextField
                  id="loadStart"
                  placeholder={t(`attributes.loadStart`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                    readOnly: !params.isEditable,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'departureloadEnd',
              headerName: t('attributes.loadEnd'),
              width: 200,
              valueGetter: getLocationLoadEnd(DEPARTURE_LOCATION_POSITION),
              editable: true,
              renderCell: (params) => (
                <TextField
                  id="loadEnd"
                  placeholder={t(`attributes.loadEnd`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: !params.isEditable,
                    step: 300,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'departurebookedPPL',
              headerName: t('attributes.bookedPPL'),
              width: 200,
              type: 'number',
              editable: true,
              valueGetter: getLocationBookedPPL(DEPARTURE_LOCATION_POSITION),
            },
            {
              field: 'loadingLocation1locationId',
              headerName: 'Loading location 1',
              width: 180,
              editable: true,
              headerClassName: 'loadingLocation1',
              valueGetter: getLoadingLocationName(LOADING_LOCATION_1_POSITION),
              valueFormatter: getValueOrText('Set loading location 1'),
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  locationId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: locationId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectLocationCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'loadingLocation1loadStart',
              headerName: t('attributes.loadStart'),
              width: 200,
              valueGetter: getLocationLoadStart(LOADING_LOCATION_1_POSITION),
              editable: true,
              renderCell: (params) => (
                <TextField
                  id="loadStart"
                  placeholder={t(`attributes.loadStart`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                    readOnly: !params.isEditable,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'loadingLocation1loadEnd',
              headerName: t('attributes.loadEnd'),
              width: 200,
              valueGetter: getLocationLoadEnd(LOADING_LOCATION_1_POSITION),
              editable: true,
              renderCell: (params) => (
                <TextField
                  id="loadEnd"
                  placeholder={t(`attributes.loadEnd`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: !params.isEditable,
                    step: 300,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'loadingLocation1bookedPPL',
              headerName: t('attributes.bookedPPL'),
              width: 200,
              type: 'number',
              editable: true,
              valueGetter: getLocationBookedPPL(LOADING_LOCATION_1_POSITION),
            },
            {
              field: 'loadingLocation2locationId',
              headerName: 'Loading location 2',
              width: 180,
              editable: true,
              headerClassName: 'loadingLocation2',
              valueGetter: getLoadingLocationName(LOADING_LOCATION_2_POSITION),
              valueFormatter: getValueOrText('Set loading location'),
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  locationId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: locationId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectLocationCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'loadingLocation2loadStart',
              headerName: t('attributes.loadStart'),
              width: 200,
              editable: true,
              valueGetter: getLocationLoadStart(LOADING_LOCATION_2_POSITION),
              renderCell: (params) => (
                <TextField
                  id="loadStart"
                  placeholder={t(`attributes.loadStart`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: !params.isEditable,
                    step: 300,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'loadingLocation2loadEnd',
              headerName: t('attributes.loadEnd'),
              width: 200,
              editable: true,
              valueGetter: getLocationLoadEnd(LOADING_LOCATION_2_POSITION),
              renderCell: (params) => (
                <TextField
                  id="loadEnd"
                  placeholder={t(`attributes.loadEnd`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: !params.isEditable,
                    step: 300,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'loadingLocation2bookedPPL',
              headerName: t('attributes.bookedPPL'),
              width: 200,
              type: 'number',
              editable: true,
              valueGetter: getLocationBookedPPL(LOADING_LOCATION_2_POSITION),
            },
            {
              field: 'arrivallocationId',
              headerName: t('attributes.arrivalLocation'),
              width: 180,
              editable: true,
              headerClassName: 'arrivalLocation',
              valueGetter: getLoadingLocationName(ARRIVAL_LOCATION_POSITION),
              valueFormatter: getValueOrText('Set arrival location'),
              renderEditCell(params) {
                const { id, field } = params;
                const updateCellValue = (
                  locationId?: {
                    id: number;
                    name: string;
                  } | null,
                ) => {
                  apiRef.current.setEditCellValue({
                    id,
                    field,
                    value: locationId?.id,
                  });
                };

                const handleChange = React.useCallback(updateCellValue, []);

                return (
                  <SelectLocationCell
                    onCellChange={handleChange}
                    initialValue={params.value as number | undefined}
                  />
                );
              },
            },
            {
              field: 'arrivalLocationloadStart',
              headerName: t('attributes.loadStart'),
              width: 200,
              valueGetter: getLocationLoadStart(ARRIVAL_LOCATION_POSITION),
              editable: true,
              renderCell: (params) => (
                <TextField
                  id="loadStart"
                  placeholder={t(`attributes.loadStart`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: !params.isEditable,
                    step: 300,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'arrivalLocationloadEnd',
              headerName: t('attributes.loadEnd'),
              width: 200,
              valueGetter: getLocationLoadEnd(ARRIVAL_LOCATION_POSITION),
              editable: true,
              renderCell: (params) => (
                <TextField
                  id="loadEnd"
                  placeholder={t(`attributes.loadEnd`)}
                  fullWidth={true}
                  type="time"
                  value={params.value}
                  onChange={(
                    e: React.ChangeEvent<HTMLInputElement>,
                  ): void | Promise<boolean> =>
                    apiRef.current.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                    readOnly: !params.isEditable,
                  }}
                />
              ),
              cellClassName: (params: GridCellParams) =>
                !params.isEditable ? 'unEditable' : '',
            },
            {
              field: 'createSporadicRoute',
              headerName: 'Create SR',
              width: 180,
              renderCell: (params: GridRenderCellParams) => (
                <>
                  {!params.row.generated && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 16, height: 25 }}
                      onClick={() => {
                        handleCreateSporadicRoute(params);
                      }}
                    >
                      {t('button.create', {
                        item: t('resource.sporadicRoute.abbreviation'),
                      })}
                    </Button>
                  )}
                  {params.row.sporadicRoute?.deletedAt != null && (
                    <Typography align="left">{'SR deleted'}</Typography>
                  )}
                </>
              ),
            },
            {
              field: 'unloaded',
              headerName: t('attributes.unloaded'),
              width: 180,
            },
            {
              field: 'unusedCapacity',
              headerName: t('attributes.unusedCapacity'),
              width: 180,
              type: 'number',
            },
            {
              field: 'utilization',
              headerName: t('attributes.utilization'),
              width: 180,
              type: 'number',
              valueFormatter: (params: GridValueFormatterParams) => {
                const valueFormatted = Number(
                  ((params.value as number) * 100).toFixed(2),
                ).toLocaleString();
                return `${valueFormatted} %`;
              },
            },
            {
              field: 'debitCount',
              headerName: t('attributes.debitCount'),
              width: 180,
              type: 'number',
              valueGetter: getDebitLines,
            },
            {
              field: 'createDebit',
              headerName: t('attributes.debit'),
              width: 180,
              cellClassName: () => 'noOpacity',
              renderCell: (params: GridRenderCellParams) =>
                params.row.generated ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginLeft: 16, height: 25 }}
                    onClick={() => {
                      setDebitLocation({
                        ...params.row.sporadicRoute.legs[0],
                      });
                      setDebitPeakPlanningLocations([
                        ...params.row.sporadicRoute.legs,
                      ]);
                      setSelectedCreateDebitRow({ ...params.row });
                      if (params.row.sporadicRoute.legs.length === 1) {
                        setOpenDebitDialog(true);
                      } else {
                        setOpenDebitDialog(true);
                      }
                    }}
                  >
                    {t('actions.debit')}
                  </Button>
                ) : (
                  ''
                ),
            },
            {
              field: 'actions',
              type: 'actions',
              headerName: t('actions.delete.capitalized'),
              renderCell: (params: GridRenderCellParams) => (
                <IconButton
                  aria-label="delete"
                  color="primary"
                  disabled={
                    params.row.generated &&
                    params.row.sporadicRoute.deletedAt == null
                  }
                  onClick={() => {
                    setPeakPlanningItemToDelete(params.row);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ]}
          rowHeight={30}
          autoHeight={true}
          autoPageSize={true}
          disableSelectionOnClick
        />
        <DialogModalDebit
          open={openDebitDialog}
          setOpen={setOpenDebitDialog}
          contentText={t('dialog.chooseLocation')}
          selectAction={setDebitLocation}
          doAction={handleCreateDebit}
          buttonText={'Create peak debit'}
          debitLocation={debitLocation}
          locations={debitPeakPlanningLocations}
        />
        <DialogModal
          open={peakPlanningItemToDelete != null}
          setOpen={() => setPeakPlanningItemToDelete}
          contentText={t('validation.confirmation', {
            item: t('resource.peakPlanning.lowercased'),
            action: t('actions.delete.lowercased'),
          })}
          doAction={() => {
            deletePeakPlanningItem({
              variables: { id: peakPlanningItemToDelete.id },
            });
            setPeakPlanningItemToDelete(null);
          }}
          closeAction={() => {
            setPeakPlanningItemToDelete(null);
          }}
          buttonText={t('button.continue')}
        />
        <Snackbar
          open={openAlertMessage != null}
          autoHideDuration={3000}
          onClose={() => {
            setOpenAlertMessage(null);
          }}
        >
          <Alert
            onClose={() => {
              setOpenAlertMessage(null);
            }}
            severity="warning"
          >
            {openAlertMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
}
