import { Box, Button, Grid, Typography } from '@material-ui/core';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AddIcon from '@material-ui/icons/Add';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { useGetOrdersQuery } from '../generated/graphql';

export function Orders() {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();
  const { data, loading, error } = useGetOrdersQuery();

  if (error) {
    console.error(error);
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }

  const orders = data?.orders.map((order) => ({
    ...order,
    customerName: order.customer.name,
  }));

  return (
    <Grid container>
      <Grid item container alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={5}
        >
          <Typography variant="h1">{t('resource.order.plural')}</Typography>

          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={`${path}/create`}
            startIcon={<AddIcon />}
          >
            {t('button.create', {
              item: t('resource.order.lowercased'),
            })}
          </Button>
        </Box>
      </Grid>
      <Grid item container>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGridPro
            rows={orders || []}
            loading={loading}
            columns={[
              {
                headerName: '',
                field: '',
                width: 75,
                renderCell: (params) => {
                  return (
                    <Button to={`${url}/${params.row.id}`} component={Link}>
                      <OpenInBrowserIcon />
                    </Button>
                  );
                },
              },
              {
                field: 'transportationDate',
                type: 'string',
                headerName: t(`attributes.transportationDate`),
                flex: 1,
              },
              {
                field: 'note',
                type: 'string',
                headerName: t(`attributes.note`),
                flex: 1,
              },
              {
                field: 'packages',
                type: 'string',
                headerName: t(`attributes.packages`),
                flex: 1,
              },
              {
                field: 'pallets',
                type: 'string',
                headerName: t(`attributes.pallets`),
                flex: 1,
              },
              {
                field: 'palletSpace',
                type: 'string',
                headerName: t(`attributes.palletSpace`),
                flex: 1,
              },
              {
                field: 'weight',
                type: 'string',
                headerName: t(`attributes.weight`),
                flex: 1,
              },
              {
                field: 'id',
                type: 'string',
                headerName: t(`attributes.agreementNumber`),
                flex: 1,
              },
              {
                field: 'customerName',
                headerName: t('resource.customer.capitalized'),
                flex: 1,
              },
            ]}
            pageSize={10}
            rowHeight={50}
            autoHeight={true}
          />
        </div>
      </Grid>
    </Grid>
  );
}
