import {
  Box,
  createStyles,
  Grid,
  Input,
  makeStyles,
  Modal,
  TextField,
  Theme,
} from '@material-ui/core';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  CardNote,
  GetNotesForCardDocument,
  useUpdateCardNoteMutation,
} from '../generated/graphql';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 800,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

function getModalStyle() {
  return {
    width: '100%',
    height: '100vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}

const validationSchema = (t: TFunction) =>
  yup.object({
    title: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          title: t('attributes.title'),
          len: 3,
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.title') })),
    text: yup.string().nullable(),
  });

interface UpdateContactProps {
  open: boolean;
  cardId: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setModalNote: React.Dispatch<
    React.SetStateAction<
      | ({
          __typename?: 'CardNote' | undefined;
        } & Pick<CardNote, 'id' | 'text' | 'title'>)
      | undefined
    >
  >;
  note: {
    __typename?: 'CardNote' | undefined;
  } & Pick<CardNote, 'id' | 'text' | 'title'>;
}

export function UpdateNote(props: UpdateContactProps) {
  const { open, setOpen, note, setModalNote, cardId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [updateNote, {}] = useUpdateCardNoteMutation({
    refetchQueries: [{ query: GetNotesForCardDocument, variables: { cardId } }],
  });

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setModalNote(undefined);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={getModalStyle()}
      >
        <Box className={classes.paper}>
          <Formik
            initialValues={{
              title: note.title,
              text: note.text,
            }}
            enableReinitialize
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
              updateNote({
                variables: {
                  input: { ...values, id: note.id },
                },
              });
              setOpen(false);
              setModalNote(undefined);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={5} justifyContent="center">
                  <Grid item sm={10} container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        fullWidth
                        id="title"
                        name="title"
                        label={t('attributes.title')}
                        value={props.values.title}
                        onChange={props.handleChange}
                        error={
                          props.touched.title && Boolean(props.errors.title)
                        }
                        helperText={props.touched.title && props.errors.title}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        fullWidth
                        id="text"
                        name="text"
                        multiline
                        rows={5}
                        label={t('attributes.text')}
                        value={props.values.text}
                        onChange={props.handleChange}
                        error={props.touched.text && Boolean(props.errors.text)}
                        helperText={props.touched.text && props.errors.text}
                      />
                    </Grid>
                    <Grid item sm={12} container justifyContent="center">
                      <Input
                        type="submit"
                        value={t('button.update', {
                          item: t('attributes.note'),
                        })}
                        color="primary"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
