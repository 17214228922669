import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardType,
  GetCardQuery,
  GetOneTerminalDocument,
  useDemoteTerminalMutation,
  usePromoteTerminalMutation,
} from '../generated/graphql';
import { iconForType } from '../lib/iconForType';
import { CardAssociatedRoutes } from './CardAssociatedRoutes';
import { CardContacts } from './CardContacts';
import { CardCustomerAccounts } from './CardCustomerAccounts';
import { CardFiles } from './CardFiles';
import { CardLocations } from './CardLocations';
import { CardNotes } from './CardNotes';
import { CreatedAndUpdatedView } from './CreatedAndUpdatedView';
import { DialogModal } from './DialogModal';
import { HelmetComponent } from './HelmetComponent';
import { UpdateCardForm } from './UpdateCardForm';

interface ViewCardProps {
  name: string;
  id: number;
  type: string;
  isProtected?: boolean;
  isAdmin?: boolean;
  createdBy: string;
  lastUpdatedBy: string;
  updatedAt: any;
  contacts: GetCardQuery['card']['contacts'];
  files: GetCardQuery['card']['files'];
  onSubmit: (values: { name: string; id: number }) => void;
  editOpen: boolean;
  setEditOpen: Dispatch<SetStateAction<boolean>>;
  deleteCard: (id: number) => void;
  terminal?: boolean;
  isLocationExists?: boolean;
}

export const buttonStyle = makeStyles((theme) => ({
  button: {
    borderColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.error.main} !important`,
    cursor: 'pointer',
  },
  editButton: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    cursor: 'pointer',
  },
  promoteButton: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.primary.main} !important`,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
  demoteButton: {
    borderColor: `${theme.palette.warning.main} !important`,
    color: `${theme.palette.warning.main} !important`,
    cursor: 'pointer',
    marginRight: theme.spacing(1),
  },
}));

export function ViewCard(props: ViewCardProps) {
  const {
    name,
    id,
    type,
    createdBy,
    lastUpdatedBy,
    updatedAt,
    contacts,
    onSubmit,
    editOpen,
    setEditOpen,
    deleteCard,
    isProtected = false,
    isAdmin = false,
    terminal = false,
    isLocationExists = false,
  } = props;
  const { t } = useTranslation();
  const Icon = iconForType(type);
  const classes = buttonStyle();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openPromoteDialog, setOpenPromoteDialog] = useState<boolean>(false);
  const [openDemoteDialog, setOpenDemoteDialog] = useState<boolean>(false);

  const [promoteTerminal] = usePromoteTerminalMutation({
    refetchQueries: [
      {
        query: GetOneTerminalDocument,
        variables: {
          id: id,
        },
      },
    ],
  });
  const [demoteTerminal] = useDemoteTerminalMutation({
    refetchQueries: [
      {
        query: GetOneTerminalDocument,
        variables: {
          id: id,
        },
      },
    ],
  });
  const showPromoteDemoteButton =
    isAdmin && type == CardType.Terminal.toLowerCase() && isLocationExists;

  return (
    <Box>
      <HelmetComponent title={`${name}`} />
      <Box mb={2}>
        {editOpen && !isProtected ? (
          <UpdateCardForm onSubmit={onSubmit} name={name} type={type} />
        ) : (
          <>
            <Box display="flex" alignItems="center">
              <Typography align="left" variant="h2">
                {name}
              </Typography>
              <Box ml={2} display="flex" alignItems="center">
                <Typography align="left" variant="subtitle2">
                  {t(`resource.${type}.capitalized`)}
                </Typography>
                {Icon && <Icon />}
              </Box>
            </Box>
            <CreatedAndUpdatedView
              createdBy={createdBy}
              lastUpdatedBy={lastUpdatedBy}
              updatedAt={updatedAt}
            />
          </>
        )}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={editOpen ? 'space-evenly' : 'flex-end'}
          width="100%"
          mb={1}
        >
          {!terminal && showPromoteDemoteButton && (
            <PromoteDemoteButtons
              isProtected={isProtected}
              promoteRootClass={classes.promoteButton}
              demoteRootClass={classes.demoteButton}
              handleOpenDemoteDialog={() => {
                setOpenDemoteDialog(true);
              }}
              handleOpenPromoteDialog={() => {
                setOpenPromoteDialog(true);
              }}
            />
          )}
          {!terminal && !isProtected && (
            <Button
              classes={{
                root: classes.editButton,
              }}
              variant="outlined"
              onClick={() => {
                setEditOpen(!editOpen);
              }}
              startIcon={editOpen ? <CloseIcon /> : <EditIcon />}
            >
              {editOpen ? 'Cancel' : t('button.edit', { item: type })}
            </Button>
          )}

          {!terminal && editOpen && (
            <Button
              classes={{
                root: classes.button,
              }}
              variant="outlined"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenDeleteDialog(true);
              }}
            >
              {t('button.delete', { item: type })}
            </Button>
          )}
          <DialogModal
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            contentText={t('validation.confirmation', {
              item: name,
              action: t('actions.delete.lowercased'),
            })}
            doAction={() => {
              deleteCard(id);
            }}
            buttonText={t('button.continue')}
          />
          <DialogModal
            open={openPromoteDialog}
            setOpen={setOpenPromoteDialog}
            contentText={t('validation.confirmation', {
              item: name,
              action: t('actions.promote', {
                item: `${type[0].toUpperCase()}${type.slice(1)}`,
              }),
            })}
            doAction={() => {
              promoteTerminal({
                variables: {
                  id,
                },
              });
            }}
            buttonText={t('button.continue')}
          />
          <DialogModal
            open={openDemoteDialog}
            setOpen={setOpenDemoteDialog}
            contentText={t('validation.confirmation', {
              item: name,
              action: t('actions.demote', {
                item: `${type[0].toUpperCase()}${type.slice(1)}`,
              }),
            })}
            doAction={() => {
              demoteTerminal({
                variables: {
                  id,
                },
              });
            }}
            buttonText={t('button.continue')}
          />
        </Box>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {type === 'customer' && (
            <Grid item xs={12}>
              <CardCustomerAccounts
                cardId={id}
                isProtected={isProtected}
                terminal={terminal}
                name={name}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CardLocations
              cardId={id}
              isProtected={isProtected}
              terminal={terminal}
              name={name}
              cardType={type}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid item xs={12}>
            <CardContacts contacts={contacts} terminal={terminal} name={name} />
          </Grid>
          {!terminal && (
            <>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <CardNotes cardId={id} />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <CardFiles cardId={id} />
              </Grid>
              <Grid item xs={12}>
                <Divider variant="fullWidth" />
              </Grid>
              <Grid item xs={12}>
                <CardAssociatedRoutes cardId={id} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

interface PromoteDemoteButtonsProps {
  isProtected: boolean;
  promoteRootClass: string;
  demoteRootClass: string;
  handleOpenPromoteDialog: () => void;
  handleOpenDemoteDialog: () => void;
}

function PromoteDemoteButtons(props: PromoteDemoteButtonsProps) {
  const {
    isProtected,
    promoteRootClass,
    demoteRootClass,
    handleOpenDemoteDialog,
    handleOpenPromoteDialog,
  } = props;
  const { t } = useTranslation();

  if (isProtected) {
    return (
      <Button
        classes={{
          root: demoteRootClass,
        }}
        variant="outlined"
        onClick={() => {
          handleOpenDemoteDialog();
        }}
        color="secondary"
      >
        {t('actions.demote', {
          item: t('resource.terminal.capitalized'),
        })}
      </Button>
    );
  }

  return (
    <Button
      classes={{
        root: promoteRootClass,
      }}
      variant="outlined"
      startIcon={<MapsHomeWorkIcon />}
      onClick={() => {
        handleOpenPromoteDialog();
      }}
    >
      {t('actions.promote', {
        item: t('resource.terminal.capitalized'),
      })}
    </Button>
  );
}
