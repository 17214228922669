import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { buttonStyle } from './ViewCard';

interface CopyRouteDialogModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  contentText: string;
  buttonText?: string;
  copyToCurrentTour: () => void;
  copyToOtherTour: () => void;
  closeAction?: () => void;
}

export function CopyRouteDialogModal(props: CopyRouteDialogModalProps) {
  const {
    open,
    setOpen,
    title,
    contentText,
    closeAction = undefined,
    copyToCurrentTour,
    copyToOtherTour,
  } = props;
  const classes = buttonStyle();
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const router = useHistory();

  return (
    <Dialog
      open={open}
      onClose={closeAction ?? handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeAction ?? handleClose} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button
          classes={{
            root: classes.button,
          }}
          onClick={() => {
            copyToOtherTour();
            closeAction ? closeAction() : handleClose();
          }}
          color="primary"
          //autoFocus
        >
          {t('attributes.otherTour')}
        </Button>

        <Button
          classes={{
            root: classes.button,
          }}
          onClick={() => {
            router.replace({
              pathname: url,
              state: {
                copyRoute: true,
                enableEditMode: true,
                otherTour: false,
                timestamp: new Date().getTime(),
              },
            });
            copyToCurrentTour();
            closeAction ? closeAction() : handleClose();
          }}
          color="primary"
          autoFocus
        >
          {t('attributes.currentTour')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
