import { Button, makeStyles, TextField } from '@material-ui/core';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../generated/graphql';

export const useStylesForSearch = makeStyles({
  inputWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  inputForCard: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input: {
    width: '70%',
    marginRight: 3,
  },
});

interface SearchInputProps {
  searchQuery?: Maybe<string>;
  onQuerySelected: (values: any) => void;
  isFromCard?: boolean;
}

export function SearchInput({
  searchQuery,
  onQuerySelected,
  isFromCard = false,
}: SearchInputProps) {
  const { t } = useTranslation();
  const classes = useStylesForSearch();
  const [queryChanged, setQueryChanged] = useState(false);
  const initialValues: { searchQuery: string } = {
    searchQuery: searchQuery ?? '',
  };

  const onChangeQuery = (values: { searchQuery: string }) => {
    onQuerySelected(values);
    setQueryChanged(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onChangeQuery}
    >
      {() => (
        <Form
          className={isFromCard ? classes.inputForCard : classes.inputWrapper}
        >
          <Field name="searchQuery">
            {({ field }: FieldProps) => (
              <TextField
                className={classes.input}
                {...field}
                variant="outlined"
                size="small"
                label={t('filter.search')}
                onChange={(event) => {
                  setQueryChanged(true);
                  field.onChange(event);
                }}
                type="text"
              />
            )}
          </Field>
          <Button
            type="submit"
            variant="contained"
            disabled={!queryChanged}
            color="primary"
          >
            {t('filter.search')}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
