import { TextFieldProps, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useGetLocationForSelectLazyQuery } from '../../generated/graphql';

interface SelectLocationProps {
  locationId: number | undefined;
}

interface SelectLocationInterface {
  id: number;
  name: string;
  address: string;
  card: {
    name: string;
  };
}

export function ViewLocation(props: SelectLocationProps & TextFieldProps) {
  const { locationId } = props;
  const [value, setValue] = useState<SelectLocationInterface | null>(null);
  const [getLocation, { data: locationData }] =
    useGetLocationForSelectLazyQuery();
  useEffect(() => {
    if (locationId != null) {
      getLocation({ variables: { locationId: locationId } });
    }
  }, [locationId]);

  useEffect(() => {
    if (locationData) {
      setValue(locationData.location);
    }
  }, [locationData]);

  return (
    <Typography
      style={{
        fontSize: 14,
      }}
    >{` ${value?.name}`}</Typography>
  );
}
