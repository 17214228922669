import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState, useEffect, useCallback, FocusEvent } from 'react';
import { FastTextFieldProps } from './FastTextFieldPackage';

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  smallFontSize: {
    '& input': {
      fontSize: 12,
    },
    '&.MuiOutlinedInput-multiline': {
      fontSize: 12,
    },
  },
  smallPadding: {
    '& input': {
      padding: 4,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 4,
    },
  },
}));

export function FastTextFieldPallets(
  props: FastTextFieldProps & TextFieldProps,
) {
  const { t } = useTranslation();
  const {
    name,
    readOnly = false,
    fullWidth = false,
    helperText = undefined,
    InputProps = {},
    size = 'small',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    ...rest
  } = props;
  const classes = useStyles();
  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const { getFieldProps, setFieldValue } = useFormikContext();
  const [innerValue, setInnerValue] = useState('');

  useEffect(() => {
    setInnerValue(
      getFieldProps(name).value ? `${getFieldProps(name).value}` : '',
    );
  }, []);

  useEffect(() => {
    setInnerValue(
      getFieldProps(name).value ? `${getFieldProps(name).value}` : '',
    );
  }, [getFieldProps(name).value]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setInnerValue(event.target.value);
    },
    [],
  );

  const handleOnBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue =
        event && event.target && event.target.value ? event.target.value : '';
      if (`${getFieldProps(name).value}` !== newValue)
        setFieldValue(
          name,
          newValue === '' ? null : parseInt(newValue, 10),
          false,
        );
    },
    [],
  );

  return (
    <Field name={name}>
      {({ meta }: FieldProps) => (
        <TextField
          {...props}
          id={name}
          size={size}
          onChange={handleOnChange}
          onBlur={(e) => handleOnBlur(e)}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          onKeyDown={(e) => {
            exceptThisSymbols.includes(e.key) && e.preventDefault();
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
              e.preventDefault();
            } else if (e.altKey) {
              if (e.target && e.target instanceof HTMLInputElement) {
                (e.target as HTMLInputElement).blur();
              }
              e.preventDefault();
            }
          }}
          value={innerValue}
          variant="outlined"
          placeholder={noLabel ? undefined : t(`attributes.${label}`)}
          label={noLabel ? undefined : t(`attributes.${label}`)}
          error={meta.touched && Boolean(meta.error)}
          helperText={helperText || (meta.touched && meta.error)}
          fullWidth={fullWidth}
          InputProps={{
            className: clsx([
              classes.input,
              {
                [classes.smallFontSize]: smallFontSize,
                [classes.smallPadding]: smallPadding,
              },
            ]),
            readOnly,
            ...InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      )}
    </Field>
  );
}
