import { Helmet } from 'react-helmet';

interface HelmetComponentProps {
  title: string;
  defer?: boolean;
  meta?: {
    name: string;
    content: string;
  };
}

export function HelmetComponent(props: HelmetComponentProps) {
  const { title, meta, defer = false } = props;
  return (
    <Helmet defer={defer}>
      <title>{title}</title>
      {meta && <meta name={meta.name} content={meta.content} />}
    </Helmet>
  );
}
