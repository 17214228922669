import { makeStyles } from '@material-ui/core';
import { Box, Theme } from '@mui/material';

interface centeredBoxProps {
  children: React.ReactNode;
  visible?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    '& > *': {
      width: '100%',
      margin: theme.spacing(1, 1),
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
    '& *:only-child': {
      margin: 0,
    },
  },
}));

export function CenteredBox({ children, visible = true }: centeredBoxProps) {
  const classes = useStyles();
  return (
    <Box
      style={{ visibility: visible ? undefined : 'visible' }}
      className={classes.boxStyle}
    >
      {children}
    </Box>
  );
}
