import PageviewIcon from '@material-ui/icons/Pageview';
import Box from '@mui/material/Box';
import { Tooltip } from '@material-ui/core';
import {
  GridCellParams,
  GridColDef,
  GridComparatorFn,
} from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';

export interface cardColumnsOptions {
  t: TFunction;
  path: string;
  isAllType?: boolean;
}

const nameSortComparator: GridComparatorFn = (v1, v2) => {
  const first = v1 as string;
  const second = v2 as string;
  if (first && second) {
    return first.localeCompare(second);
  } else if (!first && second) {
    return 1;
  } else if (first && !second) {
    return -1;
  }
  return 0;
};

export const cardColumns = (options: cardColumnsOptions): GridColDef[] => {
  const { t, path, isAllType = false } = options;
  const c = [
    {
      field: 'id',
      type: 'number',
      headerName: t(`attributes.id`),
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: 'view',
      headerName: 'View',
      minWidth: 100,
      type: 'action',
      renderCell: (params: GridCellParams) => {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Tooltip title={`${t('actions.view')}`}>
              <Link
                to={
                  isAllType
                    ? `${path}${(params.row.type + '').toLowerCase()}s/${
                        params.id
                      }`
                    : `${path}/${params.id}`
                }
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PageviewIcon color="action" />
              </Link>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'name',
      headerName: t('attributes.name'),
      minWidth: 100,
      width: 400,
      type: 'string',
    },
    {
      field: 'locations',
      headerName: t('attributes.location'),
      minWidth: 100,
      width: 400,
      type: 'string',
      sortComparator: nameSortComparator,
    },
  ];
  isAllType
    ? c.push({
        field: 'type',
        headerName: t('attributes.type'),
        minWidth: 100,
        width: 400,
        type: 'string',
      })
    : '';

  return c;
};
