import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  Button,
  Box,
  Theme,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentFile, Maybe, RouteType } from '../generated/graphql';
import { COUNTRIES_MAP } from '../lib/constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getTFTMasterDate, getTimeAsNumberOfMinutes } from '../lib/date';
import { useHttpClient } from '../providers/HttpClientProvider';
import PreviousIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import { timeToMinute } from '../lib/time';

const useStyles = makeStyles((theme: Theme) => ({
  filterButtonRoot: {
    marginLeft: 2,
    border: '2px solid #e8e8e8',
    borderRadius: 3,
    marginTop: 4,
    padding: 2,
  },
  filterButton: {
    marginTop: 4,
    marginBottom: 2,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  btnSelected: {
    border: '1px solid #68BC46',
    backgroundColor: '#AAE590',
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
}));

interface ListIncomingRoutesLegs {
  id: number;
  arrivalTime: string;
  departureTime?: Maybe<string>;
  actualArrivalTime?: Maybe<string>;
  actualDepartureTime?: Maybe<string>;
  productionDate: string;
  transportationDate: string;
  loadCar?: Maybe<number>;
  loadCarPPL?: Maybe<number>;
  loadCages?: Maybe<number>;
  loadTrailer?: Maybe<number>;
  loadTrailerPPL?: Maybe<number>;
  unloadCar?: Maybe<number>;
  unloadCarPPL?: Maybe<number>;
  unloadCages?: Maybe<number>;
  unloadTrailer?: Maybe<number>;
  unloadTrailerPPL?: Maybe<number>;
  note: string;
  unload: boolean;
  load: boolean;
  terminalNote: string;
  lastUpdatedBy?: Maybe<string>;
  updatedAt: string;
  files: Pick<
    DocumentFile,
    'id' | 'size' | 'path' | 'mimetype' | 'originalname'
  >[];
  loadingListItems: {
    pallets?: Maybe<number>;
    palletSpace?: Maybe<number>;
    isDangerous: boolean;
  }[];
  location?: Maybe<{
    id: number;
    city: string;
    country: string;
  }>;
  route: {
    id: number;
    isCreatedFromTerminal: boolean;
    isCreatedFromPlanned: boolean;
    type: RouteType;
    routeId: string;
    capacity?: Maybe<number>;
    tourRoute?: Maybe<{
      tourId: number;
    }>;
    transportationDate: string;
    driverName: string;
    subcontractor: {
      id: number;
      name: string;
    };
    legs: {
      location?: Maybe<{
        city: string;
        country: string;
      }>;
    }[];
  };
}

interface ListRoutesLegsAllTerminal {
  id: number;
  productionDate: string;
  loadCar?: Maybe<number>;
  loadTrailer?: Maybe<number>;
  unloadCar?: Maybe<number>;
  unloadTrailer?: Maybe<number>;
  unload: boolean;
  load: boolean;
  location?: Maybe<{
    id: number;
    city: string;
    country: string;
    card?: {
      id: number;
      name: string;
      type: string;
    };
  }>;
  route: {
    id: number;
    isCreatedFromTerminal: boolean;
    isCreatedFromPlanned: boolean;
    type: RouteType;
    routeId: string;
    capacity?: Maybe<number>;
    transportationDate: string;
    legs: {
      position: number;
      location?: Maybe<{
        city: string;
        country: string;
      }>;
    }[];
  };
}

type MyRoute = {
  legId: number;
  routeName: string;
  capacity: Maybe<number>;
  load: boolean;
  unload: boolean;
  departureCountry: string;
  arrivalCountry: string;
  loadSumPPI: number;
  unloadSumPPI: number;
  loadLoadFactor: Maybe<number>;
  unloadLoadFactor: Maybe<number>;
  typeIOIE: string; //INBOUND,OUTBOUND,IMPORT,EXPORT
}[];

interface DashReportsProps {
  selectedTerminalId: number;
  selectedCountryCode: string;
  isInTerminal: boolean;
}

export function TrafficDashFilledItems(props: DashReportsProps) {
  const { t } = useTranslation();
  const NEW_DATE_FORMAT = 'yyyy-MM-dd HH:mm';
  const { selectedTerminalId, selectedCountryCode, isInTerminal } = props;
  const [lowestINBOUND, set5LowestInboundData] = useState<MyRoute>([]);
  const [lowestIMPORT, set5LowestImportData] = useState<MyRoute>([]);
  const [lowestOUTBOUND, set5LowestOutboundData] = useState<MyRoute>([]);
  const [lowestEXPORT, set5LowestExportData] = useState<MyRoute>([]);

  const [highestINBOUND, set5HighestInboundData] = useState<MyRoute>([]);
  const [highestIMPORT, set5HighestImportData] = useState<MyRoute>([]);
  const [highestOUTBOUND, set5HighestOutboundData] = useState<MyRoute>([]);
  const [highestEXPORT, set5HighestExportData] = useState<MyRoute>([]);
  const [isLoadingAll, setPageLoadingAll] = useState<boolean>(false);
  const [isLoadingSelected, setPageLoadingSelected] = useState<boolean>(false);
  const classes = useStyles();
  const { httpClient } = useHttpClient();
  const [legsData, setLegsData] = useState<ListIncomingRoutesLegs[]>([]);
  //DateTime range
  const { getTFTTime, getTFTSameDay } = useUserConfiguration();
  const isSameDaySelected = getTFTSameDay();
  const currTimeHour = new Date().getHours();
  const currTimeMinute = new Date().getMinutes();
  const isSameProdDayFound =
    !isSameDaySelected &&
    timeToMinute(`${currTimeHour}:${currTimeMinute}`) <
      timeToMinute(getTFTTime(false));

  const myDate = isSameProdDayFound
    ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    : new Date();

  const startHour = parseInt(getTFTTime(true).split(':')[0]);
  const startMinute = parseInt(getTFTTime(true).split(':')[1]);
  const [startDateTime, setStartDateTime] = useState(
    isInTerminal
      ? getTFTMasterDate(true).setHours(startHour, startMinute)
      : myDate.setHours(startHour, startMinute),
  );
  const handleStartDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setStartDateTime(value);
      //Mon Jun 19 2023 10:00:00 GMT+0530
      setReportStartDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setReportStartDate('');
    }
  }, []);

  const endHour = parseInt(getTFTTime(false).split(':')[0]);
  const endMinute = parseInt(getTFTTime(false).split(':')[1]);
  const [endDateTime, setEndDateTime] = useState(
    isInTerminal
      ? getTFTMasterDate(false).setHours(endHour, endMinute)
      : isSameDaySelected
      ? new Date(myDate.getTime()).setHours(endHour, endMinute)
      : new Date(myDate.getTime() + 24 * 60 * 60 * 1000).setHours(
          endHour,
          endMinute,
        ),
  );
  const handleEndDateChanged = useCallback((value) => {
    if (value && value != 'Invalid Date') {
      setEndDateTime(value);
      setReportEndDate(format(new Date(value.toString()), NEW_DATE_FORMAT));
    } else {
      setReportEndDate('');
    }
  }, []);
  const [isBothDateValid, setBothDateValid] = useState<boolean>(false);
  const [reportStartDate, setReportStartDate] = useState<string>(
    format(startDateTime, NEW_DATE_FORMAT),
  );
  const [reportEndDate, setReportEndDate] = useState<string>(
    format(endDateTime, NEW_DATE_FORMAT),
  );

  const [chartData, setChartData] = useState<
    {
      typeName: string;
      avgFactor: number;
    }[]
  >();

  const [chartDataForAll, setChartDataForAll] = useState<
    {
      terminalName: string;
      terminalCountryCode: string;
      factorIMPORT: number;
      factorEXPORT: number;
      factorINBOUND: number;
      factorOUTBOUND: number;
      totalLoadPPL: number;
      totalUnloadPPL: number;
    }[]
  >([]);

  const isValidTime = (startTime: string, endTime: string): boolean => {
    const startMins = getTimeAsNumberOfMinutes(startTime);
    const endMins = getTimeAsNumberOfMinutes(endTime);
    return startMins < endMins;
  };

  const getAllAvgData = (startDateTime: string, endDateTime: string) => {
    setPageLoadingAll(true);

    httpClient
      .getRouteLegsForAllTerminal({
        startDateTime,
        endDateTime,
      })
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.status === 'OK' &&
          res.data.data.length > 0
        ) {
          const groupedRoutesByTerminalId = _.groupBy(
            res.data.data,
            'location.card.name',
          );

          if (groupedRoutesByTerminalId) {
            const allChartDataArr = [];

            for (const [key, value] of Object.entries(
              groupedRoutesByTerminalId,
            )) {
              const thisTerminalName = key;
              const thisTerminalCountryCode = value[0].location?.country ?? '';

              const finalLegsRows = buildRowsForAllTerminal(
                value,
                value[0].location?.country ?? '',
              );

              if (finalLegsRows && finalLegsRows.length > 0) {
                const groupedRoutesByType = _.groupBy(
                  finalLegsRows,
                  'typeIOIE',
                );

                if (groupedRoutesByType) {
                  const chartAvgData = {
                    terminalName: thisTerminalName,
                    terminalCountryCode: thisTerminalCountryCode,
                    factorIMPORT: 0,
                    factorEXPORT: 0,
                    factorINBOUND: 0,
                    factorOUTBOUND: 0,
                    totalLoadPPL: 0,
                    totalUnloadPPL: 0,
                  };

                  for (const [key, value] of Object.entries(
                    groupedRoutesByType,
                  )) {
                    if (key === 'IMPORT') {
                      chartAvgData.factorIMPORT = getAvgFactor(value, key);
                    } else if (key === 'EXPORT') {
                      chartAvgData.factorEXPORT = getAvgFactor(value, key);
                    } else if (key === 'INBOUND') {
                      chartAvgData.factorINBOUND = getAvgFactor(value, key);
                    } else if (key === 'OUTBOUND') {
                      chartAvgData.factorOUTBOUND = getAvgFactor(value, key);
                    }
                    //total
                    if (key === 'IMPORT' || key === 'INBOUND') {
                      chartAvgData.totalUnloadPPL = getTotalPPL(value, false);
                    } else if (key === 'EXPORT' || key === 'OUTBOUND') {
                      chartAvgData.totalLoadPPL = getTotalPPL(value, true);
                    }
                  }
                  allChartDataArr.push(chartAvgData);
                }
              }
            }
            setPageLoadingAll(false);
            setChartDataForAll(allChartDataArr);
          }
        } else {
          setChartDataForAll([]);
        }
        setPageLoadingAll(false);
      })
      .catch((e) => {
        console.error('# Dashboard FF ALL error=', e);
        setPageLoadingAll(false);
      });
  };

  const getRouteLegsData = (startDateTime: string, endDateTime: string) => {
    setPageLoadingSelected(true);
    httpClient
      .getIncomingRouteLegs({
        terminalId: [selectedTerminalId],
        startDateTime,
        endDateTime,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setLegsData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setLegsData([]);
          console.error('# tft API error=', res.data.message);
        }
        setPageLoadingSelected(false);
      })
      .catch((e) => {
        console.error('# Dashboard FF error=', e);
        setPageLoadingSelected(false);
      });
  };

  const changeDatesAction = (isNext: boolean) => {
    const newDateStart = isNext
      ? new Date(new Date(reportStartDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(reportStartDate).getTime() - 1000 * 60 * 60 * 24);
    const newDateEnd = isNext
      ? new Date(new Date(reportEndDate).getTime() + 1000 * 60 * 60 * 24)
      : new Date(new Date(reportEndDate).getTime() - 1000 * 60 * 60 * 24);

    setStartDateTime(newDateStart.getTime());
    setEndDateTime(newDateEnd.getTime());
    const newTFTStartDateTime = format(newDateStart, NEW_DATE_FORMAT);
    const newTFTEndDateTime = format(newDateEnd, NEW_DATE_FORMAT);

    setReportStartDate(newTFTStartDateTime);
    setReportEndDate(newTFTEndDateTime);

    getAllAvgData(newTFTStartDateTime, newTFTEndDateTime);

    if (selectedTerminalId && selectedTerminalId !== 0) {
      getRouteLegsData(newTFTStartDateTime, newTFTEndDateTime);
    } else {
      //no terminal is selected
      setPageLoadingSelected(false);
      setChartData([]);

      set5HighestExportData([]);
      set5HighestOutboundData([]);
      set5HighestImportData([]);
      set5HighestInboundData([]);
      set5LowestExportData([]);
      set5LowestOutboundData([]);
      set5LowestImportData([]);
      set5LowestInboundData([]);
    }
  };

  useEffect(() => {
    if (reportStartDate !== '' && reportEndDate !== '') {
      if (reportStartDate.split(' ')[0] === reportEndDate.split(' ')[0]) {
        const isTimeValid = isValidTime(
          reportStartDate.split(' ')[1],
          reportEndDate.split(' ')[1],
        );
        setBothDateValid(isTimeValid);
      } else {
        setBothDateValid(true);
      }
    } else {
      setBothDateValid(false);
    }
  }, [reportStartDate, reportEndDate]);

  useEffect(() => {
    if (selectedTerminalId && selectedTerminalId !== 0 && isBothDateValid) {
      getRouteLegsData(reportStartDate, reportEndDate);
    }
  }, [selectedTerminalId]);

  useEffect(() => {
    if (legsData && legsData.length > 0) {
      const finalLegsRows = buildRows(legsData, selectedCountryCode ?? '');

      if (finalLegsRows && finalLegsRows.length > 0) {
        const groupedRoutesByType = _.groupBy(finalLegsRows, 'typeIOIE');
        if (groupedRoutesByType) {
          const chartAvgData = [
            {
              typeName: 'IMPORT',
              avgFactor: 0,
            },
            {
              typeName: 'EXPORT',
              avgFactor: 0,
            },
            {
              typeName: 'INBOUND',
              avgFactor: 0,
            },
            {
              typeName: 'OUTBOUND',
              avgFactor: 0,
            },
          ];

          for (const [key, value] of Object.entries(groupedRoutesByType)) {
            if (key === 'IMPORT') {
              chartAvgData[0].avgFactor = getAvgFactor(value, key);
            } else if (key === 'EXPORT') {
              chartAvgData[1].avgFactor = getAvgFactor(value, key);
            } else if (key === 'INBOUND') {
              chartAvgData[2].avgFactor = getAvgFactor(value, key);
            } else if (key === 'OUTBOUND') {
              chartAvgData[3].avgFactor = getAvgFactor(value, key);
            }
          }
          setChartData(chartAvgData);

          //for 4 diff tables factor
          if (
            groupedRoutesByType['INBOUND'] &&
            groupedRoutesByType['INBOUND'].length > 0
          ) {
            const allSortedArr = groupedRoutesByType['INBOUND'].sort(
              (a, b) => a.unloadLoadFactor - b.unloadLoadFactor,
            );

            set5LowestInboundData(
              allSortedArr.slice(0, Math.min(5, allSortedArr.length)),
            );
            set5HighestInboundData(
              allSortedArr.slice(Math.max(allSortedArr.length - 5, 0)),
            );
          } else {
            set5LowestInboundData([]);
            set5HighestInboundData([]);
          }
          if (
            groupedRoutesByType['IMPORT'] &&
            groupedRoutesByType['IMPORT'].length > 0
          ) {
            const allSortedArr = groupedRoutesByType['IMPORT'].sort(
              (a, b) => a.unloadLoadFactor - b.unloadLoadFactor,
            );

            set5LowestImportData(
              allSortedArr.slice(0, Math.min(5, allSortedArr.length)),
            );
            set5HighestImportData(
              allSortedArr.slice(Math.max(allSortedArr.length - 5, 0)),
            );
          } else {
            set5LowestImportData([]);
            set5HighestImportData([]);
          }
          if (
            groupedRoutesByType['OUTBOUND'] &&
            groupedRoutesByType['OUTBOUND'].length > 0
          ) {
            const allSortedArr = groupedRoutesByType['OUTBOUND'].sort(
              (a, b) => a.loadLoadFactor - b.loadLoadFactor,
            );

            set5LowestOutboundData(
              allSortedArr.slice(0, Math.min(5, allSortedArr.length)),
            );
            set5HighestOutboundData(
              allSortedArr.slice(Math.max(allSortedArr.length - 5, 0)),
            );
          } else {
            set5LowestOutboundData([]);
            set5HighestOutboundData([]);
          }
          if (
            groupedRoutesByType['EXPORT'] &&
            groupedRoutesByType['EXPORT'].length > 0
          ) {
            const allSortedArr = groupedRoutesByType['EXPORT'].sort(
              (a, b) => a.loadLoadFactor - b.loadLoadFactor,
            );

            set5LowestExportData(
              allSortedArr.slice(0, Math.min(5, allSortedArr.length)),
            );
            set5HighestExportData(
              allSortedArr.slice(Math.max(allSortedArr.length - 5, 0)),
            );
          } else {
            set5LowestExportData([]);
            set5HighestExportData([]);
          }
        }
      }
    } else {
      //no data found
      setChartData([]);
    }
  }, [legsData]);

  useEffect(() => {
    if (
      reportStartDate !== '' &&
      reportEndDate !== '' &&
      isValidTime(reportStartDate.split(' ')[1], reportEndDate.split(' ')[1])
    )
      getAllAvgData(reportStartDate, reportEndDate);
  }, []);

  function buildRows(legs: ListIncomingRoutesLegs[], thisCountryShort: string) {
    const thisTerminalCountry =
      COUNTRIES_MAP[
        thisCountryShort as keyof typeof COUNTRIES_MAP
      ].toLocaleUpperCase();

    return (
      legs?.map((routeLeg) => {
        const { route } = routeLeg;
        const leg = routeLeg;
        const { routeId, capacity } = route;
        const firstLegLocation = route.legs[0]?.location;
        const lastLegLocation = route.legs[route.legs.length - 1]?.location;

        const departureCountry =
          firstLegLocation && firstLegLocation?.country
            ? COUNTRIES_MAP[
                firstLegLocation?.country as keyof typeof COUNTRIES_MAP
              ].toLocaleUpperCase()
            : '';

        const arrivalCountry =
          lastLegLocation && lastLegLocation?.country
            ? COUNTRIES_MAP[
                lastLegLocation?.country as keyof typeof COUNTRIES_MAP
              ].toLocaleUpperCase()
            : '';

        const loadSumPPI = (leg.loadCar || 0) + (leg.loadTrailer || 0);
        const unloadSumPPI = (leg.unloadCar || 0) + (leg.unloadTrailer || 0);

        const loadLoadFactor =
          route.capacity == null || loadSumPPI === 0 || route.capacity === 0
            ? 0
            : Math.floor((loadSumPPI / route.capacity) * 100);

        const unloadLoadFactor =
          route.capacity == null || unloadSumPPI === 0 || route.capacity === 0
            ? 0
            : Math.floor((unloadSumPPI / route.capacity) * 100);

        return {
          legId: leg.id,
          routeName: routeId,
          capacity: capacity ?? 0,
          load: leg.load,
          unload: leg.unload,
          departureCountry,
          arrivalCountry,
          loadSumPPI,
          loadLoadFactor,
          unloadSumPPI,
          unloadLoadFactor,
          typeIOIE: getTypeIOIE(
            leg.load,
            leg.load ? arrivalCountry : departureCountry,
            thisTerminalCountry,
          ),
        };
      }) || []
    );
  }

  function buildRowsForAllTerminal(
    legs: ListRoutesLegsAllTerminal[],
    thisCountryShort: string,
  ) {
    const thisTerminalCountry =
      COUNTRIES_MAP[
        thisCountryShort as keyof typeof COUNTRIES_MAP
      ].toLocaleUpperCase();

    return (
      legs?.map((leg) => {
        const { route } = leg;
        const { routeId, capacity } = route;
        const firstLegLocation = route.legs[0]?.location;
        const lastLegLocation = route.legs[route.legs.length - 1]?.location;

        const departureCountry =
          firstLegLocation && firstLegLocation?.country
            ? COUNTRIES_MAP[
                firstLegLocation?.country as keyof typeof COUNTRIES_MAP
              ].toLocaleUpperCase()
            : '';

        const arrivalCountry =
          lastLegLocation && lastLegLocation?.country
            ? COUNTRIES_MAP[
                lastLegLocation?.country as keyof typeof COUNTRIES_MAP
              ].toLocaleUpperCase()
            : '';

        const loadSumPPI = (leg.loadCar || 0) + (leg.loadTrailer || 0);
        const unloadSumPPI = (leg.unloadCar || 0) + (leg.unloadTrailer || 0);

        const loadLoadFactor =
          route.capacity == null || loadSumPPI === 0 || route.capacity === 0
            ? 0
            : Math.floor((loadSumPPI / route.capacity) * 100);

        const unloadLoadFactor =
          route.capacity == null || unloadSumPPI === 0 || route.capacity === 0
            ? 0
            : Math.floor((unloadSumPPI / route.capacity) * 100);

        return {
          legId: leg.id,
          routeName: routeId,
          capacity: capacity ?? 0,
          load: leg.load,
          unload: leg.unload,
          departureCountry,
          arrivalCountry,
          loadSumPPI,
          loadLoadFactor,
          unloadSumPPI,
          unloadLoadFactor,
          typeIOIE: getTypeIOIE(
            leg.load,
            leg.load ? arrivalCountry : departureCountry,
            thisTerminalCountry,
          ),
        };
      }) || []
    );
  }

  const getAvgFactor = (routes: MyRoute, rType: string): number => {
    let sumOfCapacity = 0,
      sumOfFilledFactor = 0;
    if (routes && routes.length > 0) {
      routes.forEach((r) => {
        if (rType === 'INBOUND' || rType === 'IMPORT') {
          sumOfCapacity += r.capacity ?? 0;
          sumOfFilledFactor += r.unloadSumPPI ?? 0;
        } else if (rType === 'OUTBOUND' || rType === 'EXPORT') {
          sumOfCapacity += r.capacity ?? 0;
          sumOfFilledFactor += r.loadSumPPI ?? 0;
        }
      });
      return sumOfFilledFactor === 0 || sumOfCapacity === 0
        ? 0
        : Math.ceil((sumOfFilledFactor * 100) / sumOfCapacity);
    } else {
      return 0;
    }
  };

  const getTotalPPL = (routes: MyRoute, isForLoad: boolean): number => {
    let sumOfPPL = 0;
    if (routes && routes.length > 0) {
      routes.forEach((r) => {
        if (!isForLoad) {
          sumOfPPL += r.unloadSumPPI ?? 0;
        } else if (isForLoad) {
          sumOfPPL += r.loadSumPPI ?? 0;
        }
      });
      return sumOfPPL;
    } else {
      return 0;
    }
  };

  const getTypeIOIE = (
    isLoad: boolean,
    country: string,
    thisTerminalCountry: string,
  ): string => {
    if (country === '') {
      return '-';
    } else {
      if (isLoad && country === thisTerminalCountry) {
        return 'OUTBOUND';
      } else if (isLoad && country !== thisTerminalCountry) {
        return 'EXPORT';
      } else if (!isLoad && country === thisTerminalCountry) {
        return 'INBOUND';
      } else if (!isLoad && country !== thisTerminalCountry) {
        return 'IMPORT';
      } else {
        return '-';
      }
    }
  };

  interface BoxProps {
    type: string;
  }

  const TableForLowest = ({ type }: BoxProps) => {
    let finalData: MyRoute = [];

    if (type === 'IMPORT') {
      finalData = lowestIMPORT;
    } else if (type === 'INBOUND') {
      finalData = lowestINBOUND;
    } else if (type === 'OUTBOUND') {
      finalData = lowestOUTBOUND;
    } else if (type === 'EXPORT') {
      finalData = lowestEXPORT;
    }

    if (finalData && finalData.length > 0) {
      return (
        <Grid item xs={6}>
          <div
            style={{ width: '100%', border: '1px solid #68bc46', marginTop: 2 }}
          >
            <Typography variant="subtitle1" align="center">
              <strong>{`${type} - Lowest`}</strong>
            </Typography>
          </div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'lightgrey' }}>
                  <TableCell>Route Name</TableCell>
                  <TableCell align="right">Capacity</TableCell>
                  <TableCell align="right">Sum PPL</TableCell>
                  <TableCell align="right">Filled Factor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finalData.map((row) => (
                  <TableRow key={row.legId}>
                    <TableCell component="th" scope="row">
                      {row.routeName}
                    </TableCell>
                    <TableCell align="right">
                      {row.capacity === 0 ? '' : row.capacity}
                    </TableCell>
                    <TableCell align="right">{`${
                      type === 'INBOUND' || type === 'IMPORT'
                        ? row.unloadSumPPI
                        : row.loadSumPPI
                    }`}</TableCell>
                    <TableCell align="right">
                      {`${
                        type === 'INBOUND' || type === 'IMPORT'
                          ? row.unloadLoadFactor
                          : row.loadLoadFactor
                      }%`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  const TableForHighest = ({ type }: BoxProps) => {
    let finalData: MyRoute = [];

    if (type === 'IMPORT') {
      finalData = [...highestIMPORT].reverse();
    } else if (type === 'INBOUND') {
      finalData = [...highestINBOUND].reverse();
    } else if (type === 'OUTBOUND') {
      finalData = [...highestOUTBOUND].reverse();
    } else if (type === 'EXPORT') {
      finalData = [...highestEXPORT].reverse();
    }

    if (finalData && finalData.length > 0) {
      return (
        <Grid item xs={6}>
          <div
            style={{ width: '100%', border: '1px solid #68bc46', marginTop: 2 }}
          >
            <Typography variant="subtitle1" align="center">
              <strong>{`${type} - Highest`}</strong>
            </Typography>
          </div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'lightgrey' }}>
                  <TableCell>Route Name</TableCell>
                  <TableCell align="right">Capacity</TableCell>
                  <TableCell align="right">Sum PPL</TableCell>
                  <TableCell align="right">Filled Factor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finalData.map((row) => (
                  <TableRow key={row.legId}>
                    <TableCell component="th" scope="row">
                      {row.routeName}
                    </TableCell>
                    <TableCell align="right">
                      {row.capacity === 0 ? '' : row.capacity}
                    </TableCell>
                    <TableCell align="right">{`${
                      type === 'INBOUND' || type === 'IMPORT'
                        ? row.unloadSumPPI
                        : row.loadSumPPI
                    }`}</TableCell>
                    <TableCell align="right">
                      {`${
                        type === 'INBOUND' || type === 'IMPORT'
                          ? row.unloadLoadFactor
                          : row.loadLoadFactor
                      }%`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box className={classes.filterButtonRoot}>
            <Tooltip title={`Previous Date`}>
              <IconButton
                style={{
                  backgroundColor: '#e2e2e2',
                  height: 38,
                  width: 38,
                  marginRight: 12,
                }}
                onClick={() => {
                  changeDatesAction(false);
                }}
              >
                <PreviousIcon />
              </IconButton>
            </Tooltip>
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              allowKeyboardControl={true}
              label="Production Start"
              value={startDateTime}
              onChange={handleStartDateChanged}
              format="yyyy-MM-dd HH:mm"
              id="startDT"
              InputProps={{
                style: {
                  fontSize: 15,
                },
                classes: { input: classes.inputValTime },
                maxRows: 1,
              }}
              invalidDateMessage={'Invalid DateTime'}
              maxDate={endDateTime}
              maxDateMessage={'Invalid Range'}
              onError={(e) => {
                if (e) setBothDateValid(false);
              }}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: 'none' },
              }}
            />
            <KeyboardDateTimePicker
              variant="inline"
              ampm={false}
              allowKeyboardControl={true}
              label="Production End"
              value={endDateTime}
              onChange={handleEndDateChanged}
              format="yyyy-MM-dd HH:mm"
              id="endDT"
              InputProps={{
                style: {
                  fontSize: 15,
                },
                classes: { input: classes.inputValTime },
                maxRows: 1,
              }}
              minDate={startDateTime}
              minDateMessage={'Invalid Range'}
              invalidDateMessage={'Invalid DateTime'}
              onError={(e) => {
                if (e) setBothDateValid(false);
              }}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: 'none' },
              }}
            />
            <Tooltip title={`Next Date`}>
              <IconButton
                style={{ backgroundColor: '#e2e2e2', height: 38, width: 38 }}
                onClick={() => {
                  changeDatesAction(true);
                }}
              >
                <NextIcon />
              </IconButton>
            </Tooltip>
            <Button
              style={{ marginTop: 5, marginRight: 2, marginLeft: 6 }}
              variant="contained"
              color="primary"
              disabled={!isBothDateValid}
              onClick={() => {
                if (
                  reportStartDate !== '' &&
                  reportEndDate !== '' &&
                  isBothDateValid
                ) {
                  getAllAvgData(reportStartDate, reportEndDate);

                  if (selectedTerminalId && selectedTerminalId !== 0) {
                    getRouteLegsData(reportStartDate, reportEndDate);
                  } else {
                    //no terminal is selected
                    setPageLoadingSelected(false);
                    setChartData([]);

                    set5HighestExportData([]);
                    set5HighestOutboundData([]);
                    set5HighestImportData([]);
                    set5HighestInboundData([]);
                    set5LowestExportData([]);
                    set5LowestOutboundData([]);
                    set5LowestImportData([]);
                    set5LowestInboundData([]);
                  }
                }
              }}
            >
              {`VIEW`}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        {isLoadingAll ? (
          <Grid item xs={9}>
            <Typography variant="subtitle1" align="center">
              <strong>{t('validation.loadingApi')}</strong>
            </Typography>
          </Grid>
        ) : chartDataForAll && chartDataForAll.length > 0 ? (
          <Grid item xs={12}>
            <div
              style={{
                width: '100%',
                backgroundColor: '#68bc46',
                marginTop: 10,
              }}
            >
              <Typography variant="subtitle1" align="center">
                <strong>{t('validation.routesAllTerminals')}</strong>
              </Typography>
            </div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'lightgrey' }}>
                    <TableCell>Terminal Name</TableCell>
                    <TableCell align="right">IMPORT</TableCell>
                    <TableCell align="right">EXPORT</TableCell>
                    <TableCell align="right">INBOUND</TableCell>
                    <TableCell align="right">OUTBOUND</TableCell>
                    <TableCell align="right">Load</TableCell>
                    <TableCell align="right">Unload</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chartDataForAll.map((row) => (
                    <TableRow key={row.terminalName}>
                      <TableCell component="th" scope="row">
                        {`${row.terminalName} - ${row.terminalCountryCode}`}
                      </TableCell>
                      <TableCell align="right">
                        {`${row.factorIMPORT}%`}
                      </TableCell>
                      <TableCell align="right">
                        {`${row.factorEXPORT}%`}
                      </TableCell>
                      <TableCell align="right">
                        {`${row.factorINBOUND}%`}
                      </TableCell>
                      <TableCell align="right">
                        {`${row.factorOUTBOUND}%`}
                      </TableCell>
                      <TableCell align="right">{`${row.totalLoadPPL}`}</TableCell>
                      <TableCell align="right">
                        {`${row.totalUnloadPPL}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Grid item xs={9}>
            <Typography variant="subtitle1" align="center">
              <strong>{t('validation.noDataForAll')}</strong>
            </Typography>
          </Grid>
        )}
      </Grid>

      {isLoadingSelected ? (
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            <strong>{t('validation.loadingApi')}</strong>
          </Typography>
        </Grid>
      ) : selectedTerminalId > 0 && chartData && chartData.length > 0 ? (
        <Grid container item xs={12} spacing={2}>
          <div
            style={{
              width: '100%',
              backgroundColor: '#68bc46',
              marginTop: 12,
            }}
          >
            <Typography variant="subtitle1" align="center">
              <strong>{t('validation.routesOneHighLow')}</strong>
            </Typography>
          </div>

          <TableForLowest type="IMPORT" />
          <TableForHighest type="IMPORT" />

          <TableForLowest type="INBOUND" />
          <TableForHighest type="INBOUND" />

          <TableForLowest type="EXPORT" />
          <TableForHighest type="EXPORT" />

          <TableForLowest type="OUTBOUND" />
          <TableForHighest type="OUTBOUND" />
        </Grid>
      ) : selectedTerminalId > 0 ? (
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            <strong>{t('validation.noLegRoutesData')}</strong>
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            <strong>{t('validation.plsSelectTerminal')}</strong>
          </Typography>
        </Grid>
      )}
    </>
  );
}
