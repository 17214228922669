import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDate } from '../components/SelectDate';
import {
  CustomerLevelDeviationResponse,
  DeviationFault,
  EditLocationDeviationInput,
  EditLocationDeviationListItemsInput,
  LocationDeviationInput,
  useCreateLocationDeviationMutation,
  useDeleteDeviationMutation,
  useEditLocationDeviationMutation,
  useGetLocationDeviationsLazyQuery,
  useGetMeQuery,
} from '../generated/graphql';
import { DATE_FORMAT } from '../lib/date_time';
import { RemainingGoodsModal } from '../components/modal/RemainingGoodsModal';
import { RemainingGoodsList } from './RemainingGoodsList';
import { EditRemainingGoodsModal } from '../components/forms/EditRemainingGoods';
import { getTFTMasterDate } from '../lib/date';

const useStyles = makeStyles((theme: Theme) => ({
  selectDateRoot: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    justifyContent: 'space-between',
    '& form > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
  },
  filterButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  rootGrid: {
    marginTop: 2,
  },
}));
interface RemainingGoodsProps {
  terminal: {
    id: number;
    name: string;
    terminal: { locations: { id: number }[] };
  };
}

export type RemainingGoodsObj = {
  obj: LocationDeviationInput[];
};

export type EditRemainingGoodsObj = {
  editObj: EditLocationDeviationInput;
};

export function RemainingGoodsInTerminal(props: RemainingGoodsProps) {
  const { t } = useTranslation();

  const { terminal } = props;
  const [date, setDate] = useState<string>(
    format(getTFTMasterDate(true), DATE_FORMAT),
  );

  const [totalPallets, setTotalPallets] = useState<number>(0);
  const [totalPalletSpace, setTotalPalletSpace] = useState<number>(0);

  const [showModal, setModalVisible] = useState(false);

  const [initValueForGoodsItem, setInitValueForGoodsItem] =
    useState<RemainingGoodsObj>({
      obj: [
        {
          id: undefined,
          faultLocationId: terminal.terminal.locations[0].id,
          deviationApplicableDate: date,
          toLocationId: 0,
          fault: DeviationFault.FaultFromTerminal,
          note: '',
          consequence: false,
          customerDeviationListItemsInput: [
            {
              locationId: 0,
              packages: null,
              pallets: null,
              palletSpace: null,
              weight: null,
              locationItemNote: '',
              reason: undefined,
              otherReason: '',
              newCustomer: '',
              isNewCustomer: false,
              isDangerous: false,
            },
          ],
        },
      ],
    });

  const [currentGoodsArray, setCurrentGoodsArray] = useState<
    CustomerLevelDeviationResponse[]
  >([]);
  const [refetchData, setRefetchData] = useState<boolean>(false);
  const { data: getme } = useGetMeQuery();
  const [filteredRowsData, setFilteredRowsData] = useState({});
  useEffect(() => {
    if (_.isEmpty(filteredRowsData)) {
      //filter not applied
      if (currentGoodsArray && currentGoodsArray.length > 0) {
        setTotalPallets(_.sumBy(currentGoodsArray, 'pallets') ?? 0);
        setTotalPalletSpace(_.sumBy(currentGoodsArray, 'palletSpace') ?? 0);
      } else {
        setTotalPallets(0);
        setTotalPalletSpace(0);
      }
    } else {
      if (currentGoodsArray && currentGoodsArray.length > 0) {
        //filter is applied
        const visibleItems: any[] = [];
        for (const [id, value] of Object.entries(filteredRowsData)) {
          if (value === true) {
            visibleItems.push(id);
          }
        }
        const filteredRowsOnly = currentGoodsArray.filter((item) =>
          visibleItems.includes(item.id?.toString()),
        );
        if (filteredRowsOnly) {
          setTotalPallets(_.sumBy(filteredRowsOnly, 'pallets') ?? 0);
          setTotalPalletSpace(_.sumBy(filteredRowsOnly, 'palletSpace') ?? 0);
        } else {
          setTotalPallets(0);
          setTotalPalletSpace(0);
        }
      } else {
        setTotalPallets(0);
        setTotalPalletSpace(0);
      }
    }
  }, [filteredRowsData]);

  const [editDeviation, setEditDeviation] = useState<boolean>(false);
  const [editCustomerDeviation, setEditCustomerDeviation] =
    useState<EditLocationDeviationInput>({
      id: -1,
      deviationApplicableDate: undefined,
      consequence: false,
      fault: DeviationFault.BringCustoms,
      faultLocationId: 0,
      toLocationId: 0,
      note: '',
      customerDeviationListItemsInput: [],
    });

  const handleEditClicked = (
    edit: EditLocationDeviationInput,
    EditMode: boolean,
  ) => {
    setEditCustomerDeviation({
      id: edit.id,
      routeId: edit.routeId,
      faultLocationId: edit.faultLocationId,
      toLocationId: edit.toLocationId,
      fault: edit.fault,
      consequence: edit.consequence,
      deviationApplicableDate: edit.deviationApplicableDate,
      note: edit.note,
      customerDeviationListItemsInput: [],
    });
    setEditDeviation(EditMode);
  };

  const classes = useStyles();
  const [getLocationDeviationsLazy, { data: custData, loading: custLoading }] =
    useGetLocationDeviationsLazyQuery({
      fetchPolicy: 'no-cache',
    });

  // Create Deviation
  const [createLocationDeviationMutation] =
    useCreateLocationDeviationMutation();

  //Update deviation
  const [editLocationDeviationMutation] = useEditLocationDeviationMutation();

  //delete
  const [deleteDeviation] = useDeleteDeviationMutation();

  const onDateSelected = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    setTotalPallets(0);
    setTotalPalletSpace(0);
    if (getme?.me?.internalTerminal?.terminal?.locations[0]?.id) {
      getLocationDeviationsLazy({
        variables: {
          startDate: date,
          endDate: date,
          terminalIds: [getme?.me?.internalTerminal?.terminal?.locations[0].id],
        },
      });
    }
  }, [date, refetchData, getme?.me]);

  useEffect(() => {
    if (custLoading === false && custData && custData.getLocationDeviations) {
      //TEMP filter - need to get filtered from API
      const onlyCustomerLevelArr = custData.getLocationDeviations.filter(
        (d) => d.type !== 'Route',
      );
      const sortedArr = _.orderBy(onlyCustomerLevelArr, 'id', 'asc');
      setCurrentGoodsArray(sortedArr);
    }
  }, [custData, custLoading]);

  useEffect(() => {
    //calculate totals
    if (currentGoodsArray && currentGoodsArray.length > 0) {
      setTotalPallets(_.sumBy(currentGoodsArray, 'pallets') ?? 0);
      setTotalPalletSpace(_.sumBy(currentGoodsArray, 'palletSpace') ?? 0);
    } else {
      setTotalPallets(0);
      setTotalPalletSpace(0);
    }
  }, [currentGoodsArray]);

  const onEditCustomerDeviationSubmit = (
    editlocationDeviationInput: EditLocationDeviationInput,
  ) => {
    const deviationToLocationArr: EditLocationDeviationListItemsInput[] = [];
    editlocationDeviationInput.customerDeviationListItemsInput.forEach(
      (customerDeviationListItem) => {
        deviationToLocationArr.push({
          deviationId: customerDeviationListItem.deviationId,
          id: customerDeviationListItem.id ?? 0,
          locationId: customerDeviationListItem.locationId,
          locationItemNote: customerDeviationListItem.locationItemNote,
          pallets: customerDeviationListItem.pallets,
          packages: customerDeviationListItem.packages,
          palletSpace: customerDeviationListItem.palletSpace,
          weight: customerDeviationListItem.weight,
          reason: customerDeviationListItem.reason,
          otherReason: customerDeviationListItem.otherReason,
          newCustomer: customerDeviationListItem.newCustomer,
          isDangerous: customerDeviationListItem.isDangerous,
        });
      },
    );
    editLocationDeviationMutation({
      variables: {
        input: {
          ...editlocationDeviationInput,
          customerDeviationListItemsInput: deviationToLocationArr,
        },
      },
    }).then(() => {
      setRefetchData(!refetchData);
    });
    setEditDeviation(false);
  };

  return (
    <Grid container spacing={1} className={classes.rootGrid}>
      {showModal && (
        <RemainingGoodsModal
          open={showModal}
          handleClose={() => {
            setModalVisible(false);
          }}
          handleSubmit={(value) => {
            //add
            createLocationDeviationMutation({
              variables: {
                input: [...value],
              },
            }).then(() => {
              setRefetchData(!refetchData);
            });
            setModalVisible(false);
          }}
          terminalName={terminal.name}
          initialValue={initValueForGoodsItem}
        />
      )}
      {editDeviation && (
        <EditRemainingGoodsModal
          initialValue={{
            ...editCustomerDeviation,
            customerDeviationListItemsInput: [],
          }}
          handleSubmit={(data: EditLocationDeviationInput) => {
            onEditCustomerDeviationSubmit({
              ...data,
            });
          }}
          handleClose={() => {
            setEditDeviation(false);
          }}
          onDeleteAction={(id: number) => {
            deleteDeviation({
              variables: { id },
            }).then(() => {
              setRefetchData(!refetchData);
            });
            setEditDeviation(false);
          }}
          open={editDeviation}
          terminalName={terminal.name}
        />
      )}
      <Grid item xs={12}>
        <Paper className={classes.selectDateRoot}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <Typography variant="body1">{terminal.name}</Typography>
          </Box>

          <SelectDate
            label={t('attributes.productionDate')}
            currentDate={date}
            onDateSelected={onDateSelected}
          />
        </Paper>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={10} alignContent="center">
          <Typography style={{ fontWeight: 'bolder', display: 'inline-block' }}>
            {`Total pallets: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalPallets}
            </Typography>
            {` | Total pallet space: `}
            <Typography
              style={{
                fontWeight: 'bolder',
                color: '#69bc46',
                display: 'inline-block',
              }}
            >
              {totalPalletSpace}
            </Typography>
          </Typography>
        </Grid>
        <Grid item container xs={2} style={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setInitValueForGoodsItem({
                obj: [
                  {
                    id: undefined,
                    faultLocationId: terminal.terminal.locations[0].id,
                    deviationApplicableDate: date,
                    toLocationId: 0,
                    consequence: true,
                    fault: DeviationFault.FaultFromTerminal,
                    note: '',
                    customerDeviationListItemsInput: [
                      {
                        locationId: 0,
                        packages: null,
                        pallets: null,
                        palletSpace: null,
                        weight: null,
                        locationItemNote: '',
                        reason: undefined,
                        otherReason: '',
                        newCustomer: '',
                        isNewCustomer: false,
                        isDangerous: false,
                      },
                    ],
                  },
                ],
              });
              setModalVisible(true);
            }}
          >
            {t('button.add', {
              item: `Item`,
            })}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} id="truckFillGrid">
        {custLoading ? (
          <Box display="flex" justifyContent="center" mt="30vh">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <div style={{ width: '100%', marginTop: 6 }}>
              <RemainingGoodsList
                currentGoodsData={currentGoodsArray}
                onEditAction={(editDeviation, openEditDeviation) =>
                  handleEditClicked(editDeviation, openEditDeviation)
                }
                onStateChangeAction={(visibleRows) => {
                  setFilteredRowsData(visibleRows);
                }}
              />
            </div>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
