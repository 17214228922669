import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';
import { useFormContext, Path, Controller } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../../views/CheckListRoutesTraffic';
import { Stack } from '@mui/material';

interface TimePickerFieldProps {
  name: string;
  controllerName: Path<CheckListRoutesFormInput>;
  readOnly?: boolean;
}
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  inputValTime: {
    height: 15,
    width: 60,
    fontSize: 15,
  },
}));

export function TimePickerCLRHF(props: TimePickerFieldProps) {
  const { name, readOnly = false, controllerName } = props;
  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    control,
    setValue: setFieldValue,
    clearErrors,
  } = useFormContext<CheckListRoutesFormInput>();

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box
          ml={1}
          mr={1}
          alignItems="center"
          border={1}
          borderColor="grey.500"
          sx={{
            minWidth: 140,
            borderRadius: 5,
          }}
          justifyContent="center"
          display="flex"
        >
          <Stack>
            <Typography style={{ fontSize: 11 }}>
              {t(`attributes.${label}`)}
            </Typography>
            <KeyboardTimePicker
              {...field}
              autoComplete="off"
              placeholder="00:00"
              mask="__:__"
              id={`ID${label}`}
              KeyboardButtonProps={{
                disabled: true,
                style: { display: 'none' },
              }}
              value={field.value ? `1-1-2023 ${field.value}` : null}
              InputProps={{
                inputProps: {
                  step: 300, // 5 min
                  style: {
                    padding: 4,
                  },
                },
                readOnly,
                style: {
                  fontSize: 13,
                },
                classes: { input: classes.inputValTime },
                disableUnderline: true,
                maxRows: 1,
              }}
              invalidDateMessage={'Invalid'}
              ampm={false}
              error={error && Boolean(error)}
              helperText={error && Boolean(error) && error.message}
              onChange={(date) => {
                date ? date.toString() : '';
                if (date && date.toString() !== 'Invalid Date') {
                  const dt = new Date(date);
                  const newDt = format(dt, 'HH:mm');
                  clearErrors(controllerName);
                  setFieldValue(controllerName, newDt);
                } else {
                  setFieldValue(controllerName, date ? date.toString() : '');
                }
              }}
            />
          </Stack>
        </Box>
      )}
    />
  );
}
