import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { sortBy } from 'lodash';
import { COUNTRIES } from '../../components/AddTransport';
import { FileItem } from '../../components/FileListComponent';
import { Days, Maybe } from '../../generated/graphql';
import { getFirstOneLeg, getLastOneLeg } from '../../lib/legs';
import { removeSecondsFromTimeString } from '../../lib/time';
import { calculateTourTransportationDates } from '../../lib/tour_leg_dates';
import RobotoBold from '../assets/Roboto-Bold.ttf';
import Roboto from '../assets/Roboto-Regular.ttf';
import Logo from '../pdfImages/logo.png';

Font.register({
  family: 'Roboto',
  format: 'truetype',
  fontWeight: 'bold',
  src: RobotoBold,
});
Font.register({
  family: 'Roboto',
  format: 'truetype',
  src: Roboto,
});

// Create styles
const classes = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 24,
    width: '100%',
    fontFamily: 'Roboto',
  },
  title: {
    margin: 10,
  },
  semititle: {
    marginTop: 18,
    marginLeft: 8,
    fontSize: 13,
    border: '1px solid #c2c2c2',
    padding: 4,
  },
  semititleRoute: {
    marginTop: 8,
    marginLeft: 6,
    fontSize: 13,
    border: '1px solid #c2c2c2',
    padding: 3,
  },
  routeNamesView: {
    marginLeft: 4,
    marginTop: 4,
    marginBottom: 8,
  },
  pageNoView: {
    alignSelf: 'center',
    position: 'absolute',
    fontSize: 9,
    bottom: 28,
    padding: 1,
  },
  infoField: {
    width: '40%',
    fontSize: 10,
    fontWeight: 300,
    margin: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoFieldBold: {
    width: '40%',
    fontSize: 10,
    fontWeight: 600,
    margin: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  routeNameBold: {
    width: '50%',
    fontSize: 11,
    fontWeight: 600,
    margin: 4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoFieldNote: {
    width: '90%',
    fontSize: 10,
    fontWeight: 600,
    margin: 4,
  },
  infoFieldDays: {
    width: '90%',
    marginTop: 4,
    marginBottom: 4,
    fontSize: 11,
    fontWeight: 600,
    marginLeft: 12,
  },
  infoBox: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '0pt 10pt',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'grey',
  },
  section: {
    padding: '20pt 10pt',
  },
  viewer: {
    height: '100vh',
  },
  routeStyles: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  routeTitle: {
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 10,
  },
  routeLegRegNoField: {
    fontWeight: 300,
    fontSize: 8,
    width: '22%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  routeAttributeFieldsIndex: {
    fontWeight: 600,
    fontSize: 10,
    width: '2%',
  },
  routeAttributeFields: {
    fontWeight: 600,
    fontSize: 8,
    width: '24%',
  },
  routeAttributeFieldsTime: {
    fontWeight: 600,
    fontSize: 8,
    width: '5%',
  },
  routeField: {
    fontWeight: 300,
    fontSize: 8,
    width: '24%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  routeFieldTime: {
    fontWeight: 300,
    fontSize: 8,
    width: '5%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  oneRoute: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 4,
    fontSize: 10,
  },
});

export interface TourTransportAgreementInterface {
  id: string;
  name: string;
  subcontractorName: string;
  dispatcherName?: Maybe<string>;
  tourAgreementNo: number;
  price?: Maybe<number>;
  currency: string;
  note: string;
  licensePlate: Maybe<string>;
  createdBy: string;
  routes: {
    id?: number;
    capacity?: Maybe<number>;
    kilometer?: Maybe<number>;
    weight?: Maybe<number>;
    startDate: string;
    originalStartDate?: Maybe<string>;
    endDate?: Maybe<string>;
    startExceptionDate?: Maybe<string>;
    endExceptionDate?: Maybe<string>;
    hasExceptionDates: boolean;
    routeId: string;
    files: FileItem[];
    price?: Maybe<number>;
    note: string;
    externalNote: string;
    days: Days[];
    legs: {
      id?: number;
      key?: string;
      location: {
        name: string;
        address: string;
        city: string;
        country: string;
        postalCode: string;
      };
      position: number;
      load: boolean;
      unload: boolean;
      arrivalTime: Maybe<string>;
      departureTime?: Maybe<string>;
      note?: string;
      transportationDateOffset: number;
      carRegistrationNumber?: Maybe<string>;
      trailerRegistrationNumber?: Maybe<string>;
    }[];
  }[];
}

function getCountry(country: string) {
  return COUNTRIES[country as keyof typeof COUNTRIES];
}

type TourTransportAgreementProps = {
  tour: TourTransportAgreementInterface;
};

// Create Document Component
export const TourTransportAgreement = (props: TourTransportAgreementProps) => {
  const { tour } = props;

  return (
    <Document
      title={`Tour Transport Agreement`}
      author="Posten - Nexus linehaul"
    >
      <Page size="A4" style={classes.page}>
        <Image
          style={{
            width: 'auto',
          }}
          src={Logo}
          fixed
        />
        <View fixed style={classes.title}>
          <Text>TOUR TRANSPORT AGREEMENT</Text>
        </View>
        <View>
          <View style={classes.infoBox}>
            <Text style={classes.infoFieldBold}>
              Tour Name: <Text style={classes.infoField}>{tour.name} </Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Agreement No:{' '}
              <Text style={classes.infoField}>
                {`${tour.id}${tour.tourAgreementNo}`}{' '}
              </Text>
            </Text>
          </View>
          <View style={classes.divider}></View>
          <View style={classes.infoBox}>
            <Text style={classes.infoFieldBold}>
              Subcontractor:{' '}
              <Text style={classes.infoField}>{tour.subcontractorName}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Dispatcher:{' '}
              <Text style={classes.infoField}>{tour.dispatcherName}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Price:{' '}
              <Text style={classes.infoField}>{`${
                tour.price
                  ? `${tour.price} ${
                      tour.currency ? tour.currency?.toUpperCase() : ''
                    }`
                  : ''
              }`}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Contractor Bring:{' '}
              <Text style={classes.infoField}>{tour.createdBy}</Text>
            </Text>

            <Text style={classes.infoFieldBold}>
              Tour Note:{' '}
              <Text style={classes.infoField}>{tour.note ?? ''}</Text>
            </Text>
            <Text style={classes.infoFieldBold}>
              Total Routes:{' '}
              <Text style={classes.infoField}>
                {tour.routes ? tour.routes.length : 0}
              </Text>
            </Text>
          </View>
          <View style={classes.divider}></View>
          <View style={classes.semititle}>
            <Text>TOUR ROUTES ID</Text>
          </View>
          <View style={classes.routeNamesView}>
            {tour.routes && tour.routes.length > 0 ? (
              tour.routes.map((route, i) => {
                return (
                  <>
                    <Text style={classes.routeNameBold}>
                      {`#${i + 1}- ${route.routeId} `}{' '}
                      <Text style={classes.infoField}>
                        {` (${route.days
                          .map((x) => x.toUpperCase())
                          .join(' - ')})`}
                      </Text>
                    </Text>
                  </>
                );
              })
            ) : (
              <Text>No Routes</Text>
            )}
          </View>
        </View>
        <View style={classes.pageNoView}>
          <Text render={({ pageNumber }) => `Page ${pageNumber}`} />
        </View>
      </Page>
      {tour.routes &&
        tour.routes.length > 0 &&
        tour.routes.map((route, i) => {
          const sortedLegs = sortBy(route.legs, 'position');
          const legsWithDays = calculateTourTransportationDates(sortedLegs);

          return (
            <Page size="A4" style={classes.page} key={`p${i}`}>
              <Image
                style={{
                  width: 'auto',
                }}
                src={Logo}
                fixed
              />
              <View fixed style={classes.title}>
                <Text>TOUR TRANSPORT AGREEMENT</Text>
              </View>
              <Text style={classes.semititleRoute}>{`#${i + 1}- ${
                route.routeId
              }`}</Text>

              <View style={classes.infoBox} key={`v${i + 1}`}>
                <Text style={classes.infoFieldBold}>
                  Departure City:{' '}
                  <Text style={classes.infoField}>
                    {getFirstOneLeg(sortedLegs)?.location?.city || ''}
                  </Text>
                </Text>
                {sortedLegs.length && (
                  <>
                    <Text style={classes.infoFieldBold}>
                      Departure Country:{' '}
                      <Text style={classes.infoField}>
                        {getCountry(
                          getFirstOneLeg(sortedLegs)?.location?.country || '',
                        ) || ''}
                      </Text>
                    </Text>
                    <Text style={classes.infoFieldBold}>
                      Arrival City:{' '}
                      <Text style={classes.infoField}>
                        {getLastOneLeg(sortedLegs)?.location?.city || ''}
                      </Text>
                    </Text>
                    <Text style={classes.infoFieldBold}>
                      Arrival Country:{' '}
                      <Text style={classes.infoField}>
                        {getCountry(
                          getLastOneLeg(sortedLegs)?.location?.country || '',
                        ) || ''}
                      </Text>
                    </Text>
                  </>
                )}
                <Text style={classes.infoFieldBold}>
                  Start Date:{' '}
                  <Text style={classes.infoField}>{route.startDate}</Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  Exception Start Date:{' '}
                  <Text style={classes.infoField}>
                    {route.hasExceptionDates ? route.startExceptionDate : ''}
                  </Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  End Date:{' '}
                  <Text style={classes.infoField}>{`${
                    route.endDate ? route.endDate : ''
                  }`}</Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  Exception End Date:{' '}
                  <Text style={classes.infoField}>
                    {route.hasExceptionDates ? route.endExceptionDate : ''}
                  </Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  Capacity:{' '}
                  <Text style={classes.infoField}>{route.capacity ?? ''}</Text>
                </Text>
                <Text style={classes.infoFieldBold}>
                  Weight:{' '}
                  <Text style={classes.infoField}>
                    {`${
                      route.weight != null ? route.weight + ' kg' : ''
                    }    ` ?? '    '}
                  </Text>
                  <Text style={classes.infoFieldBold}>
                    Kilometer:{' '}
                    <Text style={classes.infoField}>
                      {route.kilometer ?? ''}
                    </Text>
                  </Text>
                </Text>
                <Text style={classes.infoFieldNote}>
                  Note:{' '}
                  <Text style={classes.infoField}>{route.note || ''}</Text>
                </Text>
              </View>
              <View style={{ marginTop: 8 }}>
                <View style={classes.divider}></View>
                <Text style={classes.infoFieldDays}>
                  Route Schedule:{' '}
                  <Text>
                    {route.days.map((x) => x.toUpperCase()).join(' - ')}
                  </Text>
                </Text>
                <View style={classes.divider}></View>
              </View>
              <View style={classes.section}>
                <Text style={classes.routeTitle}>Legs: </Text>
                <View>
                  <View style={classes.routeStyles}>
                    <Text style={classes.routeAttributeFieldsIndex}>#</Text>
                    <Text style={classes.routeAttributeFields}>Location</Text>
                    <Text style={classes.routeAttributeFields}>Address </Text>
                    <Text style={classes.routeAttributeFields}>
                      P.Code & City{' '}
                    </Text>
                    <Text style={classes.routeAttributeFieldsTime}>
                      Arrival{' '}
                    </Text>
                    <Text style={classes.routeAttributeFieldsTime}>
                      Depart{' '}
                    </Text>
                    <Text style={classes.routeAttributeFieldsTime}>Day </Text>
                  </View>
                  {legsWithDays.map((leg, index) => {
                    return (
                      <>
                        <View key={index}>
                          <View style={classes.oneRoute}>
                            <Text style={classes.routeAttributeFieldsIndex}>
                              {index + 1}
                            </Text>
                            <Text style={classes.routeField}>
                              {leg.location?.name || ''}
                            </Text>
                            <Text style={classes.routeField}>
                              {leg.location?.address || ''}{' '}
                            </Text>
                            <Text style={classes.routeField}>
                              {`${leg.location?.postalCode} - ${leg.location?.city}` ||
                                ''}
                            </Text>
                            <Text style={classes.routeFieldTime}>
                              {removeSecondsFromTimeString(leg.arrivalTime) ??
                                ''}
                            </Text>
                            <Text style={classes.routeFieldTime}>
                              {removeSecondsFromTimeString(leg.departureTime) ??
                                ''}
                            </Text>
                            <Text style={classes.routeFieldTime}>
                              {`Day ${leg.daysDiff}`}
                            </Text>
                          </View>
                        </View>
                        {(leg.note && leg.note != '') ||
                        (leg.carRegistrationNumber &&
                          leg.carRegistrationNumber != '') ||
                        (leg.trailerRegistrationNumber &&
                          leg.trailerRegistrationNumber != '') ? (
                          <View style={classes.oneRoute}>
                            <Text
                              style={classes.routeAttributeFieldsIndex}
                            >{``}</Text>
                            <Text style={classes.routeField}>{``}</Text>
                            {leg.note && leg.note != '' ? (
                              <Text style={classes.routeLegRegNoField}>
                                {`Note: ${leg.note}`}
                              </Text>
                            ) : (
                              <Text
                                style={classes.routeLegRegNoField}
                              >{``}</Text>
                            )}
                            {leg.carRegistrationNumber &&
                            leg.carRegistrationNumber != '' ? (
                              <Text style={classes.routeLegRegNoField}>
                                {`Car RegNo: ${leg.carRegistrationNumber}`}
                              </Text>
                            ) : (
                              <Text
                                style={classes.routeLegRegNoField}
                              >{``}</Text>
                            )}
                            {leg.trailerRegistrationNumber &&
                            leg.trailerRegistrationNumber != '' ? (
                              <Text style={classes.routeLegRegNoField}>
                                {`Trailer RegNo: ${leg.trailerRegistrationNumber}`}
                              </Text>
                            ) : (
                              <Text
                                style={classes.routeLegRegNoField}
                              >{``}</Text>
                            )}
                          </View>
                        ) : null}
                        <View style={classes.divider}></View>
                      </>
                    );
                  })}
                </View>
              </View>
              <View style={classes.pageNoView}>
                <Text render={({ pageNumber }) => `Page ${pageNumber}`} />
              </View>
            </Page>
          );
        })}
    </Document>
  );
};
