import { Button, Grid, List, ListItem } from '@material-ui/core';
import { Alert, debounce } from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  CostAllocationItemType,
  Maybe,
  useGetDepartmentsQuery,
} from '../../generated/graphql';
import { newCostAllocationItem } from '../../lib/formHelpers/costAllocation';
import { calculateFromPercentage } from '../../lib/percentage';
import {
  useFormContext,
  useFormState,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { HideableFormFieldsRHF } from './HideableFormFieldsRHF';
import { CostAllocationItemRHF } from './CostAllocationItemRHF';
import { CostAllocationPriceLable } from './CostAllocationPriceLable';

export const validationSchemaRouteCostAllocationRHF = () =>
  yup.object({
    items: yup
      .array(
        yup
          .object({
            departmentId: yup.string(),
            type: yup.string().oneOf(Object.values(CostAllocationItemType)),
            includedKpi: yup.bool(),
            includedPrice: yup.bool(),
            cost: yup.number().nullable().required(),
            percentage: yup.number(),
            comment: yup.string(),
          })
          .omit(['percentage']),
      )
      .test('items', 'Values need to be same as agreed price', (value, ctx) => {
        const context = ctx as unknown as {
          from: [
            { value?: { agreedPrice: number | string } },
            { value?: { agreedPrice: number | string } },
          ];
        };

        const agreedPrice = context?.from[1]?.value?.agreedPrice;

        const price: number =
          typeof agreedPrice === 'number'
            ? agreedPrice
            : typeof agreedPrice === 'string' && agreedPrice !== ''
            ? parseInt(agreedPrice)
            : 0;

        const totalCost: number =
          value
            ?.filter((x) => x.includedPrice)
            .reduce(
              (sum: number, x: { cost?: Maybe<number> }) => sum + (x.cost ?? 0),
              0,
            ) || 0;

        const diff =
          price - totalCost > 0
            ? `${Math.abs(price - totalCost)} missing`
            : `${Math.abs(price - totalCost)} too much`;
        if (price !== totalCost) {
          return ctx.createError({
            path: ctx.path,
            message: `Cost allocations with cost allocations should sum up to ${price}. Diff: ${diff}`,
          });
        }

        return true;
      }),
  });

export interface CostAllocationItemInterface {
  id?: number;
  departmentId: string;
  type: CostAllocationItemType;
  includedKpi: boolean;
  includedPrice: boolean;
  bringCost: boolean;
  additionalCost: boolean;
  additionalDiscount: boolean;
  percentage?: number;
  cost: number;
  comment: string;
}

interface CostAllocationFieldsProps {
  readOnly?: boolean;
  show: boolean;
  onHideClicked: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function CostAllocationFieldsRHF(props: CostAllocationFieldsProps) {
  const { readOnly = false, show, onHideClicked, setOpen } = props;
  const { t } = useTranslation();
  const {
    control,
    getValues: getFieldProps,
    setValue: setFieldValue,
  } = useFormContext<SporadicRouteFormInput>();
  const { errors } = useFormState<SporadicRouteFormInput>();
  const { data: departmentsData, loading } = useGetDepartmentsQuery();

  let totalIncludedCost = 0;
  let totalBringCost = 0;
  let totalAdditionalCost = 0;
  let totalAdditionalDiscount = 0;
  let newAgreedPrice = 0;
  let finalEveryCost = 0;
  let finalAdditionalCost = 0;

  const [agreedPrice] = useWatch({
    control,
    name: ['agreedPrice'],
  });

  const items = getFieldProps(
    'costAllocation.items',
  ) as CostAllocationItemInterface[];

  const { fields, append, remove } = useFieldArray({
    name: 'costAllocation.items',
    control,
  });

  const updateCost = (agreedPrice: number) => {
    (items || []).forEach((item, index) => {
      if (item.includedPrice) {
        setFieldValue(
          `costAllocation.items.${index}.cost`,
          calculateFromPercentage(agreedPrice, item.percentage ?? 0),
        );
      }
    });
    //update totals
    if (items) {
      totalIncludedCost = items
        .filter((i) => i.includedPrice === true)
        .reduce((a, item) => {
          return a + item.cost;
        }, 0);

      totalBringCost = items
        .filter((i) => i.includedPrice === false && i.bringCost === true)
        .reduce((a, item) => {
          return a + item.cost;
        }, 0);

      totalAdditionalCost = items
        .filter((i) => i.includedPrice === false && i.additionalCost === true)
        .reduce((a, item) => {
          return a + item.cost;
        }, 0);

      totalAdditionalDiscount = items
        .filter(
          (i) => i.includedPrice === false && i.additionalDiscount === true,
        )
        .reduce((a, item) => {
          return a + item.cost;
        }, 0);

      //newAgreedPrice is for subcontractor - excluding bring cost
      newAgreedPrice =
        totalIncludedCost + totalAdditionalCost - totalAdditionalDiscount;
      //totalEvery is all substracting discount
      finalEveryCost =
        totalIncludedCost +
        totalBringCost +
        totalAdditionalCost -
        totalAdditionalDiscount;

      //only additional cost substracting discount
      finalAdditionalCost = totalAdditionalCost - totalAdditionalDiscount;
      setFieldValue(`totalBringCost`, totalBringCost);
      setFieldValue(`newAgreedPrice`, newAgreedPrice);
      setFieldValue(`finalEveryCost`, finalEveryCost);
      setFieldValue(`finalAdditionalCost`, finalAdditionalCost);
    }
  };

  const updateCostDebounce = useCallback(debounce(updateCost, 400), [items]);

  useEffect(() => {
    if (agreedPrice != undefined) {
      updateCostDebounce(agreedPrice);
    }
  }, [agreedPrice, items]);

  const costAllocationErrors = errors.costAllocation?.items?.message;
  const values = { items };
  if (items) {
    totalIncludedCost = items
      .filter((i) => i.includedPrice === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    totalBringCost = items
      .filter((i) => i.includedPrice === false && i.bringCost === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    totalAdditionalCost = items
      .filter((i) => i.includedPrice === false && i.additionalCost === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    totalAdditionalDiscount = items
      .filter((i) => i.includedPrice === false && i.additionalDiscount === true)
      .reduce((a, item) => {
        return a + item.cost;
      }, 0);

    //newAgreedPrice is for subcontractor - excluding bring cost
    newAgreedPrice =
      totalIncludedCost + totalAdditionalCost - totalAdditionalDiscount;
    //totalEvery is all substracting discount
    finalEveryCost =
      totalIncludedCost +
      totalBringCost +
      totalAdditionalCost -
      totalAdditionalDiscount;

    //only additional cost substracting discount
    finalAdditionalCost = totalAdditionalCost - totalAdditionalDiscount;
    setFieldValue(`totalBringCost`, totalBringCost);
    setFieldValue(`newAgreedPrice`, newAgreedPrice);
    setFieldValue(`finalEveryCost`, finalEveryCost);
    setFieldValue(`finalAdditionalCost`, finalAdditionalCost);
  }
  if (loading || !departmentsData) {
    return <div>loading...</div>;
  }

  return (
    <HideableFormFieldsRHF
      title={t('resource.costAllocation.plural')}
      show={show}
      onHideClicked={onHideClicked}
      errors={[costAllocationErrors]}
      setOpen={setOpen}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              {typeof costAllocationErrors === 'string' ? (
                <Grid item xs={12}>
                  <Alert severity="error">{costAllocationErrors}</Alert>
                </Grid>
              ) : null}
              {!readOnly && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        append(
                          newCostAllocationItem(
                            [{ id: values.items[0]?.departmentId ?? 'se' }],
                            values.items.reduce(
                              (sum, x) => sum + (x.percentage ?? 0),
                              0,
                            ),
                          ),
                        );
                      }}
                    >
                      {t('button.add', {
                        item: t('resource.costAllocation.lowercased'),
                      })}
                    </Button>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <CostAllocationPriceLable />
                <List>
                  {fields.map((item, index) => (
                    <ListItem key={item.id} divider>
                      <CostAllocationItemRHF
                        key={`ID${item.id}`}
                        itemIndex={index}
                        readOnly={readOnly}
                        removeItem={(i) => remove(i)}
                        agreedPrice={agreedPrice ?? 0}
                        totalLength={values.items.length}
                        departmentsData={departmentsData}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </HideableFormFieldsRHF>
  );
}
