import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  makeStyles,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from '../svgIcons/infoicon.svg';
import { ReactComponent as ImportantIcon } from '../svgIcons/important.svg';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  DocumentFile,
  GetInternalTerminalReportInfoQuery,
  InfoType,
  InternalTerminalReportInfo,
  useDeleteAttachmentFileMutation,
  useGetInternalTerminalReportInfoQuery,
} from '../generated/graphql';
import { ReportToTerminalEditInfoModal } from './modal/ReportToTerminalEditInfoModal';
import { useHttpClient } from '../providers/HttpClientProvider';
import { UploadInfoDocModal } from './modal/UploadInfoDocModal';

interface ReportToTerminalInfoProps {
  selectedTerminal: {
    id: number;
    name: string;
  };
  selectedDateAndDay: { date: string; day: string };
  isForTerminal: boolean;
  infoDataFunction?: (data: GetInternalTerminalReportInfoQuery) => void;
}

const DarkerDisabledTextField = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
})(TextField);
const DarkerDisabledTextFieldForReply = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.7)',
      fontSize: 14,
    },
  },
})(TextField);

const useStyle = makeStyles(() => ({
  btnBorder: {
    minWidth: '0px',
    width: 'fit-content',
    border: '1px solid #68bc46',
  },
  btnNormal: {
    minWidth: '0px',
    width: 'fit-content',
  },
}));

const POLLING_INTERVALL = 60 * 1000;
export function ReportToTerminalInfo(props: ReportToTerminalInfoProps) {
  const { t } = useTranslation();
  const { httpClient } = useHttpClient();
  const classes = useStyle();
  const {
    selectedTerminal,
    selectedDateAndDay,
    isForTerminal,
    infoDataFunction,
  } = props;
  const { id, name } = selectedTerminal;
  const { date, day } = selectedDateAndDay;

  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [showUploadLoading, setShowUploadLoading] = useState<boolean>(false);
  const [extraData, setExtraData] = useState<{
    infoType: string;
    infoId: number;
    existingFiles: Pick<
      DocumentFile,
      'id' | 'size' | 'path' | 'originalname' | 'mimetype'
    >[];
  }>();

  const { data, loading, error, refetch } =
    useGetInternalTerminalReportInfoQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      pollInterval: POLLING_INTERVALL,
      variables: {
        internalTerminalId: id,
        dateForAddInfo: date,
        forTerminal: isForTerminal,
      },
    });

  const [deleteAttachmentFileMutation] = useDeleteAttachmentFileMutation();

  const handleUploadClick = React.useCallback(
    (data: {
      infoType: string;
      infoId: number;
      existingFiles: Pick<
        DocumentFile,
        'id' | 'size' | 'path' | 'originalname' | 'mimetype'
      >[];
    }) => {
      setExtraData(data);
      setOpenUploadModal(true);
    },
    [],
  );

  useEffect(() => {
    if (id && date) {
      refetch({
        internalTerminalId: id,
        dateForAddInfo: date,
        forTerminal: isForTerminal,
      });
    }
  }, [id, date]);

  const [selected, setSelected] = useState<
    | Pick<
        InternalTerminalReportInfo,
        | 'id'
        | 'info'
        | 'infoType'
        | 'internalTerminalId'
        | 'dateForAddInfo'
        | 'isAddedFromTerminal'
        | 'replyInfo'
        | 'replyText'
      >
    | undefined
  >(undefined);

  useEffect(() => {
    if (data && data.internalTerminalReportInfo && infoDataFunction)
      infoDataFunction(data);
  }, [data]);

  const handleShowEditInfoModal = useCallback(() => {
    setSelected(undefined);
  }, [selected]);

  if (!data || loading || error) {
    return <></>;
  }
  const infoList: GetInternalTerminalReportInfoQuery['internalTerminalReportInfo'] =
    data.internalTerminalReportInfo;
  const refinedInfo = infoList?.length
    ? infoList.flatMap((info) => {
        return {
          id: info.id,
          internalTerminalId: info.internalTerminalId,
          dateForAddInfo: info.dateForAddInfo,
          info: info.info,
          infoType: info.infoType,
          isAddedFromTerminal: info.isAddedFromTerminal,
          replyInfo: info.replyInfo ?? '',
          replyText: info.replyText ?? '',
          files: info.files,
        };
      })
    : [];

  return (
    <Paper>
      {openUploadModal ? (
        <UploadInfoDocModal
          open={openUploadModal}
          handleClose={() => {
            setOpenUploadModal(false);
          }}
          extraData={extraData}
          showLoading={showUploadLoading}
          onUploadStart={(infoId, file) => {
            if (infoId !== 0) {
              setShowUploadLoading(true);
              httpClient.uploadAttachmentFile(file, infoId, 'info').then(() => {
                setShowUploadLoading(false);
                setOpenUploadModal(false);
                //refresh Info grid
                refetch({
                  internalTerminalId: id,
                  dateForAddInfo: date,
                  forTerminal: isForTerminal,
                });
              });
            }
          }}
          onDeleteClicked={(infoId, fileId) => {
            setShowUploadLoading(true);

            deleteAttachmentFileMutation({
              variables: {
                input: {
                  entityId: infoId,
                  entityType: 'info',
                  fileId: fileId,
                },
              },
            }).then(() => {
              //refresh Info grid
              refetch({
                internalTerminalId: id,
                dateForAddInfo: date,
                forTerminal: isForTerminal,
              });
              setShowUploadLoading(false);
              setOpenUploadModal(false);
            });
          }}
        />
      ) : null}
      {selected && selected.id ? (
        <ReportToTerminalEditInfoModal
          handleClose={handleShowEditInfoModal}
          open={Boolean(selected)}
          internalTerminalReportInfo={selected}
          terminalName={name}
          dayForAddInfo={day}
        />
      ) : null}
      <List>
        {refinedInfo.length ? (
          refinedInfo.map((item) =>
            item && item.id ? (
              <ListItem
                key={item.id}
                style={{ border: '2px solid #c2c2c2', marginBottom: 4 }}
              >
                <Grid container direction="row" alignItems="flex-start">
                  <Grid item xs={1}>
                    {item.infoType === InfoType.Info ? (
                      <ListItemIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          fontSize: '1.5em',
                        }}
                      >
                        <InfoIcon fill="#0096FF" fill-rule="nonzero" />
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon
                        style={{
                          width: '1em',
                          height: '1em',
                          fontSize: '1.5em',
                        }}
                      >
                        <ImportantIcon fill="#0096FF" />
                      </ListItemIcon>
                    )}
                  </Grid>
                  <Grid item xs={9}>
                    {Boolean(
                      item.replyText &&
                        item.replyText !== '' &&
                        item.isAddedFromTerminal,
                    ) ? (
                      <DarkerDisabledTextField
                        id={item.id.toString()}
                        multiline
                        rows={2}
                        disabled={true}
                        maxRows={Infinity}
                        variant={'standard'}
                        value={item.info}
                        fullWidth={true}
                      />
                    ) : (
                      <TextField
                        id={item.id.toString()}
                        multiline
                        rows={2}
                        maxRows={Infinity}
                        variant={'standard'}
                        value={item.info}
                        fullWidth={true}
                      />
                    )}
                  </Grid>
                  <Grid item container xs={2} justifyContent="flex-end">
                    {(!item.replyText || item.replyText === '') && (
                      <>
                        <Button
                          style={{ minWidth: '0px', width: 'fit-content' }}
                          onClick={() => setSelected(item)}
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          className={
                            item.files && item.files.length > 0
                              ? classes.btnBorder
                              : classes.btnNormal
                          }
                          onClick={() => {
                            handleUploadClick({
                              infoType: `${item.infoType}`,
                              infoId: item.id,
                              existingFiles: item.files,
                            });
                          }}
                        >
                          <UploadFileIcon />
                        </Button>
                      </>
                    )}
                  </Grid>
                  {item.isAddedFromTerminal &&
                    item.replyText &&
                    item.replyText !== '' && (
                      <>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={7}>
                          <DarkerDisabledTextFieldForReply
                            id={'1' + item.id.toString()}
                            multiline
                            rows={1}
                            disabled={true}
                            maxRows={Infinity}
                            variant={'standard'}
                            value={item.replyText}
                            fullWidth={true}
                          />
                        </Grid>
                        <Grid item container alignContent="flex-end" xs={3}>
                          <Typography
                            style={{
                              fontStyle: 'italic',
                              fontSize: 12,
                            }}
                          >{`${item.replyInfo ?? ''}`}</Typography>
                        </Grid>
                      </>
                    )}
                </Grid>
              </ListItem>
            ) : (
              <></>
            ),
          )
        ) : (
          <></>
        )}

        {!refinedInfo?.length ? (
          <>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <Typography variant="subtitle1" style={{ paddingRight: '1%' }}>
                {`${t('resource.noInfoAdded.capitalized')}`}
              </Typography>
            </Box>
          </>
        ) : (
          <></>
        )}
      </List>
    </Paper>
  );
}
