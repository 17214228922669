import { useFormikContext } from 'formik';
import { CenteredBox } from '../CenteredBox';
import { SelectCurrency } from '../SelectCurrency';
import { CommonTextField } from './CommonTextField';

interface AgreementFieldsProps {
  terminal: boolean;
  readOnly: boolean;
}

export function AgreementFields({ readOnly, terminal }: AgreementFieldsProps) {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <CenteredBox>
        <CommonTextField name="name" fullWidth readOnly={readOnly} />
      </CenteredBox>
      {!terminal && (
        <CenteredBox>
          <CommonTextField
            name="price"
            type="number"
            readOnly={readOnly}
            onBlur={() => {
              setFieldValue('isPriceEquallyDivided', false);
            }}
          />
          <SelectCurrency fieldName="currency" readOnly={readOnly} />
        </CenteredBox>
      )}
    </>
  );
}
