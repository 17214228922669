import { Days } from '../../generated/graphql';

export function changeToDaysArray(allWeekDays: {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}) {
  const daysArray: Days[] = [];
  for (const [day, present] of Object.entries(allWeekDays)) {
    if (present) {
      switch (day) {
        case 'mon':
          daysArray.push(Days.Mon);
          break;
        case 'tue':
          daysArray.push(Days.Tue);
          break;
        case 'wed':
          daysArray.push(Days.Wed);
          break;
        case 'thu':
          daysArray.push(Days.Thu);
          break;
        case 'fri':
          daysArray.push(Days.Fri);
          break;
        case 'sat':
          daysArray.push(Days.Sat);
          break;
        case 'sun':
          daysArray.push(Days.Sun);
          break;
      }
    }
  }
  return daysArray;
}

export function changeFromDaysArray(activeDays: Days[]) {
  const allWeekDays = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  activeDays.forEach((day) => {
    allWeekDays[`${day.toLowerCase()}` as keyof typeof allWeekDays] = true;
  });

  return allWeekDays;
}
