import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import React from 'react';
import { createContext, ReactNode, useCallback } from 'react';
import { getAccessToken } from '../lib/azure_ad';
import { OVERRIDE_DEPARTMENT_HEADER } from '../lib/constants';
import { HttpClient } from '../lib/httpClient';
import { useUserConfiguration } from './UserConfigurationProvider';

interface HttpClientContextValues {
  httpClient: HttpClient | undefined;
}

const HttpClientContext = createContext<HttpClientContextValues>({
  httpClient: undefined,
});

interface HttpClientProviderProps {
  children: ReactNode;
}

export function HttpClientProvider({ children }: HttpClientProviderProps) {
  const { instance } = useMsal();
  const { department } = useUserConfiguration();
  const axiosInstance = axios.create({});

  axiosInstance.interceptors.request.use(
    useCallback(
      async (config) => {
        const accessToken = await getAccessToken(instance);

        const overrideDepartmentHeaders = department.override
          ? {
              [OVERRIDE_DEPARTMENT_HEADER]: department.id,
            }
          : {};

        if (accessToken != null) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
            ...overrideDepartmentHeaders,
          };
        }

        return config;
      },
      [instance, department],
    ),
  );

  const httpClient = new HttpClient(axiosInstance);

  return (
    <HttpClientContext.Provider
      value={{
        httpClient,
      }}
    >
      {children}
    </HttpClientContext.Provider>
  );
}

export function useHttpClient() {
  const context = React.useContext(HttpClientContext);
  if (context === undefined) {
    throw new Error('useHttpClient must be used within a HttpClientProvider');
  }

  return context as {
    httpClient: HttpClient;
  };
}
