import { makeStyles, TextField, TextFieldProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useFormContext, Controller, Path } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { ImportShipmentFormInput } from '../../views/ImportShipment';

const useStyles = makeStyles(() => ({
  input: {
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  smallFontSize: {
    '& input': {
      fontSize: 12,
    },
    '&.MuiOutlinedInput-multiline': {
      fontSize: 12,
    },
  },
  smallPadding: {
    '& input': {
      padding: 4,
    },
    '&.MuiOutlinedInput-multiline': {
      padding: 4,
    },
  },
}));

export function FastTextFieldUnNumberRHF(
  props: {
    name: string;
    controllerName: Path<SporadicRouteFormInput | ImportShipmentFormInput>;
    readOnly?: boolean;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    smallFontSize?: boolean;
    noLabel?: boolean;
    smallPadding?: boolean;
  } & TextFieldProps,
) {
  const { t } = useTranslation();
  const {
    name,
    controllerName,
    readOnly = false,
    fullWidth = false,
    InputProps = {},
    size = 'small',
    smallFontSize = false,
    noLabel = false,
    smallPadding = false,
    ...rest
  } = props;
  const classes = useStyles();
  const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];
  const { control } = useFormContext<
    SporadicRouteFormInput | ImportShipmentFormInput
  >();

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...props}
          {...field}
          id={name}
          size={size}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          onKeyDown={(e) => {
            exceptThisSymbols.includes(e.key) && e.preventDefault();
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
              e.preventDefault();
            } else if (e.altKey) {
              if (e.target && e.target instanceof HTMLInputElement) {
                (e.target as HTMLInputElement).blur();
              }
              e.preventDefault();
            }
          }}
          variant="outlined"
          placeholder={noLabel ? undefined : t(`attributes.${label}`)}
          label={noLabel ? undefined : t(`attributes.${label}`)}
          error={fieldState?.error && Boolean(fieldState.error)}
          helperText={fieldState?.error && fieldState.error.message}
          fullWidth={fullWidth}
          InputProps={{
            className: clsx([
              classes.input,
              {
                [classes.smallFontSize]: smallFontSize,
                [classes.smallPadding]: smallPadding,
              },
            ]),
            readOnly,
            ...InputProps,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...rest}
        />
      )}
    />
  );
}
