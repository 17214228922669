import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PageviewIcon from '@material-ui/icons/Pageview';
import UnLockIcon from '@mui/icons-material/LockOpen';
import PingIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { DataGridPro, GridCellParams } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDate } from '../components/SelectDate';
import {
  useGetAllRouteGroupTagsLazyQuery,
  usePingRouteLockUserLazyQuery,
  useUpdateLockMutation,
  useUpdateLockOnBulkRoutesMutation,
  useChecklistResetSubSubscription,
  useUpdateMinimalRouteMutation,
  ChecklistRoutes,
  ChecklistRouteLegs,
  ChecklistRouteLegLoadinglistItems,
} from '../generated/graphql';
import { getCurrentDate } from '../lib/date';
import { DATE_FORMAT } from '../lib/date_time';
import { useHttpClient } from '../providers/HttpClientProvider';
import { CheckListRoutesFormRHF } from './CheckListRoutesFormRHF';
import { DialogModal } from '../components/DialogModal';
import { cloneDeep } from 'lodash';
import { HelmetComponent } from '../components/HelmetComponent';
import { Prompt } from 'react-router';
import { SporadicRouteFormInput } from '../components/RouteFormRHF';

const useStyles = makeStyles((theme: Theme) => ({
  selectDateRoot: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
    '& form > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
  },
  filterButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  rootGrid: {
    marginTop: 2,
  },
}));
export type CheckListRoutesFormInput = {
  routes: {
    idNum: number;
    routeId: string;
    transportationDate: string;
    subcontractorName: string;
    capacity?: number;
    isRouteLocked: boolean;
    isChecklistLocked: boolean;
    routeLockedBy?: string;
    routeLockedByUserId?: string;
    routeLockedAt?: string;
    routeGroupTag?: string;
    legs: {
      key?: string;
      idNum: number;
      position: number;
      locationId: number;
      load: boolean;
      unload: boolean;
      productionDate: string;
      transportationDate: string;
      transportationDateOffset: number;
      arrivalTime: string;
      departureTime?: string;
      note: string | null;
      loadingListItems?: {
        id?: number;
        locationId?: number;
        checked: boolean;
        isDangerous: boolean;
        palletSpace?: number | string;
        packages?: number | string;
        pallets?: number | string;
        weight?: number | string;
        note: string;
      }[];
    }[];
  }[];
};
export type AnyFormType = SporadicRouteFormInput | CheckListRoutesFormInput;
type LockedRoutesType = {
  lockedRoutes: {
    idNum: number;
    routeId: string;
    isRouteLocked: boolean;
    isChecklistLocked: boolean;
    routeLockedBy?: string;
    routeLockedByUserId?: string;
    routeLockedAt?: string;
  }[];
};

export function CheckListRoutesTraffic() {
  const { t } = useTranslation();

  const [date, setDate] = useState<string>(
    format(getCurrentDate(), DATE_FORMAT),
  );
  const [selRouteGroup, setSelRouteGroup] = useState<string>('');
  const [groupLoading, setGroupLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [screenMessage, setScreenMessage] = useState<string>('');
  const [showScreenMsg, setShowScreenMsg] = useState<boolean>(false);
  const [successAlert, setSuccessAlert] = useState<boolean>(false);
  const [unlockSuccessAlert, setUnlockSuccessAlert] = useState<boolean>(false);
  const [containLockedRoutes, setContainLockedRoutes] =
    useState<boolean>(false);
  const [openUnlockAnywayDialog, setOpenUnlockAnywayDialog] =
    useState<boolean>(false);
  const [dialogRouteId, setDialogRouteId] = useState<number>(0);
  const [allRoutesData, setAllRoutesData] = useState<CheckListRoutesFormInput>({
    routes: [],
  });
  const [lockedRoutesData, setLockedRoutesData] = useState<LockedRoutesType>({
    lockedRoutes: [],
  });

  const [
    getAllGroupsApi,
    { data: allGroupTags, loading: allGroupTagsLoading },
  ] = useGetAllRouteGroupTagsLazyQuery();

  const PLEASE_CHOOSE = '--Please choose--';
  const NO_DATA = '--No data--';
  const [allGroupsData, setAllGroupsData] = useState<string[]>([]);
  const [isGroupSelected, setGroupSelected] = useState<boolean>(false);
  const [isUpdateInProgress, setUpdateInProgress] = useState<boolean>(false);
  const [myUserId, setMyUserId] = useState<string>('');
  const [updateLockMutation] = useUpdateLockMutation();
  const [updateBulkLockMutation] = useUpdateLockOnBulkRoutesMutation();
  const [updateFinalLegs] = useUpdateMinimalRouteMutation();
  const [pingToUnlockApi] = usePingRouteLockUserLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const { httpClient } = useHttpClient();
  const classes = useStyles();

  useChecklistResetSubSubscription({
    variables: {
      ownerUserId: myUserId,
      forGroup: selRouteGroup,
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.checklistReset &&
        subscriptionData.data.checklistReset.ownerUserId === myUserId &&
        subscriptionData.data.checklistReset.forGroup === selRouteGroup
      ) {
        //reset page
        setUnlockSuccessAlert(true);
        setDate(format(getCurrentDate(), DATE_FORMAT));
        setAllRoutesData({
          routes: [],
        });
      }
    },
  });

  const onDateSelected = (date: string) => {
    setDate(date);
  };

  const getAllData = () => {
    setDataLoading(true);
    httpClient
      .getCheckListRoutes({
        transportationDate: date,
        routeGroupTag: selRouteGroup,
      })
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          const r = res.data.data as string[];
          if (r.length === 0) {
            setAllRoutesData({
              routes: [],
            });
            setScreenMessage(`${res.data.message}`);
            setShowScreenMsg(true);
            setContainLockedRoutes(false);
          } else if (
            res.data &&
            res.data.lockRouteIds &&
            res.data.lockRouteIds.length > 0
          ) {
            //lock available routes first
            updateBulkLockMutation({
              variables: {
                routeIds: res.data.lockRouteIds as number[],
                isLock: true,
                forGroup: selRouteGroup,
              },
            }).then((resp) => {
              if (resp) {
                const allRoutes: CheckListRoutesFormInput = {
                  routes: res.data.data,
                };

                setAllRoutesData(allRoutes);
                setShowScreenMsg(false);
                setContainLockedRoutes(false);
              }
            });
          } else {
            //check for locked ones
            const allRoutes: CheckListRoutesFormInput = {
              routes: res.data.data,
            };

            const isLockedRoute = allRoutes.routes.findIndex(
              (r) => r.isRouteLocked === true && r.isChecklistLocked === false,
            );
            if (isLockedRoute > -1) {
              //locked by Edit route
              setContainLockedRoutes(true);
              setLockedRoutesData({
                lockedRoutes: allRoutes.routes.filter(
                  (r) =>
                    r.isRouteLocked === true && r.isChecklistLocked === false,
                ),
              });
            } else {
              setContainLockedRoutes(false);

              setAllRoutesData(allRoutes);
            }
            setShowScreenMsg(false);
          }
        } else if (res.data && res.data.status === 'FAIL') {
          setScreenMessage(`${res.data.message}`);
          setShowScreenMsg(true);
          console.error('# CheckListRoutes Res error=', res.data.message);
        }

        setDataLoading(false);
      })
      .catch((e) => {
        console.error('# CheckListRoutes error=', e);
        setDataLoading(false);
      });
  };

  const onSubmit = async (values: CheckListRoutesFormInput) => {
    //construct minimal array of type UpdateMinimalRouteInput
    const fnlRoutes: ChecklistRoutes[] = [];

    if (values && values.routes && values.routes.length > 0) {
      //setUpdateInProgress(true);
      values.routes.map((inputRoute) => {
        const inLegs: ChecklistRouteLegs[] = [];

        if (inputRoute.legs && inputRoute.legs.length > 0) {
          //legs
          inputRoute.legs.map((inputLeg) => {
            const inLegsLLI: ChecklistRouteLegLoadinglistItems[] = [];
            if (
              inputLeg.loadingListItems &&
              inputLeg.loadingListItems.length > 0
            ) {
              //loadingList
              inputLeg.loadingListItems.map((inputLL) => {
                inLegsLLI.push({
                  id: inputLL.id,
                  locationId:
                    inputLL.locationId != null
                      ? parseInt(`${inputLL.locationId}`)
                      : 0,
                  packages:
                    inputLL.packages != null && inputLL.packages != ''
                      ? parseInt(`${inputLL.packages}`)
                      : 0,
                  pallets:
                    inputLL.pallets != null
                      ? parseInt(`${inputLL.pallets}`)
                      : 0,
                  palletSpace:
                    inputLL.palletSpace != null
                      ? parseInt(`${inputLL.palletSpace}`)
                      : 0,
                  weight:
                    inputLL.weight != null && inputLL.weight != ''
                      ? parseInt(`${inputLL.weight}`)
                      : 0,
                  note: inputLL.note,
                });
              });
            }

            inLegs.push({
              id: inputLeg.idNum === 0 ? undefined : inputLeg.idNum,
              idNum: inputLeg.idNum,
              locationId: inputLeg.locationId,
              position: inputLeg.position,
              transportationDate: inputLeg.transportationDate,
              productionDate: inputLeg.productionDate,
              load: inputLeg.load,
              unload: inputLeg.unload,
              arrivalTime: inputLeg.arrivalTime,
              departureTime: inputLeg.departureTime,
              note: inputLeg.note ?? '',
              loadingListItems: inLegsLLI,
            });
          });
        }

        fnlRoutes.push({
          idNum: inputRoute.idNum,
          legs: inLegs,
        });
      });

      //final mutation to update legs
      updateFinalLegs({
        variables: {
          input: {
            routes: fnlRoutes,
          },
        },
      }).then((res) => {
        if (res.data?.updateMinimalRoutes) {
          setSuccessAlert(true);
        }
        setUpdateInProgress(false);
      });
    }
  };

  const pingToUnlock = (routeId: number) => {
    pingToUnlockApi({
      variables: {
        routeId: routeId,
      },
    });
  };

  const unlockAnyway = (routeId: number) => {
    if (routeId !== 0) {
      updateLockMutation({
        variables: {
          routeId: routeId,
          lock: 0,
          reload: 1,
        },
      }).then(() => {
        getAllData();
      });
    }
  };

  useEffect(() => {
    setGroupSelected(false);
    getAllGroupsApi({
      variables: {
        transportDate: date,
      },
    });
  }, [date]);

  useEffect(() => {
    if (allGroupTags == null || allGroupTagsLoading) {
      setGroupLoading(true);
    } else if (allGroupTags && allGroupTags.getAllRouteGroupTags.length) {
      setAllRoutesData({
        routes: [],
      });
      const tempArray: string[] = cloneDeep(allGroupTags.getAllRouteGroupTags);
      //for extracting userId from response
      if (tempArray[0] === PLEASE_CHOOSE) {
        setMyUserId(tempArray.pop() ?? '');
      }
      setAllGroupsData(tempArray);
      setSelRouteGroup(tempArray[0]);
      setGroupLoading(false);
    }
  }, [allGroupTags, allGroupTagsLoading]);

  return (
    <Grid container spacing={1} className={classes.rootGrid}>
      <HelmetComponent
        title={`${t('attributes.checkLists')} ${
          selRouteGroup === PLEASE_CHOOSE ||
          selRouteGroup === NO_DATA ||
          selRouteGroup === '' ||
          allRoutesData.routes.length === 0
            ? ''
            : `: ${selRouteGroup}`
        }`}
      />
      <div>
        <Prompt
          key={'prompt'}
          when={allRoutesData.routes.length > 0 && !containLockedRoutes}
          message={`${t('validation.checklistLeaveConfirmation')}`}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={successAlert}
          key={'alertUpdated'}
          autoHideDuration={3000}
          onClose={() => {
            setSuccessAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setSuccessAlert(false);
            }}
            severity="success"
          >
            {`${t('attributes.routesUpdated')}`}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={unlockSuccessAlert}
          key={'alertUnlocked'}
          autoHideDuration={3000}
          onClose={() => {
            setUnlockSuccessAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setUnlockSuccessAlert(false);
            }}
            severity="success"
          >
            {`${t('attributes.routesUnlocked')}`}
          </Alert>
        </Snackbar>
      </div>
      <Grid item xs={12}>
        <Paper className={classes.selectDateRoot}>
          <Box
            sx={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="body1">{`${t(
              'attributes.checkListRoutesTitle',
            )}`}</Typography>

            {allRoutesData.routes.length > 0 && !containLockedRoutes && (
              <Typography style={{ fontSize: 13 }}>
                {`${t('attributes.transDate')}: ${date} | ${t(
                  'attributes.group',
                )}: ${selRouteGroup}`}
              </Typography>
            )}
          </Box>

          {allRoutesData.routes.length > 0 && !containLockedRoutes ? (
            <>
              <Typography
                style={{ fontSize: 13, fontWeight: 'bold', color: 'orange' }}
              >
                {`${t('attributes.rememberToUnlock')}: ${
                  allRoutesData.routes.length
                }`}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(`/traffic/checklist-routes`, '_blank');
                }}
              >
                {`${t('attributes.createNewChecklist')}`}
              </Button>
            </>
          ) : (
            <Box className={classes.filterButtonRoot}>
              <SelectDate
                label={t('attributes.transportDate')}
                currentDate={date}
                onDateSelected={onDateSelected}
                disableFutureDate={false}
              />
              <TextField
                id="route-group-tag"
                style={{
                  width: 200,
                  marginLeft: 10,
                  marginRight: 4,
                  padding: 0,
                }}
                select
                disabled={groupLoading || selRouteGroup === NO_DATA}
                size="small"
                variant="standard"
                value={selRouteGroup}
                label={'Route group'}
                onChange={(event) => {
                  setSelRouteGroup(event.target.value as string);
                  if (
                    (event.target.value as string) != PLEASE_CHOOSE &&
                    (event.target.value as string) != NO_DATA
                  ) {
                    setGroupSelected(true);
                  } else {
                    setGroupSelected(false);
                  }
                }}
              >
                {allGroupsData.map((routeGroup) => (
                  <MenuItem value={routeGroup}>{routeGroup}</MenuItem>
                ))}
              </TextField>
              <Button
                style={{ marginRight: 2, marginLeft: 6 }}
                variant="contained"
                color="primary"
                disabled={!isGroupSelected}
                onClick={() => {
                  getAllData();
                }}
              >
                {`${dataLoading ? t('attributes.wait') : t('actions.view')}`}
              </Button>
            </Box>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12} container direction="row">
        {showScreenMsg ? (
          <div style={{ marginTop: 5 }}>{screenMessage}</div>
        ) : allRoutesData.routes.length > 0 && !containLockedRoutes ? (
          <CheckListRoutesFormRHF
            initialValues={allRoutesData}
            isUpdateInProgress={isUpdateInProgress}
            onSubmit={onSubmit}
          />
        ) : lockedRoutesData.lockedRoutes.length > 0 && containLockedRoutes ? (
          <div
            style={{
              marginTop: 2,
              padding: 10,
              height: '100%',
              width: '100%',
            }}
          >
            <DialogModal
              open={openUnlockAnywayDialog}
              setOpen={setOpenUnlockAnywayDialog}
              contentText={String.raw`${t(
                'validation.unlockConfirmation',
              )}this route?  ${t('validation.unlockWarning')}`}
              doAction={() => {
                unlockAnyway(dialogRouteId);
              }}
              buttonText={t('button.yes')}
            />
            <Typography
              style={{ marginBottom: 4, fontWeight: 'bold', color: 'orange' }}
            >
              {`${t('attributes.unlockRoutesPls')}`}
            </Typography>
            <DataGridPro
              rows={lockedRoutesData.lockedRoutes || []}
              disableSelectionOnClick
              columns={[
                {
                  field: 'idNum',
                  headerName: 'Route ID',
                  disableColumnMenu: true,
                },
                {
                  field: 'viewRoute',
                  headerName: 'Open',
                  minWidth: 90,
                  disableColumnMenu: true,
                  type: 'actions',
                  renderCell: (params: GridCellParams) => {
                    const { row } = params;
                    const { idNum } = row;
                    if (idNum == null) {
                      return null;
                    }
                    return (
                      <Link
                        variant="body2"
                        href={`/traffic/dispatched-routes/${idNum}`}
                        target={'_blank'}
                      >
                        <PageviewIcon color="action" />
                      </Link>
                    );
                  },
                },
                {
                  field: 'routeId',
                  headerName: 'Route Id',
                  minWidth: 120,
                  disableColumnMenu: true,
                },
                {
                  field: 'routeLockedBy',
                  headerName: 'Locked By',
                  minWidth: 150,
                  disableColumnMenu: true,
                },
                {
                  field: 'routeLockedAt',
                  headerName: 'Locked At',
                  minWidth: 150,
                  disableColumnMenu: true,
                  valueGetter({ value }) {
                    return new Date(value as string);
                  },
                  valueFormatter({ value }) {
                    return format(value as Date, 'dd-MM-yyyy HH:mm');
                  },
                },
                {
                  field: 'ping',
                  headerName: 'Ping',
                  minWidth: 90,
                  disableColumnMenu: true,
                  type: 'actions',
                  renderCell: (params: GridCellParams) => {
                    const { row } = params;
                    const { idNum } = row;

                    return (
                      <Tooltip title={`${t('attibutes.pingUser')}`}>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            pingToUnlock(idNum);
                          }}
                        >
                          <PingIcon />
                        </IconButton>
                      </Tooltip>
                    );
                  },
                },
                {
                  field: 'unlock',
                  headerName: 'Unlock',
                  minWidth: 90,
                  disableColumnMenu: true,
                  type: 'actions',
                  renderCell: (params: GridCellParams) => {
                    const { row } = params;
                    const { idNum } = row;

                    return (
                      <Tooltip title={`${t('attributes.unlockAnyway')}`}>
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            setDialogRouteId(idNum);
                            setOpenUnlockAnywayDialog(true);
                          }}
                        >
                          <UnLockIcon />
                        </IconButton>
                      </Tooltip>
                    );
                  },
                },
              ]}
              autoHeight={true}
            />
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}
