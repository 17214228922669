import { Checkbox, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Radio,
  RadioGroup,
  Box,
  ClassNameMap,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Tooltip,
} from '@mui/material';
import { Field, FieldProps, FormikProps, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CostAllocationItemType, Department } from '../../../generated/graphql';
import {
  calculateFromPercentage,
  calculatePercentage,
} from '../../../lib/percentage';
import { TourTemplateFormInput } from '../../TourTemplateForm';

interface CostAllocationItemFieldsProps {
  readOnly: boolean;
  removeAllocation: (index: number) => void;
  index: number;
  costAllIndex: number;
  classes: ClassNameMap<'subResources' | 'routeCostAllocItems'>;
  props: FormikProps<
    Omit<TourTemplateFormInput, 'subcontractorId'> & {
      subcontractorId?: number | undefined;
    }
  >;
  departments: Pick<Department, 'id' | 'name'>[];
}

export function TourRouteCostAllocationItemFields({
  readOnly,
  index,
  costAllIndex,
  removeAllocation,
  props,
  classes,
  departments,
}: CostAllocationItemFieldsProps) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const includedPrice =
    props.values.routes[index].costAllocations[costAllIndex].includedPrice;
  const thisBringCost =
    props.values.routes[index].costAllocations[costAllIndex].bringCost;
  const thisAdditionalCost =
    props.values.routes[index].costAllocations[costAllIndex].additionalCost;

  const thisCostType =
    includedPrice || thisBringCost ? 'bc' : thisAdditionalCost ? 'ac' : 'ad';

  return (
    <ListItem key={costAllIndex} divider>
      <Box className={classes.routeCostAllocItems}>
        <Field
          name={`routes.${index}.costAllocations.${costAllIndex}.departmentId`}
        >
          {({ field }: FieldProps) => (
            <FormControl {...field} variant="outlined" className="select-small">
              <InputLabel id="department-label" shrink>
                {t('attributes.department')}
              </InputLabel>
              <Select
                {...field}
                labelId="department-label"
                label={t('attributes.department')}
                readOnly={readOnly}
                id="department"
                size="small"
              >
                {departments.map((dep) => (
                  <MenuItem key={dep.id} value={dep.id}>
                    {dep.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
        <Field name={`routes.${index}.costAllocations.${costAllIndex}.type`}>
          {({ field }: FieldProps) => (
            <FormControl {...field} variant="outlined" className="select">
              <InputLabel id="type-label">{t('attributes.type')}</InputLabel>
              <Select
                {...field}
                labelId="type-label"
                label={t('attributes.type')}
                id="type"
                readOnly={readOnly}
                size="small"
              >
                {Object.values(CostAllocationItemType).map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Field>
        <Box display="flex" flexDirection="column" style={{ paddingLeft: 4 }}>
          <Field
            name={`routes.${index}.costAllocations.${costAllIndex}.includedKpi`}
            readOnly={readOnly}
          >
            {({ field }: FieldProps) => (
              <FormControlLabel
                {...field}
                checked={field.value}
                control={
                  <Checkbox
                    color="primary"
                    disabled={readOnly}
                    readOnly={readOnly}
                    size="small"
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    {t('attributes.includedKpi')}
                  </Typography>
                }
              />
            )}
          </Field>
          <Field
            name={`routes.${index}.costAllocations.${costAllIndex}.includedPrice`}
          >
            {({ field, form }: FieldProps) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    disabled={readOnly}
                    onChange={(
                      _event: React.ChangeEvent<unknown>,
                      checked: boolean,
                    ) => {
                      form.setFieldValue(
                        `routes.${index}.costAllocations.${costAllIndex}.includedPrice`,
                        !!checked,
                      );
                      if (checked) {
                        form.setFieldValue(
                          `routes.${index}.costAllocations.${costAllIndex}.bringCost`,
                          true,
                        );
                        form.setFieldValue(
                          `routes.${index}.costAllocations.${costAllIndex}.percentage`,
                          0,
                        );
                        form.setFieldValue(
                          `routes.${index}.costAllocations.${costAllIndex}.cost`,
                          0,
                        );
                      }
                    }}
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    {t('attributes.includedPrice')}
                  </Typography>
                }
              />
            )}
          </Field>
        </Box>

        {includedPrice === true ? (
          <Field
            name={`routes.${index}.costAllocations.${costAllIndex}.percentage`}
          >
            {({ field, form, meta }: FieldProps) => (
              <TextField
                {...field}
                variant="outlined"
                error={meta.touched && Boolean(meta.error)}
                inputProps={{
                  readOnly,
                }}
                style={{ maxWidth: 110 }}
                size="small"
                helperText={meta.touched && meta.error}
                onChange={(
                  event: React.ChangeEvent<
                    HTMLTextAreaElement | HTMLInputElement
                  >,
                ) => {
                  const newValue = parseInt(event.target.value, 10);
                  form.setFieldValue(
                    `routes.${index}.costAllocations.${costAllIndex}.percentage`,
                    newValue,
                  );
                  form.setFieldValue(
                    `routes.${index}.costAllocations.${costAllIndex}.cost`,
                    calculateFromPercentage(
                      props.values.routes[index].price,
                      newValue,
                    ),
                  );
                }}
                disabled={!includedPrice}
                id="percentage"
                label={t('attributes.percentage')}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </Field>
        ) : (
          <Box style={{ paddingLeft: 4 }}>
            <RadioGroup
              row={false}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={thisCostType}
              onChange={(event) => {
                setFieldValue(
                  `routes.${index}.costAllocations.${costAllIndex}.bringCost`,
                  event.target.value === 'bc',
                );
                setFieldValue(
                  `routes.${index}.costAllocations.${costAllIndex}.additionalCost`,
                  event.target.value === 'ac',
                );
                setFieldValue(
                  `routes.${index}.costAllocations.${costAllIndex}.additionalDiscount`,
                  event.target.value === 'ad',
                );
              }}
            >
              <FormControlLabel
                value="bc"
                control={
                  <Radio
                    value="bc"
                    name="radio1"
                    size="small"
                    disabled={readOnly}
                    sx={{
                      '&.Mui-checked': {
                        color: '#69bc48',
                      },
                    }}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    {t('attributes.bringCost')}
                  </Typography>
                }
              />
              <FormControlLabel
                value="ac"
                control={
                  <Radio
                    value="ac"
                    name="radio2"
                    size="small"
                    disabled={readOnly}
                    sx={{
                      '&.Mui-checked': {
                        color: '#69bc48',
                      },
                    }}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    {t('attributes.additionalCost')}
                  </Typography>
                }
              />
              <FormControlLabel
                value="ad"
                control={
                  <Radio
                    value="ad"
                    name="radio3"
                    size="small"
                    disabled={readOnly}
                    sx={{
                      '&.Mui-checked': {
                        color: '#69bc48',
                      },
                    }}
                  />
                }
                label={
                  <Typography style={{ fontSize: 14 }}>
                    {t('attributes.additionalDiscount')}
                  </Typography>
                }
              />
            </RadioGroup>
          </Box>
        )}

        <Field name={`routes.${index}.costAllocations.${costAllIndex}.cost`}>
          {({ field, form, meta }: FieldProps) => (
            <TextField
              {...field}
              variant="outlined"
              error={typeof meta.error === 'string'}
              helperText={meta.touched && meta.error}
              size="small"
              style={
                thisCostType === 'ad'
                  ? {
                      maxWidth: 110,
                      backgroundColor: '#ffcfd2',
                    }
                  : {
                      maxWidth: 110,
                      backgroundColor: '#dcfcd2',
                    }
              }
              inputProps={{
                readOnly,
              }}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                const newValue = parseInt(event.target.value, 10);
                form.setFieldValue(
                  `routes.${index}.costAllocations.${costAllIndex}.cost`,
                  newValue,
                );
                form.setFieldValue(
                  `routes.${index}.costAllocations.${costAllIndex}.percentage`,
                  calculatePercentage(
                    props.values.routes[index].price,
                    newValue,
                  ),
                );
              }}
              id="cost"
              label={
                thisCostType === 'ad'
                  ? t('attributes.discount')
                  : t('attributes.cost')
              }
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </Field>
        <Field name={`routes.${index}.costAllocations.${costAllIndex}.comment`}>
          {({ field, meta }: FieldProps) => (
            <TextField
              {...field}
              variant="outlined"
              id="comment"
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              label={t('attributes.comment')}
              multiline
              size="small"
              rows={3}
              inputProps={{
                readOnly,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </Field>
        {!readOnly && (
          <Tooltip
            title={
              props.values.routes[index].costAllocations.length < 2
                ? ''
                : `${t('actions.delete.lowercased')}`
            }
          >
            <IconButton
              edge="end"
              aria-label="delete"
              disabled={props.values.routes[index].costAllocations.length < 2}
              onClick={() => {
                removeAllocation(costAllIndex);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </ListItem>
  );
}
