import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../CenteredBox';
import { SelectContactFromCardRHF } from './SelectContactFromCardRHF';
import { SelectSubcontractorRHF } from './SelectSubcontractorRHF';

interface SubcontractorFieldsProps {
  readOnly: boolean;
  fromTerminal?: boolean;
}

export function SubcontractorFieldsRHF({
  readOnly,
  fromTerminal = false,
}: SubcontractorFieldsProps) {
  const { t } = useTranslation();

  return (
    <>
      <CenteredBox>
        <SelectSubcontractorRHF
          name="subcontractorId"
          controllerName={'subcontractorId'}
          readOnly={readOnly || fromTerminal}
        />
      </CenteredBox>
      <CenteredBox>
        <SelectContactFromCardRHF
          controllerName={'dispatcherId'}
          label={t('attributes.dispatcherId')}
          readOnly={readOnly || fromTerminal}
        />
      </CenteredBox>
    </>
  );
}
