import {
  Box,
  Dialog,
  IconButton,
  ListItemIcon,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { ReactComponent as InfoIcon } from '../../svgIcons/infoicon.svg';
import { ReactComponent as ImportantIcon } from '../../svgIcons/important.svg';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  GetInternalTerminalReportInfoDocument,
  InfoType,
  useAddInternalTerminalReportInfoMutation,
} from '../../generated/graphql';
import { SelectDate } from '../SelectDate';

interface ReportToTerminalAddInfoModalProps {
  handleClose: () => void;
  open: boolean;
  internalTerminalId: number;
  terminalName: string;
  dateForAddInfo: string;
  dayForAddInfo: string;
  isForTerminal: boolean;
}

export function ReportToTerminalAddInfoModal(
  props: ReportToTerminalAddInfoModalProps,
) {
  const {
    open,
    handleClose,
    internalTerminalId,
    terminalName,
    dateForAddInfo,
    dayForAddInfo,
    isForTerminal,
  } = props;
  const { t } = useTranslation();

  const titleString =
    terminalName && dayForAddInfo && dateForAddInfo
      ? dayForAddInfo
          .concat(' ')
          .concat(dateForAddInfo)
          .concat(' | ')
          .concat(terminalName)
      : '';
  const theme = useTheme();
  const lessThan1400 = useMediaQuery(theme.breakpoints.between(0, 1400));
  const [addInternalTerminalReportInfoMutation] =
    useAddInternalTerminalReportInfoMutation({
      refetchQueries: [
        {
          query: GetInternalTerminalReportInfoDocument,
          variables: {
            internalTerminalId,
            dateForAddInfo,
            forTerminal: isForTerminal,
          },
        },
      ],
    });

  if (!internalTerminalId || !dateForAddInfo) {
    return <></>;
  }
  return (
    <Dialog
      maxWidth={lessThan1400 ? 'sm' : 'md'}
      fullWidth={true}
      open={open}
      onClose={handleClose}
    >
      <Formik
        initialValues={{
          infoType: InfoType.Info,
          info: '',
          internalTerminalId,
          dateForAddInfo,
          createUntilDate: dateForAddInfo,
          isAddedFromTerminal: isForTerminal,
        }}
        onSubmit={async (values: {
          infoType: InfoType;
          info: string;
          internalTerminalId: number;
          dateForAddInfo: string;
          createUntilDate: string;
          isAddedFromTerminal: boolean;
        }) => {
          addInternalTerminalReportInfoMutation({
            variables: {
              input: { ...values },
            },
          });
          handleClose();
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <DialogTitle>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
                mb={2}
              >
                <Typography variant="body1">
                  <strong>{`${t('actions.add', {
                    item: t('attributes.info'),
                  })}`}</strong>
                </Typography>
                <Typography variant="body1">{titleString}</Typography>
                <IconButton style={{ padding: '0px' }} onClick={handleClose}>
                  <CloseIcon fontSize="medium" color="action" />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  paddingBottom: '1%',
                }}
              >
                <Typography variant="body1">{t('attributes.type')}</Typography>
                <Select
                  variant="standard"
                  name="infoType"
                  id="infoType"
                  autoWidth={true}
                  defaultValue={InfoType.Info}
                  value={props.values.infoType}
                  onChange={props.handleChange}
                >
                  <MenuItem value={InfoType.Important}>
                    <ListItemIcon
                      style={{
                        width: '1em',
                        height: '1em',
                        fontSize: '1.5em',
                      }}
                    >
                      <ImportantIcon fill="#0096FF" />
                    </ListItemIcon>
                  </MenuItem>
                  <MenuItem value={InfoType.Info}>
                    <ListItemIcon
                      style={{
                        width: '1em',
                        height: '1em',
                        fontSize: '1.5em',
                      }}
                    >
                      <InfoIcon fill="#0096FF" fill-rule="nonzero" />
                    </ListItemIcon>
                  </MenuItem>
                </Select>
                <SelectDate
                  label={t('attributes.untilDate')}
                  currentDate={props.values.createUntilDate}
                  onDateSelected={(dt) => {
                    props.setFieldValue('createUntilDate', dt);
                  }}
                  minDateValue={dateForAddInfo}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '2%',
                  marginTop: '1%',
                }}
              >
                <TextField
                  label="Add Info here"
                  id="info"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={props.values.info}
                  onChange={props.handleChange}
                />
              </Box>
            </DialogContent>
            <DialogActions
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                paddingBottom: '1%',
              }}
            >
              <Box>
                <Button type="submit" variant="contained" color="success">
                  {t('button.add')}
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
