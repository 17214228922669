import { Typography } from '@material-ui/core';
import { useFormContext, useWatch } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

export function CostAllocationPriceLable() {
  const { control } = useFormContext<SporadicRouteFormInput>();

  const [finalEveryCost, totalBringCost, newAgreedPrice, finalAdditionalCost] =
    useWatch({
      control,
      name: [
        'finalEveryCost',
        'totalBringCost',
        'newAgreedPrice',
        'finalAdditionalCost',
      ],
    });

  return (
    <>
      <Typography style={{ fontWeight: 'bolder' }}>
        {`Overall Cost: ${finalEveryCost} | Total BringCost: ${totalBringCost} | New AgreedPrice: ${newAgreedPrice} | Total AdditionalCost: ${finalAdditionalCost}`}{' '}
      </Typography>
    </>
  );
}
