import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller, Path } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { ImportShipmentFormInput } from '../../views/ImportShipment';

interface SelectEnumFieldProps<T> {
  name: string;
  controllerName: Path<SporadicRouteFormInput | ImportShipmentFormInput>;
  enumObject: T;
  readOnly?: boolean;
  translationPath?: string;
}

export function SelectEnumFieldSmallRHF<T extends { [key: string]: unknown }>(
  props: SelectEnumFieldProps<T>,
) {
  const {
    name,
    enumObject,
    readOnly = false,
    translationPath,
    controllerName,
  } = props;
  const values = Object.values(enumObject);
  const { t } = useTranslation();
  const { control } = useFormContext<
    SporadicRouteFormInput | ImportShipmentFormInput
  >();

  const renderValue = useCallback(
    (val: string) => {
      if (translationPath != null) {
        return t(`${translationPath}.${val}`);
      }
      return val;
    },
    [translationPath],
  );

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          id={name}
          error={fieldState.error && Boolean(fieldState.error)}
          disabled={readOnly}
          style={{
            width: '97%',
            height: 26,
            padding: 0,
          }}
        >
          <Select
            disableUnderline
            style={{
              fontSize: 12,
              borderRadius: 4,
              border:
                fieldState.error && Boolean(fieldState.error)
                  ? '1px solid red'
                  : '1px solid #bababa',
              paddingLeft: 2,
              backgroundColor: 'transparent',
            }}
            {...field}
            variant="standard"
            id={`${name}-select`}
            size="small"
          >
            {values.map((value: any) => (
              <MenuItem value={value}>{renderValue(value)}</MenuItem>
            ))}
          </Select>
          {fieldState.error && Boolean(fieldState.error) && (
            <FormHelperText>{`Please select one`}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
