import {
  Box,
  Button,
  createStyles,
  Grid,
  Input,
  makeStyles,
  Modal,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';
import {
  GetContactDocument,
  GetTagsQuery,
  useDeleteCardContactMutation,
  useGetContactQuery,
  useGetTagsQuery,
  useUpdateContactMutation,
} from '../generated/graphql';
import { getRefetchCardQueryByUrl } from './AddContact';
import { CreatedAndUpdatedView } from './CreatedAndUpdatedView';
import { DialogModal } from './DialogModal';
import { buttonStyle } from './ViewCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: 800,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

function getModalStyle() {
  return {
    width: '100%',
    height: '100vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}

const validationSchema = (t: TFunction) =>
  yup.object({
    name: yup.string().min(
      3,
      t('validation.atLeastLen', {
        name: t('attributes.name'),
        len: 3,
      }),
    ),
    role: yup.string(),
    phoneNumber: yup.string().min(
      3,
      t('validation.atLeastLen', {
        phoneNumber: t('attributes.phoneNumber'),
        len: 3,
      }),
    ),
    email: yup.string().min(
      3,
      t('validation.atLeastLen', {
        email: t('attributes.email'),
        len: 3,
      }),
    ),
  });

interface UpdateContactProps {
  id: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export function UpdateContact(props: UpdateContactProps) {
  const { id, open, setOpen } = props;
  const { data: allTags } = useGetTagsQuery();
  const { loading, error, data } = useGetContactQuery({
    variables: { id },
  });
  const classes = useStyles();
  const deleteButtonClass = buttonStyle();
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const { path } = useRouteMatch();
  const cardType = path.split('/')[3];
  const { id: cardId } = useParams<{ id: string }>();
  const refreshQueryDocument = getRefetchCardQueryByUrl(cardType);
  const [updateContact, {}] = useUpdateContactMutation({
    refetchQueries: [
      {
        query: GetContactDocument,
        variables: {
          id,
        },
      },
    ],
  });

  const [deleteContact] = useDeleteCardContactMutation({
    refetchQueries: [
      {
        query: refreshQueryDocument,
        variables: { id: parseInt(cardId, 10) },
      },
    ],
  });

  const handleDeleteContact = () => {
    deleteContact({ variables: { id: id } });
    setOpen(false);
  };

  if (error) {
    return <div>{error.message}</div>;
  }

  if (loading || data == null || allTags == null) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={getModalStyle()}
      >
        <Box className={classes.paper}>
          <Formik
            initialValues={{
              name: data.cardContact.name,
              role: data.cardContact.role,
              email: data.cardContact.email,
              phoneNumber: data.cardContact.phoneNumber,
              note: data.cardContact.note,
              tagIds: data.cardContact.tags.map((tag) => {
                return tag.id;
              }),
            }}
            enableReinitialize
            validationSchema={validationSchema(t)}
            onSubmit={(values) => {
              updateContact({
                variables: {
                  input: { ...values, id: data.cardContact.id },
                },
              });
              setOpen(false);
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item sm={10} container spacing={3}>
                    <CreatedAndUpdatedView
                      createdBy={data.cardContact.createdBy}
                      lastUpdatedBy={data.cardContact.lastUpdatedBy}
                      updatedAt={data.cardContact.updatedAt}
                    />
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label={t('attributes.name')}
                        value={props.values.name}
                        onChange={props.handleChange}
                        error={props.touched.name && Boolean(props.errors.name)}
                        helperText={props.touched.name && props.errors.name}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="role"
                        name="role"
                        label={t('attributes.role')}
                        value={props.values.role}
                        onChange={props.handleChange}
                        error={props.touched.role && Boolean(props.errors.role)}
                        helperText={props.touched.role && props.errors.role}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label={t('attributes.phoneNumber')}
                        value={props.values.phoneNumber}
                        onChange={props.handleChange}
                        error={
                          props.touched.phoneNumber &&
                          Boolean(props.errors.phoneNumber)
                        }
                        helperText={
                          props.touched.phoneNumber && props.errors.phoneNumber
                        }
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label={t('attributes.email')}
                        value={props.values.email}
                        onChange={props.handleChange}
                        error={
                          props.touched.email && Boolean(props.errors.email)
                        }
                        helperText={props.touched.email && props.errors.email}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        fullWidth
                        id="note"
                        name="note"
                        label={t('attributes.note')}
                        value={props.values.note}
                        onChange={props.handleChange}
                        error={props.touched.note && Boolean(props.errors.note)}
                        helperText={props.touched.note && props.errors.note}
                      />
                    </Grid>

                    <Grid item sm={12}>
                      <Autocomplete
                        id="tagIds"
                        multiple
                        autoHighlight
                        options={allTags.cardContactTags}
                        defaultValue={data.cardContact.tags}
                        getOptionLabel={(
                          tag: GetTagsQuery['cardContactTags'][number],
                        ) => tag.title}
                        onChange={(
                          _event: ChangeEvent<unknown>,
                          value: GetTagsQuery['cardContactTags'],
                        ) => {
                          props.setFieldValue(
                            'tagIds',
                            value.map((tag) => {
                              return tag.id;
                            }),
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Add contact role"
                            placeholder="Tags"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      container
                      justifyContent="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Input
                          type="submit"
                          value={t('button.update', {
                            item: t('resource.contact.lowercased'),
                          })}
                          color="primary"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          classes={{
                            root: deleteButtonClass.button,
                          }}
                          onClick={() => {
                            setOpenDeleteDialog(true);
                          }}
                        >
                          {t('button.delete')}
                        </Button>
                      </Grid>
                      <Grid item sm={12} container justifyContent="center">
                        <Button
                          type="submit"
                          variant="outlined"
                          onClick={() => {
                            setOpen(false);
                          }}
                          color="primary"
                        >
                          {t('button.close')}
                        </Button>
                        <DialogModal
                          open={openDeleteDialog}
                          setOpen={setOpenDeleteDialog}
                          contentText={t('validation.confirmation', {
                            item: `${data.cardContact.name}`,
                            action: t('actions.delete.lowercased'),
                          })}
                          doAction={handleDeleteContact}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}
