import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

interface checkboxFieldProps {
  name: string;
  label: string;
  readOnly?: boolean;
  size?: 'small' | 'medium';
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.fontSize - 1,
    },
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: 1,
    },
  }),
);

export function CheckboxForShipment(props: checkboxFieldProps) {
  const classes = useStyles();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const { name, label, readOnly = false, size = undefined } = props;

  const [checked, setChecked] = useState(getFieldProps(`${name}`).value);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}`, event.target.checked);
  };
  useEffect(() => {
    setChecked(getFieldProps(`${name}`).value);
    if (getFieldProps(`${name}`).value === true && name === 'terminalCleared') {
      setFieldValue('terminalNotCleared', false);
      setFieldValue('terminalShipmentNotArrived', false);
    } else if (
      getFieldProps(`${name}`).value === true &&
      name === 'terminalNotCleared'
    ) {
      setFieldValue('terminalCleared', false);
      setFieldValue('terminalShipmentNotArrived', false);
    } else if (
      getFieldProps(`${name}`).value === true &&
      name === 'terminalShipmentNotArrived'
    ) {
      setFieldValue('terminalNotCleared', false);
      setFieldValue('terminalCleared', false);
    }
  }, [getFieldProps(`${name}`).value]);

  return (
    <FormControlLabel
      className={classes.formControl}
      disabled={readOnly}
      control={
        <Checkbox
          size={size}
          color="primary"
          className={classes.checkBox}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={
        <Typography
          variant="body2"
          className={classes.label}
          color="textPrimary"
        >
          {label}
        </Typography>
      }
    />
  );
}
