import { Grid, Container, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getRandomNumber } from '../lib/date';
import { BoxType } from '../views/ViewReportFromTerminal';
import { TerminalValueBox } from './TerminalValueBox';

interface ReportFromTrafficValueBoxProps {
  finalBoxes: BoxType[];
}

export function ReportFromTrafficValueBox(
  props: ReportFromTrafficValueBoxProps,
) {
  const { t } = useTranslation();
  const { finalBoxes } = props;
  const sortedFinalBoxes = [...finalBoxes].sort((a, b) =>
    a.boxNameText.localeCompare(b.boxNameText, undefined, {
      sensitivity: 'base',
    }),
  );

  return (
    <Paper>
      <Container style={{ flexDirection: 'row' }}>
        {sortedFinalBoxes.length > 0 ? (
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ paddingTop: 8, paddingBottom: 8 }}
          >
            {sortedFinalBoxes.map((b) => {
              return (
                <TerminalValueBox
                  key={`${b.boxId}${getRandomNumber(1)}`}
                  boxData={b}
                />
              );
            })}
          </Grid>
        ) : (
          <Typography variant="subtitle1" style={{ paddingRight: '1%' }}>
            {`${t('resource.noBoxAdded.capitalized')}`}
          </Typography>
        )}
      </Container>
    </Paper>
  );
}
