import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDate } from '../components/SelectDate';
import PlusIcon from '@mui/icons-material/Add';
import { useAddFixedValueBoxesMutation } from '../generated/graphql';
import { getTFTMasterDate } from '../lib/date';
import { DATE_FORMAT } from '../lib/date_time';
import { ReportToTerminalValueBox } from '../components/ReportToTerminalValueBox';
import { ReportToTerminalInfo } from '../components/ReportToTerminalInfo';
import { ValueBoxModal } from '../components/modal/ValueBoxModal';
import { ReportToTerminalAddInfoModal } from '../components/modal/ReportToTerminalAddInfoModal';
import { GridFunctionForTerminal } from '../components/TerminalGridFunction';
import { TerminalGridValues } from '../components/TerminalGridValues';

const useStyles = makeStyles((theme: Theme) => ({
  selectDateRoot: {
    display: 'flex',
    padding: theme.spacing(2, 3),
    justifyContent: 'space-between',
    '& form > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
  },
  filterButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  rootGrid: {
    marginTop: 2,
  },
}));

interface ReportToTrafficProps {
  terminal: {
    id: number;
    name: string;
  };
}

export function ReportToTrafficInTerminal(props: ReportToTrafficProps) {
  const { t } = useTranslation();
  const { terminal } = props;
  const selected = {
    id: terminal.id,
    name: terminal.name,
  };
  const classes = useStyles();
  const [date, setDate] = useState<string>(
    format(getTFTMasterDate(true), DATE_FORMAT),
  );

  const [showAddInfoModal, setShowAddInfoModal] = useState(false);
  const handleShowAddInfoModal = useCallback(() => {
    setShowAddInfoModal(!showAddInfoModal);
  }, [showAddInfoModal]);

  const [showValueBoxModal, setShowValueBoxModal] = useState<boolean>(false);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [refreshBoxes, setRefreshBoxes] = useState<boolean>(true);
  const [refreshGrid, setRefreshGrid] = useState<boolean>(false);
  const [hideGridData, setHideGridData] = useState<boolean>(false);

  const [addFixedBoxesApi] = useAddFixedValueBoxesMutation();
  const handleShowValueBoxModal = useCallback(() => {
    setShowValueBoxModal(!showValueBoxModal);
    setRefreshBoxes(!refreshBoxes);
  }, [showValueBoxModal]);

  const [startDate, setStartDate] = useState<{ date: string; day: string }>({
    date: format(new Date(), DATE_FORMAT),
    day: new Date().toLocaleDateString(undefined, {
      weekday: 'long',
    }),
  });

  const onDateSelected = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    if (date) {
      const dateTyped = parse(date, DATE_FORMAT, new Date());
      setStartDate({
        date: format(dateTyped, DATE_FORMAT),
        day: dateTyped.toLocaleDateString(undefined, {
          weekday: 'long',
        }),
      });
    }
  }, [date]);

  useEffect(() => {
    addFixedBoxesApi({
      variables: {
        internalTerminalId: terminal.id,
      },
    }).then((res) => {
      if (res.data?.addFixedValueBoxes) {
        setRefreshBoxes(!refreshBoxes);
      }
    });
  }, []);

  return (
    <Grid container spacing={1} className={classes.rootGrid}>
      <Grid item xs={12}>
        <Paper className={classes.selectDateRoot}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <Typography variant="body1">{terminal.name}</Typography>
          </Box>

          <SelectDate
            label={t('attributes.productionDate')}
            currentDate={date}
            onDateSelected={onDateSelected}
          />
        </Paper>
      </Grid>

      {showAddInfoModal && selected && selected.id ? (
        <ReportToTerminalAddInfoModal
          handleClose={handleShowAddInfoModal}
          open={showAddInfoModal}
          internalTerminalId={selected?.id}
          terminalName={selected.name}
          dateForAddInfo={startDate.date}
          dayForAddInfo={startDate.day}
          isForTerminal={true}
        />
      ) : null}

      {showValueBoxModal && selected && selected.id ? (
        <ValueBoxModal
          open={showValueBoxModal}
          handleClose={() => {
            setShowValueBoxModal(false);
            setRefreshBoxes(!refreshBoxes);
          }}
          internalTerminalId={selected?.id}
          terminalName={selected.name}
          isOpenFromTerminal={true}
        />
      ) : null}

      <Grid item xs={12} id="boxAndInfo">
        <Paper variant="outlined">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            mb={2}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<PlusIcon />}
              onClick={handleShowAddInfoModal}
            >
              {t('button.add', {
                item: t('attributes.info'),
              })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowGrid(!showGrid);
                setHideGridData(true);
              }}
            >
              {t('button.add', {
                item: t('attributes.grid'),
              })}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleShowValueBoxModal}
            >
              {`${t('resource.valueBoxes.capitalized')}`}
            </Button>
          </Box>

          <Box height="100%">
            <ReportToTerminalValueBox
              selectedTerminal={selected}
              selectedDateAndDay={startDate}
              refreshToggle={refreshBoxes}
              createBoxForTerminal={true}
            />
            <br />
            <ReportToTerminalInfo
              selectedTerminal={selected}
              selectedDateAndDay={startDate}
              isForTerminal={true}
            />
          </Box>
          <Box>
            {showGrid && selected && selected.id ? (
              <GridFunctionForTerminal
                open={showGrid}
                handleClose={() => {
                  setShowGrid(false);
                  setRefreshGrid(!refreshGrid);
                  setHideGridData(false);
                }}
                internalTerminalId={selected?.id}
                terminalName={selected.name}
                gridType="Terminal"
              />
            ) : null}
          </Box>
        </Paper>
        <TerminalGridValues
          internalTerminalId={selected?.id}
          refreshGrid={refreshGrid}
          selectedDateAndDay={startDate.date}
          hideGridData={hideGridData}
          gridType="Terminal"
        />
      </Grid>
    </Grid>
  );
}
