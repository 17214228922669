import {
  useDeleteMasterGridMutation,
  useGetGridValuesForDateQuery,
} from '../generated/graphql';
import { Form, Formik, FieldArray } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useState, useEffect, useCallback } from 'react';
import { DialogModal } from './DialogModal';
import { useTranslation } from 'react-i18next';
import { TextCellForGrid } from './form/TextCellForGrid';

interface GridProps {
  internalTerminalId: number;
  refreshGrid: boolean;
  selectedDateAndDay: string;
  hideGridData?: boolean;
  gridType: string;
}

interface InputGridValue {
  id: number | undefined;
  gridName: string;
  internalTerminalId: number;
}

export function TerminalGridValues(props: GridProps) {
  const {
    internalTerminalId,
    refreshGrid,
    selectedDateAndDay,
    hideGridData = false,
    gridType,
  } = props;
  const [initValues, setInitValues] = useState<InputGridValue>({
    id: undefined,
    internalTerminalId: internalTerminalId,
    gridName: '',
  });
  const { t } = useTranslation();
  const [isDeleteBoxVisible, setDeleteBoxVisible] = useState<boolean>(false);
  const [deleteMasterGridApi] = useDeleteMasterGridMutation();
  const {
    data: gridData,
    loading: gridLoading,
    refetch: gridRefetch,
  } = useGetGridValuesForDateQuery({
    fetchPolicy: 'network-only',
    variables: {
      internalTerminalId: internalTerminalId,
      valueAddedDate: selectedDateAndDay,
      gridType: gridType,
    },
  });
  useEffect(() => {
    gridRefetch({
      internalTerminalId: internalTerminalId,
      valueAddedDate: selectedDateAndDay,
      gridType: gridType,
    });
  }, [refreshGrid]);

  const openDeleteDialog = useCallback((grid: InputGridValue) => {
    setInitValues({
      ...initValues,
      id: grid.id,
      gridName: grid.gridName,
    });
    setDeleteBoxVisible(true);
  }, []);

  return (
    <Paper>
      <DialogModal
        open={isDeleteBoxVisible}
        setOpen={setDeleteBoxVisible}
        closeAction={() => setDeleteBoxVisible(false)}
        contentText={t('validation.confirmation', {
          item: `${initValues.gridName}`,
          action: t('actions.delete.lowercased'),
        })}
        doAction={() => {
          deleteMasterGridApi({
            variables: {
              Id: initValues.id ? initValues.id : 0,
            },
          }).then(() => {
            gridRefetch({
              internalTerminalId: internalTerminalId,
              valueAddedDate: selectedDateAndDay,
              gridType: gridType,
            });
          });
        }}
      />

      {gridLoading && (
        <div style={{ width: '90%', margin: 8 }}>
          <CircularProgress style={{ margin: 8, alignSelf: 'center' }} />
        </div>
      )}

      {!gridLoading &&
      !hideGridData &&
      gridData?.getGridValues !== undefined &&
      gridData?.getGridValues.length > 0
        ? gridData?.getGridValues.map((record) => {
            const deleteInput: InputGridValue = {
              gridName: record.gridName,
              id: record.gridEntryId,
              internalTerminalId: record.internalTerminalId,
            };
            const rowHeaderLength = record.rowHeaders.find(
              (rowHeader) => rowHeader.length > 11,
            );
            const rowHeaders = record.rowHeaders;
            const colHeaders = record.columnHeaders;
            const masterId = record.gridEntryId;
            const gridEntries = record.gridEntries;
            const gridName = record.gridName;
            const isNumberGridWithTotal = record.isNumberGridWithTotal;

            //to sort rows and their cells accordingly
            const sortedRowHeaders = [...rowHeaders].sort();
            //if grid with "Total row" then removing the 'total row',
            //then sorting the row cells and then again attach 'total row'
            const gridEntriesTotal = isNumberGridWithTotal
              ? [...gridEntries].pop()
              : null;
            const gridEntriesExcludedTotal = isNumberGridWithTotal
              ? [...gridEntries].slice(0, gridEntries.length - 1)
              : null;
            const GridEntriesToSort = isNumberGridWithTotal
              ? gridEntriesExcludedTotal
              : gridEntries;

            //stored the postion of index before and after sorting the rows
            //so that we can sort gridEntries in the same way we sorted rows
            const indexAfterSorting = sortedRowHeaders.map((value) =>
              rowHeaders.indexOf(value),
            );

            const sortedGridEntries = GridEntriesToSort?.slice().sort(
              (a: any, b: any) => {
                const aRowPos = a.rowPos;
                const bRowPos = b.rowPos;
                const aIndex = indexAfterSorting.indexOf(aRowPos);
                const bIndex = indexAfterSorting.indexOf(bRowPos);
                return aIndex - bIndex;
              },
            );

            isNumberGridWithTotal
              ? sortedGridEntries.push(gridEntriesTotal)
              : null;

            return (
              <>
                <Formik
                  initialValues={{
                    rowHeadersArr: rowHeaders
                      ? isNumberGridWithTotal
                        ? [...sortedRowHeaders, 'Total >']
                        : sortedRowHeaders
                      : [],
                    columnHeadersArr: colHeaders ? colHeaders : [],
                    gridEntriesArray: sortedGridEntries
                      ? sortedGridEntries
                      : [],
                  }}
                  enableReinitialize={true}
                  onSubmit={() => {
                    return;
                  }}
                >
                  {(props) => (
                    <Form>
                      <div style={{ marginTop: 2, marginBottom: 2 }}>
                        <FieldArray
                          name="gridEntriesArray"
                          render={() => {
                            return (
                              <>
                                {!record.isGridDeleted ? (
                                  <Grid container>
                                    <Grid item xs={11}></Grid>
                                    <Grid item xs={1}>
                                      <>
                                        <Tooltip title={`Delete ${gridName}`}>
                                          <IconButton
                                            style={{ padding: 0 }}
                                            onClick={() =>
                                              openDeleteDialog(deleteInput)
                                            }
                                          >
                                            <DeleteIcon
                                              fontSize="small"
                                              color="action"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    </Grid>
                                  </Grid>
                                ) : null}
                                <Grid container>
                                  <Grid
                                    item
                                    xs={
                                      gridName.length > 11 || rowHeaderLength
                                        ? 2
                                        : 1
                                    }
                                  >
                                    <div
                                      style={{
                                        padding: '4px 6px 4px 6px',
                                        backgroundColor: '#76ff03',
                                        fontSize: 13,
                                        justifyContent: 'center',
                                        border: '1px solid #000',
                                        marginTop: 3,
                                        borderRadius: 3,
                                        minHeight: 29,
                                      }}
                                    >
                                      {`${gridName}`}
                                    </div>
                                  </Grid>
                                  {props.values.columnHeadersArr.map(
                                    (colHeader) => {
                                      return (
                                        <Grid item xs={1}>
                                          <div
                                            style={{
                                              padding: '4px 6px 4px 6px',
                                              backgroundColor: '#d4d4d4',
                                              fontSize: 12,
                                              justifyContent: 'center',
                                              border: '1px solid #000',
                                              marginTop: 3,
                                              flexDirection: 'column',
                                              borderRadius: 3,
                                              minHeight: 29,
                                            }}
                                          >
                                            {`${colHeader}`}
                                          </div>
                                        </Grid>
                                      );
                                    },
                                  )}
                                </Grid>
                                {props.values.rowHeadersArr.map(
                                  (rowHeader, rowIndex) => {
                                    return (
                                      <>
                                        <Grid container direction="row">
                                          <Grid
                                            item
                                            xs={
                                              gridName.length > 11 ||
                                              rowHeaderLength
                                                ? 2
                                                : 1
                                            }
                                          >
                                            <div
                                              style={{
                                                padding: '4px 6px 4px 6px',
                                                backgroundColor: '#d4d4d4',
                                                fontSize: 12,
                                                justifyContent: 'center',
                                                border: '1px solid #000',
                                                borderRadius: 3,
                                                minHeight: 29,
                                              }}
                                            >
                                              {`${rowHeader}`}
                                            </div>
                                          </Grid>

                                          {props.values.columnHeadersArr.map(
                                            (colHeader, colIndex) => {
                                              const rowPosFromGridEntriesArr =
                                                props.values?.gridEntriesArray[
                                                  rowIndex
                                                ]?.rowPos;
                                              const colPosFromGridEntriesArr =
                                                props.values?.gridEntriesArray[
                                                  rowIndex
                                                ]?.columnArr[colIndex]
                                                  ?.columnPos;
                                              return (
                                                <Grid item xs={1}>
                                                  <TextCellForGrid
                                                    name={`gridEntriesArray[${rowIndex}].columnArr[${colIndex}].value`}
                                                    noLabel={true}
                                                    smallPadding={true}
                                                    allowOnlyNumbers={
                                                      isNumberGridWithTotal
                                                    }
                                                    type={
                                                      isNumberGridWithTotal
                                                        ? 'number'
                                                        : 'text'
                                                    }
                                                    isTotalCell={
                                                      isNumberGridWithTotal &&
                                                      rowHeader === 'Total >'
                                                    }
                                                    readOnly={
                                                      record.isGridDeleted ||
                                                      (isNumberGridWithTotal &&
                                                        rowHeader === 'Total >')
                                                    }
                                                    gridMasterId={masterId}
                                                    columnPosition={
                                                      colPosFromGridEntriesArr
                                                    }
                                                    rowPosition={
                                                      rowPosFromGridEntriesArr
                                                    }
                                                    valueAddedDate={
                                                      selectedDateAndDay
                                                    }
                                                    doRefetchGrid={() => {
                                                      gridRefetch({
                                                        internalTerminalId:
                                                          internalTerminalId,
                                                        valueAddedDate:
                                                          selectedDateAndDay,
                                                        gridType: gridType,
                                                      });
                                                    }}
                                                  />
                                                </Grid>
                                              );
                                            },
                                          )}
                                        </Grid>
                                      </>
                                    );
                                  },
                                )}
                              </>
                            );
                          }}
                        />
                      </div>
                      <Divider color="green" variant="fullWidth" />
                    </Form>
                  )}
                </Formik>
              </>
            );
          })
        : !gridLoading &&
          !hideGridData && (
            <Typography
              variant="subtitle1"
              style={{ paddingRight: '1%', margin: 5 }}
            >
              {`${t('resource.noGridAdded.capitalized')}`}
            </Typography>
          )}
    </Paper>
  );
}
