import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryParams } from '../../lib/useQuery';
import { TabsContainer, TabsContainerProps } from '../TabsContainer';

type FormikTabsContainerProps<T> = Omit<
  TabsContainerProps<T>,
  'tab' | 'setTab'
>;

export function FormikTabsContainer<T>(props: FormikTabsContainerProps<T>) {
  const query = useQueryParams();
  const routeQuery = query.get('route');
  const route = typeof routeQuery === 'string' ? parseInt(routeQuery) : 0;
  const [tab, setTab] = useState(route);

  const { isSubmitting, errors } = useFormikContext<{
    routes: { [key: string]: unknown }[];
  }>();

  useEffect(() => {
    if (isSubmitting) {
      const routeErrors = errors?.routes;

      if (routeErrors != null && isSubmitting) {
        const firstTabWithError = Object.values(routeErrors).findIndex(
          (value) => value != null,
        );

        if (firstTabWithError != null) {
          setTab(firstTabWithError);
        }
      }
    }
  }, [isSubmitting]);

  return <TabsContainer {...props} tab={tab} setTab={setTab} />;
}
