import { Box, Grid, Input, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
  GetCustomerAccountsForCardDocument,
  GetCustomerDocument,
  useCreateCustomerAccountMutation,
} from '../generated/graphql';
import { iconForType } from '../lib/iconForType';

const validationSchema = (t: TFunction) =>
  yup.object({
    accountNumber: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.accountNumber'),
          len: 3,
        }),
      )
      .matches(
        /^[0-9a-zA-Z]*$/,
        t('validation.onlyAlphaNumeric', {
          name: t('attributes.accountNumber'),
        }),
      )
      .required(
        t('validation.isRequired', { name: t('attributes.accountNumber') }),
      ),
    salesPersonName: yup
      .string()
      .nullable()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.salesPersonName'),
          len: 3,
        }),
      ),
    logisticPersonName: yup
      .string()
      .nullable()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.logisticPersonName'),
          len: 3,
        }),
      ),
  });

interface AddCustomerAccountProps {
  next: string;
}

export function AddCustomerAccount(props: AddCustomerAccountProps) {
  const { t } = useTranslation();
  const router = useHistory();
  const { id } = useParams<{ id: string }>();

  const cardType = 'customers';
  const cardTypeName = cardType.substring(0, cardType.length - 1);
  const Icon = iconForType(cardTypeName);

  const [cardName, setCardName] = useState<string>('');

  const location = useLocation<{
    cardName: string;
    timestamp: number;
  }>();

  useEffect(() => {
    if (location.state && location.state.cardName) {
      setCardName(location.state.cardName);
    }
  }, [location.state?.cardName, location.state?.timestamp]);

  const [addCustomerAccountApi, {}] = useCreateCustomerAccountMutation({
    refetchQueries: [
      {
        query: GetCustomerAccountsForCardDocument,
        variables: {
          cardId: parseInt(id, 10),
        },
      },
      {
        query: GetCustomerDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });

  const formik = useFormik({
    initialValues: {
      accountNumber: '',
      note: '',
      salesPersonName: '',
      logisticPersonName: '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      addCustomerAccountApi({
        variables: { input: values, cardId: parseInt(id, 10) },
      });
      router.push(`/traffic/cards/${props.next}/${id}`);
    },
  });

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={3}>
        <Typography align="left" variant="h2">
          {cardName}
        </Typography>
        <Box ml={2} display="flex" alignItems="center">
          <Typography align="left" variant="subtitle2">
            {t(`resource.${cardTypeName}.capitalized`)}
          </Typography>
          {Icon && <Icon />}
        </Box>
      </Box>

      <Box mb={6}>
        <Typography variant="h2">
          {t('pages.addPage.title', {
            item: t('resource.accounts.lowercased'),
          })}
        </Typography>
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={5} justifyContent="center">
            <Grid item sm={10} container spacing={3}>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="accountNumber"
                  name="accountNumber"
                  label={t('attributes.accountNumber')}
                  value={formik.values.accountNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountNumber &&
                    Boolean(formik.errors.accountNumber)
                  }
                  helperText={
                    formik.touched.accountNumber && formik.errors.accountNumber
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="note"
                  name="note"
                  label={t('attributes.note')}
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  error={formik.touched.note && Boolean(formik.errors.note)}
                  helperText={formik.touched.note && formik.errors.note}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="salesPersonName"
                  name="salesPersonName"
                  label={t('attributes.salesPersonName')}
                  value={formik.values.salesPersonName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.salesPersonName &&
                    Boolean(formik.errors.salesPersonName)
                  }
                  helperText={
                    formik.touched.salesPersonName &&
                    formik.errors.salesPersonName
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  id="logisticPersonName"
                  name="logisticPersonName"
                  label={t('attributes.logisticPersonName')}
                  value={formik.values.logisticPersonName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.logisticPersonName &&
                    Boolean(formik.errors.logisticPersonName)
                  }
                  helperText={
                    formik.touched.logisticPersonName &&
                    formik.errors.logisticPersonName
                  }
                />
              </Grid>

              <Grid item sm={12} container justifyContent="center">
                <Input
                  type="submit"
                  value={t('button.add', {
                    item: t('resource.accounts.lowercased'),
                  })}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
