import { Form } from 'formik';
import { ComponentProps } from 'react';

type SupressEnterFormProps = ComponentProps<typeof Form>;

export function SupressEnterForm(props: SupressEnterFormProps) {
  return (
    <Form {...props}>
      <button disabled type="submit" aria-hidden style={{ display: 'none' }} />
      {props.children}
    </Form>
  );
}
