import { Box, Button, Grid, List, ListItem } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { calculateTransportationAndProductionDates } from '../lib/route_leg_dates';
import { CheckListRoutesFormInput } from '../views/CheckListRoutesTraffic';
import { ChecklistLegRHF } from './form/ChecklistLegRHF';

interface ChkLegsListProps {
  readOnly: boolean;
  routeIndex: number;
}

export function ChecklistLegsListRHF(props: ChkLegsListProps) {
  const { readOnly = false, routeIndex } = props;
  const {
    control,
    getValues,
    setValue: setFieldValue,
  } = useFormContext<CheckListRoutesFormInput>();
  const [posChanged, setPosChanged] = useState<boolean>(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: `routes.${routeIndex}.legs`,
  });
  const transportationDateString = getValues(
    `routes.${routeIndex}.transportationDate`,
  );

  //for dates calculations on remove leg
  useEffect(() => {
    if (readOnly || transportationDateString === '') {
      return;
    }
    const allLegs = getValues(`routes.${routeIndex}.legs`);

    calculateTransportationAndProductionDates(
      allLegs,
      transportationDateString,
    ).forEach((leg, index) => {
      setFieldValue(
        `routes.${routeIndex}.legs.${index}.productionDate`,
        leg.productionDate,
      );
      setFieldValue(
        `routes.${routeIndex}.legs.${index}.transportationDate`,
        leg.transportationDate,
      );
    });
  }, [posChanged, transportationDateString]);

  return (
    <Box style={{ marginLeft: 42, marginRight: 30 }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        style={{ padding: 0 }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            append({
              key: uuidv4(),
              idNum: 0,
              arrivalTime: '',
              departureTime: undefined,
              locationId: 0,
              position: parseInt(`${fields.length}`),
              note: null,
              load: false,
              unload: false,
              loadingListItems: [],
              transportationDate: transportationDateString,
              productionDate: transportationDateString,
              transportationDateOffset: 0,
            });
          }}
        >
          {`ADD LEG`}
        </Button>
      </Grid>

      <List>
        {fields.map((leg, index) => (
          <ListItem key={leg.id} style={{ padding: 0 }}>
            <ChecklistLegRHF
              readOnly={readOnly}
              routeIndex={routeIndex}
              legIndex={index}
              legsLength={fields.length}
              onRemove={(index) => {
                remove(index);
                setPosChanged(!posChanged);
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
