import Button from '@material-ui/core/Button';
import { HttpClient } from '../lib/httpClient';
import { useHttpClient } from '../providers/HttpClientProvider';

interface DownloadPeakPlanningListProps {
  startDate: string;
  endDate: string;
}

export function DownloadPeakPlanningDebitList(
  props: DownloadPeakPlanningListProps,
) {
  const { startDate, endDate } = props;
  const { httpClient } = useHttpClient();

  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      onClick={() => {
        getPeakPlanningDebit(httpClient, startDate, endDate);
      }}
    >
      Export to Exel
    </Button>
  );
}

function getPeakPlanningDebit(
  httpClient: HttpClient,
  startDate: string,
  endDate: string,
) {
  httpClient
    .downloadPeakPlanningDebitList({ startDate, endDate })
    .then((res) => {
      const blob = res.data;
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', `PeakPlanningDebit`);
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
}
