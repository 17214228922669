import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';

interface CheckboxIconFieldProps {
  name: string;
  icon: ReactNode;
  checkedIcon: ReactNode;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  handleEdit?: (
    e: React.SyntheticEvent,
    name: string,
    editedField: string,
    checked?: boolean,
  ) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: '1px',
    },
  }),
);

export function CheckboxIconField(props: CheckboxIconFieldProps) {
  const classes = useStyles();
  const { getFieldProps, setFieldValue } = useFormikContext();

  const {
    name,
    readOnly = false,
    size = undefined,
    disabled = false,
    icon,
    checkedIcon,
  } = props;
  const [checked, setChecked] = useState(getFieldProps(`${name}`).value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`${name}`, event.target.checked);
  };

  useEffect(() => {
    setChecked(getFieldProps(`${name}`).value);
  }, [getFieldProps(`${name}`).value]);

  return (
    <FormControlLabel
      className={classes.formControl}
      disabled={readOnly || disabled}
      control={
        <Checkbox
          size={size}
          color="primary"
          className={classes.checkBox}
          checked={checked}
          onChange={handleChange}
          icon={icon}
          checkedIcon={checkedIcon}
        />
      }
      label={''}
    />
  );
}
