import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CreatedAndUpdatedView } from '../components/CreatedAndUpdatedView';
import { OrderForm } from '../components/OrderForm';
import {
  GetOrderDocument,
  OrderInput,
  useGetOrderQuery,
  useUpdateOrderMutation,
} from '../generated/graphql';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  header: {
    margin: theme.spacing(2, 5, 1),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export function ViewOrder() {
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState(false);
  const { t } = useTranslation();
  const classes = useStyle();

  const [updateOrder] = useUpdateOrderMutation({
    refetchQueries: [
      {
        query: GetOrderDocument,
        variables: {
          id,
        },
      },
    ],
  });
  const { data, loading, error } = useGetOrderQuery({
    variables: {
      id: parseInt(id, 10),
    },
  });

  const onSubmit = async (
    values: Omit<OrderInput, 'customerId'> & {
      customerId?: number;
    },
  ) => {
    await updateOrder({
      variables: {
        id: parseInt(id, 10),
        input: {
          transportationDate: values.transportationDate,
          note: values.note,
          customerId: values.customerId as number,
          packages: values.packages,
          pallets: values.pallets,
          palletSpace: values.palletSpace,
          weight: values.weight,
        },
      },
    });
    setEdit(false);
  };

  if (loading || data == null) {
    return <div>loading...</div>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const { order } = data;

  const initialValues = (): Omit<OrderInput, 'customerId'> & {
    customerId?: number;
  } => ({
    id: order.id,
    transportationDate: order.transportationDate,
    note: order.note,
    customerId: order.customerId,
    packages: order.packages,
    pallets: order.pallets,
    palletSpace: order.palletSpace,
    weight: order.weight,
  });

  return (
    <Grid container spacing={5} direction="column">
      <Grid item>
        <Box className={classes.header}>
          <Typography align="left" variant="h2">
            {t('resource.order.capitalized')}
          </Typography>
          <CreatedAndUpdatedView
            createdBy={data.order.createdBy}
            lastUpdatedBy={data.order.lastUpdatedBy}
            updatedAt={data.order.updatedAt}
          />
          <Box>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => setEdit(!edit)}
            >
              {edit ? <CancelIcon /> : <EditIcon />}
            </IconButton>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <OrderForm
          onSubmit={onSubmit}
          initialValues={initialValues()}
          submitButtonLabel={t('button.update', {
            item: t('resource.order.lowercased'),
          })}
          readOnly={!edit}
        />
      </Grid>
    </Grid>
  );
}
