import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { TABLE_NAMES } from '../lib/constants';
import { useHttpClient } from '../providers/HttpClientProvider';
import { tourTemplateColumns } from '../utils/tourTemplateColumns';

interface TourTemplatesResponse {
  id: number;
  name: string;
  subcontractorId: number;
  capacity?: number;
  fuel: string;
  price: number;
  currency: string;
  startDate: string;
  endDate?: string;
  originalStartDate?: string | undefined;
  isActive: boolean;
  note: string;
  createdBy: string;
  lastUpdatedBy: string;
  updatedAt?: string | undefined;
  startExceptionDate?: string | undefined;
  endExceptionDate?: string | undefined;
  responsiblePerson?: string | undefined;
  groupingTag?: string | undefined;
  subcontractorName: string;
  dispatcherName: string;
}

export function TourTemplatesTourView() {
  const { t } = useTranslation();

  const { url } = useRouteMatch();
  const { httpClient } = useHttpClient();
  const [gridHeight, setGridHeight] = useState(0);
  const [apiData, setApiData] = useState<TourTemplatesResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorObj, setErrorObj] = useState<{
    error: boolean;
    message: string;
  }>({
    error: false,
    message: '',
  });

  useEffect(() => {
    const element = document.getElementById('tourGrid');
    const positions = element?.getBoundingClientRect();
    if (positions) {
      setGridHeight(window.innerHeight - (positions.top + positions.top / 5));
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    httpClient
      .getAllTourTemplates()
      .then((res) => {
        if (res.data && res.data.status === 'OK') {
          setApiData(res.data.data);
        } else if (res.data && res.data.status === 'FAIL') {
          setApiData([]);
          setErrorObj({
            error: true,
            message: `${res.data.message}`,
          });
          console.error('# TourTemplates Res error=', res.data.message);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.error('# TourTemplates exception error=', e);
        setLoading(false);
      });
  }, []);

  if (errorObj.error) {
    return <div>{errorObj.message}</div>;
  }

  if (loading || apiData == null) {
    return <div>loading...</div>;
  }

  return (
    <Box
      id="tourGrid"
      style={{
        height: gridHeight ? gridHeight : 500,
        width: '100%',
      }}
    >
      <UserConfiguredDataGridPro
        tableName={TABLE_NAMES.TourTemplates}
        rows={apiData || []}
        loading={loading}
        disableSelectionOnClick
        columns={tourTemplateColumns({ t, url })}
        pageSize={10}
        rowHeight={50}
      />
    </Box>
  );
}
