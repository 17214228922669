import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Tooltip,
  Typography,
} from '@material-ui/core';
import UnLockIcon from '@mui/icons-material/LockOpen';
import DangerIcon from '@mui/icons-material/Report';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useEffect, useState } from 'react';
import {
  DangerousGoodsClassification,
  useGetRouteLegForViewLoadingListLazyQuery,
  useUpdateLockMutation,
  useGetRouteLockLazyQuery,
  Maybe,
  useUpdateLegWithLoadingListMutation,
  LegLoadingListInput,
  useRouteLockSubSubscription,
} from '../../generated/graphql';
import { useStylesForLoadingList } from '../LoadingList';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useTranslation } from 'react-i18next';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { SimpleMessageDialog } from '../SimpleMessageDialog';
import { SelectLocation } from '../form/SelectLocation';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { FastCheckbox } from '../form/FastCheckbox';
import { CheckboxIconField } from '../form/CheckboxIconField';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import { FastTextFieldUnNumber } from '../form/FastTextFieldUnNumber';
import { InfoOutlined } from '@material-ui/icons';
import { CreatedAndUpdatedView } from '../CreatedAndUpdatedView';
import * as yup from 'yup';
import { TFunction } from 'i18next';
import { terminalLoadingListItemYupValidation } from '../../lib/validationSchema/loadingListItem';
import _ from 'lodash';
import { getLoadingList } from '../DownloadLoadingList';
import { HttpClient } from '../../lib/httpClient';
import LinkedIcon from '@mui/icons-material/Link';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { DATE_FORMAT } from '../../lib/date_time';

const loadingListItemValidation = (t: TFunction) =>
  yup.object().shape({
    legsInitValue: yup.array(
      yup.object().shape({
        locationId: yup
          .number()
          .required(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          )
          .positive(
            t('validation.isRequired', { name: t('attributes.terminal') }),
          )
          .min(
            1,
            t('validation.isRequired', {
              name: t('attributes.terminal'),
            }),
          ),
        loadingList: terminalLoadingListItemYupValidation(t),
      }),
    ),
  });

export const styleForTimeModal = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  height: '92vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 20,
  p: 2,
  overflowY: 'scroll' as const,
};
interface LegWithLoadingListInterface {
  id: number;
  locationId: number;
  position: number;
  loadingList: {
    id?: number;
    packages?: Maybe<number>;
    pallets?: Maybe<number>;
    palletSpace?: Maybe<number>;
    weight?: Maybe<number>;
    note: string;
    locationId: number;
    checked: boolean;
    isDangerous: boolean;
    isImportedFromShipment?: boolean;
    unNumber?: Maybe<number> | undefined;
    customWaybillNo?: Maybe<string> | undefined;
    isLimitedQty: boolean;
    classification?: Maybe<DangerousGoodsClassification> | undefined;
  }[];
}

interface loadingListProps {
  legId: number;
  routeId: number;
  routeName: string;
  httpClient: HttpClient;
  loadingListError: () => void;
}

export function LoadingListModal(props: loadingListProps) {
  const { t } = useTranslation();
  const { legId, loadingListError, routeId, routeName, httpClient } = props;
  const { id } = useParams<{ id: string }>();
  const [fetchLegList, { data: viewLegListData, error: viewLegListError }] =
    useGetRouteLegForViewLoadingListLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const [fetchRouteLock, { data: routeLockData }] = useGetRouteLockLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [updateLoadingList] = useUpdateLegWithLoadingListMutation();
  const [updateLockMutation, { loading: lockLoading }] =
    useUpdateLockMutation();
  const classes = useStylesForLoadingList();
  const [routeStatus, setRouteStatus] = useState<{
    routeCreatedBy: string;
    routeLastUpdatedBy: string;
    routeUpdatedAt: string;
    routeDeletedAt: string;
    routeDeletedBy: string;
  }>({
    routeCreatedBy: '',
    routeDeletedAt: '',
    routeDeletedBy: '',
    routeLastUpdatedBy: '',
    routeUpdatedAt: '',
  });
  const [viewloadingListItems, setViewLoadingListItems] = useState(false);
  const [readOnlyView, setReadOnlyView] = useState(false);
  const [currentFocusedIndex, setCurrentFocusedIndex] = useState<number>(-1);
  const [openLimitedQtyInfoDialog, setOpenLimitedQtyInfoDialog] =
    useState<boolean>(false);
  const [lockObject, setLockObject] = useState<{
    lockedBy: string;
    lockedAt: string;
  }>({ lockedBy: '', lockedAt: '' });
  const [legsInitValue, setLegsInitValue] = useState<
    LegWithLoadingListInterface[]
  >([]);
  const getFormattedDate = (date: any): string => {
    if (date && date !== '-') {
      const dt = new Date(date);
      return format(dt, `HH:mm - ${DATE_FORMAT}`);
    } else return '';
  };

  //for lock/unlock refresh
  useRouteLockSubSubscription({
    variables: {
      routeId: parseInt(id, 10),
    },
    async onSubscriptionData({ subscriptionData }) {
      if (
        subscriptionData.data &&
        subscriptionData.data.routeLockSub &&
        subscriptionData.data.routeLockSub.routeId === parseInt(id, 10)
      ) {
        if (
          (subscriptionData.data.routeLockSub.lock === 3 &&
            subscriptionData.data.routeLockSub.reload === 3) ||
          (subscriptionData.data.routeLockSub.lock === 4 &&
            subscriptionData.data.routeLockSub.reload === 4)
        ) {
          setViewLoadingListItems(false);
        } else {
          if (
            subscriptionData.data.routeLockSub.lockedBy !== '' &&
            subscriptionData.data.routeLockSub.lock === 1
          ) {
            setLockObject({
              lockedBy: subscriptionData.data.routeLockSub.lockedBy,
              lockedAt: getFormattedDate(
                subscriptionData.data.routeLockSub.lockedAt,
              ),
            });
          }
        }
      }
    },
  });
  const [totalSum, setTotalSum] = useState({
    totalPalletsSum: 0,
    totalPPLSum: 0,
  });
  let totalPallets = 0;
  let totalPPL = 0;

  useEffect(() => {
    if (viewLegListData && viewLegListData.routeLegForViewLoadingList) {
      const loadingListInitArray: LegWithLoadingListInterface[] = [];
      viewLegListData.routeLegForViewLoadingList.forEach((leg) => {
        loadingListInitArray.push({
          id: leg.id,
          locationId: leg.locationId,
          position: leg.position,
          loadingList: leg.loadingListItems,
        });
        totalPallets = totalPallets + _.sumBy(leg.loadingListItems, 'pallets');
        totalPPL = totalPPL + _.sumBy(leg.loadingListItems, 'palletSpace');
      });
      setLegsInitValue(loadingListInitArray);
      setTotalSum({ totalPalletsSum: totalPallets, totalPPLSum: totalPPL });
      if (viewLegListData.routeLegForViewLoadingList[0].route) {
        setRouteStatus({
          routeCreatedBy:
            viewLegListData.routeLegForViewLoadingList[0].route.createdBy,
          routeLastUpdatedBy:
            viewLegListData.routeLegForViewLoadingList[0].route.lastUpdatedBy,
          routeUpdatedAt:
            viewLegListData.routeLegForViewLoadingList[0].route.updatedAt,
          routeDeletedAt:
            viewLegListData.routeLegForViewLoadingList[0].route.deletedAt,
          routeDeletedBy:
            viewLegListData.routeLegForViewLoadingList[0].route.deletedBy,
        });
      }
      if (viewLegListData.routeLegForViewLoadingList[0].id === legId) {
        //if selected leg is load type then we check if its route is locked or not
        fetchRouteLock({ variables: { routeId } });
      } else {
        //if selected leg is unload type then we display previous leg in readOnly mode.
        setViewLoadingListItems(true);
        setReadOnlyView(true);
      }
    } else if (viewLegListError) {
      loadingListError();
    }
  }, [viewLegListData, viewLegListError]);

  useEffect(() => {
    if (routeLockData?.routeLock) {
      setViewLoadingListItems(true);
      if (routeLockData?.routeLock && routeLockData.routeLock.lockedBy) {
        setLockObject({
          lockedAt: getFormattedDate(routeLockData?.routeLock.lockedAt),
          lockedBy: routeLockData?.routeLock.lockedBy,
        });
        setReadOnlyView(true);
      } else {
        //Locked by you
        updateLockMutation({
          variables: { routeId: routeId, lock: 1, reload: 0 },
        });
        setReadOnlyView(false);
      }
    }
  }, [routeLockData]);

  const handleBorderVisibility = (index: number) => {
    if (index !== currentFocusedIndex) setCurrentFocusedIndex(index);
  };
  function getChecked(legIndex: number, index: number): string {
    if (legsInitValue && legsInitValue[legIndex].loadingList.length > 0) {
      if (index + 1 > legsInitValue.length) '';
      else if (legsInitValue[legIndex].loadingList[index].checked)
        classes.greenChecked;
      return '';
    }
    return '';
  }

  function getDangerous(legIndex: number, index: number): string {
    if (legsInitValue && legsInitValue[legIndex].loadingList.length > 0) {
      if (index + 1 > legsInitValue.length) '';
      else if (legsInitValue[legIndex].loadingList[index].isDangerous)
        classes.redBorder;
      return '';
    }
    return '';
  }

  return (
    <>
      <Tooltip
        title={`${t('actions.viewItem', {
          item: t('resource.loadingList.capitalized'),
        })}`}
      >
        <IconButton
          onClick={() => {
            fetchLegList({
              variables: {
                legId: legId,
              },
            });
          }}
        >
          <PageviewIcon color="action" />
        </IconButton>
      </Tooltip>
      {legsInitValue.length > 0 && (
        <Modal
          open={viewloadingListItems}
          onClose={() => {
            //if route is locked by other
            if (!readOnlyView) {
              updateLockMutation({
                variables: { routeId: routeId, lock: 0, reload: 1 },
              }).then(() => {
                setViewLoadingListItems(false);
              });
            } else setViewLoadingListItems(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableScrollLock
        >
          <Box sx={styleForTimeModal}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography
                  align="center"
                  style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}
                >
                  {`Route Id: ${routeName}`}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Box>
                  {lockObject?.lockedBy && readOnlyView ? (
                    <Tooltip title={`${t('actions.unlock')}`}>
                      <IconButton
                        edge="end"
                        aria-label="Unlock"
                        onClick={() => {
                          updateLockMutation({
                            variables: { routeId: routeId, lock: 1, reload: 1 },
                          }).then(() => {
                            setReadOnlyView(false);
                            setLockObject({
                              lockedAt: '',
                              lockedBy: '',
                            });
                          });
                        }}
                      >
                        {<UnLockIcon />}
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
            {routeStatus.routeCreatedBy && (
              <CreatedAndUpdatedView
                createdBy={routeStatus.routeCreatedBy}
                lastUpdatedBy={routeStatus.routeLastUpdatedBy}
                updatedAt={routeStatus.routeUpdatedAt}
                deletedAt={routeStatus.routeDeletedAt}
                deletedBy={routeStatus.routeDeletedBy}
                lockedBy={lockObject?.lockedBy}
                lockedAt={lockObject?.lockedAt}
              />
            )}

            {!readOnlyView && (
              <Typography align="left" style={{ color: 'orangered' }}>
                {t('attributes.routeLockedBySelf')}
              </Typography>
            )}
            {/* if leg is of type load then only we can edit else we will 
            just disply previous leg in read only mode */}
            <Grid container>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <Typography align="center" style={{ fontWeight: 'bold' }}>
                  {`Total-pallets: ${totalSum.totalPalletsSum}`}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography align="center" style={{ fontWeight: 'bold' }}>
                  {`Total pallet-place: ${totalSum.totalPPLSum}`}
                </Typography>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
            <Formik
              initialValues={{ legsInitValue }}
              validate={(value) => {
                try {
                  validateYupSchema(
                    value,
                    loadingListItemValidation(t),
                    true,
                    value,
                  );
                } catch (err) {
                  const errors = yupToFormErrors<{ error: string }>(err);
                  return errors;
                }
                return {};
              }}
              onSubmit={(value) => {
                const mutationVariable: LegLoadingListInput = {
                  id: legId,
                  loadingListItems: value.legsInitValue[0].loadingList.map(
                    (ll) => ({
                      id: ll.id,
                      locationId: ll.locationId,
                      packages: ll.packages,
                      pallets: ll.pallets,
                      palletSpace: ll.palletSpace,
                      weight: ll.weight,
                      checked: ll.checked,
                      isDangerous: ll.isDangerous,
                      classification: ll.classification,
                      unNumber: ll.unNumber,
                      isLimitedQty: ll.isLimitedQty,
                      note: ll.note,
                    }),
                  ),
                };

                updateLoadingList({
                  variables: { input: mutationVariable },
                });
                setViewLoadingListItems(false);
                if (!readOnlyView)
                  updateLockMutation({
                    variables: { routeId: routeId, lock: 0, reload: 1 },
                  }).then(() => setViewLoadingListItems(false));
                else setViewLoadingListItems(false);
              }}
            >
              {(props) => {
                return (
                  <Form>
                    <FieldArray
                      name={'legsInitValue'}
                      render={() => (
                        <Grid item xs={12}>
                          {props.values.legsInitValue.map((leg, legIndex) => (
                            <>
                              <Grid container style={{ marginTop: 5 }}>
                                <Grid item xs={1}>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      marginBottom: 5,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {`Leg ${leg.position + 1}`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <SelectLocation
                                    key={`legsInitValue.${legIndex}`}
                                    name={`legsInitValue.${legIndex}.locationId`}
                                    className={classes.selecLocationField}
                                    readOnly={true}
                                    size="small"
                                    smallFontSize
                                    noLabel
                                    smallPadding
                                    shortAddress={true}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                spacing={2}
                                container
                                className={classes.root}
                              >
                                <SimpleMessageDialog
                                  open={openLimitedQtyInfoDialog}
                                  setOpen={setOpenLimitedQtyInfoDialog}
                                  title={t('validation.limitedQtyInfoTitle')}
                                  contentText={t('validation.limitedQtyInfo')}
                                  isMultiLine={true}
                                />
                                <Grid item xs={10}>
                                  <Typography variant="body2" align="left">
                                    {t('resource.loadingList.capitalized')}
                                  </Typography>
                                </Grid>

                                <FieldArray
                                  name={`legsInitValue.${legIndex}.loadingList`}
                                  render={(listArrayHelpers) => (
                                    <>
                                      {!readOnlyView && (
                                        <Grid item xs={2}>
                                          <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                          >
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              size="small"
                                              onClick={() => {
                                                listArrayHelpers.push({
                                                  id: undefined,
                                                  packages: 0,
                                                  pallets: 0,
                                                  palletSpace: 0,
                                                  weight: 0,
                                                  note: '',
                                                  locationId: undefined,
                                                  checked: false,
                                                  isDangerous: false,
                                                  classification: undefined,
                                                  unNumber: undefined,
                                                  isLimitedQty: false,
                                                });
                                              }}
                                            >
                                              {t('button.add', {
                                                item: t('order'),
                                              })}
                                            </Button>
                                          </Box>
                                        </Grid>
                                      )}
                                      {leg.loadingList.length > 0 ? (
                                        <>
                                          <Grid
                                            xs={12}
                                            item
                                            container
                                            style={{
                                              backgroundColor: '#c0c4bc',
                                              marginBottom: 5,
                                              borderRadius: 2,
                                              marginTop: 5,
                                            }}
                                          >
                                            <Grid item xs={4}>
                                              <Typography
                                                variant="body2"
                                                color="textPrimary"
                                              >
                                                {'Customer'}
                                              </Typography>
                                            </Grid>

                                            <Grid container item xs={3}>
                                              <Grid item xs={3}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'PCS'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'PAL'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'PPL'}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                <Typography
                                                  variant="body2"
                                                  color="textPrimary"
                                                >
                                                  {'KG'}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            <Grid item xs={2}>
                                              <Typography
                                                variant="body2"
                                                color="textPrimary"
                                              >
                                                {'WayBillNo'}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                              <Typography
                                                variant="body2"
                                                color="textPrimary"
                                              >
                                                {'Note'}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={12}
                                            style={{
                                              backgroundColor: '#f6fce8',
                                            }}
                                          >
                                            <List className={classes.noPadding}>
                                              {leg.loadingList.map(
                                                (_order, index) => {
                                                  const isOrderLinked =
                                                    _order.isImportedFromShipment;
                                                  return (
                                                    <ListItem
                                                      key={index}
                                                      divider
                                                      className={`${getChecked(
                                                        legIndex,
                                                        index,
                                                      )} ${getDangerous(
                                                        legIndex,
                                                        index,
                                                      )} ${classes.noPadding}`}
                                                    >
                                                      <Grid
                                                        xs={12}
                                                        item
                                                        container
                                                        key={index}
                                                        style={{
                                                          width: '100%',
                                                        }}
                                                        onFocus={(event) => {
                                                          if (
                                                            !event.currentTarget.contains(
                                                              event.relatedTarget as Node,
                                                            )
                                                          ) {
                                                            handleBorderVisibility(
                                                              index,
                                                            );
                                                          }
                                                        }}
                                                        onBlur={(event) => {
                                                          if (
                                                            !event.currentTarget.contains(
                                                              event.relatedTarget as Node,
                                                            )
                                                          ) {
                                                            setCurrentFocusedIndex(
                                                              -1,
                                                            );
                                                          }
                                                        }}
                                                        className={`${
                                                          currentFocusedIndex ===
                                                          index
                                                            ? classes.greyBackFocused
                                                            : ''
                                                        } ${classes.noPadding}`}
                                                      >
                                                        <Box
                                                          display="flex"
                                                          justifyContent="flex-start"
                                                          alignItems="flex-start"
                                                          style={{
                                                            width: '100%',
                                                          }}
                                                        >
                                                          <Grid
                                                            item
                                                            container
                                                            xs={4}
                                                            direction={'row'}
                                                          >
                                                            {isOrderLinked && (
                                                              <Grid item xs={1}>
                                                                <Tooltip
                                                                  title={`${t(
                                                                    'attributes.orderIsLinked',
                                                                  )}`}
                                                                >
                                                                  <LinkedIcon
                                                                    style={{
                                                                      color:
                                                                        'grey',
                                                                    }}
                                                                  />
                                                                </Tooltip>
                                                              </Grid>
                                                            )}
                                                            <Grid
                                                              item
                                                              xs={
                                                                isOrderLinked
                                                                  ? 11
                                                                  : 12
                                                              }
                                                            >
                                                              <SelectLocation
                                                                key={`loadingListInitValue_${index}`}
                                                                name={`legsInitValue.${legIndex}.loadingList.${index}.locationId`}
                                                                className={
                                                                  classes.selecLocationField
                                                                }
                                                                readOnly={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                }
                                                                size="small"
                                                                smallFontSize
                                                                noLabel
                                                                smallPadding
                                                                shortAddress={
                                                                  true
                                                                }
                                                              />
                                                            </Grid>
                                                          </Grid>
                                                          <Grid
                                                            container
                                                            item
                                                            xs={3}
                                                          >
                                                            <Grid item xs={3}>
                                                              <FastTextFieldPackage
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                name={`legsInitValue.${legIndex}.loadingList.${index}.packages`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                                readOnly={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                }
                                                                smallPadding
                                                              />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                              <FastTextFieldPallets
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                name={`legsInitValue.${legIndex}.loadingList.${index}.pallets`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                                readOnly={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                }
                                                                smallPadding
                                                              />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                              <FastTextFieldPalletSpace
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                smallPadding
                                                                name={`legsInitValue.${legIndex}.loadingList.${index}.palletSpace`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                                readOnly={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                }
                                                              />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                              <FastTextFieldWeight
                                                                noLabel
                                                                size="small"
                                                                smallFontSize
                                                                smallPadding
                                                                name={`legsInitValue.${legIndex}.loadingList.${index}.weight`}
                                                                className={
                                                                  classes.numberFields
                                                                }
                                                                type="number"
                                                                readOnly={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                }
                                                              />
                                                            </Grid>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={2}
                                                            style={{
                                                              marginRight: 2,
                                                            }}
                                                          >
                                                            <FastTextFieldNote
                                                              noLabel
                                                              size="small"
                                                              smallFontSize
                                                              name={`legsInitValue.${legIndex}.loadingList.${index}.customWaybillNo`}
                                                              readOnly={true}
                                                              className={
                                                                classes.field
                                                              }
                                                              smallPadding
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={2}
                                                            style={{
                                                              marginRight: 2,
                                                            }}
                                                          >
                                                            <FastTextFieldNote
                                                              noLabel
                                                              size="small"
                                                              multiline
                                                              smallFontSize
                                                              name={`legsInitValue.${legIndex}.loadingList.${index}.note`}
                                                              readOnly={
                                                                readOnlyView ||
                                                                isOrderLinked
                                                              }
                                                              className={
                                                                classes.field
                                                              }
                                                              smallPadding
                                                            />
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            xs={1}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                          >
                                                            <Box
                                                              width={'100%'}
                                                              display="flex"
                                                              justifyContent="space-evenly"
                                                              alignItems="center"
                                                            >
                                                              <FastCheckbox
                                                                name={`legsInitValue.${legIndex}.loadingList.${index}.checked`}
                                                                label={''}
                                                                readOnly={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                }
                                                                size="small"
                                                              />

                                                              <Tooltip
                                                                title={
                                                                  readOnlyView ||
                                                                  isOrderLinked
                                                                    ? `${t(
                                                                        'attributes.tooltipDangerousGoodsIconReadOnly',
                                                                      )}`
                                                                    : `${t(
                                                                        'attributes.tooltipDangerousGoodsIcon',
                                                                      )}`
                                                                }
                                                              >
                                                                <div>
                                                                  <CheckboxIconField
                                                                    name={`legsInitValue.${legIndex}.loadingList.${index}.isDangerous`}
                                                                    icon={
                                                                      <DangerIcon
                                                                        style={{
                                                                          color:
                                                                            'grey',
                                                                        }}
                                                                      />
                                                                    }
                                                                    checkedIcon={
                                                                      <DangerIcon
                                                                        style={{
                                                                          color:
                                                                            'red',
                                                                        }}
                                                                      />
                                                                    }
                                                                    size="small"
                                                                    readOnly={
                                                                      readOnlyView ||
                                                                      isOrderLinked
                                                                    }
                                                                  />
                                                                </div>
                                                              </Tooltip>
                                                              <>
                                                                {leg.loadingList
                                                                  .length <=
                                                                  1 ||
                                                                readOnlyView ||
                                                                isOrderLinked ? (
                                                                  <></>
                                                                ) : (
                                                                  <IconButton
                                                                    className={
                                                                      classes.deleteButton
                                                                    }
                                                                    onClick={() => {
                                                                      listArrayHelpers.remove(
                                                                        index,
                                                                      );
                                                                    }}
                                                                    disabled={
                                                                      readOnlyView
                                                                    }
                                                                  >
                                                                    <DeleteIcon />
                                                                  </IconButton>
                                                                )}
                                                              </>
                                                            </Box>
                                                          </Grid>
                                                        </Box>
                                                        {_order.isDangerous && (
                                                          <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="flex-start"
                                                            style={{
                                                              width: '100%',
                                                              marginTop: 1,
                                                            }}
                                                          >
                                                            {/* new for dangerous goods info */}
                                                            <Grid
                                                              item
                                                              xs={5}
                                                              container
                                                              direction="row"
                                                              alignItems="center"
                                                            >
                                                              <Grid item xs={2}>
                                                                <Typography
                                                                  style={{
                                                                    fontSize: 12,
                                                                  }}
                                                                  color="textPrimary"
                                                                >{`Goods Info -`}</Typography>
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={10}
                                                              >
                                                                <SelectEnumFieldSmall
                                                                  name={`legsInitValue.${legIndex}.loadingList.${index}.classification`}
                                                                  readOnly={
                                                                    readOnlyView ||
                                                                    isOrderLinked
                                                                  }
                                                                  enumObject={
                                                                    DangerousGoodsClassification
                                                                  }
                                                                  translationPath={
                                                                    'enums.dangerousGoodsClass'
                                                                  }
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={2}
                                                              container
                                                              direction="row"
                                                              alignItems="center"
                                                            >
                                                              <Grid item xs={2}>
                                                                <Typography
                                                                  style={{
                                                                    fontSize: 12,
                                                                  }}
                                                                  color="textPrimary"
                                                                >{`UN-`}</Typography>
                                                              </Grid>
                                                              <Grid
                                                                item
                                                                xs={10}
                                                              >
                                                                <FastTextFieldUnNumber
                                                                  noLabel
                                                                  size="small"
                                                                  smallFontSize
                                                                  name={`legsInitValue.${legIndex}.loadingList.${index}.unNumber`}
                                                                  className={
                                                                    classes.numberFields
                                                                  }
                                                                  type="number"
                                                                  placeholder="4 digit number"
                                                                  readOnly={
                                                                    readOnlyView ||
                                                                    isOrderLinked
                                                                  }
                                                                  smallPadding
                                                                  autoComplete="off"
                                                                  onInput={(
                                                                    e,
                                                                  ) => {
                                                                    (
                                                                      e.target as HTMLInputElement
                                                                    ).value =
                                                                      Math.max(
                                                                        0,
                                                                        parseInt(
                                                                          (
                                                                            e.target as HTMLInputElement
                                                                          )
                                                                            .value,
                                                                        ),
                                                                      )
                                                                        .toString()
                                                                        .slice(
                                                                          0,
                                                                          4,
                                                                        );
                                                                  }}
                                                                />
                                                              </Grid>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={2}
                                                              container
                                                              direction="row"
                                                            >
                                                              <Grid
                                                                item
                                                                xs={10}
                                                              >
                                                                <FastCheckbox
                                                                  name={`legsInitValue.${legIndex}.loadingList.${index}.isLimitedQty`}
                                                                  label={t(
                                                                    'attributes.limitedQty',
                                                                  )}
                                                                  readOnly={
                                                                    readOnlyView ||
                                                                    isOrderLinked
                                                                  }
                                                                  size="small"
                                                                />
                                                              </Grid>
                                                              <Grid item xs={2}>
                                                                <Tooltip
                                                                  title={`${t(
                                                                    'attributes.tooltipLimitedQtyInfo',
                                                                  )}`}
                                                                >
                                                                  <IconButton
                                                                    className={
                                                                      classes.deleteButton
                                                                    }
                                                                    onClick={() =>
                                                                      setOpenLimitedQtyInfoDialog(
                                                                        true,
                                                                      )
                                                                    }
                                                                  >
                                                                    <InfoOutlined />
                                                                  </IconButton>
                                                                </Tooltip>
                                                              </Grid>
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={3}
                                                            ></Grid>
                                                          </Box>
                                                        )}
                                                      </Grid>
                                                    </ListItem>
                                                  );
                                                },
                                              )}
                                            </List>
                                          </Grid>
                                        </>
                                      ) : (
                                        <Typography>
                                          {t('attributes.loadingListNotFound')}
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                />
                              </Grid>
                              <Divider
                                style={{
                                  backgroundColor: 'green',
                                  marginTop: 5,
                                }}
                              />
                            </>
                          ))}
                        </Grid>
                      )}
                    />
                    <Grid
                      item
                      container
                      justifyContent="center"
                      style={{ marginTop: 5 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: 10 }}
                        startIcon={<CloudDownloadIcon />}
                        disabled={lockLoading}
                        onClick={() => {
                          getLoadingList(routeId, routeName, httpClient);
                          setViewLoadingListItems(false);

                          if (!readOnlyView) {
                            updateLockMutation({
                              variables: {
                                routeId: routeId,
                                lock: 0,
                                reload: 1,
                              },
                            }).then(() => {
                              setViewLoadingListItems(false);
                            });
                          } else {
                            setViewLoadingListItems(false);
                          }
                        }}
                      >
                        {t('actions.download')}
                      </Button>
                      {!readOnlyView && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ marginRight: 10 }}
                          disabled={lockLoading}
                        >
                          {t('actions.update')}
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        disabled={lockLoading}
                        onClick={() => {
                          setViewLoadingListItems(false);
                          if (!readOnlyView) {
                            updateLockMutation({
                              variables: {
                                routeId: routeId,
                                lock: 0,
                                reload: 1,
                              },
                            }).then(() => setViewLoadingListItems(false));
                          } else {
                            setViewLoadingListItems(false);
                          }
                        }}
                      >
                        {t('button.close')}
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Modal>
      )}
    </>
  );
}
