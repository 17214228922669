import {
  Button,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { FieldArray, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CommonTextField } from '../components/form/CommonTextField';
import {
  CostAllocationItemType,
  Department,
  FuelType,
  Maybe,
  TourRouteType,
} from '../generated/graphql';
import { newTourRoute } from '../lib/formHelpers/route';
import { normalizeRouteIdForTourRoute } from '../lib/normalizeRouteId';
import { getUserName } from '../lib/useRoles';
import {
  hasDuplicates,
  tourValidationSchema,
} from '../lib/validationSchema/tour';
import { AutoReset } from '../utils/AutoReset';
import { AggregatesLoadingList } from './AggregatesLoadingList';
import { CenteredBox } from './CenteredBox';
import { DispatchRoute } from './DispatchRoute';
import { FileItem } from './FileListComponent';
import { AccordionContainer } from './form/AccordionContainer';
import { AgreementFields } from './form/AgreementFields';
import { CheckboxField } from './form/CheckboxField';
import { DriverFields } from './form/DriverFields';
import { FilesField } from './form/FilesField';
import { FormikTabsContainer } from './form/FormikTabsContainer';
import { HideableFormFields } from './form/HideableFormFields';
import { SelectGroupTag } from './form/SelectGroupTag';
import { SelectResponsiblePerson } from './form/SelectResponsiblePerson';
import { SubcontractorFields } from './form/SubcontractorFields';
import { SupressEnterForm } from './form/SupressEnterForm';
import { RouteControlButtons } from './form/tourFields/RouteControlButtons';
import { TourRouteCostAllocationFields } from './form/tourFields/TourRouteCostAllocationFields';
import { TourRouteDatesFields } from './form/tourFields/TourRouteDatesFields';
import { TourRouteLegsFields } from './form/tourFields/TourRouteLegsFields';
import { TourRouteFTLFields } from './form/TourRouteFTLFields';
import { LoadingListItemInterface } from './LoadingList';
import { TourTemplateRoutePriceBox } from './TourTemplateRoutePriceBox';

export type TourTemplateFormInput = {
  id: string;
  name: string;
  subcontractorId: number;
  dispatcherId?: Maybe<number>;
  price?: Maybe<number>;
  currency: string;
  startDate: string;
  originalStartDate?: Maybe<string>;
  note: string;
  driverName: string;
  driverPhoneNumber: string;
  licensePlate: string;
  responsiblePerson?: Maybe<string>;
  groupingTag?: Maybe<string>;
  isPriceEquallyDivided: boolean;
  isNewVersion: boolean;
  routes: {
    id?: number;
    capacity?: Maybe<number>;
    kilometer?: Maybe<number>;
    weight?: Maybe<number>;
    endDate?: Maybe<string>;
    startDate: string;
    startExceptionDate?: Maybe<string>;
    endExceptionDate?: Maybe<string>;
    hasExceptionDates: boolean;
    exceptionDates?: string[];
    routeDateType?: Maybe<string>;
    tourRouteType: TourRouteType;
    routeId: string;
    files: FileItem[];
    routeDriverName?: Maybe<string>;
    routeDriverPhoneNumber?: Maybe<string>;
    routeCarRegNumber?: Maybe<string>;
    routeTrailerRegNumber?: Maybe<string>;
    isCopyDriverPlateInfo: boolean;
    ftl?: {
      cdc: string;
      cmr: string;
      price: number;
      currency: string;
      customerId?: Maybe<number>;
      customerNumber: string;
      customerReference: string;
      invoiceNote: string;
    };
    price?: Maybe<number>;
    note: string;
    externalNote: string;
    days: {
      mon: boolean;
      tue: boolean;
      wed: boolean;
      thu: boolean;
      fri: boolean;
      sat: boolean;
      sun: boolean;
    };
    costAllocations: {
      id?: number;
      departmentId: string;
      type: CostAllocationItemType;
      includedKpi: boolean;
      includedPrice: boolean;
      bringCost: boolean;
      additionalCost: boolean;
      additionalDiscount: boolean;
      percentage?: number;
      cost: number;
      comment: string;
    }[];
    legs: {
      id?: number;
      fuel: FuelType;
      key?: string;
      gateNumber?: Maybe<string>;
      routeDriverName?: Maybe<string>;
      routeDriverPhoneNumber?: Maybe<string>;
      carRegistrationNumber?: string;
      trailerRegistrationNumber?: string;
      locationId?: number;
      position: number;
      load: boolean;
      unload: boolean;
      arrivalTime?: Maybe<string>;
      departureTime?: Maybe<string>;
      note?: string;
      transportationDateOffset: number;
      daysDiff?: number;
      loadingListItems: LoadingListItemInterface[];
    }[];
  }[];
};

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '100%',
  },
  categoryHeader: {
    width: '80%',
    margin: theme.spacing(2, 'auto'),
  },
  subResources: {
    margin: theme.spacing(1, 'auto'),
  },
  button: {
    margin: theme.spacing(2, 'auto'),
  },
  formContent: {
    margin: theme.spacing(1, 0, 2, 1),
    padding: theme.spacing(0, 2, 2, 2),
  },
  formHeader: {
    margin: theme.spacing(1, 2),
    padding: theme.spacing(1, 2),
  },
  routeControlButtons: {
    position: 'absolute',
  },
  routeCostAllocItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95%',
  },
  routeFormField: {
    width: '100%',
    display: 'flex',
    '& > *': {
      padding: theme.spacing(1, 1, 1, 0),
    },
  },
  legField: {
    width: '100%',
    fontSize: '10px',
    marginRight: 20,
    marginTop: 18,
    marginBottom: 18,
  },
}));

interface TourTemplateFormProps {
  initialValues: Omit<TourTemplateFormInput, 'subcontractorId'> & {
    subcontractorId?: number;
  };
  onSubmit: (
    values: Omit<TourTemplateFormInput, 'subcontractorId'> & {
      subcontractorId?: number;
    },
  ) => void;
  submitButtonLabel: string;
  readOnly?: boolean;
  departments: Pick<Department, 'id' | 'name'>[];
  terminal?: boolean;
}

export function TourTemplateForm(props: TourTemplateFormProps) {
  const {
    initialValues,
    onSubmit,
    submitButtonLabel,
    departments,
    readOnly = false,
    terminal = false,
  } = props;
  const user = getUserName();
  const collapseSections = useCallback(() => {
    if (localStorage.getItem('collapseTruckSections_' + user) === 'true') {
      return true;
    } else {
      return false;
    }
  }, [localStorage.getItem('collapseTruckSections_' + user)]);
  const [aggreementOpen, setAggreementOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [noteOpen, setNoteOpen] = useState<boolean>(!collapseSections());
  const [subcontractorOpen, setSubcontractorOpen] = useState<boolean>(
    !collapseSections(),
  );
  const [identity, setIdentity] = useState<boolean>(!collapseSections());
  const [driverOpen, setDriverOpen] = useState<boolean>(!collapseSections());

  const [costAllocationOpen, setCostAllocationOpen] = useState<boolean>(
    initialValues.price != null && initialValues.price > 0,
  );
  const [dispatchRouteOpen, setDispatchRouteOpen] = useState<boolean>(false);
  const trafficReadOnly = terminal || readOnly;
  const [filesOpen, setFilesOpen] = useState<boolean>(true);
  const [ftlOpen, setFTLOpen] = useState<boolean>(false);
  const [driverInfoOpen, setDriverInfoOpen] = useState<boolean>(true);
  const [routeNoteOpen, setRouteNoteOpen] = useState<boolean>(true);

  const [isCopiedRoute, setCopiedRoute] = useState<boolean>(false);
  const [isCopyActive, setCopyActive] = useState<boolean>(false);
  const [showDuplicateErr, setShowDuplicateErr] = useState<boolean>(false);
  const [totalRoutes, setTotalRoutes] = useState<number>(
    initialValues.routes.length,
  );
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();

  const location = useLocation<{
    copyRoute: boolean;
    enableEditMode: boolean;
    otherTour: boolean;
    routeId?: number;
    timestamp: number;
  }>();

  useEffect(() => {
    if (
      location.state &&
      location.state.copyRoute &&
      location.state.otherTour &&
      location.state.routeId
    ) {
      //add route to initvalue
      setCopiedRoute(true);
    }

    if (
      location.state &&
      location.state.copyRoute &&
      location.state.enableEditMode
    ) {
      //copy to current tour // for setTab to 1st in case of close edit mode
      setCopyActive(true);
    }
  }, [
    location.state?.copyRoute,
    location.state?.otherTour,
    location.state?.timestamp,
    location.state?.routeId,
  ]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        // enableReinitialize={true}
        validateOnBlur
        validateOnChange={false}
        validate={(value) => {
          let duplicateErr = 'NO';
          if (
            value.routes &&
            value.routes.length > 1 &&
            hasDuplicates(value.routes.map((r) => r.routeId))
          ) {
            setShowDuplicateErr(true);
            duplicateErr = 'YES';
          } else {
            setShowDuplicateErr(false);
          }
          try {
            validateYupSchema(
              { ...value, duplicateErr },
              tourValidationSchema(t),
              true,
              value,
            );
          } catch (err) {
            const errors = yupToFormErrors(err); //for rendering validation errors
            return errors;
          }
          return {};
        }}
        onSubmit={normalizeRouteIdForTourRoute(onSubmit)}
      >
        {(props) => (
          <SupressEnterForm
            onSubmit={props.handleSubmit}
            className={classes.root}
          >
            <Paper className={classes.formContent}>
              <Grid container>
                <AutoReset readOnly={readOnly} />
                <Grid item xs={6}>
                  <AccordionContainer
                    open={aggreementOpen}
                    errors={[props.errors.price]}
                    setOpen={setAggreementOpen}
                    accordionTitle={t('attributeGrouping.agreement')}
                  >
                    <AgreementFields readOnly={readOnly} terminal={terminal} />

                    {props.values.price && props.values.price > 0 ? (
                      <CenteredBox>
                        <CheckboxField
                          name={'isPriceEquallyDivided'}
                          label={t('attributes.dividePriceEqually')}
                          readOnly={readOnly}
                        />
                      </CenteredBox>
                    ) : null}
                  </AccordionContainer>
                  <AccordionContainer
                    open={noteOpen}
                    setOpen={setNoteOpen}
                    accordionTitle={`${t('resource.tour.capitalized')} ${t(
                      'attributeGrouping.note',
                    )}`}
                  >
                    <CommonTextField
                      className={classes.field}
                      name="note"
                      multiline
                      fullWidth
                      rows={4}
                      readOnly={readOnly}
                      noLabel
                    />
                  </AccordionContainer>
                </Grid>
                <Grid item xs={6}>
                  <AccordionContainer
                    open={subcontractorOpen}
                    setOpen={setSubcontractorOpen}
                    errors={[props.errors.subcontractorId]}
                    accordionTitle={t('resource.subcontractor.capitalized')}
                  >
                    <SubcontractorFields readOnly={readOnly} />
                  </AccordionContainer>
                  <AccordionContainer
                    open={identity}
                    setOpen={setIdentity}
                    accordionTitle={t('resource.identity.capitalized')}
                  >
                    <CenteredBox>
                      <SelectResponsiblePerson
                        readOnly={readOnly}
                        name="responsiblePerson"
                      />
                    </CenteredBox>
                    <CenteredBox>
                      <SelectGroupTag readOnly={readOnly} name="groupingTag" />
                    </CenteredBox>
                  </AccordionContainer>
                  {submitButtonLabel.includes('Update') &&
                  props.values.isNewVersion === false ? (
                    <AccordionContainer
                      open={driverOpen}
                      setOpen={setDriverOpen}
                      accordionTitle={`${t('attributes.driverRead')}`}
                    >
                      <DriverFields classes={classes} trafficReadOnly={true} />
                    </AccordionContainer>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container direction="row">
                {showDuplicateErr && (
                  <Grid item xs={12}>
                    <div style={{ color: '#d32f2f', textAlign: 'left' }}>
                      {`${t('validation.duplicateRouteName')}`}
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    style={{ marginTop: 5, fontSize: 17, textAlign: 'left' }}
                  >{`Routes`}</Typography>
                  <Box>
                    <FieldArray
                      name="routes"
                      render={(arrayHelpers) => (
                        <>
                          <FormikTabsContainer
                            title={(index: number) => {
                              return `${t('resource.route.capitalized')} - ${
                                index + 1
                              } ${
                                props.getFieldProps(`routes.${index}.routeId`)
                                  .value
                              }`;
                            }}
                            addAction={() => {
                              arrayHelpers.push(newTourRoute(departments));
                              setTotalRoutes(totalRoutes + 1);

                              const allRoutes =
                                props.getFieldProps(`routes`).value;

                              const isDividePrice = props.getFieldProps(
                                `isPriceEquallyDivided`,
                              ).value;
                              if (
                                isDividePrice &&
                                allRoutes &&
                                allRoutes.length > 0
                              ) {
                                const totalRoutes = allRoutes.length + 1;
                                const actualPrice =
                                  props.getFieldProps(`price`).value;
                                const calculatedPrice =
                                  actualPrice / totalRoutes;
                                const calculatedPercentage = 100 / totalRoutes;

                                for (let i = 0; i < totalRoutes; i++) {
                                  props.setFieldValue(
                                    `routes.${i}.price`,
                                    Math.round(calculatedPrice * 100) / 100,
                                  );
                                  props.setFieldValue(
                                    `routes.${i}.pricePercentage`,
                                    Math.round(calculatedPercentage * 100) /
                                      100,
                                  );
                                }
                              }
                            }}
                            addButtonTitle={t('button.add', {
                              item: t('route'),
                            })}
                            readOnly={readOnly}
                            list={props.values.routes}
                            onMove={(fromTab, toTab) => {
                              arrayHelpers.swap(fromTab, toTab);
                            }}
                          >
                            {({ item, index }, setTab) => {
                              if (isCopiedRoute) {
                                //to set last tab for copy other tour
                                setTab(initialValues.routes.length - 1);
                                setCopiedRoute(false);
                              } else if (readOnly && isCopyActive) {
                                //to set first tab in case of close edit mode
                                setTab(0);
                                setCopyActive(false);
                              }

                              return (
                                <>
                                  <RouteControlButtons
                                    terminal={terminal}
                                    readOnly={readOnly}
                                    index={index}
                                    arrayHelpers={arrayHelpers}
                                    setTab={setTab}
                                    setFilesOpen={setFilesOpen}
                                    filesOpen={filesOpen}
                                    setFTLOpen={setFTLOpen}
                                    ftlOpen={ftlOpen}
                                    setDriverInfoOpen={setDriverInfoOpen}
                                    driverInfoOpen={driverInfoOpen}
                                    costAllocationOpen={costAllocationOpen}
                                    setCostAllocationOpen={
                                      setCostAllocationOpen
                                    }
                                    routeNoteOpen={routeNoteOpen}
                                    setRouteNoteOpen={setRouteNoteOpen}
                                    dispatchRouteOpen={dispatchRouteOpen}
                                    setDispatchRouteOpen={setDispatchRouteOpen}
                                    clickedRouteData={item}
                                    totalRoutes={totalRoutes}
                                    setTotalRoutes={setTotalRoutes}
                                  />
                                  <Paper
                                    style={{
                                      backgroundColor: '#FAFAFA',
                                      padding: theme.spacing(1),
                                    }}
                                    elevation={0}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Box className={classes.routeFormField}>
                                        <CenteredBox>
                                          <CommonTextField
                                            name={`routes.${index}.routeId`}
                                            className={classes.field}
                                            readOnly={readOnly}
                                            size="small"
                                            style={{ paddingTop: 1 }}
                                          />
                                        </CenteredBox>
                                        {!terminal && (
                                          <TourTemplateRoutePriceBox
                                            readOnly={readOnly}
                                            index={index}
                                          />
                                        )}
                                      </Box>
                                      <Box className={classes.routeFormField}>
                                        <CommonTextField
                                          name={`routes.${index}.capacity`}
                                          type="number"
                                          readOnly={readOnly}
                                        />
                                        <CommonTextField
                                          name={`routes.${index}.kilometer`}
                                          type="number"
                                          readOnly={readOnly}
                                        />
                                        <CommonTextField
                                          name={`routes.${index}.weight`}
                                          labelText={'weightKg'}
                                          type="number"
                                          readOnly={readOnly}
                                        />
                                        {submitButtonLabel.includes('Update') &&
                                        item.id != undefined ? (
                                          <CommonTextField
                                            name={`routes.${index}.originalStartDate`}
                                            variant="outlined"
                                            readOnly
                                          />
                                        ) : null}
                                      </Box>
                                      <Box className={classes.routeFormField}>
                                        <TourRouteDatesFields
                                          key={`k${item.id}`}
                                          readOnly={readOnly}
                                          routeIndex={index}
                                          isFromUpdate={submitButtonLabel.includes(
                                            'Update',
                                          )}
                                          isNewRouteAdd={item.id == undefined}
                                        />
                                      </Box>
                                      <HideableFormFields
                                        title={t('actions.dispatchRoute')}
                                        onHideClicked={() =>
                                          setDispatchRouteOpen(false)
                                        }
                                        setOpen={setDispatchRouteOpen}
                                        show={dispatchRouteOpen}
                                      >
                                        <DispatchRoute routeId={item.id} />
                                      </HideableFormFields>
                                      <TourRouteCostAllocationFields
                                        show={costAllocationOpen}
                                        onHideClicked={() =>
                                          setCostAllocationOpen(false)
                                        }
                                        setOpen={setCostAllocationOpen}
                                        readOnly={readOnly}
                                        terminal={terminal}
                                        index={index}
                                        props={props}
                                        classes={classes}
                                        departments={departments}
                                      />
                                      <HideableFormFields
                                        title={`${t(
                                          'attributes.routeDriverInfo',
                                        )}`}
                                        onHideClicked={() =>
                                          setDriverInfoOpen(false)
                                        }
                                        setOpen={setDriverInfoOpen}
                                        show={driverInfoOpen}
                                      >
                                        <CenteredBox>
                                          <CheckboxField
                                            name={`routes.${index}.isCopyDriverPlateInfo`}
                                            label={t(
                                              'attributes.copyDriverPlateInfo',
                                            )}
                                            routeIndex={index}
                                            readOnly={readOnly}
                                          />
                                        </CenteredBox>
                                        {readOnly === false ? (
                                          <Box
                                            display="flex"
                                            alignItems="centre"
                                            justifyContent="space-between "
                                          >
                                            <CommonTextField
                                              name={`routes.${index}.routeCarRegNumber`}
                                              type="string"
                                              className={classes.legField}
                                              readOnly={trafficReadOnly}
                                            />

                                            <CommonTextField
                                              name={`routes.${index}.routeTrailerRegNumber`}
                                              type="string"
                                              className={classes.legField}
                                              readOnly={trafficReadOnly}
                                            />
                                            <CommonTextField
                                              name={`routes.${index}.routeDriverName`}
                                              className={classes.legField}
                                              readOnly={trafficReadOnly}
                                            />

                                            <CommonTextField
                                              name={`routes.${index}.routeDriverPhoneNumber`}
                                              className={classes.legField}
                                              readOnly={trafficReadOnly}
                                            />
                                          </Box>
                                        ) : null}
                                      </HideableFormFields>
                                      <HideableFormFields
                                        title={`${t(
                                          'resource.route.capitalized',
                                        )} ${t('attributes.note')}`}
                                        onHideClicked={() =>
                                          setRouteNoteOpen(false)
                                        }
                                        setOpen={setRouteNoteOpen}
                                        show={routeNoteOpen}
                                      >
                                        <CommonTextField
                                          name={`routes.${index}.note`}
                                          readOnly={readOnly}
                                          size="small"
                                          fullWidth={true}
                                        />
                                        <CommonTextField
                                          name={`routes.${index}.externalNote`}
                                          readOnly={readOnly}
                                          size="small"
                                          fullWidth={true}
                                          style={{ marginTop: 18 }}
                                        />
                                      </HideableFormFields>
                                      <HideableFormFields
                                        setOpen={setFTLOpen}
                                        show={ftlOpen}
                                        title={t(
                                          'resource.routeFtl.capitalized',
                                        )}
                                        onHideClicked={() => setFTLOpen(false)}
                                      >
                                        <TourRouteFTLFields
                                          index={index}
                                          readOnly={readOnly}
                                        />
                                      </HideableFormFields>
                                      <FilesField
                                        show={filesOpen}
                                        onUpdate={(files) => {
                                          props.setFieldValue(
                                            `routes.${index}.files`,
                                            files,
                                          );
                                        }}
                                        setOpen={setFilesOpen}
                                        onHideClicked={() =>
                                          setFilesOpen(false)
                                        }
                                        files={
                                          item.files
                                            ? item.files.filter((f) =>
                                                f.originalname.includes('.pdf'),
                                              )
                                            : []
                                        }
                                        readOnly={readOnly}
                                      />
                                      {item && item.legs && (
                                        <AggregatesLoadingList
                                          loadingListItems={item.legs.flatMap(
                                            (leg) => leg.loadingListItems,
                                          )}
                                          capacity={item.capacity}
                                        />
                                      )}
                                      <Grid item xs={12}>
                                        <Box className={classes.subResources}>
                                          <TourRouteLegsFields
                                            fieldsPath={`routes.${index}.legs`}
                                            routeIndex={index}
                                            readOnly={readOnly}
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </>
                              );
                            }}
                          </FormikTabsContainer>
                        </>
                      )}
                    />
                  </Box>
                </Grid>

                {!readOnly && (
                  <>
                    <Grid container justifyContent="flex-end">
                      <Button
                        className={classes.button}
                        type="submit"
                        color="primary"
                        variant="contained"
                      >
                        {submitButtonLabel}
                      </Button>
                    </Grid>
                    {submitButtonLabel.includes('Update') && (
                      <Grid container justifyContent="center">
                        <Typography
                          style={{
                            textAlign: 'center',
                            color: '#fa5252',
                            fontSize: 14,
                          }}
                        >
                          {`${t('attributes.tourTemplateWarn')}`}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Paper>
          </SupressEnterForm>
        )}
      </Formik>
    </>
  );
}
