import { useMsal } from '@azure/msal-react';
import { loginUser } from '../lib/azure_ad';
import { useTranslation } from 'react-i18next';
import { HelmetComponent } from '../components/HelmetComponent';

export function LoginPage() {
  const { instance } = useMsal();

  const { t } = useTranslation();

  return (
    <div>
      <HelmetComponent title={t('pages.login.login')} />
      <h5 className="card-title">{t('pages.login.intro')}</h5>
      <button
        onClick={() => {
          loginUser(instance);
        }}
        data-testid="login-title"
      >
        {t('pages.login.login')}
      </button>
    </div>
  );
}
