import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  CostAllocationItemType,
  GetDepartmentsQuery,
} from '../../generated/graphql';

import {
  calculateFromPercentage,
  calculatePercentage,
} from '../../lib/percentage';
import {
  useFormContext,
  Controller,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';
import { Radio, RadioGroup } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRoot: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      '& > *': {
        marginRight: theme.spacing(1),
      },
      '& .select-small': {
        width: 100,
      },
      '& .select': {
        width: 150,
      },
    },
  }),
);

export interface CostAllocationItemInterface {
  id?: number;
  departmentId: string;
  type: CostAllocationItemType;
  includedKpi: boolean;
  includedPrice: boolean;
  bringCost: boolean;
  additionalCost: boolean;
  additionalDiscount: boolean;
  percentage?: number;
  cost: number;
  comment: string;
}

interface CostAllocationItemProps {
  removeItem: (index: number) => void;
  readOnly?: boolean;
  itemIndex: number;
  departmentsData: GetDepartmentsQuery | undefined;
  agreedPrice: number;
  totalLength: number;
  key: string;
}

export function CostAllocationItemRHF(props: CostAllocationItemProps) {
  const {
    readOnly = false,
    removeItem,
    itemIndex: index,
    key,
    agreedPrice,
    totalLength,
    departmentsData,
  } = props;
  const { t } = useTranslation();
  const {
    trigger,
    control,
    setValue: setFieldValue,
  } = useFormContext<SporadicRouteFormInput>();
  const { errors } = useFormState<SporadicRouteFormInput>();

  const classes = useStyles();

  const [includedPrice, thisBringCost, thisAdditionalCost] = useWatch({
    control,
    name: [
      `costAllocation.items.${index}.includedPrice`,
      `costAllocation.items.${index}.bringCost`,
      `costAllocation.items.${index}.additionalCost`,
    ],
  });
  const thisCostType =
    includedPrice || thisBringCost ? 'bc' : thisAdditionalCost ? 'ac' : 'ad';

  return (
    <Box className={classes.formRoot} key={key}>
      <Controller
        name={`costAllocation.items.${index}.departmentId`}
        control={control}
        render={({ field }) => (
          <FormControl {...field} variant="outlined" className="select-small">
            <InputLabel id="department-label" shrink>
              {t('attributes.department')}
            </InputLabel>
            <Select
              {...field}
              labelId="department-label"
              label={t('attributes.department')}
              id="department"
              readOnly={readOnly}
            >
              {departmentsData?.departments.map((dep) => (
                <MenuItem key={dep.id} value={dep.id}>
                  {dep.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name={`costAllocation.items.${index}.type`}
        control={control}
        render={({ field }) => (
          <FormControl {...field} variant="outlined" className="select">
            <InputLabel id="type-label">{t('attributes.type')}</InputLabel>
            <Select
              {...field}
              labelId="type-label"
              label={t('attributes.type')}
              id="type"
              readOnly={readOnly}
            >
              {Object.values(CostAllocationItemType).map((val) => (
                <MenuItem key={val} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <Box display="flex" flexDirection="column">
        <Controller
          name={`costAllocation.items.${index}.includedKpi`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              checked={field.value}
              control={
                <Checkbox
                  color="primary"
                  disabled={readOnly}
                  readOnly={readOnly}
                />
              }
              label={
                <Typography style={{ fontSize: 14 }}>
                  {t('attributes.includedKpi')}
                </Typography>
              }
            />
          )}
        />
        <Controller
          name={`costAllocation.items.${index}.includedPrice`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  readOnly={readOnly}
                  disabled={readOnly}
                  onChange={(
                    _event: React.ChangeEvent<unknown>,
                    checked: boolean,
                  ) => {
                    setFieldValue(
                      `costAllocation.items.${index}.includedPrice`,
                      !!checked,
                    );

                    if (checked) {
                      setFieldValue(
                        `costAllocation.items.${index}.bringCost`,
                        true,
                      );
                      setFieldValue(
                        `costAllocation.items.${index}.percentage`,
                        0,
                      );
                      setFieldValue(`costAllocation.items.${index}.cost`, 0);
                    }
                    trigger('costAllocation.items');
                  }}
                  color="primary"
                />
              }
              label={
                <Typography style={{ fontSize: 14 }}>
                  {t('attributes.includedPrice')}
                </Typography>
              }
            />
          )}
        />
      </Box>

      {includedPrice === true ? (
        <Controller
          name={`costAllocation.items.${index}.percentage`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              error={
                errors &&
                Boolean(
                  errors.costAllocation?.items &&
                    errors.costAllocation?.items[index]?.percentage,
                )
              }
              helperText={
                Boolean(
                  errors &&
                    errors.costAllocation?.items &&
                    errors.costAllocation?.items[index]?.percentage,
                ) &&
                Boolean(
                  errors &&
                    errors.costAllocation?.items &&
                    errors.costAllocation?.items[index]?.percentage?.message,
                )
              }
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                const newValue = parseInt(event.target.value, 10);
                setFieldValue(
                  `costAllocation.items.${index}.cost`,
                  calculateFromPercentage(agreedPrice, newValue),
                );
                setFieldValue(
                  `costAllocation.items.${index}.percentage`,
                  newValue,
                );
              }}
              style={{ maxWidth: 110 }}
              inputProps={{
                readOnly,
              }}
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              disabled={!includedPrice}
              id="percentage"
              label={t('attributes.percentage')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={() => {
                trigger('costAllocation.items');
              }}
            />
          )}
        />
      ) : (
        <Box style={{ paddingLeft: 4 }}>
          <RadioGroup
            row={false}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={thisCostType}
            onChange={(event) => {
              setFieldValue(
                `costAllocation.items.${index}.bringCost`,
                event.target.value === 'bc',
              );
              setFieldValue(
                `costAllocation.items.${index}.additionalCost`,
                event.target.value === 'ac',
              );
              setFieldValue(
                `costAllocation.items.${index}.additionalDiscount`,
                event.target.value === 'ad',
              );
              trigger('costAllocation.items');
            }}
          >
            <FormControlLabel
              value="bc"
              control={
                <Radio
                  value="bc"
                  name="radio1"
                  size="small"
                  disabled={readOnly}
                  sx={{
                    '&.Mui-checked': {
                      color: '#69bc48',
                    },
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: 14 }}>
                  {t('attributes.bringCost')}
                </Typography>
              }
            />
            <FormControlLabel
              value="ac"
              control={
                <Radio
                  value="ac"
                  name="radio2"
                  size="small"
                  disabled={readOnly}
                  sx={{
                    '&.Mui-checked': {
                      color: '#69bc48',
                    },
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: 14 }}>
                  {t('attributes.additionalCost')}
                </Typography>
              }
            />
            <FormControlLabel
              value="ad"
              control={
                <Radio
                  value="ad"
                  name="radio3"
                  size="small"
                  disabled={readOnly}
                  sx={{
                    '&.Mui-checked': {
                      color: '#69bc48',
                    },
                  }}
                />
              }
              label={
                <Typography style={{ fontSize: 14 }}>
                  {t('attributes.additionalDiscount')}
                </Typography>
              }
            />
          </RadioGroup>
        </Box>
      )}

      <Controller
        name={`costAllocation.items.${index}.cost`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            error={
              errors &&
              Boolean(
                errors.costAllocation?.items &&
                  errors.costAllocation?.items[index]?.cost,
              )
            }
            helperText={
              Boolean(
                errors &&
                  errors.costAllocation?.items &&
                  errors.costAllocation?.items[index]?.cost,
              ) &&
              Boolean(
                errors &&
                  errors.costAllocation?.items &&
                  errors.costAllocation?.items[index]?.cost?.message,
              )
            }
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              const newValue = parseInt(event.target.value, 10);
              setFieldValue(`costAllocation.items.${index}.cost`, newValue);
              setFieldValue(
                `costAllocation.items.${index}.percentage`,
                calculatePercentage(agreedPrice, newValue),
              );
            }}
            style={
              thisCostType === 'ad'
                ? {
                    maxWidth: 110,
                    backgroundColor: '#ffcfd2',
                  }
                : {
                    maxWidth: 110,
                    backgroundColor: '#dcfcd2',
                  }
            }
            inputProps={{
              readOnly,
            }}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            id="cost"
            label={
              thisCostType === 'ad'
                ? t('attributes.discount')
                : t('attributes.cost')
            }
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onBlur={() => {
              trigger('costAllocation.items');
            }}
          />
        )}
      />

      <Controller
        name={`costAllocation.items.${index}.comment`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            variant="outlined"
            id="comment"
            inputProps={{
              readOnly,
            }}
            error={
              errors &&
              Boolean(
                errors.costAllocation?.items &&
                  errors.costAllocation?.items[index]?.comment,
              )
            }
            helperText={
              Boolean(
                errors &&
                  errors.costAllocation?.items &&
                  errors.costAllocation?.items[index]?.comment,
              ) &&
              Boolean(
                errors &&
                  errors.costAllocation?.items &&
                  errors.costAllocation?.items[index]?.comment?.message,
              )
            }
            label={t('attributes.comment')}
            multiline
            rows={3}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />

      {!readOnly && (
        <IconButton
          edge="end"
          aria-label="delete"
          disabled={totalLength < 2 && agreedPrice !== 0}
          onClick={() => removeItem(index)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  );
}
