import { TextField } from '@mui/material';
import debounce from '@mui/utils/debounce';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, isMatch } from 'date-fns';
import { DATE_FORMAT } from '../lib/date_time';

interface TftSelectDateProps {
  onDateSelected: (dateString: string) => void;
  label?: string;
  disableFutureDate?: boolean;
}

export function TftSelectDate(props: TftSelectDateProps) {
  const { onDateSelected, label, disableFutureDate = false } = props;
  const updateUpwards = debounce((date: string) => {
    onDateSelected(date);
  }, 500);
  const onChangeDate = (values: string) => {
    if (isMatch(values, DATE_FORMAT)) {
      updateUpwards(values);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        value={null}
        label={label ?? undefined}
        onChange={(newValue) => {
          if (newValue) {
            const formatDate = format(new Date(newValue), DATE_FORMAT);
            onChangeDate(formatDate);
          }
        }}
        disableFuture={disableFutureDate}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: 140 }}
            id="date"
            variant="standard"
            inputProps={{ ...params.inputProps, readOnly: true }}
            size="small"
            fullWidth={false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        inputFormat="yyyy-MM-dd"
      />
    </LocalizationProvider>
  );
}
