import { Maybe } from '../generated/graphql';

export function calculatePercentageOfRoutePrice(
  tourPrice: number,
  routePrice: number,
): number {
  if (tourPrice == 0 || routePrice == 0) return 0;
  return Math.round(((routePrice * 100) / tourPrice) * 100) / 100;
}
export function calculatePercentage(
  total: Maybe<number> | undefined,
  part: number,
): number {
  if (total == null || total == 0) {
    return 0;
  }

  return parseFloat(Math.min((part / total) * 100, 100).toFixed(2));
}

export function calculateFromPercentage(
  total: Maybe<number> | undefined,
  percentage: number,
): number {
  if (total == null || total == 0) {
    return 0;
  }
  return parseFloat((total * (percentage / 100)).toFixed(2));
}
