import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Box, Modal, Typography } from '@mui/material';
import {
  FieldArray,
  Form,
  Formik,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  EditLocationDeviationInput,
  GetDeviationToLocationForDeviationIdQuery,
  Reason,
  useGetDeviationToLocationForDeviationIdLazyQuery,
} from '../../generated/graphql';
import * as yup from 'yup';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import { SelectCardLocations } from '../form/SelectCardLocations';
import { SelectLocation } from '../form/SelectLocation';
import { FastTextFieldPackage } from '../form/FastTextFieldPackage';
import { FastTextFieldPallets } from '../form/FastTextFieldPallets';
import { FastTextFieldPalletSpace } from '../form/FastTextFieldPalletSpace';
import { FastTextFieldWeight } from '../form/FastTextFieldWeight';
import { FastTextFieldNote } from '../form/FastTextFieldNote';
import { SelectEnumFieldSmall } from '../form/SelectEnumFieldSmall';
import { useEffect } from 'react';
import { usePrevious } from '../../utils/UsePrevious';
import _ from 'lodash';
import { CommonTextField } from '../form/CommonTextField';
import { CheckboxField } from '../form/CheckboxField';
import { CheckboxIconField } from '../form/CheckboxIconField';
import DangerIcon from '@mui/icons-material/Report';

const style = {
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '68vw',
  height: '88vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll' as const,
};

interface RemainingGoodsModalProps {
  handleClose: () => void;
  open: boolean;
  initialValue: EditLocationDeviationInput;
  onDeleteAction: (deviationId: number) => void;
  terminalName: string;
  handleSubmit: (data: EditLocationDeviationInput) => void;
}

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    toLocationId: yup
      .number()
      .required(t('validation.isRequired', { name: t('attributes.terminal') }))
      .positive(t('validation.isRequired', { name: t('attributes.terminal') })),
    customerDeviationListItemsInput: yup
      .array(
        yup.object().shape({
          locationItemNote: yup.string().optional(),
          reason: yup.string().required().oneOf(Object.keys(Reason)),
          otherReason: yup.string().when('reason', {
            is: 'Other',
            then: yup.string().required(
              t('validation.isRequired', {
                name: t('attributes.customer'),
              }),
            ),
          }),
          locationId: yup.number().when('isNewCustomer', {
            is: false,
            then: yup
              .number()
              .min(
                1,
                t('validation.isRequired', {
                  name: t('attributes.location'),
                }),
              )
              .required(
                t('validation.isRequired', {
                  name: t('attributes.location'),
                }),
              ),
          }),
          newCustomer: yup.string().when('isNewCustomer', {
            is: true,
            then: yup.string().required(
              t('validation.isRequired', {
                name: t('attributes.location'),
              }),
            ),
          }),
          packages: yup
            .number()
            .nullable(true)
            .integer(
              t('validation.noDecimals', { name: t('attributes.packages') }),
            )
            .positive(
              t('validation.fieldPositive', {
                name: t('attributes.packages'),
              }),
            ),
          weight: yup
            .number()
            .nullable(true)
            .positive(
              t('validation.fieldPositive', { name: t('attributes.weight') }),
            ),
          pallets: yup
            .number()
            .integer(
              t('validation.noDecimals', {
                name: t('attributes.pallets'),
                len: '0',
              }),
            )
            .nullable()
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.pallets'),
                len: '0',
              }),
            ),
          palletSpace: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(
              0,
              t('validation.minNumber', {
                name: t('attributes.palletSpace'),
                len: '0',
              }),
            )
            .typeError(t('validation.required'))
            .required(t('validation.required')),
        }),
      )
      .min(1, t('validation.atLeastItem'))
      .required(t('validation.atLeastItem')),
  });

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    width: '100%',
    '& > *': {
      width: '150px',
    },
  },
  halfRow: {
    display: 'flex',
    width: '100%',
    marginBottom: 26,
  },
  center: {
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 1),
    },
  },
  root: {
    margin: 0,
    padding: 0,
    '& > .MuiGrid-item': {
      padding: 0,
    },
  },
  noPadding: {
    padding: '0 !important',
  },
  noPaddingListRow: {
    paddingLeft: '0 !important',
    width: '100%',
    paddingBottom: 4,
  },
  deleteButton: {
    padding: '0 !important',
  },
  numberFields: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
  selecLocationField: {
    width: '99%',
    fontSize: '10px',
    margin: 0,
    padding: 0,
  },
  field: {
    width: '96%',
    fontSize: '10px',
    marginLeft: '10px',
    marginRight: 0,
  },
}));

export function EditRemainingGoodsModal(props: RemainingGoodsModalProps) {
  const {
    handleClose,
    open,
    initialValue,
    terminalName,
    handleSubmit,
    onDeleteAction,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  if (!initialValue || !initialValue.id) {
    return <div>Error: deviation values are null</div>;
  }
  const [getDeviationToLocationForDeviationIdLazyQuery, { data, loading }] =
    useGetDeviationToLocationForDeviationIdLazyQuery({
      fetchPolicy: 'no-cache',
    });
  useEffect(() => {
    getDeviationToLocationForDeviationIdLazyQuery({
      variables: {
        deviationId: initialValue.id,
      },
    });
  }, []);
  const deviationToLocationForDeviationId:
    | GetDeviationToLocationForDeviationIdQuery['deviationToLocationForDeviationId']
    | undefined = data?.deviationToLocationForDeviationId;
  const previousDeviationToLocationForDeviationId:
    | GetDeviationToLocationForDeviationIdQuery['deviationToLocationForDeviationId']
    | undefined = usePrevious(deviationToLocationForDeviationId);
  useEffect(() => {
    if (
      deviationToLocationForDeviationId &&
      deviationToLocationForDeviationId.length > 0 &&
      !_.isEqual(
        deviationToLocationForDeviationId,
        previousDeviationToLocationForDeviationId,
      )
    ) {
      deviationToLocationForDeviationId.forEach((customer) => {
        initialValue.customerDeviationListItemsInput.push({
          id: customer.id,
          deviationId: customer.deviationId,
          locationId: customer.locationId,
          locationItemNote: customer.locationItemNote,
          newCustomer: customer.newCustomer ?? '',
          isNewCustomer: customer.newCustomer ? true : false,
          otherReason: customer.otherReason,
          packages: customer.packages,
          pallets: customer.pallets,
          palletSpace: customer.palletSpace,
          reason: customer.reason,
          weight: customer.weight,
          isDangerous: customer.isDangerous,
        });
      });
    }
  }, [deviationToLocationForDeviationId]);

  const afterValidation = (values: EditLocationDeviationInput) => {
    const validatedInput = values as EditLocationDeviationInput;
    handleSubmit(validatedInput);
    handleClose();
  };

  if (loading) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
    >
      <Box sx={style}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{t('attributes.remainingGoods')}</Typography>
          <Typography style={{ fontSize: 16 }}>{`${terminalName}`}</Typography>
        </Box>
        <Formik
          initialValues={initialValue}
          onSubmit={afterValidation}
          validate={(value) => {
            try {
              validateYupSchema(value, validationSchema(t), true, value);
            } catch (err) {
              const errors = yupToFormErrors<{ error: string }>(err);
              return errors;
            }
            return {};
          }}
        >
          {(props) => (
            <Form>
              <Box m={1} p={2}>
                <FieldArray
                  name={`customerDeviationListItemsInput`}
                  render={(arrayHelpers) => (
                    <Grid
                      item
                      xs={12}
                      spacing={2}
                      container
                      className={classes.root}
                    >
                      <Grid container xs={12} className={clsx(classes.halfRow)}>
                        <Grid item xs={6}>
                          {/* To Terminal */}
                          <SelectCardLocations
                            name="toLocationId"
                            fullWidth
                            size="small"
                            cardType="Terminal"
                            label={'To Terminal'}
                          />
                        </Grid>
                        {/* Add btn */}
                        <Grid item container xs={6} justifyContent="flex-end">
                          <Box alignItems="center">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => {
                                arrayHelpers.push({
                                  deviationId: initialValue.id,
                                  locationId: 0,
                                  packages: null,
                                  pallets: null,
                                  palletSpace: null,
                                  weight: null,
                                  locationItemNote: '',
                                  reason: undefined,
                                  otherReason: '',
                                  newCustomer: '',
                                  isNewCustomer: false,
                                  isDangerous: false,
                                });
                              }}
                            >
                              {t('button.add', { item: t('customer') })}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Lables */}
                      <Grid
                        xs={12}
                        item
                        container
                        className={classes.noPadding}
                        style={{ backgroundColor: '#e3e3e3' }}
                      >
                        <Grid item xs={5}>
                          <Typography variant="body2" color="textPrimary">
                            {t('attributes.customer')}
                          </Typography>
                        </Grid>

                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {t('attributes.packages')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {t('attributes.pallets')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {t('attributes.palletSpace')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography variant="body2" color="textPrimary">
                            {t('attributes.weight')}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography variant="body2" color="textPrimary">
                            {t('attributes.note')}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                      {/* list items */}
                      <Grid item xs={12}>
                        <List>
                          {props.values?.customerDeviationListItemsInput?.map(
                            (_customer, index) => {
                              return (
                                <ListItem
                                  divider
                                  key={index}
                                  className={classes.noPaddingListRow}
                                >
                                  <Grid
                                    xs={12}
                                    item
                                    container
                                    key={index}
                                    style={{ width: '100%' }}
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="flex-start"
                                      style={{
                                        width: '100%',
                                        marginBottom: 5,
                                      }}
                                    >
                                      <Grid item xs={4}>
                                        {props.values
                                          .customerDeviationListItemsInput[
                                          index
                                        ].isNewCustomer ? (
                                          <CommonTextField
                                            name={`customerDeviationListItemsInput.${index}.newCustomer`}
                                          />
                                        ) : (
                                          <SelectLocation
                                            key={`customerDeviationListItemsInput_${index}.locationId`}
                                            name={`customerDeviationListItemsInput.${index}.locationId`}
                                            className={
                                              classes.selecLocationField
                                            }
                                            size="small"
                                            smallFontSize
                                            noLabel
                                            smallPadding
                                            shortAddress={true}
                                          />
                                        )}
                                      </Grid>
                                      <Grid item xs={1}>
                                        <CheckboxField
                                          name={`customerDeviationListItemsInput.${index}.isNewCustomer`}
                                          label={t('attributes.newCustomer')}
                                          customerName={`customerDeviationListItemsInput.${index}`}
                                        />
                                      </Grid>

                                      <Grid item xs={1}>
                                        <FastTextFieldPackage
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          name={`customerDeviationListItemsInput.${index}.packages`}
                                          className={classes.numberFields}
                                          type="number"
                                          smallPadding
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <FastTextFieldPallets
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          name={`customerDeviationListItemsInput.${index}.pallets`}
                                          className={classes.numberFields}
                                          type="number"
                                          smallPadding
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <FastTextFieldPalletSpace
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          smallPadding
                                          name={`customerDeviationListItemsInput.${index}.palletSpace`}
                                          className={classes.numberFields}
                                          type="number"
                                        />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <FastTextFieldWeight
                                          noLabel
                                          size="small"
                                          smallFontSize
                                          smallPadding
                                          name={`customerDeviationListItemsInput.${index}.weight`}
                                          className={classes.numberFields}
                                          type="number"
                                        />
                                      </Grid>
                                      <Grid item xs={2}>
                                        <FastTextFieldNote
                                          noLabel
                                          size="small"
                                          multiline
                                          smallFontSize
                                          name={`customerDeviationListItemsInput.${index}.locationItemNote`}
                                          className={classes.field}
                                          smallPadding
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <>
                                          {index === 0 ? null : (
                                            <IconButton
                                              className={classes.deleteButton}
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          )}
                                        </>
                                      </Grid>
                                    </Box>
                                    <Grid container>
                                      {/* for reason */}
                                      <Grid
                                        item
                                        xs={1}
                                        justifyContent={'flex-end'}
                                        style={{ marginTop: 2 }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: 12,
                                            textAlign: 'center',
                                          }}
                                          color="textPrimary"
                                        >{`Reason -  `}</Typography>
                                      </Grid>
                                      <Grid item xs={5}>
                                        <SelectEnumFieldSmall
                                          name={`customerDeviationListItemsInput.${index}.reason`}
                                          enumObject={Reason}
                                          translationPath={
                                            'enums.remainingGoodsReasonClass'
                                          }
                                          reasonPath={`customerDeviationListItemsInput.${index}`}
                                        />
                                      </Grid>

                                      {props.values
                                        .customerDeviationListItemsInput[index]
                                        .reason === Reason.Other ? (
                                        <Grid item xs={5}>
                                          <CommonTextField
                                            name={`customerDeviationListItemsInput.${index}.otherReason`}
                                            smallPadding
                                            fullWidth
                                          />
                                        </Grid>
                                      ) : null}
                                      <Grid item xs={1}>
                                        <Tooltip
                                          title={`${t(
                                            'attributes.tooltipDangerousGoodsIcon',
                                          )}`}
                                        >
                                          <div>
                                            <CheckboxIconField
                                              name={`customerDeviationListItemsInput.${index}.isDangerous`}
                                              icon={
                                                <DangerIcon
                                                  style={{
                                                    color: 'grey',
                                                  }}
                                                />
                                              }
                                              checkedIcon={
                                                <DangerIcon
                                                  style={{
                                                    color: 'red',
                                                  }}
                                                />
                                              }
                                              size="small"
                                            />
                                          </div>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              );
                            },
                          )}
                        </List>
                      </Grid>
                    </Grid>
                  )}
                />
              </Box>

              <Box className={clsx(classes.row, classes.center)}>
                <Button type="submit" variant="contained" color="primary">
                  {t('actions.update')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: '#f07267' }}
                  onClick={() => {
                    if (initialValue && initialValue.id) {
                      onDeleteAction(initialValue.id);
                    }
                  }}
                >
                  {t('actions.delete.capitalized')}
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {t('actions.cancel')}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
