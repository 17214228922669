import { Grid, Typography } from '@material-ui/core';

import { format } from 'date-fns';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  RouteFormRHF,
  SporadicRouteFormInput,
} from '../components/RouteFormRHF';
import {
  LoadingListItemInput,
  SporadicRouteInput,
  useCreateMultipleSporadicRouteMutation,
} from '../generated/graphql';
import { DATE_FORMAT, timeOrNull } from '../lib/date_time';
import { newLeg } from '../lib/formHelpers/leg';
import {
  numberOrNull,
  _toNumber,
  _toNumberOrUndefined,
} from '../lib/formHelpers/number';
import { multipleRouteValidationSchema } from '../lib/validationSchema/route';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { getCurrencyFromDepartment } from '../utils/GetCurrencyFromDepartment';

export const getDayNumber = (key: string): number => {
  switch (key) {
    case 'sun':
      return 0;
    case 'mon':
      return 1;
    case 'tue':
      return 2;
    case 'wed':
      return 3;
    case 'thu':
      return 4;
    case 'fri':
      return 5;
    case 'sat':
      return 6;
    default:
      return -1;
  }
};

export function CreateMultipleSporadicRouteRHF() {
  //const { template } = useParams<{ template: string }>();
  const router = useHistory();
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>();
  const { department } = useUserConfiguration();
  const [createPlannedSporadicRoutes] = useCreateMultipleSporadicRouteMutation(
    {},
  );

  const onSubmit = async (
    val: Omit<SporadicRouteFormInput, 'dispatcherId' | 'subcontractorId'> & {
      dispatcherId?: number;
      subcontractorId?: number;
    },
  ) => {
    const values = val as SporadicRouteFormInput;
    let transDateStr = '';

    if (values.transportationDate) {
      transDateStr = format(new Date(values.transportationDate), 'yyyy-MM-dd');
    }
    const input: SporadicRouteInput = {
      isCreatedFromPlanned: true,
      isCreatedFromTerminal: false,
      transportationDate: transDateStr,
      agreedPrice: values.agreedPrice,
      currency: values.currency,
      note: values.note,
      externalNote: values.externalNote,
      driverName: values.driverName,
      driverPhoneNumber: values.driverPhoneNumber,
      licensePlate: values.licensePlate,
      capacity: numberOrNull(values.capacity),
      kilometer: _toNumberOrUndefined(values.kilometer),
      weight: _toNumberOrUndefined(values.weight),
      routeId: values.routeId,
      dispatcherId: values?.dispatcherId as number,
      subcontractorId: values?.subcontractorId as number,
      ftl:
        values.ftl && values.ftl.customerId && values.ftl.customerNumber
          ? values.ftl
          : undefined,
      files: values.files.map((file) => ({
        id: file.id,
      })),
      legs: values.legs.map((leg, index) => ({
        fuel: leg.fuel,
        load: leg.load,
        unload: leg.unload,
        gateNumber: leg.gateNumber ?? '',
        carRegistrationNumber: leg.carRegistrationNumber ?? '',
        trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
        actualArrivalDate: leg.transportationDate,
        actualDepartureDate: leg.transportationDate,
        routeDriverName: leg.routeDriverName ?? '',
        routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
        note: leg.note ?? '',
        arrivalTime: leg.arrivalTime ?? '00:00',
        departureTime: timeOrNull(leg.departureTime),
        position: index,
        productionDate: leg.productionDate ?? values.transportationDate,
        transportationDate: leg.transportationDate ?? values.transportationDate,
        transportationDateOffset: _toNumber(leg.transportationDateOffset),
        locationId: leg.locationId as number,
        location: undefined,
        loadingListItems: leg.load
          ? leg.loadingListItems.map(
              (loadingListItem): LoadingListItemInput => ({
                locationId: _toNumber(loadingListItem.locationId),
                packages: numberOrNull(loadingListItem.packages),
                pallets: numberOrNull(loadingListItem.pallets),
                palletSpace: numberOrNull(loadingListItem.palletSpace),
                weight: numberOrNull(loadingListItem.weight),
                note: loadingListItem.note,
                checked: loadingListItem.checked,
                isDangerous: loadingListItem.isDangerous,
                classification: loadingListItem.classification,
                unNumber: _toNumberOrUndefined(loadingListItem.unNumber),
                isLimitedQty: loadingListItem.isLimitedQty,
              }),
            )
          : [],
      })),
    };

    await createPlannedSporadicRoutes({
      variables: {
        input,
        dates: values.selectedCalDates ?? '',
      },
    }).then((res) => {
      router.push({
        pathname: '/traffic/sporadic-routes',
        state: {
          isFromPlannedRoutes: true,
          totalCreatedRoutes: res.data?.createMultipleSporadicRoute,
          timestamp: new Date().getTime(),
        },
      });
    });
  };

  const initialValues = (): Omit<
    SporadicRouteFormInput,
    'dispatcherId' | 'subcontractorId'
  > & {
    dispatcherId?: number;
    subcontractorId?: number;
  } => ({
    dateType: 'dr',
    hasExceptionDates: false,
    startDate: '',
    endDate: '',
    startExceptionDate: '',
    endExceptionDate: '',
    selectedCalDates: [],
    selectedDays: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    isUpdateAndClose: false,
    isCreatedFromPlanned: true,
    isCreatedFromTerminal: false,
    transportationDate: format(new Date(), DATE_FORMAT),
    agreedPrice: undefined,
    currency: getCurrencyFromDepartment(department?.id),
    note: '',
    externalNote: '',
    driverName: '',
    driverPhoneNumber: '',
    licensePlate: '',
    capacity: undefined,
    kilometer: undefined,
    weight: undefined,
    routeId: '',
    dispatcherId: undefined,
    subcontractorId: undefined,
    files: [],
    invoiceNumber: undefined,
    invoiceNote: undefined,
    ftl: undefined,
    totalBringCost: 0,
    newAgreedPrice: 0,
    finalEveryCost: 0,
    finalAdditionalCost: 0,
    isCopyDriverPlateInfo: false,
    legs: newLeg(''),
  });

  return (
    <Grid container spacing={1} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.plannedRoute.capitalized',
        )}`}
      />
      <Grid item>
        <Typography variant="h1">
          {t('resource.plannedRoute.capitalized')}
        </Typography>
      </Grid>

      <Grid item>
        <RouteFormRHF
          formRef={formRef}
          onSubmit={onSubmit}
          fromCreateRoute={'CreateMultipleSporadicRoute'}
          validationSchema={multipleRouteValidationSchema(t, false)}
          hideCostAllocation
          initialValues={initialValues()}
          submitButtonLabel={t('button.create', {
            item: t('resource.plannedRoute.plural'),
          })}
        />
      </Grid>
    </Grid>
  );
}
