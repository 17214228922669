import {
  debounce,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SearchSubcontractorsQuery,
  useGetSubcontractorLazyQuery,
  useGetSubcontractorsLazyQuery,
} from '../../generated/graphql';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiControl-root': {
      width: '100%',
    },
  },
}));

interface SelectSubcontractorProps {
  name: string;
  readOnly?: boolean;
  fullWidth?: boolean;
}

export function SelectSubcontractor(
  props: SelectSubcontractorProps & TextFieldProps,
) {
  const { name, readOnly = false, fullWidth = false } = props;
  const { t } = useTranslation();
  const { getFieldProps } = useFormikContext();
  const classes = useStyles();
  const [options, setOptions] = useState<
    SearchSubcontractorsQuery['subcontractors']
  >([]);
  const [value, setValue] = useState<
    SearchSubcontractorsQuery['subcontractors'][number] | null
  >(null);

  const [search, setSearch] = useState('');
  const [searchSubcontractors, { data: optionsData, loading: loadingOptions }] =
    useGetSubcontractorsLazyQuery({ fetchPolicy: 'network-only' });
  const [getSubcontractor, { data: subcontractorData, loading }] =
    useGetSubcontractorLazyQuery({ fetchPolicy: 'network-only' });
  const initialValue = getFieldProps(name).value;

  useEffect(() => {
    if (initialValue != null && initialValue !== '') {
      getSubcontractor({ variables: { id: initialValue } });
    }
  }, [initialValue]);

  useEffect(() => {
    if (subcontractorData) {
      setOptions([subcontractorData.subcontractor]);
      setValue(subcontractorData.subcontractor);
    }
  }, [subcontractorData]);

  useEffect(() => {
    if (search.length > 2) {
      searchSubcontractors({
        variables: {
          search: search,
          withTerminal: true,
        },
      });
    }
  }, [search]);

  useEffect(() => {
    if (optionsData) {
      if (value) {
        setOptions([value, ...optionsData.subcontractors]);
      } else {
        setOptions(optionsData.subcontractors);
      }
    }
  }, [optionsData]);

  const setSearchHandler = (_event: any, newVal: string) => {
    setSearch(newVal);
  };

  const debouncedChangeHandler = useCallback(
    debounce(setSearchHandler, 300),
    [],
  );

  const nameSplit = name.split('.');
  const label = nameSplit[nameSplit.length - 1];

  if (readOnly) {
    return (
      <TextField
        {...props}
        variant="outlined"
        label={t(`attributes.${label}`)}
        value={value?.name}
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <Autocomplete
          className={classes.root}
          onBlur={field.onBlur}
          value={value}
          multiple={false}
          fullWidth={fullWidth}
          autoHighlight
          onChange={(_event, val) => {
            form.setFieldValue(name, val?.id ?? null);
            if (!val || !val.id) {
              setOptions([]);
              setValue(null);
            }
          }}
          noOptionsText=""
          onInputChange={debouncedChangeHandler}
          getOptionSelected={(option, value) => option.id === value?.id}
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={options}
          loading={loading || loadingOptions}
          renderInput={(params) => (
            <TextField
              {...props}
              {...params}
              size="small"
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              variant="outlined"
              label={t(`attributes.${label}`)}
              InputProps={{ ...params.InputProps }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    </Field>
  );
}
