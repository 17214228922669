import { Checkbox, makeStyles, TextField } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { useState } from 'react';

const useStyles = makeStyles({
  input: {
    width: '70%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #585858',
    margin: 8,
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  rowContainer: {
    display: 'flex',
    border: '1px solid #585858',
    margin: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputVal: {
    height: 10,
    fontSize: 14,
  },
  inputValTime: {
    height: 10,
    width: 50,
    fontSize: 14,
  },
});

type BoxDataType = {
  boxId: number;
  boxNameText: string;
  boxValue: number | undefined;
  entryId: number | undefined;
  isReadOnly: boolean;
  isFixedBox: boolean;
  isYesNoBox: boolean;
  isAddedFromTerminal: boolean;
  isTimeBox: boolean;
};

interface ValueBoxProps {
  boxData: BoxDataType;
  onValueChanged: (
    id: number,
    val: string,
    entryId: number | undefined,
  ) => void;
}

export function ValueBox({ boxData, onValueChanged }: ValueBoxProps) {
  const classes = useStyles();
  const [isTimeCorrect, setTimeCorrect] = useState<boolean>(false);

  const getProperTimeString = (timeValStr: string): string => {
    if (timeValStr !== '') {
      return (
        '1-1-2022 ' + timeValStr.substring(0, 2) + ':' + timeValStr.substring(2)
      );
    } else {
      return '';
    }
  };
  const getTimeStringFromNumber = (numberValue: number): string => {
    const numStr = numberValue.toString();

    if (numStr.length === 4) {
      return getProperTimeString(numStr);
    } else if (numStr.length === 3) {
      return getProperTimeString('0' + numStr);
    } else if (numStr.length === 2) {
      return '1-1-2022 00:' + numStr;
    } else if (numStr.length === 1) {
      return '1-1-2022 00:0' + numStr;
    } else {
      return '';
    }
  };

  const [myValue, setMyValue] = useState<string>(
    boxData.boxValue
      ? boxData.boxValue === 0
        ? ''
        : boxData.isFixedBox || boxData.isTimeBox
        ? getTimeStringFromNumber(boxData.boxValue)
        : boxData.boxValue.toString()
      : '',
  );

  return (
    <div className={classes.rowContainer}>
      <div
        style={{
          padding: '2px 4px 2px 4px',
          backgroundColor: '#d1d1d1',
          fontSize: 13,
        }}
      >
        {`${boxData.boxNameText}`}
      </div>

      <div
        style={{
          width: boxData.isFixedBox ? 50 : 40,
          backgroundColor: '#ffffff',
          fontSize: 13,
          marginLeft: boxData.isYesNoBox ? 0 : 6,
        }}
      >
        {boxData.isFixedBox || boxData.isTimeBox ? (
          <KeyboardTimePicker
            placeholder="00:00"
            mask="__:__"
            KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
            ampm={false}
            keyboardIcon={false}
            value={myValue === '' ? null : myValue}
            InputProps={{
              classes: { input: classes.inputValTime },
              disableUnderline: true,
              maxRows: 1,
            }}
            invalidDateMessage={'Invalid'}
            onChange={(time) => {
              if (time && time.toString() !== 'Invalid Date') {
                setTimeCorrect(
                  time && time.toString().length > 10 ? true : false,
                );
              } else {
                setTimeCorrect(false);
              }
              setMyValue(time ? time.toString() : '');
            }}
            onBlur={(e) => {
              onValueChanged(
                boxData.boxId,
                isTimeCorrect ? e.target.value.replace(/:/g, '') : '',
                boxData.entryId ? boxData.entryId : undefined,
              );
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (e.target && e.target instanceof HTMLInputElement) {
                  (e.target as HTMLInputElement).blur();
                }
                e.preventDefault();
              }
            }}
          />
        ) : boxData.isYesNoBox ? (
          <Checkbox
            name={`YN-${boxData.boxId}`}
            size="small"
            disabled={boxData.isReadOnly}
            checked={myValue === '' || myValue === '-2' ? false : true}
            style={{
              paddingRight: 2,
              paddingLeft: 2,
              paddingTop: 1,
              paddingBottom: 1,
              color: '#68bc46',
            }}
            onChange={(e) => {
              onValueChanged(
                boxData.boxId,
                e.target.checked ? '-1' : '-2',
                boxData.entryId ? boxData.entryId : undefined,
              );
              setMyValue(e.target.checked ? '-1' : '-2');
            }}
          />
        ) : (
          <TextField
            id={`T${boxData.boxId}`}
            name={`T${boxData.boxId}`}
            disabled={boxData.isReadOnly}
            autoComplete="off"
            InputProps={{
              classes: { input: classes.inputVal },
              disableUnderline: true,
              maxRows: 1,
            }}
            inputProps={{ maxLength: 5 }}
            value={myValue}
            onBlur={(e) => {
              onValueChanged(
                boxData.boxId,
                e.target.value,
                boxData.entryId ? boxData.entryId : undefined,
              );
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (e.target && e.target instanceof HTMLInputElement) {
                  (e.target as HTMLInputElement).blur();
                }
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (
                e.target &&
                e.target.value !== null &&
                e.target.value !== undefined
              ) {
                const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                setMyValue(onlyNums);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
