import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'react-i18next';
import { COUNTRIES_MAP } from '../lib/constants';
import { CommonTextField } from './form/CommonTextField';
import { SelectCountryField } from './form/SelectCountryField';
import * as yup from 'yup';
import { DATE_FORMAT } from '../lib/date_time';
import { format } from 'date-fns';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';
import { CommonDateField } from './form/CommonDateField';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
  formRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      padding: theme.spacing(1),
      width: '100%',
    },

    '& input': {
      width: '100%',
    },
  },
  field: {
    width: '100%',
  },
}));
const validationSchema = (t: TFunction) =>
  yup.object({
    title: yup
      .string()
      .min(
        1,
        t('validation.atLeastLen', {
          name: t('attributes.title'),
          len: 3,
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.title') })),
    date: yup
      .date()
      .required(
        t('validation.isRequired', {
          name: t('attributes.date'),
        }),
      )
      .min(format(new Date(), DATE_FORMAT)),
  });

export interface HolidayFormValues {
  title: string;
  date: string;
  country: string;
}

interface HolidayFormProps {
  onSubmit: (values: HolidayFormValues) => void;
  initialValues?: HolidayFormValues;
}

export function HolidayForm(props: HolidayFormProps) {
  const { department } = useUserConfiguration();
  const {
    onSubmit,
    initialValues = {
      title: '',
      date: '',
      country: department?.id ?? Object.keys(COUNTRIES_MAP)[0],
    },
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          resetForm({
            values: {
              title: '',
              date: '',
              country: values.country,
            },
          });
        }}
        validationSchema={validationSchema(t)}
      >
        {() => (
          <Form>
            <Box className={classes.formRoot}>
              <Box>
                <CommonTextField name="title" fullWidth />
              </Box>
              <Box>
                <CommonDateField name="date" fullWidth variant="outlined" />
              </Box>
              <Box>
                <SelectCountryField name="country" fullWidth />
              </Box>
              <Button type="submit">
                {t('button.add', { item: t('resource.holiday.capitalized') })}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
