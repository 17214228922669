import {
  Checkbox,
  createStyles,
  FormControlLabel,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { SporadicRouteFormInput } from '../RouteFormRHF';

interface CheckboxFieldProps {
  name: string;
  controllerName: Path<SporadicRouteFormInput>;
  label: string;
  readOnly?: boolean;
  size?: 'small' | 'medium';
  disabled?: boolean;
  isRed?: boolean;
  handleEdit?: (
    e: React.SyntheticEvent,
    name: string,
    editedField: string,
    checked?: boolean,
  ) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.fontSize - 1,
    },
    checkBox: {
      padding: 0,
    },
    formControl: {
      marginLeft: 1,
    },
  }),
);

export function CheckboxFieldRHF(props: CheckboxFieldProps) {
  const classes = useStyles();
  const { control } = useFormContext<SporadicRouteFormInput>();

  const {
    name,
    label,
    readOnly = false,
    size = undefined,
    disabled = false,
    isRed = false,
    controllerName,
  } = props;

  return (
    <Controller
      name={controllerName}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          id={name}
          className={classes.formControl}
          disabled={readOnly || disabled}
          control={
            <Checkbox
              size={size}
              color="primary"
              className={classes.checkBox}
              style={isRed ? { padding: 0, color: '#fc3926' } : undefined}
              checked={field.value === true}
              onChange={field.onChange}
            />
          }
          label={
            <Typography
              variant="body2"
              className={classes.label}
              style={isRed ? { color: '#fc3926' } : undefined}
              color="textPrimary"
            >
              {label}
            </Typography>
          }
        />
      )}
    />
  );
}
