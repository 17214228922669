import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ViewCard } from '../components/ViewCard';
import {
  GetOneTerminalDocument,
  GetTerminalsDocument,
  useDeleteTerminalMutation,
  useGetOneTerminalQuery,
  useUpdateTerminalMutation,
} from '../generated/graphql';
import { useUserConfiguration } from '../providers/UserConfigurationProvider';

interface ViewTerminalProps {
  terminal?: boolean;
}

export function ViewTerminal(props: ViewTerminalProps) {
  const { terminal = false } = props;
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useGetOneTerminalQuery({
    variables: { id: parseInt(id, 10) },
  });
  const router = useHistory();
  const [editOpen, setEditOpen] = useState(false);
  const [updateTerminal, {}] = useUpdateTerminalMutation({
    refetchQueries: [
      {
        query: GetOneTerminalDocument,
        variables: {
          id: parseInt(id, 10),
        },
      },
    ],
  });
  const [deleteTerminal] = useDeleteTerminalMutation({
    refetchQueries: [
      {
        query: GetTerminalsDocument,
      },
    ],
  });
  const { isAdmin } = useUserConfiguration();

  const handleDeleteTerminal = (id: number) => {
    deleteTerminal({ variables: { id } });
    router.push(`/traffic/cards/terminals`);
  };

  const onSubmit = (values: { name: string; id: number }) => {
    updateTerminal({ variables: { ...values } });
    setEditOpen(false);
  };
  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading || data == null) {
    return <div>loading...</div>;
  }

  return (
    <ViewCard
      name={data.terminal.name}
      id={data.terminal.id}
      isProtected={data.terminal.isInternalTerminal}
      isAdmin={isAdmin}
      type="terminal"
      files={data.terminal.files}
      createdBy={data.terminal.createdBy}
      lastUpdatedBy={data.terminal.lastUpdatedBy}
      updatedAt={data.terminal.updatedAt}
      contacts={data.terminal.contacts}
      onSubmit={onSubmit}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      deleteCard={handleDeleteTerminal}
      terminal={terminal}
      isLocationExists={
        data.terminal?.locations
          ? data.terminal?.locations.length > 0
            ? true
            : false
          : false
      }
    />
  );
}
