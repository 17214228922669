import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { usePDF } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { FileItem } from '../components/FileListComponent';
import { downloadTourPDF } from '../lib/pdf/downloadPdf';
import { useHttpClient } from '../providers/HttpClientProvider';
import {
  TourTransportAgreement,
  TourTransportAgreementInterface,
} from './TransportAgreement/TourTransportAgreement';

type CreateTourPdfProps = {
  tour: TourTransportAgreementInterface;
};

// Create Document Component
export function CreateTourPdf(props: CreateTourPdfProps) {
  const document = <TourTransportAgreement tour={props.tour} />;
  const [instance] = usePDF({ document });
  const { httpClient } = useHttpClient();
  const { t } = useTranslation();
  let allRoutesFiles: FileItem[] = [];
  props.tour?.routes.map((r) => {
    if (r.files) {
      allRoutesFiles = allRoutesFiles.concat(r.files);
    }
  });

  return (
    <>
      <Button
        variant="contained"
        style={{ marginRight: 16 }}
        startIcon={<CloudDownloadIcon />}
        onClick={() => {
          downloadTourPDF(
            instance.blob,
            allRoutesFiles,
            httpClient,
            `TourAgreement-${props.tour.name}.pdf`,
          );
        }}
      >
        {t('button.download', { item: 'Agreement' })}
      </Button>
    </>
  );
}
