import {
  GridCellParams,
  GridDensityTypes,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Maybe } from '../../generated/graphql';
import { TABLE_NAMES } from '../../lib/constants';
import { EditShipmentModal } from '../modal/EditShipmentModal';
import { UserConfiguredDataGridPro } from './UserConfiguredDataGridPro';

interface gridProps {
  rows: {
    id: number;
    name: string;
    createdFrom?: Maybe<string>;
    updatedFrom?: Maybe<string>;
    createdBy: string;
    lastUpdatedBy: string;
  }[];
  listUpdated: () => void;
  from: string;
}
export function ShipmentTemplateGrid(props: gridProps) {
  const { rows, listUpdated, from } = props;

  const apiRef = useGridApiRef();

  return (
    <UserConfiguredDataGridPro
      apiRef={apiRef}
      tableName={TABLE_NAMES.ShipmentList}
      rows={rows || []}
      loading={false}
      disableMultipleSelection
      disableSelectionOnClick
      columns={[
        {
          field: 'id',
          headerName: 'ID',
          type: 'number',
        },
        {
          field: 'name',
          headerName: 'Name',
          type: 'string',
        },
        {
          field: 'viewShipmentModal',
          headerName: 'View template',
          minWidth: 100,
          type: 'actions',
          sortable: false,
          renderCell: (params: GridCellParams) => {
            const { row } = params;
            const { id, shipmentDate } = row;
            return (
              <EditShipmentModal
                key={`kkk${id}`}
                id={id}
                showDate={true}
                shipmentDate={shipmentDate ?? ''}
                templateUpdated={() => {
                  listUpdated();
                }}
                updatedFrom={from}
              />
            );
          },
        },
        {
          field: 'createdFrom',
          headerName: 'Created from',
        },
        {
          field: 'updatedFrom',
          headerName: 'Updated from',
        },

        {
          field: 'createdBy',
          headerName: 'Created by',
        },
        {
          field: 'lastUpdatedBy',
          headerName: 'Updated by',
        },
      ]}
      density={GridDensityTypes.Compact}
      autoHeight={true}
    />
  );
}
