import { GridSortModel } from '@mui/x-data-grid-pro';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { UserConfiguredDataGridPro } from '../components/datagrid/UserConfiguredDataGridPro';
import { useGetAllCardsLazyQuery } from '../generated/graphql';
import { TABLE_NAMES } from '../lib/constants';
import { cardColumns } from '../utils/CardColumns';
import { LoadingAndError } from '../utils/LoadingAndError';
import { locationString } from '../utils/LocationString';

interface AllGroupsProps {
  searchText?: string;
}

export function AllGroups(props: AllGroupsProps) {
  const { searchText } = props;
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const [getAllCards, { data, loading, error }] = useGetAllCardsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  useEffect(() => {
    getAllCards({
      variables: { search: searchText },
    });
  }, [searchText]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'locations',
      sort: 'asc',
    },
  ]);

  const onSortModelChange = useCallback(
    (newSortModel: GridSortModel) => {
      if (newSortModel && !_.isEqual(newSortModel, sortModel)) {
        setSortModel(newSortModel);
      }
    },
    [sortModel],
  );

  return (
    <LoadingAndError loading={loading} error={error} data={data?.allCards}>
      {({ loadedData }) => (
        <div style={{ height: '100%', width: '100%' }}>
          <UserConfiguredDataGridPro
            tableName={TABLE_NAMES.Subcontractors}
            rows={
              loadedData.map((c: { locations: any[] }) => ({
                ...c,
                locations: c.locations
                  .map((l: any) => locationString(l))
                  .join(', '),
              })) || []
            }
            columns={cardColumns({ t, path: `${path}`, isAllType: true })}
            loading={loading}
            rowHeight={50}
            autoHeight={true}
            pagination={true}
            disableSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
          />
        </div>
      )}
    </LoadingAndError>
  );
}
