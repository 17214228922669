import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { format, parse } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../../generated/graphql';
import { DATE_FORMAT } from '../../lib/date_time';
import { LoadingListItemInterface } from '../LoadingList';
import clsx from 'clsx';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateTransportationAndProductionDates } from '../../lib/route_leg_dates';
import { CheckListRoutesFormInput } from '../../views/CheckListRoutesTraffic';
import { TimePickerCLRHF } from './TimePickerCLRHF';
import { CheckboxCLRHF } from './CheckboxCLRHF';
import { SelectLocationCLRHF } from './SelectLocationCLRHF';
import { CommonTextCLRHF } from './CommonTextCLRHF';
import { LoadingListCLRHF } from '../LoadingListCLRHF';
import DeleteIcon from '@material-ui/icons/Delete';
export interface LegFieldInput {
  key: string;
  id?: number;
  load: boolean;
  unload: boolean;
  note?: string;
  arrivalTime?: Maybe<string>;
  departureTime?: Maybe<string>;
  position: number;
  productionDate?: string;
  transportationDate?: string;
  transportationDateOffset: number;
  locationId?: number;
  isNonArrivalMarked?: boolean;
  nonArrivalMarkedByUserName?: string;
  nonArrivalMarkedFrom?: string;
  nonArrivalMarkedAt?: string;
  loadingListItems: LoadingListItemInterface[];
  gateNumber?: string;
  carRegistrationNumber?: string;
  trailerRegistrationNumber?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(0.2, 0),
  },
  field: {
    width: '100%',
    fontSize: '10px',
    margin: 0,
  },
  checkboxLabel: {
    fontSize: theme.typography.fontSize,
  },
  fieldsRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    marginTop: -6,
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minWidth: 500,
      marginTop: 8,
      marginBottom: 4,
    },
    '& > *:first-child': {
      width: '100%',
    },
    '& > *:last-child': {
      width: '100%',
    },
  },
  noteCheckbox: {
    margin: '0 8px',
  },
  loadingListButton: {
    padding: 0,
  },
  pushRight: {
    marginLeft: 14,
  },
  divider: {
    height: '5px',
    margin: '2px 0',
    backgroundColor: theme.palette.primary.light,
  },
}));

interface ChecklistLegProps {
  readOnly: boolean;
  routeIndex: number;
  legIndex: number;
  legsLength: number;
  onRemove: (index: number) => void;
}

export function ChecklistLegRHF(props: ChecklistLegProps) {
  const { t } = useTranslation();
  const { readOnly, routeIndex, legIndex, legsLength, onRemove } = props;

  const classes = useStyles();

  const {
    control,
    setValue: setFieldValue,
    getValues: getFieldProps,
    getFieldState,
  } = useFormContext<CheckListRoutesFormInput>();

  //set position dynamically
  setFieldValue(`routes.${routeIndex}.legs.${legIndex}.position`, legIndex);
  const leg = getFieldProps(`routes.${routeIndex}.legs.${legIndex}`);
  const [isLegLoad, isLegUnload, legNote, tranDate] = useWatch({
    control,
    name: [
      `routes.${routeIndex}.legs.${legIndex}.load`,
      `routes.${routeIndex}.legs.${legIndex}.unload`,
      `routes.${routeIndex}.legs.${legIndex}.note`,
      `routes.${routeIndex}.legs.${legIndex}.transportationDate`,
    ],
  });

  const [arrivalTime, transportationDateString] = useWatch({
    control,
    name: [
      `routes.${routeIndex}.legs.${legIndex}.arrivalTime`,
      `routes.${routeIndex}.transportationDate`,
    ],
  });

  useEffect(() => {
    if (
      isLegLoad &&
      getFieldState(`routes.${routeIndex}.legs.${legIndex}.load`).isDirty
    ) {
      setFieldValue(`routes.${routeIndex}.legs.${legIndex}.unload`, false, {
        shouldTouch: true,
      });
    } else if (
      isLegLoad &&
      getFieldState(`routes.${routeIndex}.legs.${legIndex}.load`).isTouched
    ) {
      setFieldValue(`routes.${routeIndex}.legs.${legIndex}.unload`, false);
    }
  }, [isLegLoad]);

  useEffect(() => {
    if (
      isLegUnload &&
      getFieldState(`routes.${routeIndex}.legs.${legIndex}.unload`).isDirty
    ) {
      setFieldValue(`routes.${routeIndex}.legs.${legIndex}.load`, false, {
        shouldTouch: true,
      });
    } else if (
      isLegUnload &&
      getFieldState(`routes.${routeIndex}.legs.${legIndex}.unload`).isTouched
    ) {
      setFieldValue(`routes.${routeIndex}.legs.${legIndex}.load`, false);
    }
  }, [isLegUnload]);

  //for dates calculation
  useEffect(() => {
    if (
      readOnly ||
      transportationDateString === '' ||
      (arrivalTime != undefined && arrivalTime == 'Invalid Date')
    ) {
      return;
    }
    const allLegs = getFieldProps(`routes.${routeIndex}.legs`);

    calculateTransportationAndProductionDates(
      allLegs,
      transportationDateString,
    ).forEach((leg, index) => {
      setFieldValue(
        `routes.${routeIndex}.legs.${index}.productionDate`,
        leg.productionDate,
      );
      setFieldValue(
        `routes.${routeIndex}.legs.${index}.transportationDate`,
        leg.transportationDate,
      );
    });
  }, [arrivalTime]); //transportationDateString

  const [loadingListOpen, setLoadingListOpen] = useState<boolean>(
    leg.loadingListItems != undefined,
  );

  const handleClearNote = useCallback(() => {
    setFieldValue(`routes.${routeIndex}.legs.${legIndex}.note`, '');
  }, []);

  return (
    <Box className={classes.root}>
      <Box display="flex" style={{ marginBottom: 4, alignItems: 'center' }}>
        <ViewDateField
          label={`${t('attributes.transportationDate')}:`}
          value={tranDate}
        />
        <Divider
          orientation="horizontal"
          style={{
            width: '6px',
            marginLeft: 6,
            marginRight: 2,
          }}
        />
        <Box ml={4}>
          <CheckboxCLRHF
            name={`routes.${routeIndex}.legs.${legIndex}.load`}
            controllerName={`routes.${routeIndex}.legs.${legIndex}.load`}
            label={t('attributes.load')}
            readOnly={readOnly}
          />
          <CheckboxCLRHF
            name={`routes.${routeIndex}.legs.${legIndex}.unload`}
            controllerName={`routes.${routeIndex}.legs.${legIndex}.unload`}
            label={t('attributes.unload')}
            readOnly={readOnly}
          />
        </Box>
        <Divider
          orientation="horizontal"
          style={{
            width: '5px',
            marginLeft: 2,
            marginRight: 2,
          }}
        />
        <Box ml={2}>
          <FormControlLabel
            className={classes.noteCheckbox}
            value={legNote != ''}
            disabled={readOnly}
            onChange={() => {
              if (legNote == null) {
                setFieldValue(`routes.${routeIndex}.legs.${legIndex}.note`, '');
              } else if (legNote === '') {
                setFieldValue(
                  `routes.${routeIndex}.legs.${legIndex}.note`,
                  null,
                );
              }
            }}
            control={
              <Checkbox
                style={{ padding: 0 }}
                checked={legNote != null}
                color="primary"
              />
            }
            label={
              <Typography
                variant="body2"
                className={classes.checkboxLabel}
                color="textPrimary"
              >
                {'Add note'}
              </Typography>
            }
          />
        </Box>
        <Box ml={2}>
          {isLegLoad && (
            <Tooltip
              title={`${t('button.view', {
                item: t('resource.loadingList.lowercased'),
              })}`}
            >
              <IconButton
                className={clsx(classes.loadingListButton, {
                  [classes.pushRight]: legIndex === 0,
                })}
                color={loadingListOpen ? 'primary' : undefined}
                disabled={
                  readOnly &&
                  leg.loadingListItems &&
                  leg.loadingListItems.length === 0
                }
                onClick={() => {
                  setLoadingListOpen(!loadingListOpen);
                }}
              >
                <ListAltIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Grid
          xs={3}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography
            style={{ fontSize: 13, color: 'red' }}
          >{`Delete Leg >`}</Typography>
          <IconButton
            onClick={() => {
              onRemove(legIndex);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Box>
      <Box className={classes.fieldsRoot}>
        <Grid container xs={12} direction="row" justifyContent="flex-start">
          <Grid item xs={7}>
            <Box display="flex" alignItems="flex-start">
              <SelectLocationCLRHF
                name={`routes.${routeIndex}.legs.${legIndex}.locationId`}
                controllerName={`routes.${routeIndex}.legs.${legIndex}.locationId`}
                className={classes.field}
                readOnly={readOnly}
                size="small"
                smallFontSize={true}
                fullWidth
                withLink
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box display="flex" alignItems="flex-start" ml={1}>
              <TimePickerCLRHF
                name={`routes.${routeIndex}.legs.${legIndex}.arrivalTime`}
                controllerName={`routes.${routeIndex}.legs.${legIndex}.arrivalTime`}
                readOnly={readOnly}
              />

              <TimePickerCLRHF
                name={`routes.${routeIndex}.legs.${legIndex}.departureTime`}
                controllerName={`routes.${routeIndex}.legs.${legIndex}.departureTime`}
                readOnly={readOnly}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={12} direction="row" justifyContent="flex-start">
          <Grid item xs={7}>
            {legNote != null && (
              <CommonTextCLRHF
                name={`routes.${routeIndex}.legs.${legIndex}.note`}
                controllerName={`routes.${routeIndex}.legs.${legIndex}.note`}
                readOnly={readOnly}
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: !readOnly && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear note"
                        onClick={() => handleClearNote()}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box width="85%" display={!loadingListOpen ? 'none' : 'block'}>
        {isLegLoad && (
          <LoadingListCLRHF
            load={isLegLoad}
            routeIndex={routeIndex}
            legIndex={legIndex}
            readOnly={readOnly}
          />
        )}
      </Box>
      {legsLength - 1 !== legIndex && <Divider className={classes.divider} />}
    </Box>
  );
}

const useStyleViewDate = makeStyles(() => ({
  root: {
    display: 'flex',

    '& p': {
      fontSize: 12,
      marginRight: 5,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface ViewDateFieldProps {
  label: string;
  value?: string;
}

function ViewDateField(props: ViewDateFieldProps) {
  const { label, value } = props;
  const classes = useStyleViewDate();

  if (value == null || value === '') {
    return null;
  }

  const date = parse(value, DATE_FORMAT, new Date());
  const dateString = format(date, DATE_FORMAT);

  return (
    <Box className={classes.root}>
      <Typography className={classes.bold}>{label}</Typography>
      <Typography>{dateString}</Typography>
    </Box>
  );
}
