import { FormHelperText } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectEnumFieldProps<T extends { [key: string]: unknown }> {
  name: string;
  enumObject: T;
  label: string;
  fullWidth?: boolean;
  translationPath?: string;
  isRequired?: boolean;
}

export function SelectEnumField<T extends { [key: string]: unknown }>(
  props: SelectEnumFieldProps<T>,
) {
  const {
    name,
    enumObject,
    fullWidth = false,
    label,
    translationPath,
    isRequired = false,
  } = props;
  const values = Object.values(enumObject);
  const { t } = useTranslation();

  const renderValue = useCallback(
    (val: string) => {
      if (translationPath != null) {
        return t(`${translationPath}.${val}`);
      }

      return val;
    },
    [translationPath],
  );

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl fullWidth={fullWidth}>
          <InputLabel id={`${name}-label`} variant="outlined">
            {label}
          </InputLabel>
          <Select
            {...field}
            variant="outlined"
            labelId={`${name}-label`}
            id={`${name}-select`}
            size="small"
            label={label}
            style={{
              border:
                meta.touched && Boolean(meta.error)
                  ? '1px solid red'
                  : '1px solid #bababa',
            }}
          >
            {values.map((value: any) => (
              <MenuItem value={value}>{renderValue(value)}</MenuItem>
            ))}
          </Select>
          {isRequired && meta.touched && Boolean(meta.error) && (
            <FormHelperText error={true}>
              {t('attributes.warningMessage')}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
}
