import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface AutoResetProps {
  readOnly: boolean;
}

export function AutoReset(props: AutoResetProps) {
  const { readOnly } = props;
  const { resetForm } = useFormikContext();
  useEffect(() => {
    if (readOnly === true) {
      resetForm();
    }
  }, [readOnly]);
  return null;
}
