import { Button, Grid, List, ListItem, Typography } from '@material-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { newLeg } from '../lib/formHelpers/leg';
import { calculateTransportationAndProductionDates } from '../lib/route_leg_dates';
import { LegFieldInput, LegFields } from './form/LegFields';

interface LegsFieldsProps {
  trafficReadOnly: boolean;
  readOnly: boolean;
  fieldsPath: string;
  disableOffsetCalculation?: boolean;
  fromCreateRoute?: string;
  isCreateTerminalRoute?: boolean;
  isFromTerminal: boolean;
  legIdForLoadingList?: number;
  enableEditMode?: boolean;
  isTruckLoading?: boolean;
}

export function LegsFields(props: LegsFieldsProps) {
  const {
    trafficReadOnly, //terminalORreadonly
    readOnly,
    fieldsPath,
    disableOffsetCalculation = false,
    fromCreateRoute,
    isCreateTerminalRoute = false,
    isFromTerminal = false,
    legIdForLoadingList,
    enableEditMode = false,
    isTruckLoading,
  } = props;
  const { t } = useTranslation();
  const {
    values: { transportationDate: transportationDateString, legs },
    setFieldValue,
  } = useFormikContext<{ transportationDate: string; legs: LegFieldInput[] }>();

  const ref = useRef<any>();

  useEffect(() => {
    if (enableEditMode) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [enableEditMode]);

  if (!disableOffsetCalculation) {
    const arrivalTimes = legs.map((x) => x.arrivalTime).join(', ');
    const transportationDateOffsets = legs
      .map((x) => x.transportationDateOffset)
      .join(', ');

    useEffect(() => {
      if (readOnly || transportationDateString === '') {
        return;
      }
      calculateTransportationAndProductionDates(
        legs,
        transportationDateString,
      ).forEach((leg, index) => {
        setFieldValue(
          `${fieldsPath}.${index}.productionDate`,
          leg.productionDate,
        );
        setFieldValue(
          `${fieldsPath}.${index}.transportationDate`,
          leg.transportationDate,
        );
      });
    }, [
      readOnly,
      transportationDateString,
      arrivalTimes,
      transportationDateOffsets,
    ]);
  }

  return (
    <FieldArray
      name="legs"
      render={(arrayHelpers) => (
        <>
          {!trafficReadOnly && !isCreateTerminalRoute && (
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography variant="subtitle1" align="left">
                {t('resource.routeLeg.plural')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  arrayHelpers.push(newLeg(transportationDateString)[0]);
                }}
              >
                {t('button.add', { item: t('leg') })}
              </Button>
            </Grid>
          )}
          <List>
            {legs.map((leg, index) => (
              <ListItem key={leg.key} style={{ padding: 0 }}>
                {enableEditMode ? (
                  <>
                    {legIdForLoadingList === leg.id ? (
                      <div ref={ref}>
                        <LegFields
                          onRemove={(index) => arrayHelpers.remove(index)}
                          onMoveUp={() => arrayHelpers.swap(index, index - 1)}
                          onMoveDown={() => arrayHelpers.swap(index, index + 1)}
                          leg={leg}
                          legsPath={`${fieldsPath}`}
                          index={index}
                          legsLength={legs.length}
                          readOnly={readOnly}
                          trafficReadOnly={trafficReadOnly}
                          isFromTerminal={isFromTerminal}
                          from={
                            fromCreateRoute === 'ViewSporadicRouteTemplate'
                              ? 'ViewSporadicTemplate'
                              : 'LegsFields'
                          }
                          fromCreateRoute={fromCreateRoute ?? ''}
                          isCreateTerminalRoute={isCreateTerminalRoute}
                          enableEditMode={enableEditMode}
                        />
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <LegFields
                      onRemove={(index) => arrayHelpers.remove(index)}
                      onMoveUp={() => arrayHelpers.swap(index, index - 1)}
                      onMoveDown={() => arrayHelpers.swap(index, index + 1)}
                      leg={leg}
                      legsPath={`${fieldsPath}`}
                      index={index}
                      legsLength={legs.length}
                      readOnly={readOnly}
                      trafficReadOnly={trafficReadOnly}
                      isFromTerminal={isFromTerminal}
                      from={
                        fromCreateRoute === 'ViewSporadicRouteTemplate'
                          ? 'ViewSporadicTemplate'
                          : 'LegsFields'
                      }
                      fromCreateRoute={fromCreateRoute ?? ''}
                      isCreateTerminalRoute={isCreateTerminalRoute}
                      isTruckLoading={isTruckLoading}
                    />
                  </>
                )}
              </ListItem>
            ))}
          </List>
        </>
      )}
    />
  );
}
