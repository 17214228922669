import { format, isMatch, parse } from 'date-fns';

export function removeSecondsFromTimeString(
  timeString?: string | null,
): string | null | undefined {
  return isMatch(timeString ?? '', 'HH:mm:ss')
    ? format(parse(timeString ?? '', 'HH:mm:ss', new Date()), 'HH:mm')
    : timeString;
}

export function timeToMinute(timeString: string): number {
  const t = timeString.split(':');
  return +t[0] * 60 + +t[1];
}
