import { DangerousGoodsClassification, Maybe } from '../../generated/graphql';

export function newLoadingListItem(): {
  id?: number;
  packages: Maybe<number> | string;
  pallets: Maybe<number> | string;
  palletSpace: Maybe<number> | string;
  weight: Maybe<number> | string;
  note: string;
  locationId?: number;
  checked: boolean;
  isDangerous: boolean;
  classification: DangerousGoodsClassification | undefined;
  unNumber: string | undefined;
  isLimitedQty: boolean;
} {
  return {
    id: undefined,
    packages: '',
    pallets: '',
    palletSpace: '',
    weight: '',
    note: '',
    locationId: 0,
    checked: false,
    isDangerous: false,
    classification: undefined,
    unNumber: undefined,
    isLimitedQty: false,
  };
}
