import { Button, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import DriverInfoCarIcon from '@mui/icons-material/DirectionsCarFilled';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Box } from '@mui/material';
import { FieldArrayRenderProps, useFormikContext } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyRouteDialogModal } from '../../CopyRouteDialogModal';
import { useHistory } from 'react-router-dom';
import { CostAllocationItemType, Maybe } from '../../../generated/graphql';
import { FileItem } from './../../FileListComponent';
import { LoadingListItemInterface } from './../../LoadingList';
import { SelectOtherTourModal } from '../../modal/SelectOtherTourModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SimpleMessageDialog } from '../../SimpleMessageDialog';
import StorefrontIcon from '@mui/icons-material/Storefront';

export interface SingleRoute {
  id?: number;
  routeId: string;
  files: FileItem[];
  price?: Maybe<number>;
  note: string;
  days: {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
  };
  costAllocations: {
    id?: number;
    departmentId: string;
    type: CostAllocationItemType;
    includedKpi: boolean;
    includedPrice: boolean;
    percentage?: number;
    cost: number;
    comment: string;
  }[];
  legs: {
    id?: number;
    key: string;
    locationId?: number;
    position: number;
    load: boolean;
    unload: boolean;
    arrivalTime?: Maybe<string>;
    departureTime?: Maybe<string>;
    note?: string;
    transportationDateOffset: number;
    loadingListItems: LoadingListItemInterface[];
  }[];
}

interface LegsFieldsProps {
  readOnly: boolean;
  terminal: boolean;
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  setTab: (tabIndex: number) => void;
  filesOpen: boolean;
  setFilesOpen: Dispatch<SetStateAction<boolean>>;
  setFTLOpen: Dispatch<SetStateAction<boolean>>;
  ftlOpen: boolean;
  driverInfoOpen: boolean;
  setDriverInfoOpen: Dispatch<SetStateAction<boolean>>;
  costAllocationOpen: boolean;
  setCostAllocationOpen: Dispatch<SetStateAction<boolean>>;
  dispatchRouteOpen: boolean;
  setDispatchRouteOpen: Dispatch<SetStateAction<boolean>>;
  routeNoteOpen: boolean;
  setRouteNoteOpen: Dispatch<SetStateAction<boolean>>;
  setTotalRoutes: Dispatch<SetStateAction<number>>;
  clickedRouteData: any;
  totalRoutes: number;
}

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export function RouteControlButtons({
  readOnly,
  arrayHelpers,
  index,
  setTab,
  filesOpen,
  setFilesOpen,
  setFTLOpen,
  ftlOpen,
  driverInfoOpen,
  setDriverInfoOpen,
  costAllocationOpen,
  setCostAllocationOpen,
  dispatchRouteOpen,
  setDispatchRouteOpen,
  routeNoteOpen,
  setRouteNoteOpen,
  terminal,
  clickedRouteData,
  totalRoutes,
  setTotalRoutes,
}: LegsFieldsProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false);
  const [openCanNotDeleteDialog, setOpenCanNotDeleteDialog] =
    useState<boolean>(false);

  const [openOtherToursModal, setOpenOtherToursModal] =
    useState<boolean>(false);
  const router = useHistory();
  const { getFieldProps, setFieldValue } = useFormikContext();

  return (
    <>
      {openOtherToursModal && (
        <SelectOtherTourModal
          open={openOtherToursModal}
          handleClose={() => {
            setOpenOtherToursModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          onSelectConfirm={(tourId) => {
            //redirect to page with routeId

            router.push({
              pathname: `/traffic/tours/${tourId}`,
              state: {
                copyRoute: true,
                enableEditMode: true,
                routeId: clickedRouteData.id,
                otherTour: true,
                timestamp: new Date().getTime(),
              },
            });
          }}
        />
      )}

      <SimpleMessageDialog
        open={openCanNotDeleteDialog}
        setOpen={setOpenCanNotDeleteDialog}
        title={t('attributes.alert')}
        contentText={t('validation.lastRoute')}
      />

      <CopyRouteDialogModal
        open={openCopyDialog}
        setOpen={setOpenCopyDialog}
        contentText={t('validation.copyRouteAlert', {
          item: `[${clickedRouteData.routeId}]`,
        })}
        copyToCurrentTour={() => {
          //temp
          router.push({
            state: {
              copyRoute: true,
              enableEditMode: true,
              routeId: clickedRouteData.id,
              otherTour: true,
              timestamp: new Date().getTime(),
            },
          });
        }}
        copyToOtherTour={() => {
          setOpenOtherToursModal(true);
        }}
      />

      <Box className={classes.wrapper}>
        {!terminal && readOnly && clickedRouteData?.id && (
          <Tooltip
            title={`${t('button.copy', {
              item: t('resource.route.capitalized'),
            })}`}
          >
            <IconButton
              hidden={true}
              edge="end"
              aria-label="edit"
              style={{ marginRight: 8 }}
              onClick={() => setOpenCopyDialog(true)}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
        {!terminal && readOnly && (
          <Tooltip
            title={`${t('button.view', {
              item: t('actions.dispatchRoute'),
            })}`}
          >
            <IconButton
              color={dispatchRouteOpen ? 'primary' : undefined}
              onClick={() => {
                setDispatchRouteOpen(!dispatchRouteOpen);
              }}
            >
              <LocalShippingIcon />
            </IconButton>
          </Tooltip>
        )}
        {!terminal && (
          <Tooltip
            title={`${t('button.view', {
              item: t('resource.costAllocation.lowercased'),
            })}`}
          >
            <IconButton
              color={costAllocationOpen ? 'primary' : undefined}
              onClick={() => {
                setCostAllocationOpen(!costAllocationOpen);
              }}
            >
              <AttachMoneyIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={`${t('button.view', {
            item: t('attributes.note'),
          })}`}
        >
          <IconButton
            color={routeNoteOpen ? 'primary' : undefined}
            onClick={() => {
              setRouteNoteOpen(!routeNoteOpen);
            }}
          >
            <QuestionAnswerIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`${t('button.view', {
            item: t('resource.documentFile.plural'),
          })}`}
        >
          <IconButton
            color={filesOpen ? 'primary' : undefined}
            onClick={() => {
              setFilesOpen(!filesOpen);
            }}
          >
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`${t('button.view', {
            item: t('resource.routeFtl.plural'),
          })}`}
        >
          <IconButton
            color={ftlOpen ? 'primary' : undefined}
            onClick={() => {
              setFTLOpen(!ftlOpen);
            }}
          >
            <StorefrontIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={`${t('button.view', {
            item: t('attributes.driverInfo'),
          })}`}
        >
          <IconButton
            color={driverInfoOpen ? 'primary' : undefined}
            onClick={() => {
              setDriverInfoOpen(!driverInfoOpen);
            }}
          >
            <DriverInfoCarIcon />
          </IconButton>
        </Tooltip>
        {!readOnly && (
          <Button
            color="inherit"
            variant="contained"
            onClick={() => {
              if (totalRoutes > 1) {
                arrayHelpers.remove(index);
                //
                const allRoutes = getFieldProps(`routes`).value;
                const isDividePrice = getFieldProps(
                  `isPriceEquallyDivided`,
                ).value;
                if (isDividePrice && allRoutes && allRoutes.length > 0) {
                  const totalRoutes = allRoutes.length - 1;
                  const actualPrice = getFieldProps(`price`).value;
                  const calculatedPrice = actualPrice / totalRoutes;
                  const calculatedPercentage = 100 / totalRoutes;

                  for (let i = 0; i < totalRoutes; i++) {
                    setFieldValue(
                      `routes.${i}.price`,
                      Math.round(calculatedPrice * 100) / 100,
                    );
                    setFieldValue(
                      `routes.${i}.pricePercentage`,
                      Math.round(calculatedPercentage * 100) / 100,
                    );
                  }
                }
                setTotalRoutes(totalRoutes - 1);
                setTab(Math.max(index - 1, 0));
              } else {
                setOpenCanNotDeleteDialog(true);
              }
            }}
            endIcon={<DeleteIcon color="action" />}
          >
            {t('button.delete', {
              item: t('resource.route.lowercased'),
            })}
          </Button>
        )}
      </Box>
    </>
  );
}
