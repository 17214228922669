import { isBefore, isEqual, set } from 'date-fns';
import { Maybe } from '../generated/graphql';
import {
  addDaysToDateString,
  getTimeFromDateString,
  parseDateString,
  subDaysToDateString,
} from './date_time';

// ===========================================================================
// ===========================================================================
// IMPORTANT: Any changes to this file should be updated in route_leg_dates.ts
// in client
// ===========================================================================
// ===========================================================================

export const BREAK_POINT_HOUR = 4;

interface Leg {
  arrivalTime?: Maybe<string>;
  transportationDateOffset: number;
  position: number;
}

type WithRouteLegDates<T> = T & {
  productionDate: string;
  transportationDate: string;
};

export function calculateTransportationAndProductionDates<T extends Leg>(
  legs: T[],
  transportationDateString: string,
): WithRouteLegDates<T>[] {
  const sortedLegs = [...legs].sort((a, b) => a.position - b.position);

  return sortedLegs.reduce((newLegs, leg, index) => {
    const previousLeg = index !== 0 ? newLegs[index - 1] : undefined;

    if (leg.arrivalTime == null) {
      return [
        ...newLegs,
        {
          ...leg,
          productionDate: transportationDateString,
          transportationDate: transportationDateString,
        },
      ];
    }

    if (previousLeg?.arrivalTime == null) {
      return [
        ...newLegs,
        {
          ...leg,
          productionDate: determineProductionDate(
            transportationDateString,
            leg.arrivalTime,
          ),
          transportationDate: transportationDateString,
        },
      ];
    }

    // Transportation date
    const legTransportationDateString = addDaysToDateString(
      determineTransportationDate(
        index === 0
          ? transportationDateString
          : newLegs[index - 1].transportationDate,
        leg.arrivalTime,
        previousLeg?.arrivalTime,
      ),
      leg.transportationDateOffset,
    );

    //Production date
    const legProductionDate = determineProductionDate(
      legTransportationDateString,
      leg.arrivalTime,
    );

    return [
      ...newLegs,
      {
        ...leg,
        productionDate: legProductionDate,
        transportationDate: legTransportationDateString,
      },
    ];
  }, [] as WithRouteLegDates<T>[]);
}

function determineProductionDate(
  dateString: string,
  timeString: string,
): string {
  const date = parseDateString(dateString);
  const time = getTimeFromDateString(date, timeString);

  const breakPoint = set(date, { hours: BREAK_POINT_HOUR, minutes: 0 });

  if (isBefore(time, breakPoint)) {
    return subDaysToDateString(dateString, 1);
  }
  return dateString;
}

function determineTransportationDate(
  dateString: string,
  currentTimeString: string,
  previousTimeString?: string,
): string {
  const date = parseDateString(dateString);
  const time = getTimeFromDateString(date, currentTimeString);

  if (previousTimeString != null) {
    const previousTime = getTimeFromDateString(date, previousTimeString);
    if (isBefore(time, previousTime) || isEqual(time, previousTime)) {
      return addDaysToDateString(dateString, 1);
    }
  }
  return dateString;
}
