import {
  Box,
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonDateField } from '../components/form/CommonDateField';
import { SelectLocation } from '../components/form/SelectLocation';
import { SelectSubcontractor } from '../components/form/SelectSubcontractor';
import { HelmetComponent } from '../components/HelmetComponent';
import { useHttpClient } from '../providers/HttpClientProvider';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  field: {
    width: '80%',
    margin: theme.spacing(2),
  },
  download: {
    '& > *': {
      marginBottom: theme.spacing(2),
    },
  },
}));

interface CMRSheetValues {
  senderLocationId?: number;
  receiverLocationId?: number;
  subcontractorId?: number;
  subcontractorTwoId?: number;
  deliveryDate?: string;
}

export function ManualCMR() {
  const { t } = useTranslation();
  const classes = useStyle();
  const { httpClient } = useHttpClient();
  const [downloaded, setDownloaded] = useState(false);

  const initialValues = {
    senderLocationId: undefined,
    receiverLocationId: undefined,
    subcontractorId: undefined,
    subcontractorTwoId: undefined,
    deliveryDate: undefined,
  };

  const onSubmit = async (values: CMRSheetValues) => {
    setDownloaded(true);
    const response = await httpClient.downloadCMRBlob({
      senderLocationId:
        values.senderLocationId != null ? values.senderLocationId : undefined,
      receiverLocationId:
        values.receiverLocationId != null
          ? values.receiverLocationId
          : undefined,
      subcontractorId:
        values.subcontractorId != null ? values.subcontractorId : undefined,
      subcontractorTwoId:
        values.subcontractorTwoId != null
          ? values.subcontractorTwoId
          : undefined,
      deliveryDate: values.deliveryDate,
    });

    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', 'CMR');
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Grid container spacing={4} style={{ marginTop: 3 }}>
      <HelmetComponent title={t('resource.cmr.capitalized')} />
      <Grid item xs={12}>
        <Typography variant="h1">{t('pages.cmr.title')}</Typography>
      </Grid>
      <Grid item xs={3} />
      <Grid item xs={6}>
        {downloaded === false ? (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm({});
            }}
          >
            {() => (
              <Form>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <SelectLocation
                    name="senderLocationId"
                    className={classes.field}
                    fullWidth
                  />
                  <SelectLocation
                    name="receiverLocationId"
                    className={classes.field}
                    fullWidth
                  />
                  <SelectSubcontractor
                    name="subcontractorId"
                    fullWidth
                    className={classes.field}
                  />
                  <SelectSubcontractor
                    name="subcontractorTwoId"
                    fullWidth
                    className={classes.field}
                  />
                  <CommonDateField
                    name="deliveryDate"
                    width={420}
                    variant="outlined"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                  >
                    {t('button.download', {
                      item: t('resource.cmr.capitalized'),
                    })}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box className={classes.download}>
            <Typography>CMR should start dowloading</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setDownloaded(false)}
            >
              Go back
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
