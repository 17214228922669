import { format } from 'date-fns';
import { FileItem } from '../../components/FileListComponent';
import { LoadingListItemInterface } from '../../components/LoadingList';
import {
  Days,
  Department,
  FuelType,
  GetTourTemplateQuery,
  Maybe,
  TourRouteType,
} from '../../generated/graphql';
import { CURRENCIES } from '../constants';
import { calculatePercentage } from '../percentage';
import { newCostAllocationItem } from './costAllocation';
import { newLeg } from './leg';

export function newTourRoute(departments: Pick<Department, 'id'>[]): {
  days: {
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
    sun: boolean;
  };
  note: string;
  externalNote: string;
  routeId: string;
  files: FileItem[];
  price?: Maybe<number>;
  currency: keyof typeof CURRENCIES;
  costAllocations: ReturnType<typeof newCostAllocationItem>[];
  isCopyDriverPlateInfo: boolean;
  startDate: string;
  endDate?: Maybe<string>;
  startExceptionDate?: Maybe<string>;
  endExceptionDate?: Maybe<string>;
  hasExceptionDates: boolean;
  exceptionDates?: string[];
  routeDateType?: Maybe<string>;
  tourRouteType: TourRouteType;
  legs: ReturnType<typeof newLeg>;
} {
  return {
    days: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    startDate: format(new Date(), 'yyyy-MM-dd'),
    isCopyDriverPlateInfo: true,
    routeId: '',
    note: '',
    externalNote: '',
    files: [],
    price: undefined,
    currency: 'sek',
    hasExceptionDates: false,
    costAllocations: [newCostAllocationItem(departments)],
    routeDateType: 'dr',
    tourRouteType: TourRouteType.Old,
    legs: newLeg(''),
  };
}

export function copyTourRoutesArray(
  departments: Pick<Department, 'id'>[],
  copyTourRoutes: GetTourTemplateQuery['tourTemplate']['routes'],
): [
  {
    days: {
      mon: boolean;
      tue: boolean;
      wed: boolean;
      thu: boolean;
      fri: boolean;
      sat: boolean;
      sun: boolean;
    };
    note: string;
    externalNote: string;
    routeId: string;
    files: FileItem[];
    ftl?: {
      cdc: string;
      cmr: string;
      price: number;
      currency: string;
      customerId?: Maybe<number>;
      customerNumber: string;
      customerReference: string;
      invoiceNote: string;
    };
    price?: Maybe<number>;
    currency: keyof typeof CURRENCIES;
    costAllocations: ReturnType<typeof newCostAllocationItem>[];
    routeDriverName?: Maybe<string>;
    routeDriverPhoneNumber?: Maybe<string>;
    routeCarRegNumber?: Maybe<string>;
    routeTrailerRegNumber?: Maybe<string>;
    isCopyDriverPlateInfo: boolean;
    capacity?: Maybe<number>;
    kilometer?: Maybe<number>;
    weight?: Maybe<number>;
    startDate: string;
    endDate?: Maybe<string>;
    startExceptionDate?: Maybe<string>;
    endExceptionDate?: Maybe<string>;
    hasExceptionDates: boolean;
    exceptionDates?: string[];
    routeDateType?: Maybe<string>;
    tourRouteType: TourRouteType;
    legs: [
      {
        id?: number;
        fuel: FuelType;
        key: string;
        gateNumber?: string;
        carRegistrationNumber?: string;
        trailerRegistrationNumber?: string;
        routeDriverName?: Maybe<string>;
        routeDriverPhoneNumber?: Maybe<string>;
        load: boolean;
        unload: boolean;
        note?: string;
        arrivalTime?: Maybe<string>;
        departureTime?: Maybe<string>;
        position: number;
        locationId?: number;
        loadingListItems: LoadingListItemInterface[];
        productionDate: string;
        transportationDate: string;
        transportationDateOffset: number;
      },
    ];
  },
] {
  if (copyTourRoutes?.length) {
    let resArray:
      | [
          {
            days: {
              mon: boolean;
              tue: boolean;
              wed: boolean;
              thu: boolean;
              fri: boolean;
              sat: boolean;
              sun: boolean;
            };
            note: string;
            externalNote: string;
            routeId: string;
            files: FileItem[];
            price?: Maybe<number>;
            currency: keyof typeof CURRENCIES;
            costAllocations: ReturnType<typeof newCostAllocationItem>[];
            routeDriverName?: Maybe<string>;
            routeDriverPhoneNumber?: Maybe<string>;
            routeCarRegNumber?: Maybe<string>;
            routeTrailerRegNumber?: Maybe<string>;
            isCopyDriverPlateInfo: boolean;
            capacity?: Maybe<number>;
            kilometer?: Maybe<number>;
            weight?: Maybe<number>;
            startDate: string;
            endDate?: Maybe<string>;
            startExceptionDate?: Maybe<string>;
            endExceptionDate?: Maybe<string>;
            exceptionDates?: string[];
            routeDateType?: Maybe<string>;
            tourRouteType: TourRouteType;
            legs: [
              {
                id?: number;
                fuel: FuelType;
                key: string;
                gateNumber?: string;
                carRegistrationNumber?: string;
                trailerRegistrationNumber?: string;
                routeDriverName?: Maybe<string>;
                routeDriverPhoneNumber?: Maybe<string>;
                load: boolean;
                unload: boolean;
                note?: string;
                arrivalTime?: Maybe<string>;
                departureTime?: Maybe<string>;
                position: number;
                locationId?: number;
                loadingListItems: LoadingListItemInterface[];
                productionDate: string;
                transportationDate: string;
                transportationDateOffset: number;
              },
            ];
          },
        ]
      | undefined = undefined;
    copyTourRoutes.forEach((copyTourRoute) => {
      const copyTour = {
        days: {
          mon: copyTourRoute.days.includes(Days.Mon),
          tue: copyTourRoute.days.includes(Days.Tue),
          wed: copyTourRoute.days.includes(Days.Wed),
          thu: copyTourRoute.days.includes(Days.Thu),
          fri: copyTourRoute.days.includes(Days.Fri),
          sat: copyTourRoute.days.includes(Days.Sat),
          sun: copyTourRoute.days.includes(Days.Sun),
        },
        routeId: copyTourRoute.routeId ?? '',
        note: copyTourRoute.note ?? '',
        externalNote: copyTourRoute.externalNote ?? '',
        files: copyTourRoute.files ?? [],
        price: copyTourRoute.price,
        currency: 'sek' as keyof typeof CURRENCIES,
        costAllocations: copyTourRoute.costAllocations.map((ca) => {
          return {
            ...ca,
            percentage: calculatePercentage(copyTourRoute.price, ca.cost),
          };
        }),
        routeDriverName: copyTourRoute.routeDriverName,
        routeDriverPhoneNumber: copyTourRoute.routeDriverPhoneNumber,
        routeCarRegNumber: copyTourRoute.routeCarRegNumber,
        routeTrailerRegNumber: copyTourRoute.routeTrailerRegNumber,
        isCopyDriverPlateInfo: copyTourRoute.isCopyDriverPlateInfo,
        capacity: copyTourRoute.capacity,
        kilometer: copyTourRoute.kilometer,
        weight: copyTourRoute.weight,
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: undefined,
        startExceptionDate: undefined,
        endExceptionDate: undefined,
        hasExceptionDates: false,
        exceptionDates: undefined,
        routeDateType: copyTourRoute.routeDateType,
        tourRouteType: TourRouteType.Old,
        legs: newLeg('', copyTourRoute.legs),
      };
      if (!resArray) {
        resArray = [copyTour];
      } else {
        resArray.push(copyTour);
      }
    });
    if (resArray) {
      return resArray;
    }
  }

  return [
    {
      days: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      routeId: '',
      note: '',
      externalNote: '',
      files: [],
      price: undefined,
      currency: 'sek',
      costAllocations: [newCostAllocationItem(departments)],
      isCopyDriverPlateInfo: false,
      hasExceptionDates: false,
      startDate: format(new Date(), 'yyyy-MM-dd'),
      tourRouteType: TourRouteType.Old,
      legs: newLeg(''),
    },
  ];
}
