import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/styles';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '../lib/constants';

interface SelectCurrencyProps {
  fieldName: string;
  readOnly?: boolean;
  classes?: ClassNameMap<'field'>;
}

export function SelectCurrency(props: SelectCurrencyProps) {
  const { fieldName, readOnly = false, classes = { field: '' } } = props;
  const { t } = useTranslation();
  const addHandler = (keypressed: string) => {
    for (const [key, value] of Object.entries(CURRENCIES)) {
      if (value.startsWith(keypressed.toUpperCase(), 0)) {
        return key;
      }
    }
  };
  return (
    <Field name={fieldName}>
      {({ field, form }: FieldProps) => (
        <FormControl
          fullWidth
          variant="outlined"
          className={classes?.field}
          size="small"
        >
          <InputLabel shrink id="ftl-currency">
            {t('attributes.currency')}
          </InputLabel>
          <Select
            id="ftl-currency"
            value={field && field.value ? field.value : ' '}
            labelId="ftl-currency"
            label={t('attributes.currency')}
            readOnly={readOnly}
            inputProps={{ readOnly: readOnly }}
            IconComponent={(props) => (
              <ArrowDropDown
                {...props}
                style={{
                  display: readOnly ? 'none' : 'inline-block',
                }}
              />
            )}
            onKeyPress={(ev) => {
              if (!readOnly && typeof ev.key === 'string') {
                const retKey = addHandler(ev.key);
                form.setFieldValue(fieldName, retKey);
              }
            }}
            onChange={(ev) => {
              if (!readOnly && ev && typeof ev.target.value === 'string') {
                form.setFieldValue(fieldName, ev.target.value);
              }
            }}
          >
            {Object.entries(CURRENCIES).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  );
}
