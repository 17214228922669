import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HelmetComponent } from '../components/HelmetComponent';
import {
  SporadicRouteTemplateForm,
  SporadicRouteTemplateFormInput,
} from '../components/SporadicRouteTemplateForm';
import {
  SporadicRouteTemplatesDocument,
  useCreateSporadicRouteTemplateMutation,
} from '../generated/graphql';
import { timeOrNull } from '../lib/date_time';
import { newLeg } from '../lib/formHelpers/leg';
import { numberOrNull } from '../lib/formHelpers/number';

export function CreateSporadicRouteTemplate() {
  const router = useHistory();
  const { t } = useTranslation();

  const [createSporadicRouteTemplate] = useCreateSporadicRouteTemplateMutation({
    refetchQueries: [
      {
        query: SporadicRouteTemplatesDocument,
      },
    ],
  });

  const onSubmit = async (
    val: Omit<
      SporadicRouteTemplateFormInput,
      'dispatcherId' | 'subcontractorId'
    > & {
      dispatcherId?: number;
      subcontractorId?: number;
    },
  ) => {
    const values = val as SporadicRouteTemplateFormInput;
    await createSporadicRouteTemplate({
      variables: {
        SporadicRouteTemplateInput: {
          name: values.name,
          agreedPrice: values.agreedPrice,
          currency: values.currency,
          note: values.note ?? '',
          externalNote: values.externalNote ?? '',
          driverName: values.driverName,
          driverPhoneNumber: values.driverPhoneNumber,
          licensePlate: values.licensePlate,
          capacity: numberOrNull(values.capacity),
          kilometer: numberOrNull(values.kilometer),
          weight: numberOrNull(values.weight),
          routeId: values.routeId,
          dispatcherId: values?.dispatcherId,
          subcontractorId: values?.subcontractorId,
          files: values.files.map((file) => ({
            id: file.id,
          })),
          ftl: values.ftl || undefined,
          legs: values.legs.map((leg, index) => ({
            load: leg.load,
            unload: leg.unload,
            note: leg.note ?? '',
            fuel: leg.fuel,
            gateNumber: leg.gateNumber ?? '',
            carRegistrationNumber: leg.carRegistrationNumber ?? '',
            trailerRegistrationNumber: leg.trailerRegistrationNumber ?? '',
            routeDriverName: leg.routeDriverName ?? '',
            routeDriverPhoneNumber: leg.routeDriverPhoneNumber ?? '',
            arrivalTime: leg.arrivalTime ?? '00:00',
            departureTime: timeOrNull(leg.departureTime),
            position: index,
            locationId: leg.locationId as number,
            location: undefined,
            transportationDateOffset: leg.transportationDateOffset,
            loadingListItems: leg.load
              ? leg.loadingListItems.map((loadingListItem) => ({
                  ...loadingListItem,
                  locationId: loadingListItem.locationId as number,
                  packages: numberOrNull(loadingListItem.packages),
                  pallets: numberOrNull(loadingListItem.pallets),
                  palletSpace: numberOrNull(loadingListItem.palletSpace),
                  weight: numberOrNull(loadingListItem.weight),
                }))
              : [],
          })),
        },
      },
    });
    router.push('/traffic/sporadic-routes');
  };

  const initialValues = (): Omit<
    SporadicRouteTemplateFormInput,
    'dispatcherId' | 'subcontractorId'
  > & {
    dispatcherId?: number;
    subcontractorId?: number;
  } => ({
    name: '',
    agreedPrice: 0,
    currency: 'dkk',
    note: '',
    externalNote: '',
    driverName: '',
    driverPhoneNumber: '',
    licensePlate: '',
    capacity: undefined,
    routeId: '',
    dispatcherId: undefined,
    subcontractorId: undefined,
    files: [],
    ftl: undefined,
    legs: newLeg(''),
  });

  return (
    <Grid container spacing={5} direction="column">
      <HelmetComponent
        title={`${t('actions.create.capitalized')} ${t(
          'resource.sporadicRoute.capitalized',
        )} ${t('resource.template.lowercased')}`}
      />
      <Grid item>
        <Typography variant="h1">
          {`${t('resource.sporadicRoute.capitalized')} ${t(
            'resource.template.lowercased',
          )}`}
        </Typography>
      </Grid>
      <Grid item>
        <SporadicRouteTemplateForm
          onSubmit={onSubmit}
          initialValues={initialValues()}
          submitButtonLabel={t('button.create', {
            item: `${t('resource.sporadicRoute.capitalized')} ${t(
              'resource.template.lowercased',
            )}`,
          })}
        />
      </Grid>
    </Grid>
  );
}
