import {
  Button,
  createStyles,
  Grid,
  List,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { CheckListRoutesFormInput } from '../views/CheckListRoutesTraffic';
import { LoadOrderCLRHF } from './LoadOrderCLRHF';

interface LoadingListProps {
  load: boolean;
  readOnly: boolean;
  legIndex: number;
  routeIndex: number;
  newRow?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    greenChecked: {
      backgroundColor: '#d3f8d3',
    },
    redBorder: {
      border: '1px solid red',
    },
    greyBackFocused: {
      backgroundColor: '#f1f5eb',
      paddingTop: 2,
      paddingBottom: 2,
    },
    root: {
      margin: 0,
      padding: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
    noPadding: {
      padding: '0 !important',
    },
    deleteButton: {
      padding: '0 !important',
      marginRight: 1,
    },
    listItem: {
      '&.MuiListItem': {
        padding: 0,
      },
      '&.MuiListItem-gutters': {
        padding: 0,
      },
      '.MuiListItem-root': {
        padding: 0,
      },
    },
    numberFields: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
    selecLocationField: {
      width: '98%',
      fontSize: '10px',
      margin: 0,
      padding: 0,
    },
    field: {
      width: '95%',
      fontSize: '10px',
      margin: 0,
    },
  }),
);

export function LoadingListCLRHF(props: LoadingListProps) {
  const { load, readOnly, legIndex, routeIndex } = props;
  const { t } = useTranslation();

  const { control } = useFormContext<CheckListRoutesFormInput>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `routes.${routeIndex}.legs.${legIndex}.loadingListItems`,
  });
  const classes = useStyles();

  if (!load) {
    return <>Loading...</>;
  }
  return (
    <>
      <Grid item xs={12} spacing={2} container className={classes.root}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" align="left">
            {t('resource.loadingList.capitalized')}
          </Typography>
        </Grid>
        <Grid item xs={4}></Grid>
        {!readOnly && (
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  append({
                    id: undefined,
                    packages: '',
                    pallets: '',
                    palletSpace: '',
                    weight: '',
                    note: '',
                    locationId: 0,
                    checked: false,
                    isDangerous: false,
                  });
                }}
              >
                {t('button.add', { item: t('order') })}
              </Button>
            </Box>
          </Grid>
        )}
        {fields != undefined && fields.length > 0 && (
          <Grid xs={11} item container className={classes.noPadding}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textPrimary">
                {'Customer'}
              </Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography variant="body2" color="textPrimary">
                {'Packages'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body2" color="textPrimary">
                {'Pallets'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body2" color="textPrimary">
                {'PPL'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body2" color="textPrimary">
                {'Weight'}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant="body2" color="textPrimary">
                {'Note'}
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <List className={classes.noPadding}>
            {fields.map((_order, index) => (
              <ListItem key={_order.id} style={{ padding: 0 }}>
                <LoadOrderCLRHF
                  key={`ID${_order.id}`}
                  routeIndex={routeIndex}
                  legIndex={legIndex}
                  orderIndex={index}
                  removeOrder={(i) => remove(i)}
                  readOnly={readOnly}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
