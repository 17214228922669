import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FrequencyDays } from '../generated/graphql';

interface ValueBoxDaysProps {
  daysArray: FrequencyDays[] | undefined;
  isReadOnly: boolean;
  onDaysChanged?: (updatedDays: FrequencyDays[]) => void;
}

const useStyles = makeStyles(() => ({
  checkBox: {
    paddingRight: 2,
    paddingLeft: 4,
    paddingTop: 6,
    paddingBottom: 6,
  },
}));

export function ValueBoxDays({
  daysArray,
  isReadOnly,
  onDaysChanged,
}: ValueBoxDaysProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [latestArr, setLatestArr] = useState<FrequencyDays[]>(
    daysArray ? daysArray : [],
  );

  const getFreqEnumValue = (d: string): FrequencyDays => {
    switch (d) {
      case 'mon':
        return FrequencyDays.Mon;
      case 'tue':
        return FrequencyDays.Tue;
      case 'wed':
        return FrequencyDays.Wed;
      case 'thu':
        return FrequencyDays.Thu;
      case 'fri':
        return FrequencyDays.Fri;
      case 'sat':
        return FrequencyDays.Sat;
      case 'sun':
        return FrequencyDays.Sun;
    }
    return FrequencyDays.Sun;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isReadOnly) {
      if (e.target.checked) {
        setLatestArr([...latestArr, getFreqEnumValue(e.target.name)]);
      } else {
        setLatestArr(
          latestArr.filter((d) => d !== getFreqEnumValue(e.target.name)),
        );
      }
    }
  };

  useEffect(() => {
    if (latestArr && !isReadOnly) if (onDaysChanged) onDaysChanged(latestArr);
  }, [latestArr]);

  return (
    <FormGroup>
      <Box
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.mon')}
            </Typography>
          }
          control={
            <Checkbox
              name={'mon'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Mon')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />

        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.tue')}
            </Typography>
          }
          control={
            <Checkbox
              name={'tue'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Tue')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.wed')}
            </Typography>
          }
          control={
            <Checkbox
              name={'wed'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Wed')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.thu')}
            </Typography>
          }
          control={
            <Checkbox
              name={'thu'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Thu')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.fri')}
            </Typography>
          }
          control={
            <Checkbox
              name={'fri'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Fri')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.sat')}
            </Typography>
          }
          control={
            <Checkbox
              name={'sat'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Sat')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />
        <FormControlLabel
          label={
            <Typography style={{ fontSize: 12 }}>
              {t('weekDays.sun')}
            </Typography>
          }
          control={
            <Checkbox
              name={'sun'}
              size="small"
              disabled={isReadOnly}
              checked={latestArr.some((d) => d === 'Sun')}
              onChange={handleChange}
              className={classes.checkBox}
            />
          }
        />
      </Box>
    </FormGroup>
  );
}
