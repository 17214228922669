import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
  Divider,
  Checkbox,
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Form, Formik, FieldArray } from 'formik';
import { useAddMasterGridMutation } from '../generated/graphql';
import { CommonSimpleTextField } from './form/CommonSimpleTextField';
import { useTranslation } from 'react-i18next';

interface GridFunctionModalProps {
  handleClose: () => void;
  open: boolean;
  internalTerminalId: number;
  terminalName: string;
  gridType: string;
}
const useStyles = makeStyles({
  input: {
    width: '70%',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #585858',
    margin: 8,
    justifyContent: 'flex-start',
    flexGrow: 1,
  },
  rowContainer: {
    display: 'flex',
    border: '1px solid #585858',
    margin: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  inputVal: {
    height: 10,
    fontSize: 14,
  },
  inputValTime: {
    height: 10,
    width: 50,
    fontSize: 14,
  },
});

export function GridFunctionForTerminal(props: GridFunctionModalProps) {
  const { t } = useTranslation();
  const { open, internalTerminalId, handleClose, gridType } = props;
  const [rowValue, setRowValue] = useState<string>('');
  const [colValue, setColValue] = useState<string>('');
  const [gridName, setGridName] = useState<string>('');
  const classes = useStyles();
  const [showGrid, setShowGrid] = useState<boolean>(open);
  const [addHeader, setAddHeader] = useState<boolean>(false);
  const [columnError, setColumnError] = useState<boolean>(false);
  const [displayTotalRow, setDisplayTotalRow] = useState<boolean>(false);
  const [headerErr, setHeaderErr] = useState<boolean>(false);

  const [addMasterGridApi] = useAddMasterGridMutation();

  const numberOfRows = +rowValue;
  const numberOfCol = +colValue;
  const rowHeaderArr: string[] = [];
  const colHeaderArr: string[] = [];
  {
    for (let i = 0; i < numberOfRows; i++) {
      rowHeaderArr.push('');
    }
  }
  {
    for (let i = 0; i < numberOfCol; i++) {
      colHeaderArr.push('');
    }
  }

  return (
    <>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h6">{`${t(
            'resource.grid.capitalized',
          )}`}</Typography>
          <IconButton style={{ marginLeft: 8 }} onClick={handleClose}>
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          sx={{ margin: 2 }}
        >
          <Checkbox
            style={{ padding: 0, marginRight: 4, color: '#68bc46' }}
            checked={displayTotalRow}
            size="small"
            onChange={(_, checked) => setDisplayTotalRow(checked)}
          />
          <Typography style={{ fontSize: 14 }}>{`${t(
            'attributes.numbersGrid',
          )}`}</Typography>
        </Box>
        {headerErr && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="start"
            sx={{ margin: 2 }}
          >
            <Typography style={{ color: 'orangered' }}>{`${t(
              'validation.wrongGridHeader',
            )}`}</Typography>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          sx={{ margin: 5 }}
        >
          {showGrid && (
            <TextField
              style={{ margin: 2 }}
              id="gridName"
              name="gridName"
              size="small"
              autoComplete="off"
              label={t('attributes.gridNameLabel')}
              variant="outlined"
              InputProps={{
                classes: { input: classes.inputVal },
              }}
              inputProps={{ maxLength: 15, style: { width: 175 } }}
              value={gridName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (e.target && e.target instanceof HTMLInputElement) {
                    (e.target as HTMLInputElement).blur();
                  }
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (
                  e.target &&
                  e.target.value !== null &&
                  e.target.value !== undefined
                ) {
                  setGridName(e.target.value);
                }
              }}
            />
          )}
          {showGrid && (
            <TextField
              style={{ margin: 2 }}
              id="row"
              name="row"
              size="small"
              autoComplete="off"
              label={t('attributes.gridRowsLabel')}
              variant="outlined"
              InputProps={{
                classes: { input: classes.inputVal },
              }}
              inputProps={{ maxLength: 2 }}
              value={rowValue}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (e.target && e.target instanceof HTMLInputElement) {
                    (e.target as HTMLInputElement).blur();
                  }
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (
                  e.target &&
                  e.target.value !== null &&
                  e.target.value !== undefined
                ) {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, '');
                  setRowValue(onlyNums);
                }
              }}
            />
          )}
          {showGrid && (
            <TextField
              style={{ margin: 2 }}
              id="column"
              name="column"
              size="small"
              autoComplete="off"
              label={t('attributes.gridColumnsLabel')}
              error={columnError}
              variant="outlined"
              inputProps={{ maxLength: 2, style: { width: 175 } }}
              InputProps={{
                classes: { input: classes.inputVal },
              }}
              value={colValue}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if (e.target && e.target instanceof HTMLInputElement) {
                    (e.target as HTMLInputElement).blur();
                  }
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                if (
                  e.target &&
                  e.target.value !== null &&
                  e.target.value !== undefined &&
                  +e.target.value < 11
                ) {
                  setColumnError(false);
                  e.target.value.replace(/[^0-9]/g, '');
                  setColValue(e.target.value);
                } else {
                  setColumnError(true);
                }
              }}
            />
          )}
          {showGrid &&
            ((!displayTotalRow && numberOfRows > 0) ||
              (displayTotalRow && numberOfRows > 1)) &&
            numberOfCol != 0 &&
            gridName !== '' && (
              <>
                <Button
                  style={{ margin: 2 }}
                  variant={!addHeader ? 'contained' : 'outlined'}
                  color="primary"
                  onClick={() => {
                    setAddHeader(!addHeader);
                    setShowGrid(false);
                  }}
                >
                  {t('button.create', { item: 'Grid' })}
                </Button>
                <Button
                  style={{ margin: 2 }}
                  variant="contained"
                  onClick={handleClose}
                >
                  {t('button.cancel')}
                </Button>
              </>
            )}
        </Box>
        {addHeader && (
          <Formik
            initialValues={{
              rowHeaders: rowHeaderArr ? rowHeaderArr : [],
              columnHeaders: colHeaderArr ? colHeaderArr : [],
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              //check for TOTAL name
              if (
                displayTotalRow &&
                values.rowHeaders &&
                (values.rowHeaders.includes('Total') ||
                  values.rowHeaders.includes('total') ||
                  values.rowHeaders.includes('TOTAL'))
              ) {
                setHeaderErr(true);
                return;
              }
              setHeaderErr(false);

              addMasterGridApi({
                variables: {
                  gridData: {
                    internalTerminalId: internalTerminalId,
                    rowHeaders: values.rowHeaders,
                    columnHeaders: values.columnHeaders,
                    gridName: gridName,
                    gridType: gridType,
                    isNumberGridWithTotal: displayTotalRow,
                  },
                },
              }).then(() => {
                handleClose();
              });
            }}
          >
            {(props) => (
              <Form>
                <Grid container>
                  <Grid item xs={6}>
                    <FieldArray
                      name="rowHeaders"
                      render={() => {
                        return (
                          <>
                            <p>{t('attributes.rowHeader')}</p>
                            {props.values.rowHeaders.map((_, index) => {
                              return (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  sx={{
                                    marginRight: 25,
                                    marginLeft: 50,
                                    marginBottom: 5,
                                  }}
                                >
                                  <CommonSimpleTextField
                                    name={`rowHeaders[${index}]`}
                                    size="small"
                                    noLabel={true}
                                    maxTextLength={15}
                                  />
                                </Box>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <p>{t('attributes.columnHeader')}</p>

                    <FieldArray
                      name="columnHeaders"
                      render={() => {
                        return (
                          <>
                            {props.values.columnHeaders.map((_, index) => {
                              return (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="space-between"
                                  sx={{
                                    marginRight: 50,
                                    marginLeft: 25,
                                    marginBottom: 5,
                                  }}
                                >
                                  <CommonSimpleTextField
                                    name={`columnHeaders[${index}]`}
                                    size="small"
                                    noLabel={true}
                                    maxTextLength={10}
                                  />
                                </Box>
                              );
                            })}
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Button type="submit" color="primary" variant="contained">
                  {t('button.save')}
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Box>
      <Divider style={{ backgroundColor: 'green' }} />
    </>
  );
}
