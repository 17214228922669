import { Grid, makeStyles, Snackbar, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ImportShipmentFormInput } from './ImportShipment';
import { ImportShipmentsListRHF } from '../components/ImportShipmentsListRHF';

const useStyles = makeStyles((theme: Theme) => ({
  selectDateRoot: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
    '& form > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
  },
  filterButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
  rootGrid: {
    marginTop: 2,
  },
}));

interface ImportShipmentFormProps {
  initialValues: ImportShipmentFormInput;
  onSubmit: (val: ImportShipmentFormInput) => void;
  shipmentUpdated: () => void;
}
export function ImportShipmentFormRHF(props: ImportShipmentFormProps) {
  const { t } = useTranslation();
  const [validationAlert, setValidationAlert] = useState<boolean>(false);
  const { initialValues, onSubmit, shipmentUpdated } = props;
  const classes = useStyles();

  const allFormMethods = useForm<ImportShipmentFormInput>({
    defaultValues: initialValues,
    values: initialValues,
  });

  const { handleSubmit } = allFormMethods;

  return (
    <Grid item xs={12} className={classes.rootGrid}>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={validationAlert}
          key={'alertError'}
          autoHideDuration={3000}
          onClose={() => {
            setValidationAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setValidationAlert(false);
            }}
            severity="error"
          >
            {`${t('attributes.checkValidation')}`}
          </Alert>
        </Snackbar>
      </div>

      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore */}
      <FormProvider {...allFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ImportShipmentsListRHF
            readOnly={true}
            shipmentUpdated={() => shipmentUpdated()}
          />
        </form>
      </FormProvider>
    </Grid>
  );
}
