import Button from '@material-ui/core/Button';
import { HttpClient } from '../lib/httpClient';
import { useHttpClient } from '../providers/HttpClientProvider';

interface DownloadPeakPlanningListProps {
  startDate: string;
  endDate: string;
}

export function DownloadPeakPlanningList(props: DownloadPeakPlanningListProps) {
  const { startDate, endDate } = props;
  const { httpClient } = useHttpClient();

  return (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      onClick={() => {
        getPeakPlanningList(httpClient, startDate, endDate);
      }}
    >
      Export to Exel
    </Button>
  );
}

function getPeakPlanningList(
  httpClient: HttpClient,
  startDate: string,
  endDate: string,
) {
  httpClient.downloadPeakPlanningList({ startDate, endDate }).then((res) => {
    const blob = res.data;
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', `PeakPlanningList`);
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
}
