import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Field, FieldProps, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetContactsForCardQuery,
  useGetContactsForCardLazyQuery,
} from '../../generated/graphql';
import { AddContactModal } from '../modal/AddContactModal';

interface SelectContactProps {
  name: string;
  cardId?: number;
  label: string;
  readOnly?: boolean;
}
const useStyles = makeStyles({
  addContactForCardButton: {
    marginTop: '10px',
  },
});

export function SelectContactFromCard(props: SelectContactProps & SelectProps) {
  const classes = useStyles(props);
  const { name, cardId, label, readOnly = false } = props;
  const [options, setOptions] = useState<
    GetContactsForCardQuery['cardContacts']
  >([]);
  const { getFieldHelpers, getFieldProps, setFieldValue } = useFormikContext();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const [getContactsForCard, { data, refetch }] =
    useGetContactsForCardLazyQuery();

  const contactId = getFieldProps(name).value;

  useEffect(() => {
    if (cardId != null) {
      const newContactId =
        typeof contactId === 'number' ? contactId : undefined;
      getContactsForCard({
        variables: {
          cardId,
          contactId: newContactId,
        },
      });
    } else {
      setOptions([]);
      setFieldValue(name, null);
    }
  }, [cardId]);

  useEffect(() => {
    if (data) {
      setOptions(data.cardContacts);
      if (!data.cardContacts.find(({ id }) => id === contactId))
        getFieldHelpers(name).setValue(data.cardContacts[0]?.id);
    }
  }, [data, cardId]);

  if (readOnly) {
    const contact = data?.cardContacts.find((x) => x.id === contactId);
    return (
      <TextField
        className={props.className}
        variant="outlined"
        label={label}
        size="small"
        value={
          contact
            ? `${contact?.name} - ${contact?.phoneNumber} - ${contact?.email}`
            : ''
        }
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }

  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => (
        <>
          <FormControl
            id="dispatcher"
            fullWidth
            disabled={cardId == null}
            variant="outlined"
            size="small"
            className={props.className}
          >
            <InputLabel id="dispatcher-label">{label}</InputLabel>
            <Select
              {...field}
              value={field.value ? `${field.value}` : ''}
              id="dispatcher"
              labelId="dispatcher-label"
              label={label}
              error={meta.touched && Boolean(meta.error)}
              readOnly={readOnly}
            >
              {options.map((contact) => (
                <MenuItem key={contact.id} value={contact.id}>
                  {`${contact.name} - ${contact.email} - ${contact.phoneNumber}`}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{meta.touched && meta.error}</FormHelperText>
            {cardId && (
              <Grid
                item
                sm={12}
                className={classes.addContactForCardButton}
                container
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  {t('button.create', { item: 'contact' })}
                </Button>
              </Grid>
            )}
          </FormControl>

          {modalOpen && cardId && (
            <AddContactModal
              onCreate={async (contactId: number) => {
                if (cardId != null && refetch != null) {
                  const res = await refetch({
                    cardId,
                  });
                  setOptions(res.data.cardContacts);
                  form.setFieldValue(field.name, contactId);
                }
              }}
              cardId={cardId}
              open={modalOpen}
              setOpen={setModalOpen}
            />
          )}
        </>
      )}
    </Field>
  );
}
