export function normalizeRouteIdForRoute<
  T extends { routeId: string; isCreatedFromTerminal?: boolean },
>(onSubmit: (values: T) => void): (values: T) => void {
  return (values: T) => {
    if (values.isCreatedFromTerminal) {
      onSubmit(values);
    } else {
      const routeId = normalizeRouteId(values.routeId);
      const newValues = {
        ...values,
        routeId,
      };
      onSubmit(newValues);
    }
  };
}

export function normalizeRouteIdForTourRoute<
  T extends { routes: { routeId: string }[] },
>(onSubmit: (values: T) => void): (values: T) => void {
  return (values: T) => {
    const routes = values.routes.map((route) => ({
      ...route,
      routeId: normalizeRouteId(route.routeId),
    }));

    const newValues = {
      ...values,
      routes,
    };

    onSubmit(newValues);
  };
}

function normalizeRouteId(routeId: string): string {
  return routeId.trim().replaceAll('  ', ' ').toUpperCase();
}
