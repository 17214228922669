import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, makeStyles, Snackbar, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ChecklistRoutesListRHF } from '../components/ChecklistRoutesListRHF';
import { CheckListRoutesFormInput } from './CheckListRoutesTraffic';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  selectDateRoot: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'space-between',
    '& form > *': {
      margin: theme.spacing(0, 1),
    },
    alignItems: 'center',
  },
  filterButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  inputValTime: {
    height: 15,
    width: 120,
    fontSize: 15,
  },
  rootGrid: {
    marginTop: 2,
  },
}));

interface CheckListRoutesFormProps {
  initialValues: CheckListRoutesFormInput;
  isUpdateInProgress: boolean;
  onSubmit: (values: CheckListRoutesFormInput) => void;
}
export function CheckListRoutesFormRHF(props: CheckListRoutesFormProps) {
  const { t } = useTranslation();
  const [validationAlert, setValidationAlert] = useState<boolean>(false);
  const { initialValues, onSubmit, isUpdateInProgress } = props;
  const classes = useStyles();

  const allFormMethods = useForm<CheckListRoutesFormInput>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    resolver: yupResolver(
      yup.object({
        routes: yup.array().of(
          yup.object({
            legs: yup.array().of(
              yup.object().shape({
                locationId: yup
                  .number()
                  .min(
                    1,
                    t('validation.isRequired', {
                      name: t('attributes.location'),
                    }),
                  )
                  .required(
                    t('validation.isRequired', {
                      name: t('attributes.location'),
                    }),
                  ),
                arrivalTime: yup
                  .string()
                  .matches(
                    /^\d{2}:\d{2}(:\d{2})?$/,
                    t('validation.timeFormatError'),
                  )
                  .required(
                    t('validation.isRequired', {
                      name: t('attributes.arrivalTime'),
                    }),
                  ),
                departureTime: yup
                  .string()
                  .transform((value) => (value == '' ? null : value))
                  .nullable()
                  .matches(
                    /^\d{2}:\d{2}(:\d{2})?$/,
                    t('validation.timeFormatError'),
                  ),
                note: yup.string().nullable(),
                loadingListItems: yup.array().of(
                  yup.object().shape({
                    palletSpace: yup
                      .number()
                      .transform((value) =>
                        Number.isNaN(value) ? null : value,
                      )
                      .min(
                        0,
                        t('validation.minNumber', {
                          name: t('attributes.palletSpace'),
                          len: '0',
                        }),
                      )
                      .typeError(`${t('validation.required')}`)
                      .required(`${t('validation.required')}`),
                    note: yup.string(),
                    locationId: yup
                      .string()
                      .transform((value) =>
                        value == null || value == '' || value == '0'
                          ? undefined
                          : value,
                      )
                      .required(
                        t('validation.isRequired', {
                          name: t('attributes.customer'),
                        }),
                      ),
                  }),
                ),
              }),
            ),
          }),
        ),
      }),
    ),
    defaultValues: initialValues,
    values: initialValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = allFormMethods;

  useEffect(() => {
    if (errors && errors.routes?.length) {
      setValidationAlert(true);
    }
  }, [errors]);

  return (
    <Grid item xs={12} className={classes.rootGrid}>
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={validationAlert}
          key={'alertError'}
          autoHideDuration={3000}
          onClose={() => {
            setValidationAlert(false);
          }}
        >
          <Alert
            onClose={() => {
              setValidationAlert(false);
            }}
            severity="error"
          >
            {`${t('attributes.checkValidation')}`}
          </Alert>
        </Snackbar>
      </div>
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore */}
      <FormProvider {...allFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ChecklistRoutesListRHF
            readOnly={false}
            isUpdateInProgress={isUpdateInProgress}
          />
        </form>
      </FormProvider>
    </Grid>
  );
}
