import { Box, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CenteredBox } from '../CenteredBox';
import { SelectCurrencyRHF } from '../SelectCurrencyRHF';
import { CommonTextFieldRHF } from './CommonTextFieldRHF';
import { SelectCustomerRHF } from './SelectCustomerRHF';

const useStyles = makeStyles((theme: Theme) => ({
  field: {
    width: '95%',
    margin: theme.spacing(1),
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface RouteFTLFieldsProps {
  readOnly: boolean;
}

export function RouteFTLFieldsRHF(props: RouteFTLFieldsProps) {
  const { readOnly } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Box className={classes.row}>
        <CenteredBox>
          <CommonTextFieldRHF
            name="ftl.cdc"
            controllerName={'ftl.cdc'}
            className={classes.field}
            readOnly={readOnly}
          />
          <CommonTextFieldRHF
            name="ftl.cmr"
            controllerName={'ftl.cmr'}
            className={classes.field}
            readOnly={readOnly}
          />
        </CenteredBox>

        <CenteredBox>
          <SelectCustomerRHF
            name="ftl.customerId"
            controllerName="ftl.customerId"
            variant="outlined"
            label={t('attributes.customerId')}
            readOnly={readOnly}
            fullWidth
          />
        </CenteredBox>
      </Box>
      <Box className={classes.row}>
        <CenteredBox>
          <CommonTextFieldRHF
            name="ftl.price"
            controllerName={'ftl.price'}
            type="number"
            className={classes.field}
            readOnly={readOnly}
          />
          <SelectCurrencyRHF
            controllerName={'ftl.currency'}
            name="ftl.currency"
            classes={classes}
          />
        </CenteredBox>
        <CenteredBox>
          <CommonTextFieldRHF
            name="ftl.customerNumber"
            controllerName={'ftl.customerNumber'}
            className={classes.field}
            readOnly={readOnly}
          />
          <CommonTextFieldRHF
            name="ftl.customerReference"
            controllerName={'ftl.customerReference'}
            className={classes.field}
            readOnly={readOnly}
          />
          <CommonTextFieldRHF
            name="ftl.invoiceNote"
            controllerName={'ftl.invoiceNote'}
            className={classes.field}
            readOnly={readOnly}
          />
        </CenteredBox>
      </Box>
    </Box>
  );
}
