import { TFunction } from 'i18next';
import * as yup from 'yup';
import { CURRENCIES } from '../constants';
import { loadingListItemYupValidation } from './loadingListItem';

export const routeTemplateValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .min(
        3,
        t('validation.atLeastLen', {
          name: t('attributes.name'),
          len: 3,
        }),
      )
      .required(t('validation.isRequired', { name: t('attributes.name') })),
    agreedPrice: yup
      .number()
      .min(0)
      .required(
        t('validation.isRequired', { name: t('attributes.agreedPrice') }),
      ),
    currency: yup.string().oneOf(Object.keys(CURRENCIES)),
    note: yup.string(),
    driverName: yup.string(),
    driverPhoneNumber: yup.string(),
    licensePlate: yup.string(),
    capacity: yup.number().nullable().min(0),
    routeId: yup.string().min(3),
    invoiceNumber: yup.string().optional().nullable(),
    invoiceNote: yup.string().optional().nullable(),
    dispatcherId: yup.number().nullable(),
    subcontractorId: yup.number().nullable(),
    legs: yup
      .array(
        yup.object().shape({
          arrivalTime: yup
            .string()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/)
            .required(
              t('validation.isRequired', { name: t('attributes.arrivalTime') }),
            ),
          departureTime: yup
            .string()
            .nullable()
            .matches(/^\d{2}:\d{2}(:\d{2})?$/),
          locationId: yup.string().required(
            t('validation.isRequired', {
              name: t('attributes.location'),
            }),
          ),
          note: yup.string(),
          load: yup.boolean(),
          unload: yup.boolean(),
          loadingListItems: loadingListItemYupValidation(t),
        }),
      )
      .required(),
    files: yup.array(
      yup.object().shape({
        id: yup.number(),
      }),
    ),
    ftl: yup
      .object({
        cdc: yup.string(),
        cmr: yup.string(),
        currency: yup.string(),
        customerId: yup.number().nullable().min(1),
        customerNumber: yup.string(),
        customerReference: yup.string(),
        invoiceNote: yup.string(),
        price: yup.number().min(1),
      })
      .nullable(),
  });
