import { MenuItem, Select, SelectProps } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  GetContactsForCardQuery,
  useGetContactsForCardLazyQuery,
} from '../../generated/graphql';

interface SelectContactProps {
  onCellChange: (location?: { id: number; name: string } | null) => void;
  initialValue: number | undefined;
  cardId?: number;
  contactId?: number;
}

export function SelectContactCell(props: SelectContactProps & SelectProps) {
  const { onCellChange, cardId, contactId } = props;
  const [options, setOptions] = useState<
    GetContactsForCardQuery['cardContacts']
  >([]);

  const [getContactsForCard, { data }] = useGetContactsForCardLazyQuery();

  useEffect(() => {
    if (cardId != null) {
      const newContactId =
        typeof contactId === 'number' ? contactId : undefined;
      getContactsForCard({
        variables: {
          cardId,
          contactId: newContactId,
        },
      });
    }
  }, [cardId]);

  useEffect(() => {
    if (data) {
      setOptions(data.cardContacts);
    }
  }, [data]);

  return (
    <Select
      value={``}
      id="dispatcher"
      labelId="dispatcher-label"
      label={'Contact'}
      fullWidth={true}
      onChange={(event) => {
        onCellChange({
          id: event.target.value as number,
          name: event.target.name ? event.target.name : '',
        });
      }}
    >
      {options.map((contact) => (
        <MenuItem key={contact.id} value={contact.id}>
          {`${contact.name} - ${contact.email} - ${contact.phoneNumber}`}
        </MenuItem>
      ))}
    </Select>
  );
}
